import styles from "./sidebar.module.scss";
import { NavLink } from "react-router-dom";
import { Accordion, AccordionDetails, AccordionSummary, List } from "@material-ui/core";
import classNames from "classnames";
import { Path } from "enums";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import locale from "localization";
import { useContext } from "react";
import { FeatureFlagContext, UserContext } from "contexts";
import { Role, Portal } from "enums";

let Navigation = [
  {
    label: "Dashboard",
    link: Path.LocqDashboard,
    icon: "icon-dashboard",
    exact: true,
    permission: [Role.PortalAdmin],
  },
  {
    label: locale.cashFlow,
    icon: "icon-cash-flow",
    link: Path.LocqCashFlow,
    items: [
      // {
      //   label: locale.dealerSettlements,
      //   child: true,
      //   link: Path.LocqSettlement,
      //   permission: [Role.PortalAdmin, Role.Accounting],
      // },
      {
        label: locale.dealerSettlementHistory,
        child: true,
        link: Path.LocqSettlementHistory,
        permission: [Role.PortalAdmin, Role.Accounting, Role.SalesPartnerships],
      },
      {
        label: locale.oilcoSettlementHistory,
        child: true,
        link: Path.LocqOilcoSettlementHistory,
        permission: [Role.PortalAdmin, Role.Accounting],
      },
      {
        label: locale.dealerMonthlyFee,
        child: true,
        link: Path.LocqDealerMonthlyFee,
        permission: [Role.PortalAdmin],
      },
      {
        label: locale.prepayment,
        child: true,
        link: Path.LocqPrepayment,
        key: "PREPAYMENT",
        permission: [Role.PortalAdmin, Role.Accounting],
      },
    ],
    permission: [Role.PortalAdmin, Role.Accounting, Role.SalesPartnerships],
  },
  {
    label: locale.pricing,
    icon: "icon-pricing",
    link: Path.Pricing,
    items: [
      {
        label: locale.mopsPrice,
        child: true,
        link: Path.MopsPrice,
        permission: [Role.PortalAdmin],
      },
    ],
    permission: [Role.PortalAdmin],
  },
];

const ChildItem = (props) => {
  const { hasFeature } = useContext(FeatureFlagContext);
  const { styles, item, role } = props;

  return (
    <Accordion elevation={0} className={styles.accordionDetails}>
      <AccordionSummary expandIcon={<ExpandMoreIcon className={styles.expandIcon} />}>
        <NavLink
          to={item.link}
          className={classNames(styles.listItem, styles.disableLink)}
          activeClassName={classNames(item, styles.selectedMenu)}
        >
          <span className={styles.item}>
            <span className={styles.listIconRoot}>
              {item.icon && <span className={`icon ${item.icon}`} />}
            </span>
            <span className={styles.listText}>{item.label}</span>
          </span>
        </NavLink>
      </AccordionSummary>
      <AccordionDetails>
        <div className={styles.child}>
          {item.items
            ?.filter((child) => {
              return child.key ? hasFeature(`PAGE_${child.key}`) : true;
            })
            .map((child, childKey) => {
              if (!child) return <></>;
              return child.permission?.map((permission) => {
                return permission === role ? (
                  <NavLink
                    key={childKey}
                    to={child.link}
                    className={classNames(styles.listItem)}
                    activeClassName={styles.active}
                  >
                    <span className={styles.listIconRoot}>
                      {child.icon && <span className={`icon ${child.icon}`} />}
                    </span>
                    <span className={styles.listText}>{child.label}</span>
                  </NavLink>
                ) : (
                  <></>
                );
              });
            })}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

const Sidebar = () => {
  const { hasFeature } = useContext(FeatureFlagContext);
  const { user } = useContext(UserContext);
  const { role } = user?.userAccesses?.find((element) => {
    return element.portal === Portal.LOCQ;
  });

  return (
    <div className={classNames(styles.consumerSidebarContainer)}>
      <div className={styles.smallColorBox}></div>
      <List className={styles.sidebarList}>
        {Navigation.filter((item) => {
          return item.key ? hasFeature(`PAGE_${item.key}`) : true;
        }).map((item, index) => {
          if (!item) return <></>;

          if (!user.userAccesses)
            return (
              <span key={index}>
                {item.exact ? (
                  <NavLink
                    to={item.link}
                    exact={true}
                    className={classNames(styles.listItem)}
                    activeClassName={styles.active}
                  >
                    <span className={styles.item}>
                      <span className={styles.listIconRoot}>
                        {item.icon && <span className={`icon ${item.icon}`} />}
                      </span>
                      <span className={styles.listText}>{item.label}</span>
                    </span>
                  </NavLink>
                ) : (
                  <ChildItem styles={styles} item={item} role={role} />
                )}
              </span>
            );

          return item.permission?.map((permission) => {
            return permission === role ? (
              <span key={index}>
                {item.exact ? (
                  <NavLink
                    to={item.link}
                    exact={true}
                    className={classNames(styles.listItem)}
                    activeClassName={styles.active}
                  >
                    <span className={styles.item}>
                      <span className={styles.listIconRoot}>
                        {item.icon && <span className={`icon ${item.icon}`} />}
                      </span>
                      <span className={styles.listText}>{item.label}</span>
                    </span>
                  </NavLink>
                ) : (
                  <ChildItem styles={styles} item={item} role={role} />
                )}
              </span>
            ) : (
              <></>
            );
          });
        })}
      </List>
    </div>
  );
};

export default Sidebar;
