import { SettlementStatus } from "enums";
import PayWithPesoStatus from "enums/pay-with-peso-status";
export const nonFuelFilterState = () => ({
  startDate: null,
  endDate: null,
  searchKey: "",
  settlementStatuses: {
    isSelectedAll: true,
    value: [
      SettlementStatus.ForProcessing,
      SettlementStatus.ForSettlement,
      SettlementStatus.Processed,
    ],
  },
  status: {
    isSelectedAll: true,
    value: [PayWithPesoStatus.Success, PayWithPesoStatus.Voided],
  },
  redemptionStation: {
    value: null,
    label: "All",
  },
  page: 1,
  perPage: 10,
});
