import LabelAndValue from "components/commons/label-and-value/label-and-value";
import { DateTime } from "enums";
import { formatDate } from "utils";
import { prettifyPlatformType, prettifyProduct } from "utils/pretty.utils";

export const mapDataToList = (conversion) => {
  const {
    productConversionId,
    createdAt,
    platformType,
    mobileNumber,
    originalProductCode,
    productCode,
    originalBuyFuelId,
    buyFuelId,
    stationName,
    originalStationName,
    firstName,
    lastName,
    originalReferenceDate,
    referenceDate,
  } = conversion;
  return {
    productConversionId,
    conversionDate: formatDate(createdAt, DateTime.G),
    platform: prettifyPlatformType(platformType),
    accountName: (
      <LabelAndValue label={`${firstName} ${lastName}`.trim()}>{mobileNumber}</LabelAndValue>
    ),
    fromToProduct: (
      <LabelAndValue label={prettifyProduct(productCode)}>
        {prettifyProduct(originalProductCode)}
      </LabelAndValue>
    ),
    buyFuelIds: <LabelAndValue label={buyFuelId}>{originalBuyFuelId}</LabelAndValue>,
    referenceStations: <LabelAndValue label={stationName}>{originalStationName}</LabelAndValue>,
    referenceDates: (
      <LabelAndValue label={formatDate(referenceDate, DateTime.G)}>
        {formatDate(originalReferenceDate, DateTime.G)}
      </LabelAndValue>
    ),
  };
};

export const mapFilterToRequest = (filterState) => {
  const { convertFrom, convertTo, ...fs } = filterState;

  return {
    ...fs,
    originalProductCode: convertFrom,
    productCode: convertTo,
  };
};
