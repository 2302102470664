import { getVouchersUnique } from "apis/voucher.api";
import { useApi, useFilter, useModal, useMount } from "hooks";
import { locale } from "localization/en";
import React, { useContext } from "react";
import { Intro } from "components/commons";

import { generateReportAdmin } from "apis";
import { SuccessModal } from "components/modals";
import UniqueVoucherFilter from "./unique-vouchers-filters";
import { uniqueVoucherFilterState } from "./unique-voucher-filter.state";
import { useCallback } from "react";
import UniqueVoucherList from "./unique-vouchers-list";
import { SessionContext } from "contexts";

const UniqueVouchers = () => {
  const { request, result = {}, loading } = useApi({ api: getVouchersUnique });
  const { voucherClaims = [], count = 0 } = result;
  const {
    filterState,
    requestState,
    submitFilter,
    modifyFilters,
    filterCount,
    resetFilter,
    clearFilter,
    initialState,
  } = useFilter(uniqueVoucherFilterState());

  const { sessionId } = useContext(SessionContext);

  const successModal = useModal();

  const exportApi = useApi({
    api: generateReportAdmin,
    params: {
      platformType: "plc",
      reportType: "voucher-claim",
      sessionId,
    },
  });

  const mapParamRequest = useCallback(
    (params) => {
      const { campaignGroups, currencies, campaignCreators, statuses, ...requestParams } = params;
      return {
        ...requestParams,
        statuses: statuses.join(","),
        currencies: currencies.join(","),
        campaignGroups:
          initialState.campaignGroups.length === campaignGroups.length
            ? ""
            : campaignGroups.join(","),
        campaignCreators: campaignCreators?.isAll
          ? ""
          : campaignCreators?.value.map((v) => v.value).join(","),
      };
    },
    [initialState]
  );

  const fetchData = useCallback(
    async (params = requestState) => {
      const requestParams = {
        ...submitFilter({ ...params }),
      };

      await request(
        {
          ...mapParamRequest(requestParams),
        },
        fetchData
      );
    },
    [request, requestState, submitFilter, mapParamRequest]
  );

  useMount(() => {
    fetchData({ ...requestState });
  });

  return (
    <div>
      <SuccessModal {...successModal} />
      <div style={{ marginBottom: "30px" }}>
        <Intro title={locale.uniqueVouchers} subtitle={"dadas"} />
      </div>
      <UniqueVoucherFilter
        filterState={filterState}
        submitFilter={(obj = {}) => {
          const { requestState } = modifyFilters({ page: 1, ...obj });
          fetchData(requestState);
        }}
        loading={loading}
        resetFilter={resetFilter}
        filterCount={filterCount}
        modifyFilters={modifyFilters}
        clearFilter={() => {
          const { requestState } = clearFilter();
          fetchData(requestState);
        }}
        exporting={exportApi.loading}
        isExportDisbaled={count === 0}
        onExport={async () => {
          const {
            statuses,
            currencies,
            searchKey,
            startDate,
            endDate,
            campaignGroups,
            campaignCreators,
            voucherType,
          } = mapParamRequest(requestState || {});

          const resp = await exportApi.request({
            statuses,
            currencies,
            searchKey: searchKey || "",
            startDate,
            endDate,
            voucherCampaignGroups: campaignGroups,
            voucherCampaignCreators: campaignCreators,
            voucherType: voucherType === "all" ? "" : voucherType,
          });
          if (resp?.downloadUrl) {
            window.open(resp?.downloadUrl, "_blank");
          } else {
            successModal.show({
              title: locale.exclamatedSuccess,
              content: locale.reportGenerated,
            });
          }
        }}
      />
      <UniqueVoucherList
        data={voucherClaims}
        filterState={filterState}
        modifyFilters={modifyFilters}
        fetchData={fetchData}
        result={voucherClaims}
        loading={loading}
        count={count}
      />
    </div>
  );
};

export default UniqueVouchers;
