import { formatNumber } from "utils";

export const accountTypeFormGet = (data) => {
  return {
    accountTypeId: { value: data?.accountTypeId },
    name: { value: data?.name },
    type: { value: data?.type },
    walletSize: { value: formatNumber(data?.walletSize, 2) },
    fuelTankSize: { value: formatNumber(data?.fuelTankSize, 3) },
    totalCashinLimitDaily: {
      value: formatNumber(data?.totalCashinLimitDaily, 2),
    },
    totalCashinLimitMonthly: {
      value: formatNumber(data?.totalCashinLimitMonthly, 2),
    },
    totalTransactionCardDaily: {
      value: formatNumber(data?.totalTransactionCardDaily, 2),
    },
    totalTransactionCardMonthly: {
      value: formatNumber(data?.totalTransactionCardMonthly, 2),
    },
    totalBuyFuelLimitDaily: {
      value: formatNumber(data?.totalBuyFuelLimitDaily, 3),
    },
    totalBuyFuelLimitMonthly: {
      value: formatNumber(data?.totalBuyFuelLimitMonthly, 3),
    },
    totalPayWithPesoLimitDaily: {
      value: formatNumber(data?.totalPayWithPesoLimitDaily, 2),
    },
    totalPayWithPesoLimitMonthly: {
      value: formatNumber(data?.totalPayWithPesoLimitMonthly, 2),
    },
    totalRedeemLimitDaily: {
      value: data?.totalRedeemLimitDaily,
    },
    totalRedeemLimitType: {
      value: data?.totalRedeemLimitType,
    },
    totalRedemptionLiterLimitDaily: {
      value: formatNumber(data?.totalRedemptionLiterLimitDaily, 3),
    },
  };
};
