import React, { useContext, useEffect, useState } from "react";
import { EditRounded } from "@material-ui/icons";

import { ActionButton, BackLink, Intro, Text } from "components/commons";
import { Path, SettlementType } from "enums";
import locale from "localization";
import { useApi, useModal, useMount, useRouter } from "hooks";

import styles from "./oilco-view-settlement-history.module.scss";

import ViewSettlementHistoryForm from "./oilco-view-settlement-history-form.module";
import ViewSettlementHistoryTable from "./oilco-view-settlement-history-table.module";
import { updateSettlementOilcoHistory } from "apis";

import useCancelSettlement from "../hooks/useCancelSettlement";
import { ConfirmModal, ErrorModal } from "components/modals";
import { handleRequest, prettifySettlementStatus } from "utils";

import {
  renderMultipleSettlements,
  renderSingleSettlement,
} from "../components/settlement-collection";
import { getSettlementOilcoHistoryById } from "apis/settlement.api";
import useWebSocket from "hooks/useWebSockets";
import { SessionContext } from "contexts";
import env from "environments/env";

const OilcoViewSettlementHistory = () => {
  const {
    history,
    query: { id: settlementId },
  } = useRouter();
  const confirmModal = useModal();
  const successModal = useModal();
  const errorModal = useModal();
  const serverErrorModal = useModal();

  const [totalMopsRemarks, setTotalMopsRemarks] = useState();
  const [
    totalOilcoSettlementReceivablePayableRemarks,
    setTotalOilcoSettlementReceivablePayableRemarks,
  ] = useState();
  const [oilcoSettlementStatus, setOilCoSettlementStatus] = useState();
  const [downloadUrl, setDownloadUrl] = useState();

  const [edit, setEdit] = useState(false);
  const cancelSettlement = useCancelSettlement();

  const { onConnect, onDisconnect } = useContext(SessionContext);
  const { message, sessionId: connectionId } = useWebSocket({ URL: env?.WEB_SOCKET_URL });

  const { result, request, loading } = useApi({
    api: getSettlementOilcoHistoryById,
    params: {
      settlementType: SettlementType.Oilco,
    },
    pageError: true,
  });

  const updateSettlement = useApi({
    api: updateSettlementOilcoHistory,
    handleOwnError: {
      unauthorized: true,
      badrequest: true,
    },
  });

  // const onCancelSettlement = () => {
  //   cancelSettlement.request({
  //     settlementId,
  //     confirmationRequest: async () => {
  //       history.push(Path.LocqOilcoSettlementHistory);
  //     },
  //     settlementCollectionRedirection: async (settlementId) => {
  //       request({ id: settlementId });
  //       history.push(settlementId);
  //     },
  //     oilcoSettlementStatus: result?.oilcoSettlementStatus,
  //     discountVoucherStatus: result?.discountVoucherStatus,
  //   });
  // };

  const onUpdateSuccess = () => {
    successModal.show({
      title: locale.exclamatedSuccess,
      content: locale.detailHaveBeenSuccessfullyUpdated,
      primary: {
        text: locale.gotIt,
        onClick: async () => {
          history.push(Path.LocqOilcoSettlementHistory);
        },
      },
    });
  };

  const onConfirmRequest = () => {
    confirmModal.show({
      title: locale.saveChangesQuestion,
      content: (
        <locale.Populate
          text={locale.editOilcoSettlementStatus}
          items={[<strong>{prettifySettlementStatus(oilcoSettlementStatus)}</strong>]}
        />
      ),
      secondary: {
        text: locale.continueEditing,
        onClick: () => confirmModal.close(),
      },
      primary: {
        text: locale.saveChanges,
        onClick: () =>
          handleRequest(
            async () => {
              await updateSettlement.request({
                settlementId,
                totalMopsRemarks,
                totalOilcoSettlementReceivablePayableRemarks,
                oilcoSettlementStatus,
              });
              onUpdateSuccess();
              confirmModal.close();
            },
            {
              S0004: ({ settlements }) => {
                confirmModal.close();
                errorModal.show({
                  title: locale.processSettlementIds,
                  content: (
                    <>
                      <Text align={"center"}>{locale.processSettlementIdsContent}</Text>
                      {settlements?.length > 1
                        ? renderMultipleSettlements(settlements, (settlementId) => {
                            request({ id: settlementId });
                            history.push(Path.ViewSettlementHistoryById(settlementId));
                          })
                        : renderSingleSettlement(settlements[0], (settlementId) => {
                            request({ id: settlementId });
                            history.push(Path.ViewSettlementHistoryById(settlementId));
                          })}
                    </>
                  ),
                });
              },
            }
          ),
      },
    });
  };

  useMount(async () => {
    if (!settlementId) {
      history.push(Path.LocqOilcoSettlementHistory);
    } else {
      onDisconnect();
    }
  });

  useEffect(() => {
    if (message.length !== 0) {
      setDownloadUrl(message[0].fileUrl);
    }
  }, [message]);

  //eslint-disable-next-line
  useEffect(async () => {
    if (connectionId) {
      const res = await request({ id: settlementId, connectionId });
      setTotalMopsRemarks(res.totalMopsRemarks);
      setTotalOilcoSettlementReceivablePayableRemarks(
        res.totalOilcoSettlementReceivablePayableRemarks
      );
      setOilCoSettlementStatus(res.oilcoSettlementStatus);
    }

    return () => {
      onConnect();
    };
    //eslint-disable-next-line
  }, [connectionId]);

  return (
    <>
      <ConfirmModal
        {...confirmModal}
        disabled={updateSettlement?.loading}
        closable={!updateSettlement?.loading}
        loading={updateSettlement?.loading}
      />
      <ConfirmModal {...successModal} closable={false} />
      <ConfirmModal {...cancelSettlement.cancelSettlementErrorModal} />
      <ConfirmModal {...errorModal} />
      <ErrorModal {...serverErrorModal} />
      <ErrorModal {...cancelSettlement.serverErrorModal} />
      <cancelSettlement.ConfirmCancel />
      <div className={styles.container}>
        <BackLink text={locale.oilcoSettlementHistory} path={Path.LocqOilcoSettlementHistory} />
        <div
          style={{
            display: "flex",
          }}
        >
          <Intro
            style={{
              minWidth: "300px",
            }}
            title={locale.oilcoSettlementDetails}
          />
          {edit ? (
            <ActionButton
              right
              style={{
                marginTop: "0px",
              }}
              items={[
                {
                  onClick: () => {
                    setTotalMopsRemarks(result.totalMopsRemarks);
                    setTotalOilcoSettlementReceivablePayableRemarks(
                      result.totalOilcoSettlementReceivablePayableRemarks
                    );
                    setOilCoSettlementStatus(result.oilcoSettlementStatus);
                    setEdit(!edit);
                  },
                  text: locale.cancel,
                  primary: false,
                },
                {
                  onClick: onConfirmRequest,
                  text: locale.save,
                  primary: true,
                  disabled: !(
                    (totalMopsRemarks || null) !== result.totalMopsRemarks ||
                    (totalOilcoSettlementReceivablePayableRemarks || null) !==
                      result.totalOilcoSettlementReceivablePayableRemarks ||
                    oilcoSettlementStatus !== result.oilcoSettlementStatus
                  ),
                },
              ]}
            />
          ) : (
            <ActionButton
              right
              style={{
                marginTop: "0px",
              }}
              items={[
                {
                  onClick: () => {
                    setEdit(!edit);
                  },
                  text: locale.edit,
                  startIcon: <EditRounded />,
                  exportButton: true,
                  primary: true,
                  // },
                  // {
                  //   onClick: onCancelSettlement,
                  //   text: locale.cancelSettlement,
                  //   outline: true,
                  //   primary: true,
                },
              ]}
            />
          )}
        </div>
        <ViewSettlementHistoryForm
          settlementDetails={{ ...result } || null}
          setTotalMopsRemarks={setTotalMopsRemarks}
          totalMopsRemarks={totalMopsRemarks}
          setTotalOilcoSettlementReceivablePayableRemarks={
            setTotalOilcoSettlementReceivablePayableRemarks
          }
          totalOilcoSettlementReceivablePayableRemarks={
            totalOilcoSettlementReceivablePayableRemarks
          }
          oilcoSettlementStatus={oilcoSettlementStatus}
          setOilCoSettlementStatus={setOilCoSettlementStatus}
          settlementLoading={loading}
          viewMode={edit}
          currentOilcoSettlementStatus={result?.oilcoSettlementStatus}
        />
        <ViewSettlementHistoryTable
          settlementDetails={{ ...result }}
          settlementId={settlementId}
          downloadUrl={downloadUrl}
        />
      </div>
    </>
  );
};

export default OilcoViewSettlementHistory;
