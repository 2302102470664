import { ActionButton, Modal, Text } from "components/commons";
import locale from "localization";
import { formatAmount } from "utils";
import styles from "./confirm-mops-modal.module.scss";

const ConfirmMopsModal = ({ onGenerate, generating, values, deactivated, ...modalState }) => {
  const { close, active } = modalState;

  const submit = async () => {
    await onGenerate();
  };

  return (
    <Modal
      active={active}
      close={close}
      title={locale.askConfirmWithChanges}
      className={styles.modal}
    >
      <div className={styles.container}>
        <Text className={styles.content}>{locale.kindlyReviewTheBelowInfo}</Text>
        <div className={styles.mainContainer}>
          <div className={styles.subContainer}>
            <div className={styles.category}>
              <Text align={"left"} label>
                {locale.mogas}
              </Text>
            </div>
            <div>
              <Text strong>{formatAmount(values.mogas.value) || "N/A"}</Text>
            </div>
          </div>

          <div className={styles.subContainer}>
            <div className={styles.category}>
              <Text align={"left"} label>
                {locale.gasoil}
              </Text>
            </div>
            <div>
              <Text strong>{formatAmount(values.gasoil.value) || "N/A"}</Text>
            </div>
          </div>

          <div className={styles.subContainer}>
            <div className={styles.category}>
              <Text align={"left"} label>
                {locale.effectiveDate}
              </Text>
            </div>
            <div>
              <Text strong>{values.effectiveAt.value || "N/A"}</Text>
            </div>
          </div>
        </div>
      </div>
      <ActionButton
        right
        items={[
          {
            onClick: () => {
              close();
            },
            text: locale.cancel,
          },
          {
            onClick: () => {
              submit(submit);
            },
            loading: generating,
            text: locale.save,
            disabled: deactivated,
          },
        ]}
      />
    </Modal>
  );
};

export default ConfirmMopsModal;
