const FuelCodesStatus = {
  Active: "active",
  Inactive: "inactive",
  Expired: "expired",
  Scheduled: "scheduled",
  Deactivated: "deactivated",
  Redeemed: "redeemed",
};

export default FuelCodesStatus;
