import locale from "localization";
import styles from "./target-stations-columns.module.scss";

export const SelectedFleetCardColumns = [
  {
    key: "cardType",
    text: <span className={styles.heading}>{locale.cardType}</span>,
    width: "20%",
  },
  {
    key: "transactionLimit",
    text: <span className={styles.heading}>{locale.monthlyCardTransactionLimit}</span>,
    width: "25%",
  },
  {
    key: "locqpayLimit",
    text: <span className={styles.heading}>{locale.monthyCardLocqpayLimit}</span>,
    width: "25%",
  },
  {
    key: "products",
    text: <span className={styles.heading}>{locale.allowedProduct}</span>,
    width: "25%",
  },
  {
    key: "plateNumber",
    text: <span className={styles.heading}>{locale.vehiclePlateNo}</span>,
    width: "25%",
  },
  {
    key: "model",
    text: <span className={styles.heading}>{locale.vehicleBrandModel}</span>,
    width: "25%",
  },
  {
    key: "vehicleRemarks",
    text: <span className={styles.heading}>{locale.vehicleRemarks}</span>,
    width: "25%",
  },
  {
    key: "driver",
    text: <span className={styles.heading}>{locale.driverName}</span>,
    width: "25%",
  },
  {
    key: "driverLicenseId",
    text: <span className={styles.heading}>{locale.driverLicenseId}</span>,
    width: "25%",
  },
  {
    key: "mobileNumber",
    text: <span className={styles.heading}>{locale.driverContactNo}</span>,
    width: "25%",
  },
  {
    key: "cardNumber",
    text: <span className={styles.heading}>{locale.fleetCardNo}</span>,
    width: "25%",
  },
];
