import { Path } from "enums";
import React, { useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import CustomerListModule from "modules/customer/customer-list/customer-list.module";
import { FeatureFlagContext } from "contexts";
import AttributesPage from "./attributes.page";
import AccountTypesPage from "./account-types.page";
import KYCPage from "./kyc.page";

const CustomerPage = () => {
  const { hasFeature } = useContext(FeatureFlagContext);
  return (
    <Switch>
      <Route exact path={Path.CustomersList} component={CustomerListModule} />
      <Route path={Path.Attributes} component={AttributesPage} />
      <Route path={Path.AccountTypes} component={AccountTypesPage} />
      {hasFeature("PAGE_KYC_SUBMISSIONS") && (
        <Route path={Path.KYCSubmissions} component={KYCPage} />
      )}
      <Redirect to={Path.CustomersList} />
    </Switch>
  );
};

export default CustomerPage;
