import React, {
  useMemo,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useState,
} from "react";
import { useForm, useApi, useModal, useMount, useFilter } from "hooks";
import { FormMode, Path, CampaignStatus } from "enums";
import { initialState as formState } from "./discount-details.state";
import DiscountDetailsFormModule from "./discount-details-form.module";
import styles from "./discount-details.module.scss";
import locale from "localization";
import { Loader } from "components/commons";
import { handleRequest } from "utils";
import { ConfirmModal } from "components/modals";
import TargetStationsModal from "./target-stations-modal";
import ImportStationModal from "./import-station";
import { useHistory } from "react-router-dom";
import {
  createLOCQPayDiscountCampaign,
  updateLOCQPayDiscountCampaign,
  getLOCQPayDiscount,
  getUserAttributes,
  getStations,
  getLOCQPayDiscountCampaignStations,
} from "apis";
import {
  discountCampaignFormPost,
  discountCampaignFormGet,
  getCohortIds,
  stationsIterate,
} from "./discount-campaign-form.utils";
import { TargetStationsFilterState } from "./target-stations-filter.state";
import usePagination from "hooks/usePagination";

const DiscountDetailsModule = (
  { pageMode, outrightDiscountCampaignId, stationCount, ...props },
  ref
) => {
  const { setUpdateLoading, setDisableUpdate, setIsCancelled } = props;
  const viewMode = pageMode === FormMode.View;
  const addMode = pageMode === FormMode.Add;
  const editMode = pageMode === FormMode.Edit;
  const [attributeInput, setAttributeInput] = useState([]);
  const [cohortInputs, setCohortInputs] = useState([]);
  const [stationInputs, setStationInputs] = useState([]);
  const [selectedAttributeCohort, setSelectedAttributeCohort] = useState([]);
  const [isAllCohort, setIsAllCohort] = useState([]);
  const [attributeRead, setAttributeRead] = useState([]);
  const [otherTargetAttribute, setOtherTargetAttribute] = useState({
    accountType: false,
    station: false,
  });
  const [isAllStation, setIsAllStation] = useState({ checked: false });
  const [campaignStatus, setCampaignStatus] = useState("");
  const [inputStationCount, setInputStationCount] = useState(0);
  const [stationslist, setStationsList] = useState([]);
  const [campaignStations, setCampaignStations] = useState([]);
  const ongoingCampaign = campaignStatus === CampaignStatus.Ongoing;
  const endedCampaign = campaignStatus === CampaignStatus.Ended;

  const {
    request: getDiscountRequest,
    loading: loadingDiscount,
    result: getDiscountResult,
  } = useApi({
    api: getLOCQPayDiscount,
    pageError: true,
    params: {
      outrightDiscountCampaignId,
    },
  });

  const {
    request: getUserAttributesRequest,
    loading: loadingAttributes,
    result: getUserAttributesResult = { attributes: [], count: 0 },
  } = useApi({
    api: getUserAttributes,
    modalError: true,
    params: { page: 1, perPage: 10000 }, // todo: temporal fix perPage value
  });

  const { request: getDiscountStationsRequest } = useApi({
    api: getLOCQPayDiscountCampaignStations,
    modalError: true,
    params: { page: 1 },
  });

  const {
    filterState,
    requestState,
    submitFilter,
    modifyFilters,
    filterCount,
    modifyFilter,
    clearFilter,
    submittedFilter,
  } = useFilter(TargetStationsFilterState());

  const { request: getStationsRequest, loading: loadingStations } = useApi({
    api: getStations,
    modalError: true,
    params: {
      platformType: "plc",
    },
  });

  const stationData = usePagination();
  const fetchStations = useCallback(
    async (rs = { ...requestState }, perPage) => {
      if (rs.reset) {
        stationData.reset();
      }
      if (stationData.canLoadMore || rs.reset) {
        const result = await getStationsRequest(
          submitFilter({
            perPage: perPage ? perPage : 10,
            ...rs,
            page: rs.reset ? 1 : stationData.page,
          }),
          null
        );
        stationData.onLoadMore({ data: result.stations, total: result.count, resetPage: rs.reset });

        // saves the count for the first render only
        if (inputStationCount === 0) {
          setInputStationCount(result?.count);
          setStationsList(result?.stations);
        }
        return result;
      }
    },
    [getStationsRequest, requestState, inputStationCount, submitFilter, stationData]
  );

  const populateAttributeInputValue = useCallback(({ cohorts, attributes, excludedAttributes }) => {
    let newAttributes = {};
    let selectCohorts = {};
    let cohortsInput = [];
    let isAllCohort = [];
    cohorts = cohorts ?? [];
    excludedAttributes = excludedAttributes ?? [];

    // merge excludedAttributes to display its own attribute input without value but with checked 'exclusion checkbox'
    [...cohorts, ...excludedAttributes]?.forEach((cohort) => {
      const attributeId = cohort?.attribute?.attributeId || cohort?.attributeId;
      const name = cohort?.attribute?.name || cohort?.name;
      if (cohort?.cohortId) {
        cohortsInput.push({
          name: cohort.name,
          value: cohort.cohortId,
          attributeId,
        });
      }
      const filteredAttributes = attributes.filter((a) => a.attributeId === attributeId)[0];
      newAttributes[attributeId] = {
        name,
        attributeId,
        cohorts: filteredAttributes?.cohorts,
      };

      const cohorts = cohortsInput.filter((c) => c?.attributeId === attributeId);
      selectCohorts[attributeId] = [];
      selectCohorts[attributeId] = cohorts;
      isAllCohort[attributeId] = cohorts.length === filteredAttributes?.cohorts?.length;
    });
    setSelectedAttributeCohort(selectCohorts);
    setAttributeInput(Object.values(newAttributes));
    setIsAllCohort(isAllCohort);
  }, []);

  const form = useMemo(() => {
    let initialState = {};
    if (outrightDiscountCampaignId) {
      const data = getDiscountResult;
      if (data) {
        data.stations = campaignStations;
        initialState = discountCampaignFormGet(data);
        if (attributeRead?.length > 0) {
          setCampaignStatus(data?.status);
          if (viewMode) {
            setIsCancelled(data?.status === CampaignStatus.Cancelled ? true : false);
          }

          // inject values to cohorts of attribute inputs
          populateAttributeInputValue({
            cohorts: data?.cohorts,
            attributes: attributeRead,
          });
        }
      }
    }
    return formState(initialState);
  }, [
    outrightDiscountCampaignId,
    getDiscountResult,
    attributeRead,
    populateAttributeInputValue,
    campaignStations,
    setIsCancelled,
    viewMode,
  ]);

  const confirmModal = useModal();
  const { show, close } = confirmModal;
  const stationsModal = useModal();
  const importStationModal = useModal();
  const history = useHistory();

  useMount(async () => {
    if (outrightDiscountCampaignId) {
      getDiscountRequest();
      if (stationCount) fetchCampaignStations({ outrightDiscountCampaignId, stationCount });
    }
    const attributeRequest = await getUserAttributesRequest();
    if (attributeRequest?.attributes.length > 0) {
      setAttributeRead(attributeRequest.attributes);
    }
  });

  const {
    fields,
    modifyField,
    isFormSubmittable,
    submitForm,
    modifyForm,
    getFormValues,
    applyFieldErrors,
  } = useForm({
    initialState: form,
  });

  const fetchCampaignStations = useCallback(
    async ({ outrightDiscountCampaignId, stationCount }) => {
      const results = await getDiscountStationsRequest({
        outrightDiscountCampaignId,
        perPage: stationCount,
      });

      if (results) {
        const formatStation = stationsIterate(results?.outrightDiscountCampaignStations);
        setCampaignStations(formatStation);
      }
    },
    [getDiscountStationsRequest]
  );

  const addRequest = useApi({
    api: createLOCQPayDiscountCampaign,
    handleOwnError: {
      badrequest: true,
    },
  });

  const editRequest = useApi({
    api: updateLOCQPayDiscountCampaign,
    handleOwnError: {
      badrequest: true,
    },
    params: {
      outrightDiscountCampaignId,
    },
  });

  const loading = addRequest.loading || editRequest.loading;

  const submit = (params) => {
    const apiRequest = addMode ? addRequest : editRequest;

    handleRequest(
      async () => {
        close();
        await apiRequest.request(
          {
            ...params,
          },
          () => handleSubmit()
        );

        if (addMode) {
          history.push(Path.LOCQPayDiscountCampaigns);
        } else {
          show({
            title: locale.exclamatedSuccess,
            content: locale.locqpayCampaignHasBeenUpdated,
            primary: {
              text: locale.gotIt,
              onClick: () => {
                history.push(Path.ViewLOCQPayDiscountCampaigns, {
                  outrightDiscountCampaignId: outrightDiscountCampaignId,
                  stationCount: params?.stations?.length,
                });
              },
            },
          });
        }
      },
      {
        LC1001: () => {
          applyFieldErrors({
            campaignName: locale.locqpayDiscountCampaignAlreadyExist,
          });
        },
      }
    );
  };

  const handleSubmit = () => {
    const currentFormValues = getFormValues();
    let payload = discountCampaignFormPost(currentFormValues);
    const { products, startDate, endDate } = payload;

    if (ongoingCampaign || endedCampaign) {
      delete payload.startDate;
    }

    if (products.length === 0 || !startDate || !endDate) {
      const productsError =
        products.length === 0 ? locale.insuficientProductError : locale.startDateEndDateError;
      show({
        title: addMode ? locale.createCampaignError : locale.saveChangesError,
        content: productsError,
        primary: {
          text: locale.close,
          onClick: () => close(),
        },
      });
    } else {
      show({
        title: addMode ? locale.createCampaign : locale.saveChangesQuestion,
        content: addMode
          ? locale.areYouSureProceedCreatingCampaign
          : locale.areYouSureSaveAllChangesCampaign,
        secondary: {
          text: addMode ? locale.Cancel : locale.continueEditing,
        },
        primary: {
          text: addMode ? locale.yesCreateCampaign : locale.saveChanges,
          onClick: () => {
            submit(payload);
          },
        },
      });
    }
  };

  useImperativeHandle(ref, () => ({
    handleUpdate() {
      submitForm(handleSubmit);
    },
  }));

  const onDateRangeCb = useCallback(
    (name, { value }) => {
      const { startDate, endDate } = value;
      modifyField(fields?.campaignDuration?.name, {
        value: {
          startDate: startDate,
          endDate: endDate,
        },
      });
    },
    [fields?.campaignDuration?.name, modifyField]
  );

  const onSetVolumeCapCb = useCallback(
    (name, { value }) => {
      modifyForm({
        discountCapType: {
          value,
        },
      });
    },
    [modifyForm]
  );

  const validateDiscountFields = useMemo(() => {
    const fieldsToBeChecked = { ...fields };
    let numberOfZeroValues = 0;
    delete fieldsToBeChecked["accountTypes"];
    delete fieldsToBeChecked["campaignCardNameCopy"];
    delete fieldsToBeChecked["campaignDuration"];
    delete fieldsToBeChecked["campaignName"];
    delete fieldsToBeChecked["campaignName"];
    delete fieldsToBeChecked["cohorts"];
    delete fieldsToBeChecked["discountCap"];
    delete fieldsToBeChecked["discountCapType"];
    delete fieldsToBeChecked["excludedAttributes"];
    delete fieldsToBeChecked["shoulderedBy"];
    delete fieldsToBeChecked["stations"];
    for (const [, item] of Object.entries(fieldsToBeChecked)) {
      if (item.value === "0.00" || item.value === "0") {
        numberOfZeroValues++;
      }
    }
    if (numberOfZeroValues > 0 && numberOfZeroValues === 4) {
      return true;
    }

    return false;
  }, [fields]);

  useEffect(() => {
    if (editMode) {
      setUpdateLoading(loading);
      setDisableUpdate(!isFormSubmittable || validateDiscountFields);
    }
  }, [
    editMode,
    isFormSubmittable,
    loading,
    setDisableUpdate,
    setUpdateLoading,
    validateDiscountFields,
  ]);

  const preparedAttributes = useMemo(() => {
    let preparedData = [];
    const { attributes } = getUserAttributesResult;

    attributes?.forEach((attribute) => {
      const selected = Object.keys(attributeInput).some((k) => {
        return attributeInput[k].name === attribute?.name;
      });
      if (!selected) {
        preparedData.push({
          content: attribute?.name,
          onClick: () => {
            if (attribute) {
              setAttributeInput([
                ...attributeInput,
                {
                  name: attribute?.name,
                  cohorts: attribute?.cohorts,
                  attributeId: attribute?.attributeId,
                },
              ]);
            }
          },
        });
      }
    });

    return preparedData;
  }, [getUserAttributesResult, attributeInput]);

  const selectAccountType = useCallback(
    (name, { value, isAll }) => {
      modifyField(name, { value, isAll });
    },
    [modifyField]
  );

  const onChangeShoulderedByCb = useCallback(
    (shoulderedByType) => {
      modifyField(fields?.shoulderedBy?.name, { value: shoulderedByType });
    },
    [fields?.shoulderedBy?.name, modifyField]
  );

  const removeAccountType = useCallback(() => {
    setOtherTargetAttribute({ ...otherTargetAttribute, accountType: false });
    modifyField(fields?.accountTypes?.name, { value: [], isAll: false });
  }, [fields?.accountTypes?.name, modifyField, otherTargetAttribute]);

  const selectCohorts = useCallback(
    (name, { value, isAll }) => {
      let cloneSelectedAttributeCohort = { ...selectedAttributeCohort };
      let isAllCohortClone = { ...isAllCohort };

      cloneSelectedAttributeCohort[name] = value;
      setSelectedAttributeCohort(cloneSelectedAttributeCohort);

      isAllCohortClone[name] = isAll;
      setIsAllCohort(isAllCohortClone);

      modifyField(fields?.cohorts?.name, {
        value: cloneSelectedAttributeCohort,
      });
    },
    [selectedAttributeCohort, isAllCohort, modifyField, fields?.cohorts?.name]
  );

  const removeAttribute = useCallback(
    (attributeName, attributeId) => {
      let cloneIsAllCohort = { ...isAllCohort };
      delete cloneIsAllCohort[attributeId];
      setIsAllCohort(cloneIsAllCohort);

      let cloneSelectedAttributeCohort = { ...selectedAttributeCohort };
      delete cloneSelectedAttributeCohort[attributeId];
      setSelectedAttributeCohort(cloneSelectedAttributeCohort);

      let cloneCohort = [...cohortInputs];
      cloneCohort.splice(attributeId, 1);
      setCohortInputs(cloneCohort);

      setAttributeInput(attributeInput.filter((attribute) => attribute.name !== attributeName));

      const cohortsId = getCohortIds(cloneSelectedAttributeCohort);

      // force unchecked excludedAttributeId
      if (cohortsId.length > 0) {
        // TODO
      } else {
        modifyField(fields?.cohorts?.name, {
          value: cloneSelectedAttributeCohort,
        });
      }
    },
    [
      attributeInput,
      cohortInputs,
      fields?.cohorts?.name,
      isAllCohort,
      modifyField,
      selectedAttributeCohort,
    ]
  );

  const selectStationsCb = useCallback(
    (stationParams) => {
      let newStations = [];
      let isAllSelected = false;

      const stationSelected = stationInputs.some((station) => {
        return station.value === stationParams.value;
      });
      if (stationSelected) {
        newStations = stationInputs.filter((station) => station.value !== stationParams.value);
      } else {
        const clone = [...stationInputs];
        newStations = [...clone, stationParams];
      }

      if (inputStationCount === newStations.length) {
        isAllSelected = true;
      }

      setIsAllStation({ checked: isAllSelected });
      setStationInputs(newStations);
    },
    [inputStationCount, stationInputs]
  );

  const onClearSelectedStation = useCallback(() => {
    modifyField(fields?.stations?.name, { value: [] });
    setStationInputs([]);
  }, [fields?.stations?.name, modifyField, setStationInputs]);

  const saveStationsCb = useCallback(() => {
    modifyField(fields?.stations?.name, {
      value: stationInputs,
    });
  }, [fields?.stations?.name, modifyField, stationInputs]);

  const targetStationsProps = {
    filterState,
    modifyFilters,
    filterCount,
    modifyFilter,
    clearFilter,
    selectStationsCb,
    stationInputs,
    setStationInputs,
    saveStationsCb,
    fetchStations,
    loadingStations,
    isAllStation,
    setIsAllStation,
    inputStationCount,
    stationslist,
    setStationsList,
    submittedFilter,
    stationData,
    onClearSelectedStation,
  };

  const detailsFormProps = {
    viewMode,
    addMode,
    editMode,
    fields,
    isFormSubmittable,
    loading,
    preparedAttributes,
    modifyField,
    submitForm,
    modifyForm,
    onDateRangeCb,
    handleSubmit,
    attributeInput,
    removeAttribute,
    selectAccountType,
    selectCohorts,
    selectedAttributeCohort,
    isAllCohort,
    otherTargetAttribute,
    setOtherTargetAttribute,
    removeAccountType,
    stationsModal,
    importStationModal,
    setStationInputs,
    getUserAttributesResult,
    campaignStatus,
    fetchStations,
    onChangeShoulderedByCb,
    onSetVolumeCapCb,
    validateDiscountFields,
  };

  return (
    <div>
      <Loader open={loadingDiscount || loadingAttributes} />
      {!loadingDiscount && !loadingAttributes && (
        <div className={styles.container}>
          {!viewMode && (
            <>
              <ConfirmModal {...confirmModal} />
              <TargetStationsModal {...stationsModal} {...targetStationsProps} />
              <ImportStationModal
                {...importStationModal}
                request={""}
                stationData={stationData}
                setStationInputs={setStationInputs}
                saveStationsCb={saveStationsCb}
                stationInputs={stationInputs}
                modifyForm={modifyForm}
              />
            </>
          )}
          <DiscountDetailsFormModule {...detailsFormProps} />
        </div>
      )}
    </div>
  );
};

export default forwardRef(DiscountDetailsModule);
