import { createAccountType, getAccountType, updateAccountType } from "apis";
import { Loader } from "components/commons";
import { FormMode, Path } from "enums";
import { useApi, useForm, useModal, useMount } from "hooks";
import locale from "localization";
import _ from "lodash";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { ConfirmModal } from "components/modals";
import { useHistory } from "react-router-dom";
import { getObjectDifference, getObjectValues, handleRequest } from "utils";
import AccountTypeDetailsFormModule from "./account-type-details-form.module";
import styles from "./account-type-details.module.scss";
import { initialState as formState } from "./account-type-details.state";
import { accountTypeFormGet } from "./account-type-form.utils";
import AccountType from "enums/account-type";

const AccountTypeDetailsModule = forwardRef(({ pageMode, accountTypeId, ...props }, ref) => {
  const { setUpdateLoading, setDisableUpdate } = props;
  const viewMode = pageMode === FormMode.View;
  const addMode = pageMode === FormMode.Add;
  const editMode = pageMode === FormMode.Edit;
  const [previousFormValues, setPreviousFormValues] = useState({});
  const [defaultType, setDefaultType] = useState(false);

  const {
    request: getAccountTypeRequest,
    loading: loadingAccountType,
    result: getAccountTypeResult,
  } = useApi({
    api: getAccountType,
    pageError: true,
    params: {
      accountTypeId,
    },
  });

  const form = useMemo(() => {
    let initialState = {};
    if (accountTypeId) {
      const data = getAccountTypeResult;
      if (data) {
        initialState = accountTypeFormGet(data);
        if (editMode) {
          setPreviousFormValues(_.cloneDeep(getObjectValues(initialState)));
          setDefaultType(data.type === AccountType.TNVS || data.isDefault);
        }
      }
    }
    return formState(initialState);
  }, [editMode, accountTypeId, getAccountTypeResult]);

  const confirmModal = useModal();
  const { close } = confirmModal;
  const history = useHistory();

  useMount(() => {
    if (accountTypeId) {
      getAccountTypeRequest();
    }
  });

  const {
    fields,
    modifyField,
    isFormSubmittable,
    submitForm,
    clearForm,
    getFormValues,
    validatedField,
  } = useForm({
    initialState: form,
  });

  const onChangeRedeemLimitTypeCb = useCallback(
    (type) => {
      modifyField(fields.totalRedeemLimitType.name, { value: type });
    },
    [fields.totalRedeemLimitType.name, modifyField]
  );

  const addRequest = useApi({
    api: createAccountType,
    handleOwnError: {
      badrequest: true,
    },
  });

  const editRequest = useApi({
    api: updateAccountType,
    handleOwnError: {
      badrequest: true,
    },
    params: {
      accountTypeId,
    },
  });

  const loading = addRequest.loading || editRequest.loading;

  const submit = (params) => {
    const apiRequest = addMode ? addRequest : editRequest;
    handleRequest(async () => {
      close();
      const result = await apiRequest.request(
        {
          ...params,
        },
        () => handleSubmit()
      );
      clearForm();
      if (result) {
        history.push(Path.ViewAccountType, {
          accountTypeId: result.accountTypeId,
        });
      }
    });
  };

  const getChangedValues = (compare) => {
    return getObjectDifference(previousFormValues, compare);
  };

  const handleSubmit = () => {
    const currentFormValues = getFormValues();

    confirmModal.show({
      title: addMode ? locale.createAccountTypeQuestion : locale.saveChangesQuestion,
      content: addMode ? locale.areYouSureCreatingAccountType : locale.areYouSureSavingAccountType,
      secondary: {
        text: addMode ? locale.Cancel : locale.continueEditing,
      },
      primary: {
        text: addMode ? locale.yesCreateAccountType : locale.saveChanges,
        onClick: () => {
          const params = currentFormValues;
          submit(params);
        },
      },
    });
  };

  useImperativeHandle(ref, () => ({
    handleUpdate() {
      submitForm(handleSubmit);
    },
  }));

  useEffect(() => {
    if (editMode) {
      setUpdateLoading(loading);
      const currentFormValues = getFormValues();
      const updateParams = getChangedValues(currentFormValues);
      const fieldsChanged = Object.keys(updateParams).length > 0;
      setDisableUpdate(!isFormSubmittable || !fieldsChanged);
    }
    //eslint-disable-next-line
  }, [fields, loading]);

  const stateForm = {
    viewMode,
    addMode,
    fields,
    isFormSubmittable,
    loading,
    submitForm,
    handleSubmit,
    modifyField,
    onChangeRedeemLimitTypeCb,
    validatedField,
    defaultType,
  };

  return (
    <div>
      <Loader open={loadingAccountType} />
      {!loadingAccountType && (
        <div className={styles.container}>
          {!viewMode && (
            <ConfirmModal
              {...confirmModal}
              titleStyle={styles.modalTitle}
              contentStyle={styles.modalContent}
            />
          )}
          <AccountTypeDetailsFormModule {...stateForm} />
        </div>
      )}
    </div>
  );
});

export default AccountTypeDetailsModule;
