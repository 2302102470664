import {
  Autocomplete,
  Button,
  Field,
  Radio,
  Text,
  TextField,
  Image,
  Modal,
  ActionButton,
} from "components/commons";
import ClaimMethod from "enums/claim-method";
import VoucherType from "enums/voucher-type";
import VoucherCampaignGroup from "enums/campaign-group";
import locale from "localization";
import React, { useState } from "react";

import VoucherSkeleton from "./voucher-skeleton";
import {
  prettifyVoucherCampaignGroup,
  prettifyVoucherClaimMethod,
  prettifyVoucherType,
  prettifyThirdPartyVoucherCode,
} from "utils/pretty.utils";
import { InputAmount } from "components/field";
import { useCallback } from "react";
import {
  validateVoucherAmount,
  validateVoucherValue,
  voucherAmountState,
  voucherValueState,
  thirdPartyVoucherValueState,
  thirdPartyVoucherAutoSeedState,
  validateThirdPartyVoucherAutoSeed,
} from "./voucher-campaign.state";
import { RemoveIcon } from "images";
import VoucherTypeSetting from "enums/voucher-type-setting";
import VoucherRecipients from "enums/voucher-recipients";
import RemoveItemButton from "components/commons/remove-item-button/remove-item-button";
import { Grid } from "@material-ui/core";
import { isNumeric, parseNumber } from "utils";
import UploadFile from "components/commons/upload-file/upload-file";
import VoucherDiscountType from "enums/voucher-discount-type";
import DateRange from "components/commons/date-range/date-range";
import { parseCSVFile } from "utils/object.utils";
import { capitalizeAll, isMobileNumberStrictValid, isNumber } from "utils/text.utils";
import { useApi, useModal } from "hooks";
import { ConfirmModal } from "components/modals";
import { getUserByMobileNumber } from "apis/user.api";
import { BilledTo } from "enums";
import ThirdPartyVoucherCode from "enums/third-party-voucher-code";
import ImportStationModal from "./import-station";
import ImportThirdPartyOneTimeAndMutiModal from "./import-third-party-csv/import-third-party-one-time-multi";
import ImportThirdPartyAutoSeedModal from "./import-third-party-csv/import-third-party-auto-seed";
import TargetStationsModal from "./target-stations-modal";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import styles from "./voucher-form.module.scss";
import { getStations } from "apis";
import { usePagination, useFilter } from "hooks";
import { TargetStationsFilterState } from "./target-stations-filter.state";
import Big from "big.js";
import { CloseRounded } from "@material-ui/icons";

const VoucherForm = ({ loading, title, form = {}, intro }) => {
  const { fields, modifyForm } = form;
  const confirmModal = useModal();
  const importStationModal = useModal();
  const importOneTimeAndMultiThirdPartyModal = useModal();
  const importAutoSeedThirdPartyModal = useModal();
  const thirdPartyVoucherImportModalResult = useModal();
  const stationsModal = useModal();
  const [stationInputs, setStationInputs] = useState([]);
  const [isAllStation, setIsAllStation] = useState({ checked: false });
  const [inputStationCount, setInputStationCount] = useState(0);
  const [stationslist, setStationsList] = useState([]);
  const {
    campaignName = {},
    shortDescription = {},
    longDescription = {},
    campaignImage = {},
    voucherType = {},
    campaignGroup = {},
    voucherPrefix = {},
    campaignDuration = {},
    voucherValue = {},
    claimMethod = {},
    thirdPartyVoucherCode = {},
    voucherTypeSetting = {},
    recipients = {},
    voucherAmount = {},
    uploadCSV = {},
    uploadThirdPartyVoucherCSV = {},
    voucherValueMultiClaim = {},
    claimLimit = {},
    voucherDiscountValue = {},
    discountValue = {},
    discountCap = {},
    minimumSpend = {},
    requiredReferenceStation = {},
    voucherQuantity = {},
    quantity = {},
    billedTo = {},
    billedToOthers = {},
    remarks = {},
    multiCodeQuantity = {},
    thirdPartyVoucherValue = {},
    thirdPartyVoucherAutoSeed = {},
    thirdPartyVoucherCodes = {},
  } = fields || {};

  const validateMobileNumber = useApi({
    api: getUserByMobileNumber,
    handleOwnError: true,
  });

  const onChangeVoucherValue = useCallback(
    ({ index, amount, quantity }) => {
      const value = voucherValue.value || [];
      if (value[index]) {
        const currentValue = value[index];
        value[index] = {
          amount: {
            ...currentValue.amount,
            ...amount,
          },
          quantity: {
            ...currentValue.quantity,
            ...quantity,
          },
        };
        voucherValue.onChange(voucherValue.name, {
          value: value,
        });
      }
    },
    [voucherValue]
  );

  const onChangeUploadCsv = useCallback(
    async (name, value) => {
      const { value: file } = value;
      const csvValidation = [
        {
          text: "Mobile Number",
          key: "mobileNumber",
          validation: (text, row) => {
            const error = !isMobileNumberStrictValid(text);
            return {
              error,
              message: "Invalid mobile number",
            };
          },
        },
      ];
      if (voucherType.value === VoucherType.PesoVoucherCode) {
        csvValidation.push({
          text: "Voucher Value",
          key: "value",
          validation: (text, row) => {
            const error = !isNumber(text);
            return {
              error,
              message: "Invalid value",
            };
          },
        });
      }
      csvValidation.push({
        text: "Quantity",
        key: "amount",
        validation: (text, row) => {
          const error = !isNumber(text);
          return {
            error,
            message: "Invalid amount",
          };
        },
      });
      const validation = await parseCSVFile(
        file[0],
        csvValidation.filter((item) => item?.text)
      );
      if (validation.errors.length) {
        confirmModal.show({
          content: (
            <div
              style={{
                textAlign: "left",
              }}
            >
              <Text
                strong
                style={{
                  marginBottom: "20px",
                }}
              >
                There are errors in the file you uploaded. Kindly double-check the file and try
                again.
              </Text>
              {/* {validation.errors.map((error, index) => {
              return <div key={index}>{error}</div>;
            })} */}
            </div>
          ),
          primary: {
            text: "OK",
            onClick: () => {
              confirmModal.close();
            },
          },
        });
      } else {
        uploadCSV.onChange(name, value);
      }
    },
    [voucherType, uploadCSV, confirmModal]
  );

  const submitUploadThirdPartyVoucher = (file, data, importModal) => {
    uploadThirdPartyVoucherCSV.onChange("uploadThirdPartyVoucherCSV", { value: file.file });
    thirdPartyVoucherImportModalResult.show({
      title: locale.importSuccessful,
      children: (
        <div>
          <Text normal align="center" style={{ marginBottom: "20px", marginTop: "20px" }}>
            <locale.Populate
              text={locale.successfullyImportThirdPartyVoucher}
              items={[
                <b>
                  {voucherTypeSetting.value === VoucherTypeSetting.OneTimeClaim ||
                  voucherTypeSetting.value === VoucherTypeSetting.MultiClaimGeneric
                    ? `${data.length} ${locale.thirdPartyVoucherModalSuccess}`
                    : `${data.length} ${locale.recipients}`}
                </b>,
              ]}
            />
          </Text>
          <ActionButton
            center
            items={[
              {
                text: locale.gotIt,
                primary: true,
                onClick: () => {
                  thirdPartyVoucherImportModalResult.close();
                },
              },
            ]}
          />
        </div>
      ),
    });
    importModal.close();
  };

  const onChangeVoucherAmount = useCallback(
    ({ index, amount, quantity, mobileNumber }) => {
      const value = voucherAmount.value || [];
      if (value[index]) {
        const currentValue = value[index];
        value[index] = {
          amount: {
            ...currentValue.amount,
            ...amount,
          },
          quantity: {
            ...currentValue.quantity,
            ...quantity,
          },
          mobileNumber: {
            ...currentValue.mobileNumber,
            ...mobileNumber,
          },
        };
        voucherAmount.onChange(voucherAmount.name, {
          value: value,
        });
      }
    },
    [voucherAmount]
  );

  const onChangeThirdPartyVoucherAutoSeed = useCallback(
    ({ index, thirdPartyVoucherCode, mobileNumber }) => {
      const value = thirdPartyVoucherAutoSeed.value || [];
      if (value[index]) {
        const currentValue = value[index];
        value[index] = {
          thirdPartyVoucherCode: {
            ...currentValue.thirdPartyVoucherCode,
            ...thirdPartyVoucherCode,
          },
          mobileNumber: {
            ...currentValue.mobileNumber,
            ...mobileNumber,
          },
          quantity: {
            value: 1,
          },
        };
        thirdPartyVoucherAutoSeed.onChange(thirdPartyVoucherAutoSeed.name, {
          value: value,
        });
      }
    },
    [thirdPartyVoucherAutoSeed]
  );

  const onChangeThirdPartyVoucherValue = useCallback(
    ({ index, thirdPartyVoucherCode }) => {
      const value = thirdPartyVoucherValue.value || [];
      if (value[index]) {
        const currentValue = thirdPartyVoucherCode.value || [];
        value[index] = {
          quantity: {
            value: 1,
          },
          thirdPartyVoucherCode: {
            ...currentValue.thirdPartyVoucherCode,
            ...thirdPartyVoucherCode,
          },
        };
        thirdPartyVoucherValue.onChange(thirdPartyVoucherValue.name, {
          value: value,
        });
      }
    },
    [thirdPartyVoucherValue]
  );

  const onChangeVoucherType = useCallback(
    ({ voucherType, voucherTypeSetting }) => {
      const isAutoSeed = voucherTypeSetting.value === VoucherTypeSetting.AutoSeed;
      const showRecipients = isAutoSeed;
      const isMultiClaimGeneric =
        voucherTypeSetting.value === VoucherTypeSetting.MultiClaimGeneric ||
        voucherTypeSetting.value === VoucherTypeSetting.MultiCodeGeneric;
      const isMultiCodeGeneric = voucherTypeSetting.value === VoucherTypeSetting.MultiCodeGeneric;
      const isPesoDiscount =
        voucherType.value === VoucherType.PesoDiscount ||
        voucherType.value === VoucherType.LOCQPayPesoDiscountVoucher;
      const showVoucherQuantity =
        isPesoDiscount && voucherTypeSetting.value === VoucherTypeSetting.OneTimeClaim;
      const showVoucherAmount = isAutoSeed;
      const showVoucherValue =
        !isMultiClaimGeneric && voucherType.value === VoucherType.PesoVoucherCode && !isAutoSeed;
      // const showQuantity =
      //   isPesoDiscount && voucherTypeSetting.value === VoucherTypeSetting.OneTimeClaim;
      const isThirdPartyVoucher = voucherType.value === VoucherType.ThirdPartyVoucher;
      const showQuantity = false;
      const showThirdPartyVoucherCode =
        voucherType.value === VoucherType.ThirdPartyVoucher &&
        (voucherTypeSetting.value === VoucherTypeSetting.OneTimeClaim ||
          voucherTypeSetting.value === VoucherTypeSetting.MultiClaimGeneric);
      const showThirdPartyVoucherValue =
        voucherType.value === VoucherType.ThirdPartyVoucher &&
        (voucherTypeSetting.value === VoucherTypeSetting.OneTimeClaim ||
          voucherTypeSetting.value === VoucherTypeSetting.MultiClaimGeneric);
      const showThirdPartyVoucherAutoSeed =
        voucherType.value === VoucherType.ThirdPartyVoucher && isAutoSeed;

      modifyForm(
        {
          voucherType,
          voucherTypeSetting,
          recipients: {
            visible: showRecipients,
            value: VoucherRecipients.SelectedAccounts,
            isDirty: false,
          },
          claimMethod: {
            value:
              showRecipients || isPesoDiscount || showThirdPartyVoucherValue
                ? ClaimMethod.SeedInVoucherTray
                : "",
            validations: {
              ...claimMethod.validations,
              isRequired: !showRecipients && !isPesoDiscount,
            },
            isDirty: false,
            content:
              isPesoDiscount || isAutoSeed || showThirdPartyVoucherValue
                ? prettifyVoucherClaimMethod(ClaimMethod.SeedInVoucherTray)
                : null,
          },
          thirdPartyVoucherCode: {
            validations: {
              ...thirdPartyVoucherCode.validations,
            },
            value: ThirdPartyVoucherCode.ManualInput,
            isDirty: false,
            visible: showThirdPartyVoucherCode,
          },
          thirdPartyVoucherValue: {
            canAdd: showThirdPartyVoucherCode,
            value: showThirdPartyVoucherValue
              ? thirdPartyVoucherValue.value[0]
                ? [thirdPartyVoucherValue.value[0]]
                : []
              : thirdPartyVoucherValue.value || [],
            visible: showThirdPartyVoucherValue,
            validations: {
              ...thirdPartyVoucherValue.validations,
              isRequired: showThirdPartyVoucherValue,
            },
            isDirty: false,
          },
          thirdPartyVoucherAutoSeed: {
            visible: showThirdPartyVoucherAutoSeed,
            value: showThirdPartyVoucherAutoSeed ? [thirdPartyVoucherAutoSeedState] : [],
            validations: {
              ...thirdPartyVoucherAutoSeed.validations,
              isRequired: showThirdPartyVoucherAutoSeed,
            },
            isDirty: false,
          },
          voucherValue: {
            canAdd: !showRecipients && !isMultiClaimGeneric,
            value: showVoucherValue
              ? voucherValue.value[0]
                ? [voucherValue.value[0]]
                : []
              : voucherValue.value || [],
            visible: showVoucherValue && !isThirdPartyVoucher,
            validations: {
              ...voucherValue.validations,
              isRequired: showVoucherValue && !isThirdPartyVoucher,
            },
            isDirty: false,
          },
          voucherAmount: {
            visible: showVoucherAmount && !isThirdPartyVoucher,
            value: showVoucherAmount ? [voucherAmountState] : [],
            validations: {
              ...voucherAmount.validations,
              isRequired: showVoucherAmount && !isThirdPartyVoucher,
            },
            isDirty: false,
          },
          uploadCSV: {
            value: [],
            visible: false,
            validations: {
              ...uploadCSV.validations,
              isRequired: false,
            },
            isDirty: false,
          },
          uploadThirdPartyVoucherCSV: {
            value: [],
            visible: false,
            validations: {
              ...uploadThirdPartyVoucherCSV.validations,
              isRequired: false,
            },
            isDirty: false,
          },
          voucherValueMultiClaim: {
            value: "",
            validations: {
              ...voucherValueMultiClaim.validations,
              isRequired: isMultiClaimGeneric && !isPesoDiscount && !isThirdPartyVoucher,
            },
            visible: isMultiClaimGeneric && !isPesoDiscount && !isThirdPartyVoucher,
            isDirty: false,
          },
          claimLimit: {
            ...claimLimit,
            value: "",
            validations: {
              ...claimLimit.validations,
              isRequired: isMultiClaimGeneric,
            },
            visible: isMultiClaimGeneric,
            isDirty: false,
          },
          multiCodeQuantity: {
            ...multiCodeQuantity,
            value: "",
            validations: {
              ...multiCodeQuantity.validations,
              isRequired: isMultiCodeGeneric || (isThirdPartyVoucher && isMultiClaimGeneric),
            },
            visible: isMultiCodeGeneric || (isThirdPartyVoucher && isMultiClaimGeneric),
            isDirty: false,
          },
          voucherDiscountValue: {
            value: "",
            visible: isPesoDiscount,
            validations: {
              isRequired: isPesoDiscount,
            },
            isDirty: false,
          },
          discountValue: {
            isDirty: false,
            value: "",
            validations: {
              ...discountValue.validations,
              isRequired: isPesoDiscount,
            },
          },
          discountCap: {
            value: "",
            visible: isPesoDiscount,
            validations: {
              ...discountCap.validations,
              isRequired: isPesoDiscount,
            },
            isDirty: false,
          },
          minimumSpend: {
            value: "",
            visible: isPesoDiscount,
            validations: {
              ...minimumSpend.validations,
              isRequired: isPesoDiscount,
            },
            isDirty: false,
          },
          voucherQuantity: {
            value: "",
            visible: showVoucherQuantity,
            validations: {
              ...voucherQuantity.validations,
              isRequired: showVoucherQuantity,
            },
            isDirty: false,
          },
          requiredReferenceStation: {
            isDirty: false,
            value: [],
            visible: isPesoDiscount,
            validations: {
              custom: [
                (value, field) => {
                  if (isPesoDiscount) {
                    const error = value.length === 0 && !field.isAll;
                    return {
                      error,
                      message: error ? "This field is required." : "",
                    };
                  }
                  return {
                    error: false,
                    message: "",
                  };
                },
              ],
            },
          },
          quantity: {
            isDirty: false,
            value: "",
            visible: showQuantity,
            validations: {
              ...quantity.validations,
              isRequired: showQuantity,
            },
          },
        },
        false
      );
    },
    [
      modifyForm,
      claimMethod.validations,
      thirdPartyVoucherCode.validations,
      thirdPartyVoucherValue.value,
      thirdPartyVoucherValue.validations,
      thirdPartyVoucherAutoSeed.validations,
      voucherValue.value,
      voucherValue.validations,
      voucherAmount.validations,
      uploadCSV.validations,
      uploadThirdPartyVoucherCSV.validations,
      voucherValueMultiClaim.validations,
      claimLimit,
      multiCodeQuantity,
      discountValue.validations,
      discountCap.validations,
      minimumSpend.validations,
      voucherQuantity.validations,
      quantity.validations,
    ]
  );

  const onChangeBilledTo = useCallback(
    ({ billedTo }) => {
      modifyForm({
        billedTo: {
          ...billedTo,
        },
        billedToOthers: {
          validations: {
            isRequired: billedTo.value === BilledTo.Others,
          },
        },
      });
    },
    [modifyForm]
  );

  const {
    filterState,
    requestState,
    submitFilter,
    modifyFilters,
    filterCount,
    modifyFilter,
    clearFilter,
    submittedFilter,
  } = useFilter(TargetStationsFilterState());

  const { request: getStationsRequest, loading: loadingStations } = useApi({
    api: getStations,
    modalError: true,
    params: {
      platformType: "plc",
    },
  });

  const stationData = usePagination();
  const fetchStations = useCallback(
    async (rs = { ...requestState }, perPage) => {
      if (rs.reset) {
        stationData.reset();
      }
      if (stationData.canLoadMore || rs.reset) {
        const result = await getStationsRequest(
          submitFilter({
            perPage: perPage ? perPage : 10,
            ...rs,
            page: rs.reset ? 1 : stationData.page,
          }),
          null
        );
        stationData.onLoadMore({ data: result.stations, total: result.count, resetPage: rs.reset });

        // saves the count for the first render only
        if (inputStationCount === 0) {
          setInputStationCount(result?.count);
          setStationsList(result?.stations);
        }
        return result;
      }
    },
    [getStationsRequest, requestState, inputStationCount, submitFilter, stationData]
  );

  const selectStationsCb = useCallback(
    (stationParams) => {
      let newStations = [];
      let isAllSelected = false;

      const stationSelected = stationInputs.some((station) => {
        return station.value === stationParams.value;
      });
      if (stationSelected) {
        newStations = stationInputs.filter((station) => station.value !== stationParams.value);
      } else {
        const clone = [...stationInputs];
        newStations = [...clone, stationParams];
      }

      if (inputStationCount === newStations.length) {
        isAllSelected = true;
      }

      setIsAllStation({ checked: isAllSelected });
      setStationInputs(newStations);
    },
    [inputStationCount, stationInputs]
  );

  const onClearSelectedStation = useCallback(() => {
    modifyForm({
      requiredReferenceStation: {
        value: [],
      },
    });
    setStationInputs([]);
  }, [modifyForm, setStationInputs]);

  const saveStationsCb = useCallback(() => {
    modifyForm({
      requiredReferenceStation: {
        value: stationInputs,
      },
    });
  }, [modifyForm, stationInputs]);

  const targetStationsProps = {
    filterState,
    modifyFilters,
    filterCount,
    modifyFilter,
    clearFilter,
    selectStationsCb,
    stationInputs,
    setStationInputs,
    saveStationsCb,
    fetchStations,
    loadingStations,
    isAllStation,
    setIsAllStation,
    inputStationCount,
    stationslist,
    setStationsList,
    submittedFilter,
    stationData,
    onClearSelectedStation,
  };

  return (
    <div>
      <VoucherSkeleton
        intro={intro}
        title={title}
        loading={loading}
        fields={{
          campaignName: {
            ...campaignName,
            content: campaignName.content || (
              <TextField
                {...campaignName}
                onChange={(name, value) => {
                  campaignName.onChange(name, {
                    ...value,
                    errorType: "",
                  });
                }}
              />
            ),
          },
          shortDescription: {
            ...shortDescription,
            content: shortDescription.content || <TextField {...shortDescription} />,
          },
          longDescription: {
            ...longDescription,
            content: longDescription.content || <TextField {...longDescription} />,
          },
          campaignImage: {
            ...campaignImage,
            content: campaignImage.content || (
              <UploadFile
                {...campaignImage}
                acceptInvalid
                file={["png", "jpg", "jpeg"]}
                maxSizeMB={2}
              />
            ),
          },
          uploadCSV: {
            ...uploadCSV,
            content: uploadCSV.content || (
              <UploadFile
                {...uploadCSV}
                text="Upload"
                image={false}
                errorMessage="File type not accepted. Please upload a .csv file."
                onChange={onChangeUploadCsv}
                file={["csv"]}
                accept={{
                  "text/csv": [".csv"],
                }}
              />
            ),
          },
          recipients: {
            ...recipients,
            content: recipients.content || (
              <Radio
                style={{
                  width: "100%",
                  alignItems: "flex-start !important",
                }}
                {...recipients}
                onChange={(name, { value }) => {
                  const showVoucherAmount = value === VoucherRecipients.SelectedAccounts;
                  const showVoucherValue =
                    voucherType.value === VoucherType.PesoVoucherCode &&
                    value === VoucherRecipients.All;
                  const showUploadCSV = value === VoucherRecipients.UploadCsv;
                  const showQuantity =
                    voucherType.value === VoucherType.PesoDiscount ||
                    (voucherType.value === VoucherType.LOCQPayPesoDiscountVoucher &&
                      value === VoucherRecipients.All);
                  const isThirdPartyVoucher = voucherType.value === VoucherType.ThirdPartyVoucher;
                  const showThirdPartyVoucherAutoSeed =
                    value === VoucherRecipients.SelectedAccounts;
                  modifyForm({
                    recipients: {
                      value,
                    },
                    quantity: {
                      value: "",
                      visible: showQuantity,
                      validations: {
                        ...quantity.validations,
                        isRequired: showQuantity && !isThirdPartyVoucher,
                      },
                    },
                    voucherAmount: {
                      visible: showVoucherAmount && !isThirdPartyVoucher,
                      value: showVoucherAmount ? [voucherAmountState] : [],
                      validations: {
                        ...voucherAmount.validations,
                        isRequired: showVoucherAmount && !isThirdPartyVoucher,
                      },
                    },
                    voucherValue: {
                      visible: showVoucherValue && !isThirdPartyVoucher,
                      value: [voucherValueState],
                      validations: {
                        ...voucherValue.validations,
                        isRequired: showVoucherValue && !isThirdPartyVoucher,
                      },
                    },
                    thirdPartyVoucherAutoSeed: {
                      visible: showThirdPartyVoucherAutoSeed && isThirdPartyVoucher,
                      value: showThirdPartyVoucherAutoSeed ? [thirdPartyVoucherAutoSeedState] : [],
                      validations: {
                        ...thirdPartyVoucherAutoSeed.validations,
                        isRequired: showThirdPartyVoucherAutoSeed,
                      },
                    },
                    uploadCSV: {
                      value: [],
                      visible: showUploadCSV && !isThirdPartyVoucher,
                      validations: {
                        isRequired: showUploadCSV && !isThirdPartyVoucher,
                      },
                    },
                    uploadThirdPartyVoucherCSV: {
                      value: [],
                      visible: showUploadCSV && isThirdPartyVoucher,
                      validations: {
                        isRequired: showUploadCSV && isThirdPartyVoucher,
                      },
                    },
                  });
                }}
                options={[
                  // {
                  //   value: VoucherRecipients.All,
                  //   text: locale.all,
                  // },
                  {
                    value: VoucherRecipients.SelectedAccounts,
                    text: locale.selectedAccounts,
                  },
                  {
                    value: VoucherRecipients.UploadCsv,
                    text: locale.uploadCsv,
                  },
                ]}
              />
            ),
          },
          voucherDiscountValue: {
            ...voucherDiscountValue,
            content: voucherDiscountValue.content || (
              <Radio
                style={{
                  width: "100%",
                  alignItems: "flex-start !important",
                }}
                vertical
                {...voucherDiscountValue}
                onChange={(name, { value }) => {
                  const isPesoDiscount = value === VoucherDiscountType.Percent;
                  modifyForm({
                    [name]: { value },
                    discountCap: {
                      visible: isPesoDiscount,
                      validations: {
                        ...discountCap.validations,
                        isRequired: isPesoDiscount,
                      },
                    },
                    discountValue: {
                      value: "",
                    },
                  });
                  // voucherDiscountValue.onChange(name, { value });
                }}
                options={[
                  {
                    value: VoucherDiscountType.Percent,
                    text: locale.percentDiscount,
                  },
                  {
                    value: VoucherDiscountType.Specific,
                    text: locale.specificAmount,
                  },
                ].map((item, key) => {
                  return {
                    ...item,
                    content: (
                      <div
                        style={{
                          marginLeft: "30px",
                          width: "150px",
                        }}
                        key={key}
                      >
                        <Field {...discountValue} style={{ margin: "0px" }}>
                          <InputAmount
                            {...discountValue}
                            maxLength={
                              voucherDiscountValue.value === VoucherDiscountType.Percent ? 3 : null
                            }
                            onChange={(name, { value }) => {
                              if (
                                voucherDiscountValue.value === VoucherDiscountType.Specific ||
                                (voucherDiscountValue.value === VoucherDiscountType.Percent &&
                                  !value.includes("."))
                              ) {
                                discountValue.onChange(name, { value });
                              }
                            }}
                            innerLabel
                            prefix={
                              voucherDiscountValue.value === VoucherDiscountType.Percent ? "%" : "₱"
                            }
                          />
                        </Field>
                      </div>
                    ),
                  };
                })}
              />
            ),
          },
          voucherType: {
            ...voucherType,
            content: voucherType.content || (
              <Radio
                style={{
                  width: "100%",
                  alignItems: "flex-start !important",
                }}
                vertical
                {...voucherType}
                onChange={(name, { value }) => {
                  onChangeVoucherType({
                    voucherType: {
                      value,
                    },
                    voucherTypeSetting: {
                      value: VoucherTypeSetting.OneTimeClaim,
                    },
                  });
                }}
                options={[
                  VoucherType.PesoVoucherCode,
                  VoucherType.PesoDiscount,
                  VoucherType.ThirdPartyVoucher,
                  VoucherType.LOCQPayPesoDiscountVoucher,
                ].map((item, key) => {
                  const options = [
                    {
                      value: VoucherTypeSetting.OneTimeClaim,
                      text: locale.oneTimeClaim,
                    },
                    {
                      value: VoucherTypeSetting.AutoSeed,
                      text: locale.autoSeed,
                    },
                  ];

                  if (voucherType.value !== VoucherType.ThirdPartyVoucher) {
                    options.push({
                      value: VoucherTypeSetting.MultiClaimGeneric,
                      text: locale.multiClaimGeneric,
                    });
                  }

                  if (voucherType.value === VoucherType.LOCQPayPesoDiscountVoucher) {
                    options.push({
                      value: VoucherTypeSetting.MultiCodeGeneric,
                      text: locale.multiCodeGeneric,
                    });
                  }

                  return {
                    value: item,
                    text: prettifyVoucherType(item),
                    content: (
                      <div
                        style={{
                          marginLeft: "30px",
                        }}
                        key={key}
                      >
                        <Radio
                          style={{
                            width: "100%",
                            alignItems: "flex-start !important",
                          }}
                          vertical
                          {...voucherTypeSetting}
                          onChange={(name, { value }) => {
                            onChangeVoucherType({
                              voucherType: {
                                value: voucherType.value,
                              },
                              voucherTypeSetting: {
                                value,
                              },
                            });
                          }}
                          options={options}
                        />
                      </div>
                    ),
                  };
                })}
              />
            ),
          },
          campaignGroup: {
            ...campaignGroup,
            content: campaignGroup.content || (
              <Autocomplete
                {...campaignGroup}
                options={[
                  VoucherCampaignGroup.Others,
                  VoucherCampaignGroup.SPIAllowance,
                  VoucherCampaignGroup.SPIAllocation,
                ].map((item) => ({
                  value: item,
                  label: prettifyVoucherCampaignGroup(item),
                }))}
              />
            ),
          },
          voucherPrefix: {
            ...voucherPrefix,
            content: voucherPrefix.content || (
              <TextField
                {...voucherPrefix}
                onChange={(name, value) => {
                  voucherPrefix.onChange(name, {
                    ...value,
                    value: capitalizeAll(value?.value.toString()),
                    errorType: "",
                  });
                }}
              />
            ),
          },
          campaignDuration: {
            ...campaignDuration,
            content: campaignDuration.content || (
              <div
                style={{
                  width: "250px",
                }}
              >
                <DateRange {...campaignDuration} />
              </div>
            ),
          },
          voucherValue: {
            ...voucherValue,
            content: voucherValue.content || (
              <div>
                {voucherValue.value?.map((value = {}, index) => {
                  const { amount = {}, quantity = {} } = value;
                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "flex-start",
                      }}
                      key={index}
                    >
                      <div
                        style={{
                          width: "150px",
                        }}
                      >
                        <Field
                          {...amount}
                          labelPosition="left"
                          error={amount.error}
                          labelStyle={{
                            marginRight: "20px",
                          }}
                          style={{
                            margin: "10px 0px",
                          }}
                        >
                          <InputAmount
                            innerLabel
                            value={amount.value}
                            error={amount.error}
                            onChange={(name, { value }) => {
                              let hasError = "";
                              const val = parseNumber(value);
                              if (value && val < 1) {
                                hasError = `Minimum of 1`;
                              }
                              if (value && val > 100000) {
                                hasError = `Maximum of 100,000`;
                              }
                              if (amount.validation({ value }) || hasError || !value)
                                onChangeVoucherValue({
                                  amount: {
                                    value,
                                    error: Boolean(hasError),
                                    message: hasError,
                                  },
                                  quantity,
                                  index,
                                });
                            }}
                          />
                        </Field>
                      </div>
                      <div
                        style={{
                          width: "220px",
                          marginLeft: "20px",
                        }}
                      >
                        <Field
                          {...quantity}
                          labelPosition="left"
                          error={quantity.error}
                          label={locale.quantity}
                          labelStyle={{
                            marginRight: "20px",
                            marginTop: "10px",
                          }}
                          style={{
                            margin: "10px 0px",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                          }}
                        >
                          <TextField
                            value={quantity.value}
                            error={quantity.error}
                            onChange={(name, { value }) => {
                              let hasError = "";
                              const val = !isNaN(parseInt(value)) ? parseNumber(value) : "";
                              if (value && val?.toString() && val < 1) {
                                hasError = `Minimum of 1`;
                              }
                              if (value && val && val > 100000) {
                                hasError = `Maximum of 100,000`;
                              }
                              if (!value || quantity.validation({ value }) || hasError)
                                onChangeVoucherValue({
                                  amount,
                                  quantity: {
                                    value,
                                    error: Boolean(hasError) || !value.trim(),
                                    message: hasError,
                                  },
                                  index,
                                });
                            }}
                          />
                        </Field>
                      </div>
                      {voucherValue.value.length > 1 && (
                        <RemoveItemButton
                          onClick={() => {
                            const items = [...voucherValue.value];
                            items.splice(index, 1);
                            voucherValue.onChange(voucherValue.name, {
                              value: [...items],
                            });
                          }}
                        />
                      )}
                    </div>
                  );
                })}
                {voucherValue.canAdd && (
                  <Button
                    style={{
                      marginTop: "10px",
                    }}
                    outline
                    onClick={() => {
                      const error = validateVoucherValue(voucherValue.value);
                      if (!error) {
                        voucherValue.onChange(voucherValue.name, {
                          value: [...voucherValue.value, voucherValueState],
                        });
                      } else {
                        voucherValue.onChange(voucherValue.name, {
                          value: voucherValue.value.map((item) => {
                            return {
                              amount: {
                                ...item.amount,
                                error: !parseInt(item.amount.value),
                              },
                              quantity: {
                                ...item.quantity,
                                error: !parseInt(item.quantity.value),
                              },
                            };
                          }),
                        });
                      }
                    }}
                  >
                    {locale.addDenomination}
                  </Button>
                )}
              </div>
            ),
          },
          voucherAmount: {
            ...voucherAmount,
            content: voucherAmount.content || (
              <div>
                <Grid container spacing={1}>
                  <Grid item xs={5}>
                    <Text subtitle>{locale.priceLocqAccount}</Text>
                  </Grid>
                  {voucherType.value === VoucherType.PesoVoucherCode && (
                    <Grid item xs={4}>
                      <Text subtitle>{locale.voucherValue}</Text>
                    </Grid>
                  )}

                  <Grid item xs={3}>
                    <Text subtitle>{locale.quantity}</Text>
                  </Grid>
                </Grid>
                {voucherAmount.value?.map((value = {}, index) => {
                  const { amount, quantity, mobileNumber } = value;
                  return (
                    <Grid container spacing={1} key={index}>
                      <Grid item xs={5}>
                        <Field
                          style={{
                            margin: "0px",
                          }}
                          error={mobileNumber.error}
                          message={mobileNumber.message}
                        >
                          <TextField
                            value={mobileNumber.value}
                            maxLength={10}
                            error={mobileNumber.error}
                            placeholder="format: 9192345678"
                            disabled={validateMobileNumber.loading}
                            onChange={(name, { value }) => {
                              if (mobileNumber.validation({ value }) || !value) {
                                const invalidMobileNumber = value.length !== 10 || value[0] !== "9";

                                onChangeVoucherAmount({
                                  amount,
                                  quantity,
                                  mobileNumber: {
                                    value,
                                    error: invalidMobileNumber,
                                    message:
                                      invalidMobileNumber && value
                                        ? locale.pleaseInputValidPriceLOCQAccount
                                        : "",
                                  },
                                  index,
                                });
                              }
                            }}
                            onBlur={async (name, { value }) => {
                              if (!mobileNumber.error) {
                                try {
                                  const res = await validateMobileNumber.request({
                                    mobileNumber: `0${value}`,
                                  });
                                  const invalid = ["deactivated", "deleted"].includes(
                                    res?.data?.status
                                  );
                                  onChangeVoucherAmount({
                                    amount,
                                    quantity,
                                    mobileNumber: {
                                      value,
                                      error: invalid,
                                      message: invalid
                                        ? locale.pleaseInputValidPriceLOCQAccount
                                        : "",
                                    },
                                    index,
                                  });
                                } catch (e) {
                                  const { errorCode } = e?.data || {};
                                  if (errorCode === "P1005") {
                                    onChangeVoucherAmount({
                                      amount,
                                      quantity,
                                      mobileNumber: {
                                        value,
                                        error: true,
                                        message: locale.pleaseInputValidPriceLOCQAccount,
                                        errorType: "not-found",
                                      },
                                      index,
                                    });
                                  }
                                }
                              }
                            }}
                            prefix={
                              <div>
                                <Text
                                  style={{
                                    fontSize: "14px",
                                  }}
                                >
                                  +63
                                </Text>
                              </div>
                            }
                          />
                        </Field>
                      </Grid>
                      {voucherType.value === VoucherType.PesoVoucherCode && (
                        <Grid item xs={4}>
                          <InputAmount
                            innerLabel
                            value={amount.value}
                            error={amount.error}
                            disabled={validateMobileNumber.loading}
                            onChange={(name, { value }) => {
                              let hasError = "";
                              const val = parseNumber(value);
                              if (value && val < 1) {
                                hasError = `Minimum of 1`;
                              }
                              if (value && val > 100000) {
                                hasError = `Maximum of 100,000`;
                              }
                              if (amount.validation({ value }) || hasError || !value)
                                onChangeVoucherAmount({
                                  amount: {
                                    value,
                                    error: Boolean(hasError),
                                    message: hasError,
                                  },
                                  quantity,
                                  mobileNumber,
                                  index,
                                });
                            }}
                          />
                        </Grid>
                      )}
                      <Grid
                        item
                        xs={3}
                        style={{
                          display: "flex",
                        }}
                      >
                        <Field
                          {...quantity}
                          style={{
                            margin: "0px",
                          }}
                          labelStyle={{
                            padding: "0px",
                            margin: "0px",
                          }}
                        >
                          <TextField
                            value={quantity.value}
                            error={quantity.error}
                            disabled={validateMobileNumber.loading}
                            onChange={(name, { value }) => {
                              let hasError = "";
                              const val = parseNumber(value);
                              const isNumeric = quantity.validation({ value });
                              if (isNumeric && val < 1) {
                                hasError = `Minimum of 1`;
                              }
                              if (isNumeric && val > 100000) {
                                hasError = `Maximum of 100,000`;
                              }
                              if (isNumeric || hasError || !value)
                                onChangeVoucherAmount({
                                  amount,
                                  quantity: {
                                    value,
                                    error: Boolean(hasError) || !value,
                                    message: hasError,
                                  },
                                  mobileNumber,
                                  index,
                                });
                            }}
                          />
                        </Field>
                        {voucherAmount.value.length > 1 && (
                          <RemoveItemButton
                            onClick={() => {
                              const items = [...voucherAmount.value];
                              items.splice(index, 1);
                              voucherAmount.onChange(voucherAmount.name, {
                                value: [...items],
                              });
                            }}
                          />
                        )}
                      </Grid>
                    </Grid>
                  );
                })}
                {voucherAmount.canAdd && (
                  <Button
                    style={{
                      marginTop: "10px",
                    }}
                    outline
                    disabled={validateMobileNumber.loading}
                    onClick={() => {
                      const error = validateVoucherAmount(voucherAmount.value, {
                        isAmountRequired: voucherType.value === VoucherType.PesoVoucherCode,
                      });
                      if (!error) {
                        voucherAmount.onChange(voucherAmount.name, {
                          value: [...voucherAmount.value, voucherAmountState],
                        });
                      } else {
                        voucherAmount.onChange(voucherAmount.name, {
                          value: voucherAmount.value.map((item) => {
                            return {
                              amount: {
                                ...item.amount,
                                error:
                                  voucherType.value === VoucherType.PesoVoucherCode
                                    ? !parseInt(item.amount.value)
                                    : false,
                              },
                              quantity: {
                                ...item.quantity,
                                error: !parseInt(item.quantity.value),
                              },
                              mobileNumber: {
                                ...item.mobileNumber,
                                error:
                                  !isNumeric(item.mobileNumber.value) ||
                                  item.mobileNumber.value.length !== 10 ||
                                  item.mobileNumber.value[0] !== "9" ||
                                  item.mobileNumber.errorType === "not-found",
                              },
                            };
                          }),
                        });
                      }
                    }}
                  >
                    {locale.addRow}
                  </Button>
                )}
              </div>
            ),
          },
          voucherValueMultiClaim: {
            ...voucherValueMultiClaim,
            content: voucherValueMultiClaim.content || (
              <div
                style={{
                  width: "200px",
                }}
              >
                <InputAmount innerLabel {...voucherValueMultiClaim} />
              </div>
            ),
          },
          voucherQuantity: {
            ...voucherQuantity,
            content: voucherQuantity.content || (
              <div
                style={{
                  width: "200px",
                }}
              >
                <TextField {...voucherQuantity} />
              </div>
            ),
          },
          quantity: {
            ...quantity,
            content: quantity.content || (
              <div
                style={{
                  width: "200px",
                }}
              >
                <TextField {...quantity} />
              </div>
            ),
          },
          claimLimit: {
            ...claimLimit,
            content: claimLimit.content || (
              <div
                style={{
                  width: "200px",
                }}
              >
                <TextField {...claimLimit} />
              </div>
            ),
          },
          multiCodeQuantity: {
            ...multiCodeQuantity,
            content: multiCodeQuantity.content || (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div style={{ width: "390px", marginRight: "20px" }}>
                  <TextField {...multiCodeQuantity} />
                </div>
                <Field label={locale.totalVouchers} horizontal>
                  <Text style={{ marginLeft: "10px" }}>
                    {Big(Number(fields?.claimLimit?.value || 0))
                      .mul(Number(fields?.multiCodeQuantity?.value || 0))
                      .toString() ?? "0"}
                  </Text>
                </Field>
              </div>
            ),
          },
          discountCap: {
            ...discountCap,
            content: discountCap.content || (
              <div
                style={{
                  width: "200px",
                }}
              >
                <InputAmount {...discountCap} innerLabel />
              </div>
            ),
          },
          minimumSpend: {
            ...minimumSpend,
            content: minimumSpend.content || (
              <div
                style={{
                  width: "200px",
                }}
              >
                <InputAmount {...minimumSpend} innerLabel />
              </div>
            ),
          },
          requiredReferenceStation: {
            ...requiredReferenceStation,
            label:
              voucherType.value === VoucherType.LOCQPayPesoDiscountVoucher
                ? locale.eligibleGasUpStation
                : locale.eligibleReferenceStation,
            content: requiredReferenceStation.content || (
              <div className={styles.stationReference}>
                {/* <SelectStation {...requiredReferenceStation} isPlcOnboarded /> */}
                {fields?.requiredReferenceStation?.value?.length === 0 ? (
                  <>
                    <Button
                      startIcon={<span className="icon-pencil" />}
                      exportButton
                      onClick={() => {
                        stationsModal.show({});
                        fetchStations({ reset: true, perPage: 10 });
                      }}
                    >
                      {locale.selectStations}
                    </Button>
                    <Button
                      startIcon={<GetAppRoundedIcon />}
                      exportButton
                      onClick={() => {
                        importStationModal.show({});
                        fetchStations({ reset: true, perPage: 1000 });
                      }}
                    >
                      {locale.importCSV}
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      startIcon={<span className="icon-pencil" />}
                      exportButton
                      onClick={() => {
                        stationsModal.show({});
                        setStationInputs(fields?.requiredReferenceStation?.value);
                        fetchStations({ reset: true, perPage: 10 });
                      }}
                    >
                      {locale.editStations}
                    </Button>
                    <Button
                      startIcon={<GetAppRoundedIcon />}
                      exportButton
                      onClick={() => {
                        importStationModal.show({});
                        setStationInputs(fields?.requiredReferenceStation?.value);
                        fetchStations({ reset: true, perPage: 1000 });
                      }}
                    >
                      {locale.import}
                    </Button>
                  </>
                )}

                {fields?.requiredReferenceStation?.value?.length > 0 && (
                  <>
                    <span className={styles.numberStationSelected}>
                      {
                        <locale.Populate
                          text={locale.stationsEligibleSelected}
                          items={[fields?.requiredReferenceStation?.value?.length]}
                        />
                      }
                    </span>
                    <span
                      onClick={() => {
                        onClearSelectedStation();
                      }}
                      className={styles.removeCohort}
                    >
                      <Image src={RemoveIcon} />
                    </span>
                  </>
                )}
              </div>
            ),
          },
          thirdPartyVoucherValue: {
            ...thirdPartyVoucherValue,
            content: thirdPartyVoucherValue.content || (
              <div>
                {thirdPartyVoucherValue.value?.map((value = {}, index, array) => {
                  const { thirdPartyVoucherCode } = value;
                  const currentIndex = index;
                  return (
                    <Grid container spacing={1} key={index}>
                      <Grid item xs={5}>
                        <Field
                          style={{
                            margin: "0px",
                          }}
                          error={thirdPartyVoucherCode.error}
                          message={thirdPartyVoucherCode.message}
                        >
                          <TextField
                            value={thirdPartyVoucherCode.value}
                            maxLength={30}
                            error={thirdPartyVoucherCode.error}
                            placeholder={locale.enterVoucherCode}
                            disabled={thirdPartyVoucherCode.loading}
                            onChange={(name, { value }) => {
                              const invalidVoucherCode = value.length <= 4 || value.length > 30;
                              onChangeThirdPartyVoucherValue({
                                thirdPartyVoucherCode: {
                                  value,
                                  error: invalidVoucherCode,
                                  message:
                                    invalidVoucherCode && value
                                      ? locale.pleaseInputValidVoucherCode
                                      : "",
                                },
                                index,
                              });
                            }}
                            onBlur={async (name, { value }) => {
                              if (!thirdPartyVoucherCode.error) {
                                try {
                                  let inputValue = value;
                                  const invalidVoucherCode = value.length <= 4 || value.length > 30;

                                  const isSameVoucherCode = thirdPartyVoucherValue?.value?.find(
                                    (value = {}, index) => {
                                      const { thirdPartyVoucherCode } = value;
                                      if (currentIndex !== index) {
                                        if (thirdPartyVoucherCode.value === inputValue) {
                                          return true;
                                        }
                                        return false;
                                      }
                                      return false;
                                    }
                                  );

                                  onChangeThirdPartyVoucherValue({
                                    thirdPartyVoucherCode: {
                                      value,
                                      error: invalidVoucherCode || isSameVoucherCode,
                                      message:
                                        invalidVoucherCode && inputValue
                                          ? locale.pleaseInputValidVoucherCode
                                          : isSameVoucherCode
                                          ? locale.thirdPartyVoucherCodesExist
                                          : "",
                                    },
                                    index,
                                  });
                                } catch (e) {}
                              }
                            }}
                          />
                        </Field>
                      </Grid>
                      <Grid item xs={5} style={{ display: "flex", alignItems: "center" }}>
                        {thirdPartyVoucherValue.value.length > 1 && (
                          <RemoveItemButton
                            marginLeft={"0px"}
                            onClick={() => {
                              const items = [...thirdPartyVoucherValue.value];
                              items.splice(index, 1);
                              thirdPartyVoucherValue.onChange(thirdPartyVoucherValue.name, {
                                value: [...items],
                              });
                            }}
                          />
                        )}
                      </Grid>
                    </Grid>
                  );
                })}
                {thirdPartyVoucherValue.value?.map((value = {}, index) => {
                  const { thirdPartyVoucherCode } = value;
                  return index === 0 && thirdPartyVoucherCode.value === "" ? (
                    <Text danger style={{ margin: "5px 0" }}>
                      {locale.fieldIsRequired}
                    </Text>
                  ) : (
                    ""
                  );
                })}
                {thirdPartyVoucherValue.canAdd && (
                  <Button
                    style={{
                      marginTop: "10px",
                    }}
                    outline
                    disabled={thirdPartyVoucherValue.value.find(
                      (item) =>
                        item.thirdPartyVoucherCode.error || item.thirdPartyVoucherCode.value === ""
                    )}
                    onClick={() => {
                      thirdPartyVoucherValue.onChange(thirdPartyVoucherValue.name, {
                        value: [...thirdPartyVoucherValue.value, thirdPartyVoucherValueState],
                      });
                    }}
                  >
                    {locale.addInput}
                  </Button>
                )}
              </div>
            ),
          },
          thirdPartyVoucherAutoSeed: {
            ...thirdPartyVoucherAutoSeed,
            content: thirdPartyVoucherAutoSeed.content || (
              <div>
                <Grid container spacing={1}>
                  <Grid item xs={5}>
                    <Text subtitle>{locale.priceLocqAccount}</Text>
                  </Grid>

                  <Grid item xs={5}>
                    <Text subtitle>{locale.thirdPartyVoucherCode}</Text>
                  </Grid>
                </Grid>
                {thirdPartyVoucherAutoSeed.value?.map((value = {}, index, array) => {
                  const { thirdPartyVoucherCode, mobileNumber } = value;
                  const currentIndex = index;
                  return (
                    <Grid container spacing={1} key={index}>
                      <Grid item xs={5}>
                        <Field
                          style={{
                            margin: "0px",
                          }}
                          error={mobileNumber.error}
                          message={mobileNumber.message}
                        >
                          <TextField
                            value={mobileNumber.value}
                            maxLength={10}
                            error={mobileNumber.error}
                            placeholder="format: 9192345678"
                            disabled={validateMobileNumber.loading}
                            onChange={(name, { value }) => {
                              if (mobileNumber.validation({ value }) || !value) {
                                const invalidMobileNumber = value.length !== 10 || value[0] !== "9";

                                onChangeThirdPartyVoucherAutoSeed({
                                  mobileNumber: {
                                    value,
                                    error: invalidMobileNumber,
                                    message:
                                      invalidMobileNumber && value
                                        ? locale.pleaseInputValidPriceLOCQAccount
                                        : "",
                                  },
                                  index,
                                });
                              }
                            }}
                            onBlur={async (name, { value }) => {
                              if (!mobileNumber.error) {
                                try {
                                  const res = await validateMobileNumber.request({
                                    mobileNumber: `0${value}`,
                                  });
                                  const invalid = ["deactivated", "deleted"].includes(
                                    res?.data?.status
                                  );
                                  onChangeThirdPartyVoucherAutoSeed({
                                    mobileNumber: {
                                      value,
                                      error: invalid,
                                      message: invalid
                                        ? locale.pleaseInputValidPriceLOCQAccount
                                        : "",
                                    },
                                    index,
                                  });
                                } catch (e) {
                                  const { errorCode } = e?.data || {};
                                  if (errorCode === "P1005") {
                                    onChangeThirdPartyVoucherAutoSeed({
                                      mobileNumber: {
                                        value,
                                        error: true,
                                        message: locale.pleaseInputValidPriceLOCQAccount,
                                        errorType: "not-found",
                                      },
                                      index,
                                    });
                                  }
                                }
                              }
                            }}
                            prefix={
                              <div>
                                <Text
                                  style={{
                                    fontSize: "14px",
                                  }}
                                >
                                  +63
                                </Text>
                              </div>
                            }
                          />
                        </Field>
                      </Grid>
                      <Grid
                        item
                        xs={7}
                        style={{
                          display: "flex",
                        }}
                      >
                        <Field
                          {...thirdPartyVoucherCode}
                          style={{
                            margin: "0px",
                          }}
                          labelStyle={{
                            padding: "0px",
                            margin: "0px",
                          }}
                          error={thirdPartyVoucherCode.error}
                          message={thirdPartyVoucherCode.message}
                        >
                          <TextField
                            value={thirdPartyVoucherCode.value}
                            maxLength={30}
                            error={thirdPartyVoucherCode.error}
                            placeholder="Enter voucher code"
                            disabled={thirdPartyVoucherCode.loading}
                            onChange={(name, { value }) => {
                              const invalidVoucherCode = value.length <= 4 || value.length > 30;
                              onChangeThirdPartyVoucherAutoSeed({
                                thirdPartyVoucherCode: {
                                  value,
                                  error: invalidVoucherCode,
                                  message:
                                    invalidVoucherCode && value
                                      ? locale.pleaseInputValidVoucherCode
                                      : "",
                                },
                                index,
                              });
                            }}
                            onBlur={async (name, { value }) => {
                              if (!thirdPartyVoucherCode.error) {
                                try {
                                  let inputValue = value;
                                  const invalidVoucherCode = value.length <= 4 || value.length > 30;
                                  const isSameVoucherCode = thirdPartyVoucherAutoSeed?.value?.find(
                                    (value = {}, index) => {
                                      const { thirdPartyVoucherCode } = value;
                                      if (currentIndex !== index) {
                                        if (thirdPartyVoucherCode.value === inputValue) {
                                          return true;
                                        }
                                        return false;
                                      }
                                      return false;
                                    }
                                  );
                                  onChangeThirdPartyVoucherAutoSeed({
                                    thirdPartyVoucherCode: {
                                      value,
                                      error: invalidVoucherCode || isSameVoucherCode,
                                      message:
                                        invalidVoucherCode && value
                                          ? locale.pleaseInputValidVoucherCode
                                          : isSameVoucherCode
                                          ? locale.thirdPartyVoucherCodesExist
                                          : "",
                                    },
                                    index,
                                  });
                                } catch (e) {}
                              }
                            }}
                          />
                        </Field>
                        <Grid item xs={5} style={{ display: "flex", alignItems: "center" }}>
                          {thirdPartyVoucherAutoSeed.value.length > 1 && (
                            <RemoveItemButton
                              marginLeft={"10px"}
                              onClick={() => {
                                const items = [...thirdPartyVoucherAutoSeed.value];
                                items.splice(index, 1);
                                thirdPartyVoucherAutoSeed.onChange(thirdPartyVoucherAutoSeed.name, {
                                  value: [...items],
                                });
                              }}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
                {thirdPartyVoucherAutoSeed.canAdd && (
                  <Button
                    style={{
                      marginTop: "10px",
                    }}
                    outline
                    disabled={thirdPartyVoucherAutoSeed.value.find(
                      (item) =>
                        item.thirdPartyVoucherCode.error ||
                        (item.thirdPartyVoucherCode.value === "" && item.mobileNumber.error) ||
                        item.mobileNumber.value === ""
                    )}
                    onClick={() => {
                      const error = validateThirdPartyVoucherAutoSeed(
                        thirdPartyVoucherAutoSeed.value
                      );
                      if (!error) {
                        thirdPartyVoucherAutoSeed.onChange(thirdPartyVoucherAutoSeed.name, {
                          value: [
                            ...thirdPartyVoucherAutoSeed.value,
                            thirdPartyVoucherAutoSeedState,
                          ],
                        });
                      } else {
                        thirdPartyVoucherAutoSeed.onChange(thirdPartyVoucherAutoSeed.name, {
                          value: thirdPartyVoucherAutoSeed.value.map((item) => {
                            return {
                              thirdPartyVoucherCode: {
                                ...item.thirdPartyVoucherCode,
                                error:
                                  item.thirdPartyVoucherCode?.value.length >= 4 &&
                                  item.thirdPartyVoucherCode?.value.length <= 30
                                    ? false
                                    : true,
                              },
                              mobileNumber: {
                                ...item.mobileNumber,
                                error:
                                  !isNumeric(item.mobileNumber.value) ||
                                  item.mobileNumber.value.length !== 10 ||
                                  item.mobileNumber.value[0] !== "9" ||
                                  item.mobileNumber.errorType === "not-found",
                              },
                            };
                          }),
                        });
                      }
                    }}
                  >
                    {locale.addRow}
                  </Button>
                )}
              </div>
            ),
          },
          uploadThirdPartyVoucherCSV: {
            ...uploadThirdPartyVoucherCSV,
            content: uploadThirdPartyVoucherCSV.content || (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Button
                  startIcon={<GetAppRoundedIcon />}
                  outline
                  onClick={() => {
                    if (
                      voucherType?.value === VoucherType.ThirdPartyVoucher &&
                      voucherTypeSetting.value === VoucherTypeSetting.AutoSeed
                    ) {
                      importAutoSeedThirdPartyModal.show({});
                    } else {
                      importOneTimeAndMultiThirdPartyModal.show({});
                    }
                  }}
                >
                  {locale.importCSV}
                </Button>
                {uploadThirdPartyVoucherCSV.value && uploadThirdPartyVoucherCSV.value.path && (
                  <div
                    style={{
                      marginLeft: "10px",
                      background: "#f5f6fa",
                      minHeight: "35px",
                      borderRadius: "40px",
                      color: "#753bbd",
                      fontWeight: "500",
                      display: "flex",
                      alignItems: "center",
                      padding: "5px",
                    }}
                  >
                    <div>{uploadThirdPartyVoucherCSV.value.path}</div>
                    <CloseRounded
                      style={{
                        margin: "0px 5px",
                        fontSize: "14px",
                      }}
                      className="pointer"
                      onClick={() => {
                        uploadThirdPartyVoucherCSV.onChange("uploadThirdPartyVoucherCSV", {
                          value: [],
                        });
                      }}
                    />
                  </div>
                )}
              </div>
            ),
          },
          thirdPartyVoucherCode: {
            ...thirdPartyVoucherCode,
            content: thirdPartyVoucherCode.content || (
              <Radio
                style={{
                  width: "100%",
                  alignItems: "flex-start !important",
                }}
                {...thirdPartyVoucherCode}
                onChange={(name, { value }) => {
                  // const showVoucherAmount = value === ThirdPartyVoucherCode.ManualInput;
                  const showThirdPartyVoucherValue =
                    voucherType.value === VoucherType.ThirdPartyVoucher &&
                    value === ThirdPartyVoucherCode.ManualInput;
                  const showUploadCSV = value === ThirdPartyVoucherCode.UploadCsv;
                  modifyForm({
                    thirdPartyVoucherCode: {
                      value,
                    },
                    thirdPartyVoucherValue: {
                      visible: showThirdPartyVoucherValue,
                      value: [thirdPartyVoucherValueState],
                      validations: {
                        ...thirdPartyVoucherValue.validations,
                        isRequired: showThirdPartyVoucherValue,
                      },
                    },
                    uploadThirdPartyVoucherCSV: {
                      value: [],
                      visible: showUploadCSV,
                      validations: {
                        isRequired: showUploadCSV,
                      },
                    },
                  });
                }}
                options={[ThirdPartyVoucherCode.ManualInput, ThirdPartyVoucherCode.UploadCsv].map(
                  (item) => {
                    return {
                      value: item,
                      text: prettifyThirdPartyVoucherCode(item),
                    };
                  }
                )}
              />
            ),
          },
          claimMethod: {
            ...claimMethod,
            content: claimMethod.content || (
              <Radio
                style={{
                  width: "100%",
                  alignItems: "flex-start !important",
                }}
                vertical
                {...claimMethod}
                options={[ClaimMethod.AutoCashIn, ClaimMethod.SeedInVoucherTray].map((item) => {
                  return {
                    value: item,
                    text: prettifyVoucherClaimMethod(item),
                  };
                })}
              />
            ),
          },
          billedTo: {
            ...billedTo,
            content: billedTo.content || (
              <>
                <Autocomplete
                  {...billedTo}
                  options={[
                    BilledTo.SeaoilPhilippines,
                    BilledTo.JadePinnacleConsolidatedInc,
                    BilledTo.OceanTankersCorporation,
                    BilledTo.LocqOPC,
                    BilledTo.SupremeDominionConsolidatedInc,
                    BilledTo.SecurityBankCorporation,
                    BilledTo.ServCentralInc,
                    BilledTo.PlentinaLendingInc,
                    BilledTo.ELinkBusinessInnovation,
                    BilledTo.GlobalDominionFinancingIncorporated,
                    BilledTo.NovelCapitalInc,
                    BilledTo.ServCentralInc,
                    BilledTo.GazzUpInc,
                    BilledTo.EmeraldFieldsTradingInc,
                    BilledTo.FrancisGlennYu,
                    BilledTo.VirginiaGloriaBraga,
                    BilledTo.FrancisYu,
                    BilledTo.Others,
                  ].map((item) => {
                    return {
                      value: item,
                      label: item,
                    };
                  })}
                  onChange={(name, { value }) => {
                    onChangeBilledTo({
                      billedTo: {
                        value,
                      },
                    });
                  }}
                />
                {billedTo.value === BilledTo.Others && (
                  <Field>
                    <TextField {...billedToOthers}></TextField>
                  </Field>
                )}
              </>
            ),
          },
          remarks: {
            ...remarks,
            content: remarks.content || <TextField {...remarks} />,
          },
          thirdPartyVoucherCodes: {
            ...thirdPartyVoucherCodes,
            content: thirdPartyVoucherCodes.content,
          },
        }}
      />
      <ConfirmModal {...confirmModal} />
      <Modal {...thirdPartyVoucherImportModalResult} />
      <ImportStationModal
        {...importStationModal}
        request={""}
        stationData={stationData}
        setStationInputs={setStationInputs}
        saveStationsCb={saveStationsCb}
        stationInputs={stationInputs}
        modifyForm={modifyForm}
        requiredReferenceStation={fields?.requiredReferenceStation}
      />

      <ImportThirdPartyOneTimeAndMutiModal
        {...importOneTimeAndMultiThirdPartyModal}
        submitUploadThirdPartyVoucher={submitUploadThirdPartyVoucher}
        voucherTypeSetting={voucherTypeSetting}
      />
      <ImportThirdPartyAutoSeedModal
        {...importAutoSeedThirdPartyModal}
        submitUploadThirdPartyVoucher={submitUploadThirdPartyVoucher}
        voucherTypeSetting={voucherTypeSetting}
      />
      <TargetStationsModal {...stationsModal} {...targetStationsProps} />
    </div>
  );
};

export default VoucherForm;
