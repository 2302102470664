import React, { useState, useCallback } from "react";
import { ActionButton, Modal } from "components/commons";
import locale from "localization";
import styles from "./import-modal.module.scss";
// import { ErrorCode } from "enums";
import ImportFile from "components/commons/import-file/import-file";

const ImportModal = ({ onSubmit, loading, children, ...modalState }) => {
  const { close, active } = modalState;

  const [selectedFiles, setSelectedFiles] = useState(undefined);

  const upload = async () => {
    let file = selectedFiles[0];
    await onSubmit({ file });
    setSelectedFiles(undefined);
    // handleRequest(
    //   async () => {

    //     if (!res.isSuccess) {
    //       if (res.isError) {
    //         importStatusFileModal.show({
    //           title: locale.error,
    //           content: locale.invalidCsvFileTypeFormat,
    //           itemsWithError: [...res.errors],
    //           primary: {
    //             text: locale.retry,
    //             onClick: () => {
    //               setSelectedFiles(undefined);
    //               importStatusFileModal.close();
    //             },
    //           },
    //         });
    //       } else {
    //         importStatusFileModal.show({
    //           title: locale.importSuccessfulWithErrors,
    //           content: locale.importSuccessfulWithErrorsContent,
    //           itemsWithError: [...res.errors],
    //           secondary: {
    //             text: locale.gotIt,
    //             loading: false,
    //             onClick: () => {
    //               close();
    //               setSelectedFiles(undefined);
    //             },
    //           },
    //           primary: {
    //             text: locale.retry,
    //             onClick: () => {
    //               importStatusFileModal.close();
    //             },
    //           },
    //         });
    //       }
    //     } else {
    //       close();
    //       importStatusFileModal.show({
    //         title: locale.importSuccessful,
    //         content: locale.detailHaveBeenSuccessfullyUpdated,
    //         primary: {
    //           text: locale.gotIt,
    //           onClick: () => {
    //             setSelectedFiles(undefined);
    //             importStatusFileModal.close();
    //           },
    //         },
    //       });
    //       setSelectedFiles(undefined);
    //     }
    //   },
    //   {},
    //   (err) => {
    //     if (err?.data?.errorCode === ErrorCode.InvalidImportFormat) {
    //       importStatusFileModal.show({
    //         title: locale.error,
    //         content: locale.invalidCsvFileTypeFormat,
    //         primary: {
    //           text: locale.retry,
    //           onClick: () => {
    //             setSelectedFiles(undefined);
    //             importStatusFileModal.close();
    //           },
    //         },
    //       });
    //     }
    //     if (err?.showError) {
    //       return err.showError(false);
    //     }
    //   }
    // );
  };

  const onDrop = useCallback((files) => {
    setSelectedFiles(files);
  }, []);

  return (
    <>
      <Modal
        active={active}
        title={locale.import}
        {...modalState}
        close={() => {
          setSelectedFiles(undefined);
          close();
        }}
        className={styles.modal}
        titleStyle={styles.titleStyle}
        // subtitle={locale.uploadingAnImportFileWill}
        containerStyle={styles.container}
      >
        {children}
        <div className={styles.content}>
          <ImportFile value={selectedFiles} onChange={onDrop} />
        </div>
        <ActionButton
          right
          items={[
            {
              onClick: () => {
                setSelectedFiles(undefined);
                close();
              },
              text: locale.cancel,
              disabled: loading,
            },
            {
              onClick: () => {
                upload();
              },
              text: locale.continue,
              loading: loading,
              disabled: !selectedFiles,
            },
          ]}
        />
      </Modal>
    </>
  );
};

export default ImportModal;
