import React, { useCallback, useMemo, useState } from "react";
import { ActionButton, Modal, DataTable, Text, Checkbox } from "components/commons";
import locale from "localization";
import styles from "./target-stations-modal.module.scss";
import TargetStationsFilter from "./target-stations-filter";
import { TargetStationsColumns } from "./target-stations-columns";
import { StationType } from "enums";
import { CircularProgress } from "@material-ui/core";
import classNames from "classnames";

const TargetStationsModal = ({ ...props }) => {
  const {
    close,
    active,
    filterState,
    modifyFilters,
    filterCount,
    modifyFilter,
    clearFilter,
    selectStationsCb,
    stationInputs,
    setStationInputs,
    saveStationsCb,
    fetchStations,
    loadingStations,
    isAllStation,
    setIsAllStation,
    inputStationCount,
    stationslist,
    setStationsList,
    submittedFilter,
    stationData,
    onClearSelectedStation,
    index,
    isHedging,
    stationsSelected,
    setStationsSelected,
  } = props;
  const [stationType, setStationType] = useState([]);

  const onSearchCb = useCallback(
    async (searchKey) => {
      const { requestState } = modifyFilters({ searchKey, page: 1 });
      if (requestState.chargedTo.length === 1) {
        requestState.chargedTo = requestState.chargedTo[0].toString();
      } else {
        delete requestState.chargedTo;
      }
      const result = await fetchStations({ ...requestState, reset: true });
      setStationsList(result?.stations);
    },
    [fetchStations, modifyFilters, setStationsList]
  );

  const onChangeStationTypeCb = useCallback(
    (name, { value }) => {
      if (name) {
        let newStationType = stationType;
        if (name === "all") {
          Object.values(StationType).forEach((st) => {
            if (value) {
              if (!newStationType.includes(st)) {
                newStationType.push(st);
              }
            } else {
              newStationType.splice(stationType.indexOf(st), 1);
            }
          });
        } else {
          if (value) {
            newStationType.push(name);
          } else {
            newStationType.splice(stationType.indexOf(name), 1);
          }
        }
        setStationType(newStationType);
        modifyFilters({ chargedTo: newStationType });
      }
    },
    [modifyFilters, stationType]
  );

  const preparedTargetStationsData = useMemo(() => {
    const data = stationData.data;
    if (data && data.length > 0) {
      const preparedData = data.map((s) => {
        const map = new Map();
        const isChecked = stationInputs.some((station) => {
          return station.value === s?.stationId;
        });
        const isDisabled = stationsSelected.some((station) => {
          return station.value === s?.stationId && station.id !== index;
        });
        map.set(
          "checkbox",
          <Checkbox
            checked={isChecked}
            value={isChecked}
            disabled={isDisabled}
            onChange={() =>
              selectStationsCb({
                label: s?.name,
                value: s?.stationId,
              })
            }
          />
        );
        map.set("stationCode", <Text className={styles.textStyle}>{s?.stationCode}</Text>);
        map.set("stationName", <Text className={styles.textStyle}>{s?.name}</Text>);
        map.set("stationType", <Text className={styles.textStyle}>{s?.stationType}</Text>);

        return map;
      });
      return preparedData;
    }
    return [];
  }, [stationData.data, stationInputs, stationsSelected, index, selectStationsCb]);

  const onCheckAllCb = useCallback(
    async (value) => {
      let isAllSelected = false;
      let stationInputData = [];
      if (value) {
        const all = [];
        all.push({
          label: "isAll",
          value: 0,
          id: index,
        });
        setStationsSelected([...all, ...stationsSelected]);
        if (stationData.canLoadMore) {
          const result = await fetchStations({
            ...submittedFilter,
            perPage: inputStationCount,
            platformType: "plb",
            reset: true,
          });
          result?.stations.forEach((s) => {
            const isDisabled = stationsSelected.some((station) => {
              return station.value === s?.stationId && station.id !== index;
            });
            if (!isDisabled) {
              stationInputData.push({
                label: s?.name,
                value: s?.stationId,
              });
            }
          });
        } else {
          stationData.data.forEach((d) => {
            const isDisabled = stationsSelected.some((station) => {
              return station.value === d?.stationId && station.id !== index;
            });
            if (!isDisabled) {
              stationInputData.push({
                label: d?.name,
                value: d?.stationId,
              });
            }
          });
        }
        if (stationInputData.length > 0) {
          isAllSelected = true;
        }
      } else {
        setStationsSelected([]);
      }

      setStationInputs(stationInputData);
      setIsAllStation({ checked: isAllSelected });
    },
    [
      setStationInputs,
      setIsAllStation,
      index,
      setStationsSelected,
      stationsSelected,
      stationData.canLoadMore,
      stationData.data,
      fetchStations,
      submittedFilter,
      inputStationCount,
    ]
  );

  const closeCb = useCallback(async () => {
    close();
    setStationInputs([]);
    setIsAllStation({ checked: false });
    const { requestState } = clearFilter();
    const result = await fetchStations({ ...requestState, reset: true });
    setStationsList(result?.stations);
  }, [clearFilter, close, fetchStations, setIsAllStation, setStationInputs, setStationsList]);

  const isDisabled = stationsSelected.some((station) => {
    return station.value === 0 && station.id !== index;
  });

  return (
    <Modal
      active={active}
      title={locale.selectStations}
      close={closeCb}
      className={styles.modal}
      titleStyle={styles.titleStyle}
    >
      <div className={styles.content}>
        <div className={styles.filters}>
          <TargetStationsFilter
            filterState={filterState}
            onSearchChange={modifyFilter}
            onSearch={onSearchCb}
            resetFilter={() => {}}
            onClearSelectedStation={() => onClearSelectedStation(index, isHedging)}
            clearFilter={async () => {
              const { requestState } = clearFilter(["searchKey"]);

              const result = await fetchStations({ ...requestState, reset: true });
              setStationsList(result?.stations);
            }}
            submitFilter={async () => {
              const { requestState } = modifyFilters({ page: 1 });
              if (requestState.chargedTo.length === 1) {
                requestState.chargedTo = requestState.chargedTo[0].toString();
              } else {
                delete requestState.chargedTo;
              }
              const result = await fetchStations({ ...requestState, reset: true });
              setStationsList(result?.stations);
            }}
            filterCount={filterCount}
            inputStationCount={stationInputs?.length}
            onChangeStationType={onChangeStationTypeCb}
          />
        </div>
        <div
          className={styles.table}
          onScroll={async (e) => {
            if (
              stationData.canLoadMore &&
              !loadingStations &&
              e.target.scrollTop <= e.target.scrollHeight - e.target.offsetHeight
            ) {
              const { requestState } = modifyFilters({ page: Number(filterState.page) + 1 });
              if (requestState.chargedTo.length === 1) {
                requestState.chargedTo = requestState.chargedTo[0].toString();
              } else {
                delete requestState.chargedTo;
              }
              const result = await fetchStations(requestState);
              setStationsList([...stationslist, ...result?.stations]);
            }
          }}
        >
          {loadingStations && !stationData.data.length ? (
            <div className={styles.stationListloading}>
              <Text align="center">{locale.loading}</Text>
            </div>
          ) : (
            <DataTable
              page={filterState.page}
              pageSize={filterState.perPage}
              columns={TargetStationsColumns}
              data={preparedTargetStationsData}
              dataCount={inputStationCount}
              hidePagination
              actionHeaderProps={isAllStation}
              onCheckAll={onCheckAllCb}
              disabled={isDisabled}
            />
          )}
          {loadingStations && stationData.data.length ? (
            <div
              className={classNames(
                "text-center flex items-center full-width",
                styles.circularLoader
              )}
            >
              <CircularProgress
                style={{
                  margin: "auto",
                }}
              />
            </div>
          ) : null}
        </div>
      </div>
      <ActionButton
        right
        items={[
          {
            onClick: closeCb,
            text: locale.cancel,
          },
          {
            onClick: () => {
              saveStationsCb(index, isHedging);
              close();
              setStationInputs([]);
            },
            text: locale.save,
            disabled: stationInputs?.length === 0,
          },
        ]}
      />
    </Modal>
  );
};

export default TargetStationsModal;
