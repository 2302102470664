import React, { useContext } from "react";

import { Intro, Text } from "components/commons";
import useDataTable from "hooks/useDataTable";
import locale from "localization";
import DataTableV2 from "components/commons/data-table/data-table-v2";
import useExport from "hooks/useExport";
import { generateReportAdmin } from "apis";
import { getPayments, voidPayment } from "apis/pay-with-peso.api";
import { payWithPesoFilterState } from "./pay-with-peso-filter.state";
import { mapDataToList, mapFilterToRequest } from "./pay-with-peso.mapper";
import { payWithPesoColumns } from "./pay-with-peso-columns";
import PayWithPesoFilter from "./pay-with-peso-filter";
import { ConfirmModal, SuccessModal } from "components/modals";
import { SessionContext, UserContext } from "contexts";
import { useApi, useModal } from "hooks";
import { SettlementStatus, Portal } from "enums";
import { formatAmount } from "utils";
import { useState } from "react";
import { useCallback } from "react";
import VoidPaymentModal from "./void-payment-modal";
import ActionsHistoryModal from "./actions-history-modal";
import DiscountType from "enums/discount-type";

const PayWithPesoModule = () => {
  const voidPaymentModal = useModal();
  const actionsHistoryModal = useModal();
  const confirmModal = useModal();

  const { sessionId } = useContext(SessionContext);
  const [remarks, setRemarks] = useState("");
  const [value, setValue] = useState("");

  const { user } = useContext(UserContext);
  const { role } = user?.userAccesses?.find((element) => {
    return element.portal === Portal.LOCQ;
  });

  const { request: voidRequest, loading: voidingRequest } = useApi({
    api: voidPayment,
    pageError: false,
    modalError: true,
  });

  const handleVoidPayment = ({
    paymentId,
    amount,
    rebate,
    settlementId,
    settlementStatus,
    discountType,
    totalDiscount,
  }) => {
    setRemarks("");
    voidPaymentModal.show({
      title: `${locale.voidTransaction}?`,
      description: (
        <Text align="center">
          <locale.Populate
            text={
              settlementId
                ? settlementStatus === SettlementStatus.ForProcessing
                  ? locale.voidingThisTransactionSettlementProcessing
                  : locale.voidingThisTransactionSettlementProcessed
                : locale.voidingThisPayment
            }
            items={[
              <b>{formatAmount(amount)}</b>,
              <b>
                {formatAmount(discountType === DiscountType.Outright ? totalDiscount : rebate)}
              </b>,
            ]}
          />
        </Text>
      ),
      label: locale.reasonRemarks,
      actionText: locale.voidTransaction,
      submit: (value) => {
        onVoidPaymentCb({
          paymentId,
          value,
          amount,
        });
      },
    });
  };

  const handleActionsHistory = ({ voidedByEmail, status, voidedAt }) => {
    actionsHistoryModal.show({
      title: locale.actionsHistory,
      voidedByEmail,
      status,
      voidedAt,
    });
  };

  const onVoidPaymentCb = useCallback(
    async ({ paymentId, value, amount, totalDiscount }) => {
      await voidRequest({ paymentId, remarks: value });
      voidPaymentModal.close();
      table.fetch({});

      confirmModal.show({
        title: locale.transactionVoided,
        content: (
          <locale.Populate
            text={locale.thisPaymentHasBeenVoided}
            items={[<b>{formatAmount(amount)}</b>]}
          />
        ),
        loading: voidingRequest,
        primary: {
          text: locale.gotIt,
          onClick: () => {
            confirmModal.close();
          },
        },
      });
      setRemarks("");
    },
    //eslint-disable-next-line
    [voidRequest, voidPaymentModal, confirmModal, voidingRequest]
  );

  const { filter, search, table } = useDataTable({
    api: { api: getPayments },
    filter: { initialState: payWithPesoFilterState(), mapper: mapFilterToRequest },
    table: {
      key: "payments",
      mapper: (payments) => mapDataToList({ payments, handleVoidPayment, handleActionsHistory, role }),
      columns: payWithPesoColumns,
    },
  });

  const exports = useExport({
    api: generateReportAdmin,
    hasModal: true,
    reportType: "payment",
    mappedFilterState: {
      ...filter?.mappedFilterState,
      searchKey: search.searchKey,
      sessionId,
    },
  });

  delete table.result.count;

  return (
    <>
      <SuccessModal {...exports?.successModalComponent} />
      <ConfirmModal {...confirmModal} />
      <div>
        <Intro title={locale.locqPayForFuel} subtitle={locale.viewAndTrackScanToPayLocqPay} />
      </div>
      <PayWithPesoFilter {...filter} {...search} {...exports} />
      <DataTableV2 {...table} />
      <ActionsHistoryModal {...actionsHistoryModal} />
      <VoidPaymentModal
        {...voidPaymentModal}
        remarks={remarks}
        onChangeRemarks={(v) => {
          setRemarks(v ? v : "");
        }}
        loading={voidingRequest}
        onSubmit={voidPaymentModal.submit}
        value={value}
        setValue={setValue}
      />
    </>
  );
};

export default PayWithPesoModule;
