import locale from "localization";

export const columns = [
  { key: "productConversionId", text: locale.productConversionId },
  { key: "conversionDate", text: locale.conversionDate },
  { key: "platform", text: locale.platform },
  { key: "accountName", text: locale.pricelocqAccountName },
  { key: "fromToProduct", text: `${locale.convertFrom} / ${locale.convertTo}` },
  { key: "buyFuelIds", text: `${locale.originalBuyFuelId} / ${locale.newBuyFuelId}` },
  {
    key: "referenceStations",
    text: `${locale.originalReferenceStation} / ${locale.newReferenceStation}`,
  },
  {
    key: "referenceDates",
    text: `${locale.originalReferenceDate} / ${locale.newReferenceDate}`,
  },
];
