import { Text } from "components/commons";
import { prettifyProduct } from "utils/pretty.utils";
import styles from "./price-movement.module.scss";
import classNames from "classnames";
import { formatAmount, dateTimeFormat } from "utils";

export const mapDataToList = (priceMovements) => {
  const { product, priceMovementId, effectiveAt, createdAt, movement } = priceMovements;
  const diesel = "diesel" === product ? movement : 0;
  const gas =
    "gas" === product
      ? {
          gas91: movement,
          gas95: movement,
          gas97: movement,
        }
      : {
          gas91: 0,
          gas95: 0,
          gas97: 0,
        };

  const newProduct = Object.assign({ diesel }, gas);

  return {
    priceMovementId,
    product: Object.keys(newProduct).map((data) => (
      <div>
        <div className="flex items-center">
          <div className={classNames(styles.circle, styles[data])}></div>
          {prettifyProduct(data)}
        </div>
      </div>
    )),
    priceMovement: Object.keys(newProduct).map((data) => (
      <Text>{formatAmount(newProduct[data])}</Text>
    )),
    createdDate: <Text>{dateTimeFormat(createdAt)}</Text>,
    effectiveDate: <Text>{dateTimeFormat(effectiveAt)}</Text>,
  };
};

export const mapFilterToRequest = (filterState) => {
  const { ...fs } = filterState;

  return {
    ...fs,
  };
};
