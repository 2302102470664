import React, { useMemo, useCallback } from "react";
import { useApi, useModal, useForm } from "hooks";
import locale from "localization";
import styles from "./mops-add.module.scss";
import { DateTime } from "enums";
import { Path } from "enums";
import { initialState as formState } from "./mops.add.state";

import { Intro, BackLink, Field, Button } from "components/commons";
import { InputAmount } from "components/field";
import DateTimePicker from "components/commons/date-time/date-time";
import moment from "moment";
import SuccessMopsModal from "./mops-modals/success-mops-modal";
import ConfirmMopsModal from "./mops-modals/confirm-mops-modal";
import { createMops } from "apis";
import { handleRequest } from "utils";

const AddMopsModule = ({ initialState }) => {
  const successMopsModal = useModal();
  const confirmMopsModal = useModal();

  const form = useMemo(() => {
    return formState(initialState);
  }, [initialState]);
  const { fields, modifyField, isFormSubmittable } = useForm({
    initialState: form,
  });

  const { request: addMopsRequest, loading: addingMopsRequest } = useApi({
    api: createMops,
  });

  const getDate = useCallback(
    (name, value) => {
      if (moment(value.value).format(DateTime.A) === moment().format(DateTime.A)) {
        fields.effectiveAt.value = moment().format(DateTime.H);
      } else {
        fields.effectiveAt.value = moment(value.value)
          .set("hour", "6")
          .set("minute", "00")
          .format(DateTime.H);
      }

      modifyField(name, fields.effectiveAt);
    },
    [fields.effectiveAt, modifyField]
  );

  const generateMops = useCallback(() => {
    const requestBody = {
      mogas: parseFloat(fields.mogas.value),
      gasoil: parseFloat(fields.gasoil.value),
      effectiveAt: moment(fields.effectiveAt.value, DateTime.H).format(DateTime.I),
    };
    handleRequest(async () => {
      await addMopsRequest({ body: requestBody, generateMops });
      confirmMopsModal.close();
      successMopsModal.show();
    });
  }, [addMopsRequest, confirmMopsModal, successMopsModal, fields]);

  return (
    <div>
      <BackLink text={locale.mopsPrice} path={Path.MopsPrice} />
      <div className={styles.intro}>
        <Intro title={locale.editMopsPrice} />
      </div>
      <div className={styles.form}>
        <div className={styles.horizontalInput}>
          <Field className={styles.mogasTitle} horizontal {...fields.mogas}>
            <InputAmount
              decimalPlaces={2}
              {...fields.mogas}
              onChange={modifyField}
              className={styles.mogasAmount}
            />
          </Field>
        </div>
        <div className={styles.horizontalInput}>
          <Field className={styles.mogasTitle} horizontal {...fields.gasoil}>
            <InputAmount
              decimalPlaces={2}
              {...fields.gasoil}
              onChange={modifyField}
              className={styles.mogasAmount}
            />
          </Field>
        </div>
        <div className={styles.dateInput}>
          <Field className={styles.dateTitle} horizontal {...fields.effectiveAt}>
            <DateTimePicker
              className={styles.datePicker}
              {...fields.effectiveAt}
              format={DateTime.H}
              onChange={(name, value) => {
                getDate(name, value);
              }}
            />
          </Field>
        </div>
        <div className={styles.actionButtonBox}>
          <Button
            primary
            className={styles.actionButton}
            disabled={!isFormSubmittable}
            onClick={() => {
              confirmMopsModal.show();
            }}
          >
            {locale.save}
          </Button>
        </div>
      </div>
      <SuccessMopsModal generating={addingMopsRequest} values={fields} {...successMopsModal} />
      <ConfirmMopsModal
        generating={addingMopsRequest}
        onGenerate={generateMops}
        {...confirmMopsModal}
        values={fields}
      />
    </div>
  );
};

export default AddMopsModule;
