import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import locale from "localization";
import styles from "./user-attribute-view.module.scss";
import { FormMode, Path, Role, Portal } from "enums";
import { Intro, BackLink } from "components/commons";
import UserAttributeDetailsModule from "../user-attribute-details/user-attribute-details.module";
import { UserContext } from "contexts";

const ViewUserAttributeModule = (props) => {
  const history = useHistory();
  const attributeId = props.location.state ? props.location.state.attributeId : "";
  const { user } = useContext(UserContext);
  const { role } = user?.userAccesses?.find((element) => {
    return element.portal === Portal.LOCQ;
  });

  return (
    <div>
      <BackLink text={locale.userAttributes} path={Path.Attributes} />
      <div className={styles.intro}>
        {role === Role.Accounting ?<Intro
          title={locale.userAttributeDetails}
          titleStyle={styles.title}
        /> : <Intro
        title={locale.userAttributeDetails}
        titleStyle={styles.title}
        actionText={locale.edit}
        actionOnClick={() => {
          history.push(Path.EditUserAttribute, {
            attributeId,
          });
        }}
        actionOutlined
        actionClassName={styles.primaryEditButton}
        actionStartIcon={<span className="icon-pencil" />}
      />}
        
        <UserAttributeDetailsModule pageMode={FormMode.View} attributeId={attributeId} />
      </div>
    </div>
  );
};

export default ViewUserAttributeModule;
