import locale from "localization";

export const columns = [
  { key: "buyFuelId", text: locale.buyFuelId },
  { key: "cashinId", text: locale.cashInId },
  { key: "purchasedDate", text: locale.purchasedDate },
  { key: "userDetails", text: locale.nameAndMobileNumber },
  { key: "referenceStation", text: locale.referenceStation },
  { key: "product", text: locale.product },
  { key: "creditPrice", text: locale.creditPrice },
  // { key: "supplierWpp", text: locale.supplierWpp },
  { key: "volume", text: locale.volume },
  { key: "totalCreditAmount", text: locale.totalCreditAmount },
  { key: "discountCampaign", text: locale.discountCampaign },
  { key: "statusDate", text: `${locale.statusDate} Voided` },
  { key: "remarks", text: locale.remarks },
  { key: "action", text: locale.actions },
];
