import React from "react";
import { Switch, Redirect, Route } from "react-router";
import { Path } from "enums";
import NewCustomerVouchers from "modules/campaigns-programs/new-customer-vouchers/new-customer-vouchers.module";
import DiscountCampaignPage from "./discount-campaign.page";
import VoucherCampaignPage from "./voucher-campaign.page";
import VouchersPage from "./vouchers.page";
// import { useContext } from "react";
// import { FeatureFlagContext } from "contexts";
import CampaignPage from "./campaign.page";
import CampaignVouchers from "modules/campaigns-programs/campaign-vouchers/campaign-vouchers.module";

const CampaingsPage = () => {
  // const { hasFeature } = useContext(FeatureFlagContext);
  return (
    <div>
      <Switch>
        {/* <Route path={Path.Campaign}>
     
        {hasFeature("VOUCHER_V2") ? (
          <Route path={Path.Vouchers} component={CampaignPage} />
        ) : (
          <Redirect to={Path.DiscountCampaign} />
        )}
         */}
        <Route path={Path.Campaign} component={CampaignPage} />
        <Route path={Path.Vouchers} component={VouchersPage} />
        <Route path={Path.CampaignVouchers} component={CampaignVouchers} />
        <Route path={Path.VoucherCampaign} component={VoucherCampaignPage} />
        <Route path={Path.DiscountCampaign} component={DiscountCampaignPage} />

        <Route exact path={Path.NewCustomerVouchers} component={NewCustomerVouchers} />
        <Redirect to={Path.DiscountCampaign} />
      </Switch>
    </div>
  );
};

export default CampaingsPage;
