import { Autocomplete, DateRange, Field, SearchField, Filter, Button } from "components/commons";
import React from "react";
import styles from "./account-list-filter.module.scss";
import locale from "localization";
import { prettifyAccountStatus, prettifyFuelCodes } from "utils";
import { AccountStatus, FuelCodesStatus } from "enums";

const AccountListFilter = ({
  filterState,
  applyFilter,
  applyClearFilter,
  applyResetFilter,
  modifyFilter,
  modifySearch,
  applySearch,
  applyClearSearch,
  searchKey,
  filterCount,
  exportReport,
  exporting,
}) => {
  return (
    <div className={styles.filter}>
      <Filter
        submit={applyFilter}
        clear={applyClearFilter}
        close={applyResetFilter}
        filterCount={filterCount}
      >
        <div className={styles.content}>
          <Field
            label={locale.dateOnboarded}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
          >
            <DateRange
              value={{
                startDate: filterState.startDate,
                endDate: filterState.endDate,
              }}
              onChange={(_, { value }) => {
                const { startDate, endDate } = value;
                modifyFilter({
                  startDate,
                  endDate,
                });
              }}
            />
          </Field>
          <Field
            label={locale.testFuelCodeStatus}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
          >
            <Autocomplete
              name="testFuelCodeStatus"
              value={filterState.testFuelCodeStatus.value}
              options={[
                {
                  label: prettifyFuelCodes(FuelCodesStatus.Scheduled),
                  value: FuelCodesStatus.Scheduled,
                },
                {
                  label: prettifyFuelCodes(FuelCodesStatus.Active),
                  value: FuelCodesStatus.Active,
                },
                {
                  label: prettifyFuelCodes(FuelCodesStatus.Redeemed),
                  value: FuelCodesStatus.Redeemed,
                },
                {
                  label: prettifyFuelCodes(FuelCodesStatus.Deactivated),
                  value: FuelCodesStatus.Deactivated,
                },
                {
                  label: prettifyFuelCodes(FuelCodesStatus.Expired),
                  value: FuelCodesStatus.Expired,
                },
              ]}
              onChange={(name, { value, isSelectedAll }) => {
                modifyFilter({ [name]: { value, isSelectedAll } });
              }}
              hasAll
              multiple
            />
          </Field>
          <Field
            label={locale.accountStatus}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
          >
            <Autocomplete
              name="stationAccountStatus"
              value={filterState.stationAccountStatus.value}
              options={[
                {
                  label: prettifyAccountStatus(AccountStatus.Active),
                  value: AccountStatus.Active,
                },
                {
                  label: prettifyAccountStatus(AccountStatus.Deactivated),
                  value: AccountStatus.Deactivated,
                  disabled: true,
                },
                {
                  label: prettifyAccountStatus(AccountStatus.Pending),
                  value: AccountStatus.Pending,
                },
                {
                  label: prettifyAccountStatus(AccountStatus.Expired),
                  value: AccountStatus.Expired,
                },
              ]}
              onChange={(name, { value, isSelectedAll }) => {
                modifyFilter({ [name]: { value, isSelectedAll } });
              }}
              hasAll
              multiple
            />
          </Field>
        </div>
      </Filter>
      <Field className={styles.search}>
        <SearchField
          restrict={false}
          placeholder={`${locale.stationCode}, ${locale.stationName}`}
          value={searchKey}
          onChange={(_, { value }) => modifySearch(value)}
          onEnter={applySearch}
          onClear={applyClearSearch}
        />
      </Field>

      <Field className={styles.downloadButton}>
        <Button
          startIcon={<span className="icon-download" />}
          exportButton
          onClick={exportReport}
          disabled={exporting}
          loading={exporting}
        >
          {locale.export}
        </Button>
      </Field>
    </div>
  );
};

export default AccountListFilter;
