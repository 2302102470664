import { Intro } from "components/commons";
import useDataTable from "hooks/useDataTable";
import React, { useContext } from "react";
import { accountListColumns } from "./account-list-columns";
import AccountListFilter from "./account-list-filter";
import { accountListFilterState } from "./account-list-filter.state";
import styles from "./account-list.module.scss";
import locale from "localization";
import { generateReportAdmin, getStationAccounts } from "apis";
import useExport from "hooks/useExport";
import { SuccessModal } from "components/modals";
import { SessionContext } from "contexts";
import { mapDataToList, mapFilterToRequest } from "./account-list.mapper";
import DataTableV2 from "components/commons/data-table/data-table-v2";

const StationAccountListModule = () => {
  const { sessionId } = useContext(SessionContext);

  const { filter, search, table } = useDataTable({
    api: {
      api: getStationAccounts,
    },
    filter: { initialState: accountListFilterState(), mapper: mapFilterToRequest },
    table: {
      key: "stations",
      mapper: (stations) => mapDataToList({ stations }),
      columns: accountListColumns,
    },
  });

  const exports = useExport({
    api: generateReportAdmin,
    hasModal: true,
    reportType: "station-account",
    mappedFilterState: {
      ...filter?.mappedFilterState,
      searchKey: search.searchKey,
      sessionId,
    },
  });

  return (
    <div>
      <SuccessModal {...exports?.successModalComponent} />
      <div>
        <Intro title={locale.stationAccountList} subtitle={locale.viewTrackstationAccounts} />
      </div>
      <div className={styles.filters}>
        <AccountListFilter {...filter} {...search} {...exports} />
      </div>
      <div className={styles.table}>
        <DataTableV2 {...table} paginationV2 />
      </div>
    </div>
  );
};

export default StationAccountListModule;
