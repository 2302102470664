import React, { useState } from "react";
import { Button } from "@material-ui/core";
import styles from "./input-file.module.scss";
import { Text, Image } from "components/commons";
import locale from "localization";
import { CancelIcon } from "images";
import classNames from "classnames";

const InputFile = ({ allowedTypes, getFileInfo, selectedFile, setSelectedFile }) => {
  const [message, setMessage] = useState(null);
  const [isError, setError] = useState(false);

  const clearSelected = () => {
    document.getElementById("btn-upload").value = "";
    setSelectedFile(null);
    getFileInfo(null);
    setMessage(null);
    setError(false);
  };

  const selectFile = async (event) => {
    const selectedFile = event.target.files[0];
    const extName = selectedFile.name.split(".").pop();
    const currentFile = event.target.files[0];

    if (!currentFile) {
      return;
    }

    if (currentFile && allowedTypes && allowedTypes.indexOf(extName) < 0) {
      setError(true);
      setMessage(locale.pleaseSelectCSVFile);
      setSelectedFile(currentFile);
      getFileInfo(null);
    } else {
      setError(false);
      setMessage(null);
      setSelectedFile(currentFile);
      getFileInfo(currentFile);
    }
  };

  const Cancel = () => {
    return <Image className={styles.clearIcon} onClick={clearSelected} src={CancelIcon} />;
  };

  return (
    <div className={styles.main}>
      <label htmlFor="btn-upload">
        <input
          id="btn-upload"
          name="btn-upload"
          type="file"
          className={styles.fileInput}
          onChange={selectFile}
        />

        <Text className={styles.text}>{locale.uploadCsvFile}</Text>
        <div style={{ position: "relative" }}>
          <Button className={styles.browseButton} variant="outlined" component="span">
            {selectedFile ? locale.replace : locale.browseYourFile}
          </Button>
        </div>
      </label>
      {selectedFile && (
        <span
          className={classNames(styles.fileName, {
            [`${styles.withError}`]: isError,
          })}
        >
          {isError && <div className={styles.error}>{message}</div>}
          {selectedFile.name} <Cancel />
        </span>
      )}
    </div>
  );
};

export default InputFile;
