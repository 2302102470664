import classNames from "classnames";
import React, { useMemo, useRef } from "react";
import { useState } from "react";
import { TextField, Text, Checkbox } from "..";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import styles from "./dropdown-multiple.module.scss";
import locale from "localization";

const DropdownMultiple = ({
  name,
  value = [],
  label,
  onChange,
  request,
  options = [],
  total,
  placeholder,
  allLabel,
  allSelectedText,
  selectAllLabel = locale.selectAll,
  isAll = false,
  classNameDropdown,
  disabled,
  ...props
}) => {
  const [searchKey, setSearchKey] = useState("");
  const [active, setActive] = useState(false);
  const inputRef = useRef();

  const renderData = useMemo(() => {
    if (searchKey) {
      return options.filter((d) => {
        return d.label.toLowerCase().includes(searchKey.toLowerCase());
      });
    }

    return options;
  }, [searchKey, options]);

  const renderPlaceholder = useMemo(() => {
    if (label) {
      return label;
    }
    return placeholder;
  }, [placeholder, label]);

  return (
    <div>
      {active && (
        <div
          className={styles.dimmer}
          onClick={() => {
            setActive(false);
          }}
        ></div>
      )}
      <div
        onMouseDown={() => {
          setSearchKey("");
          setActive(true);
        }}
      >
        <TextField
          ref={inputRef}
          className={classNames(styles.textfield, classNameDropdown, {
            [`${styles.active}`]: active && label,
          })}
          value={
            active
              ? searchKey
              : isAll || value?.length
              ? isAll
                ? allSelectedText
                : allLabel
              : label
          }
          onChange={(name, { value }) => {
            setSearchKey(value);
          }}
          prefix={
            value.length || isAll ? (
              <div className={styles.selectedLength}>
                {isAll ? total : value.length}
                <CloseOutlinedIcon
                  className={styles.removeIcon}
                  onMouseDown={(e) => {
                    onChange(name, {
                      value: [],
                      isAll: false,
                    });
                    setActive(false);
                    setSearchKey("");
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                />
              </div>
            ) : null
          }
          suffix={
            <ArrowDropDownIcon
              className={styles.clickable}
              onClick={() => {
                if (inputRef) {
                  inputRef.current.focus();
                }
                if (!active) {
                  setActive(true);
                }
              }}
            />
          }
          disabled={disabled}
          {...props}
          placeholder={renderPlaceholder}
        />
      </div>
      <div
        className={classNames(styles.options, {
          [`${styles.active}`]: active,
          [`${styles.empty}`]: !options.length,
        })}
      >
        <div
          className={classNames(styles.option, {
            [`${styles.selectedAll}`]: isAll,
          })}
          onMouseDown={() => {
            if (!disabled) {
              onChange(name, {
                value: isAll ? [] : options,
                isAll: !isAll,
              });
            }
          }}
        >
          <Checkbox value={isAll} checked={isAll} />
          <div className={styles.allText}>{selectAllLabel}</div>
        </div>
        {renderData.map((option, key) => {
          const selectedValue = value.filter((id) => {
            return option.value === id.value;
          });
          const isChecked = selectedValue.length > 0 || isAll ? true : false;
          return (
            <div
              key={key}
              className={classNames(styles.option, {
                [`${styles.selected}`]: option.value === value,
              })}
              onClick={() => {
                if (inputRef) {
                  inputRef.current.focus();
                }
              }}
              onMouseDown={() => {
                if (Array.isArray(value) && !disabled) {
                  const newValue = isAll
                    ? options
                        .filter((id) => {
                          return option.value !== id.value;
                        })
                        .map((id) => {
                          return {
                            value: id.value,
                            label: id.label,
                          };
                        })
                    : isChecked
                    ? value.filter((id) => {
                        return option.value !== id.value;
                      })
                    : [
                        ...value,
                        {
                          value: option.value,
                          label: option.label,
                        },
                      ];
                  const willSelectAll = newValue.length === total && searchKey === "";
                  onChange(name, {
                    value: willSelectAll ? options : newValue,
                    isAll: willSelectAll,
                  });
                }
              }}
            >
              {<Checkbox value={isChecked} checked={isChecked} />}
              {option.label}
            </div>
          );
        })}
        {!renderData.length && (
          <div className={styles.option}>
            <Text subtitle>No Data.</Text>
          </div>
        )}
      </div>
    </div>
  );
};

export default DropdownMultiple;
