import { FuelCodeType } from "enums";
import { prettifyFuelCodeType } from "utils/pretty.utils";

export const fuelCodeTypeOptions = [
  {
    label: prettifyFuelCodeType(FuelCodeType.DriverSpecific),
    value: FuelCodeType.DriverSpecific,
  },
  {
    label: prettifyFuelCodeType(FuelCodeType.VehicleSpecific),
    value: FuelCodeType.VehicleSpecific,
  },
  {
    label: prettifyFuelCodeType(FuelCodeType.DriverVehicleSpecific),
    value: FuelCodeType.DriverVehicleSpecific,
  },
  {
    label: prettifyFuelCodeType(FuelCodeType.AnyDriverAnyVehicle),
    value: FuelCodeType.AnyDriverAnyVehicle,
  },
];
