import * as queryString from "query-string";
import React, { useMemo, useState } from "react";
import { useForm, useApi, useMount } from "hooks";
import { initialState } from "./activate-account.state";
import styles from "./activate-account.module.scss";
import { ErrorCode, Path } from "enums";
import {
  Field,
  TextField,
  PasswordField,
  Button,
  Image,
  Text,
  Title,
  Footer,
  Loader,
} from "components/commons";
import classNames from "classnames";
import { PriceLOCQLogo } from "images";
import locale from "localization";
import { handleRequest, redirectTo } from "utils";
import { getUserInvite, acceptInvite } from "apis/user.api";
import ResultActivateAccount from "./result-activate-account";
import { isPasswordValid } from "utils";

const ActivateAccountModule = (props) => {
  const [active, setActive] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const parse = queryString.parse(props.location.search);

  const { request: acceptInviteRequest, loading: acceptingInvite } = useApi({
    api: acceptInvite,
    handleOwnError: {
      badrequest: true,
    },
  });

  const {
    fields,
    modifyField,
    submitForm,
    getFormValues,
    applyFieldErrors,
    isFormSubmittable,
    clearForm,
  } = useForm({
    initialState,
  });

  const { request: getRequestUserInvite, loading: fetchingUserInvite } = useApi({
    api: getUserInvite,
    params: {
      token: parse.token,
    },
    pageError: false,
    modalError: false,
  });

  useMount(() => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("fleetId");
    if (parse.token) {
      handleRequest(
        async () => {
          const inviteResult = await getRequestUserInvite();
          if (inviteResult && inviteResult.email) {
            clearForm();

            modifyField("email", {
              value: inviteResult.email,
            });
          }
        },
        null,
        (err) => {
          if (
            err.data?.errorCode === ErrorCode.InvalidInviteUserToken ||
            err.data?.errorCode === ErrorCode.ExpiredInviteUserToken
          ) {
            redirectTo(`${Path.Auth}?code=${err.data?.errorCode}`);
          }
          return err.showError(false);
        }
      );
    } else {
      redirectTo(`${Path.Auth}?code=${ErrorCode.InvalidInviteUserToken}`);
    }
  });

  const onBlurPasswordField = () => {
    const { password, confirmPassword } = getFormValues();

    let validations = {};
    if (password) {
      if (!isPasswordValid(password)) {
        Object.assign(validations, {
          password: locale.use8CharWithMix,
        });
      }
      if (password !== confirmPassword && password && confirmPassword) {
        Object.assign(validations, {
          confirmPassword: locale.passwordsDoNotMatch,
        });
      }
      return applyFieldErrors(validations);
    }
  };

  useMemo(() => {
    const { password, confirmPassword } = getFormValues();
    setPasswordValid(password === confirmPassword && isPasswordValid(password));
    // eslint-disable-next-line
  }, [fields.password.value, fields.confirmPassword.value]);

  const handleActivateAccount = async () => {
    const { password, firstName, lastName } = getFormValues();
    handleRequest(
      async () => {
        await acceptInviteRequest({ firstName, lastName, password, token: parse.token }, () =>
          handleActivateAccount()
        );
        setActive(true);
      },
      null,
      (err) => {
        if (
          err.data?.errorCode === ErrorCode.InvalidInviteStationToken ||
          err.data?.errorCode === ErrorCode.ExpiredInviteStationToken
        ) {
          redirectTo(`${Path.Auth}?code=${err.data?.errorCode}`);
        }
        return err.showError();
      }
    );
  };

  return (
    <div className={styles.container}>
      <Loader open={fetchingUserInvite} />

      <div className={classNames(styles.header)}>
        <Image src={PriceLOCQLogo} className={styles.logoImage} />
      </div>

      <div className={styles.content}>
        <div className={styles.contentContainer}>
          <div className={styles.activateAccount}>
            <Title className={styles.title}>{locale.activateAccount}</Title>
            <div className={styles.panel}>
              <Title className={styles.subtitle}>{locale.basicInformation}</Title>
              <Field
                labelClassName={styles.label}
                {...fields.firstName}
                horizontal
                childrenClassName={styles.field}
              >
                <TextField {...fields.firstName} onChange={modifyField} />
              </Field>
              <Field
                labelClassName={styles.label}
                {...fields.lastName}
                horizontal
                childrenClassName={styles.field}
              >
                <TextField {...fields.lastName} onChange={modifyField} />
              </Field>
              <Field
                labelClassName={styles.label}
                {...fields.email}
                horizontal
                childrenClassName={styles.field}
              >
                <TextField {...fields.email} onChange={modifyField} />
              </Field>
            </div>
            <div className={styles.panel}>
              <Title className={styles.subtitle}>{locale.setPassword}</Title>
              <Field
                labelClassName={styles.label}
                {...fields.password}
                horizontal
                labelPosition="top"
                childrenClassName={styles.field}
              >
                <PasswordField
                  {...fields.password}
                  onChange={modifyField}
                  onBlur={onBlurPasswordField}
                />
                {fields.password.value && !fields.password.error && (
                  <Text label className={styles.labelMessage}>
                    {locale.use8CharWithMix}
                  </Text>
                )}
              </Field>
              <Field
                labelClassName={styles.label}
                {...fields.confirmPassword}
                horizontal
                childrenClassName={styles.field}
              >
                <PasswordField
                  {...fields.confirmPassword}
                  onChange={modifyField}
                  onBlur={onBlurPasswordField}
                />
              </Field>
            </div>

            <Button
              primary
              className={styles.activateButton}
              loading={acceptingInvite}
              disabled={!isFormSubmittable || !passwordValid}
              onClick={() => {
                submitForm(handleActivateAccount);
              }}
            >
              {locale.activateAccount}
            </Button>
          </div>
        </div>
      </div>
      <Footer />
      <ResultActivateAccount active={active} />
    </div>
  );
};

export default ActivateAccountModule;
