import classNames from "classnames";
import { Modal, ActionButton, TextField, Field } from "components/commons";
import locale from "localization";
import React from "react";
import styles from "./edit-remarks-modal.module.scss";

const EditRemarksModal = ({
  close,
  active,
  onSubmit,
  remarks = "",
  onChangeRemarks,
  loading,
  title,
  description,
  actionText,
}) => {
  return (
    <Modal active={active} title={title} close={close} className={styles.modal}>
      <div
        className={classNames(styles.content, {
          [`${styles.noSpace}`]: !description,
        })}
      >
        {/* <Text align="center" style={{ padding: "0px 20px" }}>
          {description}
        </Text> */}
        <Field
          childrenStyle={{
            width: "100%",
          }}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            padding: "0px 20px",
          }}
          labelStyle={{
            marginTop: 10,
          }}
          label={locale.reasonRemarks}
        >
          <div
            style={{
              marginTop: "10px",
            }}
          >
            <TextField
              multiline
              rowsMax={1}
              onChange={(n, { value }) => {
                onChangeRemarks(value);
              }}
              value={remarks}
            ></TextField>
          </div>
        </Field>
      </div>
      <ActionButton
        right
        loading={loading}
        items={[
          {
            text: locale.cancel,
            onClick: () => {
              close();
              onChangeRemarks("");
            },
          },
          {
            onClick: () => {
              onSubmit(remarks);
              onChangeRemarks("");
            },
            text: actionText,
            disabled: remarks?.length === 0,
          },
        ]}
      />
    </Modal>
  );
};

export default EditRemarksModal;
