import { Modal, Text, ActionButton } from "components/commons";
import React from "react";
import styles from "./confirm-modal.module.scss";

import classNames from "classnames";

const ConfirmModal = ({
  content = "",
  primary,
  secondary,
  actions = [],
  children,
  loading: loadingState,
  disabled: disableButtonState,
  ...state
}) => {
  const {
    text: primaryText = "Got It",
    loading,
    onClick: primaryOnClick = () => {
      state.close();
    },
  } = primary || {};

  const {
    text: secondaryText = "Cancel",
    onClick: secondaryClick = () => {
      state.close();
    },
  } = secondary || {};
  return (
    <Modal {...state}>
      <div className={classNames(styles.container)}>
        <Text className={styles.content}>{content}</Text>
      </div>
      {children}
      {primary || secondary || actions.length ? (
        <ActionButton
          right={secondary}
          center={!secondary}
          loading={loadingState}
          items={[
            ...actions,
            ...(secondary
              ? [
                  {
                    disabled: disableButtonState,
                    onClick: () => {
                      secondaryClick();
                      state.close();
                    },
                    text: secondaryText,
                    primary: false,
                  },
                ]
              : []),
            {
              onClick: () => {
                primaryOnClick();
              },
              text: primaryText,
              primary: true,
              loading: loading || loadingState,
            },
          ]}
        />
      ) : null}
    </Modal>
  );
};

export default ConfirmModal;
