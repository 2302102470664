import React, { useContext } from "react";

import { Intro, Text } from "components/commons";
import useDataTable from "hooks/useDataTable";
import locale from "localization";
import DataTableV2 from "components/commons/data-table/data-table-v2";
import { getMerchantPayments, voidMerchantPayment } from "apis/non-fuel.api";
import { nonFuelFilterState } from "./non-fuel-filter.state";
import { mapDataToList, mapFilterToRequest } from "./non-fuel.mapper";
import { nonFuelColumns } from "./non-fuel-columns";
import NonFuelFilter from "./non-fuel-filter";
import { useApi, useExport, useModal } from "hooks";
import { generateReportAdmin } from "apis/generate-report.api";
import { SuccessModal } from "components/modals";
import { PlatformType } from "enums";
import { formatAmount } from "utils";
import { useCallback } from "react";
import { useState } from "react";
import VoidPaymentModal from "./void-payment-modal";
import ActionsHistoryModal from "./actions-history-modal";
import { SessionContext } from "contexts";

const NonFuelModule = () => {
  const { sessionId } = useContext(SessionContext);
  const voidPaymentModal = useModal();
  const actionsHistoryModal = useModal();
  const confirmModal = useModal();

  const [remarks, setRemarks] = useState("");
  const [value, setValue] = useState("");

  const { request: voidRequest, loading: voidingRequest } = useApi({
    api: voidMerchantPayment,
    pageError: false,
    modalError: true,
  });

  const handleVoidPayment = ({ merchantPaymentId, amount }) => {
    setRemarks("");
    voidPaymentModal.show({
      title: `${locale.voidTransaction}?`,
      description: (
        <Text align="center">
          <locale.Populate
            text={locale.voidingThisNonFuel}
            items={[<b>{formatAmount(amount)}</b>]}
          />
        </Text>
      ),
      label: locale.reasonRemarks,
      actionText: locale.voidTransaction,
      submit: (value) => {
        onVoidPaymentCb({
          merchantPaymentId,
          value,
          amount,
        });
      },
    });
  };

  const handleActionsHistory = ({ voidedByEmail, status, voidedAt }) => {
    actionsHistoryModal.show({
      title: locale.actionsHistory,
      voidedByEmail,
      status,
      voidedAt,
    });
  };

  const onVoidPaymentCb = useCallback(
    async ({ merchantPaymentId, value, amount }) => {
      await voidRequest({ merchantPaymentId, remarks: value });
      voidPaymentModal.close();
      table.fetch({});

      confirmModal.show({
        title: locale.transactionVoided,
        content: (
          <locale.Populate
            text={locale.thisPaymentHasBeenVoided}
            items={[<b>{formatAmount(amount)}</b>]}
          />
        ),
        loading: voidingRequest,
        primary: {
          text: locale.gotIt,
          onClick: () => {
            confirmModal.close();
          },
        },
      });
      setRemarks("");
    },
    //eslint-disable-next-line
    [voidRequest, voidPaymentModal, confirmModal, voidingRequest]
  );

  const { filter, search, table } = useDataTable({
    api: { api: getMerchantPayments, params: { platformType: PlatformType.PLB } },
    filter: { initialState: nonFuelFilterState(), mapper: mapFilterToRequest },
    table: {
      key: "merchantPayments",
      mapper: (merchantPayments) =>
        mapDataToList({ merchantPayments, handleVoidPayment, handleActionsHistory }),
      columns: nonFuelColumns,
    },
  });

  const exports = useExport({
    api: generateReportAdmin,
    hasModal: true,
    reportType: "merchant-payment",
    mappedFilterState: {
      ...filter?.mappedFilterState,
      merchantPaymentStatuses: filter?.mappedFilterState.status,
      searchKey: search?.searchKey,
      platformType: PlatformType.PLB,
      redemptionStationIds: filter?.mappedFilterState?.redemptionStationIds,
      sessionId,
    },
  });

  delete table.result.count;

  return (
    <>
      <SuccessModal {...exports?.successModalComponent} />
      <div>
        <Intro
          title={locale.locqpayForNonFuel}
          subtitle={locale.viewLocqpayForNonfuelTransactions}
        />
      </div>
      <NonFuelFilter {...filter} {...search} {...exports} />
      <DataTableV2 {...table} />
      <ActionsHistoryModal {...actionsHistoryModal} />
      <VoidPaymentModal
        {...voidPaymentModal}
        remarks={remarks}
        onChangeRemarks={(v) => {
          setRemarks(v);
        }}
        loading={voidingRequest}
        onSubmit={voidPaymentModal.submit}
        value={value}
        setValue={setValue}
      />
    </>
  );
};

export default NonFuelModule;
