import { ApiPath } from "enums";
import { Get, Post } from "utils";

export const createMops = async ({ body }) => {
  const res = await Post(`${ApiPath.Mops}`, body);
  return res.data;
};

export const getMops = async ({ ...query }) => {
  const res = await Get(`${ApiPath.Mops}`, query);
  return res.data;
};
