import { Intro, DataTable, Text, PopOverMenu } from "components/commons";
import { Path, Role, Portal } from "enums";
import { useApi, useFilter, useMount, useModal } from "hooks";
import React, { useCallback, useMemo, useContext } from "react";
import { accountTypeColumns } from "./account-type-list-columns";
import styles from "./account-types-list.module.scss";
import locale from "localization";
import { accountTypesFilterState } from "./account-type-list-filter.state";
import { formatAmount, formatVolume, handleRequest, prettifyRedeemLimitType } from "utils";
import { getAccountTypes, deleteAccountType } from "apis";
import { useHistory } from "react-router-dom";
import { ConfirmModal } from "components/modals";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AccountType from "enums/account-type";
import { UserContext } from "contexts";

const AccountTypesListModule = () => {
  const history = useHistory();
  const confirmModal = useModal();
  const { close } = confirmModal;
  const { filterState, requestState, submitFilter } = useFilter(accountTypesFilterState());

  const { user } = useContext(UserContext);
  const { role } = user?.userAccesses?.find((element) => {
    return element.portal === Portal.LOCQ;
  });

  const {
    request: getAccountTypesRequest,
    loading: fetchingAccountTypes,
    result: getAccountTypesResult = { accountTypes: [], count: 0 },
  } = useApi({
    api: getAccountTypes,
    pageError: false,
  });

  useMount(() => {
    fetchAccounTypes();
  });

  const fetchAccounTypes = useCallback(
    (rs = { ...requestState }) => {
      getAccountTypesRequest(submitFilter(rs));
    },
    [getAccountTypesRequest, requestState, submitFilter]
  );

  const deleteAccountStatusRequest = useApi({
    api: deleteAccountType,
    handleOwnError: {
      badrequest: true,
    },
  });

  const submitDeleteAccountType = useCallback(
    (params) => {
      handleRequest(async () => {
        const result = await deleteAccountStatusRequest?.request({
          ...params,
        });
        if (result) {
          fetchAccounTypes();
        }
      });
    },
    [deleteAccountStatusRequest, fetchAccounTypes]
  );

  const handleDeleteAccountType = useCallback(
    (params, name) => {
      confirmModal.show({
        title: locale.deleteAccountTypeQuestion,
        content: <locale.Populate text={locale.deleteAccountTypeContent} items={[<b>{name}</b>]} />,
        secondary: {
          text: locale.Cancel,
        },
        primary: {
          text: locale.yesDeleteIt,
          onClick: async () => {
            submitDeleteAccountType(params);
            close();
          },
        },
      });
    },
    [close, confirmModal, submitDeleteAccountType]
  );

  const preparedAccountTypesData = useMemo(() => {
    const { accountTypes } = getAccountTypesResult;

    if (accountTypes && accountTypes.length > 0) {
      const preparedData = accountTypes.map((a) => {
        const map = new Map();

        map.set(
          "accountTypeName",
          <div>
            <Text className={styles.textStyle}>{a.name}</Text>
            {a.isDefault && <Text className={styles.subText}>{locale.default}</Text>}
          </div>
        );
        map.set(
          "pesoWalletSize",
          <Text className={styles.textStyle}>{formatAmount(a.walletSize)}</Text>
        );
        map.set(
          "fuelTankSize",
          <div>
            <Text className={styles.textStyle}>{formatVolume(a.fuelTankSize)}</Text>
          </div>
        );
        map.set(
          "cashInLimits",
          <div>
            <Text className={styles.textStyle}>{formatAmount(a.totalCashinLimitDaily)}</Text>
            <Text className={styles.subText}>{formatAmount(a.totalCashinLimitMonthly)}</Text>
          </div>
        );
        map.set(
          "buyFuelLimits",
          <div>
            <Text className={styles.textStyle}>{formatVolume(a.totalBuyFuelLimitDaily)}</Text>
            <Text className={styles.subText}>{formatVolume(a.totalBuyFuelLimitMonthly)}</Text>
          </div>
        );
        map.set(
          "payWithPesoLimits",
          <div>
            <Text className={styles.textStyle}>{formatAmount(a.totalPayWithPesoLimitDaily)}</Text>
            <Text className={styles.subText}>{formatAmount(a.totalPayWithPesoLimitMonthly)}</Text>
          </div>
        );
        map.set(
          "redemptionLimit",
          <div>
            <Text className={styles.textStyle}>{`${
              a.totalRedeemLimitDaily
            } - ${prettifyRedeemLimitType(a.totalRedeemLimitType)}`}</Text>
            <Text className={styles.subText}>{formatVolume(a.totalRedemptionLiterLimitDaily)}</Text>
          </div>
        );
        map.set(
          "actions",
          <PopOverMenu
            options={[
              {
                content: locale.viewDetails,
                onClick: () => {
                  history.push(Path.ViewAccountType, {
                    accountTypeId: a.accountTypeId,
                  });
                },
              },
              {
                removable: true,
                content: locale.editAccountType,
                disabled: role === Role.Accounting,
                onClick: () => {
                  history.push(Path.EditAccountType, {
                    accountTypeId: a.accountTypeId,
                  });
                },
              },
              {
                removable: true,
                content: locale.deleteAccountType,
                onClick: () => {
                  handleDeleteAccountType(
                    {
                      accountTypeId: a.accountTypeId,
                    },
                    a.name
                  );
                },
                disabled: a.type === AccountType.TNVS || a.isDefault || role === Role.Accounting,
              },
            ]}
          >
            <MoreVertIcon className={styles.icon} />
          </PopOverMenu>
        );

        return map;
      });
      return preparedData;
    }
    return [];
  }, [getAccountTypesResult, handleDeleteAccountType, history, role]);

  return (
    <div>
      <ConfirmModal
        {...confirmModal}
        titleStyle={styles.modalTitle}
        contentStyle={styles.modalContent}
      />
      <div>
      {role === Role.Accounting ? <div>
        <Intro
          titleStyle={styles.titleStyle}
          textStyle={styles.textStyle}
          title={locale.accountTypes}
          subtitle={locale.viewCreateAccountTypes}
        />
      </div> : <div>
      <Intro
          titleStyle={styles.titleStyle}
          textStyle={styles.textStyle}
          actionText={locale.addNewAccountType}
          actionOnClick={() => {
            history.push(Path.AddAccountType);
          }}
          title={locale.accountTypes}
          subtitle={locale.viewCreateAccountTypes}
        /></div>}
      </div>
      <div className={styles.table}>
        <DataTable
          loading={fetchingAccountTypes || deleteAccountStatusRequest?.loading}
          page={filterState.page}
          pageSize={filterState.perPage}
          columns={accountTypeColumns}
          data={preparedAccountTypesData}
          dataCount={getAccountTypesResult.count}
          hidePagination
        />
      </div>
    </div>
  );
};

export default AccountTypesListModule;
