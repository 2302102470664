import { ChargingStatus, PaymentStatus, PricingStationType, StationClass } from "enums";

export const dealerMonthlyFeeDetailsListFilterState = () => ({
  stationClass: {
    value: [StationClass.EFTI, StationClass.GUI, StationClass.SCI, StationClass.NA],
    isAll: true,
  },
  chargingStatus: { value: [ChargingStatus.ForCharging, ChargingStatus.Waived], isAll: true },
  paymentStatus: {
    value: [PaymentStatus.Paid, PaymentStatus.Pending, PaymentStatus.Waived],
    isAll: true,
  },
  stationType: {
    value: [
      PricingStationType.COCO,
      PricingStationType.CODO,
      PricingStationType.CODOX,
      PricingStationType.COXO,
      PricingStationType.DODO,
      PricingStationType.DODOX,
      PricingStationType.WIBCO,
      PricingStationType.WIDOX,
      PricingStationType.WILCO,
    ],
    isAll: true,
  },
  perPage: 10,
  page: 1,
});
