import {
  ActionButton,
  Autocomplete,
  Checkbox,
  Field,
  Modal,
  Text,
  TextField,
} from "components/commons";
import { UserContext } from "contexts";
import moment from "moment";
import React, { useContext, useMemo } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import { ErrorModal } from "components/modals";
import { useModal } from "hooks";

const FeatureFlagForm = ({ form = {}, action, data, action: formAction, mobile, ...modal }) => {
  const { user } = useContext(UserContext);
  const { email = "", firstName = "", lastName = "" } = user || {};
  const { fields = {}, modifyForm, isFormSubmittable, onSubmit } = form;
  const {
    enabled = {},
    remarks = {},
    key = {},
    location = {},
    users = {},
    app = {},
    android = {},
    ios = {},
    huawei = {},
  } = fields;
  const errorModal = useModal();

  const userChanges = useMemo(() => {
    if (!users.error) {
      const addedUsers = users.value
        ? users.value?.split(",").filter((email) => !users.initialValue?.split(",").includes(email))
        : [];
      const removedUsers = users.initialValue
        ? users.initialValue
            ?.trim()
            .split(",")
            .filter((email) => !users.value?.split(",").includes(email))
        : [];
      const message = [];
      if (addedUsers?.length) {
        message.push(`Added ${addedUsers.join(";")}`);
      }
      if (removedUsers?.length) {
        message.push(`Removed ${removedUsers.join(";")}`);
      }
      return message;
    }
    return [];
  }, [users]);
  const appOptions = {
    mobile: "PLC App",
    admin: "Admin Portal",
    fleet: "Fleet Portal",
    station: "Station Portal",
    oilco: "Oilco Portal",
  };
  return (
    <div>
      <Modal
        {...modal}
        style={{
          width: "600px",
        }}
      >
        <div
          style={{
            maxHeight: "500px",
            overflowY: "auto",
          }}
        >
          <Field {...key} label="Feature Flag">
            <div
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  width: "400px",
                  marginRight: "10px",
                }}
              >
                <Autocomplete
                  {...app}
                  options={Object.keys(appOptions).map((k) => ({
                    value: k,
                    label: appOptions[k],
                  }))}
                />
              </div>
              <TextField {...key} />
            </div>
          </Field>
          <Field {...location}>
            <Text subtitle>Short description where the feature flag is used. </Text>
            <TextField {...location} />
          </Field>
          <Field {...users}>
            <Text subtitle>If feature flag is disabled, users below can access the feature.</Text>
            <TextField {...users} />
            {users.messages?.map((message, key) => (
              <Text key={key} danger>
                {message}
              </Text>
            ))}
          </Field>
          <Field {...enabled}>
            <Checkbox
              {...enabled}
              onChange={(name, { value }) => {
                modifyForm({
                  enabled: {
                    value,
                  },
                  remarks: {
                    disabled: enabled.initialValue === value,
                  },
                });
              }}
            />
          </Field>
          {(modal.isMobile || app.value === "mobile") && (
            <div
              style={{
                marginLeft: "30px",
              }}
            >
              <Field {...ios}>
                <Checkbox
                  {...ios}
                  onChange={(name, { value }) => {
                    modifyForm({
                      ios: {
                        value,
                      },
                      remarks: {
                        disabled: ios.initialValue === value,
                      },
                    });
                  }}
                />
              </Field>
              <Field {...android}>
                <Checkbox
                  {...android}
                  onChange={(name, { value }) => {
                    modifyForm({
                      android: {
                        value,
                      },
                      remarks: {
                        disabled: android.initialValue === value,
                      },
                    });
                  }}
                />
              </Field>
              <Field {...huawei}>
                <Checkbox
                  {...huawei}
                  onChange={(name, { value }) => {
                    modifyForm({
                      huawei: {
                        value,
                      },
                      remarks: {
                        disabled: huawei.initialValue === value,
                      },
                    });
                  }}
                />
              </Field>
            </div>
          )}
          <Field {...remarks}>
            {userChanges.length ? (
              <div>
                {userChanges.map((changes, index) => (
                  <div key={index}>{changes}</div>
                ))}
              </div>
            ) : null}
            <TextField multiline {...remarks} />
          </Field>
          {formAction === "create" && (
            <div>To implement a new feature flag, kindly coordinate with dev team.</div>
          )}

          <ActionButton
            right
            disabled={!isFormSubmittable}
            items={[
              {
                primary: false,
                text: "Cancel",
                onClick: () => {
                  modal.close();
                },
              },
              {
                disabled: !isFormSubmittable,
                primary: true,
                text: "Save",
                onClick: () => {
                  onSubmit(({ ...values }) => {
                    try {
                      const toSave = {
                        users: values.users,
                        app: values.app,
                        enabled: values.enabled,
                        location: values.location,
                        key: values.key,
                      };
                      const toSaveData =
                        formAction === "edit"
                          ? {
                              ...data,
                              updatedAt: `${moment().format("X")},${data.updatedAt}`,
                              updatedBy: `${email}-${`${firstName} ${lastName}`.trim()},${
                                data.updatedBy
                              }`,
                              remarks: `${`${userChanges.join(" ")} ${
                                values.remarks
                                  ? values.remarks
                                  : values.enabled !== enabled.initialValue
                                  ? "Toggled flag"
                                  : ""
                              }`.trim()}-${values.enabled},${data.remarks}`,
                              ...toSave,
                            }
                          : {
                              updatedAt: `${moment().format("X")}`,
                              updatedBy: `${email}-${`${firstName} ${lastName}`.trim()}`,
                              remarks: `${data.remarks || "Create"}-${values.enabled}`,
                              createdAt: `${moment().format("X")}`,
                              createdBy: `${email},${`${firstName} ${lastName}`.trim()}`,
                              ...toSave,
                            };
                      const { key, app, ...obj } = toSaveData;
                      if (modal.isMobile || values.app === "mobile") {
                        obj.android = values.android;
                        obj.ios = values.ios;
                        obj.huawei = values.huawei;
                        const db = mobile.database();
                        db.ref(`feature-flag/${key}`).update(obj);
                      } else {
                        const db = firebase.database();
                        db.ref(`${app}/${key}`).update(obj);
                      }

                      modal.close();
                    } catch (e) {
                      errorModal.show();
                    }
                  });
                },
              },
            ]}
          />
        </div>
      </Modal>
      <ErrorModal {...errorModal} />
    </div>
  );
};

export default FeatureFlagForm;
