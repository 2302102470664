import { Text, Product, PopOver } from "components/commons";
import styles from "./customer-list.module.scss";
import classNames from "classnames";
import { formatDate, formatPesoWithDecimalRange } from "utils";
import { CustomerStatus, DateTime, Products } from "enums";
import locale from "localization";
import LabelAndPill from "components/commons/label-and-pill/label-and-pill";
import { prettifyCustomerStatus, prettifyCustomerRemarks } from "utils/pretty.utils";

export const mapDataToList = ({ customers, actionComponent, literBalance, customerProfile }) => {
  const { Gas91, Gas95, Gas97, Diesel } = Products;

  if (!!customerProfile?.updatedProfile) {
    customers =
      customers?.userId === customerProfile.updatedProfile.userId
        ? { ...customers, ...customerProfile.updatedProfile }
        : customers;
  }

  const {
    firstName,
    lastName,
    mobileNumber,
    email,
    userId,
    referenceStation,
    selectedStation,
    referralCode,
    createdAt,
    walletBalance,
    accountType,
    fuelBalances,
    updatedAt,
    status,
    remarks,
  } = customers;

  return {
    fullName: (
      <div className={styles.city}>
        <Text className={styles.upperText} subtitle>
          {firstName && firstName.toLowerCase()}
        </Text>
        <Text className={styles.lowerText} small>
          {lastName && lastName.toLowerCase()}
        </Text>
      </div>
    ),
    contact: (
      <div className={styles.city}>
        <Text className={styles.upperText} subtitle>
          {mobileNumber && mobileNumber}
        </Text>
        <Text className={classNames(styles.lowerText, styles.lowerCase)} small>
          {email && email.toLowerCase()}
        </Text>
        <br />
        <Text className={classNames(styles.lowerText, styles.lowerCase)} small>
          {userId && userId.toLowerCase()}
        </Text>
      </div>
    ),
    station: (
      <div className={styles.city}>
        <Text className={classNames(styles.upperText, styles.uppercase)} subtitle>
          {referenceStation && referenceStation.name.toLowerCase()}
        </Text>
        <Text className={classNames(styles.lowerText, styles.uppercase)} small>
          {selectedStation && selectedStation.name.toLowerCase()}
        </Text>
      </div>
    ),
    referral: (
      <div className={styles.city}>
        <Text className={styles.upperText} subtitle>
          {referralCode && referralCode}
        </Text>
      </div>
    ),
    registered: (
      <div className={styles.city}>
        <Text className={styles.upperText} subtitle>
          {createdAt && formatDate(createdAt, "D MMM YYYY")}
        </Text>
        <Text className={classNames(styles.upperText, styles.lowerCase)} subtitle>
          {createdAt && formatDate(createdAt, "hh:mma")}
        </Text>
      </div>
    ),
    walletBalance: (
      <div className={styles.city}>
        <Text className={styles.upperText} subtitle>
          {formatPesoWithDecimalRange(walletBalance ? walletBalance : 0, 2)}
        </Text>
      </div>
    ),
    product: (
      <div className={styles.product}>
        <Product cheddar>
          <Text className={styles.upperText} subtitle>
            {locale.diesel}
          </Text>
        </Product>
        <Product grass>
          <Text className={styles.upperText} subtitle>
            {locale.populate(locale.gasName, ["91"])}
          </Text>
        </Product>
        <Product salsa>
          <Text className={styles.upperText} subtitle>
            {locale.populate(locale.gasName, ["95"])}
          </Text>
        </Product>
        <Product deepBlue>
          <Text className={styles.upperText} subtitle>
            {locale.populate(locale.gasName, ["97"])}
          </Text>
        </Product>
      </div>
    ),
    literBalance: (
      <div className={classNames(styles.product, styles.right)}>
        <div>
          <Text className={styles.upperText} subtitle>
            {literBalance(fuelBalances, Diesel)}
          </Text>
        </div>
        <div>
          <Text className={styles.upperText} subtitle>
            {literBalance(fuelBalances, Gas91)}
          </Text>
        </div>
        <div>
          <Text className={styles.upperText} subtitle>
            {literBalance(fuelBalances, Gas95)}
          </Text>
        </div>
        <div>
          <Text className={styles.upperText} subtitle>
            {literBalance(fuelBalances, Gas97)}
          </Text>
        </div>
      </div>
    ),
    status: (
      <div className={styles.city}>
        <LabelAndPill
          label={updatedAt ? formatDate(updatedAt, DateTime.G) : "--"}
          pillProps={{
            grass: status === CustomerStatus.Active,
            cement: status === CustomerStatus.Deleted,
            deepRed: status === CustomerStatus.Blocked || status === CustomerStatus.Deactivated,
          }}
          pillLabel={prettifyCustomerStatus(status)}
        />
      </div>
    ),
    remarks: (
      <div>
        {status === CustomerStatus.Blocked ? (
          <PopOver
            content={
              <div
                style={{
                  maxHeight: "160px",
                  overflowX: "auto",
                }}
              >
                {prettifyCustomerRemarks(remarks)}
              </div>
            }
          >
            <div className="link">View</div>
          </PopOver>
        ) : (
          ""
        )}
      </div>
    ),
    action: actionComponent({ name: `${firstName} ${lastName}`, data: customers }),
    accountType: <Text className={styles.upperText}>{accountType?.name}</Text>,
  };
};

export const mapFilterToRequest = (filterState) => {
  const { status, redemptionStation, ...fs } = filterState;
  return {
    ...fs,
    status: !status?.isSelectedAll ? status.value.join(",") : null,
    referenceStationId: redemptionStation?.value,
  };
};
