import { Checkbox } from "components/commons";
import classNames from "classnames";
import React from "react";
import { useMemo } from "react";
import Text from "../text/text";
import styles from "./table.module.scss";
import { AddCircleRounded } from "@material-ui/icons";
import PopOver from "../pop-over/pop-over";
import EditViewColumns from "./edit-view-columns";

const Table = ({
  columns,
  data,
  actionHeaderProps,
  onCheckAll,
  hasColumnPicker,
  onChangeActiveColumns,
  ...props
}) => {
  const renderColumns = useMemo(() => {
    const rowHeader = [];
    columns.forEach((c, i) => {
      if (c.actionHeader) {
        rowHeader.push(
          <th
            key={i}
            style={{ verticalAlign: "bottom", textAlign: c.align || "left", width: c.width }}
            className={classNames(styles.headerText, c.className)}
            onMouseDown={() => {
              onCheckAll(!actionHeaderProps?.checked);
            }}
          >
            <Checkbox value={actionHeaderProps?.checked} disabled={props.disabled} />
          </th>
        );
      } else {
        rowHeader.push(
          <th
            key={i}
            style={{ verticalAlign: "top", textAlign: c.align || "left", width: c.width }}
            className={classNames(styles.headerText, c.className)}
          >
            <div
              style={{ textAlign: c.align || "left" }}
              className={classNames({
                [`${styles.lastColumnWithColumnPicker}`]:
                  i === columns.length - 1 && hasColumnPicker,
              })}
            >
              {c.text}
              {i === columns.length - 1 && hasColumnPicker ? (
                <div className={styles.modifyColumn}>
                  <PopOver
                    className={styles.modifyColumnContent}
                    simple
                    onClose={() => {
                      props.resetActiveColumn();
                    }}
                    contentStyle={styles.editColumnContent}
                    content={
                      <EditViewColumns
                        columns={columns}
                        onApply={onChangeActiveColumns}
                        {...props}
                      />
                    }
                  >
                    <AddCircleRounded className={classNames("pointer", styles.modifyColumnIcon)} />
                  </PopOver>
                </div>
              ) : null}
            </div>
          </th>
        );
      }
    });
    return rowHeader;
  }, [
    actionHeaderProps?.checked,
    columns,
    onCheckAll,
    hasColumnPicker,
    onChangeActiveColumns,
    props,
  ]);

  const renderRows = useMemo(() => {
    const rowContent = [];
    data.forEach((row, i) => {
      rowContent.push(
        <tr key={i}>
          {columns.map((c, j) => {
            const content = row.get(c.key);
            return (
              <td
                key={j}
                style={{ textAlign: c.align, verticalAlign: "top", ...c.style }}
                className={classNames(styles.content, c.className)}
              >
                <div
                  style={{ textAlign: c.align, verticalAlign: "top", ...c.style }}
                  className={classNames(styles.content, c.className)}
                >
                  {typeof content === "string" ? <Text>{content}</Text> : content}
                </div>
              </td>
            );
          })}
        </tr>
      );
    });
    return rowContent;
  }, [columns, data]);

  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead>
          <tr>{renderColumns}</tr>
        </thead>
        <tbody>{renderRows}</tbody>
      </table>
    </div>
  );
};

export default Table;
