import styles from "./price-buildup-filter.module.scss";
import { Field, Filter, SearchField, Button, Autocomplete } from "components/commons";
import React, { useCallback } from "react";
import locale from "localization";
import { PricingStationType } from "enums";

const { COCO, COXO, CODO, CODOX, DODO, DODOX, WIBCO, WIDOX, WILCO } = PricingStationType;

const PriceBuildupFilter = ({
  filterState,
  onSearchChange,
  onSearch,
  resetFilter,
  submitFilter,
  clearFilter,
  filterCount,
  exporting,
  onStationTypeChange,
  onSyncSeaoilPriceTool,
  syncingSeaoilPriceTool,
  exportReport,
}) => {
  const onSearchChangeCb = useCallback(
    (name, { value }) => {
      if (onSearchChange) {
        return onSearchChange("searchKey", { value });
      }
    },
    [onSearchChange]
  );

  const onSearchCb = useCallback(
    (searchKey) => {
      if (onSearch) {
        return onSearch(searchKey);
      }
    },
    [onSearch]
  );

  let checker = (arr, target) => target.every((v) => arr.includes(v));

  const onStationTypeChangeCb = useCallback(
    (name, { props }) => {
      const allItem = [COCO, COXO, CODO, CODOX, DODO, DODOX, WIBCO, WIDOX, WILCO];

      let newStationTypes = [...filterState.stationTypes];
      const newValue = props.value;
      let index = newStationTypes.indexOf(newValue);

      if (index !== -1) {
        if (newValue === "all") {
          newStationTypes = [];
        } else {
          const allIndex = newStationTypes.indexOf("all");
          if (allIndex !== -1) {
            newStationTypes.splice(allIndex, 1);
          }
          index = newStationTypes.indexOf(newValue);
          newStationTypes.splice(index, 1);
        }
      } else {
        if (newValue === "all") {
          newStationTypes = ["all", COCO, COXO, CODO, CODOX, DODO, DODOX, WIBCO, WIDOX, WILCO];
        } else {
          newStationTypes.push(props.value);
        }
      }
      if (checker(newStationTypes, allItem)) {
        newStationTypes = ["all", COCO, COXO, CODO, CODOX, DODO, DODOX, WIBCO, WIDOX, WILCO];
      }

      return onStationTypeChange(newStationTypes);
    },
    [filterState.stationTypes, onStationTypeChange]
  );

  return (
    <div className={styles.filter}>
      <Filter
        submit={submitFilter}
        close={resetFilter}
        clear={clearFilter}
        filterCount={filterCount}
      >
        <Field
          className={styles.filterInput}
          label={locale.stationType}
          labelPosition={"left"}
          childrenClassName={styles.fieldContent}
        >
          <Autocomplete
            name="stationTypes"
            value={filterState.stationTypes}
            options={[
              { label: locale.all, value: "all" },
              { label: COCO, value: COCO },
              { label: CODO, value: CODO },
              { label: CODOX, value: CODOX },
              { label: COXO, value: COXO },
              { label: DODO, value: DODO },
              { label: DODOX, value: DODOX },
              { label: WIBCO, value: WIBCO },
              { label: WIDOX, value: WIDOX },
              { label: WILCO, value: WILCO },
            ]}
            onChange={onStationTypeChangeCb}
            multiple
          />
        </Field>
      </Filter>

      <Field className={styles.search}>
        <SearchField
          placeholder={`${locale.searchStationNameSationCode}`}
          value={filterState.searchKey}
          onChange={onSearchChangeCb}
          onEnter={onSearchCb}
          restrict={false}
        />
      </Field>
      <Field className={styles.downloadButton}>
        <Button
          startIcon={<span className="icon-sync" />}
          onClick={onSyncSeaoilPriceTool}
          loading={syncingSeaoilPriceTool}
          outline
        >
          {locale.syncWithPricingTool}
        </Button>
        <Button
          startIcon={<span className="icon-download" />}
          exportButton
          onClick={exportReport}
          loading={exporting}
        >
          {locale.export}
        </Button>
      </Field>
    </div>
  );
};

export default PriceBuildupFilter;
