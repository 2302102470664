import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Pill, PopOverMenu, Text, Button, PopOverList } from "components/commons";
import { DisplayCustomerName } from "components/commons/data-display";
import LabelAndCircle from "components/commons/label-and-circle/label-and-circle";
import { BuyFuelStatus, CustomerStatus, DateTime, Products } from "enums";
import { dateTimeFormat, formatCurrency, formatDate, formatVolume } from "utils";
import { prettifyBuyFuelStatus, prettifyProduct } from "utils/pretty.utils";
import locale from "localization";
import styles from "./buy-fuel-filter.module.scss";

const getProductColor = (product) => {
  return (
    {
      [Products.Diesel]: "#ffaa01",
      [Products.Gas91]: "#019f3e",
      [Products.Gas95]: "#ee3b34",
      [Products.Gas97]: "#2e4491",
    }[product] || null
  );
};

export const mapDataToList = ({ buyFuel, voidTransactionModal, editRemarksModal }) => {
  const {
    buyFuelId,
    cashinId,
    productCode,
    creditPrice,
    literVolume,
    totalCreditAmount,
    createdAt,
    userDetail,
    station,
    discountCampaign,
    status,
    voidedAt,
    remarks,
    isPartiallyRedeemed,
  } = buyFuel;
  const voidTransaction = {
    content: locale.voidTransaction,
    onClick: () =>
      voidTransactionModal({
        buyFuelId,
        amount: formatCurrency(totalCreditAmount),
        liters: formatVolume(literVolume),
      }),
  };
  const editRemarks = {
    content: locale.editRemarks,
    onClick: () =>
      editRemarksModal({
        buyFuelId,
        remarks,
      }),
  };
  const buyFuelAction = {
    [BuyFuelStatus.Success]: voidTransaction,
    [BuyFuelStatus.Voided]: editRemarks,
  };

  return {
    buyFuelId,
    cashinId: cashinId || "--",
    userDetails: <DisplayCustomerName data={userDetail} />,
    referenceStation: station?.name || "",
    purchasedDate: formatDate(createdAt, DateTime.G),
    product: (
      <div className="min-70">
        {
          <LabelAndCircle
            label={prettifyProduct(productCode)}
            color={getProductColor(productCode)}
          />
        }
      </div>
    ),
    creditPrice: <div className="min-70">{formatCurrency(creditPrice)}</div>,
    volume: <div className="min-70">{formatVolume(literVolume)}</div>,
    totalCreditAmount: <div className="min-70">{formatCurrency(totalCreditAmount)}</div>,
    discountCampaign: discountCampaign?.campaignName || "",
    statusDate: (
      <>
        <Pill grass={status === BuyFuelStatus.Success} cement={status === BuyFuelStatus.Voided}>
          {prettifyBuyFuelStatus(status)}
        </Pill>
        <Text className={styles.subBusinessId}>
          {voidedAt
            ? dateTimeFormat(voidedAt)
            : isPartiallyRedeemed
            ? locale.partiallyRedeemed
            : ""}
        </Text>
      </>
    ),
    remarks: remarks && (
      <>
        <PopOverList list={[remarks].map((label) => label)}>
          <Button link>{locale.view}</Button>
        </PopOverList>
      </>
    ),
    action:
      (isPartiallyRedeemed && status !== BuyFuelStatus.Voided) ||
      (userDetail?.status !== CustomerStatus.Deleted && (
        <PopOverMenu options={[buyFuelAction[status]]}>
          <MoreVertIcon />
        </PopOverMenu>
      )),
  };
};

export const mapFilterToRequest = (filterState) => {
  const { referenceStation, productCodes, ...p } = filterState;
  if (referenceStation.value.length) {
    p.referenceStationIds = referenceStation.value
      .map(({ value }) => {
        return value;
      })
      .join(",");
  } else {
    p.referenceStationIds = null;
  }

  return {
    ...p,
    productCodes: productCodes.length < 4 ? productCodes.join(",") : null,
  };
};
