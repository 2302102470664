import React from "react";
import { Path } from "enums";
import { Route, Switch, Redirect } from "react-router-dom";
import SettlementModule from "modules/cash-flow/settlement/settlement.module";
import ProcessSettlement from "modules/cash-flow/settlement/process-settlement/process-settlement.module";

const SettlementPage = () => {
  return (
    <Switch>
      <Route exact path={Path.LocqSettlement} component={SettlementModule} />
      <Route exact path={Path.ProcessSettlement} component={ProcessSettlement} />
      <Redirect to={Path.ProcessSettlement} />
    </Switch>
  );
};

export default SettlementPage;
