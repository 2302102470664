import DashboardModule from "modules/dashboard/dashboard.module";
import React from "react";

const DashboardPage = () => {
  return (
    <div>
      <DashboardModule />
    </div>
  );
};

export default DashboardPage;
