import locale from "localization";

export const payWithLOCQPayColumns = [
  {
    key: "paymentId",
    text: locale.locqPayID,
    style: {
      minWidth: "70px",
    },
  },
  {
    key: "businessNameId",
    text: locale.businessNameId,
    style: {
      minWidth: "70px",
    },
  },
  {
    key: "paymentStationAndStationBusinessName",
    text: `${locale.gasUpStationWithOutDash}/${locale.stationBusinessName}`,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "cardNumber",
    text: locale.cardNumber,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "driversNameandId",
    text: `${locale.driversName}/${locale.driversId}`,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "plateNumber",
    text: locale.plateNumber,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "product",
    text: locale.product,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "paidAt",
    text: `${locale.dateOfTransaction}`,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "amount",
    text: locale.paymentAmount,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "pumpPrice",
    text: `${locale.pumpPrice} (P/L)`,
    style: {
      minWidth: "70px",
    },
  },
  {
    key: "discount",
    text: `${locale.discount}`,
    style: {
      minWidth: "70px",
    },
  },
  {
    key: "discountedVolume",
    text: `${locale.discountPerLiter} (P/L)`,
    style: {
      minWidth: "70px",
    },
  },
  {
    key: "finalAmount",
    text: locale.finalAmountPaid,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "orNumber",
    text: locale.orNumber,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "status",
    text: locale.status,
    style: {
      minWidth: "100px",
    },
  },
  {
    key: "settlementStatus",
    text: `${locale.settlementStatus}/${locale.settlementId}`,
    style: {
      minWidth: "100px",
    },
  },
  // { key: "remarks", text: locale.remarks,  style: {
  //   minWidth: "200px",
  // }, },
  {
    key: "options",
    text: locale.actions,
    style: {
      minWidth: "100px",
    },
  },
];
