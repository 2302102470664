export const initialFilterState = () => ({
  stationTypes: {
    value: ["COCO", "CODO", "CODOX", "COXO", "DODO", "DODOX", "WIBCO", "WIDOX", "WILCO"],
    isAll: true,
  },
  referenceStation: {
    value: [],
    isAll: true,
  },
});
