import { Grid } from "@material-ui/core";
import { getConversionBuyFuel } from "apis/buy-fuel.api";
import { getFuelBalanceByUserId } from "apis/fuel-balance.api";
import { createProductConversion, getProductConversionPrice } from "apis/product-conversion.api";
import { getUserByMobileNumber } from "apis/user.api";
import classNames from "classnames";
import {
  Autocomplete,
  Button,
  DatePicker,
  Field,
  Intro,
  Text,
  TextField,
  TextfieldWithLabel,
  Title,
} from "components/commons";
import { SelectStation } from "components/field";
import { ConfirmModal } from "components/modals";
import { productOptions } from "data/select-options";
import { DateTime, Path } from "enums";
import PlatformType from "enums/platform-type";
import { useApi, useModal } from "hooks";
import locale from "localization";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  debounced,
  formatDate,
  formatAmount,
  formatVolume,
  isLiterValid,
  prettifyFuelCodes,
} from "utils";
import { prettifyProduct } from "utils/pretty.utils";
import { formatNamePossessive, isMobileNumberValid } from "utils/text.utils";
import styles from "./product-conversion-form.module.scss";

const ProductConversionForm = ({ title, form = {} }) => {
  const history = useHistory();
  const {
    fields = {},
    modifyField,
    modifyForm,
    isFormSubmittable,
    dirty,
    submitForm,
    getFormValues,
  } = form;
  const { account, beforeProduct, afterProduct, referenceStation, referenceDate, volume } = fields;
  const [typingVolume, setTypingVolume] = useState(false);
  const [typingMobileNumber, setTypingMobileNumber] = useState(false);
  const validateUser = useApi({
    api: getUserByMobileNumber,
    handleOwnError: true,
  });

  const conversionBuyFuel = useApi({
    api: getConversionBuyFuel,
    handleOwnError: true,
  });

  const { result: priceResult = {}, ...productConversionPrice } = useApi({
    api: getProductConversionPrice,
  });

  const createProductConversionApi = useApi({
    api: createProductConversion,
  });

  const userFuelBalanceApi = useApi({
    api: getFuelBalanceByUserId,
  });

  const userBalance = useMemo(() => {
    if (!beforeProduct.value && userFuelBalanceApi?.result?.data) {
      return 0;
    }
    const selected = userFuelBalanceApi?.result?.data?.find((item) => {
      return item?.productCode === beforeProduct?.value;
    });
    return selected?.fuelBalance || 0;
  }, [beforeProduct, userFuelBalanceApi]);

  const confirmModal = useModal();
  const successModal = useModal();

  const [userInfo, setUserInfo] = useState({});
  const [conversionBuyFuelInfo, setConversionBuyFuelInfo] = useState({});

  const {
    userId,
    firstName,
    lastName,
    referenceStation: referenceStationData = {},
    referenceStationId,
  } = userInfo || {};

  const { name: referenceStationName } = referenceStationData;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(
    debounced(async () => {
      const mobileNumber = account.value.trim();
      if (isMobileNumberValid(mobileNumber)) {
        try {
          const d = await validateUser.request({ mobileNumber: mobileNumber });
          setUserInfo(d.data);
          await userFuelBalanceApi.request({
            userId: d.data.userId,
          });
          getConversionBuyFuelId({
            userId: d.data.userId,
          });
          setTypingMobileNumber(false);
        } catch (e) {
          setTypingMobileNumber(false);
          modifyForm({
            account: {
              value: mobileNumber,
              noUser: true,
            },
          });
        }
      } else {
        setTypingMobileNumber(false);
      }
    }, 1000),
    [account.value]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(
    debounced(async () => {
      getProductConversionPriceAmount({
        literVolume: volume.value,
      });
      setTypingVolume(false);
    }, 1000),
    [volume.value]
  );

  const getProductConversionPriceAmount = useCallback(
    async (d) => {
      const uId = d.userId || userId;
      const pCode = d.productCode || afterProduct.value;
      const buyFuelId = conversionBuyFuelInfo.buyFuelId;
      const rStation = d.referenceStationId || referenceStation.value;
      const rDate = d.referenceDate || referenceDate.value;
      const lVolume = d.literVolume || volume.value;

      if (uId && pCode && buyFuelId && rDate && rStation && lVolume) {
        await productConversionPrice.request({
          userId: uId,
          productCode: pCode,
          buyFuelId: buyFuelId,
          referenceStationId: rStation,
          referenceDate: formatDate(rDate, DateTime.I),
          literVolume: lVolume,
        });
      }
    },
    [
      userId,
      conversionBuyFuelInfo,
      productConversionPrice,
      afterProduct,
      referenceDate,
      referenceStation,
      volume,
    ]
  );

  const userAccountName = `${firstName} ${lastName}`.trim();

  const getConversionBuyFuelId = useCallback(
    async (param) => {
      const { changes, ...d } = param || {};
      const uId = d.userId || userId;
      const pCode = d.productCode || beforeProduct.value;
      if (uId && pCode) {
        try {
          const res = await conversionBuyFuel.request({
            userId: uId,
            productCode: pCode,
          });
          setConversionBuyFuelInfo(res || {});
          getProductConversionPriceAmount({
            productCode: pCode,
            buyFuelId: res.buyFuelId,
          });
          modifyForm({
            volume: {
              maximum: res.fuelBalanceFifo?.remainingVolume,
            },
            beforeProduct: {
              value: pCode,
              noData: false,
            },
            referenceDate: {
              value: null,
            },
            ...changes,
          });
        } catch (e) {
          const newValue = {
            ...changes,
            volume: {
              maximum: 0,
            },
            beforeProduct: {
              value: pCode,
            },
          };
          const { errorCode } = e?.data || {};
          if (errorCode === "BF1001") {
            newValue.beforeProduct.noData = true;
          } else {
            e.showError();
          }
          setConversionBuyFuelInfo({});
          modifyForm(newValue);
        }
      }
    },
    [userId, beforeProduct, conversionBuyFuel, getProductConversionPriceAmount, modifyForm]
  );
  const handleSubmitForm = useCallback(
    (formValues) => {
      if (priceResult.literVolume) {
        confirmModal.show({ values: formValues });
      }
    },
    [confirmModal, priceResult]
  );

  const onConfirmSubmit = useCallback(async () => {
    const values = getFormValues();

    await createProductConversionApi.request({
      platformType: PlatformType.PLC,
      mobileNumber: values.account,
      productCode: values.afterProduct,
      buyFuelId: conversionBuyFuelInfo.buyFuelId,
      liter: Number(volume.value),
      referenceStationId: values.referenceStation,
      referenceDate: formatDate(values.referenceDate, DateTime.I),
    });
    confirmModal.close();
    successModal.show();
  }, [
    confirmModal,
    getFormValues,
    createProductConversionApi,
    successModal,
    conversionBuyFuelInfo,
    volume,
  ]);

  return (
    <div>
      <Intro title={title} />
      <Title medium className={styles.title}>
        {locale.accountDetails}
      </Title>
      <div className={styles.content}>
        <Field
          className={styles.field}
          labelPosition={"left"}
          labelClassName={styles.fieldLabel}
          childrenClassName={styles.fieldContent}
          label={account.label}
        >
          <TextField
            {...account}
            disabled={validateUser.loading}
            onChange={(name, { value }) => {
              setTypingMobileNumber(true);
              modifyForm({
                ...conversionDetailsEmptyValue,
                [name]: { value, noUser: false },
              });
              setUserInfo({});
            }}
            loading={validateUser.loading}
          />
        </Field>
        {(userId && account.value) || (account.error && account.message) ? (
          <Field
            className={classNames(styles.field, styles.info)}
            labelPosition={"left"}
            labelClassName={styles.fieldLabel}
            childrenClassName={styles.fieldContent}
            label={" "}
          >
            {account.error && account.message ? (
              <Text danger>{account.message}</Text>
            ) : (
              <>
                <Text subtitle>
                  {locale.accountName}: {userAccountName}
                </Text>
                <Text subtitle>
                  {locale.referenceStation}: {referenceStationName || `#${referenceStationId}`}
                </Text>
              </>
            )}
          </Field>
        ) : null}
      </div>

      <div className={styles.conversionTable}>
        <Title medium className={styles.title}>
          {locale.conversionDetails}
        </Title>
        <Table
          items={[
            ["", locale.beforeConversion, locale.afterConversion],
            [
              locale.product,
              <div className={styles.smallField}>
                <Autocomplete
                  {...beforeProduct}
                  disabled={validateUser.loading || beforeProduct.disabled || typingMobileNumber}
                  capitalize={false}
                  options={productOptions}
                  onChange={(name, { value, label }) => {
                    const changes = {
                      [name]: {
                        value,
                        label,
                        noData: false,
                      },
                      [referenceDate.name]: {
                        value: null,
                      },
                    };
                    if (value === afterProduct.value) {
                      changes.afterProduct = {
                        value: "",
                        label: "",
                      };
                    }
                    modifyForm(changes);
                    getConversionBuyFuelId({ productCode: value, changes });
                  }}
                />
                {beforeProduct.error && beforeProduct.message ? (
                  <Text danger>{beforeProduct.message}</Text>
                ) : null}
              </div>,
              <div className={styles.smallField}>
                <Autocomplete
                  {...afterProduct}
                  capitalize={false}
                  options={productOptions.filter(({ value }) => {
                    return value !== beforeProduct.value;
                  })}
                  onChange={(name, { value }) => {
                    modifyField(name, { value });
                    getProductConversionPriceAmount({
                      productCode: value,
                    });
                  }}
                />
              </div>,
            ],
            [
              locale.buyFuelId,
              <div className={styles.smallField}>
                <TextField
                  value={conversionBuyFuelInfo.buyFuelId}
                  disabled
                  placeholder="e.g 23560"
                  loading={conversionBuyFuel.loading}
                />
              </div>,
              locale.aNewIdWillBeCreated,
            ],
            [
              locale.referenceStation,
              <TextField
                value={
                  conversionBuyFuelInfo.station?.name || conversionBuyFuelInfo.referenceStationId
                }
                disabled
                placeholder={locale.select}
              />,
              <SelectStation
                disableAllOption
                {...referenceStation}
                onChange={(name, { value, label }) => {
                  modifyField(name, { value, label });
                  getProductConversionPriceAmount({
                    referenceStationId: value,
                  });
                }}
                className={styles.fullWidth}
              />,
            ],
            [
              locale.referenceDate,
              <TextField
                disabled
                placeholder={locale.setDate}
                value={
                  conversionBuyFuelInfo.createdAt
                    ? formatDate(conversionBuyFuelInfo.createdAt, DateTime.A)
                    : null
                }
              />,
              <DatePicker
                {...referenceDate}
                disabled={
                  referenceDate.disabled || !beforeProduct.value || !conversionBuyFuelInfo.buyFuelId
                }
                onChange={(name, { value }) => {
                  modifyField(name, { value });
                  getProductConversionPriceAmount({
                    referenceDate: value,
                  });
                }}
                className={styles.fullWidth}
                disableStartDate={
                  conversionBuyFuelInfo.createdAt ? moment(conversionBuyFuelInfo.createdAt) : null
                }
              />,
            ],
            [
              locale.creditPrice,
              <div className={styles.smallField}>
                <TextfieldWithLabel
                  label={"₱"}
                  disabled
                  placeholder={"0.00/L"}
                  value={conversionBuyFuelInfo.creditPrice}
                />
              </div>,
              <div className={styles.smallField}>
                <TextfieldWithLabel
                  value={priceResult.creditPrice}
                  label={"₱"}
                  disabled
                  placeholder={"0.00/L"}
                  loading={productConversionPrice.loading}
                />
              </div>,
            ],
            [
              locale.volume,
              <div>
                <div className={styles.smallField}>
                  <TextfieldWithLabel
                    {...volume}
                    label={"L"}
                    error={
                      volume.error ||
                      (conversionBuyFuelInfo.buyFuelId &&
                        !conversionBuyFuelInfo.fuelBalanceFifo?.remainingVolume)
                    }
                    placeholder={"0.000"}
                    onChange={(name, { value }) => {
                      if (isLiterValid(value)) {
                        modifyField(name, { value });
                        setTypingVolume(true);
                      }
                    }}
                  />
                </div>

                {conversionBuyFuelInfo.buyFuelId && beforeProduct.value ? (
                  <div className={styles.marginTop}>
                    {!conversionBuyFuelInfo.fuelBalanceFifo?.remainingVolume && (
                      <Text danger>
                        {locale.populate(locale.noFuelBalanceAvailable, [
                          prettifyProduct(beforeProduct.value),
                        ])}
                      </Text>
                    )}
                    {volume.error && volume.message ? (
                      <Text danger>{volume.message}</Text>
                    ) : (
                      <Text subtitle>
                        {locale.buyFuelId.toUpperCase()} {conversionBuyFuelInfo.buyFuelId}{" "}
                        {locale.balance}:{" "}
                        {formatVolume(conversionBuyFuelInfo.fuelBalanceFifo?.remainingVolume || 0)}
                      </Text>
                    )}
                    <Text subtitle>
                      {locale.populate(locale.usersFuelBalance, [
                        prettifyFuelCodes(beforeProduct.value),
                      ])}
                      : {formatVolume(userBalance || 0)}
                    </Text>
                  </div>
                ) : null}
              </div>,
              <div className={styles.smallField}>
                <TextfieldWithLabel
                  label={"L"}
                  disabled
                  placeholder={"0.000"}
                  value={formatAmount(priceResult.literVolume || 0, "", 3, 3)}
                  loading={productConversionPrice.loading}
                />
              </div>,
            ],
          ]}
        />
      </div>
      <Button
        primary
        disabled={
          !isFormSubmittable ||
          !dirty ||
          productConversionPrice.loading ||
          conversionBuyFuel.loading ||
          typingVolume ||
          !conversionBuyFuelInfo.fuelBalanceFifo?.remainingVolume
        }
        onClick={() => {
          submitForm(handleSubmitForm);
        }}
      >
        {locale.continue}
      </Button>
      <ConfirmModal
        {...confirmModal}
        title={locale.proceedWithProductConversion}
        primary={{
          text: locale.confirmProductConversion,
          onClick: () => {
            onConfirmSubmit();
          },
          loading: createProductConversionApi.loading,
        }}
        secondary
      >
        <div
          style={{
            width: "400px",
            textAlign: "center",
          }}
        >
          <locale.Populate
            className="text-center"
            text={locale.youAreAboutToConvertUserDoYouWantToProceed}
            items={[
              formatNamePossessive(userAccountName),
              <b>{prettifyProduct(beforeProduct.value)}</b>,
              formatVolume(volume.value || 0),
              <b>{prettifyProduct(afterProduct.value)}</b>,
              formatAmount(priceResult.literVolume || 0, "", 3, 3),
            ]}
          />
        </div>
      </ConfirmModal>
      <ConfirmModal
        {...successModal}
        title={locale.productConverted}
        primary={{
          onClick: () => {
            successModal.close();
            history.push(Path.ProductConversion);
          },
        }}
      >
        <div
          style={{
            width: "430px",
            textAlign: "center",
          }}
        >
          <locale.Populate
            className="text-center"
            text={locale.youHaveSuccessfullyConvertedUserProduct}
            items={[
              formatNamePossessive(userAccountName),
              <b>{prettifyProduct(beforeProduct.value)}</b>,
              formatVolume(volume.value || 0),
              <b>{prettifyProduct(afterProduct.value)}</b>,
              formatAmount(priceResult.literVolume || 0, "", 3, 3),
            ]}
          />
        </div>
      </ConfirmModal>
    </div>
  );
};

const Table = ({ items }) => {
  return (
    <div>
      {items.map((item, key) => {
        return (
          <Grid key={key} container spacing={2} className={styles.row}>
            <Grid item xs={3} className={classNames(styles.column, styles.rowName)}>
              {item[0]}
            </Grid>
            <Grid item xs={4} className={styles.column}>
              {item[1]}
            </Grid>
            <Grid item xs={4} className={classNames(styles.column, styles.fieldRow)}>
              {item[2]}
            </Grid>
          </Grid>
        );
      })}
    </div>
  );
};

const conversionDetailsEmptyValue = {
  // beforeProduct: { value: null },
  // afterProduct: { value: null },
  referenceStation: { value: null },
  referenceDate: { value: null },
};

export default ProductConversionForm;
