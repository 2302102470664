import React from "react";
import { Intro } from "components/commons";

const DashboardModule = () => {

  return (
    <div>
      <div>
        <Intro title="Dashboard" />
      </div>
    </div>
  );
};

export default DashboardModule;
