import React, { useContext, useCallback } from "react";
import { useHistory } from "react-router-dom";

import { ActionButton, Intro, Modal, Text } from "components/commons";
import { generateReportAdmin, getCashIns, batchManualCashIn } from "apis";
import { Role, Path, Portal } from "enums";
import useDataTable from "hooks/useDataTable";
import locale from "localization";

import { cashInColumns } from "./cashin-columns";
import CashInFilter from "./cashin-filter";
import { cashInFilterState } from "./cashin-filter.state";
import { apiMapper, mapDataToList, mapFilterToRequest } from "./cashin.mapper";
import CashInList from "./cashin.list";
import useExport from "hooks/useExport";
import { SuccessModal } from "components/modals";
import { SessionContext, UserContext} from "contexts";
import { useModal, useApi } from "hooks";
import CashInImportModal from "./cashin-import";
import { handleRequest } from "utils";

const CashInModule = () => {
  const { sessionId } = useContext(SessionContext);
  const cashinImportModal = useModal();
  const cashinImportModalResult = useModal();

  const { user } = useContext(UserContext);
  const { role } = user?.userAccesses?.find((element) => {
    return element.portal === Portal.LOCQ;
  });

  const { filter, search, table } = useDataTable({
    api: { api: getCashIns, mapper: apiMapper },
    filter: { initialState: cashInFilterState(), mapper: mapFilterToRequest },
    table: { key: "cashins", mapper: mapDataToList, columns: cashInColumns },
  });

  const { request: batchManualCashInRequest, loading: batchManualCashInLoading } = useApi({
    api: batchManualCashIn,
    handleOwnError: true,
  });

  const exportReport = useExport({
    api: generateReportAdmin,
    reportType: "cashin",
    hasModal: true,
    mappedFilterState: {
      ...apiMapper(filter?.mappedFilterState),
      platformType: "plb",
      searchKey: search?.searchKey,
      sessionId,
    },
  });

  const history = useHistory();

  const openImportCb = useCallback(() => {
    cashinImportModal.show();
  }, [cashinImportModal]);

  const submitCashInManual = (data, importModal) => {
    handleRequest(
      async () => {
        await batchManualCashInRequest({
          data: [...data],
        });

        cashinImportModalResult.show({
          title: locale.manualCashInImportSuccess,
          children: (
            <div>
              <Text normal align="center" style={{ marginBottom: "20px", marginTop: "20px" }}>
                {locale.detailsHaveBeenSuccessfullyUpdated}
              </Text>
              <ActionButton
                center
                items={[
                  {
                    text: locale.gotIt,
                    primary: true,
                    onClick: () => {
                      cashinImportModalResult.close();
                    },
                  },
                ]}
              />
            </div>
          ),
        });

        table.refetch();
        importModal.close();
      },
      {
        C1020: (e) => {
          importModal.close();
          cashinImportModalResult.show({
            title: locale.manualCashInImportFailed,
            children: (
              <div>
                <Text
                  normal
                  align="center"
                  style={{ marginBottom: "20px" }}
                >{`${locale.unableToImportCheckTheFollowingEntries}: `}</Text>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Text label style={{ marginRight: "40px" }}>{`${locale.invalidEntries}:`}</Text>
                  <Text normal strong>{`${
                    e?.invalidRowList.length
                  } (rows no. ${e?.invalidRowList.join()})`}</Text>
                </div>
                <ActionButton
                  center
                  items={[
                    {
                      text: locale.retry,
                      primary: true,
                      onClick: () => {
                        cashinImportModalResult.close();
                      },
                    },
                  ]}
                />
              </div>
            ),
          });
        },
      }
    );
  };
  
  return (
    <>
      <SuccessModal {...exportReport?.successModalComponent} />
      <CashInImportModal
        {...cashinImportModal}
        submitCashInManual={submitCashInManual}
        batchManualCashInLoading={batchManualCashInLoading}
      />
      <Modal {...cashinImportModalResult} />
      {role === Role.Accounting ? <div>
        <Intro
          title={locale.cashIn}
          subtitle={locale.viewTrackCashActivities}
        />
      </div> : <div>
        <Intro
          actionText={locale.createManualCashIn}
          actionOnClick={() => {
            history.push(Path.ManualCashIn);
          }}
          title={locale.cashIn}
          subtitle={locale.viewTrackCashActivities}
        />
      </div>}
      <CashInFilter {...filter} {...search} {...exportReport} openImport={openImportCb} />
      <CashInList table={table} />
    </>
  );
};

export default CashInModule;
