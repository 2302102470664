export const initialFilterState = ({ searchKey }) => ({
  startDate: null,
  endDate: null,
  cashinStatus: "",
  cashinChannel: "all",
  cashinMethod: "all",
  searchKey,
  mode: "",
  page: 1,
  perPage: 10,
});
