import React from "react";
import { Switch, Redirect, Route } from "react-router";
import { Path } from "enums";
import DiscountCampaignPage from "./discount-campaign.page";
import VoucherCampaignPage from "./voucher-campaign.page";
import LOCQPayDiscountCampaignPage from "./locqpay-discount-campaign.page";
import PanelButtons from "components/commons/panel-button/panel-buttons";

const CampaignPage = () => {
  return (
    <div>
      <Switch>
        <Route path={Path.DiscountCampaign} component={DiscountCampaignPage} />
        <Route path={Path.VoucherCampaign} component={VoucherCampaignPage} />
        <Route path={Path.LOCQPayDiscountCampaigns} component={LOCQPayDiscountCampaignPage} />
        <Redirect to={Path.DiscountCampaign} />
      </Switch>
    </div>
  );
};

export const CampaignListModule = ({ children }) => {
  return (
    <div>
      <div
        style={{
          marginBottom: "30px",
        }}
      >
        <PanelButtons
          items={[
            {
              text: "Discount Campaigns",
              path: Path.DiscountCampaign,
            },
            {
              text: "Voucher Campaigns",
              path: Path.VoucherCampaign,
            },
            {
              text: "LOCQPay Discount Campaigns",
              path: Path.LOCQPayDiscountCampaigns,
            },
          ]}
        />
      </div>
      {children}
    </div>
  );
};

export default CampaignPage;
