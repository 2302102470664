import locale from "localization";

export const columns = [
  { key: "businessNameId", text: `${locale.businessName}/${locale.id}`, width: "12%" },
  { key: "fleetCardNo", text: `${locale.fleetCardNumber}/${locale.fleetCardType}`, width: "12%" },
  { key: "assignedDriver", text: locale.assignedDriver, width: "12%" },
  { key: "assignedVehicle", text: locale.assignedVehicle, width: "12%" },
  { key: "product", text: locale.product, width: "12%" },
  { key: "locqpayLimit", text: locale.locqpayLimit, width: "12%" },
  { key: "locqpayPaymentThisMonth", text: locale.locqpayPaymentThisMonth, width: "12%" },
  { key: "transactionLimit", text: locale.transactionLimit, width: "10%" },
  { key: "transactionThisMonth", text: locale.transactionThisMonth, width: "10%" },
  { key: "status", text: `${locale.status}/${locale.dateLastUpdate}`, width: "12%" },
  { key: "actions", text: locale.action, width: "10%" },
];
