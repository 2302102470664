import classNames from "classnames";
import { Button, Dropdown, Field, Text, TextField, Title } from "components/commons";
import { InputAmount, InputLitre } from "components/field";
import locale from "localization";
import React from "react";
import { formatCurrencyWithoutDecimal, formatVolumeWithoutDecimal } from "utils";
import styles from "./account-type-details.module.scss";

const AccountTypeDetailsFormModule = ({ ...stateForm }) => {
  const {
    viewMode,
    addMode,
    fields,
    isFormSubmittable,
    loading,
    submitForm,
    handleSubmit,
    modifyField,
    onChangeRedeemLimitTypeCb,
    validatedField,
    defaultType,
  } = stateForm;

  const Schedule = (limit) => {
    return <span className={styles.schedule}>{limit}</span>;
  };
  return (
    <div className={styles.container}>
      <Title className={styles.title} xsmall>
        {locale.businessInformation}
      </Title>
      <Field className={styles.fieldLabel} {...fields.name}>
        {viewMode ? (
          <Text className={styles.fieldText}>{fields.name.value}</Text>
        ) : (
          <TextField disabled={loading || defaultType} {...fields.name} onChange={modifyField} />
        )}
      </Field>

      <Title className={styles.title} xsmall>
        {locale.walletLimit}
      </Title>
      <Field className={styles.fieldLabel} {...fields.walletSize}>
        {viewMode ? (
          <Text className={styles.fieldText}>
            {formatCurrencyWithoutDecimal(fields.walletSize.value)}
          </Text>
        ) : (
          <InputAmount
            className={styles.amount}
            {...fields.walletSize}
            onChange={modifyField}
            disabled={loading}
          />
        )}
      </Field>

      <Title className={styles.title} xsmall>
        {locale.fuelLimit}
      </Title>
      <Field className={styles.fieldLabel} {...fields.fuelTankSize}>
        {viewMode ? (
          <Text className={styles.fieldText}>
            {formatVolumeWithoutDecimal(fields.fuelTankSize.value)}
          </Text>
        ) : (
          <InputLitre
            className={styles.amount}
            {...fields.fuelTankSize}
            onChange={modifyField}
            disabled={loading}
          />
        )}
      </Field>

      <Title className={styles.title} xsmall>
        {locale.cashInLimit}
      </Title>
      <Field className={styles.fieldLabel} {...fields.totalCashinLimitDaily}>
        <div className={styles.limitDisplay}>
          {viewMode ? (
            <Text className={styles.fieldText}>
              {formatCurrencyWithoutDecimal(fields.totalCashinLimitDaily.value)}
            </Text>
          ) : (
            <InputAmount
              className={styles.amount}
              {...fields.totalCashinLimitDaily}
              onChange={modifyField}
              disabled={loading}
            />
          )}
          {Schedule(locale.perDay)}
        </div>
      </Field>
      <Field className={styles.fieldLabel} {...fields.totalCashinLimitMonthly}>
        <div className={styles.limitDisplay}>
          {viewMode ? (
            <Text className={styles.fieldText}>
              {formatCurrencyWithoutDecimal(fields.totalCashinLimitMonthly.value)}
            </Text>
          ) : (
            <InputAmount
              className={styles.amount}
              {...fields.totalCashinLimitMonthly}
              onChange={modifyField}
              disabled={loading}
            />
          )}
          {Schedule(locale.perMonth)}
        </div>
      </Field>
      <Field className={styles.fieldLabel} {...fields.totalTransactionCardDaily}>
        <div className={styles.limitDisplay}>
          {viewMode ? (
            <Text className={styles.fieldText}>
              {formatCurrencyWithoutDecimal(fields.totalTransactionCardDaily.value)}
            </Text>
          ) : (
            <InputAmount
              className={styles.amount}
              {...fields.totalTransactionCardDaily}
              onChange={modifyField}
              disabled={loading}
            />
          )}
          {Schedule(locale.perDay)}
        </div>
      </Field>
      <Field className={styles.fieldLabel} {...fields.totalTransactionCardMonthly}>
        <div className={styles.limitDisplay}>
          {viewMode ? (
            <Text className={styles.fieldText}>
              {formatCurrencyWithoutDecimal(fields.totalTransactionCardMonthly.value)}
            </Text>
          ) : (
            <InputAmount
              className={styles.amount}
              {...fields.totalTransactionCardMonthly}
              onChange={modifyField}
              disabled={loading}
            />
          )}
          {Schedule(locale.perMonth)}
        </div>
      </Field>

      <Title className={styles.title} xsmall>
        {locale.buyFuelLimit}
      </Title>
      <Field className={styles.fieldLabel} {...fields.totalBuyFuelLimitDaily}>
        <div className={styles.limitDisplay}>
          {viewMode ? (
            <Text className={styles.fieldText}>
              {formatVolumeWithoutDecimal(fields.totalBuyFuelLimitDaily.value)}
            </Text>
          ) : (
            <InputLitre
              className={styles.amount}
              {...fields.totalBuyFuelLimitDaily}
              onChange={modifyField}
              disabled={loading}
            />
          )}
          {Schedule(locale.perDay)}
        </div>
      </Field>
      <Field className={styles.fieldLabel} {...fields.totalBuyFuelLimitMonthly}>
        <div className={styles.limitDisplay}>
          {viewMode ? (
            <Text className={styles.fieldText}>
              {formatVolumeWithoutDecimal(fields.totalBuyFuelLimitMonthly.value)}
            </Text>
          ) : (
            <InputLitre
              className={styles.amount}
              {...fields.totalBuyFuelLimitMonthly}
              onChange={modifyField}
              disabled={loading}
            />
          )}
          {Schedule(locale.perMonth)}
        </div>
      </Field>

      <Title className={styles.title} xsmall>
        {locale.payWithPesoLimit}
      </Title>
      <Field className={styles.fieldLabel} {...fields.totalPayWithPesoLimitDaily}>
        <div className={styles.limitDisplay}>
          {viewMode ? (
            <Text className={styles.fieldText}>
              {formatCurrencyWithoutDecimal(fields.totalPayWithPesoLimitDaily.value)}
            </Text>
          ) : (
            <InputAmount
              className={styles.amount}
              {...fields.totalPayWithPesoLimitDaily}
              onChange={modifyField}
              disabled={loading}
            />
          )}
          {Schedule(locale.perDay)}
        </div>
      </Field>
      <Field className={styles.fieldLabel} {...fields.totalPayWithPesoLimitMonthly}>
        <div className={styles.limitDisplay}>
          {viewMode ? (
            <Text className={styles.fieldText}>
              {formatCurrencyWithoutDecimal(fields.totalPayWithPesoLimitMonthly.value)}
            </Text>
          ) : (
            <InputAmount
              className={styles.amount}
              {...fields.totalPayWithPesoLimitMonthly}
              onChange={modifyField}
              disabled={loading}
            />
          )}
          {Schedule(locale.perMonth)}
        </div>
      </Field>

      <Title className={styles.title} xsmall>
        {locale.perAccountScanToPayLimit}
      </Title>
      <Field className={styles.fieldLabel} {...fields.totalRedeemLimitDaily}>
        <div className={classNames(styles.limitDisplay, styles.redemptionLimitDisplay)}>
          {viewMode ? (
            <Text className={classNames(styles.fieldText, styles.redemptionLimitDetailsDisplay)}>
              {`${fields.totalRedeemLimitDaily.value} ${locale.per} ${fields.totalRedeemLimitType.value}`}
            </Text>
          ) : (
            <>
              <TextField
                className={styles.redemptionLimitNumber}
                {...fields.totalRedeemLimitDaily}
                onChange={modifyField}
                disabled={loading}
              />
              <Dropdown
                className={styles.redeemLimitType}
                onChange={onChangeRedeemLimitTypeCb}
                disabled={loading}
                {...fields.totalRedeemLimitType}
              />
            </>
          )}
          {Schedule(locale.perDay)}
        </div>
      </Field>
      <Field className={styles.fieldLabel} {...fields.totalRedemptionLiterLimitDaily}>
        <div className={styles.limitDisplay}>
          {viewMode ? (
            <Text className={styles.fieldText}>
              {formatVolumeWithoutDecimal(fields.totalRedemptionLiterLimitDaily.value)}
            </Text>
          ) : (
            <InputLitre
              className={styles.amount}
              {...fields.totalRedemptionLiterLimitDaily}
              onChange={modifyField}
              onBlur={validatedField}
              disabled={loading}
            />
          )}
          {Schedule(locale.perDay)}
        </div>
      </Field>

      {addMode && (
        <Button
          primary
          className={styles.create}
          loading={loading}
          disabled={!isFormSubmittable}
          onClick={() => {
            submitForm(handleSubmit);
          }}
        >
          {locale.create}
        </Button>
      )}
    </div>
  );
};
export default AccountTypeDetailsFormModule;
