import locale from "localization";
import styles from "./discount-column.module.scss";

export const discountColumns = [
  {
    key: "discountCampaignId",
    text: <span className={styles.heading}>{locale.LOCQPayDiscountCampaignId}</span>,
    width: "10%",
  },
  {
    key: "campaignName",
    text: <span className={styles.heading}>{locale.LOCQPayDiscountCampaignName}</span>,
    width: "10%",
  },
  {
    key: "stationCount",
    text: <span className={styles.heading}>{locale.participatingStations}</span>,
    width: "5%",
  },
  {
    key: "startDate",
    text: <span className={styles.heading}>{locale.startDate}</span>,
    width: "12%",
  },
  {
    key: "endDate",
    text: <span className={styles.heading}>{locale.endDate}</span>,
    width: "12%",
  },
  {
    key: "dateCreated",
    text: (
      <span className={styles.heading}>
        {locale.dateCreated} / {locale.createdBy}
      </span>
    ),
    width: "12.5%",
  },
  {
    key: "volumeCap",
    text: <span className={styles.heading}>{locale.volumeCapPerDayOrEntireCampaign}</span>,
    width: "10%",
  },
  {
    key: "products",
    text: <span className={styles.heading}>{locale.product}</span>,
    width: "10%",
  },
  {
    key: "discounts",
    text: <span className={styles.heading}>{locale.discounts}</span>,
    width: "10%",
  },
  {
    key: "status",
    text: (
      <span className={styles.heading}>
        {locale.status}/ {locale.dateOfLastUpdate}
      </span>
    ),
    width: "20%",
  },
  {
    key: "action",
    text: <span className={styles.heading}>{locale.action}</span>,
    width: "5%",
  },
];
