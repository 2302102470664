import locale from "localization";
import styles from "./discount-column.module.scss";

export const discountColumns = [
  {
    key: "discountCampaignId",
    text: <span className={styles.heading}>{locale.discountCampaignId}</span>,
    width: "10%",
  },
  {
    key: "campaignName",
    text: <span className={styles.heading}>{locale.campaignName}</span>,
    width: "17%",
  },
  {
    key: "stationCount",
    text: <span className={styles.heading}>{locale.stations}</span>,
    width: "5%",
  },
  {
    key: "startDate",
    text: <span className={styles.heading}>{locale.startDate}</span>,
    width: "12.5%",
  },
  {
    key: "endDate",
    text: <span className={styles.heading}>{locale.endDate}</span>,
    width: "12.5%",
  },
  {
    key: "dateCreated",
    text: <span className={styles.heading}>{locale.dateCreated}</span>,
    width: "12.5%",
  },
  {
    key: "products",
    text: <span className={styles.heading}>{locale.product}</span>,
    width: "10%",
  },
  {
    key: "discounts",
    text: <span className={styles.heading}>{locale.discounts}</span>,
    width: "10%",
  },
  {
    key: "status",
    text: (
      <span className={styles.heading}>
        {locale.status}/ {locale.dateOfLastUpdate}
      </span>
    ),
    width: "20%",
  },
  {
    key: "action",
    text: <span className={styles.heading}>{locale.action}</span>,
    width: "5%",
  },
];
