const LubeServSite = {
  LubeServAnabuImusCavite: "lubeserv-anabu-imus-cavite",
  LubeServTelabastaganPampanga: "lubeserv-telabastagan-pampanga",
  LubeServCarmonaCavite: "lubeserv-carmona-cavite",
  LubeServCamarinCaloocan: "lubeserv-camarin-caloocan",
  LubeServShawBlvd: "lubeserv-shaw-blvd",
  LubeServC5Pasig: "lubeserv-c5-pasig",
  LubeServBoniCainta: "lubeserv-shaw-boni-cainta",
  LubeServNorzagarayBulacan: "lubeserv-norzagaray-bulacan",
};

export default LubeServSite;
