import React, { useCallback } from "react";
import classNames from "classnames";
import Big from "big.js";

import { Field, Modal, Text, ActionButton, Button } from "components/commons";
import { InputAmount } from "components/field";

import locale from "localization";

import styles from "./pricing.module.scss";
import { GasType } from "./pricing.module";

const ModalInputs = ({ fields, gasType, modifyForm }) => (
  <>
    <Field className={styles.gasAmount}>
      <InputAmount {...fields[`matchCurrent${gasType}Price`]} className={styles.gasAmount} />
    </Field>
    <Field className={styles.gasAmount}>
      <InputAmount {...fields[`matchRefSPumpPrice${gasType}`]} decimalPlaces={2} isPriceValue />
    </Field>
    <Field {...fields[`matchAccountSpecific${gasType}`]} className={styles.gasAmount}>
      <InputAmount {...fields[`matchAccountSpecific${gasType}`]} inputDisabled />
    </Field>
    {fields[`isPriceDifference${gasType}`]?.showUpdate ? (
      <Field className={styles.gasAmount}>
        <Button
          outline
          className={styles.buttonLink}
          onClick={() => {
            modifyForm({
              [fields[`matchAccountSpecific${gasType}`].name]: {
                value: fields[`isPriceDifference${gasType}`].value,
                isUpdate: true,
              },
              [fields[`isPriceDifference${gasType}`].name]: {
                showUpdate: false,
              },
            });
          }}
        >
          {locale.update}
        </Button>
      </Field>
    ) : (
      // act as placeholder only
      <div style={{ visibility: "hidden", width: 500 }}>
        <Button
          outline
          className={styles.buttonLink}
          onClick={() => {
            modifyForm({
              [fields[`matchAccountSpecific${gasType}`].name]: {
                value: fields[`isPriceDifference${gasType}`].value,
              },
              [fields[`isPriceDifference${gasType}`].name]: {
                showUpdate: false,
              },
            });
          }}
        >
          {locale.update}
        </Button>
      </div>
    )}
  </>
);

const MatchPumpPriceModal = ({
  title,
  active,
  close,
  modifyForm,
  fields,
  closable,
  disabled,
  isShowSaveModal,
  showSaveModal,
  matchPumpPriceForm,
  computeContractPrice,
  currentInfo,
}) => {
  const updateAll = useCallback(() => {
    const obj = Object.values(GasType)
      .map((gasType) =>
        matchPumpPriceForm?.fields[`isPriceDifference${gasType}`]?.showUpdate
          ? {
              [matchPumpPriceForm?.fields[`matchAccountSpecific${gasType}`].name]: {
                value: matchPumpPriceForm?.fields[`isPriceDifference${gasType}`].value,
                isUpdate: true,
              },
              [matchPumpPriceForm?.fields[`isPriceDifference${gasType}`].name]: {
                showUpdate: false,
              },
            }
          : null
      )
      .filter((data) => data);
    matchPumpPriceForm?.modifyForm(Object.assign({}, ...obj));
  }, [matchPumpPriceForm]);

  const cancelUpdate = useCallback(() => {
    const currentInfoValues = {
      [GasType.diesel]: currentInfo ? currentInfo[0] : null,
      [GasType.gas91]: currentInfo ? currentInfo[1] : null,
      [GasType.gas95]: currentInfo ? currentInfo[2] : null,
      [GasType.gas97]: currentInfo ? currentInfo[3] : null,
    };

    const formObj = Object.values(GasType)
      .map((gasType) =>
        matchPumpPriceForm?.fields[`matchAccountSpecific${gasType}`]?.isUpdate
          ? {
              ...(fields[`accuSpecific${gasType}`]?.isUpdate
                ? {
                    [fields[`accuSpecific${gasType}`].name]: {
                      value: Big(fields[`accuSpecific${gasType}`].value).minus(
                        matchPumpPriceForm?.fields[`matchAccountSpecific${gasType}`].value
                      ),
                      isUpdate: false,
                    },
                  }
                : null),
              [fields[`contractPrice${gasType}`]?.name]: {
                value: Number(
                  Big(currentInfoValues[gasType].latestPumpPrice)
                    .minus(matchPumpPriceForm?.fields[`matchAccountSpecific${gasType}`].value)
                    .minus(fields[`discount${gasType}`].value)
                ),
              },
            }
          : null
      )
      .filter((data) => data);
    const obj = Object.values(GasType)
      .map((gasType) =>
        matchPumpPriceForm?.fields[`matchAccountSpecific${gasType}`]?.isUpdate
          ? {
              [matchPumpPriceForm?.fields[`matchAccountSpecific${gasType}`].name]: {
                value: "0",
                isUpdate: false,
                fontColor: "black",
              },
              [matchPumpPriceForm?.fields[`isPriceDifference${gasType}`].name]: {
                showUpdate: true,
              },
            }
          : null
      )
      .filter((data) => data);
    if (isShowSaveModal) {
      showSaveModal();
    }
    close();
    modifyForm(Object.assign({}, ...formObj));
    matchPumpPriceForm?.modifyForm(Object.assign({}, ...obj));
  }, [matchPumpPriceForm, close, showSaveModal, isShowSaveModal, modifyForm, currentInfo, fields]);

  const checkIfUpdate = useCallback(() => {
    const hasUpdate = Object.values({ ...matchPumpPriceForm?.fields })?.filter(
      (data) => data?.isUpdate
    );

    return hasUpdate?.length !== 0 ? false : true;
  }, [matchPumpPriceForm?.fields]);

  const applyStateChange = useCallback(() => {
    const currentInfoValues = {
      [GasType.diesel]: currentInfo ? currentInfo[0] : null,
      [GasType.gas91]: currentInfo ? currentInfo[1] : null,
      [GasType.gas95]: currentInfo ? currentInfo[2] : null,
      [GasType.gas97]: currentInfo ? currentInfo[3] : null,
    };

    const obj = Object.values(GasType)
      .map((gasType) =>
        matchPumpPriceForm?.fields[`matchAccountSpecific${gasType}`]?.isUpdate
          ? {
              ...(!fields[`accuSpecific${gasType}`]?.isUpdate
                ? {
                    [fields[`accuSpecific${gasType}`].name]: {
                      value: Big(fields[`accuSpecific${gasType}`].value).add(
                        matchPumpPriceForm?.fields[`matchAccountSpecific${gasType}`].value
                      ),
                      isUpdate: true,
                    },
                  }
                : null),
              [fields[`contractPrice${gasType}`]?.name]: {
                value: Number(
                  Big(currentInfoValues[gasType].latestPumpPrice).minus(
                    fields[`discount${gasType}`].value
                  )
                ),
              },
            }
          : null
      )
      .filter((data) => data);
    close();
    if (isShowSaveModal) {
      showSaveModal();
    }
    modifyForm(Object.assign({}, ...obj));
  }, [
    currentInfo,
    close,
    isShowSaveModal,
    modifyForm,
    matchPumpPriceForm?.fields,
    fields,
    showSaveModal,
  ]);

  return (
    <Modal title={title} active={active} close={cancelUpdate} closable={closable}>
      <Text align="center">{locale.reviewChangesBelow}</Text>
      <div className={styles.container} style={{ width: 600 }}>
        <Field
          className={classNames(
            styles.fieldLabel,
            styles.fieldPricingLabel,
            styles.fieldPricingLabelPosition
          )}
          horizontal
        >
          <div className={classNames(styles.horizontalModalInput)}>
            <Text xsmall className={styles.gasTitle}>
              {locale.currentPumpPrice}
            </Text>
            <Text xsmall className={styles.gasTitle}>
              {locale.refSPumpPrice}
            </Text>
            <Text xsmall className={styles.gasTitle}>
              {locale.accountSpecificMovement}
            </Text>
            <Text xsmall className={styles.gasTitle}></Text>
          </div>
        </Field>
        <Field
          className={classNames(styles.fieldLabel, styles.fieldPricingLabel, styles.fieldFormLabel)}
          label={locale.diesel}
          horizontal
        >
          <div className={styles.horizontalInput}>
            <ModalInputs
              gasType={"Diesel"}
              fields={matchPumpPriceForm?.fields}
              modifyForm={matchPumpPriceForm?.modifyForm}
            />
          </div>
        </Field>
        <Field
          className={classNames(styles.fieldLabel, styles.fieldPricingLabel, styles.fieldFormLabel)}
          label={locale.gas91}
          horizontal
        >
          <div className={styles.horizontalInput}>
            <ModalInputs
              gasType={"Gas91"}
              fields={matchPumpPriceForm?.fields}
              modifyForm={matchPumpPriceForm?.modifyForm}
            />
          </div>
        </Field>
        <Field
          className={classNames(styles.fieldLabel, styles.fieldPricingLabel, styles.fieldFormLabel)}
          label={locale.gas95}
          horizontal
        >
          <div className={styles.horizontalInput}>
            <ModalInputs
              gasType={"Gas95"}
              fields={matchPumpPriceForm?.fields}
              modifyForm={matchPumpPriceForm?.modifyForm}
            />
          </div>
        </Field>
        <Field
          className={classNames(styles.fieldLabel, styles.fieldPricingLabel, styles.fieldFormLabel)}
          label={locale.gas97}
          horizontal
        >
          <div className={styles.horizontalInput}>
            <ModalInputs
              gasType={"Gas97"}
              fields={matchPumpPriceForm?.fields}
              modifyForm={matchPumpPriceForm?.modifyForm}
            />
          </div>
        </Field>
      </div>
      <ActionButton
        right
        items={[
          {
            onClick: cancelUpdate,
            text: locale.cancel,
            disabled: disabled,
          },
          {
            primary: true,
            onClick: () => updateAll(),
            text: locale?.updateAll,
            disabled: disabled,
          },
          {
            loading: disabled,
            primary: true,
            disabled: checkIfUpdate(),
            onClick: applyStateChange,
            text: locale?.proceedWithChanges,
          },
        ]}
      />
    </Modal>
  );
};

export default MatchPumpPriceModal;
