import { ApiPath } from "enums";
import { Get, Post, Put } from "utils";

export const getBuyFuel = async ({ ...query }) => {
  const res = await Get(`${ApiPath.BuyFuel}`, query);
  return res.data;
};

export const buyFuel = async (query) => {
  const res = await Post(`${ApiPath.BuyFuel}`, query);
  return res.data;
};

export const getConversionBuyFuel = async (query) => {
  const res = await Get(`${ApiPath.BuyFuel}/conversion`, query);
  return res.data;
};

export const exportBuyFuels = async (body) => {
  const res = await Post(`${ApiPath.GenerateReportAdmin}`, body);
  return res.data;
};

export const voidTransactionApi = async (query) => {
  const res = await Post(`${ApiPath.VoidBuyFuel}`, query);
  return res.data;
};

export const editFuel = async ({ id, ...query }) => {
  const res = await Put(`${ApiPath.BuyFuel}/${id}`, query);
  return res.data;
};
