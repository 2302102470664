import { getPrepayments, getPrepaymentsTotal } from "apis/prepayment.api";
import { Intro } from "components/commons";
import useDataTable from "hooks/useDataTable";
import React from "react";
import { prepaymentFilterState } from "./prepayment-filter.state";
import { prepaymentMapDataToList, prepaymentMapFilterToRequest } from "./prepayment.mapper";
import locale from "localization";
import PrepaymentFilter from "./prepayment-filter";
import { useMemo } from "react";
import { useApi, useMount } from "hooks";
import DataTotalsTable from "components/commons/data-table/data-totals-table";

const Prepayment = () => {
  const { table, filter } = useDataTable({
    api: { api: getPrepayments },
    filter: { initialState: prepaymentFilterState(), mapper: prepaymentMapFilterToRequest },
    table: {
      key: "data",
      columns: [],
    },
  });

  const {
    request,
    result: totalResult,
    loading,
  } = useApi({
    api: getPrepaymentsTotal,
  });

  const { result, columns } = useMemo(() => {
    return prepaymentMapDataToList(table.result, totalResult);
  }, [table.result, totalResult]);

  useMount(() => {
    request();
  });

  return (
    <div>
      <div>
        <Intro title={locale.voucherPrepayment} subtitle={locale.viewAndTrackVoucherPrepayment} />
      </div>
      <div
        style={{
          margin: "30px 0px",
        }}
      >
        <PrepaymentFilter {...filter} />
      </div>
      <DataTotalsTable
        loading={loading}
        initialActiveColumns={{
          claimedVouchers: {
            active: true,
          },
          scanToPay: {
            active: true,
          },
        }}
        table={table}
        columns={columns}
        data={result}
      />
    </div>
  );
};

export default Prepayment;
