import React from "react";
import { Path } from "enums";
import { Route, Switch, Redirect } from "react-router-dom";
import VoucherModule from "modules/campaigns-programs/voucher-campaign/voucher.module";
import VoucherViewModule from "modules/campaigns-programs/voucher-campaign/voucher-view/voucher-view.module";
import VoucherAddModule from "modules/campaigns-programs/voucher-campaign/voucher-add/voucher-add.module";
import VoucherEditModule from "modules/campaigns-programs/voucher-campaign/voucher-edit/voucher-edit.module";
import AddVoucher from "modules/campaigns-programs/voucher-campaign/v2/add-voucher";
import ViewVoucher from "modules/campaigns-programs/voucher-campaign/v2/view-voucher";
import EditVoucher from "modules/campaigns-programs/voucher-campaign/v2/edit-voucher";
import { FeatureFlagContext } from "contexts";
import { useContext } from "react";

import VoucherCampaign from "modules/campaigns-programs/voucher-campaign/v2/voucher-campaign.module";

const VoucherCampaignPage = () => {
  const { hasFeature } = useContext(FeatureFlagContext);
  return (
    <Switch>
      <Route
        exact
        path={Path.VoucherCampaign}
        // component={VoucherCampaign}
        component={hasFeature("VOUCHER_V2") ? VoucherCampaign : VoucherModule}
      />

      <Route
        exact
        path={Path.AddVoucherCampaign}
        component={hasFeature("VOUCHER_V2") ? AddVoucher : VoucherAddModule}
      />
      <Route exact path={Path.ViewVoucherCampaign} component={VoucherViewModule} />
      <Route exact path={Path.EditVoucherCampaign} component={VoucherEditModule} />
      <Route exact path={Path.ViewVoucherCampaignDetails} component={ViewVoucher} />
      <Route exact path={Path.EditVoucherCampaignDetails} component={EditVoucher} />
      <Redirect to={Path.VoucherCampaign} />
    </Switch>
  );
};

export default VoucherCampaignPage;
