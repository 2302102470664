import React, { useState, useMemo } from "react";
import Big from "big.js";

import { Title, Checkbox, Field, Text } from "components/commons";
import locale from "localization";

import classNames from "classnames";

import styles from "./pricing.module.scss";

import { Skeleton } from "@material-ui/lab";
import TableForm from "./table-form";
import { formatDate, formatVolume, prettifyFleetAccountType } from "utils";

import { GasType } from "./pricing.module";
import { FleetAccountType } from "enums";

const PreviousPricingInfo = ({ getFleetResult, onboardingInfo, isLoading }) => {
  const [showOnboardingInfo, setShowOnboardingInfo] = useState(false);
  const fields = useMemo(() => {
    if (getFleetResult && onboardingInfo) {
      const {
        oldFleetStations: fleetStations,
        oldReferenceStation: referenceStation,
        oldDepot: depot,
        oldDiscounts: discounts,
        basePrices,
        priceAssessmentDate,
        dateTimeOnboarded,
        existingVolume,
        estimatedVolume,
        accumulatedPriceMovement,
      } = getFleetResult;

      const newCurrentPricing = Object.assign(
        {},
        ...onboardingInfo.map((cPrice, key) => ({
          [`base${GasType[cPrice.productCode]}Price`]: {
            value: cPrice.pumpPrice,
          },
        }))
      );

      const newBase = Object.assign(
        {},
        ...basePrices?.map((cPrice, key) => ({
          [`freight${GasType[cPrice.productCode]}`]: {
            value: cPrice.freight,
          },
          [`sdum${GasType[cPrice.productCode]}`]: {
            value: cPrice.sdum,
          },
          [`contractPrice${GasType[cPrice.productCode]}`]: {
            value: Number(Big(cPrice.price).minus(discounts[key]?.discount || 0)),
          },
        }))
      );

      const newAccuMovement = Object.assign(
        {},
        ...accumulatedPriceMovement?.map((priceMovement) => ({
          [`accuMovement${GasType[priceMovement.productCode]}`]: {
            value: priceMovement.movement,
          },
        }))
      );

      const newStationPricing = Object.assign(
        {},
        ...Object.values(GasType).map((gas, key) => ({
          [`stationMovement${gas}`]: {
            value: Big(newBase[`contractPrice${gas}`].value || 0)
              .minus(newCurrentPricing[`base${gas}Price`].value || 0)
              .minus(newAccuMovement[`accuMovement${gas}`].value || 0)
              .add(discounts[key]?.discount || 0)
              .toNumber(),
          },
        }))
      );

      const newDiscount = discounts.map((discount) => ({
        [`discount${GasType[discount.productCode]}`]: {
          value: discount.discount,
        },
      }));

      const newExistingVolume = existingVolume.map((exstVolume) => ({
        [`existing${GasType[exstVolume.productCode]}Volume`]: {
          value: formatVolume(exstVolume.existingVolume),
        },
      }));

      const newEstimatedVolume = estimatedVolume.map((estdVolume) => ({
        [`estimated${GasType[estdVolume.productCode]}Volume`]: {
          value: formatVolume(estdVolume.estimatedVolume),
        },
      }));

      return {
        dateTimeOnboarded: formatDate(dateTimeOnboarded),
        priceAssessmentDate: formatDate(priceAssessmentDate),
        depot: depot?.name,
        referenceStation: {
          name: referenceStation?.name,
          type: referenceStation?.stationType,
        },
        redemptionStation:
          fleetStations?.length === 0
            ? "All Stations"
            : fleetStations?.map((fleet) => fleet?.name).join(", "),
        ...newCurrentPricing,
        ...newAccuMovement,
        ...newBase,
        ...newStationPricing,
        ...Object.assign({}, ...newDiscount),
        ...Object.assign({}, ...newExistingVolume),
        ...Object.assign({}, ...newEstimatedVolume),
      };
    }
    // computeContractPrice
  }, [onboardingInfo, getFleetResult]);

  return (
    <div className={styles.container}>
      <Title xsmall>{locale.onboardingPricingInfo}</Title>
      <div className={styles.showOnboardingCheckbox}>
        {/* {!viewMode && ( */}
        <Checkbox
          name="showOnboardingInfo"
          checked={showOnboardingInfo}
          value={showOnboardingInfo}
          onChange={() => setShowOnboardingInfo(!showOnboardingInfo)}
          // disabled={loading}
        />
        {/* )} */}
        <Text className={styles.textCheckbox}>{locale.showOnboardingInfo}</Text>
      </div>
      {showOnboardingInfo && (
        <>
          <Field
            className={styles.fieldLabel}
            label={locale.accountType}
            labelClassName={styles.fieldLabelInfo}
            labelPosition="left"
            childrenClassName={styles.fieldContent}
          >
            {isLoading ? (
              <Skeleton />
            ) : (
              <Text>{prettifyFleetAccountType(FleetAccountType.FleetAccount)}</Text>
            )}
          </Field>
          <Field
            className={styles.fieldLabel}
            label={locale.redemptionStations}
            labelClassName={styles.fieldLabelInfo}
            labelPosition="left"
            childrenClassName={styles.fieldContentLong}
          >
            {isLoading ? <Skeleton /> : <Text>{fields?.redemptionStation}</Text>}
          </Field>
          <Field
            className={styles.fieldLabel}
            label={locale.referenceStation}
            labelClassName={styles.fieldLabelInfo}
            labelPosition="left"
            childrenClassName={styles.fieldContent}
          >
            {isLoading ? <Skeleton /> : <Text>{fields?.referenceStation?.name}</Text>}
          </Field>
          <Field
            className={styles.fieldLabel}
            label={locale.referenceStationType}
            labelClassName={styles.fieldLabelInfo}
            labelPosition="left"
            childrenClassName={styles.fieldContent}
          >
            {isLoading ? <Skeleton /> : <Text>{fields?.referenceStation?.type}</Text>}
          </Field>
          <Field
            className={styles.fieldLabel}
            label={locale.depot}
            labelClassName={styles.fieldLabelInfo}
            labelPosition="left"
            childrenClassName={styles.fieldContent}
          >
            {isLoading ? <Skeleton /> : <Text>{fields?.depot}</Text>}
          </Field>
          <Field
            className={styles.fieldLabel}
            label={locale.priceAssessmentDate}
            labelClassName={styles.fieldLabelInfo}
            labelPosition="left"
            childrenClassName={styles.fieldContent}
          >
            {isLoading ? <Skeleton /> : <Text>{fields?.priceAssessmentDate}</Text>}
          </Field>
          <Field
            className={styles.fieldLabel}
            label={locale.dateOnboarded}
            labelClassName={styles.fieldLabelInfo}
            labelPosition="left"
            childrenClassName={styles.fieldContent}
          >
            {isLoading ? <Skeleton /> : <Text>{fields?.dateTimeOnboarded}</Text>}
          </Field>

          <Field
            className={classNames(
              styles.fieldLabel,
              styles.fieldPricingLabel,
              styles.fieldPricingLabelPosition
            )}
            horizontal
          >
            <div className={classNames(styles.horizontalInput)}>
              <Text xsmall className={styles.gasTitle}>
                {locale.refsPumpPriceAtAssessment}
              </Text>
              <Text xsmall className={styles.gasTitle}>
                {locale.agreedDiscounts}
              </Text>
              <Text xsmall className={styles.gasTitle}>
                {locale.accuMovementSinceAssessment}
              </Text>
              <Text xsmall className={styles.gasTitle}>
                {locale.accumulatedStationSpecificMovement}
              </Text>
              <Text xsmall className={styles.gasTitle}>
                {locale.contractPriceAtOnboarding}
              </Text>
              <Text xsmall className={styles.gasTitle}>
                {locale.sdum}
              </Text>
              <Text xsmall className={styles.gasTitle}>
                {locale.freight}
              </Text>
              <Text xsmall className={styles.gasTitle}>
                {locale.estimateMonthlyVolume}
              </Text>
              <Text xsmall className={styles.gasTitle}>
                {locale.existingVolume}
              </Text>
            </div>
          </Field>
          <Field
            className={classNames(styles.fieldLabel, styles.fieldPricingLabel)}
            label={locale.diesel}
            horizontal
          >
            <TableForm
              gasType={"Diesel"}
              hasSpecificAmount={false}
              fields={fields}
              isLoading={isLoading}
            />
          </Field>
          <Field
            className={classNames(styles.fieldLabel, styles.fieldPricingLabel)}
            label={locale.gas91}
            horizontal
          >
            <TableForm
              gasType={"Gas91"}
              hasSpecificAmount={false}
              fields={fields}
              isLoading={isLoading}
            />
          </Field>
          <Field
            className={classNames(styles.fieldLabel, styles.fieldPricingLabel)}
            label={locale.gas95}
            horizontal
          >
            <TableForm
              gasType={"Gas95"}
              hasSpecificAmount={false}
              fields={fields}
              isLoading={isLoading}
            />
          </Field>
          <Field
            className={classNames(styles.fieldLabel, styles.fieldPricingLabel)}
            label={locale.gas97}
            horizontal
          >
            <TableForm
              gasType={"Gas97"}
              hasSpecificAmount={false}
              fields={fields}
              isLoading={isLoading}
            />
          </Field>
        </>
      )}
    </div>
  );
};

export default PreviousPricingInfo;
