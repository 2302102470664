import React from "react";
import { Path } from "enums";
import { Route, Switch, Redirect } from "react-router-dom";
import OilcoSettlementHistoryModule from "modules/cash-flow/oilco-settlement-history/oilco-settlement-history.module";
import OilcoViewSettlementHistory from "modules/cash-flow/oilco-settlement-history/oilco-view-settlement-history/oilco-view-settlement-history.module";

const OilcoSettlementHistoryPage = () => {
  return (
    <Switch>
      <Route
        exact
        path={Path.LocqOilcoSettlementHistory}
        component={OilcoSettlementHistoryModule}
      />
      <Route exact path={Path.ViewOilcoSettlementHistory} component={OilcoViewSettlementHistory} />
      <Redirect to={Path.ViewOilcoSettlementHistory} />
    </Switch>
  );
};

export default OilcoSettlementHistoryPage;
