import React, { useState, useRef } from "react";
import { useModal } from "hooks";
import locale from "localization";
import styles from "./discount-edit.module.scss";
import { FormMode } from "enums";
import { Path } from "enums";
import { Intro, BackLinkV2 } from "components/commons";
import DiscountDetailsModule from "../discount-details/discount-details.module";
import { useHistory } from "react-router-dom";
import { ConfirmModal } from "components/modals";

const DiscountEditModule = (props) => {
  const confirmModal = useModal();
  const discountDetailsRef = useRef();
  const successModal = useModal();
  const [updateLoading, setUpdateLoading] = useState(false);
  const [disableUpdate, setDisableUpdate] = useState(false);
  const history = useHistory();
  const outrightDiscountCampaignId = props.location.state
    ? props.location.state.outrightDiscountCampaignId
    : "";
  const stationCount = props.location.state ? props.location.state.stationCount : "";

  return (
    <div>
      <BackLinkV2
        text={locale.locqpayCampaign}
        path={Path.LOCQPayDiscountCampaigns}
        isSubmittable={disableUpdate}
        history={history}
      />
      <div className={styles.intro}>
        <Intro
          title={locale.editLOCQPayCampaign}
          actionText={locale.save}
          actionOnClick={() => {
            discountDetailsRef.current.handleUpdate();
          }}
          actionClassName={styles.primarySaveButton}
          actionLoading={updateLoading}
          actionDisabled={disableUpdate}
          actionSecondaryText={locale.cancel}
          actionSecondaryOutlined
          actionSecondaryOnClick={() => {
            if (!disableUpdate) {
              confirmModal.show({
                title: locale.areYouSureYouWouldToLeaveThePage,
                content: locale.youHaveUnsavedChangesFromThisPage,
                primary: {
                  text: locale.leavePage,
                  onClick: () => {
                    history.goBack();
                    confirmModal.close();
                  },
                },
                secondary: {
                  text: locale.cancel,
                  onClick: async () => {
                    confirmModal.close();
                  },
                },
              });
            } else {
              history.goBack();
            }
          }}
          actionSecondaryClassName={styles.secondarySaveButton}
        />
        <DiscountDetailsModule
          pageMode={FormMode.Edit}
          {...successModal}
          outrightDiscountCampaignId={outrightDiscountCampaignId}
          stationCount={stationCount}
          setUpdateLoading={setUpdateLoading}
          setDisableUpdate={setDisableUpdate}
          ref={discountDetailsRef}
        />
        <ConfirmModal {...confirmModal} />
      </div>
    </div>
  );
};

export default DiscountEditModule;
