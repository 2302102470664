import { ApiPath } from "enums";
import { Get, Put } from "utils";

export const getAccountTypes = async ({ page = 1, perPage = 20 }) => {
  const res = await Get(`${ApiPath.AccountTypesList}`, { page, perPage });
  return res.data;
};

export const updateUserAccountType = async ({ userId, accountTypeId }) => {
  const res = await Put(`${ApiPath.GetUser}/${userId}/account-type`, {
    accountTypeId,
  });
  return res;
};
