import Validation from "utils/validation.utils";
import locale from "localization";
import { CashInChannel } from "enums";

export const initialState = {
  businessId: {
    name: "businessId",
    value: "",
    placeholder: "e.g B001",
    required: true,
    validations: [Validation.required(), Validation.minlength(5)],
    maxLength: 5,
    label: locale.businessId,
  },
  businessName: {
    name: "businessName",
    value: "",
    maxLength: 5,
    label: locale.businessName,
  },
  cashInAmount: {
    name: "cashInAmount",
    value: "",
    placeholder: "0.00",
    required: true,
    validations: [
      Validation.required({ noMessage: true }),
      Validation.min(0.01, { isFormatted: true }),
    ],
    maxLength: 10,
    label: locale.cashInAmount,
  },
  cashInFeeLocq: {
    name: "cashInFeeLocq",
    value: "0.00",
    placeholder: "0.00",
    maxLength: 10,
    required: true,
    validations: [Validation.required({ noMessage: true })],
    label: locale.cashInFeeLocq,
  },
  cashInFeeCustomer: {
    name: "cashInFeeCustomer",
    value: "50.00",
    placeholder: "0.00",
    maxLength: 10,
    required: true,
    validations: [Validation.required({ noMessage: true })],
    label: locale.cashInFeeCustomer,
  },
  deductCustomerFee: {
    name: "deductCustomerFee",
    value: false,
  },
  cashInChannel: {
    name: "cashInChannel",
    value: CashInChannel.ManualBankDeposit,
    required: true,
    validations: [Validation.required({ noMessage: true })],
    label: locale.cashInChannel,
    placeholder: locale.searchOrSelectCashInChannel,
  },
  referenceNumber: {
    name: "referenceNumber",
    value: "",
    label: locale.referenceNumber,
    required: true,
    validations: [Validation.required({ noMessage: true })],
  },
  remarks: {
    name: "remarks",
    value: "",
    label: locale.remarks,
    required: false,
    validations: [],
    maxLength: 255,
    multiline: true,
    rowsMax: 3,
  },
};
