import { TextField } from "components/commons";
import React from "react";
import { isPesoValid, formatNumber, parseNumber } from "utils";

const InputNumber = ({ className, onChange, innerLabel, decimalPlaces = 2, ...props }) => {
  const componentProps = {
    ...props,
    className,
    onChange: (name, { value }) => {
      if (isPesoValid(value) || !value) {
        onChange(name, { value });
      }
    },
    onBlur: (name, { value }) => {
      if (value) {
        onChange(name, {
          value: `${formatNumber(value, decimalPlaces)}`,
        });
      }
    },
    onFocus: () => {
      if (props.value) {
        const num = Number(parseNumber(props.value));
        onChange(props.name, { value: num || "0" });
      }
    },
  };

  return <TextField {...componentProps} />;
};

export default InputNumber;
