import React from "react";
import { useModal } from "hooks";
import locale from "localization";
import styles from "./voucher-add.module.scss";
import { FormMode } from "enums";
import { Path } from "enums";
import { Intro, BackLink } from "components/commons";
import VoucherDetailsModule from "../voucher-details/voucher-details.module";

const VoucherAddModule = () => {
  const successModal = useModal();

  return (
    <div>
      <BackLink text={locale.voucherCampaigns} path={Path.VoucherCampaign} />
      <div className={styles.intro}>
        <Intro title={locale.createVoucherCampaign} />
        <VoucherDetailsModule pageMode={FormMode.Add} {...successModal} />
      </div>
    </div>
  );
};

export default VoucherAddModule;
