const ErrorCode = {
  IncorrectCredentials: "P1002",
  InvalidInviteFleetToken: "F1005",
  InsufficientAmount: "W1001",
  DuplicateFleetShortName: "F1006",
  AttributeNotFound: "U1007",
  InvalidCsvFormat: "E9997",
  InvalidImportFormat: "E9996",
  AttributeAlreadyAssignedToUser: "U1010",
  UserDetailNotFound: "U1005",
  CohortNotFound: "U1008",
  AttributeNameAlreadyExists: "U1006",
  VoucherCampaignPrefixAlreadyExist: "VC1000",
  VoucherCampaignNameAlreadyExist: "VC1008",
  InvalidInviteUserToken: "P1017",
  ExpiredInviteUserToken: "P1018",
  SettlementGroupNotFound: "S0006",
  RedemptionLimitExceeded: "R1004",
};

export default ErrorCode;
