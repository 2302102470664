import React from "react";
import { Button } from "..";
import locale from "localization";
import { CampaignType } from "enums";
import styles from "./panel-button.module.scss";
import classnames from "classnames";

const PanelButtons = ({ type, discountProps, voucherProps, locqpayCampaignProps }) => {
  return (
    <div className={styles.buttonContainer}>
      <Button
        className={classnames(styles.button, {
          [`${styles.active}`]: type === CampaignType.Discount,
        })}
        outline
        {...discountProps}
      >
        {locale.discountCampaigns}
      </Button>
      <Button
        className={classnames(styles.button, {
          [`${styles.active}`]: type === CampaignType.Voucher,
        })}
        outline
        {...voucherProps}
      >
        {locale.voucherCampaigns}
      </Button>
      <Button
        className={classnames(styles.button, {
          [`${styles.active}`]: type === CampaignType.LOCQPayCampaign,
        })}
        outline
        {...locqpayCampaignProps}
      >
        {locale.locqpayCampaign}
      </Button>
    </div>
  );
};

export default PanelButtons;
