import { CampaignStatus, VoucherCampaignCurrency, VoucherCampaignType } from "enums";
import VoucherCampaignGroup from "enums/campaign-group";

const { Ongoing, Ended, Cancelled, Upcoming } = CampaignStatus;
const { Peso, Liter, Points } = VoucherCampaignCurrency;
const { Generic, Unique } = VoucherCampaignType;
const { SPIAllocation, SPIAllowance, Others } = VoucherCampaignGroup;
export const vouchersFilterState = () => ({
  page: 1,
  perPage: 10,
  kind: "campaign",
  endDate: null,
  startDate: null,
  status: [Ongoing, Ended, Cancelled, Upcoming],
  type: [Generic, Unique],
  currency: [Peso, Liter, Points],
  campaignGroups: [SPIAllocation, SPIAllowance, Others],
  campaignCreators: {
    value: [],
    isAll: true,
  },
});
