import styles from "./sidebar.module.scss";
import { NavLink } from "react-router-dom";
import { Accordion, AccordionDetails, AccordionSummary, List } from "@material-ui/core";
import classNames from "classnames";
import { Path, UserPLBAccess } from "enums";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import locale from "localization";
import { useContext } from "react";
import { UserContext } from "contexts";
import { Role, Portal } from "enums";

let Navigation = [
  {
    label: locale.dashboard,
    link: Path.Dashboard,
    icon: "icon-dashboard",
    exact: true,
    permission: [Role.PortalAdmin],
  },
  {
    label: locale.cashFlow,
    icon: "icon-cash-flow",
    key: "CASH_FLOW",
    link: Path.CashFlow,
    items: [
      {
        label: locale.cashIn,
        child: true,
        link: Path.CashIn,
        permission: [Role.PortalAdmin, Role.Accounting, Role.SalesPartnerships],
      },
    ],
    permission: [Role.PortalAdmin, Role.Accounting, Role.SalesPartnerships],
  },
  {
    label: locale.fuel,
    icon: "icon-fuel",
    key: "FUEL",
    link: Path.Fuel,
    items: [
      {
        label: locale.buyFuelCapitalize,
        child: true,
        link: Path.BuyFuel,
        permission: [Role.PortalAdmin, Role.Accounting, Role.SalesPartnerships],
      },
      {
        label: locale.fuelCodes,
        child: true,
        link: Path.FuelCode,
        permission: [Role.PortalAdmin, Role.Accounting, Role.SalesPartnerships],
      },
      {
        label: locale.fuelCodeRedemptions,
        child: true,
        link: Path.Redemption,
        permission: [Role.PortalAdmin, Role.Accounting, Role.SalesPartnerships],
      },
    ],
    permission: [Role.PortalAdmin, Role.Accounting, Role.SalesPartnerships],
  },
  {
    label: locale.fleetCard,
    icon: "icon-cash-flow",
    key: UserPLBAccess.FleetCard,
    link: Path.FleetCard,
    items: [
      {
        label: locale.myFleetCard,
        child: true,
        link: Path.MyFleetCard,
        permission: [Role.PortalAdmin, Role.Accounting, Role.SalesPartnerships],
      },
      {
        label: locale.payWithLocqPay,
        child: true,
        link: Path.PayWithLOCQPay,
        permission: [Role.PortalAdmin, Role.Accounting, Role.SalesPartnerships],
      },
      {
        label: locale.locqpayForNonFuel,
        child: true,
        link: Path.NonFuel,
        permission: [Role.PortalAdmin, Role.Accounting, Role.SalesPartnerships],
      },
    ],
    permission: [Role.PortalAdmin, Role.Accounting, Role.SalesPartnerships],
  },
  {
    label: locale.accounts,
    icon: "icon-fleet-activity",
    link: Path.FleetAccounts,
    key: "ACCOUNTS",
    items: [
      {
        label: locale.fleetAccountList,
        child: true,
        link: Path.FleetAccounts,
        permission: [Role.PortalAdmin, Role.Accounting, Role.SalesPartnerships],
      },
      {
        label: locale.stationAccountList,
        child: true,
        link: Path.StationAccounts,
        permission: [Role.PortalAdmin, Role.Accounting, Role.SalesPartnerships],
      },
    ],
    permission: [Role.PortalAdmin, Role.Accounting, Role.SalesPartnerships],
  },
  {
    label: locale.pricing,
    icon: "icon-pricing",
    link: Path.Pricing,
    key: "PRICING",
    items: [
      {
        label: locale.fleetPrices,
        child: true,
        link: Path.FleetPrices,
        permission: [Role.PortalAdmin, Role.Accounting, Role.SalesPartnerships],
      },
      {
        label: locale.pricingChanges,
        child: true,
        link: Path.PricingChanges,
        permission: [Role.PortalAdmin, Role.Accounting, Role.SalesPartnerships],
      },
      {
        label: locale.priceMovements,
        child: true,
        link: Path.PriceMovements,
        permission: [Role.PortalAdmin, Role.Accounting, Role.SalesPartnerships],
      },
      {
        label: locale.doxNegotiatedDiscounts,
        child: true,
        link: Path.DoxNegotiatedDiscounts,
        permission: [Role.PortalAdmin, Role.SalesPartnerships, Role.Accounting],
      },
    ],
    permission: [Role.PortalAdmin, Role.Accounting, Role.SalesPartnerships],
  },
  {
    label: locale.accessAuthorization,
    link: Path.BusinessAccessAuthorization,
    key: "ACCESS_AUTHORIZATION",
    icon: "icon-access",
    items: [
      {
        label: locale.userAccess,
        child: true,
        link: Path.BusinessUserAccess,
        key: "USER_ACCESS",
        permission: [Role.PortalAdmin],
      },
    ],
    permission: [Role.PortalAdmin],
  },
];

const ChildItem = (props) => {
  const { styles, item, role } = props;

  return (
    <Accordion elevation={0} className={styles.accordionDetails}>
      <AccordionSummary expandIcon={<ExpandMoreIcon className={styles.expandIcon} />}>
        <NavLink
          to={item.link}
          className={classNames(styles.listItem, styles.disableLink)}
          activeClassName={classNames(item, styles.selectedMenu)}
        >
          <span className={styles.item}>
            <span className={styles.listIconRoot}>
              {item.icon && <span className={`icon ${item.icon}`} />}
            </span>
            <span className={styles.listText}>{item.label}</span>
          </span>
        </NavLink>
      </AccordionSummary>
      <AccordionDetails>
        <div className={styles.child}>
          {item.items?.map((child, childKey) => {
            if (!child) return <></>;
            return child.permission?.map((permission) => {
              return permission === role ? (
                <NavLink
                  key={childKey}
                  to={child.link}
                  className={classNames(styles.listItem)}
                  activeClassName={styles.active}
                >
                  <span className={styles.listIconRoot}>
                    {child.icon && <span className={`icon ${child.icon}`} />}
                  </span>
                  <span className={styles.listText}>{child.label}</span>
                </NavLink>
              ) : (
                <></>
              );
            });
          })}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

const Sidebar = () => {
  const { user } = useContext(UserContext);
  const { role } = user?.userAccesses?.find((element) => {
    return element.portal === Portal.LOCQ;
  });

  return (
    <div className={classNames(styles.sidebarContainer)}>
      <div className={styles.smallColorBox}></div>
      <List className={styles.sidebarList}>
        {Navigation.map((item, index) => {
          if (!item) return <></>;

          if (!user.userAccesses)
            return (
              <span key={index}>
                {item.exact ? (
                  <NavLink
                    to={item.link}
                    exact={true}
                    className={classNames(styles.listItem)}
                    activeClassName={styles.active}
                  >
                    <span className={styles.item}>
                      <span className={styles.listIconRoot}>
                        {item.icon && <span className={`icon ${item.icon}`} />}
                      </span>
                      <span className={styles.listText}>{item.label}</span>
                    </span>
                  </NavLink>
                ) : (
                  <ChildItem styles={styles} item={item} role={role} />
                )}
              </span>
            );

          return item.permission?.map((permission) => {
            return permission === role ? (
              <span key={index}>
                {item.exact ? (
                  <NavLink
                    to={item.link}
                    exact={true}
                    className={classNames(styles.listItem)}
                    activeClassName={styles.active}
                  >
                    <span className={styles.item}>
                      <span className={styles.listIconRoot}>
                        {item.icon && <span className={`icon ${item.icon}`} />}
                      </span>
                      <span className={styles.listText}>{item.label}</span>
                    </span>
                  </NavLink>
                ) : (
                  <ChildItem styles={styles} item={item} role={role} />
                )}
              </span>
            ) : (
              <></>
            );
          });
        })}
      </List>
    </div>
  );
};

export default Sidebar;
