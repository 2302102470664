import React from "react";
import { useHistory } from "react-router-dom";

import { Intro } from "components/commons";
import useDataTable from "hooks/useDataTable";
import locale from "localization";

import styles from "./dealer-monthly-fee.module.scss";
import DealerMonthlyFeeList from "./dealer-monthly-fee.list";
import { dealerMonthlyFeeColumns } from "./dealer-monthly-fee-columns";
import DealerMonthlyFeeFilter from "./dealer-monthly-fee-filter";
import { mapDataToList, mapFilterToRequest } from "./dealer-monthly-fee.mapper";
import { dealerMonthlyFeeFilterState } from "./dealer-monthly-fee-filter.state";
import { getCharging } from "apis/charging.api";
import useExport from "hooks/useExport";
import { generatePricingAdminReport } from "apis/generate-report.api";

const DealerMonthlyFeeModule = () => {
  const history = useHistory();

  const { filter, search, table } = useDataTable({
    api: { api: getCharging },
    filter: { initialState: dealerMonthlyFeeFilterState(), mapper: mapFilterToRequest },
    table: {
      key: "charging",
      mapper: (charging) =>
        mapDataToList({
          charging,
          history,
        }),
      columns: dealerMonthlyFeeColumns,
    },
  });

  const exportReport = useExport({
    api: generatePricingAdminReport,
    reportType: "charging",
    mappedFilterState: {
      ...filter.mappedFilterState,
      searchKey: search.searchKey,
    },
  });

  return (
    <>
      <Intro
        title={locale.dealerMonthlyFee}
        subtitle={locale.viewAndTrackChargesToDealerForPricelocq}
      />
      <DealerMonthlyFeeFilter {...filter} {...search} {...exportReport} />
      <div className={styles.table}>
        <DealerMonthlyFeeList table={table} />
      </div>
    </>
  );
};

export default DealerMonthlyFeeModule;
