import { DateRange, Field, SearchField, Filter, Autocomplete, Button } from "components/commons";
import React from "react";
import styles from "./redemption-filter.module.scss";
import locale from "localization";
import classNames from "classnames";
import { SelectStation } from "components/field";
import GroupCheckbox from "components/commons/group-checkbox/group-checkbox";
import {
  prettifyProduct,
  prettifySettlementStatus,
  prettifyDispenseType,
} from "utils/pretty.utils";
import { Products, SettlementStatus, DispenseType } from "enums";
import { redemptionStatusOptions } from "data/select-options";
import { useRef } from "react";

const RedemptionFilter = ({
  filterState,
  modifyFilter,
  filterCount,
  applyFilter,
  applyClearFilter,
  searchKey,
  modifySearch,
  applySearch,
  applyClearSearch,
  exportReport,
  exporting,
  resetFilter,
}) => {
  const selectStationRef = useRef();
  const { productCodes, redemptionStation, redemptionStatuses } = filterState;
  return (
    <div className={styles.container}>
      <div className={styles.filter}>
        <Filter
          close={resetFilter}
          clear={() => {
            applyClearFilter();
            selectStationRef?.current?.fetch();
          }}
          submit={applyFilter}
          filterCount={filterCount}
        >
          <Field
            className={styles.date}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
            label={locale.redemptionDate}
          >
            <DateRange
              value={{
                startDate: filterState.startDate,
                endDate: filterState.endDate,
              }}
              onChange={(name, { value }) => {
                const { startDate, endDate } = value;
                modifyFilter({ startDate, endDate });
              }}
            />
          </Field>
          <Field
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
            className={styles.fieldSelect}
            label={locale.redemptionStation}
          >
            <SelectStation
              {...redemptionStation}
              isMultiple
              disableAllOption
              allLabel={"Station"}
              allSelectedText={"All Station"}
              selectAllLabel={"All Station"}
              ref={selectStationRef}
              onChange={(_, { value, isAll }) => {
                modifyFilter({ redemptionStation: { value, isAll } });
              }}
            />
          </Field>
          <Field
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
            className={styles.redemptionStation}
            label={locale.dispensedTo}
          >
            <Autocomplete
              name="dispensedTo"
              value={filterState.dispensedTo}
              options={[
                { label: locale.all, value: "all" },
                { label: prettifyDispenseType(DispenseType.Vehicle), value: DispenseType.Vehicle },
                {
                  label: prettifyDispenseType(DispenseType.DrumContainer),
                  value: DispenseType.DrumContainer,
                },
              ]}
              onChange={(name, { value }) => modifyFilter({ [name]: value })}
            />
          </Field>
          <Field
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
            className={styles.fieldSelect}
            label={locale.status}
          >
            <Autocomplete
              capitalize={false}
              name={"redemptionStatus"}
              hasAll
              multiple
              placeholder="Select Status"
              value={redemptionStatuses.value}
              options={redemptionStatusOptions}
              onChange={(_, { value, isSelectedAll }) => {
                modifyFilter({ redemptionStatuses: { value, isSelectedAll } });
              }}
            />
          </Field>
          <Field
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
            className={styles.fieldSelect}
            label={locale.settlementStatus}
          >
            <Autocomplete
              name={"settlementStatuses"}
              hasAll
              multiple
              placeholder="Select Settlement Status"
              value={filterState?.settlementStatuses.value}
              options={[
                {
                  label: prettifySettlementStatus(SettlementStatus.ForSettlement),
                  value: SettlementStatus.ForSettlement,
                },
                {
                  label: prettifySettlementStatus(SettlementStatus.ForProcessing),
                  value: SettlementStatus.ForProcessing,
                },
                {
                  label: prettifySettlementStatus(SettlementStatus.Processed),
                  value: SettlementStatus.Processed,
                },
              ]}
              onChange={(_, { value, isSelectedAll }) => {
                modifyFilter({ settlementStatuses: { value, isSelectedAll } });
              }}
            />
          </Field>
          <GroupCheckbox
            className={styles.productCode}
            title={locale.availableProducts}
            options={[
              {
                content: (
                  <div className="flex items-center">
                    <div className={classNames(styles.circle, styles.diesel)}></div>
                    {prettifyProduct(Products.Diesel)}
                  </div>
                ),
                value: Products.Diesel,
              },
              {
                content: (
                  <div className="flex items-center">
                    <div className={classNames(styles.circle, styles.gas91)}></div>
                    {prettifyProduct(Products.Gas91)}
                  </div>
                ),
                value: Products.Gas91,
              },
              {
                content: (
                  <div className="flex items-center">
                    <div className={classNames(styles.circle, styles.gas95)}></div>
                    {prettifyProduct(Products.Gas95)}
                  </div>
                ),
                value: Products.Gas95,
              },
              {
                content: (
                  <div className="flex items-center">
                    <div className={classNames(styles.circle, styles.gas97)}></div>
                    {prettifyProduct(Products.Gas97)}
                  </div>
                ),
                value: Products.Gas97,
              },
            ]}
            value={productCodes}
            onChange={(_, { value }) => {
              modifyFilter({ productCodes: value });
            }}
          />
        </Filter>
        <Field className={styles.search}>
          <SearchField
            restrict={false}
            placeholder={locale.redemptionIdAndBusinessNameFuelCodeORNumber}
            value={searchKey}
            onChange={(_, { value }) => {
              modifySearch(value);
            }}
            onEnter={applySearch}
            onClear={applyClearSearch}
          />
        </Field>
      </div>

      <Button
        startIcon={<span className="icon-download" />}
        exportButton
        onClick={exportReport}
        loading={exporting}
      >
        {locale.export}
      </Button>
    </div>
  );
};

export default RedemptionFilter;
