import { exportCSV, parseCSVFile } from "utils/object.utils";
import React from "react";
import ImportModal from "components/modals/import-modal/import-modal";
import { ActionButton, Modal, Text } from "components/commons";
import locale from "localization";
import { useModal } from "hooks";
import VoucherTypeSetting from "enums/voucher-type-setting";

const ImportThirdPartyOneTimeAndMutiModal = ({
  request = {},
  submitUploadThirdPartyVoucher,
  voucherTypeSetting,
  ...importModal
}) => {
  const resultModal = useModal();

  const validateFile = async ({ file }, onParse) => {
    const items = await parseCSVFile(file, [
      {
        text: locale.thirdPartyVoucherCode,
        key: "thirdPartyVoucherCode",
        validation: (text, row) => {
          if (text.toString().length > 4 && text.toString().length <= 30) {
            return {
              error: false,
              message: "",
            };
          } else {
            return {
              error: true,
              message: locale.emptyThirdPartyVoucherCode,
              errorType: "thirdPartyVoucherCode",
            };
          }
        },
      },
    ]);
    return items;
  };

  const handleLoadData = async (file) => {
    const parsedFile = await validateFile(file);

    const errors = [];
    if (parsedFile.hasBlankRows) {
      errors.push({
        title: locale.otherError,
        items: [locale.thereAreBlankRowsAndColumns],
      });
    }

    if (parsedFile?.hasDuplicateVoucher) {
      errors.push({
        title: locale.otherError,
        items: [locale.thereAreDuplicateVoucherCode],
      });
    }

    if (parsedFile?.invalidFormat) {
      resultModal.show({
        title: locale.importInvalid,
        isSuccess: false,
        content: (
          <ErrorImport
            voucherTypeSetting={voucherTypeSetting}
            errors={[locale.invalidCsvFileTypeFormat]}
          />
        ),
      });
    } else if (parsedFile?.passed?.length > 0) {
      if (parsedFile?.errors?.length > 0) {
        resultModal.show({
          title: locale.importInvalid,
          isSuccess: false,
          content: (
            <ErrorImport
              voucherTypeSetting={voucherTypeSetting}
              errors={parsedFile.errors}
              data={parsedFile.items}
              correctData={parsedFile.passed}
            />
          ),
        });
      } else {
        const validData = parsedFile.passed;

        let data = [];
        for (let i = 0; i < validData.length; i++) {
          const thirdPartyVoucher = {
            thirdPartyVoucherCode: validData[i].thirdPartyVoucherCode,
            value: 0,
            quantity: 1,
          };
          data.push(thirdPartyVoucher);
        }

        if (validData.length > 0 && data.length > 0) {
          submitUploadThirdPartyVoucher(file, data, importModal);
        }
      }
    } else {
      resultModal.show({
        title: locale.importInvalid,
        content: (
          <ErrorImport
            voucherTypeSetting={voucherTypeSetting}
            errors={parsedFile.errors}
            data={parsedFile.items}
            correctData={parsedFile.passed}
          />
        ),
      });
    }
  };

  return (
    <div>
      <Modal
        {...resultModal}
        close={() => {
          resultModal.close();
        }}
      >
        {resultModal.content}
        <ActionButton
          center
          items={[
            {
              text: locale.retry,
              onClick: () => {
                resultModal.close();
                importModal.show();
              },
              primary: true,
            },
          ]}
        />
      </Modal>
      <ImportModal
        title={locale.importThirdPartyVoucherCode}
        {...importModal}
        onSubmit={async (file) => {
          handleLoadData(file);
        }}
      >
        <Text
          subtitle
          style={{
            margin: "0px 0px 20px 0px",
          }}
        >
          {locale.followTheFollowingColumnOrderAndLeaveRow1}
          <div
            style={{
              margin: "20px 0px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>{`Column 1: ${locale.thirdPartyVoucherCode}`}</div>
            {voucherTypeSetting.value !== VoucherTypeSetting.OneTimeClaim && (
              <div>
                {`Required Voucher Count:`}
                <b>200</b>
              </div>
            )}
          </div>
          <locale.Populate
            text={locale.downloadASampleTemplate}
            items={[
              <span
                colored
                style={{
                  color: "#753bbd",
                  fontWeight: "700",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => {
                  exportCSV({
                    rows: ["Third-Party Voucher Code", "D1G8T871"],
                    filename: locale.thirdPartyVoucherCodeFile,
                  });
                }}
              >
                {locale.sampleTemplate}
              </span>,
            ]}
          />
        </Text>
      </ImportModal>
    </div>
  );
};

const ErrorImport = ({ errors = [], data = [], correctData = [], voucherTypeSetting }) => {
  const getRow = errors.map((item) => item.replace(/[^0-9]/g, ""));
  const checkIfHasData = getRow[0] !== "" ? getRow : [];
  return (
    <div
      style={{
        width: "420px",
      }}
    >
      <div
        style={{
          textAlign: "center",
        }}
      >
        <Text
          style={{
            color: "black",
          }}
        >
          {locale.unableToImportThirdPartyVoucher}
        </Text>
      </div>
      {checkIfHasData.length > 0 ? (
        <>
          <div
            className="flex"
            style={{
              marginTop: "20px",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "45%",
              }}
            >
              <Text subtitle>{locale.invalidEntries}</Text>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <Text
                style={{
                  color: "black",
                }}
                strong
              >
                {errors.length > 10
                  ? errors.length
                  : `${errors.length} (rows no. ${getRow.join(", ")})`}
              </Text>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className="flex"
            style={{
              marginTop: "20px",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <Text subtitle>{locale.otherError}</Text>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <Text
                style={{
                  color: "black",
                }}
                strong
              >
                {" "}
                {errors.slice(0, 5).join(", ")}
              </Text>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ImportThirdPartyOneTimeAndMutiModal;
