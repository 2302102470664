import React, { useMemo } from "react";
import styles from "./fuel-code-form.module.scss";
import {
  ActionButton,
  Checkbox,
  DateRange,
  Dropdown,
  Field,
  PopOverList,
  Text,
  TextField,
} from "components/commons";
import locale from "localization";
import { initialState as formState } from "./generate-fuel-codes.state";
import { InputLitre, SelectDriver, SelectFuel, SelectVehicle } from "components/field";
import { useApi, useForm, useMount } from "hooks";
// import { getFuelBalance } from "apis";
import { formatAmount, parseLiter, parseNumber } from "utils";
import moment from "moment";
import { FuelCodeType } from "enums";
import classNames from "classnames";
import { getFuelBalance } from "apis/fuel-balance.api";
import { getFleet } from "apis";

const FuelCodeForm = ({ title, initialState, loading, close, submit, actionText, fleetId }) => {
  const form = useMemo(() => {
    return formState(initialState);
  }, [initialState]);
  const fleet = useApi({
    api: getFleet,
    params: {
      fleetId,
    },
  });
  const { fleetStations = [] } = fleet.result || {};

  const {
    fields = {},
    modifyField,
    modifyForm,
    validateField,
    submitForm,
    isFormSubmittable,
    getFormValues,
  } = useForm({
    initialState: form,
  });

  const {
    request: getFuelBalanceRequest,
    loading: fetchingFuelBalance,
    result: fuelBalance = [],
  } = useApi({
    api: getFuelBalance,
    params: {
      fleetId,
    },
    pageError: true,
  });

  const {
    productCode = {},
    literVolume = {},
    duration = {},
    period = {},
    vehicleIds = {},
    driverIds = {},
    fuelCodeType = {},
    limitOneTime = {},
    mobileNumber = {},
    sendAsSms = {},
    autoRenew = {},
    saveTemplate = {},
  } = fields;

  const pesoLiterValue = useMemo(() => {
    const { creditPrice = 0 } = productCode || {};
    const { value: literValue = 0 } = literVolume || {};
    return parseNumber(creditPrice) * parseNumber(literValue);
  }, [productCode, literVolume]);

  const hasMobileNumberInput = useMemo(() => {
    return [FuelCodeType.VehicleSpecific, FuelCodeType.AnyDriverAnyVehicle].includes(
      fuelCodeType.value
    );
  }, [fuelCodeType]);

  useMount(() => {
    getFuelBalanceRequest();

    fleet.request();
  }, []);

  const noProductCode = !productCode.value;
  return (
    <div>
      {/* <Title small className={styles.title}>
            {title}
          </Title> */}

      {fleetStations.length ? (
        <div className={styles.headerContainer}>
          <div>
            <locale.Populate
              className="flex"
              text={locale.canOnlyRedeemAt}
              items={[
                <PopOverList
                  className={styles.popOver}
                  listTitle={locale.redemptionStations}
                  list={fleetStations.map((station) => {
                    return station.name;
                  })}
                >
                  <span className={styles.linkStationList}>{locale.stations}</span>
                </PopOverList>,
              ]}
            />
          </div>
        </div>
      ) : null}

      <div>
        <Text label>{locale.selectProductType}</Text>
        <SelectFuel
          {...productCode}
          onChange={modifyField}
          getFuelBalanceRequest={getFuelBalanceRequest}
          fetchingFuelBalance={fetchingFuelBalance}
          fuelBalance={fuelBalance}
          fleetId={fleetId}
        />
      </div>

      <div className={styles.form}>
        <Field label={locale.volumeRedeemLimit} {...literVolume}>
          {literVolume.infoMessage ? (
            <div
              className="italic gray"
              style={{
                paddingBottom: "10px",
              }}
            >
              {literVolume.infoMessage}
            </div>
          ) : null}
          <InputLitre {...literVolume} onChange={modifyField} disabled={noProductCode} />
          <Text label className={styles.estimatedPesoValue}>
            <locale.Populate
              text={locale.approxPesoEquivalent}
              items={[
                <span className={styles.estimatedPesoValueAmount}>
                  {formatAmount(pesoLiterValue)}
                </span>,
              ]}
            />
          </Text>
        </Field>
        <div className={styles.subform}>
          <div className={styles.dateContainer}>
            <div className={styles.dateRange}>
              <Field label={locale.eligiblePeriod}>
                <DateRange
                  {...period}
                  disableEndDate
                  disableStartDate={period.disabled}
                  onChange={(name, value) => {
                    const { startDate } = { ...value.value };
                    const startDateValue = startDate;
                    const endDate = moment(startDateValue).add(
                      parseNumber(duration.value) - 1,
                      "days"
                    );
                    modifyForm({
                      [name]: {
                        value: {
                          ...value.value,
                          endDate: new Date(endDate),
                        },
                      },
                    });
                  }}
                  disabled={noProductCode || period.disabled}
                />
              </Field>
            </div>
            <div className={styles.duration}>
              <Field {...duration} label={locale.durationInDays}>
                <TextField
                  {...duration}
                  onChange={(name, { value }) => {
                    const startDateValue = period.value.startDate;
                    const endDate = moment(startDateValue).add(parseNumber(value - 1 || 0), "days");
                    endDate.set({ hour: 23, minute: 59, second: 59 });
                    modifyForm({
                      [name]: { value },
                      [period.name]: {
                        value: {
                          startDate: period.value.startDate,
                          endDate: new Date(endDate),
                        },
                      },
                    });
                  }}
                  onBlur={validateField}
                  disabled={!moment(period.value?.startDate, true).isValid()}
                />
              </Field>
            </div>
          </div>

          <Field label={locale.fuelCodeType}>
            <Dropdown
              {...fuelCodeType}
              onChange={(value) => {
                const obj = {
                  [FuelCodeType.DriverVehicleSpecific]: {
                    [vehicleIds.name]: {
                      value: null,
                    },
                    [mobileNumber.name]: {
                      value: "",
                      required: false,
                    },
                  },
                  [FuelCodeType.DriverSpecific]: {
                    [vehicleIds.name]: {
                      value: null,
                    },
                    [mobileNumber.name]: {
                      value: "",
                      required: false,
                    },
                  },
                  [FuelCodeType.VehicleSpecific]: {
                    [driverIds.name]: {
                      value: null,
                    },
                  },
                  [FuelCodeType.AnyDriverAnyVehicle]: {
                    [driverIds.name]: {
                      value: null,
                    },
                    [vehicleIds.name]: {
                      value: null,
                    },
                  },
                };
                modifyForm({
                  [fuelCodeType.name]: {
                    value,
                  },
                  ...obj[value],
                });
              }}
              disabled={noProductCode || fuelCodeType.disabled}
            />
          </Field>
          {(fuelCodeType.value === FuelCodeType.DriverSpecific ||
            fuelCodeType.value === FuelCodeType.DriverVehicleSpecific) && (
            <Field label={locale.assignedDriver}>
              <SelectDriver
                {...fields.driverIds}
                onChange={modifyField}
                disabled={noProductCode || fuelCodeType.disabled}
                fleetId={fleetId}
              />
            </Field>
          )}
          {(fuelCodeType.value === FuelCodeType.VehicleSpecific ||
            fuelCodeType.value === FuelCodeType.DriverVehicleSpecific) && (
            <Field label={locale.assignedVehicle}>
              <SelectVehicle
                {...fields.vehicleIds}
                onChange={modifyField}
                disabled={noProductCode || fuelCodeType.disabled}
                fleetId={fleetId}
              />
            </Field>
          )}
        </div>

        <div className={styles.checkboxes}>
          <Checkbox
            {...autoRenew}
            onChange={(name, { value }) => {
              modifyForm({
                [name]: {
                  value,
                },
              });
            }}
            disabled={noProductCode || autoRenew.disabled}
          />
          <locale.Populate
            text={locale.autoRenewFuelCode}
            items={[
              <b className={styles.autoRenew}>{duration.value ? duration.value : 0} day(s)</b>,
            ]}
          />
        </div>

        <div className={classNames(styles.checkboxes, styles.limit)}>
          <Checkbox
            {...limitOneTime}
            onChange={modifyField}
            disabled={noProductCode || limitOneTime.disabled}
          />
          <div>
            <div>{limitOneTime.label}</div>
            {limitOneTime.infoMessage ? (
              <div className="gray">{limitOneTime.infoMessage}</div>
            ) : null}
          </div>
        </div>

        <div className={styles.checkboxes}>
          <Checkbox
            {...sendAsSms}
            onChange={(name, { value }) => {
              modifyForm({
                [name]: {
                  value,
                },
                [mobileNumber.name]: {
                  value: "",
                  required: value && hasMobileNumberInput,
                },
              });
            }}
            disabled={noProductCode || sendAsSms.disabled}
          />
          {sendAsSms.label}
        </div>

        {hasMobileNumberInput && sendAsSms.value && (
          <Field
            {...mobileNumber}
            className={styles.mobileField}
            labelClassName={styles.labelMobileNumber}
          >
            <TextField
              {...mobileNumber}
              onChange={modifyField}
              onBlur={validateField}
              disabled={noProductCode || sendAsSms.disabled}
            />
          </Field>
        )}
        {!saveTemplate.hidden && (
          <div className={styles.checkboxes}>
            <Checkbox {...saveTemplate} onChange={modifyField} disabled={noProductCode} />
            {saveTemplate.label}
          </div>
        )}
      </div>

      <ActionButton
        right
        loading={loading}
        items={[
          {
            onClick: () => {
              close();
            },
            text: locale.cancel,
          },
          {
            onClick: () => {
              submitForm(() => {
                const values = getFormValues();
                submit({
                  ...values,
                  literVolume: parseLiter(values.literVolume),
                  startDate: values.period.startDate,
                  endDate: values.period.endDate,
                  maxUsage: values.limitOneTime ? 1 : 0,
                });
              });
            },
            disabled: !isFormSubmittable,
            text: actionText,
          },
        ]}
      />
    </div>
  );
};

export default FuelCodeForm;
