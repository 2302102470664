import React from "react";
import { useHistory } from "react-router-dom";
import locale from "localization";
import styles from "./voucher-view.module.scss";
import { FormMode } from "enums";
import { Path } from "enums";
import { Intro, BackLink } from "components/commons";
import VoucherDetailsModule from "../voucher-details/voucher-details.module";

const VoucherViewModule = (props) => {
  const history = useHistory();
  const editable = props.location.state ? props.location.state.editable : "";
  const voucherCampaignId = props.location.state ? props.location.state.voucherCampaignId : "";
  return (
    <div>
      <BackLink text={locale.voucherCampaigns} path={Path.VoucherCampaign} />
      <div className={styles.intro}>
        <Intro
          title={locale.voucherCampaignDetails}
          actionText={editable ? locale.edit : null}
          actionOnClick={() => {
            history.push(Path.EditVoucherCampaign, {
              voucherCampaignId,
            });
          }}
          actionOutlined
          actionClassName={styles.primaryEditButton}
          actionStartIcon={<span className="icon-pencil" />}
        />
        <VoucherDetailsModule pageMode={FormMode.View} voucherCampaignId={voucherCampaignId} />
      </div>
    </div>
  );
};

export default VoucherViewModule;
