import React, {
  useMemo,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useState,
} from "react";
import { useForm, useApi, useModal, useMount, useFilter } from "hooks";
import { FormMode, Path, CampaignStatus } from "enums";
import { initialState as formState } from "./discount-details.state";
import DiscountDetailsFormModule from "./discount-details-form.module";
import styles from "./discount-details.module.scss";
import locale from "localization";
import { Loader } from "components/commons";
import { handleRequest } from "utils";
import { ConfirmModal } from "components/modals";
import TargetStationsModal from "./target-stations-modal";
import ImportStationModal from "./import-station";
import { useHistory } from "react-router-dom";
import {
  createDiscountCampaign,
  updateDiscountCampaign,
  getDiscount,
  getUserAttributes,
  getAccountTypes,
  getStations,
  getDiscountCampaignStations,
} from "apis";
import {
  discountCampaignFormPost,
  discountCampaignFormGet,
  attributeIdIterate,
  getCohortIds,
  stationsIterate,
} from "./discount-campaign-form.utils";
import { TargetStationsFilterState } from "./target-stations-filter.state";
import usePagination from "hooks/usePagination";

const DiscountDetailsModule = ({ pageMode, discountCampaignId, stationCount, ...props }, ref) => {
  const { setUpdateLoading, setDisableUpdate } = props;
  const viewMode = pageMode === FormMode.View;
  const addMode = pageMode === FormMode.Add;
  const editMode = pageMode === FormMode.Edit;
  const [attributeInput, setAttributeInput] = useState([]);
  const [cohortInputs, setCohortInputs] = useState([]);
  const [stationInputs, setStationInputs] = useState([]);
  const [selectedAttributeCohort, setSelectedAttributeCohort] = useState([]);
  const [excludedAttributes, setExcludedAttributes] = useState([]);
  const [isAllCohort, setIsAllCohort] = useState([]);
  const [attributeRead, setAttributeRead] = useState([]);
  const [otherTargetAttribute, setOtherTargetAttribute] = useState({
    accountType: false,
    station: false,
  });
  const [isAllStation, setIsAllStation] = useState({ checked: false });
  const [campaignStatus, setCampaignStatus] = useState("");
  const [inputStationCount, setInputStationCount] = useState(0);
  const [stationslist, setStationsList] = useState([]);
  const [campaignStations, setCampaignStations] = useState([]);
  const ongoingCampaign = campaignStatus === CampaignStatus.Ongoing;
  const endedCampaign = campaignStatus === CampaignStatus.Ended;

  const {
    request: getDiscountRequest,
    loading: loadingDiscount,
    result: getDiscountResult,
  } = useApi({
    api: getDiscount,
    pageError: true,
    params: {
      discountCampaignId,
    },
  });

  const {
    request: getUserAttributesRequest,
    loading: loadingAttributes,
    result: getUserAttributesResult = { attributes: [], count: 0 },
  } = useApi({
    api: getUserAttributes,
    modalError: true,
    params: { page: 1, perPage: 10000 }, // todo: temporal fix perPage value
  });

  const { request: getAccountTypesRequest, result: getAccountTypesResult = { accountTypes: [] } } =
    useApi({
      api: getAccountTypes,
      modalError: true,
      params: { page: 1, perPage: 10000 }, // todo: temporal fix perPage value
    });

  const { request: getDiscountStationsRequest } = useApi({
    api: getDiscountCampaignStations,
    modalError: true,
    params: { page: 1 },
  });

  const {
    filterState,
    requestState,
    submitFilter,
    modifyFilters,
    filterCount,
    modifyFilter,
    clearFilter,
    submittedFilter,
  } = useFilter(TargetStationsFilterState());

  const { request: getStationsRequest, loading: loadingStations } = useApi({
    api: getStations,
    modalError: true,
    params: {
      platformType: "plc",
    },
  });

  const stationData = usePagination();
  const fetchStations = useCallback(
    async (rs = { ...requestState }, perPage) => {
      if (rs.reset) {
        stationData.reset();
      }
      if (stationData.canLoadMore || rs.reset) {
        const result = await getStationsRequest(
          submitFilter({
            perPage: perPage ? perPage : 10,
            ...rs,
            page: rs.reset ? 1 : stationData.page,
          }),
          null
        );
        stationData.onLoadMore({ data: result.stations, total: result.count, resetPage: rs.reset });

        // saves the count for the first render only
        if (inputStationCount === 0) {
          setInputStationCount(result?.count);
          setStationsList(result?.stations);
        }
        return result;
      }
    },
    [getStationsRequest, requestState, inputStationCount, submitFilter, stationData]
  );

  const populateAttributeInputValue = useCallback(({ cohorts, attributes, excludedAttributes }) => {
    let newAttributes = {};
    let selectCohorts = {};
    let cohortsInput = [];
    let isAllCohort = [];

    // merge excludedAttributes to display its own attribute input without value but with checked 'exclusion checkbox'
    [...cohorts, ...excludedAttributes]?.forEach((cohort) => {
      const attributeId = cohort?.attribute?.attributeId || cohort?.attributeId;
      const name = cohort?.attribute?.name || cohort?.name;
      if (cohort?.cohortId) {
        cohortsInput.push({
          name: cohort.name,
          value: cohort.cohortId,
          attributeId,
        });
      }
      const filteredAttributes = attributes.filter((a) => a.attributeId === attributeId)[0];
      newAttributes[attributeId] = {
        name,
        attributeId,
        cohorts: filteredAttributes?.cohorts,
      };

      const cohorts = cohortsInput.filter((c) => c?.attributeId === attributeId);
      selectCohorts[attributeId] = [];
      selectCohorts[attributeId] = cohorts;
      isAllCohort[attributeId] = cohorts.length === filteredAttributes?.cohorts?.length;
    });
    setSelectedAttributeCohort(selectCohorts);
    setExcludedAttributes(attributeIdIterate(excludedAttributes));
    setAttributeInput(Object.values(newAttributes));
    setIsAllCohort(isAllCohort);
  }, []);

  const form = useMemo(() => {
    let initialState = {};
    if (discountCampaignId) {
      const data = getDiscountResult;

      if (data) {
        data.stations = campaignStations;
        initialState = discountCampaignFormGet(data);
        if (attributeRead?.length > 0) {
          setCampaignStatus(data?.status);
          // inject values to cohorts of attribute inputs
          populateAttributeInputValue({
            cohorts: data?.cohorts,
            attributes: attributeRead,
            excludedAttributes: data?.excludedAttributes,
          });
        }
      }
    }
    return formState(initialState);
  }, [
    discountCampaignId,
    getDiscountResult,
    attributeRead,
    populateAttributeInputValue,
    campaignStations,
  ]);

  const confirmModal = useModal();
  const { show, close } = confirmModal;
  const stationsModal = useModal();
  const importStationModal = useModal();
  const history = useHistory();

  useMount(async () => {
    if (discountCampaignId) {
      getDiscountRequest();
      if (stationCount) fetchCampaignStations({ discountCampaignId, stationCount });
    }
    getAccountTypesRequest();
    const attributeRequest = await getUserAttributesRequest();
    if (attributeRequest?.attributes.length > 0) {
      setAttributeRead(attributeRequest.attributes);
    }
  });

  const {
    fields,
    modifyField,
    isFormSubmittable,
    submitForm,
    modifyForm,
    clearForm,
    getFormValues,
    applyFieldErrors,
  } = useForm({
    initialState: form,
  });

  const fetchCampaignStations = useCallback(
    async ({ discountCampaignId, stationCount }) => {
      const results = await getDiscountStationsRequest({
        discountCampaignId,
        perPage: stationCount,
      });

      if (results) {
        const formatStation = stationsIterate(results?.stations);
        setCampaignStations(formatStation);
      }
    },
    [getDiscountStationsRequest]
  );

  const addRequest = useApi({
    api: createDiscountCampaign,
    handleOwnError: {
      badrequest: true,
    },
  });

  const editRequest = useApi({
    api: updateDiscountCampaign,
    handleOwnError: {
      badrequest: true,
    },
    params: {
      discountCampaignId,
    },
  });

  const loading = addRequest.loading || editRequest.loading;

  const submit = (params) => {
    const apiRequest = addMode ? addRequest : editRequest;

    handleRequest(
      async () => {
        close();
        const result = await apiRequest.request(
          {
            ...params,
          },
          () => handleSubmit()
        );
        clearForm();
        if (addMode) {
          history.push(Path.Campaigns);
        } else {
          history.push(Path.ViewDiscountCampaign, {
            discountCampaignId,
            stationCount: result?.stationCount,
          });
        }
      },
      {
        DC1001: () => {
          applyFieldErrors({
            campaignName: locale.campaignAlreadyExist,
          });
        },
      }
    );
  };

  const handleSubmit = () => {
    const currentFormValues = getFormValues();
    let payload = discountCampaignFormPost(currentFormValues);
    const { products, startDate, endDate } = payload;

    if (inputStationCount === payload?.stations?.length) {
      payload.stations = [];
    }

    if (ongoingCampaign || endedCampaign) {
      delete payload.startDate;
    }

    if (products.length === 0 || !startDate || !endDate) {
      const productsError =
        products.length === 0 ? locale.insuficientProductError : locale.startDateEndDateError;
      show({
        title: addMode ? locale.createCampaignError : locale.saveChangesError,
        content: productsError,
        primary: {
          text: locale.close,
          onClick: () => close(),
        },
      });
    } else {
      show({
        title: addMode ? locale.createCampaign : locale.saveChangesQuestion,
        content: addMode
          ? locale.areYouSureProceedCreatingCampaign
          : locale.areYouSureSaveAllChangesCampaign,
        secondary: {
          text: addMode ? locale.Cancel : locale.continueEditing,
        },
        primary: {
          text: addMode ? locale.yesCreateCampaign : locale.saveChanges,
          onClick: () => {
            submit(payload);
          },
        },
      });
    }
  };

  useImperativeHandle(ref, () => ({
    handleUpdate() {
      submitForm(handleSubmit);
    },
  }));

  const onDateRangeCb = useCallback(
    (name, { value }) => {
      const { startDate, endDate } = value;
      modifyField(fields?.campaignDuration?.name, {
        value: {
          startDate: startDate,
          endDate: endDate,
        },
      });
    },
    [fields?.campaignDuration?.name, modifyField]
  );

  const onSetPaymentMethodRestrictionCb = useCallback(
    (name, { value }) => {
      modifyForm({
        paymentMethodRestriction: {
          value,
        },
        bins: {
          value: value === locale.yes ? "" : "",
          required: value === locale.yes ? true : false,
        },
        monthlyVolumeCap: {
          value: value === locale.yes ? "" : "",
          disabled: value === locale.no ? true : false,
          inputDisabled: value === locale.no ? true : false,
          required: value === locale.no ? false : true,
        },
      });
    },
    [modifyForm]
  );
  useEffect(() => {
    if (editMode) {
      setUpdateLoading(loading);
      setDisableUpdate(!isFormSubmittable);
    }
  }, [editMode, isFormSubmittable, loading, setDisableUpdate, setUpdateLoading]);

  const preparedAttributes = useMemo(() => {
    let preparedData = [];
    const { attributes } = getUserAttributesResult;

    if (!otherTargetAttribute.accountType && fields?.accountTypes?.value?.length === 0) {
      preparedData.push({
        content: locale.accountType,
        onClick: () => {
          setOtherTargetAttribute({
            ...otherTargetAttribute,
            accountType: !otherTargetAttribute.accountType,
          });
        },
      });
    }

    if (!otherTargetAttribute.station && fields?.stations?.value?.length === 0) {
      preparedData.push({
        content: locale.eligibleReferenceStation,
        onClick: () => {
          setOtherTargetAttribute({
            ...otherTargetAttribute,
            station: !otherTargetAttribute.station,
          });
        },
      });
    }

    attributes?.forEach((attribute) => {
      const selected = Object.keys(attributeInput).some((k) => {
        return attributeInput[k].name === attribute?.name;
      });
      if (!selected) {
        preparedData.push({
          content: attribute?.name,
          onClick: () => {
            if (attribute) {
              setAttributeInput([
                ...attributeInput,
                {
                  name: attribute?.name,
                  cohorts: attribute?.cohorts,
                  attributeId: attribute?.attributeId,
                },
              ]);
            }
          },
        });
      }
    });

    return preparedData;
  }, [
    getUserAttributesResult,
    otherTargetAttribute,
    fields?.accountTypes?.value?.length,
    fields?.stations?.value?.length,
    attributeInput,
  ]);

  const selectAccountType = useCallback(
    (name, { value, isAll }) => {
      modifyField(name, { value, isAll });
    },
    [modifyField]
  );

  const onChangeShoulderedByCb = useCallback(
    (shoulderedByType) => {
      modifyField(fields?.shoulderedBy?.name, { value: shoulderedByType });
    },
    [fields?.shoulderedBy?.name, modifyField]
  );

  const removeAccountType = useCallback(() => {
    setOtherTargetAttribute({ ...otherTargetAttribute, accountType: false });
    modifyField(fields?.accountTypes?.name, { value: [], isAll: false });
  }, [fields?.accountTypes?.name, modifyField, otherTargetAttribute]);

  const excludedAttributeCb = useCallback(
    ({ attributeId, selectedAttributeCohort = {}, forceRemove = false }) => {
      let newAttributeIds = [];
      if (forceRemove || excludedAttributes.includes(attributeId)) {
        newAttributeIds = excludedAttributes.filter((id) => id !== attributeId);
      } else {
        const clone = [...excludedAttributes];
        newAttributeIds = [...clone, attributeId];
      }
      let attributeFormState = { [fields?.excludedAttributes?.name]: { value: newAttributeIds } };

      if (Object.keys(selectedAttributeCohort).length > 0) {
        attributeFormState = {
          ...attributeFormState,
          [fields?.cohorts?.name]: { value: selectedAttributeCohort },
        };
      }
      setExcludedAttributes(newAttributeIds);
      modifyForm(attributeFormState);
    },
    [excludedAttributes, fields?.cohorts?.name, fields?.excludedAttributes?.name, modifyForm]
  );

  const selectCohorts = useCallback(
    (name, { value, isAll }) => {
      let cloneSelectedAttributeCohort = { ...selectedAttributeCohort };
      let isAllCohortClone = { ...isAllCohort };

      cloneSelectedAttributeCohort[name] = value;
      setSelectedAttributeCohort(cloneSelectedAttributeCohort);

      isAllCohortClone[name] = isAll;
      setIsAllCohort(isAllCohortClone);

      const cohortsId = getCohortIds(cloneSelectedAttributeCohort);

      // force unchecked excludedAttributeId
      if (cohortsId.length > 0 && excludedAttributes.includes(Number(name))) {
        excludedAttributeCb({
          attributeId: Number(name),
          selectedAttributeCohort: cloneSelectedAttributeCohort,
        });
      } else {
        modifyField(fields?.cohorts?.name, {
          value: cloneSelectedAttributeCohort,
        });
      }
    },
    [
      selectedAttributeCohort,
      isAllCohort,
      excludedAttributes,
      excludedAttributeCb,
      modifyField,
      fields?.cohorts?.name,
    ]
  );

  const removeAttribute = useCallback(
    (attributeName, attributeId) => {
      let cloneIsAllCohort = { ...isAllCohort };
      delete cloneIsAllCohort[attributeId];
      setIsAllCohort(cloneIsAllCohort);

      let cloneSelectedAttributeCohort = { ...selectedAttributeCohort };
      delete cloneSelectedAttributeCohort[attributeId];
      setSelectedAttributeCohort(cloneSelectedAttributeCohort);

      let cloneCohort = [...cohortInputs];
      cloneCohort.splice(attributeId, 1);
      setCohortInputs(cloneCohort);

      setAttributeInput(attributeInput.filter((attribute) => attribute.name !== attributeName));

      const cohortsId = getCohortIds(cloneSelectedAttributeCohort);

      // force unchecked excludedAttributeId
      if (cohortsId.length > 0) {
        excludedAttributeCb({
          attributeId,
          selectedAttributeCohort: cloneSelectedAttributeCohort,
          forceRemove: true,
        });
      } else {
        modifyField(fields?.cohorts?.name, {
          value: cloneSelectedAttributeCohort,
        });
      }
    },
    [
      attributeInput,
      cohortInputs,
      excludedAttributeCb,
      fields?.cohorts?.name,
      isAllCohort,
      modifyField,
      selectedAttributeCohort,
    ]
  );

  const selectStationsCb = useCallback(
    (stationParams) => {
      let newStations = [];
      let isAllSelected = false;

      const stationSelected = stationInputs.some((station) => {
        return station.value === stationParams.value;
      });
      if (stationSelected) {
        newStations = stationInputs.filter((station) => station.value !== stationParams.value);
      } else {
        const clone = [...stationInputs];
        newStations = [...clone, stationParams];
      }

      if (inputStationCount === newStations.length) {
        isAllSelected = true;
      }

      setIsAllStation({ checked: isAllSelected });
      setStationInputs(newStations);
    },
    [inputStationCount, stationInputs]
  );

  const onClearSelectedStation = useCallback(() => {
    modifyField(fields?.stations?.name, { value: [] });
    setStationInputs([]);
  }, [fields?.stations?.name, modifyField, setStationInputs]);

  const saveStationsCb = useCallback(() => {
    modifyField(fields?.stations?.name, {
      value: stationInputs,
    });
  }, [fields?.stations?.name, modifyField, stationInputs]);

  const targetStationsProps = {
    filterState,
    modifyFilters,
    filterCount,
    modifyFilter,
    clearFilter,
    selectStationsCb,
    stationInputs,
    setStationInputs,
    saveStationsCb,
    fetchStations,
    loadingStations,
    isAllStation,
    setIsAllStation,
    inputStationCount,
    stationslist,
    setStationsList,
    submittedFilter,
    stationData,
    onClearSelectedStation,
  };

  const detailsFormProps = {
    viewMode,
    addMode,
    editMode,
    fields,
    isFormSubmittable,
    loading,
    preparedAttributes,
    modifyField,
    submitForm,
    modifyForm,
    onDateRangeCb,
    handleSubmit,
    attributeInput,
    removeAttribute,
    selectAccountType,
    selectCohorts,
    selectedAttributeCohort,
    isAllCohort,
    otherTargetAttribute,
    setOtherTargetAttribute,
    getAccountTypesResult,
    removeAccountType,
    excludedAttributes,
    excludedAttributeCb,
    stationsModal,
    importStationModal,
    setStationInputs,
    getUserAttributesResult,
    campaignStatus,
    fetchStations,
    onChangeShoulderedByCb,
    onSetPaymentMethodRestrictionCb,
  };

  return (
    <div>
      <Loader open={loadingDiscount || loadingAttributes} />
      {!loadingDiscount && !loadingAttributes && (
        <div className={styles.container}>
          {!viewMode && (
            <>
              <ConfirmModal {...confirmModal} />
              <TargetStationsModal {...stationsModal} {...targetStationsProps} />
              <ImportStationModal
                {...importStationModal}
                request={""}
                stationData={stationData}
                setStationInputs={setStationInputs}
                saveStationsCb={saveStationsCb}
                stationInputs={stationInputs}
                modifyForm={modifyForm}
              />
            </>
          )}
          <DiscountDetailsFormModule {...detailsFormProps} />
        </div>
      )}
    </div>
  );
};

export default forwardRef(DiscountDetailsModule);
