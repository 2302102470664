import React from "react";
import { Route, Switch, Redirect } from "react-router";
import { Path } from "enums";
import UniqueVouchers from "modules/voucher/unique-vouchers/unique-vouchers.module";
import PanelButtons from "components/commons/panel-button/panel-buttons";

const VouchersPage = () => {
  return (
    <div>
      <PanelButtons
        items={[
          {
            text: "Unique Vouchers",
            path: Path.UniqueVouchers,
          },
          // {
          //   text: "Generic Vouchers",
          //   path: Path.GenericVouchers,
          // },
        ]}
      />
      <div style={{ marginTop: "60px" }}>
        <Switch>
          <Route exact path={Path.UniqueVouchers} component={UniqueVouchers} />
          {/* <Route exact path={Path.GenericVouchers} component={UniqueVouchers} /> */}
          <Redirect to={Path.UniqueVouchers} />
        </Switch>
      </div>
    </div>
  );
};

export default VouchersPage;
