import React from "react";
import { useModal } from "hooks";
import locale from "localization";
import styles from "./account-type-add.module.scss";
import { FormMode } from "enums";
import { Path } from "enums";
import { Intro, BackLink } from "components/commons";
import AccountTypeDetailsModule from "../account-type-details/account-type-details.module";

const AddAccountTypeModule = () => {
  const successModal = useModal();

  return (
    <div>
      <BackLink text={locale.accountTypes} path={Path.AccountTypes} />
      <div className={styles.intro}>
        <Intro title={locale.createAccountType} titleStyle={styles.title} />
        <AccountTypeDetailsModule pageMode={FormMode.Add} {...successModal} />
      </div>
    </div>
  );
};

export default AddAccountTypeModule;
