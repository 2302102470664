import { Intro } from "components/commons";
import React, { useCallback, useContext } from "react";
import NewCustomerVouchersList from "./new-customer-vouchers.list";
import locale from "localization";
import { useApi, useFilter, useMount } from "hooks";
import NewCustomerVouchersFilter from "./new-customer-vouchers-filter";
import { filterState } from "./new-customer-vouchers-filter.state";
import { useHistory } from "react-router-dom";
import { Path } from "enums";
import { getNewCustomerVouchers } from "apis/customer-voucher.api";
import useExport from "hooks/useExport";
import { generateReportAdmin } from "apis";
import { SuccessModal } from "components/modals";
import { SessionContext } from "contexts";

const NewCustomerVouchers = () => {
  const history = useHistory();
  const { sessionId } = useContext(SessionContext);
  const request = useApi({
    api: getNewCustomerVouchers,
  });
  const filter = useFilter(filterState());
  const { requestState, submitFilter } = filter;

  const handleRequestParam = useCallback(
    (filterValue) => {
      const obj = { ...filterValue };
      submitFilter(obj);
      const { statuses = [], voucherCurrency = [], ...params } = obj;
      return {
        page: 1,
        perPage: 10,
        ...params,
        currency: voucherCurrency.join(","),
        statuses: statuses.join(","),
      };
    },
    [submitFilter]
  );

  const fetchList = useCallback(
    (filterValue) => {
      request.request(handleRequestParam(filterValue));
    },
    [request, handleRequestParam]
  );

  const exportReport = useExport({
    api: generateReportAdmin,
    reportType: "new-customer-voucher",
    hasModal: true,
    mappedFilterState: {
      startDate: requestState?.startDate,
      endDate: requestState?.endDate,
      voucherCurrencies: requestState?.voucherCurrency?.join(","),
      newCustomerVoucherClaimStatuses: requestState?.statuses?.join(","),
      sessionId,
    },
  });

  useMount(() => {
    fetchList(requestState);
  });

  return (
    <div>
      <div>
        <Intro
          title={locale.newCustomerVoucher}
          subtitle={locale.viewAndTrackRedemptionOfNewCustomerVouchers}
          actionText={locale.addNewNewCustomerVouchers}
          actionOnClick={() => {
            history.push(Path.CreateNewCustomerVouchers);
          }}
        />
      </div>
      <NewCustomerVouchersFilter
        {...filter}
        fetch={fetchList}
        handleRequestParam={handleRequestParam}
        {...exportReport}
      />
      <NewCustomerVouchersList {...filter} api={request} fetch={fetchList} />
      <SuccessModal {...exportReport?.successModalComponent} />
    </div>
  );
};

export default NewCustomerVouchers;
