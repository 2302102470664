import { Field, Modal, TextField, ActionButton, Autocomplete, Text } from "components/commons";
import { useApi, useForm, useModal } from "hooks";
import React, { useMemo } from "react";
import { initialState as formState } from "./user-access-form.state";
import styles from "./user-access.module.scss";
import { ConfirmModal, SuccessModal } from "components/modals";
import locale from "localization";
import { useEffect } from "react";
import { handleRequest } from "utils";
import { SuccessActivateAccount } from "images";
import { prettifyRole } from "utils/pretty.utils";
import { Portal, Role } from "enums";
import { createUser, updateUserStatus } from "apis/profile.api";

const UserAccessForm = ({ initialState, actionText, isEdit, refreshPage, ...state }) => {
  const { close } = state;
  const successModal = useModal();
  const confirmModal = useModal();
  const form = useMemo(() => {
    return formState(initialState);
  }, [initialState]);

  const {
    fields,
    modifyField,
    submitForm,
    getFormValues,
    applyFieldErrors,
    validateField,
    clearForm,
    isFormSubmittable,
  } = useForm({
    initialState: form,
  });

  useEffect(() => {
    if (state.active) {
      clearForm();
    }
    //eslint-disable-next-line
  }, [state.active]);

  const createUserRequest = useApi({
    api: createUser,
    handleOwnError: {
      badrequest: true,
    },
  });

  const updateRoleRequest = useApi({
    api: updateUserStatus,
    handleOwnError: {
      badrequest: true,
    },
  });

  const loading = isEdit ? updateRoleRequest.loading : createUserRequest.loading;
  const params = getFormValues();

  const submit = async () => {
    let modalContent = {
      title: locale.saveDetailsAndSendEmail,
      content: (
        <locale.Populate
          text={locale.uponClickingSaveAndSendEmail}
          items={[<b>{params.email}</b>, <b>{prettifyRole(params.role)}</b>]}
        />
      ),
    };

    let requestUrl = createUserRequest;
    let requestParams = { email: params.email, role: params.role, portal: Portal.LOCQ };
    let confirmMessage = locale.saveAndSendEmail;
    if (isEdit) {
      modalContent = {
        title: locale.saveChangesQuestion,
        content: (
          <div>
            <locale.Populate
              text={locale.youAreAboutChangeRoleUser}
              items={[<b>{params.email}</b>, <b>{prettifyRole(params.role)}</b>]}
            />
          </div>
        ),
      };

      requestUrl = updateRoleRequest;
      requestParams = { userAccessId: params.userAccessId, email: params.email, role: params.role };
      confirmMessage = locale.saveChanges;
    }

    confirmModal.show({
      ...modalContent,
      secondary: {
        text: locale.cancel,
        onClick: async () => {
          confirmModal.close();
        },
      },
      primary: {
        text: confirmMessage,
        onClick: async () => {
          handleRequest(
            async () => {
              await requestUrl.request(requestParams, () => submit());
              confirmModal.close();
              if (isEdit) {
                SuccessConfirm();
              } else {
                successModal.show();
              }

              refreshPage(isEdit);
            },
            {
              P1020: () => {
                confirmModal.close();
                applyFieldErrors({
                  email: locale.emailAddressReceivedInvite,
                });
              },
              P1019: () => {
                confirmModal.close();
                applyFieldErrors({
                  email: locale.emailAddressExist,
                });
              },
            }
          );
        },
      },
    });
  };

  const SuccessConfirm = () => {
    confirmModal.show({
      image: "",
      title: locale.exclamatedSuccess,
      content: (
        <div>
          <locale.Populate text={locale.successfullyUpdateUser} items={[<b>{params.email}</b>]} />
        </div>
      ),
      primary: {
        text: locale.gotIt,
        onClick: () => {
          confirmModal.close();
        },
      },
    });
  };

  return (
    <div>
      <ConfirmModal {...confirmModal} loading={createUserRequest.loading} />
      <SuccessModal
        image={SuccessActivateAccount}
        title={locale.exclamatedSuccess}
        content={
          isEdit
            ? locale.detailHaveBeenSuccessfullyUpdated
            : locale.anAccountActivationInstructionHasBeenSent
        }
        {...successModal}
        primary={{
          text: isEdit ? locale.updateAccessType : locale.addAnotherUser,
          onClick: () => {
            successModal.close();
            clearForm();
          },
        }}
        secondary={{
          text: locale.goToListOfUsers,
          onClick: () => {
            close();
            successModal.close();
          },
        }}
      />
      <Modal close={loading ? false : close} {...state} className={styles.modalContent}>
        <div className={styles.formContent}>
          <div className={styles.content}>
            {!isEdit && (
              <Field {...fields.email} className={styles.inputDiv}>
                <TextField
                  {...fields.email}
                  onChange={modifyField}
                  fontColor={"#753BBD"}
                  onBlur={validateField}
                />
              </Field>
            )}
            {isEdit && (
              <Field {...fields.email} className={styles.inputDiv}>
                <Text normal>{params.email}</Text>
              </Field>
            )}
            <Field {...fields.role} className={styles.inputDiv}>
              <Autocomplete
                {...fields.role}
                options={[
                  {
                    label: prettifyRole(Role.PortalAdmin),
                    value: Role.PortalAdmin,
                  },
                  // {
                  //   label: prettifyRole(Role.Accounting),
                  //   value: Role.Accounting,
                  // },
                  // {
                  //   label: prettifyRole(Role.SalesPartnerships),
                  //   value: Role.SalesPartnerships,
                  // },
                ]}
                onChange={modifyField}
              />
            </Field>
          </div>
        </div>
        <ActionButton
          right
          items={[
            {
              onClick: () => {
                state.close();
              },
              disabled: loading,
              text: locale.cancel,
            },
            {
              onClick: () => {
                submitForm(submit);
              },
              disabled: !isFormSubmittable,
              loading,
              text: locale.save,
            },
          ]}
        />
      </Modal>
    </div>
  );
};

export default UserAccessForm;
