import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import locale from "localization";
import styles from "./account-type-view.module.scss";
import { FormMode, Path, Role, Portal } from "enums";
import { Intro, BackLink } from "components/commons";
import AccountTypeDetailsModule from "../account-type-details/account-type-details.module";
import { UserContext } from "contexts";

const ViewAccountTypeModule = (props) => {
  const history = useHistory();
  const accountTypeId = props.location.state ? props.location.state.accountTypeId : "";
  const { user } = useContext(UserContext);
  const { role } = user?.userAccesses?.find((element) => {
    return element.portal === Portal.LOCQ;
  });

  return (
    <div>
      <BackLink text={locale.accountTypes} path={Path.AccountTypes} />
      <div className={styles.intro}>
        {role === Role.Accounting ? <Intro
          title={locale.accountTypeDetails}
          titleStyle={styles.title}
        /> : <Intro
        title={locale.accountTypeDetails}
        actionText={locale.edit}
        actionOnClick={() => {
          history.push(Path.EditAccountType, {
            accountTypeId,
          });
        }}
        actionOutlined
        actionClassName={styles.primaryEditButton}
        actionStartIcon={<span className="icon-pencil" />}
        titleStyle={styles.title}
      />}
        
        <AccountTypeDetailsModule pageMode={FormMode.View} accountTypeId={accountTypeId} />
      </div>
    </div>
  );
};

export default ViewAccountTypeModule;
