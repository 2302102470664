export const filterState = () => ({
  app: {
    value: "all",
    label: "All",
  },
  status: {
    value: "all",
    label: "All",
  },
  searchKey: "",
  page: 1,
  perPage: 10,
});
