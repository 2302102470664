import React, { useCallback, useMemo, useState } from "react";
import { ActionButton, Modal, DataTable, Text, Checkbox } from "components/commons";
import locale from "localization";
import styles from "./target-stations-modal.module.scss";
import TargetStationsFilter from "./target-stations-filter";
import { TargetStationsColumns } from "./target-stations-columns";
import { StationType } from "enums";
import { CircularProgress } from "@material-ui/core";
import classNames from "classnames";

const TargetStationsModal = ({ ...props }) => {
  const {
    close,
    active,
    filterState,
    modifyFilters,
    filterCount,
    modifyFilter,
    clearFilter,
    selectStationsCb,
    stationInputs,
    setStationInputs,
    saveStationsCb,
    fetchStations,
    loadingStations,
    isAllStation,
    setIsAllStation,
    inputStationCount,
    stationslist,
    setStationsList,
    submittedFilter,
    stationData,
    onClearSelectedStation,
  } = props;
  const [stationType, setStationType] = useState([]);

  const onSearchCb = useCallback(
    async (searchKey) => {
      const { requestState } = modifyFilters({ searchKey, page: 1 });
      if (requestState.chargedTo.length === 1) {
        requestState.chargedTo = requestState.chargedTo[0].toString();
      } else {
        delete requestState.chargedTo;
      }
      const result = await fetchStations({ ...requestState, reset: true });
      setStationsList(result?.stations);
    },
    [fetchStations, modifyFilters, setStationsList]
  );

  const onChangeStationTypeCb = useCallback(
    (name, { value }) => {
      if (name) {
        let newStationType = stationType;
        if (name === "all") {
          Object.values(StationType).forEach((st) => {
            if (value) {
              if (!newStationType.includes(st)) {
                newStationType.push(st);
              }
            } else {
              newStationType.splice(stationType.indexOf(st), 1);
            }
          });
        } else {
          if (value) {
            newStationType.push(name);
          } else {
            newStationType.splice(stationType.indexOf(name), 1);
          }
        }
        setStationType(newStationType);
        modifyFilters({ chargedTo: newStationType });
      }
    },
    [modifyFilters, stationType]
  );

  const preparedTargetStationsData = useMemo(() => {
    const data = stationData.data;
    if (data && data.length > 0) {
      const preparedData = data.map((s) => {
        const map = new Map();
        const isChecked = stationInputs.some((station) => {
          return station.value === s?.stationId;
        });
        map.set(
          "checkbox",
          <Checkbox
            checked={isChecked}
            value={isChecked}
            onChange={() =>
              selectStationsCb({
                label: s?.name,
                value: s?.stationId,
              })
            }
          />
        );
        map.set("stationCode", <Text className={styles.textStyle}>{s?.stationCode}</Text>);
        map.set("stationName", <Text className={styles.textStyle}>{s?.name}</Text>);
        map.set("stationType", <Text className={styles.textStyle}>{s?.stationType}</Text>);

        return map;
      });
      return preparedData;
    }
    return [];
  }, [selectStationsCb, stationInputs, stationData]);

  const onCheckAllCb = useCallback(
    async (value) => {
      let isAllSelected = false;
      let stationInputData = [];
      if (value) {
        if (stationData.canLoadMore) {
          const result = await fetchStations({
            ...submittedFilter,
            perPage: inputStationCount,
            platformType: "plc",
            reset: true,
          });
          result?.stations.forEach((s) => {
            stationInputData.push({
              label: s?.name,
              value: s?.stationId,
            });
          });
        } else {
          stationData.data.forEach((d) => {
            stationInputData.push({
              label: d?.name,
              value: d?.stationId,
            });
          });
        }
        isAllSelected = true;
      }

      setStationInputs(stationInputData);
      setIsAllStation({ checked: isAllSelected });
    },
    [
      fetchStations,
      setIsAllStation,
      setStationInputs,
      inputStationCount,
      submittedFilter,
      stationData,
    ]
  );

  const closeCb = useCallback(async () => {
    close();
    setStationInputs([]);
    setIsAllStation({ checked: false });
    const { requestState } = clearFilter();
    const result = await fetchStations({ ...requestState, reset: true });
    setStationsList(result?.stations);
  }, [clearFilter, close, fetchStations, setIsAllStation, setStationInputs, setStationsList]);

  return (
    <Modal
      active={active}
      title={locale.selectStations}
      close={closeCb}
      className={styles.modal}
      titleStyle={styles.titleStyle}
    >
      <div className={styles.content}>
        <div className={styles.filters}>
          <TargetStationsFilter
            filterState={filterState}
            onSearchChange={modifyFilter}
            onSearch={onSearchCb}
            resetFilter={() => {}}
            onClearSelectedStation={onClearSelectedStation}
            clearFilter={async () => {
              const { requestState } = clearFilter(["searchKey"]);

              const result = await fetchStations({ ...requestState, reset: true });
              setStationsList(result?.stations);
            }}
            submitFilter={async () => {
              const { requestState } = modifyFilters({ page: 1 });
              if (requestState.chargedTo.length === 1) {
                requestState.chargedTo = requestState.chargedTo[0].toString();
              } else {
                delete requestState.chargedTo;
              }
              const result = await fetchStations({ ...requestState, reset: true });
              setStationsList(result?.stations);
            }}
            filterCount={filterCount}
            inputStationCount={stationInputs?.length}
            onChangeStationType={onChangeStationTypeCb}
          />
        </div>
        <div
          className={styles.table}
          onScroll={async (e) => {
            if (
              stationData.canLoadMore &&
              !loadingStations &&
              e.target.scrollTop <= e.target.scrollHeight - e.target.offsetHeight
            ) {
              const { requestState } = modifyFilters({ page: Number(filterState.page) + 1 });
              if (requestState.chargedTo.length === 1) {
                requestState.chargedTo = requestState.chargedTo[0].toString();
              } else {
                delete requestState.chargedTo;
              }
              const result = await fetchStations(requestState);
              setStationsList([...stationslist, ...result?.stations]);
            }
          }}
        >
          {loadingStations && !stationData.data.length ? (
            <div className={styles.stationListloading}>
              <Text align="center">{locale.loading}</Text>
            </div>
          ) : (
            <DataTable
              page={filterState.page}
              pageSize={filterState.perPage}
              columns={TargetStationsColumns}
              data={preparedTargetStationsData}
              dataCount={inputStationCount}
              hidePagination
              actionHeaderProps={isAllStation}
              onCheckAll={onCheckAllCb}
            />
          )}
          {loadingStations && stationData.data.length ? (
            <div
              className={classNames(
                "text-center flex items-center full-width",
                styles.circularLoader
              )}
            >
              <CircularProgress
                style={{
                  margin: "auto",
                }}
              />
            </div>
          ) : null}
        </div>
      </div>
      <ActionButton
        right
        items={[
          {
            onClick: closeCb,
            text: locale.cancel,
          },
          {
            onClick: () => {
              saveStationsCb();
              close();
              setStationInputs([]);
            },
            text: locale.save,
            disabled: stationInputs?.length === 0,
          },
        ]}
      />
    </Modal>
  );
};

export default TargetStationsModal;
