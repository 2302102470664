import { Autocomplete, SearchField, Filter, Field, Button } from "components/commons";
import { SelectStation } from "components/field";
import React from "react";
import styles from "./fleet-pricing-filter.module.scss";

import locale from "localization";

import { PricingStationType } from "enums";

const { COCO, COXO, CODO, CODOX, DODO, DODOX, WIBCO, WIDOX, WILCO } = PricingStationType;

const FleetsPricingFilter = ({
  filterState,
  applyFilter,
  applyClearFilter,
  resetFilter,
  modifyFilter,
  modifySearch,
  applySearch,
  applyClearSearch,
  searchKey,
  filterCount,
  exportReport,
  exporting,
}) => {
  const { referenceStation, stationTypes } = filterState;

  return (
    <div className={styles.container}>
      <div className={styles.filter}>
        <Filter
          filterCount={filterCount}
          submit={applyFilter}
          close={resetFilter}
          clear={applyClearFilter}
        >
          <Field
            label={locale.stationType}
            labelPosition={"left"}
            className={styles.filterInput}
            childrenClassName={styles.fieldContent}
          >
            <Autocomplete
              hasAll
              multiple
              name="stationTypes"
              value={stationTypes.value}
              options={[
                { label: COCO, value: COCO },
                { label: CODO, value: CODO },
                { label: CODOX, value: CODOX },
                { label: COXO, value: COXO },
                { label: DODO, value: DODO },
                { label: DODOX, value: DODOX },
                { label: WIBCO, value: WIBCO },
                { label: WIDOX, value: WIDOX },
                { label: WILCO, value: WILCO },
              ]}
              onChange={(_, { value, isAll }) => {
                modifyFilter({ stationTypes: { value, isAll } });
              }}
            />
          </Field>
          <Field
            label={locale.referenceStation}
            labelPosition={"left"}
            className={styles.filterInput}
            childrenClassName={styles.fieldContent}
          >
            <SelectStation
              {...referenceStation}
              isMultiple
              disableAllOption
              isPlbOnboarded
              allLabel={locale.station}
              allSelectedText={locale.allStations}
              selectAllLabel={locale.allStations}
              onChange={(_, { value, isAll }) => {
                modifyFilter({ referenceStation: { value, isAll } });
              }}
            />
          </Field>
        </Filter>
        <Field className={styles.search}>
          <SearchField
            placeholder={locale.businessName}
            value={searchKey}
            onChange={(_, { value }) => {
              modifySearch(value);
            }}
            onClear={applyClearSearch}
            onEnter={applySearch}
            restrict={false}
          />
        </Field>
        <Field className={styles.downloadButton}>
          <Button
            startIcon={<span className="icon-download" />}
            exportButton
            onClick={exportReport}
            loading={exporting}
          >
            {locale.export}
          </Button>
        </Field>
      </div>
    </div>
  );
};

export default FleetsPricingFilter;
