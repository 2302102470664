import locale from "localization";

export const columns = [
  { key: "buyFuelId", text: locale.buyFuelId, width: "8%" },
  { key: "purchasedDate", text: locale.purchasedDate, width: "12.5%" },
  { key: "businessNameId", text: locale.businessNameId, width: "12.5%" },
  { key: "referenceStation", text: locale.referenceStation, width: "19%" },
  { key: "product", text: locale.product, width: "12%" },
  { key: "creditPrice", text: locale.creditPrice, width: "12%" },
  // { key: "supplierWpp", text: locale.supplierWpp, width: "10%" },
  { key: "volume", text: locale.volume, width: "12%" },
  { key: "totalCreditAmount", text: locale.totalCreditAmount, width: "25%" },
];
