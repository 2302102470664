import { Autocomplete, Button, DateRange, Field, Filter, SearchField } from "components/commons";
import SelectCampaignCreators from "components/field/select-campaign-creators/select-campaign-creators";
import { VoucherCampaignCurrency } from "enums";
import VoucherCampaignGroup from "enums/campaign-group";
import VoucherStatus from "enums/voucher-status";
import localization from "localization";
import React from "react";
import { mapOptions } from "utils/object.utils";
import {
  prettifyVoucherStatus,
  prettifyVoucherCampaignCurrency,
  prettifyVoucherCampaignGroup,
} from "utils/pretty.utils";
import styles from "./unique-vouchers-filters.module.scss";

const UniqueVoucherFilter = ({
  filterState,
  submitFilter,
  resetFilter,
  clearFilter,
  filterCount,
  modifyFilters,
  onExport,
  exporting,
  isExportDisbaled,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Filter
          submit={submitFilter}
          close={resetFilter}
          clear={clearFilter}
          filterCount={filterCount}
          filterState={filterState}
          disabled={!filterState.statuses.length && !filterState.currencies.length}
        >
          {[
            {
              name: "dateClaimed",
              content: (
                <Field
                  className={styles.filterLabel}
                  label={localization.dateClaimed}
                  labelPosition={"left"}
                  childrenClassName={styles.fieldContent}
                  l
                >
                  <DateRange
                    value={{
                      startDate: filterState.startDate,
                      endDate: filterState.endDate,
                    }}
                    onChange={(name, { value }) => {
                      const { startDate, endDate } = value || {};
                      modifyFilters({
                        startDate,
                        endDate,
                      });
                    }}
                  />
                </Field>
              ),
            },
            {
              name: "status",
              content: (
                <Field
                  className={styles.filterInput}
                  label={localization.status}
                  labelPosition={"left"}
                  childrenClassName={styles.fieldContent}
                >
                  <Autocomplete
                    name="status"
                    value={filterState.statuses.value}
                    hasAll
                    placeholder={localization.selectStatus}
                    options={[
                      // VoucherStatus.Available,
                      VoucherStatus.Unclaimed,
                      VoucherStatus.Used,
                      VoucherStatus.Expired,
                      VoucherStatus.Void,
                    ].map((item) => ({
                      label: prettifyVoucherStatus(item),
                      value: item,
                    }))}
                    onChange={(name, { value, isSelectedAll }) => {
                      modifyFilters({
                        statuses: { value, isSelectedAll },
                      });
                    }}
                    multiple
                  />
                </Field>
              ),
            },
            {
              name: "currency",
              content: (
                <Field
                  className={styles.filterInput}
                  label={localization.currency}
                  labelPosition={"left"}
                  childrenClassName={styles.fieldContent}
                >
                  <Autocomplete
                    name="currency"
                    value={filterState.currencies.value}
                    hasAll
                    placeholder={localization.selectCurrency}
                    options={[
                      VoucherCampaignCurrency.Peso,
                      VoucherCampaignCurrency.Liter,
                      VoucherCampaignCurrency.Points,
                    ].map((item) => ({
                      label: prettifyVoucherCampaignCurrency(item),
                      value: item,
                    }))}
                    onChange={(name, { value, isSelectedAll }) => {
                      modifyFilters({
                        currencies: { value, isSelectedAll },
                      });
                    }}
                    multiple
                  />
                </Field>
              ),
            },
          ].map((item, index) => (
            <div key={index}>{item.content}</div>
          ))}
          <Field
            className={styles.filterInput}
            label={localization.campaignGroups}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
          >
            <Autocomplete
              name="campaignGroups"
              value={filterState.campaignGroups.value}
              hasAll
              options={mapOptions(
                [
                  VoucherCampaignGroup.SPIAllocation,
                  VoucherCampaignGroup.SPIAllowance,
                  // VoucherCampaignGroup.SPIObTravel,
                  VoucherCampaignGroup.Others,
                ],
                prettifyVoucherCampaignGroup
              )}
              onChange={(name, { value, isSelectedAll }) => {
                modifyFilters({ [name]: { value, isSelectedAll } });
              }}
              multiple
            />
          </Field>

          <Field
            className={styles.filterInput}
            label={localization.campaignCreators}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
          >
            <SelectCampaignCreators
              {...filterState.campaignCreators}
              onChange={(name, { value, label, isAll }) => {
                modifyFilters({ campaignCreators: { value, label, isAll } });
              }}
              disableAllOption
              placeholder={localization.allCampaignCreators}
            />
          </Field>
        </Filter>
        <Field className={styles.search}>
          <SearchField
            placeholder={`${localization.campaignName}, ${localization.mobileNumber}, ${localization.name}, ${localization.voucherCode}`}
            value={filterState.searchKey}
            onChange={(name, { value }) => {
              modifyFilters({
                searchKey: value,
              });
            }}
            onEnter={() => {
              submitFilter();
            }}
            onClear={() => {
              submitFilter({ searchKey: "" });
            }}
            restrict={false}
          />
        </Field>
      </div>
      <Button
        startIcon={<span className="icon-download" />}
        exportButton
        onClick={() => {
          onExport();
        }}
        disabled={exporting || isExportDisbaled}
        loading={exporting}
      >
        {localization.export}
      </Button>
    </div>
  );
};

export default UniqueVoucherFilter;
