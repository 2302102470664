import { generateReportAdmin, getVoucherCampaigns, updateVoucherCampaign } from "apis";
import { DataTable, Intro, Text, PanelButton, PopOverMenu } from "components/commons";
import classNames from "classnames";
import moment from "moment";
import {
  dateTimeFormat,
  formatDate,
  formatPesoWithDecimalRange,
  formatVolume,
  formatNumber,
  prettifyVoucherCampaignType,
  prettifyVoucherCampaignCurrency,
} from "utils";
import { CampaignStatus, VoucherCampaignCurrency, CampaignType, Path } from "enums";
import { useApi, useFilter, useMount, useModal, useRouter } from "hooks";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { campaignsColumns } from "./voucher-columns";
import { vouchersFilterState } from "./voucher-filter.state";
import ConfirmCancelVoucherModal from "./confirm-cancel-voucher-modal";
import VoucherFilter from "./voucher-filter";
import styles from "./voucher.module.scss";
import locale from "localization";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ConfirmReactivateVoucherModal from "./confirm-reactivate-voucher-modal";
import useExport from "hooks/useExport";
import { SuccessModal } from "components/modals";
import { SessionContext } from "contexts";
import { FeatureFlagContext } from "contexts";

const VoucherModule = () => {
  const { hasFeature } = useContext(FeatureFlagContext);
  const confirmModal = useModal();
  const reactivateModal = useModal();
  const { sessionId } = useContext(SessionContext);
  const [name, setName] = useState("");
  const [campaignId, setCampaignId] = useState("");
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const { Ongoing, Ended, Cancelled } = CampaignStatus;
  const { Peso, Liter } = VoucherCampaignCurrency;
  const { history } = useRouter();

  const {
    modifyFilter,
    modifyFilters,
    filterState,
    submitFilter,
    filterCount,
    requestState,
    clearFilter,
    initialState,
  } = useFilter(vouchersFilterState());

  const {
    request: getVoucherCampaignRequest,
    loading: fetchingCampaigns,
    result: getVoucherResult = { voucherCampaigns: [], count: 0 },
  } = useApi({
    api: getVoucherCampaigns,
    pageError: false,
  });

  useMount(() => {
    fetchCampaigns();
  });

  const { request: cancelCampaignRequest, loading: updatingCampaign } = useApi({
    api: updateVoucherCampaign,
    handleOwnError: {
      badrequest: true,
    },
    params: {
      campaignId,
    },
  });

  const fetchCampaigns = useCallback(
    async (requestState) => {
      const { currency, type, status, campaignCreators, campaignGroups, ...newRequestState } =
        submitFilter(requestState);

      await getVoucherCampaignRequest({
        ...newRequestState,
        currencies: currency.join(","),
        types: type.join(","),
        status: status.join(","),
        campaignGroups:
          initialState.campaignGroups.length === campaignGroups.length
            ? ""
            : campaignGroups.join(","),
        campaignCreators: campaignCreators?.isAll
          ? ""
          : campaignCreators?.value.map((v) => v.value).join(","),
      });
    },
    [getVoucherCampaignRequest, submitFilter, initialState]
  );

  const getStatus = useCallback(
    (status) => {
      return (
        <div
          className={classNames(styles.upcoming, {
            [`${styles.ongoing}`]: Ongoing === status,
            [`${styles.ended}`]: Ended === status,
            [`${styles.cancelled}`]: Cancelled === status,
          })}
        >
          {status}
        </div>
      );
    },
    [Cancelled, Ended, Ongoing]
  );

  const preparedCustomerListData = useMemo(() => {
    const { voucherCampaigns } = getVoucherResult;
    const displayValue = (value, currency) => {
      let returnValue = null;
      switch (currency) {
        case Peso:
          returnValue = formatPesoWithDecimalRange(value ? value : 0, 2);
          break;
        case Liter:
          returnValue = formatVolume(value);
          break;
        default:
          returnValue = formatNumber(value, 0);
      }
      return returnValue;
    };

    if (voucherCampaigns.length > 0) {
      const preparedData = voucherCampaigns.map((s) => {
        const map = new Map();
        map.set(
          "voucherCampaignId",
          <div className={styles.city}>
            <Text className={styles.upperText} subtitle>
              {s.voucherCampaignId && s.voucherCampaignId}
            </Text>
          </div>
        );
        map.set(
          "namePrefix",
          <div className={styles.city}>
            <Text className={styles.upperText}>{s.campaignName && s.campaignName}</Text>
            <Text className={styles.lowerText}>{s.voucherPrefix && s.voucherPrefix}</Text>
          </div>
        );
        map.set(
          "valueCurrency",
          <div className={styles.city}>
            <Text className={styles.upperText} subtitle>
              {displayValue(s.voucherValue, s.voucherCurrency)}
            </Text>
            <Text className={styles.lowerText} small>
              {s.voucherCurrency && prettifyVoucherCampaignCurrency(s.voucherCurrency)}
            </Text>
          </div>
        );
        map.set(
          "voucherType",
          <div className={styles.city}>
            <Text className={styles.upperText} subtitle>
              {s.voucherType && prettifyVoucherCampaignType(s.voucherType)}
            </Text>
          </div>
        );
        map.set(
          "actualRedemption",
          <div className={styles.city}>
            <Text className={styles.upperText} subtitle>
              {s.actualRedemption && s.actualRedemption}
            </Text>
          </div>
        );
        map.set(
          "quantityMaxRedemptions",
          <div className={styles.city}>
            <Text className={styles.upperText} subtitle>
              {s.voucherQuantity && s.voucherQuantity}
            </Text>
          </div>
        );
        map.set(
          "startEndDate",
          <div className={styles.city}>
            <Text className={styles.upperText} subtitle>
              {s.startDate && formatDate(s.startDate, "DD MMM YYYY")}
            </Text>
            <Text className={styles.lowerText} small>
              {s.endDate && formatDate(s.endDate, "DD MMM YYYY")}
            </Text>
          </div>
        );
        map.set(
          "dateCreated",
          <div className={styles.product}>
            <Text className={styles.upperText} subtitle>
              {s.createdAt && formatDate(s.createdAt)}
            </Text>
            <Text className={classNames(styles.upperText, styles.lowerCase)} subtitle>
              {s.createdAt && formatDate(s.createdAt, "hh:mma")}
            </Text>
          </div>
        );
        map.set(
          "status",
          <div className={styles.city}>
            {getStatus(s.status)}
            <Text className={styles.lowerText} small>
              {s.updatedAt && dateTimeFormat(s.updatedAt)}
            </Text>
          </div>
        );
        map.set(
          "action",
          <PopOverMenu
            options={[
              {
                content: locale.viewDetails,
                onClick: () => {
                  if (hasFeature("VOUCHER_V2")) {
                    history.push(Path.ViewVoucherCampaignId(s.voucherCampaignId));
                  } else {
                    history.push(Path.ViewVoucherCampaign, {
                      voucherCampaignId: s.voucherCampaignId,
                      editable: s.status !== Cancelled,
                    });
                  }
                },
              },
              {
                content: locale.editDetails,
                onClick: () => {
                  if (hasFeature("VOUCHER_V2")) {
                    history.push(Path.EditVoucherCampaignId(s.voucherCampaignId));
                  } else {
                    history.push(Path.EditVoucherCampaign, {
                      voucherCampaignId: s.voucherCampaignId,
                    });
                  }
                },
                disabled: s.status === Cancelled,
              },
              {
                content: locale.cancel,
                onClick: () => {
                  setName(s.campaignName);
                  setCampaignId(s.voucherCampaignId);
                  setSelectedCampaign(s);
                  confirmModal.show();
                },
                disabled: s.status === Cancelled || s.status === Ended,
              },
              {
                content: locale.reactivate,
                onClick: () => {
                  setName(s.campaignName);
                  setCampaignId(s.voucherCampaignId);
                  setSelectedCampaign(s);
                  reactivateModal.show();
                },
                disabled:
                  !(s.status === Cancelled) ||
                  s.status === Ended ||
                  moment(s.endDate, true).isBefore(moment()),
              },
            ]}
          >
            <MoreVertIcon className={styles.actionIcon} />
          </PopOverMenu>
        );
        return map;
      });
      return preparedData;
    }
    return [];
  }, [
    Liter,
    Peso,
    Ended,
    Cancelled,
    confirmModal,
    reactivateModal,
    history,
    getVoucherResult,
    getStatus,
    hasFeature,
  ]);

  const onSubmitCancel = useCallback(async () => {
    const cancelValues = {
      ...selectedCampaign,
      campaignId: campaignId,
      status: "cancelled",
    };
    // close();
    await cancelCampaignRequest(cancelValues);
    confirmModal.close();
    const { requestState } = modifyFilters();
    fetchCampaigns(requestState);
  }, [
    campaignId,
    confirmModal,
    modifyFilters,
    fetchCampaigns,
    cancelCampaignRequest,
    selectedCampaign,
  ]);

  const onSubmitReactivate = useCallback(async () => {
    const cancelValues = {
      ...selectedCampaign,
      campaignId: campaignId,
      status: "ongoing",
    };
    // close();
    await cancelCampaignRequest(cancelValues);
    reactivateModal.close();
    const { requestState } = modifyFilters();
    fetchCampaigns(requestState);
  }, [
    campaignId,
    reactivateModal,
    cancelCampaignRequest,
    modifyFilters,
    fetchCampaigns,
    selectedCampaign,
  ]);
  const onChangePageCb = useCallback(
    (page) => {
      const { requestState } = modifyFilters({ page });
      const newRequestState = prepareRequestState(requestState);
      fetchCampaigns(newRequestState);
    },
    [modifyFilters, fetchCampaigns]
  );

  const onChangePageSizeCb = useCallback(
    (perPage) => {
      const { requestState } = modifyFilters({ perPage, page: 1 });
      const newRequestState = prepareRequestState(requestState);
      fetchCampaigns(newRequestState);
    },
    [fetchCampaigns, modifyFilters]
  );

  const onSearchCb = useCallback(
    (searchKey) => {
      const { requestState } = modifyFilters({ searchKey, page: 1 });
      const newRequestState = prepareRequestState(requestState);
      fetchCampaigns(newRequestState);
    },
    [modifyFilters, fetchCampaigns]
  );

  const onDateRangeCb = useCallback(
    (value) => {
      const { startDate, endDate } = value;
      modifyFilters({ startDate, endDate, page: 1 });
    },
    [modifyFilters]
  );

  const prepareRequestState = (requestState) => {
    const newRequestState = requestState;
    return newRequestState;
  };

  const PanelButtonProps = {
    discountProps: {
      onClick: () => history.push(Path.Campaigns),
    },
    voucherProps: {
      onClick: () => history.push(Path.VoucherCampaign),
    },
  };

  const exportReport = useExport({
    api: generateReportAdmin,
    reportType: "voucher-campaign",
    hasModal: true,
    mappedFilterState: {
      endDate: requestState?.endDate,
      startDate: requestState?.startDate,
      voucherTypes: requestState?.type.join(","),
      voucherCurrencies: requestState?.currency.join(","),
      voucherCampaignStatuses: requestState?.status.join(","),
      voucherCampaignGroups:
        initialState?.campaignGroups?.length === requestState?.campaignGroups.length
          ? ""
          : requestState?.campaignGroups.join(","),
      voucherCampaignCreators: requestState?.campaignCreators?.isAll
        ? ""
        : requestState?.campaignCreators?.value.map((v) => v.value).join(","),
      searchKey: requestState?.searchKey,
      sessionId,
    },
  });

  return (
    <div>
      <PanelButton type={CampaignType.Voucher} {...PanelButtonProps} />

      <div>
        <Intro
          title={`${locale.voucher} ${locale.campaigns}`}
          subtitle={locale.viewAndCreateVoucherCampaigns}
          actionText={locale.createVoucherCampaign}
          actionOnClick={() => {
            history.push(Path.AddVoucherCampaign);
          }}
        />
      </div>
      <div className={styles.filters}>
        <VoucherFilter
          filterState={filterState}
          onDateRangeChange={onDateRangeCb}
          onSearchChange={modifyFilter}
          onSearch={onSearchCb}
          resetFilter={() => {
            modifyFilters({
              endDate: filterState.endDate,
              startDate: filterState.startDate,
              status: filterState.status,
              type: filterState.type,
              currency: filterState.currency,
            });
          }}
          modifyFilters={modifyFilters}
          clearFilter={() => {
            const { requestState } = clearFilter();
            fetchCampaigns(requestState);
          }}
          submitFilter={() => {
            const { requestState } = modifyFilters({ page: 1 });
            fetchCampaigns(requestState);
          }}
          filterCount={filterCount}
          {...exportReport}
        />
      </div>
      <div className={styles.table}>
        <DataTable
          loading={fetchingCampaigns}
          page={filterState.page}
          pageSize={filterState.perPage}
          columns={campaignsColumns}
          data={preparedCustomerListData}
          dataCount={getVoucherResult.count}
          onChangePage={onChangePageCb}
          onChangePageSize={onChangePageSizeCb}
        />
      </div>
      <ConfirmReactivateVoucherModal
        loading={updatingCampaign}
        onSubmitReactivate={onSubmitReactivate}
        name={name}
        {...reactivateModal}
      />
      <ConfirmCancelVoucherModal
        loading={updatingCampaign}
        onSubmitCancel={onSubmitCancel}
        name={name}
        {...confirmModal}
      />
      <SuccessModal {...exportReport?.successModalComponent} />
    </div>
  );
};

export default VoucherModule;
