import locale from "localization";

export const columns = [
  { key: "mobileNumber", text: locale.mobileNumber },
  { key: "currency", text: locale.currency },
  { key: "value", text: locale.value },
  { key: "dateRegistered", text: locale.dateRegistered },
  { key: "dateRedeemed", text: locale.dateRedeemed },
  { key: "status", text: locale.status },
];
