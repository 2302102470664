import locale from "localization";

export const settlementColumns = [
  {
    key: "redemptionStation",
    text: `${locale.redemptionStation}/${locale.stationBusinessName}`,
    width: "25%",
  },
  { key: "totalLiterRedemption", text: locale.totalLiterRedemption, width: "10%" },
  { key: "totalPayWithPeso", text: locale.totalPayWithLOCQPay, width: "10%" },
  { key: "totalMDRFee", text: locale.totalMDRFee, width: "10%" },
  { key: "totalRumSDUMFDifferential", text: locale.totalRumSDUMFDifferential, width: "10%" },
  { key: "totalRumBuy", text: locale.totalRumBuy, width: "10%" },
  { key: "totalDealerPayout", text: locale.totalDealerSettlementPayout, width: "13%" },
  { key: "status", text: `${locale.status}`, width: "13%" },
  { key: "action", text: locale.action, width: "10%" },
];
