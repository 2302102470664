import { DateTime } from "enums";
import VoucherCampaignGroup from "enums/campaign-group";
import moment from "moment";
import { prettifyVoucherCampaignGroup } from "utils/pretty.utils";

export const voucherCampaignFormPost = (data) => {
  return {
    campaignName: data?.campaignName,
    startDate: data.campaignDuration?.startDate,
    endDate: data.campaignDuration?.endDate,
    shortDescription: data?.shortDescription,
    longDescription: data?.longDescription,
    voucherPrefix: data?.voucherPrefix,
    voucherCurrency: data?.voucherCurrency,
    voucherValue: parseFloat(data?.voucherValue),
    campaignImage: data?.campaignImage,
    voucherType: data?.voucherType,
    voucherQuantity: parseInt(data?.voucherQuantity),
    voucherCampaignGroup: data?.voucherCampaignGroup || VoucherCampaignGroup.Others,
  };
};

export const voucherCampaignFormGet = (data) => {
  const startDate = new Date(moment(data?.startDate).format(DateTime.C));
  const endDate = new Date(moment(data?.endDate).format(DateTime.D));

  return {
    campaignName: { value: data?.campaignName },
    campaignDuration: {
      value: { startDate: startDate, endDate: endDate },
    },
    shortDescription: { value: data?.shortDescription },
    longDescription: { value: data?.longDescription },
    voucherPrefix: { value: data?.voucherPrefix },
    voucherCurrency: { value: data?.voucherCurrency },
    voucherValue: { value: data?.voucherValue },
    campaignImage: { value: data?.campaignImage },
    voucherType: { value: data?.voucherType },
    voucherQuantity: { value: data?.voucherQuantity },
    voucherCampaignGroup: {
      value: data?.voucherCampaignGroup,
      data: {
        label: prettifyVoucherCampaignGroup(data?.voucherCampaignGroup),
      },
      disabled: true,
    },
  };
};
