import { Intro } from "components/commons";
import React, { useCallback, useState } from "react";
import Header from "components/commons/consumer-header/header";
import FeatureFlagsFilter from "./feature-flags-filter";
import { useFilter, useModal, useMount } from "hooks";
import { filterState } from "./feature-flag-filter.state";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import FeatureFlagsList from "./feature-flags.list";
import useForm from "hooks/useForm.v2";
import FeatureFlagForm from "./feature-flag-form";
import { isEmailValid } from "utils";
import AppLoader from "components/commons/app-loader/app-loader";
import env from "environments/env";
import { isMobileNumberValid } from "utils/text.utils";

const FeatureFlagAdmin = () => {
  const [data, setData] = useState([]);
  const [mobileData, setMobileData] = useState([]);
  const filter = useFilter(filterState());
  const [loading, setLoading] = useState(true);
  const { requestState, submitFilter } = filter;
  const mobile = firebase.initializeApp(env.APP_FIREBASE_CONFIG, "mobile");
  const form = useForm({
    form: {
      remarks: {
        value: "",
        label: "Remarks",
      },
      app: {
        value: "",
        label: "App",
        validations: {
          isRequired: true,
        },
      },
      key: {
        value: "",
        label: "Feature Flag",
        validations: {
          isRequired: true,
        },
      },
      enabled: {
        value: "",
        label: "Status",
      },
      huawei: {
        value: "",
        label: "Huawei",
      },
      android: {
        value: "",
        label: "Android",
      },
      ios: {
        value: "",
        label: "IOS",
      },
      location: {
        value: "",
        label: "Location",
      },
      users: {
        value: "",
        label: "Users",
        validations: {
          custom: [
            (value, field, fields = {}) => {
              const { app = {} } = fields;
              let error = false;
              if (app?.value === "mobile") {
                const number = value.split(",");
                if (value.trim()) {
                  number.forEach((email) => {
                    if (!error) {
                      error = !isMobileNumberValid(email);
                    }
                  });
                }
              } else {
                const emails = value.split(",");
                if (value.trim()) {
                  emails.forEach((email) => {
                    if (!error) {
                      error = !isEmailValid(email);
                    }
                  });
                }
              }
              return {
                error,
                message: error
                  ? `Please enter valid ${
                      app.value === "mobile" ? "mobile numbers" : "emails"
                    }. (Comma separated)`
                  : "",
              };
            },
          ],
        },
      },
    },
  });
  const formModal = useModal();
  const handleRequestParam = useCallback(
    (filterValue) => {
      const obj = { ...filterValue };
      submitFilter(obj);
      return {
        ...obj,
      };
    },
    [submitFilter]
  );

  const fetchList = useCallback(
    async (filterValue) => {
      setLoading(true);
      const params = handleRequestParam(filterValue);
      try {
        const web = await firebase.initializeApp(env.FIREBASE_CONFIG, "web");
        const db = firebase.database(web);
        const flagRef = db.ref("/");
        const mobileDB = mobile.database();
        const mobileFlagRef = mobileDB.ref("/");
        if (mobileFlagRef && (params.app.value === "all" || params.app.value === "mobile")) {
          mobileFlagRef.on("value", (snap) => {
            const d = snap.val();
            if (d) {
              const mobileFlags = d["feature-flag"];

              const feature = {
                key: "mobile",
                label: "PLC Mobile App",
                data: mobileFlags
                  ? Object.keys(mobileFlags)
                      .filter((f) => {
                        const fl = mobileFlags[f];
                        const isAll = params.status.value === "all";
                        const status = fl.enabled ? "enabled" : "disabled";
                        let filtered = isAll || params.status.value === status;

                        if (filtered && params.searchKey) {
                          filtered = f
                            .toString()
                            .toLowerCase()
                            .includes(params.searchKey.toLowerCase());
                        }
                        return filtered;
                      })
                      .map((key) => {
                        return {
                          key,
                          ...mobileFlags[key],
                          isMobile: true,
                        };
                      })
                  : [],
              };
              if (feature.data.length) {
                setMobileData([feature]);
              } else {
                setMobileData([]);
              }
            }
          });
        }

        if (flagRef) {
          flagRef.on("value", (snap) => {
            const features = [];
            const d = snap.val();

            Object.keys(d)
              .filter((key) => {
                const isAll = params.app.value === "all";
                return isAll || key === params.app.value;
              })
              .forEach((key) => {
                const flags = Object.keys(d[key])
                  .filter((f) => {
                    const fl = d[key][f];
                    const isAll = params.status.value === "all";
                    const status = fl.enabled ? "enabled" : "disabled";
                    let filtered = isAll || params.status.value === status;
                    if (filtered && params.searchKey) {
                      filtered = f
                        .toString()
                        .toLowerCase()
                        .includes(params.searchKey.toLowerCase());
                    }
                    return filtered;
                  })
                  .map((f) => {
                    return {
                      key: f,
                      ...d[key][f],
                    };
                  });
                if (flags.length) {
                  features.push({
                    key,
                    label: {
                      admin: "Admin Portal",
                      fleet: "Fleet Portal",
                      station: "Station Portal",
                      oilco: "Oilco Portal",
                    }[key],
                    data: flags,
                  });
                }
              });
            setData(features);
            // if (features.length) {
            setLoading(false);
            // }
          });
        }
      } catch (e) {
        setLoading(false);
      }
    },
    [handleRequestParam, mobile]
  );

  useMount(() => {
    fetchList(requestState);
  });

  if (loading) {
    return <AppLoader />;
  }

  return (
    <div
      style={{
        marginTop: "60px",
        padding: "27px 36px",
        minWidth: "1170px",
        overflowX: "auto",
        minHeight: "100vh",
        backgroundColor: "#fff",
      }}
    >
      <Header />
      <div>
        <Intro
          title="Feature Flags"
          subtitle="Control features for PriceLocq Web portals and app."
        />
        <FeatureFlagsFilter
          {...filter}
          fetch={fetchList}
          showCreate={() => {
            form.initializeForm({}, true);
            formModal.show({
              title: "Create Flag",
              action: "create",
              data: {},
            });
          }}
        />
        {[...mobileData, ...data].length ? (
          <FeatureFlagsList
            showEdit={({ status, key, data = {}, location, users }) => {
              const { android, huawei, ios } = data;
              form.initializeForm(
                {
                  key: {
                    value: key,
                    disabled: true,
                  },
                  remarks: {
                    value: "",
                    disabled: true,
                  },
                  location: {
                    value: location,
                  },
                  enabled: {
                    value: status,
                  },
                  android: {
                    value: android,
                  },
                  huawei: {
                    value: huawei,
                  },
                  ios: {
                    value: ios,
                  },
                  users: {
                    value: users,
                  },
                  app: {
                    disabled: true,
                    value: data.app,
                    label: {
                      admin: "Admin Portal",
                      fleet: "Fleet Portal",
                      station: "Station Portal",
                      oilco: "Oilco Portal",
                    }[key],
                  },
                },
                true
              );
              formModal.show({
                title: "Edit Flag",
                action: "edit",
                isMobile: data.isMobile,
                data,
              });
            }}
            data={[...mobileData, ...data]}
            loading={loading}
          />
        ) : (
          <div>No results found.</div>
        )}
        <FeatureFlagForm form={form} {...formModal} mobile={mobile} />
      </div>
    </div>
  );
};

export default FeatureFlagAdmin;
