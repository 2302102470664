import { Text, PopOverList } from "components/commons";
import { prettifyProduct } from "utils/pretty.utils";
import styles from "./pricing-changes.module.scss";
import classNames from "classnames";
import { formatPesoWithDecimalRange, dateTimeFormat, prettifyAuditTrailAction } from "utils";
import locale from "localization";
import { AuditTrailAction, Products } from "enums";

const RenderValueField = ({ current = [], old = [], keyItem, decimal = 2 }) => {
  return current.length !== 0 ? (
    current.map((item, key) => (
      <div key={`${item[keyItem]}-${key}`}>
        <Text>
          {item[keyItem] !== undefined ? formatPesoWithDecimalRange(item[keyItem], decimal) : "--"}
        </Text>
        <Text className={styles?.subBusinessId}>
          {old[key]?.[keyItem] !== undefined
            ? formatPesoWithDecimalRange(old[key]?.[keyItem], decimal)
            : "--"}
        </Text>
      </div>
    ))
  ) : (
    <Text>{formatPesoWithDecimalRange(0)}</Text>
  );
};

export const mapDataToList = (fleetAuditTrails) => {
  const {
    fleetAuditTrailId,
    fleet,
    referenceStation,
    oldReferenceStation,
    virtualStationPumpPrice,
    oldVirtualStationPumpPrice,
    fleetDiscounts,
    oldFleetDiscounts,
    updatedAt,
    fleetStations,
    oldFleetStations,
    action,
  } = fleetAuditTrails;
  return {
    fleetAuditTrailId,
    businessName: (
      <>
        <Text>{fleet?.businessName}</Text>
        <Text className={styles?.subBusinessId}>{fleet?.shortName}</Text>
      </>
    ),
    referenceStation: (
      <>
        <Text>{referenceStation?.name}</Text>
        <Text className={styles?.subBusinessId}>{oldReferenceStation?.name}</Text>
      </>
    ),
    redemptionStation:
      action === AuditTrailAction.UpdateFleetRedemptionStaitons ? (
        <>
          {fleetStations?.length !== 0 ? (
            <PopOverList list={fleetStations?.map((fs) => fs?.name)}>
              <span style={{ cursor: "pointer", textDecoration: "underline" }}>{locale.view}</span>
            </PopOverList>
          ) : (
            <Text>{locale.allStations}</Text>
          )}
          {oldFleetStations ? (
            oldFleetStations?.length !== 0 ? (
              <PopOverList list={oldFleetStations?.map((ofs) => ofs?.name)}>
                <span
                  className={styles?.subBusinessId}
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                >
                  {locale.view}
                </span>
              </PopOverList>
            ) : (
              <Text className={styles?.subBusinessId}>{locale.allStations}</Text>
            )
          ) : (
            <Text className={styles?.subBusinessId}>--</Text>
          )}
        </>
      ) : (
        "--"
      ),
    depot: (
      <>
        <Text>{referenceStation?.depot?.name}</Text>
        <Text className={styles?.subBusinessId}>{oldReferenceStation?.depot?.name}</Text>
      </>
    ),
    product:
      action !== AuditTrailAction.UpdateFleetRedemptionStaitons ? (
        <>
          <div>
            <div className="flex items-center">
              <div className={classNames(styles.circle, styles[Products.Diesel])}></div>
              {prettifyProduct(Products.Diesel)}
            </div>
          </div>
          <div>
            <div className="flex items-center">
              <div className={classNames(styles.circle, styles[Products.Gas91])}></div>
              {prettifyProduct(Products.Gas91)}
            </div>
          </div>
          <div>
            <div className="flex items-center">
              <div className={classNames(styles.circle, styles[Products.Gas95])}></div>
              {prettifyProduct(Products.Gas95)}
            </div>
          </div>
          <div>
            <div className="flex items-center">
              <div className={classNames(styles.circle, styles[Products.Gas97])}></div>
              {prettifyProduct(Products.Gas97)}
            </div>
          </div>
        </>
      ) : (
        "--"
      ),
    accountSpecificMovement:
      action === AuditTrailAction.ApplyAccountSpecificMovement
        ? RenderValueField({
            current: virtualStationPumpPrice,
            old: oldVirtualStationPumpPrice,
            keyItem: "movement",
          })
        : "--",
    discount:
      action === AuditTrailAction.UpdateFleetDiscount
        ? RenderValueField({
            current: fleetDiscounts,
            old: oldFleetDiscounts,
            keyItem: "discount",
          })
        : "--",
    sdum:
      action === AuditTrailAction.UpdateFleetRefs
        ? RenderValueField({
            current: virtualStationPumpPrice,
            old: oldVirtualStationPumpPrice,
            keyItem: "sdum",
          })
        : "--",
    freight:
      action === AuditTrailAction.UpdateFleetRefs
        ? RenderValueField({
            current: virtualStationPumpPrice,
            old: oldVirtualStationPumpPrice,
            keyItem: "freight",
            decimal: 4,
          })
        : "--",
    action: <Text>{prettifyAuditTrailAction(action)}</Text>,
    updatedAt: <Text>{dateTimeFormat(updatedAt)}</Text>,
  };
};

export const mapFilterToRequest = (filterState) => {
  const { ...fs } = filterState;

  return {
    ...fs,
  };
};
