import React from "react";
import { Path } from "enums";
import { Route, Switch, Redirect } from "react-router-dom";
import DiscountAddModule from "modules/campaigns-programs/locqpay-discount-campaign/discount-add/discount-add.module";
import DiscountViewModule from "modules/campaigns-programs/locqpay-discount-campaign/discount-view/discount-view.module";
import DiscountEditModule from "modules/campaigns-programs/locqpay-discount-campaign/discount-edit/discount-edit.module";
import DiscountListModule from "modules/campaigns-programs/locqpay-discount-campaign/discount-list/discount-list.module";
import { CampaignListModule } from "./campaign.page";
import { FeatureFlagContext } from "contexts";
import { useContext } from "react";

const LOCQPayDiscountCampaignPage = () => {
  const { hasFeature } = useContext(FeatureFlagContext);
  return (
    <Switch>
      <Route
        exact
        path={Path.LOCQPayDiscountCampaigns}
        component={
          hasFeature("VOUCHER_V2")
            ? () => {
                return (
                  <CampaignListModule>
                    <DiscountListModule />
                  </CampaignListModule>
                );
              }
            : DiscountListModule
        }
      />
      <Route exact path={Path.ViewLOCQPayDiscountCampaigns} component={DiscountViewModule} />
      <Route exact path={Path.AddLOCQPayDiscountCampaigns} component={DiscountAddModule} />
      <Route exact path={Path.EditLOCQPayDiscountCampaigns} component={DiscountEditModule} />
      <Redirect to={Path.DiscountCampaign} />
    </Switch>
  );
};

export default LOCQPayDiscountCampaignPage;
