import { createUserAttribute, getUserAttribute, updateUserAttribute } from "apis";
import { Loader, Checkbox, Text } from "components/commons";
import { FormMode, Path, ErrorCode } from "enums";
import { useApi, useForm, useModal, useMount } from "hooks";
import locale from "localization";
import _ from "lodash";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
  useCallback,
} from "react";
import { ConfirmModal } from "components/modals";
import { useHistory } from "react-router-dom";
import { getObjectDifference, getObjectValues, handleRequest } from "utils";
import UserAttributeDetailsFormModule from "./user-attribute-details-form.module";
import styles from "./user-attribute-details.module.scss";
import { initialState as formState } from "./user-attribute-details.state";
import { userAttributeGetObjects, userAttributePutObjects } from "./user-attributes-form.utils";

const UserAttributeDetailsModule = forwardRef(({ pageMode, attributeId, ...props }, ref) => {
  const { setUpdateLoading, setDisableUpdate } = props;
  const viewMode = pageMode === FormMode.View;
  const addMode = pageMode === FormMode.Add;
  const editMode = pageMode === FormMode.Edit;
  const [previousFormValues, setPreviousFormValues] = useState({});
  const [cohortsSearchKey, setCohortsSearchKey] = useState("");
  const [cohorts, setCohorts] = useState([]);
  const [selected, setSelected] = useState([]);
  const [duplicateCohort, setDuplicateCohort] = useState(false);

  const {
    request: getAttributeRequest,
    loading: loadingUserAttribute,
    result: getAttributeResult,
  } = useApi({
    api: getUserAttribute,
    pageError: true,
    params: {
      attributeId,
    },
  });

  const form = useMemo(() => {
    let initialState = {};
    if (attributeId) {
      const data = getAttributeResult;
      if (data) {
        initialState = userAttributeGetObjects(data);
        if (editMode) {
          setPreviousFormValues(_.cloneDeep(getObjectValues(initialState)));
        }
        setCohorts(initialState.cohorts.value);
      }
    }
    return formState(initialState);
  }, [editMode, attributeId, getAttributeResult]);

  const confirmModal = useModal();
  const { close } = confirmModal;
  const history = useHistory();

  useMount(() => {
    if (attributeId) {
      getAttributeRequest();
    }
  });

  const {
    fields,
    modifyField,
    isFormSubmittable,
    submitForm,
    clearForm,
    getFormValues,
    applyFieldErrors,
  } = useForm({
    initialState: form,
  });

  const addRequest = useApi({
    api: createUserAttribute,
    handleOwnError: {
      badrequest: true,
    },
  });

  const editRequest = useApi({
    api: updateUserAttribute,
    handleOwnError: {
      badrequest: true,
    },
    params: {
      attributeId,
    },
  });

  const loading = addRequest.loading || editRequest.loading;

  const submit = (params) => {
    const apiRequest = addMode ? addRequest : editRequest;

    handleRequest(
      async () => {
        close();
        const result = await apiRequest.request(
          {
            ...params,
          },
          () => handleSubmit()
        );
        clearForm();
        if (result) {
          history.push(Path.Attributes);
        }
      },
      null,
      (err) => {
        if (err?.data?.errorCode === ErrorCode.AttributeNameAlreadyExists) {
          return applyFieldErrors({
            name: locale.attributeAlreadyExists,
          });
        }
        return err.showError();
      }
    );
  };

  const getChangedValues = (compare) => {
    return getObjectDifference(previousFormValues, compare);
  };

  const handleSubmit = () => {
    let currentFormValues = getFormValues();
    if (editMode) {
      currentFormValues = userAttributePutObjects(getAttributeResult, currentFormValues);
    }
    confirmModal.show({
      title: addMode ? locale.createAttributeQuestion : locale.saveChangesQuestion,
      content: addMode
        ? locale.areYouSureCreatingAttribute
        : locale.areYouSureSavingChangesAttribute,
      secondary: {
        text: addMode ? locale.Cancel : locale.continueEditing,
      },
      primary: {
        text: addMode ? locale.yesCreateAttribute : locale.saveChanges,
        onClick: () => {
          submit(currentFormValues);
        },
      },
    });
  };

  useImperativeHandle(ref, () => ({
    handleUpdate() {
      submitForm(handleSubmit);
    },
  }));

  const handleCheckCohort = useCallback(
    (name, { value }) => {
      let formParams = null;
      if (value) {
        formParams = [...selected, name];
      } else {
        let selectedCopy = [...selected];
        const indexRemove = selectedCopy.indexOf(name);
        selectedCopy.splice(indexRemove, 1);
        formParams = selectedCopy;
      }
      setSelected(formParams);
      modifyField(fields.cohorts.name, { value: formParams });
    },
    [fields.cohorts.name, modifyField, selected]
  );

  const cohortsList = useMemo(() => {
    if (cohorts.length > 0) {
      return (
        <div className={styles.checkboxes}>
          {cohorts
            .filter((cohort) => cohort.includes(cohortsSearchKey))
            .map((cohort) => {
              return (
                <div className={styles.inputGroup}>
                  {!viewMode && (
                    <Checkbox
                      name={cohort}
                      checked={selected.includes(cohort)}
                      value={selected.includes(cohort)}
                      onChange={handleCheckCohort}
                      disabled={loading}
                    />
                  )}
                  <Text className={styles.textCheckbox}>{cohort}</Text>
                </div>
              );
            })}
        </div>
      );
    }
    return <div className={styles.noCohortsMsg}>{locale.noCohortsYet}</div>;
  }, [cohorts, cohortsSearchKey, handleCheckCohort, loading, selected, viewMode]);

  const onSearchChangeCb = useCallback((name, { value }) => {
    setDuplicateCohort(false);
    setCohortsSearchKey(value);
  }, []);

  const clearSearchKey = useCallback(() => {
    onSearchChangeCb("", { value: "" });
  }, [onSearchChangeCb]);

  const handleAddCohorts = useCallback(() => {
    if (cohorts.includes(cohortsSearchKey)) {
      return setDuplicateCohort(true);
    }
    if (cohortsSearchKey) {
      setCohorts([...cohorts, cohortsSearchKey]);
      clearSearchKey();
    }
  }, [clearSearchKey, cohorts, cohortsSearchKey]);

  const removeSelected = useCallback(() => {
    let cohortsCopy = [...cohorts];
    for (let i = 0; i <= selected.length; i++) {
      if (cohorts.includes(selected[i])) {
        const indexRemove = cohortsCopy.indexOf(selected[i]);
        cohortsCopy.splice(indexRemove, 1);
      }
    }
    setCohorts(cohortsCopy);
    setSelected([]);
    modifyField(fields.cohorts.name, { value: [] });
  }, [cohorts, fields.cohorts.name, modifyField, selected]);

  const checkAllCohort = useCallback(
    (name, { value }) => {
      let formParams = null;
      if (value) {
        formParams = cohorts;
      } else {
        formParams = [];
      }
      setSelected(formParams);
      modifyField(fields.cohorts.name, { value: formParams });
    },
    [cohorts, fields.cohorts.name, modifyField]
  );

  const isAllCohortSelected = useMemo(() => {
    if (cohorts.length === 0) return false;
    for (let i = 0; i < cohorts.length; i++) {
      if (selected.indexOf(cohorts[i]) === -1) {
        return false;
      }
    }
    return true;
  }, [cohorts, selected]);

  useEffect(() => {
    if (editMode) {
      setUpdateLoading(loading);
      const currentFormValues = getFormValues();
      const updateParams = getChangedValues(currentFormValues);
      const fieldsChanged = Object.keys(updateParams).length > 0;
      setDisableUpdate(!isFormSubmittable || !fieldsChanged);
    }
    //eslint-disable-next-line
  }, [fields, loading]);

  const stateForm = {
    viewMode,
    addMode,
    fields,
    isFormSubmittable,
    loading,
    submitForm,
    handleSubmit,
    modifyField,
    duplicateCohort,
    cohortsList,
    handleAddCohorts,
    removeSelected,
    cohortsSearchKey,
    onSearchChangeCb,
    handleCheckCohort,
    checkAllCohort,
    isAllCohortSelected,
  };

  return (
    <div>
      <Loader open={loadingUserAttribute} />
      {!loadingUserAttribute && (
        <div className={styles.container}>
          {!viewMode && (
            <ConfirmModal
              {...confirmModal}
              titleStyle={styles.modalTitle}
              contentStyle={styles.modalContent}
            />
          )}
          <UserAttributeDetailsFormModule {...stateForm} />
        </div>
      )}
    </div>
  );
});

export default UserAttributeDetailsModule;
