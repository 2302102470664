import React, { useContext } from "react";
import { Path } from "enums";
import { Route, Switch, Redirect } from "react-router-dom";
import BuyFuelModule from "modules/fuel/buy-fuel/buy-fuel.module";
import FuelCodeModule from "modules/fuel/fuel-codes/fuel-codes.module";
import RedemptionModule from "modules/fuel/redemptions/redemption.module";
import ProductConversion from "modules/fuel/product-conversion/product-conversion.module";
import { FeatureFlagContext } from "contexts";
import NonFuelModule from "modules/fuel/non-fuel/non-fuel.module";

const FuelPage = () => {
  const { hasFeature } = useContext(FeatureFlagContext);
  return (
    <Switch>
      <Route exact path={Path.BuyFuel} component={BuyFuelModule} />
      <Route exact path={Path.FuelCode} component={FuelCodeModule} />
      <Route exact path={Path.Redemption} component={RedemptionModule} />
      <Route exact path={Path.NonFuel} component={NonFuelModule} />
      {hasFeature("PAGE_PRODUCT_CONVERSION") ? (
        <Route exact path={Path.ProductConversion} component={ProductConversion} />
      ) : null}
      <Redirect to={Path.BuyFuel} />
    </Switch>
  );
};

export default FuelPage;
