import React, { useContext } from "react";

import { Intro } from "components/commons";
import useDataTable from "hooks/useDataTable";
import locale from "localization";

import { mapDataToList, mapFilterToRequest } from "./lubeserv-bookings.mapper";
import { lubeServOnWheelsBookingsFilterState } from "./lubeserv-on-wheels-bookings-filter.state";
import { lubeServOnSiteBookingsFilterState } from "./lubeserv-on-site-bookings-filter.state";
import { getLubeServBookings } from "apis/booking.api";
import { lubeServOnWheelsBookingsColumns } from "./lubeserv-on-wheels-bookings-columns";
import LubeServBookingsFilter from "./lubeserv-bookings-filter";
import DataTableV2 from "components/commons/data-table/data-table-v2";

import { SuccessModal } from "components/modals";
import useExport from "hooks/useExport";
import { SessionContext } from "contexts";
import { BookingType } from "enums";
import { lubeServOnSiteBookingsColumns } from "./lubeserv-on-site-bookings-columns";
import { generateBookingReportAdmin } from "apis/generate-report.api";

const LubeServBookingsModule = (props) => {
  const { sessionId } = useContext(SessionContext);
  const { bookingType } = props;

  const { filter, search, table } = useDataTable({
    api: { api: getLubeServBookings, params: { bookingTypes: bookingType } },
    filter: {
      initialState:
        bookingType === BookingType.LubeservOnWheels
          ? lubeServOnWheelsBookingsFilterState()
          : lubeServOnSiteBookingsFilterState(),
      mapper: mapFilterToRequest,
    },
    table: {
      key: "bookings",
      mapper: mapDataToList,
      columns:
        bookingType === BookingType.LubeservOnSite
          ? lubeServOnSiteBookingsColumns
          : lubeServOnWheelsBookingsColumns,
    },
  });

  const exports = useExport({
    api: generateBookingReportAdmin,
    hasModal: true,
    reportType:
      bookingType === BookingType.LubeservOnSite ? "lubeserv-on-site" : "lubeserv-on-wheels",
    mappedFilterState: {
      ...filter?.mappedFilterState,
      searchKey: search.searchKey,
      bookingTypes: bookingType,
      sessionId,
    },
  });

  return (
    <>
      <SuccessModal {...exports?.successModalComponent} />
      <div>
        <Intro
          // actionText={locale.export}
          // actionOnClick={() => {}}
          title={
            bookingType === BookingType.LubeservOnWheels
              ? locale.lubeServOnWheels
              : locale.lubeServOnSite
          }
          subtitle={locale.viewAndTrackLubeServOnWheelsBookingsTransaction}
        />
      </div>
      <LubeServBookingsFilter
        {...filter}
        {...search}
        {...table}
        {...exports}
        bookingType={bookingType}
      />
      <DataTableV2 {...table} paginationV2={true} />
    </>
  );
};

export default LubeServBookingsModule;
