import { AccountStatus, FuelCodesStatus } from "enums";

export const accountListFilterState = () => ({
  startDate: null,
  endDate: null,
  searchKey: "",
  testFuelCodeStatus: {
    isSelectedAll: true,
    value: [
      FuelCodesStatus.Scheduled,
      FuelCodesStatus.Active,
      FuelCodesStatus.Redeemed,
      FuelCodesStatus.Deactivated,
      FuelCodesStatus.Expired,
    ],
  },
  stationAccountStatus: {
    isSelectedAll: true,
    value: [
      AccountStatus.Active,
      AccountStatus.Deactivated,
      AccountStatus.Pending,
      AccountStatus.Expired,
    ],
  },
  page: 1,
  perPage: 10,
});
