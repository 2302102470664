import { Pill, Text } from "components/commons";
import { ChargingStatus, PaymentStatus } from "enums";
import { locale } from "localization/en";
import { formatAmount } from "utils";
import { prettifyChargingStatus, prettifyPaymentStatus } from "utils/pretty.utils";
import styles from "./dealer-monthly-fee-details.module.scss";

export const mapDataToList = ({ chargingDetail }) => {
  const {
    station,
    totalMonthlyFee,
    totalNetMonthlyFee,
    totalWaivedMonthlyFee,
    dealerSettlementId,
    billToOilco,
    paymentStatus,
    chargingStatus,
    isPlcOnboarded,
    isPlbOnboarded,
    dealerShoulderedMonthlyFee,
  } = chargingDetail;
  let plcPlbStation;

  if (isPlcOnboarded && isPlbOnboarded) {
    plcPlbStation = `${locale.plcAndPlb}`;
  } else {
    if (isPlcOnboarded) {
      plcPlbStation = `${locale.plc}`;
    }

    if (isPlbOnboarded) {
      plcPlbStation = `${locale.plb}`;
    }
  }

  return {
    redemptionStationBusinessName: (
      <>
        <Text>{station?.name}</Text>
        <Text className={styles.subBusinessId}>{station?.businessName}</Text>
      </>
    ),
    stationTypeStationClass: (
      <>
        <Text>{station?.stationType}</Text>
        <Text className={styles.subBusinessId}>{station?.stationClass ?? "N/A"}</Text>
      </>
    ),
    plcPlbStation: (
      <>
        <Text>{plcPlbStation}</Text>
      </>
    ),
    totalMonthlyFee: <Text>{formatAmount(totalMonthlyFee)}</Text>,
    chargingStatus: (
      <Pill
        style={{
          marginBottom: "10px",
        }}
        sky={chargingStatus === ChargingStatus.Waived}
        cheddar={chargingStatus === ChargingStatus.ForCharging}
      >
        {prettifyChargingStatus(chargingStatus)}
      </Pill>
    ),
    totalWaivedMonthlyFee: <Text>{formatAmount(totalWaivedMonthlyFee)}</Text>,
    totalNetMonthlyFee: <Text>{formatAmount(totalNetMonthlyFee)}</Text>,
    paymentStatus: (
      <Pill
        style={{
          marginBottom: "10px",
        }}
        sky={paymentStatus === PaymentStatus.Waived}
        cheddar={paymentStatus === PaymentStatus.Pending}
        grass={paymentStatus === PaymentStatus.Paid}
      >
        {prettifyPaymentStatus(paymentStatus)}
      </Pill>
    ),
    dealerSettlementId: (
      <Text>
        {paymentStatus === PaymentStatus.Paid && dealerSettlementId ? dealerSettlementId : "--"}
      </Text>
    ),
    billToOilco: <Text>{formatAmount(billToOilco)}</Text>,
    dealerShoulderedMonthlyFee: <Text>{formatAmount(dealerShoulderedMonthlyFee)}</Text>,
  };
};

export const mapFilterToRequest = (filterState) => {
  const { stationClass, paymentStatus, chargingStatus, stationType, ...fs } = filterState;

  if (stationClass.value.length) {
    fs.stationClass = stationClass.value.join(",");
  }

  if (paymentStatus.value.length) {
    fs.paymentStatus = paymentStatus.value.join(",");
  }

  if (chargingStatus.value.length) {
    fs.chargingStatus = chargingStatus.value.join(",");
  }

  if (stationType.value.length) {
    fs.stationType = stationType.value.join(",");
  }

  return {
    ...fs,
  };
};
