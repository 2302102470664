import locale from "localization";
import Validation from "utils/validation.utils";

export const initialState = (state = {}) => {
  const {
    uniqueReportId = {
      value: "",
    },
    apvNumber = {
      value: "",
    },
    remarks = {
      value: "",
    },
  } = state;

  return {
    uniqueReportId: {
      name: "uniqueReportId",
      label: locale.uniqueReportId,
      horizontal: true,
      maxLength: 20,
      validations: [Validation.numeric()],
      ...uniqueReportId,
    },
    apvNumber: {
      name: "apvNumber",
      label: locale.apvNumber,
      horizontal: true,
      maxLength: 20,
      validations: [Validation.alphaNumeric()],
      ...apvNumber,
    },
    remarks: {
      name: "remarks",
      label: locale.remarks,
      horizontal: true,
      maxLength: 30,
      ...remarks,
    },
  };
};
