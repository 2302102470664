import React, { useState } from "react";

import { ActionButton, BackLink, Intro, Text } from "components/commons";
import { Path, SettlementStatus } from "enums";
import locale from "localization";
import { useApi, useModal, useMount, useRouter } from "hooks";

import styles from "./view-settlement-history.module.scss";

import ViewSettlementHistoryForm from "./view-settlement-history-form.module";
import ViewSettlementHistoryTable from "./view-settlement-history-table.module";
import { getSettlementHistoryById, updateSettlementHistory } from "apis";

import useCancelSettlement from "../hooks/useCancelSettlement";
import { ConfirmModal, ErrorModal } from "components/modals";
import { handleRequest, prettifySettlementStatus } from "utils";

import {
  renderMultipleSettlements,
  renderSingleSettlement,
} from "../components/settlement-collection";
import { EditRounded } from "@material-ui/icons";

const ViewSettlementHistory = () => {
  const {
    history,
    query: { id: settlementId },
  } = useRouter();
  const confirmModal = useModal();
  const successModal = useModal();
  const errorModal = useModal();
  const serverErrorModal = useModal();

  const [dealerSettlementRemarks, setDealerSettlementRemarks] = useState();
  const [dealerSettlementStatus, setDealerSettlementStatus] = useState();

  const [edit, setEdit] = useState(false);
  const cancelSettlement = useCancelSettlement();

  const { result, request, loading } = useApi({
    api: getSettlementHistoryById,
    pageError: true,
  });

  const updateSettlement = useApi({
    api: updateSettlementHistory,
    handleOwnError: {
      unauthorized: true,
      badrequest: true,
    },
  });

  // const onCancelSettlement = () => {
  //   cancelSettlement.request({
  //     settlementId,
  //     confirmationRequest: async () => {
  //       // const res = await request({ id: settlementId });
  //       // setDealerSettlementRemarks(res.dealerSettlementRemarks);
  //       // setDealerSettlementStatus(res.dealerSettlementStatus);
  //       history.push(Path.LocqSettlementHistory);
  //     },
  //     settlementCollectionRedirection: async (settlementId) => {
  //       request({ id: settlementId });
  //       history.push(settlementId);
  //     },
  //     dealerSettlementStatus: result?.dealerSettlementStatus,
  //     discountVoucherStatus: result?.discountVoucherStatus,
  //   });
  // };

  const onUpdateSuccess = () => {
    successModal.show({
      title: locale.exclamatedSuccess,
      content: locale.detailHaveBeenSuccessfullyUpdated,
      primary: {
        text: locale.gotIt,
        onClick: async () => {
          history.push(Path.LocqSettlementHistory);
          // const res = await request({ id: settlementId });
          // setDealerSettlementRemarks(res.dealerSettlementRemarks);
          // setDealerSettlementStatus(res.dealerSettlementStatus);
          // setEdit(false);
          // successModal.close();
        },
      },
    });
  };

  const onConfirmRequest = () => {
    confirmModal.show({
      title: locale.saveChangesQuestion,
      content: (
        <locale.Populate
          text={
            dealerSettlementStatus === SettlementStatus?.Processed
              ? locale.editSettlementStatus
              : locale.editSettlement
          }
          items={[<strong>{prettifySettlementStatus(dealerSettlementStatus)}</strong>]}
        />
      ),
      secondary: {
        text: locale.continueEditing,
        onClick: () => confirmModal.close(),
      },
      primary: {
        text: locale.updateStatus,
        onClick: () =>
          handleRequest(
            async () => {
              await updateSettlement.request({
                settlementId,
                dealerSettlementRemarks,
                dealerSettlementStatus,
              });
              onUpdateSuccess();
              confirmModal.close();
            },
            {
              S0004: ({ settlements }) => {
                confirmModal.close();
                errorModal.show({
                  title: locale.processSettlementIds,
                  content: (
                    <>
                      <Text align={"center"}>{locale.processSettlementIdsContent}</Text>
                      {settlements?.length > 1
                        ? renderMultipleSettlements(settlements, (settlementId) => {
                            request({ id: settlementId });
                            history.push(Path.ViewSettlementHistoryById(settlementId));
                          })
                        : renderSingleSettlement(settlements[0], (settlementId) => {
                            request({ id: settlementId });
                            history.push(Path.ViewSettlementHistoryById(settlementId));
                          })}
                    </>
                  ),
                });
              },
            }
          ),
      },
    });
  };

  useMount(async () => {
    if (!settlementId) {
      history.push(Path.LocqSettlementHistory);
    } else {
      const res = await request({ id: settlementId });
      setDealerSettlementRemarks(res.dealerSettlementRemarks);
      setDealerSettlementStatus(res.dealerSettlementStatus);
    }
  });

  return (
    <>
      <ConfirmModal
        {...confirmModal}
        disabled={updateSettlement?.loading}
        closable={!updateSettlement?.loading}
        loading={updateSettlement?.loading}
      />
      <ConfirmModal {...successModal} closable={false} />
      <ConfirmModal {...cancelSettlement.cancelSettlementErrorModal} />
      <ConfirmModal {...errorModal} />
      <ErrorModal {...serverErrorModal} />
      <ErrorModal {...cancelSettlement.serverErrorModal} />
      <cancelSettlement.ConfirmCancel />
      <div className={styles.container}>
        <BackLink text={locale.settlementHistory} path={Path.LocqSettlementHistory} />
        <div
          style={{
            display: "flex",
          }}
        >
          <Intro
            style={{
              minWidth: "300px",
            }}
            title={locale.dealerSettlementDetails}
          />
          {edit ? (
            <ActionButton
              right
              style={{
                marginTop: "0px",
              }}
              items={[
                {
                  onClick: () => {
                    setDealerSettlementRemarks(result.dealerSettlementRemarks);
                    setDealerSettlementStatus(result.dealerSettlementStatus);
                    setEdit(!edit);
                  },
                  text: locale.cancel,
                  primary: false,
                },
                {
                  onClick: onConfirmRequest,
                  text: locale.save,
                  primary: true,
                  disabled: !(
                    (dealerSettlementRemarks || null) !== result.dealerSettlementRemarks ||
                    dealerSettlementStatus !== result.dealerSettlementStatus
                  ),
                },
              ]}
            />
          ) : (
            <ActionButton
              right
              style={{
                marginTop: "0px",
              }}
              items={[
                {
                  onClick: () => {
                    setEdit(!edit);
                  },
                  text: locale.edit,
                  startIcon: <EditRounded />,
                  exportButton: true,
                  primary: true,
                },
                // {
                //   onClick: onCancelSettlement,
                //   text: locale.cancelSettlement,
                //   outline: true,
                //   primary: true,
                // },
              ]}
            />
          )}
        </div>
        <ViewSettlementHistoryForm
          settlementDetails={{ ...result } || null}
          dealerSettlementRemarks={dealerSettlementRemarks}
          setDealerSettlementRemarks={setDealerSettlementRemarks}
          dealerSettlementStatus={dealerSettlementStatus}
          setDealerSettlementStatus={setDealerSettlementStatus}
          settlementLoading={loading}
          viewMode={edit}
          currentDealerSettlementStatus={result?.dealerSettlementStatus}
        />
        <ViewSettlementHistoryTable settlementDetails={{ ...result }} settlementId={settlementId} />
      </div>
    </>
  );
};

export default ViewSettlementHistory;
