import { Intro } from "components/commons";
import React, { useContext } from "react";
import { columns } from "./mops-pricing-columns";
import { initialFilterState } from "./mops-pricing-filter.state";
import locale from "localization";
import { generateReportAdmin, getMops } from "apis";
import useDataTable from "hooks/useDataTable";
import MopsPriceFilter from "./mops-pricing-filter";
import { mapDataToList, mapFilterToRequest } from "./mops-price.mapper";
import MopsPriceList from "./mops-price-list";
import { useHistory } from "react-router-dom";
import { Path } from "enums";
import useExport from "hooks/useExport";
import { SuccessModal } from "components/modals";
import { SessionContext } from "contexts";

const MopsPriceModule = () => {
  const history = useHistory();
  const { sessionId } = useContext(SessionContext);

  const { filter, search, table } = useDataTable({
    api: { api: getMops },
    filter: { initialState: initialFilterState(), mapper: mapFilterToRequest },
    table: { key: "mops", mapper: mapDataToList, columns },
  });

  const exports = useExport({
    api: generateReportAdmin,
    hasModal: true,
    reportType: "mops",
    mappedFilterState: { searchKey: search.searchKey, sessionId, ...filter.mappedFilterState },
  });

  return (
    <div>
      <SuccessModal {...exports?.successModalComponent} />
      <div>
        <Intro
          title={locale.mopsPrice}
          subtitle={locale.viewAndTrackMopsPrice}
          actionText={locale.editMopsPrice}
          actionOnClick={() => {
            history.push(Path.AddMopsPrice);
          }}
        />
      </div>
      <MopsPriceFilter {...filter} {...search} {...exports} />
      <MopsPriceList table={table} />
    </div>
  );
};

export default MopsPriceModule;
