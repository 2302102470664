import { DateTime } from "enums";
import React, {
  useCallback,
  // useImperativeHandle,
  useMemo,
  useRef,
  useState,
  // forwardRef,
} from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import { formatDate, parseDate } from "react-day-picker/moment";
import styles from "./date-range.module.scss";
import { Calendar } from "images";
import Image from "../image/image";
import { useMount } from "hooks";
import locale from "localization";
import moment from "moment";
import classNames from "classnames";

const DateRange = ({
  value,
  name,
  onChange,
  minDate = null,
  maxDate = null,
  className,
  disableStartDate = false,
  disableEndDate = false,
  disabled = false,
}) => {
  const [sd, setStartDate] = useState();
  const [ed, setEndDate] = useState();
  const [dirty, setDirty] = useState(false);

  const startDate = useMemo(() => {
    if (value) {
      return value?.startDate;
    }
    return sd;
  }, [sd, value]);

  const endDate = useMemo(() => {
    if (value) {
      return value?.endDate;
    }
    return ed;
  }, [ed, value]);

  // useImperativeHandle(ref, () => ({
  //   clearValue: () => {
  //     setStartDate(null);
  //     setEndDate(null);
  //     setDirty(false);
  //   },
  // }));

  const startDateRef = useRef();
  const endDateRef = useRef();

  const modifiers = useMemo(() => {
    return { start: startDate, end: endDate };
  }, [startDate, endDate]);

  const changeCb = useCallback(
    (value) => {
      setDirty(true);
      if (onChange) {
        return onChange(name, { value, dirty });
      }
    },
    [name, onChange, dirty]
  );

  const changeStartDateCb = useCallback(
    (start) => {
      setStartDate(start);
      if (dirty) {
        startDateRef.current.getInput().blur();
      } else {
        endDateRef.current.getInput().focus();
      }
      changeCb({
        startDate: new Date(moment(start).format(DateTime.C)),
        endDate: new Date(moment(endDate || start).format(DateTime.D)),
      });
    },
    [changeCb, endDate, dirty]
  );

  const changeEndDateCb = useCallback(
    (end) => {
      setEndDate(end);
      if (dirty) {
        endDateRef.current.getInput().blur();
      } else {
        startDateRef.current.getInput().focus();
      }
      changeCb({
        startDate: new Date(moment(startDate || end).format(DateTime.C)),
        endDate: new Date(moment(end).format(DateTime.D)),
      });
    },
    [changeCb, startDate, dirty]
  );

  useMount(() => {
    startDateRef.current.input.readOnly = true;
    endDateRef.current.input.readOnly = true;
  });

  return (
    <div
      className={classNames(styles.inputFromTo, className, {
        [`${styles.disabled}`]: disabled,
      })}
    >
      <div
        className={classNames(styles.startDate, {
          [`${styles.disableStartDate}`]: disableStartDate,
        })}
      >
        <DayPickerInput
          ref={startDateRef}
          value={startDate}
          placeholder="Start Date"
          format={DateTime.A}
          formatDate={formatDate}
          parseDate={parseDate}
          dayPickerProps={{
            selectedDays: [startDate, { from: startDate, to: endDate }],
            modifiers,
            toMonth: endDate,
            disabledDays: {
              after: disableEndDate ? null : endDate ?? maxDate,
              before: minDate,
            },
            onDayClick: () => {
              // endDateRef.current.getInput().focus();
            },
            numberOfMonths: 2,
          }}
          onDayChange={changeStartDateCb}
          inputProps={{ disabled: disableStartDate }}
        />
      </div>
      <div className={styles.to}>{locale.to}</div>
      <div
        className={classNames(styles.endDate, {
          [`${styles.disableEndDate}`]: disableEndDate,
        })}
      >
        <DayPickerInput
          ref={endDateRef}
          value={endDate}
          format={DateTime.A}
          formatDate={formatDate}
          parseDate={parseDate}
          placeholder="End Date"
          dayPickerProps={{
            selectedDays: [startDate, { from: startDate, to: endDate }],
            modifiers,
            fromMonth: startDate,
            month: startDate,
            disabledDays: { after: maxDate ?? null, before: startDate || minDate },
            numberOfMonths: 2,
          }}
          onDayChange={changeEndDateCb}
          inputProps={{ disabled: disableEndDate }}
        />
      </div>
      <Image className={styles.icon} src={Calendar} />
    </div>
  );
};

export default DateRange;
