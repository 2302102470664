import { Autocomplete, DateRange, Field, SearchField, Filter, Button } from "components/commons";
import React from "react";
import styles from "./cashin-filter.module.scss";
import locale from "localization";
import {
  cashinChannelOptions,
  cashinStatusOptions,
  cashinMethodOptions,
} from "data/select-options";

const CashInFilter = ({
  filterState,
  modifyFilter,
  filterCount,
  applyFilter,
  applyClearFilter,
  searchKey,
  modifySearch,
  applySearch,
  applyClearSearch,
  resetFilter,
  exporting,
  exportReport,
}) => {
  const { cashinStatus, cashinChannel, cashinMethod } = filterState;

  return (
    <div className={styles.container}>
      <div className={styles.filter}>
        <Filter
          close={() => {
            resetFilter();
          }}
          clear={applyClearFilter}
          submit={applyFilter}
          filterCount={filterCount}
        >
          <Field
            className={styles.date}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
            label={locale.cashInDate}
          >
            <DateRange
              value={{
                startDate: filterState.startDate,
                endDate: filterState.endDate,
              }}
              onChange={(_, { value }) => {
                const { startDate, endDate } = value;
                modifyFilter({
                  startDate,
                  endDate,
                });
              }}
            />
          </Field>
          {[
            {
              label: locale.status,
              value: cashinStatus,
              name: "cashinStatus",
              options: cashinStatusOptions,
            },
            {
              label: locale.cashinMethod,
              value: cashinMethod,
              name: "cashinMethod",
              options: cashinMethodOptions,
              override: {
                cashinChannel: null,
              },
            },
            {
              label: locale.cashInChannel,
              value: cashinChannel,
              name: "cashinChannel",
              options: cashinChannelOptions(cashinMethod),
              disabled: !cashinMethod,
            },
          ].map(({ label, value, name, options, disabled, override = {} }, key) => {
            return (
              <Field
                key={key}
                labelPosition={"left"}
                childrenClassName={styles.fieldContent}
                className={styles.fieldSelect}
                label={label}
              >
                <Autocomplete
                  capitalize={false}
                  name={name}
                  disabled={disabled}
                  hasAll
                  value={value}
                  options={options}
                  onChange={(name, { value }) => {
                    modifyFilter({ [name]: value, ...override });
                  }}
                />
              </Field>
            );
          })}
        </Filter>
        <Field className={styles.search}>
          <SearchField
            placeholder={[
              locale.requestId,
              locale.cashInIdNumber,
              locale.name,
              locale.mobileNumber,
            ].join(", ")}
            value={searchKey}
            searchWithSymbols={false}
            restrict={false}
            onChange={(_, { value }) => {
              modifySearch(value);
            }}
            onEnter={applySearch}
            onClear={applyClearSearch}
          />
        </Field>
      </div>
      <Button
        startIcon={<span className="icon-download" />}
        exportButton
        onClick={exportReport}
        loading={exporting}
      >
        {locale.export}
      </Button>
    </div>
  );
};

export default CashInFilter;
