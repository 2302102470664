import classNames from "classnames";
import React from "react";
import { useEffect, useState } from "react";

import ToggleButtonAddRemove from "../toggle-button-add-remove/toggle-button-add-remove";
import styles from "./totals-table.module.scss";

const TotalsTable = ({ columns = [], data = [], className, initialActiveColumns = {} }) => {
  const [activeColumns, setActiveColumns] = useState(initialActiveColumns);
  const [lastColumnWidth, setLastColumnWidth] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      const items = document.getElementsByClassName("total-table-columns");
      const columnContainer = document.getElementsByClassName("column-container");

      let totalWidth = 0;
      if (items?.length) {
        Array.prototype.forEach.call(items, function (item, index) {
          // Do stuff here
          if (index + 1 !== items.length) {
            totalWidth = totalWidth + item.clientWidth;
          }
        });
      }
      setLastColumnWidth(columnContainer?.[0]?.clientWidth - totalWidth);
    }, 100);
  }, [activeColumns, data]);

  return (
    <div className={classNames(styles.container, className)}>
      <div className={classNames(styles.column, "column-container")}>
        {columns.map((column, columnIndex) => {
          const isGroup = column.groupers?.length;
          const isGroupActive = activeColumns[column.key]?.active;
          const groupInitialObj = activeColumns[column.key];
          const groupObj = {
            [column.key]: {
              ...groupInitialObj,
              active: !isGroupActive,
            },
          };
          const isLastColumn = columnIndex + 1 === columns.length;
          return (
            <div
              key={columnIndex}
              className={classNames("total-table-columns", styles.col, {
                [`${styles.grouper}`]: isGroup,
                [`${styles.columnOnly}`]: !isGroup,
                [`${styles.collapse}`]: !isGroupActive,
              })}
              style={{
                width: isLastColumn
                  ? `${lastColumnWidth}px`
                  : !isGroup
                  ? `${100 / columns.length}%`
                  : null,
              }}
            >
              <div className={classNames(styles.columnHeader, "flex")}>
                {isGroup && (
                  <ToggleButtonAddRemove
                    onClick={() => {
                      setActiveColumns({
                        ...activeColumns,
                        ...groupObj,
                      });
                    }}
                    active={isGroupActive}
                  />
                )}
                <div
                  className={classNames(styles.headerTextColor, {
                    [`${styles.headerText}`]: isGroup,
                  })}
                >
                  {column.content}
                </div>
              </div>
              {isGroup ? (
                <div className="flex">
                  {isGroupActive && (
                    <div
                      className={classNames(
                        styles.heights,
                        styles.groupers,
                        styles.borderTop,
                        styles.borderRight,
                        "flex"
                      )}
                    >
                      {column.groupers.map((content, contentIndex) => {
                        const isActive = activeColumns[column.key]?.[content.key];
                        const expandable = content.data?.length;
                        const obj = {
                          [column.key]: {
                            ...activeColumns[column.key],
                            [content.key]: !isActive,
                          },
                        };
                        if (isActive && expandable) {
                          return (
                            <div
                              key={contentIndex}
                              className={classNames("flex", styles.borderRight)}
                            >
                              {content.data.map((d, i) => {
                                return (
                                  <div key={i} className={styles.grouperItems}>
                                    <div
                                      className={classNames(
                                        "flex",
                                        styles.grouperItemsHeader,
                                        styles.height
                                      )}
                                    >
                                      {i === 0 && (
                                        <ToggleButtonAddRemove
                                          className={styles.button}
                                          onClick={() => {
                                            setActiveColumns({
                                              ...activeColumns,
                                              ...obj,
                                            });
                                          }}
                                          active={true}
                                        />
                                      )}
                                      <div className={styles.headerTextColor}>{d.text}</div>
                                    </div>
                                    <div>
                                      {data.map((row, rowIndexContent) => {
                                        const rowValue =
                                          row[column.key]?.[content.key]?.[d.key] || 0;

                                        return (
                                          <div
                                            key={rowIndexContent}
                                            className={classNames(
                                              styles.height,
                                              styles.borderTop,
                                              styles.contentTextColor,
                                              styles.grouperItemsContent,
                                              {
                                                [`${styles.firstGrouperContent}`]: i === 0,
                                                [`${styles.totalHighlightContent}`]:
                                                  row.isTotalHightlight,
                                              }
                                            )}
                                          >
                                            {d.formatter ? d.formatter(rowValue) : rowValue}
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          );
                        }
                        return (
                          <div
                            key={contentIndex}
                            className={classNames(styles.grouperItems, styles.borderRight)}
                          >
                            <div
                              className={classNames(
                                "flex",
                                styles.height,
                                styles.grouperItemsHeader,
                                styles.headerTextColor
                              )}
                            >
                              {expandable && (
                                <ToggleButtonAddRemove
                                  className={styles.button}
                                  onClick={() => {
                                    setActiveColumns({
                                      ...activeColumns,
                                      ...obj,
                                    });
                                  }}
                                  active={false}
                                />
                              )}
                              {content.text}
                            </div>
                            <div>
                              {data.map((row, rowContentIndex) => {
                                const rowValue = row[column.key]?.[content.key]?.total || 0;
                                return (
                                  <div
                                    key={rowContentIndex}
                                    className={classNames(
                                      styles.height,
                                      styles.borderTop,
                                      styles.grouperItemsContent,
                                      styles.firstGrouperContent,
                                      styles.contentTextColor,
                                      {
                                        [`${styles.totalHighlightContent}`]: row.isTotalHightlight,
                                      }
                                    )}
                                  >
                                    {content.formatter ? content.formatter(rowValue) : rowValue}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  <div className={styles.width115}>
                    <div
                      className={classNames(
                        styles.grouperItems,
                        styles.borderRight,
                        styles.borderTop
                      )}
                    >
                      <div
                        className={classNames(
                          "flex",
                          styles.headerTextColor,
                          styles.height,
                          styles.grouperItemsHeader
                        )}
                      >
                        Total
                      </div>
                      <div>
                        {data.map((row, rowContentIndex) => {
                          const rowValue = row[column.key]?.total || 0;

                          return (
                            <div
                              key={rowContentIndex}
                              className={classNames(
                                styles.height,
                                styles.borderTop,
                                styles.grouperItemsContent,
                                styles.highlightTotalBG,
                                styles.contentTextColor,
                                {
                                  [`${styles.totalHighlightContent}`]: row.isTotalHightlight,
                                }
                              )}
                            >
                              {column.formatter ? column.formatter(rowValue) : rowValue}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    marginTop: "40px",
                  }}
                  className={classNames(styles.borderTopInvi)}
                >
                  {data.map((row, rowContentIndex) => {
                    const rowValue = row[column.key] || 0;
                    return (
                      <div
                        key={rowContentIndex}
                        className={classNames(
                          styles.height,
                          styles.borderTop,
                          styles.columnContent,
                          styles.contentTextColor,
                          {
                            [`${styles.totalHighlightContent}`]: row.isTotalHightlight,
                          }
                        )}
                      >
                        {column.formatter ? column.formatter(rowValue) : rowValue}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TotalsTable;
