const BilledTo = {
  SeaoilPhilippines: "Seaoil Philippines Inc.",
  JadePinnacleConsolidatedInc: "Jade Pinnacle Consolidated Inc.",
  OceanTankersCorporation: "Ocean Tankers Corporation",
  LocqOPC: "LOCQ OPC",
  SupremeDominionConsolidatedInc: "Supreme Dominion Consolidated Inc.",
  SecurityBankCorporation: "Security Bank Corporation",
  PlentinaLendingInc: "PLENTINA LENDING, INC.",
  ELinkBusinessInnovation: "E-Link Business Innovation Corporation",
  GlobalDominionFinancingIncorporated: "Global Dominion Financing Incorporated",
  NovelCapitalInc: "Novel Capital, Inc.",
  ServCentralInc: "Serv Central Inc",
  GazzUpInc: "Gazz Up Inc",
  EmeraldFieldsTradingInc: "Emerald Fields Trading Inc.",
  FrancisGlennYu: "Francis Glenn Yu",
  VirginiaGloriaBraga: "Virginia Gloria Braga",
  FrancisYu: "Francis Yu",
  Others: "Others",
};

export default BilledTo;
