import { DataTable, Text } from "components/commons";
import classNames from "classnames";
import styles from "./station-list.module.scss";
import React, { useMemo } from "react";
import { stationColumns } from "./station-columns";
import locale from "localization";
import CircularProgress from "@material-ui/core/CircularProgress";

const StationListModule = ({
  fetchingStationsDiscounts,
  getDiscountStationsResult,
  count,
  isLOCQPay,
}) => {
  const preparedCustomerListData = useMemo(() => {
    const result = getDiscountStationsResult;

    const stations = isLOCQPay ? result.outrightDiscountCampaignStations : result.stations;

    if (stations?.length > 0) {
      const preparedData = stations.map((s) => {
        const map = new Map();
        map.set(
          "stationName",
          <Text className={classNames(styles.textStyle)} small>
            {isLOCQPay ? s.stationName : s.name}
          </Text>
        );
        map.set(
          "stationType",
          <Text className={classNames(styles.textStyle)} small>
            {s.stationType}
          </Text>
        );

        return map;
      });
      return preparedData;
    }
    return [];
  }, [getDiscountStationsResult, isLOCQPay]);

  return (
    <div className={styles.container}>
      <div className={styles.title}>{locale.stations}</div>
      {!fetchingStationsDiscounts ? (
        <div className={styles.table}>
          <DataTable
            page={1}
            pageSize={count}
            columns={stationColumns}
            data={preparedCustomerListData}
            hidePagination
          />
        </div>
      ) : (
        <div className={styles.circleContainer}>
          <CircularProgress className={styles.circle} />
        </div>
      )}
    </div>
  );
};

export default StationListModule;
