import React from "react";
import styles from "./fleet-card-filter.module.scss";
import { prettifyStatus, prettifyProduct } from "utils/pretty.utils";
import { prettifyFuelCodeType } from "utils";
import { Status, FuelCodeType, Products } from "enums";
import { Autocomplete, Field, SearchField, Filter } from "components/commons";
import locale from "localization";
import classNames from "classnames";
import GroupCheckbox from "components/commons/group-checkbox/group-checkbox";

const FleetCardFilter = ({
  filterCount,
  applyFilter,
  applyClearFilter,
  resetFilter,
  filterState,
  modifyFilter,
  modifySearch,
  applySearch,
  applyClearSearch,
  searchKey,
}) => {
  return (
    <div className={styles?.container}>
      <div className={styles?.filter}>
        <Filter
          close={resetFilter}
          clear={applyClearFilter}
          submit={applyFilter}
          filterCount={filterCount}
        >
          <Field
            className={styles.filterInput}
            label={locale.cardType}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
          >
            <Autocomplete
              name="fuelCardTypes"
              hasAll
              multiple
              value={filterState.fuelCardTypes.value}
              options={[
                {
                  label: prettifyFuelCodeType(FuelCodeType.DriverSpecific),
                  value: FuelCodeType.DriverSpecific,
                },
                {
                  label: prettifyFuelCodeType(FuelCodeType.VehicleSpecific),
                  value: FuelCodeType.VehicleSpecific,
                },
                {
                  label: prettifyFuelCodeType(FuelCodeType.DriverVehicleSpecific),
                  value: FuelCodeType.DriverVehicleSpecific,
                },
                {
                  label: prettifyFuelCodeType(FuelCodeType.AnyDriverAnyVehicle),
                  value: FuelCodeType.AnyDriverAnyVehicle,
                },
              ]}
              onChange={(name, { value, isSelectedAll }) =>
                modifyFilter({ [name]: { value, isSelectedAll } })
              }
            />
          </Field>
          <Field
            className={styles.filterInput}
            label={locale.status}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
          >
            <Autocomplete
              name="fuelCardStatuses"
              hasAll
              multiple
              value={filterState.fuelCardStatuses.value}
              options={[
                {
                  label: prettifyStatus(Status.Active),
                  value: Status.Active,
                },
                {
                  label: prettifyStatus(Status.Deactivated),
                  value: Status.Deactivated,
                },
                {
                  label: prettifyStatus(Status.Terminated),
                  value: Status.Terminated,
                },
              ]}
              onChange={(name, { value, isSelectedAll }) =>
                modifyFilter({ [name]: { value, isSelectedAll } })
              }
            />
          </Field>
          <GroupCheckbox
            className={styles.productCode}
            title={locale.availableProducts}
            options={[
              {
                content: (
                  <div className="flex items-center">
                    <div className={classNames(styles.circle, styles.diesel)}></div>
                    {prettifyProduct(Products.Diesel)}
                  </div>
                ),
                value: Products.Diesel,
              },
              {
                content: (
                  <div className="flex items-center">
                    <div className={classNames(styles.circle, styles.gas91)}></div>
                    {prettifyProduct(Products.Gas91)}
                  </div>
                ),
                value: Products.Gas91,
              },
              {
                content: (
                  <div className="flex items-center">
                    <div className={classNames(styles.circle, styles.gas95)}></div>
                    {prettifyProduct(Products.Gas95)}
                  </div>
                ),
                value: Products.Gas95,
              },
              {
                content: (
                  <div className="flex items-center">
                    <div className={classNames(styles.circle, styles.gas97)}></div>
                    {prettifyProduct(Products.Gas97)}
                  </div>
                ),
                value: Products.Gas97,
              },
              {
                content: (
                  <div className="flex items-center">
                    <div className={classNames(styles.circle, styles.lubricants)}></div>
                    {prettifyProduct(Products.Lubes)}
                  </div>
                ),
                value: Products.Lubes,
              },
            ]}
            value={filterState.productCodes}
            onChange={(_, { value }) => {
              modifyFilter({ productCodes: value });
            }}
          />
        </Filter>
        <Field className={styles.search}>
          <SearchField
            restrict={false}
            placeholder={`${locale.businessName}/${locale.id}, ${locale.fleetCardNumber}`}
            value={searchKey}
            onChange={(_, { value }) => modifySearch(value)}
            onEnter={applySearch}
            onClear={applyClearSearch}
          />
        </Field>
      </div>
    </div>
  );
};

export default FleetCardFilter;
