import { CashInChannel, CashInStatus, Products } from "enums";
import Currency from "enums/currency";
import PlatformType from "enums/platform-type";
import RedemptionStatus from "enums/redemption-status";
import PayWithLOCQPayStatus from "enums/pay-with-locqpay-status";
import { mapOptions } from "utils/object.utils";
import {
  prettifyPlatformType,
  prettifyProduct,
  prettifyCashInStatus,
  prettifyCashInChannel,
  prettifyCurrency,
  prettifyRedemptionStatus,
  prettifyPayWithLOCQPayStatus,
} from "utils/pretty.utils";

export const productOptions = mapOptions(
  [Products.Diesel, Products.Gas91, Products.Gas95, Products.Gas97],
  prettifyProduct
);

export const voucherCurrencyOptions = mapOptions([Currency.Peso], prettifyCurrency);

export const platformOptions = mapOptions(
  [PlatformType.PLB, PlatformType.PLC],
  prettifyPlatformType
);

export const cashinStatusOptions = mapOptions(
  [CashInStatus.Success, CashInStatus.Pending, CashInStatus.Failed, CashInStatus.Expired],
  prettifyCashInStatus
);

export const cashinChannelOptions = (method) => {
  const obj = {
    [CashInChannel.Card]: [CashInChannel.Cybersource],
    [CashInChannel.OnlineBanking]: [
      CashInChannel.BDO,
      CashInChannel.Unionbank,
      CashInChannel.BPIOnlineBank,
    ],
    [CashInChannel.BNPL]: [CashInChannel.Billease],
    [CashInChannel.EWallet]: [CashInChannel.GCash, CashInChannel.ShopeePay],
    [CashInChannel.OTC]: [
      CashInChannel.Seaoil,
      CashInChannel.SevenEleven,
      CashInChannel.ExpressPay,
      CashInChannel.ECPay,
      CashInChannel.HLhuillier,
      CashInChannel.RDPawnshop,
      CashInChannel.TrueMoney,
    ],
    [CashInChannel.Voucher]: [CashInChannel.NewCustomerVoucher, CashInChannel.LocqPromo],
    [CashInChannel.Rebate]: [CashInChannel.PayWithPeso],
    [CashInChannel.VipLOCQPay]: [CashInChannel.SeaoilVip],
  };
  return mapOptions(obj[method] || [], prettifyCashInChannel);
};

export const cashinMethodOptions = mapOptions(
  [
    CashInChannel.Card,
    CashInChannel.OnlineBanking,
    CashInChannel.BNPL,
    CashInChannel.EWallet,
    CashInChannel.OTC,
    CashInChannel.Voucher,
    CashInChannel.Rebate,
    CashInChannel.LocqPayDiscount,
    CashInChannel.VipLOCQPay,
  ],
  prettifyCashInChannel
);

export const redemptionStatusOptions = mapOptions(
  [RedemptionStatus.Success, RedemptionStatus.Voided],
  prettifyRedemptionStatus
);

export const payWithLOCQPayStatusOptions = mapOptions(
  [PayWithLOCQPayStatus.Success, PayWithLOCQPayStatus.Voided],
  prettifyPayWithLOCQPayStatus
);
