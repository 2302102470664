import { getBuyFuel } from "apis/buy-fuel.api";
import { Intro } from "components/commons";
import useDataTable from "hooks/useDataTable";
import locale from "localization";
import React, { useContext } from "react";

import BuyFuelList from "./buy-fuel.list";
import { columns } from "./buy-fuel-columns";
import BuyFuelFilter from "./buy-fuel-filter";
import { buyFuelState } from "./buy-fuel-filter.state";
import { mapDataToList, mapFilterToRequest } from "./buy-fuel.mapper";
import useExport from "hooks/useExport";
import { generateReportAdmin } from "apis";
import { SuccessModal } from "components/modals";
import { SessionContext } from "contexts";

const BuyFuelModule = () => {
  const { sessionId } = useContext(SessionContext);

  const { filter, search, table } = useDataTable({
    api: { api: getBuyFuel },
    filter: { initialState: buyFuelState(), mapper: mapFilterToRequest },
    table: { key: "buyFuels", mapper: mapDataToList, columns },
  });

  const exportReport = useExport({
    api: generateReportAdmin,
    hasModal: true,
    reportType: "buy-fuel",
    mappedFilterState: {
      ...filter?.mappedFilterState,
      platformType: "plb",
      searchKey: search?.searchKey,
      sessionId,
    },
  });

  return (
    <>
      <SuccessModal {...exportReport?.successModalComponent} />
      <Intro title={locale.buyFuelCapitalize} subtitle={locale.viewTrackPurchases} />
      <BuyFuelFilter {...filter} {...search} {...exportReport} />
      <BuyFuelList table={table} />
    </>
  );
};

export default BuyFuelModule;
