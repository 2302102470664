import { Products } from "enums";
import PayWithLOCQPayStatus from "enums/pay-with-locqpay-status";

export const payWithLOCQPayFilterState = (searchKey) => ({
  startDate: null,
  endDate: null,
  productCodes: [Products.Diesel, Products.Gas91, Products.Gas95, Products.Gas97],
  searchKey: searchKey || "",
  redemptionStation: {
    value: null,
    label: "All",
  },
  paymentStatus: {
    isSelectedAll: true,
    value: [PayWithLOCQPayStatus.Success, PayWithLOCQPayStatus.Voided],
  },
  page: 1,
  perPage: 10,
});
