import { Autocomplete, DateRange, Field, Filter, SearchField } from "components/commons";
import { FuelCodesStatus, Products } from "enums";
import React from "react";
import { prettifyFuelCodeType, prettifyFuelCodes, prettifyProduct } from "utils/pretty.utils";
import styles from "./fuel-codes-filter.module.scss";
import locale from "localization";
import { FuelCodeType } from "enums";
import classNames from "classnames";
import GroupCheckbox from "components/commons/group-checkbox/group-checkbox";

const FuelCodesFilter = ({
  filterState,
  applyFilter,
  resetFilter,
  applyClearFilter,
  modifyFilter,
  modifySearch,
  applySearch,
  applyClearSearch,
  searchKey,
  filterCount,
  // exportReport,
  // exporting,
}) => {
  return (
    <div className={styles.filter}>
      <Filter
        submit={applyFilter}
        close={resetFilter}
        clear={applyClearFilter}
        filterCount={filterCount}
      >
        <Field
          className={styles.filterInput}
          label={locale.dateGenerated}
          labelPosition={"left"}
          childrenClassName={styles.fieldContent}
        >
          <DateRange
            value={{
              startDate: filterState.startDate,
              endDate: filterState.endDate,
            }}
            onChange={(_, { value }) => {
              const { startDate, endDate } = value;
              modifyFilter({ startDate, endDate });
            }}
          />
        </Field>
        <Field
          className={styles.filterInput}
          label={locale.status}
          labelPosition={"left"}
          childrenClassName={styles.fieldContent}
        >
          <Autocomplete
            name="status"
            value={filterState.status}
            options={mapOptions(
              [
                FuelCodesStatus.Active,
                FuelCodesStatus.Expired,
                FuelCodesStatus.Scheduled,
                FuelCodesStatus.Redeemed,
                FuelCodesStatus.Deactivated,
              ],
              prettifyFuelCodes
            )}
            onChange={(name, { value }) => modifyFilter({ [name]: value })}
          />
        </Field>

        <Field
          className={styles.filterInput}
          label={locale.fuelCodeType}
          labelPosition={"left"}
          childrenClassName={styles.fieldContent}
        >
          <Autocomplete
            name="fuelCodeType"
            value={filterState.fuelCodeType}
            options={mapOptions(
              [
                FuelCodeType.DriverSpecific,
                FuelCodeType.VehicleSpecific,
                FuelCodeType.DriverVehicleSpecific,
                FuelCodeType.AnyDriverAnyVehicle,
              ],
              prettifyFuelCodeType
            )}
            onChange={(name, { value }) => modifyFilter({ [name]: value })}
          />
        </Field>

        <Field
          className={styles.filterInput}
          label={locale.oneTimeUse}
          labelPosition={"left"}
          childrenClassName={styles.fieldContent}
        >
          <Autocomplete
            name="oneTimeUse"
            value={filterState.oneTimeUse}
            options={[
              { label: locale.all, value: "all" },
              { label: locale.yes, value: "true" },
              { label: locale.no, value: "false" },
            ]}
            onChange={(name, { value }) => modifyFilter({ [name]: value })}
          />
        </Field>

        <Field
          className={styles.filterInput}
          label={locale.autoRenew}
          labelPosition={"left"}
          childrenClassName={styles.fieldContent}
        >
          <Autocomplete
            name="autoRenew"
            value={filterState.autoRenew}
            options={[
              { label: locale.all, value: "all" },
              { label: locale.yes, value: "true" },
              { label: locale.no, value: "false" },
            ]}
            onChange={(name, { value }) => modifyFilter({ [name]: value })}
          />
        </Field>

        <GroupCheckbox
          className={styles.productCode}
          title={locale.availableProducts}
          options={[
            {
              content: (
                <div className="flex items-center">
                  <div className={classNames(styles.circle, styles.diesel)}></div>
                  {prettifyProduct(Products.Diesel)}
                </div>
              ),
              value: Products.Diesel,
            },
            {
              content: (
                <div className="flex items-center">
                  <div className={classNames(styles.circle, styles.gas91)}></div>
                  {prettifyProduct(Products.Gas91)}
                </div>
              ),
              value: Products.Gas91,
            },
            {
              content: (
                <div className="flex items-center">
                  <div className={classNames(styles.circle, styles.gas95)}></div>
                  {prettifyProduct(Products.Gas95)}
                </div>
              ),
              value: Products.Gas95,
            },
            {
              content: (
                <div className="flex items-center">
                  <div className={classNames(styles.circle, styles.gas97)}></div>
                  {prettifyProduct(Products.Gas97)}
                </div>
              ),
              value: Products.Gas97,
            },
          ]}
          value={filterState.productCodes}
          onChange={(_, { value }) => {
            modifyFilter({ productCodes: value });
          }}
        />
      </Filter>

      <Field className={styles.search}>
        <SearchField
          restrict={false}
          placeholder={`${locale.businessNameId}, ${locale.fuelCode}`}
          value={searchKey}
          onChange={(_, { value }) => modifySearch(value)}
          onEnter={applySearch}
          onClear={applyClearSearch}
        />
      </Field>
      {/* <Field className={styles.downloadButton}>
        <Button
          startIcon={<span className="icon-download" />}
          exportButton
          onClick={exportReport}
          loading={exporting}
        >
          {locale.export}
        </Button>
      </Field> */}
    </div>
  );
};

const mapOptions = (values = [], prettifier = () => {}) => {
  return [
    { label: locale.all, value: "all" },
    ...values.map((value) => ({
      label: prettifier(value),
      value,
    })),
  ];
};

export default FuelCodesFilter;
