import locale from "localization";

export const settlementHistoryColumns = [
  { key: "dateProcessed", text: `${locale.dateProcessed}/${locale.settlementId}`, width: "9%" },
  {
    key: "redemptionStation",
    text: `${locale.redemptionStation}/${locale.stationBusinessName}`,
    width: "11%",
  },
  { key: "totalLiterRedemption", text: locale.totalLiterRedemption, width: "9%" },
  { key: "totalPayWithPeso", text: locale.totalPayWithLOCQPay, width: "9%" },
  { key: "totalMDRFee", text: locale.totalMDRFee, width: "9%" },
  { key: "totalRumSDUMFDifferential", text: locale.totalRumSDUMFDifferential, width: "9%" },
  { key: "totalRumBuy", text: locale.totalRumBuy, width: "9%" },
  { key: "totalSales", text: locale.totalSales, width: "9%" },
  { key: "totalCreditAmount", text: locale.totalCreditAmount, width: "9%" },
  { key: "totalLocqpayDiscount", text: locale.totalLocqpayDiscount, width: "10%" },
  { key: "totalSdumfDifferential", text: locale.totalDealerShoulderedAdjustment, width: "9%" },
  { key: "totalDealerPayout", text: locale.totalDealerSettlementPayout, width: "11%" },
  {
    key: "dealerPayoutStatus",
    text: `${locale.dealerSettlementPayoutStatus}/${locale.date}`,
    width: "13%",
  },
  { key: "dealerSettlementApv", text: locale.apvNumber, width: "10%" },
  { key: "dealerRemarks", text: locale.remarks, width: "10%" },
  { key: "action", text: locale.action, width: "250px" },
];
