import { AppBar, IconButton, Menu, MenuItem, Toolbar, Typography } from "@material-ui/core";
import classNames from "classnames";
import { useState, useCallback } from "react";
import { DefaultProfile, PLBLogoWhite } from "../../../images";
import { PriceLocqBusiness, PriceLocqConsumer, HomeIcon } from "images";
import { DropdownMenu } from "components/commons";
import Image from "../image/image";
import styles from "./header.module.scss";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import { useApi, useRouter } from "hooks";
import { redirectTo } from "utils";
import { logout } from "apis";
import { Path } from "enums";
import { useContext } from "react";
import { AppContext, UserContext } from "contexts";
import { locale } from "localization/en";
import { Role, Portal } from "enums";

const Header = ({ setNewNotification, newNotification, isError }) => {
  const { history } = useRouter();

  const [open, setOpen] = useState(false);
  const { toggleLoading } = useContext(AppContext);

  const { request: logoutRequest } = useApi({
    api: logout,
  });

  let pathBusiness;
  let pathLOCQ;
  let pathConsumer;
  let menuItems = [];

  if (!isError) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { user } = useContext(UserContext);
    const { role } = user?.userAccesses?.find((element) => {
      return element.portal === Portal.LOCQ;
    });
    switch (role) {
      case Role.PortalAdmin:
        pathLOCQ = {
          label: locale.pricelocq,
          icon: HomeIcon,
          link: Path.Locq,
          onClick: () => history.push(Path.Locq),
        };
        pathConsumer = {
          label: locale.pricelocqConsumer,
          icon: PriceLocqConsumer,
          link: Path.PricelocqConsumer,
          onClick: () => history.push(Path.PricelocqConsumer),
        };
        pathBusiness = {
          label: locale.pricelocqBusiness,
          icon: PriceLocqBusiness,
          link: Path.PricelocqBusiness,
          onClick: () => history.push(Path.PricelocqBusiness),
        };
        break;
      case Role.Accounting:
        pathLOCQ = {
          label: locale.pricelocq,
          icon: HomeIcon,
          link: Path.LocqSettlement,
          onClick: () => history.push(Path.LocqSettlement),
        };
        pathConsumer = {
          label: locale.pricelocqConsumer,
          icon: PriceLocqConsumer,
          link: Path.ConsumerCashIn,
          onClick: () => history.push(Path.ConsumerCashIn),
        };
        pathBusiness = {
          label: locale.pricelocqBusiness,
          icon: PriceLocqBusiness,
          link: Path.CashFlow,
          onClick: () => history.push(Path.CashFlow),
        };
        break;
      case Role.SalesPartnerships:
        pathLOCQ = {
          label: locale.pricelocq,
          icon: HomeIcon,
          link: Path.LocqSettlementHistory,
          onClick: () => history.push(Path.LocqSettlementHistory),
        };
        pathConsumer = {
          label: locale.pricelocqConsumer,
          icon: PriceLocqConsumer,
          link: Path.ConsumerCashIn,
          onClick: () => history.push(Path.ConsumerCashIn),
        };
        pathBusiness = {
          label: locale.pricelocqBusiness,
          icon: PriceLocqBusiness,
          link: Path.CashIn,
          onClick: () => history.push(Path.CashIn),
        };
        break;
      default:
        pathLOCQ = {
          label: locale.pricelocq,
          icon: HomeIcon,
          link: Path.Locq,
          onClick: () => history.push(Path.Locq),
        };
        pathConsumer = {
          label: locale.pricelocqConsumer,
          icon: PriceLocqConsumer,
          link: Path.PricelocqConsumer,
          onClick: () => history.push(Path.PricelocqConsumer),
        };
        pathBusiness = {
          label: locale.pricelocqBusiness,
          icon: PriceLocqBusiness,
          link: Path.PricelocqBusiness,
          onClick: () => history.push(Path.PricelocqBusiness),
        };
        break;
    }

    menuItems = [pathLOCQ, pathConsumer, pathBusiness];
  }

  const logoutCb = useCallback(async () => {
    toggleLoading(true);
    await logoutRequest();
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("fleetId");
    toggleLoading(false);
    redirectTo(Path.Auth);
  }, [logoutRequest, toggleLoading]);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div id="header">
      <AppBar className={classNames(styles.headerContainer)}>
        <Toolbar disableGutters>
          <div className={styles.logoContainer}>
            <DropdownMenu menuItems={menuItems} />
            <Image src={PLBLogoWhite} className={styles.logo} />
          </div>
          <div className={styles.toolbarContainer}>
            <Typography variant="h6" className={styles.title}>
              {locale.locqPortal}
            </Typography>
            <div className={styles.menuContainer}>
              <IconButton
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => {
                  setNewNotification();
                  history.push(Path.Download);
                }}
              >
                {newNotification && <div className={styles?.notificationIcon} />}
                <GetAppRoundedIcon className={styles.downloadIcon} />
              </IconButton>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpen}
              >
                <Image src={DefaultProfile} className={styles.profile} />
                <KeyboardArrowDownRoundedIcon className={styles.caretIcon} />
              </IconButton>
              <Menu
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                keepMounted
                open={open}
                className={styles.menu}
                onClose={handleClose}
              >
                <MenuItem className={styles.menuItem} onClick={logoutCb}>
                  {locale.logout}
                </MenuItem>
              </Menu>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
