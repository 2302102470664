import { generateReportAdmin, getLOCQPayDiscounts, updateLOCQPayDiscountCampaign } from "apis";
import { Intro, PanelButton } from "components/commons";
import { CampaignStatus, CampaignType, Path, Role, Portal } from "enums";
import { useApi, useModal, useRouter } from "hooks";
import locale from "localization";
import React, { useCallback, useContext } from "react";
import { ConfirmModal, SuccessModal } from "components/modals";
import { discountColumns } from "./discount-columns";
import DiscountFilter from "./discount-filter";
import { discountFilterState } from "./discount-filter.state";
import { handleRequest } from "utils";
import useExport from "hooks/useExport";
import { FeatureFlagContext, SessionContext, UserContext } from "contexts";

import DataTableV2 from "components/commons/data-table/data-table-v2";
import useDataTable from "hooks/useDataTable";

import { mapFilterToRequest, mapDataToList } from "./discount-list.mapper";

const DiscountListModule = () => {
  const { hasFeature } = useContext(FeatureFlagContext);
  const confirmModal = useModal();
  const { sessionId } = useContext(SessionContext);
  const { close } = confirmModal;
  const { history } = useRouter();

  const { user } = useContext(UserContext);
  const { role } = user?.userAccesses?.find((element) => {
    return element.portal === Portal.LOCQ;
  });

  const { request: getDiscountRequest } = useApi({
    api: getLOCQPayDiscounts,
    pageError: true,
  });

  const PanelButtonProps = {
    discountProps: {
      onClick: () => history.push(Path.Campaigns),
    },
    voucherProps: {
      onClick: () => history.push(Path.VoucherCampaign),
    },
    locqpayCampaignProps: {
      onClick: () => history.push(Path.LOCQPayDiscountCampaigns),
    },
  };

  const cancelStatusRequest = useApi({
    api: updateLOCQPayDiscountCampaign,
    handleOwnError: {
      badrequest: true,
    },
  });

  const handleChangeStatus = (params, name) => {
    const isCancel = params.status === CampaignStatus.Cancelled;
    confirmModal.show({
      title: isCancel ? `${locale.cancelCampaign}?` : `${locale.reactivateCampaign}?`,
      content: isCancel ? (
        <locale.Populate
          text={locale.youAreAboutToCancelLOCQPayDiscountCampaign}
          items={[<strong>{name}</strong>, name]}
        />
      ) : (
        locale.reactivatingCampaignMessage
      ),
      secondary: {
        text: locale.Cancel,
      },
      primary: {
        text: locale.yesCancelIt,
        onClick: async () => {
          await submitChangeStatus(params);
          close();
        },
      },
    });
  };
  const { filter, search, table } = useDataTable({
    api: {
      api: getDiscountRequest,
      mapper: (param) => {
        return param;
      },
    },
    filter: { initialState: discountFilterState(), mapper: mapFilterToRequest },
    table: {
      key: "outrightDiscountCampaigns",
      mapper: (outrightDiscountCampaigns) =>
        mapDataToList({
          outrightDiscountCampaigns,
          handleChangeStatus,
          history,
          role,
        }),
      columns: discountColumns,
    },
  });

  const submitChangeStatus = useCallback(
    async (params) => {
      handleRequest(async () => {
        const result = await cancelStatusRequest.request({
          ...params,
        });
        if (result) {
          table.fetch({});
        }
      });
    },
    [cancelStatusRequest, table]
  );

  const exportReport = useExport({
    api: generateReportAdmin,
    reportType: "outright-discount-campaign",
    hasModal: true,
    mappedFilterState: {
      startDate: filter?.mappedFilterState?.startDate,
      endDate: filter?.mappedFilterState?.endDate,
      outrightDiscountCampaignStatuses: filter?.mappedFilterState?.statuses,
      searchKey: search.searchKey,
      sessionId,
    },
  });

  delete table.result.count;
  return (
    <div>
      {!hasFeature("VOUCHER_V2") && (
        <PanelButton type={CampaignType.LOCQPayCampaign} {...PanelButtonProps} />
      )}
      <div>
        {role === Role.Accounting ? (
          <Intro
            title={locale.locqpayCampaign}
            subtitle={locale.viewAndCreateLOCQPayDiscountCampaigns}
          />
        ) : (
          <Intro
            title={locale.locqpayCampaign}
            subtitle={locale.viewAndCreateLOCQPayDiscountCampaigns}
            actionText={locale.createLOCQPayDiscountCampaign}
            actionOnClick={() => history.push(Path.AddLOCQPayDiscountCampaigns)}
          />
        )}
      </div>
      <DiscountFilter {...filter} {...search} {...exportReport} />
      <DataTableV2 {...table} paginationV2 />
      <ConfirmModal {...confirmModal} />
      <SuccessModal {...exportReport?.successModalComponent} />
    </div>
  );
};

export default DiscountListModule;
