import { CustomerStatus } from "enums";

export const customerFilterState = () => ({
  page: 1,
  perPage: 10,
  endDate: null,
  startDate: null,
  updateStartDate: null,
  updateEndDate: null,
  redemptionStation: {
    value: null,
    label: "All",
  },
  status: {
    isSelectedAll: true,
    value: [
      CustomerStatus.Active,
      CustomerStatus.Deactivated,
      CustomerStatus.Deleted,
      CustomerStatus.Blocked,
    ],
  },
});
