import * as React from "react";
// import DesktopDatePicker from "@material-ui/lab/";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import { useRef } from "react";
import { DateTime } from "enums";
import { formatDate, parseDate } from "react-day-picker/moment";
import classNames from "classnames";
import styles from "./date-picker.module.scss";
import { Calendar } from "images";
import Image from "../image/image";
import moment from "moment";
import { useMemo } from "react";

const DatePicker = ({
  value,
  onChange,
  name,
  disabled,
  placeholder,
  className,
  disableStartDate,
  disableEndDate,
}) => {
  const datePickerRef = useRef();

  // useEffect(() => {
  //   if (!value) {
  //     console.log(datePickerRef.current);
  //     // datePickerRef.current.setValue(null);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [value]);

  const modifiers = useMemo(() => {
    let modifiers = null;
    let disabled = [];
    if (disableStartDate) {
      disabled.push({
        before: new Date(disableStartDate),
      });
    }
    if (disableEndDate) {
      disabled.push({
        after: new Date(disableEndDate),
      });
    }

    if (disabled.length) {
      modifiers = { disabled };
    }
    return modifiers;
  }, [disableStartDate, disableEndDate]);

  return (
    <div
      className={classNames(styles.container, className, {
        [`${styles.disabled}`]: disabled,
      })}
    >
      <div className={styles.datePicker}>
        {disabled ? (
          <div>{value ? moment(value).format(DateTime.A) : placeholder}</div>
        ) : (
          <DayPickerInput
            ref={datePickerRef}
            value={value}
            placeholder={placeholder}
            format={DateTime.A}
            formatDate={formatDate}
            parseDate={parseDate}
            onDayChange={(date) => {
              if (!disabled) {
                onChange(name, { value: date });
              }
            }}
            disabled={disabled}
            dayPickerProps={{
              disabled,
              numberOfMonths: 1,
              modifiers: modifiers,
            }}
          />
        )}
        <Image className={styles.icon} src={Calendar} />
      </div>
    </div>
  );
};

export default DatePicker;
