import React, { useState, useCallback } from "react";
import Popover from "@material-ui/core/Popover";
import styles from "./pop-over-station.module.scss";
import StationListModule from "./station-list.module";
import { getDiscountCampaignStations, getLOCQPayDiscountCampaignStations } from "apis";
import { useApi } from "hooks";

const PopOverStation = ({ label, station, isLOCQPay }) => {
  const { id, count } = station;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const {
    request: getDiscountStationsRequest,
    loading: fetchingStationsDiscounts,
    result: getDiscountStationsResult = isLOCQPay
      ? {
          outrightDiscountCampaignStations: [],
          count: 0,
        }
      : {
          stations: [],
          count: 0,
        },
  } = useApi({
    api: isLOCQPay ? getLOCQPayDiscountCampaignStations : getDiscountCampaignStations,
    pageError: false,
    params: { page: 1, perPage: count },
  });

  const fetchStations = useCallback(async () => {
    const requestParams = isLOCQPay
      ? {
          outrightDiscountCampaignId: id,
        }
      : {
          discountCampaignId: id,
        };
    await getDiscountStationsRequest(requestParams);
  }, [getDiscountStationsRequest, id, isLOCQPay]);

  const handleClick = (event) => {
    if (station.count > 0) {
      setAnchorEl(event.currentTarget);
      fetchStations();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const stationProps = { fetchingStationsDiscounts, getDiscountStationsResult, count, isLOCQPay };

  return (
    <div>
      <span className={styles.clickLabel} onClick={handleClick}>
        {label}
      </span>
      <Popover
        id="pop-over-content"
        anchorEl={anchorEl}
        className={styles.popoverContainer}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <StationListModule {...stationProps} />
      </Popover>
    </div>
  );
};

export default PopOverStation;
