import { BackLink } from "components/commons";
import { Path } from "enums";
import { useForm } from "hooks";
import { locale } from "localization/en";
import React, { useMemo } from "react";
import ProductConversionForm from "./product-conversion-form";
import { initialState } from "./product-conversion-form.state";

const CreateProductConversion = () => {
  const initialStateValue = useMemo(() => {
    return initialState();
  }, []);

  const form = useForm({
    initialState: initialStateValue,
  });
  return (
    <div>
      <BackLink text={locale.productConversions} path={Path.ProductConversion} />
      <ProductConversionForm title={locale.addNewProductConversion} form={form} />
    </div>
  );
};

export default CreateProductConversion;
