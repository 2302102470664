import React, { useCallback } from "react";
import Big from "big.js";
import classNames from "classnames";

import { Field, Modal, Text, ActionButton } from "components/commons";
import { InputAmount } from "components/field";

import locale from "localization";
import { formatDate } from "utils";
import { DateTime } from "enums";

import styles from "./pricing.module.scss";
import { GasType, computeSpecificPrice } from "./pricing.module";

const prepareFormValue = (fields) => {
  const priceMovementField = Object.values(GasType).map((data, key) => ({
    productCode: Object.keys(GasType)[key],
    movement: fields[`accountSpecific${data}`].value,
  }));
  return {
    priceMovement: priceMovementField,
    effectiveAt: formatDate(new Date(), DateTime.I),
  };
};

const ModalInputs = ({ fields, gasType, modifyForm, disabled }) => (
  <>
    <Field className={styles.gasAmount}>
      <InputAmount
        {...fields[`current${gasType}Price`]}
        onChange={(name, { value }) => {
          modifyForm({
            [name]: { value },
          });
        }}
        className={styles.gasAmount}
      />
    </Field>
    <Field className={styles.gasAmount}>
      <InputAmount
        {...fields[`accountSpecific${gasType}`]}
        decimalPlaces={2}
        isPriceValue
        disabled={disabled}
        onChange={(name, { value }) => {
          modifyForm({
            [name]: { value, isUpdate: true },
            [`newPumpPrice${gasType}`]: {
              value: computeSpecificPrice(fields[`current${gasType}Price`].value, value),
            },
          });
        }}
      />
    </Field>
    <Field className={styles.gasAmount}>
      <InputAmount {...fields[`newPumpPrice${gasType}`]} />
    </Field>
  </>
);

const AccumulatedSpecificModal = ({
  title,
  active,
  close,
  modifyForm,
  fields,
  closable,
  disabled,
  updatePricingForm,
}) => {
  const cancelUpdate = useCallback(() => {
    const obj = Object.values(GasType)
      .map((gasType) =>
        fields[`accountSpecific${gasType}`]?.isUpdate
          ? {
              [fields[`newPumpPrice${gasType}`].name]: {
                value: Number(
                  Big(fields[`newPumpPrice${gasType}`].value ?? 0).minus(
                    parseFloat(fields[`accountSpecific${gasType}`].value)
                  )
                ),
              },
              [fields[`accountSpecific${gasType}`].name]: {
                value: "0",
                isUpdate: false,
                fontColor: "black",
              },
            }
          : null
      )
      .filter((data) => data);

    close();
    modifyForm(Object.assign({}, ...obj));
  }, [close, modifyForm, fields]);

  return (
    <Modal title={title} active={active} close={close} closable={closable} disabled={disabled}>
      <Text align="center">{locale.reviewChangesBelow}</Text>
      <div className={styles.container} style={{ width: 600 }}>
        <Field
          className={classNames(
            styles.fieldLabel,
            styles.fieldPricingLabel,
            styles.fieldPricingLabelPosition
          )}
          horizontal
        >
          <div className={classNames(styles.horizontalModalInput)}>
            <Text xsmall className={styles.gasTitle}>
              {locale.currentPumpPrice}
            </Text>
            <Text xsmall className={styles.gasTitle}>
              {locale.accountSpecificMovement}
            </Text>
            <Text xsmall className={styles.gasTitle}>
              {locale.newPumpPrice}
            </Text>
          </div>
        </Field>
        <Field
          className={classNames(styles.fieldLabel, styles.fieldPricingLabel)}
          label={locale.diesel}
          horizontal
        >
          <div className={styles.horizontalInput}>
            <ModalInputs
              gasType={"Diesel"}
              fields={fields}
              disabled={disabled}
              modifyForm={modifyForm}
            />
          </div>
        </Field>
        <Field
          className={classNames(styles.fieldLabel, styles.fieldPricingLabel)}
          label={locale.gas91}
          horizontal
        >
          <div className={styles.horizontalInput}>
            <ModalInputs
              gasType={"Gas91"}
              fields={fields}
              disabled={disabled}
              modifyForm={modifyForm}
            />
          </div>
        </Field>
        <Field
          className={classNames(styles.fieldLabel, styles.fieldPricingLabel)}
          label={locale.gas95}
          horizontal
        >
          <div className={styles.horizontalInput}>
            <ModalInputs
              gasType={"Gas95"}
              fields={fields}
              disabled={disabled}
              modifyForm={modifyForm}
            />
          </div>
        </Field>
        <Field
          className={classNames(styles.fieldLabel, styles.fieldPricingLabel)}
          label={locale.gas97}
          horizontal
        >
          <div className={styles.horizontalInput}>
            <ModalInputs
              gasType={"Gas97"}
              fields={fields}
              disabled={disabled}
              modifyForm={modifyForm}
            />
          </div>
        </Field>
      </div>
      <ActionButton
        right
        items={[
          {
            onClick: cancelUpdate,
            text: locale.cancel,
            disabled: disabled,
          },
          {
            onClick: () => updatePricingForm(prepareFormValue(fields)),
            loading: disabled,
            text: locale.proceedWithChanges,
          },
        ]}
      />
    </Modal>
  );
};

export default AccumulatedSpecificModal;
