import MoreVert from "@material-ui/icons/MoreVert";
import {
  Pill,
  Table,
  Title,
  Loader,
  PopOverMenu,
  Text,
  Modal,
  ActionButton,
} from "components/commons";
import LabelAndValue from "components/commons/label-and-value/label-and-value";
import { useModal } from "hooks";
import moment from "moment";

import React from "react";
import { formatDate } from "utils";
import { columns } from "./feature-flag-columns";
import FeatureFlagHistory from "./feature-flag-history.modal";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import { WarningRounded } from "@material-ui/icons";

const FeatureFlagsList = ({ loading, data, showEdit }) => {
  const viewHistory = useModal();
  const notifModal = useModal();
  if (loading) {
    return <Loader />;
  }
  return (
    <div>
      {data.map((appData, index) => {
        return (
          <div key={index}>
            <Title small>{appData.label}</Title>
            <Table
              loading={loading}
              columns={columns}
              data={appData.data.map((d) => {
                const created = d.createdBy.split(",");
                const updatedHistory = d.updatedBy?.split(",");
                const updatedHistoryDate = d.updatedAt.toString()?.split(",");
                const remarks = d.remarks?.split(",");
                const updated = updatedHistory[0].split("-");
                const obj = {
                  ...d,
                  key: (
                    <div
                      className="break-all "
                      style={{
                        width: "200px",
                      }}
                    >
                      {d.key}
                      {d.location && (
                        <div style={{ marginTop: "5px" }}>
                          <LabelAndValue
                            label={
                              <Text
                                style={{
                                  wordBreak: "break-word",
                                  fontSize: "10px",
                                }}
                                subtitle
                              >
                                {d.location?.split(",").join(" > ")}
                              </Text>
                            }
                          >
                            <Text strong subtitle>
                              Location
                            </Text>
                          </LabelAndValue>
                        </div>
                      )}
                    </div>
                  ),
                  createdBy: (
                    <div
                      style={{
                        width: "270px",
                      }}
                    >
                      <LabelAndValue label={formatDate(moment(d.createdAt, "X"))}>
                        {`${created[1]} <${created[0]}>`.trim()}
                      </LabelAndValue>
                    </div>
                  ),
                  updatedBy: (
                    <div
                      style={{
                        width: "270px",
                      }}
                    >
                      <LabelAndValue label={formatDate(moment(updatedHistoryDate[0], "X"))}>
                        {`${updated[1]} <${updated[0]}>`.trim()}
                      </LabelAndValue>
                    </div>
                  ),
                  users: (
                    <div
                      style={{
                        width: "200px",
                      }}
                    >
                      {d.users
                        ? d.users?.split(",").map((user, key) => (
                            <div style={{ padding: "3px" }} key={key}>
                              <Pill cement>{user}</Pill>
                            </div>
                          ))
                        : null}
                    </div>
                  ),
                  status: (
                    <div style={{ width: "30px", textAlign: "center" }}>
                      <div
                        style={{
                          margin: "auto",
                          width: "10px",
                          height: "10px",
                          borderRadius: "100%",
                          background: d.enabled ? "#019f3e" : "#95a2aa",
                        }}
                      ></div>
                    </div>
                  ),
                  actions: (
                    <div
                      style={{
                        textAlign: "right",
                        margin: "auto",
                      }}
                    >
                      <PopOverMenu
                        style={{
                          marginLeft: "auto",
                        }}
                        options={[
                          {
                            onClick: () => {
                              showEdit({
                                status: d.enabled,
                                key: d.key,
                                location: d.location,
                                users: d.users,
                                data: { ...d, app: appData.key },
                              });
                            },
                            content: (
                              <div
                                style={{
                                  width: "100px",
                                }}
                              >
                                <Text>Edit</Text>
                              </div>
                            ),
                          },
                          {
                            onClick: () => {
                              notifModal.show({
                                title: `Remove ${d.key} on ${appData.label}?`,
                                content: (
                                  <div>
                                    Removing this flag in database will always show the feature
                                    where this flag is used.
                                    <div
                                      style={{
                                        background: "#c68886",
                                        color: "#fff",
                                        padding: "10px",
                                        borderRadius: "8px",
                                        marginTop: "10px",
                                      }}
                                      className="flex items-center"
                                    >
                                      <WarningRounded
                                        style={{
                                          marginRight: "10px",
                                        }}
                                      />
                                      Perform a code cleanup before removing this feature flag.
                                    </div>
                                  </div>
                                ),
                                submit: () => {
                                  const db = firebase.database();
                                  db.ref(`${appData.key}/${d.key}`).remove();
                                },
                              });
                            },
                            content: (
                              <div
                                style={{
                                  width: "100px",
                                }}
                              >
                                <Text danger>Remove</Text>
                              </div>
                            ),
                          },
                          {
                            onClick: () => {
                              const historyData = [];

                              updatedHistory.forEach((info, index) => {
                                const [email, name] = info.split("-");
                                const [remark = "", status = false] = remarks[index].split("-");
                                historyData.push({
                                  name,
                                  email,
                                  date: updatedHistoryDate[index],
                                  remarks: remark,
                                  status,
                                });
                              });
                              viewHistory.show({
                                data: historyData,
                                title: d.key,
                              });
                            },
                            content: (
                              <div
                                style={{
                                  width: "100px",
                                }}
                              >
                                <Text>View History</Text>
                              </div>
                            ),
                          },
                        ]}
                      >
                        <MoreVert />
                      </PopOverMenu>
                    </div>
                  ),
                };
                const map = new Map();
                Object.keys(obj).forEach((key) => {
                  map.set(key, obj[key]);
                });

                return map;
              })}
            />
          </div>
        );
      })}
      <Modal {...notifModal}>
        <div
          style={{
            maxWidth: "450px",
          }}
        >
          {notifModal.content}
        </div>
        <ActionButton
          right
          items={[
            {
              text: "Cancel",
              primary: false,
              onClick: () => {
                notifModal.close();
              },
            },
            {
              text: "Submit",
              primary: true,
              onClick: () => {
                notifModal.submit();
                notifModal.close();
              },
            },
          ]}
        />
      </Modal>
      <FeatureFlagHistory {...viewHistory} />
    </div>
  );
};

export default FeatureFlagsList;
