import React from "react";

import { BackLink, Field, Intro, Text, Title } from "components/commons";
import { DateTime, Path } from "enums";
import locale from "localization";
import { useApi, useMount, useRouter } from "hooks";

import styles from "./dealer-monthly-fee-details.module.scss";

import DealerMonthlyFeeDetailsListModule from "./dealer-monthly-fee-details-list.module";
import useDataTable from "hooks/useDataTable";
import { mapDataToList, mapFilterToRequest } from "./dealer-monthly-fee-details-list.mapper";
import { dealerMonthlyFeeDetailsListColumns } from "./dealer-monthly-fee-details-list-columns";
import DealerMonthlyFeeDetailsListFilter from "./dealer-monthly-fee-details-list-filter";
import { dealerMonthlyFeeDetailsListFilterState } from "./dealer-monthly-fee-details-list-filter.state";
import { getChargingById, getChargingDetails } from "apis/charging.api";
import { useState } from "react";
import { formatDate } from "utils";
import { generatePricingAdminReport } from "apis/generate-report.api";
import useExport from "hooks/useExport";

const DealerMonthlyFeeDetailsModule = () => {
  const [periodDate, setPeriodDate] = useState();
  const {
    history,
    query: { id: chargingId },
  } = useRouter();

  const { request, loading } = useApi({
    api: getChargingById,
    pageError: true,
  });

  const { filter, search, table } = useDataTable({
    api: { api: getChargingDetails, params: { id: chargingId } },
    filter: { initialState: dealerMonthlyFeeDetailsListFilterState(), mapper: mapFilterToRequest },
    table: {
      key: "chargingDetail",
      mapper: (chargingDetail) =>
        mapDataToList({
          chargingDetail,
          history,
        }),
      columns: dealerMonthlyFeeDetailsListColumns,
    },
  });

  useMount(async () => {
    if (!chargingId) {
      history.push(Path.LocqDealerMonthlyFee);
    } else {
      const res = await request({ id: chargingId });
      setPeriodDate(res.createdAt);
    }
  });

  const exportReport = useExport({
    api: generatePricingAdminReport,
    reportType: "charging-detail",
    mappedFilterState: {
      ...filter.mappedFilterState,
      searchKey: search.searchKey,
      chargingId,
    },
  });

  return (
    <>
      <div className={styles.container}>
        <BackLink text={locale.dealerMonthlyFee} path={Path.LocqDealerMonthlyFee} />
        <div>
          <Intro
            style={{
              minWidth: "300px",
            }}
            title={locale.dealerMonthlyFeeDetails}
          />
          <div className={styles.panel}>
            <Title xsmall>{locale.basicInformation}</Title>
            <Field
              labelClassName={styles.fieldLabelInfo}
              horizontal
              childrenClassName={styles.fieldContent}
              label={locale.month}
            >
              <Text className={styles.fieldText}>
                {loading ? "--" : formatDate(periodDate, DateTime.N)}
              </Text>
            </Field>
            <Field
              labelClassName={styles.fieldLabelInfo}
              horizontal
              childrenClassName={styles.fieldContent}
              label={locale.year}
            >
              <Text className={styles.fieldText}>
                {loading ? "--" : formatDate(periodDate, DateTime.O)}
              </Text>
            </Field>
          </div>
          <DealerMonthlyFeeDetailsListFilter {...filter} {...search} {...exportReport} />
          <div className={styles.table}>
            <DealerMonthlyFeeDetailsListModule table={table} />
          </div>
        </div>
      </div>
    </>
  );
};

export default DealerMonthlyFeeDetailsModule;
