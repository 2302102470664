import { Intro } from "components/commons";
import React from "react";
import { useHistory } from "react-router-dom";
import locale from "localization";
import { initialFilterState } from "./fleet-card-filter.state";
import { columns } from "./fleet-card-columns";
import { mapDataToList, mapFilterToRequest } from "./fleet-card.mapper";
import useDataTable from "hooks/useDataTable";
import { getFuelCards, updateFuelCard } from "apis/fuel-card";
import FleetCardFilter from "./fleet-card-filter";
import { useApi, useModal } from "hooks";
import { ConfirmModal } from "components/modals";
import { Status } from "enums";
import { handleRequest } from "utils";
import FleetCardList from "./fleet-card-list";
//import ImportFleetCardModal from "./import-fleet-card";
// import { importFuelCards } from "apis/fuel-card.api";

import styles from "./fleet-card.module.scss";

const FleetCardModule = (props) => {
  const history = useHistory();
  const confirmModal = useModal();
  const { close } = confirmModal;
  //const importFleetCardModal = useModal();

  const { request: updateFuelCardRequest } = useApi({
    api: updateFuelCard,
    handleOwnError: {
      badrequest: true,
    },
  });

  // const { request: importFuelCardsRequest, loading: loadingImportFuelCards } = useApi({
  //   api: importFuelCards,
  //   handleOwnError: {
  //     badrequest: true,
  //   },
  // });

  const handleUpdateStatus = (action, params) => {
    const { fuelCardId, cardNumber } = params;

    let title = locale.reactivateFleetCard;
    let content = locale.areYouSureYouWantToReactivate;
    let button = locale.yesReactivateIt;

    if (action === Status.Terminated) {
      title = locale.terminateFleetCard;
      content = locale.areYouSureYouWantToTerminate;
      button = locale.yesTerminateIt;
    }

    if (action === Status.Deactivated) {
      title = locale.deactivateFleetCard;
      content = locale.areYouSureYouWantToDeactivate;
      button = locale.yesDeactivateIt;
    }

    confirmModal.show({
      title: title,
      content: (
        <locale.Populate
          text={content}
          items={[
            <b>
              <br />
              {`Fleet Card No. ${cardNumber}`}
            </b>,
          ]}
        />
      ),
      secondary: {
        text: locale.cancel,
      },
      primary: {
        text: button,
        onClick: async () => {
          submitUpdateStatus({
            id: fuelCardId,
            status: action,
          });
          close();
        },
      },
    });
  };

  const { filter, search, table } = useDataTable({
    api: {
      api: getFuelCards,
    },
    filter: { initialState: initialFilterState(), mapper: mapFilterToRequest },
    table: {
      key: "fuelCards",
      mapper: (fuelCards) => mapDataToList({ fuelCards, handleUpdateStatus, history }),
      columns,
    },
    tableProps: { history, handleUpdateStatus },
  });

  const submitUpdateStatus = async (params) => {
    handleRequest(async () => {
      const result = await updateFuelCardRequest({
        ...params,
      });
      if (result) table.fetch({});
    });
  };

  // const transformData = (csvData) => {
  //   return {
  //     fuelCards: csvData.map((card) => ({
  //       fleetId: card.businessId,
  //       cardNumber: card.cardNumber,
  //       locqpayLimit: card.locqpayLimit,
  //       transactionLimit: card.transactionLimit,
  //       driverId: card.driverLicenseId ? parseInt(card.driverLicenseId, 10) : null,
  //       vehicleId: card.plateNumber ? parseInt(card.plateNumber, 10) : null,
  //       fuelCardType: card.cardType || "any",
  //       diesel: card.diesel,
  //       gas91: card.gas91,
  //       gas95: card.gas95,
  //       gas97: card.gas97,
  //       lubes: card.lubes,
  //     })),
  //   };
  // };

  // const saveFleetCardsFromCSVCb = useCallback(
  //   async (csvData) => {
  //     const transformedData = transformData(csvData);

  //     await importFuelCardsRequest({ ...transformedData });
  //   },
  //   [importFuelCardsRequest]
  // );

  return (
    <div>
      <ConfirmModal {...confirmModal} />
      {/* <ImportFleetCardModal {...importFleetCardModal} saveFleetCardsCb={saveFleetCardsFromCSVCb} /> */}
      <Intro
        // actionText={locale.importFleetCard}
        // actionOnClick={() => {
        //   importFleetCardModal.show({});
        // }}
        // actionLoading={loadingImportFuelCards}
        title={locale.myFleetCard}
        subtitle={locale.viewAllPayWithLocqPayTransactions}
      />
      <FleetCardFilter {...filter} {...search} />
      <div className={styles.table}>
        <FleetCardList table={table} />
      </div>
    </div>
  );
};

export default FleetCardModule;
