import React from "react";
import { Path } from "enums";
import { Route, Switch, Redirect } from "react-router-dom";
import NonFuelModule from "modules/plc/non-fuel/non-fuel.module";

const NonFuelPage = () => {
  return (
    <Switch>
      <Route exact path={Path.ConsumerLOCQPayNonFuel} component={NonFuelModule} />
      <Redirect to={Path.ConsumerLOCQPayNonFuel} />
    </Switch>
  );
};

export default NonFuelPage;
