import { ApiPath } from "enums";
import { Get, Post, Put } from "utils";

export const generateFuelCode = async ({ ...query }) => {
  const res = await Post(`${ApiPath.FuelCode}`, query);
  return res.data;
};

export const updateFuelCode = async ({ fuelCodeId, ...body }) => {
  const res = await Put(`${ApiPath.FuelCode}/${fuelCodeId}`, body);
  return res.data;
};

export const getFuelCodes = async ({ ...query }) => {
  const res = await Get(`${ApiPath.FuelCode}`, query);
  return res.data;
};
