import React, { useState, useCallback, useMemo, useEffect } from "react";
import locale from "localization";
import styles from "./discount-details.module.scss";
import {
  Field,
  TextField,
  Title,
  Button,
  Text,
  PopOverMenu,
  DateRange,
  DropdownMultiple,
  Image,
  PopOverList,
  Dropdown,
  Radio,
} from "components/commons";
import { InputAmount, InputLitre } from "components/field";
import { formatCurrency, formatDate } from "utils";
import { CampaignStatus } from "enums";
import classNames from "classnames";
import { RemoveIcon } from "images";
import moment from "moment";
import { prettifyShoulderedBy, prettifyVolumeCap } from "utils/pretty.utils";
import CheckIcon from "@material-ui/icons/Check";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";

const DiscountDetailsFormModule = ({ ...stateForm }) => {
  const {
    viewMode,
    addMode,
    fields,
    isFormSubmittable,
    modifyField,
    onDateRangeCb,
    loading,
    handleSubmit,
    preparedAttributes,
    attributeInput,
    removeAttribute,
    selectCohorts,
    selectedAttributeCohort,
    isAllCohort,
    stationsModal,
    importStationModal,
    setStationInputs,
    campaignStatus,
    fetchStations,
    onChangeShoulderedByCb,
    onSetVolumeCapCb,
    validateDiscountFields,
  } = stateForm;
  const [activeClicked, setActiveClicked] = useState(false);
  const ongoingCampaign = campaignStatus === CampaignStatus.Ongoing;
  const endedCampaign = campaignStatus === CampaignStatus.Ended;
  const cancelledCampaign = campaignStatus === CampaignStatus.Cancelled;

  const openTargetStations = useCallback(
    (perPage) => {
      stationsModal.show();
      fetchStations({ reset: true, perPage });
    },
    [fetchStations, stationsModal]
  );

  const openImportModal = useCallback(
    (perPage) => {
      importStationModal.show();
      fetchStations({ reset: true, perPage });
    },
    [fetchStations, importStationModal]
  );

  useEffect(() => {
    if (addMode) {
      if (fields?.discountCapType?.value === "") {
        modifyField(fields?.discountCapType?.name, {
          value: locale.day,
        });
      }
    }
  }, [addMode, modifyField, fields?.discountCapType]);

  const disabledAttribute = useMemo(() => {
    return loading || preparedAttributes.length === 0 || endedCampaign || cancelledCampaign;
  }, [loading, preparedAttributes, endedCampaign, cancelledCampaign]);

  return (
    <div className={styles.container}>
      <Title black xsmall>
        {locale.basicInformation}
      </Title>
      <Field className={styles.fieldLabel} {...fields?.campaignName}>
        {viewMode ? (
          <Text className={styles.fieldText}>{fields?.campaignName?.value}</Text>
        ) : (
          <TextField
            {...fields?.campaignName}
            onChange={modifyField}
            disabled={loading || endedCampaign || cancelledCampaign}
            inputDisabled={loading || endedCampaign || cancelledCampaign}
          />
        )}
      </Field>
      <Field className={styles.fieldLabel} {...fields?.campaignCardNameCopy}>
        {viewMode ? (
          <Text className={styles.fieldText}>{fields?.campaignCardNameCopy?.value}</Text>
        ) : (
          <TextField
            {...fields?.campaignCardNameCopy}
            onChange={modifyField}
            disabled={loading || endedCampaign || cancelledCampaign}
            inputDisabled={loading || endedCampaign | cancelledCampaign}
          />
        )}
      </Field>
      <Field className={styles.fieldLabel} {...fields?.campaignDuration}>
        {viewMode ? (
          <Text className={styles.fieldText}>
            {`${formatDate(fields?.campaignDuration?.value?.startDate)} to ${formatDate(
              fields?.campaignDuration?.value?.endDate
            )}`}
          </Text>
        ) : (
          <DateRange
            className={styles.dateRange}
            value={{
              startDate: fields?.campaignDuration?.value?.startDate,
              endDate: fields?.campaignDuration?.value?.endDate,
            }}
            minDate={moment().toDate()}
            onChange={onDateRangeCb}
            disableStartDate={endedCampaign || ongoingCampaign || cancelledCampaign}
            disableEndDate={cancelledCampaign}
          />
        )}
      </Field>

      <Field className={styles.fieldLabel} label={locale.participatingStation} horizontal>
        {viewMode ? (
          <PopOverList
            list={fields?.stations?.value?.map(({ label }) => {
              return label;
            })}
            disabled={loading || endedCampaign || cancelledCampaign}
          >
            <span className={styles.linkDetails}>
              {
                <locale.Populate
                  text={locale.numberStations}
                  items={[fields?.stations?.value?.length]}
                />
              }
            </span>
          </PopOverList>
        ) : (
          <div className={styles.attributesInput}>
            {fields?.stations?.value?.length === 0 ? (
              <>
                <Button
                  startIcon={<CheckIcon />}
                  onClick={() => {
                    const perPage = 10;
                    openTargetStations(perPage);
                  }}
                  outline
                >
                  {locale.selectStations}
                </Button>
                <Button
                  startIcon={<GetAppRoundedIcon />}
                  onClick={() => {
                    const perPage = 1000;
                    openImportModal(perPage);
                  }}
                  outline
                >
                  {locale.importCSV}
                </Button>
              </>
            ) : (
              <>
                <Button
                  startIcon={<span className="icon-pencil" />}
                  onClick={() => {
                    const perPage = 10;
                    setStationInputs(fields?.stations?.value);
                    openTargetStations(perPage);
                  }}
                  outline
                  disabled={loading || endedCampaign || cancelledCampaign}
                >
                  {locale.editStations}
                </Button>
                <Button
                  startIcon={<GetAppRoundedIcon />}
                  onClick={() => {
                    const perPage = 1000;
                    setStationInputs(fields?.stations?.value);
                    openImportModal(perPage);
                  }}
                  outline
                  disabled={loading || endedCampaign || cancelledCampaign}
                >
                  {locale.importCSV}
                </Button>
                <span className={styles.numberStationSelected}>
                  {
                    <locale.Populate
                      text={locale.stationsEligibleSelected}
                      items={[fields?.stations?.value?.length]}
                    />
                  }
                </span>
              </>
            )}
            {!endedCampaign && !cancelledCampaign ? (
              <span
                onClick={() => {
                  modifyField(fields?.stations?.name, { value: [] });
                }}
                className={styles.removeCohort}
              >
                <Image src={RemoveIcon} />
              </span>
            ) : (
              ""
            )}
          </div>
        )}
      </Field>
      <Field className={styles.fieldLabel} label={locale.shoulderedBy} horizontal>
        {viewMode ? (
          <Text className={classNames(styles.fieldText, styles.redemptionLimitDetailsDisplay)}>
            {`${prettifyShoulderedBy(fields.shoulderedBy.value)}`}
          </Text>
        ) : (
          <Dropdown
            className={styles.shoulderedBy}
            onChange={onChangeShoulderedByCb}
            disabled={loading || endedCampaign || cancelledCampaign}
            {...fields.shoulderedBy}
          />
        )}
      </Field>
      <Title black xsmall>
        {locale.discount}
      </Title>
      <Field className={styles.fieldLabel} {...fields?.diesel}>
        {viewMode ? (
          <Text className={classNames(styles.fieldText, styles.gasAmount)}>
            {formatCurrency(fields?.diesel?.value)}
          </Text>
        ) : (
          <InputAmount
            className={styles.gasAmount}
            {...fields?.diesel}
            onChange={modifyField}
            disabled={loading || endedCampaign || cancelledCampaign}
          />
        )}
      </Field>
      <Field className={styles.fieldLabel} {...fields?.gas91}>
        {viewMode ? (
          <Text className={classNames(styles.fieldText, styles.gasAmount)}>
            {formatCurrency(fields?.gas91?.value)}
          </Text>
        ) : (
          <InputAmount
            className={styles.gasAmount}
            {...fields?.gas91}
            onChange={modifyField}
            disabled={loading || endedCampaign || cancelledCampaign}
          />
        )}
      </Field>
      <Field className={styles.fieldLabel} {...fields?.gas95}>
        {viewMode ? (
          <Text className={classNames(styles.fieldText, styles.gasAmount)}>
            {formatCurrency(fields?.gas95?.value)}
          </Text>
        ) : (
          <InputAmount
            className={styles.gasAmount}
            {...fields?.gas95}
            onChange={modifyField}
            disabled={loading || endedCampaign || cancelledCampaign}
            inputDisabled={loading || endedCampaign || cancelledCampaign}
          />
        )}
      </Field>
      <Field className={styles.fieldLabel} {...fields?.gas97}>
        {viewMode ? (
          <Text className={classNames(styles.fieldText, styles.gasAmount)}>
            {formatCurrency(fields?.gas97?.value)}
          </Text>
        ) : (
          <InputAmount
            className={styles.gasAmount}
            {...fields?.gas97}
            onChange={modifyField}
            disabled={loading || endedCampaign || cancelledCampaign}
          />
        )}
      </Field>

      <Field
        className={styles.fieldLabel}
        textStyle={{ color: "#34384b", fontWeight: "bold" }}
        {...fields?.discountCapType}
        horizontal
      >
        {viewMode ? (
          <Text className={styles.fieldText}>
            {prettifyVolumeCap(fields?.discountCapType?.value)}
          </Text>
        ) : (
          <Radio
            className={styles.radioContainer}
            radioClassName={styles.radio}
            value={fields.discountCapType.value}
            options={[
              { text: locale.daily, value: locale.day },
              { text: locale.entireCampaign, value: locale.campaign },
            ]}
            onChange={onSetVolumeCapCb}
            disabled={loading || endedCampaign || cancelledCampaign}
          />
        )}
      </Field>
      <Field
        className={styles.fieldLabel}
        textStyle={{ color: "#34384b", fontWeight: "bold" }}
        {...fields?.discountCap}
      >
        {viewMode ? (
          <Text className={classNames(styles.fieldText, styles.monthlyVolumeCap)}>
            {fields?.discountCap?.value}
          </Text>
        ) : (
          <InputLitre
            {...fields?.discountCap}
            styles={{ width: "2rem" }}
            value={fields?.discountCap?.value}
            onChange={modifyField}
            innerLabel={"L"}
            isMonthlyVolume={true}
            disabled={loading || endedCampaign || cancelledCampaign}
            inputDisabled={loading || endedCampaign || cancelledCampaign}
            className={classNames(styles.volumeCap)}
          />
        )}
      </Field>

      <Title black xsmall>
        {locale.targetUsers}
      </Title>
      {attributeInput?.map((attribute) => (
        <Field
          className={styles.fieldLabel}
          label={attribute?.name}
          horizontal
          key={attribute?.attributeId}
        >
          {viewMode ? (
            selectedAttributeCohort[attribute?.attributeId]?.length && (
              <PopOverList
                list={selectedAttributeCohort[attribute?.attributeId]?.map(({ name }) => {
                  return name;
                })}
              >
                <span className={styles.linkDetails}>
                  {
                    <locale.Populate
                      text={locale.numberAttributeName}
                      items={[
                        selectedAttributeCohort[attribute?.attributeId]?.length,
                        attribute?.name,
                      ]}
                    />
                  }
                </span>
              </PopOverList>
            )
          ) : (
            <div className={styles.attributesInput}>
              <DropdownMultiple
                key={attribute?.attributeId}
                name={attribute?.attributeId?.toString()}
                options={attribute?.cohorts?.map((cohort) => {
                  return {
                    label: cohort.name,
                    value: cohort.cohortId,
                  };
                })}
                onChange={selectCohorts}
                label={attribute?.name}
                classNameDropdown={styles.cohortDropdown}
                value={selectedAttributeCohort[attribute?.attributeId]}
                total={attribute?.cohorts?.length}
                isAll={isAllCohort[attribute?.attributeId]}
                allSelectedText={[locale.all, attribute?.name].join(" ")}
                disabled={loading || endedCampaign || cancelledCampaign}
              />
              {!endedCampaign && (
                <span
                  onClick={() => {
                    removeAttribute(attribute?.name, attribute?.attributeId);
                  }}
                  className={styles.removeCohort}
                >
                  <Image src={RemoveIcon} />
                </span>
              )}
            </div>
          )}
        </Field>
      ))}

      {!viewMode && (
        <PopOverMenu
          options={preparedAttributes}
          position="top"
          disabledClick={disabledAttribute}
          setActiveClicked={setActiveClicked}
        >
          <Button
            className={styles.addCustomAttributeButton}
            outline={!activeClicked}
            primary={activeClicked}
            disabled={
              loading || preparedAttributes.length === 0 || endedCampaign || cancelledCampaign
            }
          >
            {locale.addAttribute}
          </Button>
        </PopOverMenu>
      )}

      {addMode && (
        <Button
          primary
          className={styles.continue}
          loading={loading}
          disabled={!isFormSubmittable || validateDiscountFields}
          onClick={handleSubmit}
        >
          {locale.create}
        </Button>
      )}
    </div>
  );
};

export default DiscountDetailsFormModule;
