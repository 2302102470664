import { Products, SettlementStatus } from "enums";
import PayWithPesoStatus from "enums/pay-with-peso-status";

export const payWithPesoFilterState = () => ({
  startDate: null,
  endDate: null,
  searchKey: "",
  productCodes: [Products.Diesel, Products.Gas91, Products.Gas95, Products.Gas97],

  settlementStatuses: {
    isSelectedAll: true,
    value: [
      SettlementStatus.ForProcessing,
      SettlementStatus.ForSettlement,
      SettlementStatus.Processed,
      SettlementStatus.Cancelled,
    ],
  },
  status: { isSelectedAll: true, value: [PayWithPesoStatus.Success, PayWithPesoStatus.Voided] },
  station: {
    value: [],
    isAll: true,
  },
  page: 1,
  perPage: 10,
});
