import React, { useContext } from "react";
import locale from "localization";
import { getFleetPricing, generateReportAdmin } from "apis";
import { Intro } from "components/commons";

import FleetsPricingFilter from "./fleet-pricing-filter";
import FleetsPricingList from "./fleet-pricing.list";
import { initialFilterState } from "./fleet-pricing-filter.state";
import { mapFilterToRequest, mapDataToList } from "./fleet-pricing.mapper";
import { columns } from "./fleet-pricing-columns.js";

import useDataTable from "hooks/useDataTable";
import useExport from "hooks/useExport";
import { SuccessModal } from "components/modals";
import { SessionContext } from "contexts";

const FleetPricingModule = () => {
  const { sessionId } = useContext(SessionContext);

  const { filter, search, table } = useDataTable({
    api: { api: getFleetPricing },
    filter: { initialState: initialFilterState(), mapper: mapFilterToRequest },
    table: { key: "fleets", mapper: mapDataToList, columns },
  });

  const exports = useExport({
    api: generateReportAdmin,
    hasModal: true,
    reportType: "fleet-prices",
    mappedFilterState: { searchKey: search.searchKey, sessionId, ...filter.mappedFilterState },
  });

  return (
    <>
      <SuccessModal {...exports?.successModalComponent} />
      <div>
        <Intro title={locale.fleetPrices} subtitle={locale.viewMonitorFleetPrice} />
      </div>
      <FleetsPricingFilter {...filter} {...search} {...exports} />
      <FleetsPricingList table={table} />
    </>
  );
};

export default FleetPricingModule;
