import { Autocomplete, Button, Field, Filter, SearchField } from "components/commons";
import React from "react";
import styles from "./dox-negotiated-discounts-filter.module.scss";
import locale from "localization";
import { PricingStationType } from "enums";

const { DODOX, WIDOX } = PricingStationType;

const DoxNegotiatedDiscountsFilter = ({
  filterState,
  applyFilter,
  applyClearFilter,
  applyResetFilter,
  modifyFilter,
  modifySearch,
  applySearch,
  applyClearSearch,
  searchKey,
  filterCount,
  exportReport,
  exporting,
}) => {
  const { stationTypes } = filterState;

  return (
    <div className={styles.container}>
      <div className={styles.filter}>
        <div className="flex items-center">
          <Filter
            submit={applyFilter}
            clear={applyClearFilter}
            close={applyResetFilter}
            filterCount={filterCount}
          >
            <Field
              label={locale.stationType}
              labelPosition={"left"}
              className={styles.filterInput}
              childrenClassName={styles.fieldContent}
            >
              <Autocomplete
                hasAll
                multiple
                name="stationTypes"
                value={stationTypes.value}
                options={[
                  { label: DODOX, value: DODOX },
                  { label: WIDOX, value: WIDOX },
                ]}
                onChange={(_, { value, isAll }) => {
                  modifyFilter({ stationTypes: { value, isAll } });
                }}
              />
            </Field>
          </Filter>
          <Field className={styles.search}>
            <SearchField
              restrict={false}
              placeholder={[locale.stationName, locale.stationCode].join(", ")}
              value={searchKey}
              onChange={(_, { value }) => modifySearch(value)}
              onEnter={applySearch}
              onClear={applyClearSearch}
            />
          </Field>
        </div>
        <Field className={styles.downloadButton}>
          <Button
            startIcon={<span className="icon-download" />}
            exportButton
            onClick={exportReport}
            disabled={exporting}
            loading={exporting}
          >
            {locale.export}
          </Button>
        </Field>
      </div>
    </div>
  );
};

export default DoxNegotiatedDiscountsFilter;
