import locale from "localization";
import styles from "./account-type-list-columns.module.scss";

export const accountTypeColumns = [
  {
    key: "accountTypeName",
    text: <span className={styles.heading}>{locale.accountTypeName}</span>,
    width: "15%",
  },
  {
    key: "pesoWalletSize",
    text: <span className={styles.heading}>{locale.pesoWalletSize}</span>,
    width: "10%",
  },
  {
    key: "fuelTankSize",
    text: <span className={styles.heading}>{locale.fuelTankSize}</span>,
    width: "10%",
  },
  {
    key: "cashInLimits",
    text: <span className={styles.heading}>{locale.dailyCashInLimitMonthlyCashInLimit}</span>,
    width: "10%",
  },
  {
    key: "buyFuelLimits",
    text: <span className={styles.heading}>{locale.dailyBuyFuelLimitMonthlyBuyFuelLimit}</span>,
    width: "10%",
  },
  {
    key: "payWithPesoLimits",
    text: (
      <span className={styles.heading}>{locale.dailyPayWithPesoLimitMonthlyPayWithPesoLimit}</span>
    ),
    width: "10%",
  },
  {
    key: "redemptionLimit",
    text: <span className={styles.heading}>{locale.dailyRedemptionLiterLimit}</span>,
    width: "7%",
  },
  {
    key: "actions",
    text: <span className={styles.heading}>{locale.actions}</span>,
    width: "3%",
  },
];
