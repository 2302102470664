import { generateReportAdmin, getDiscounts, updateDiscountCampaign } from "apis";
import classNames from "classnames";
import {
  DataTable,
  Intro,
  PanelButton,
  PopOverMenu,
  PopOverStation,
  Product,
  Text,
} from "components/commons";
import { CampaignStatus, CampaignType, Path, Products, Role, Portal } from "enums";
import { useApi, useFilter, useModal, useMount, useRouter } from "hooks";
import locale from "localization";
import React, { useCallback, useContext, useMemo } from "react";
import { dateTimeFormat, formatAmount } from "utils";
import { ConfirmModal, SuccessModal } from "components/modals";
import { discountColumns } from "./discount-columns";
import DiscountFilter from "./discount-filter";
import { discountFilterState } from "./discount-filter.state";
import styles from "./discount-list.module.scss";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { handleRequest } from "utils";
import useExport from "hooks/useExport";
import { FeatureFlagContext, SessionContext, UserContext } from "contexts";

const DiscountListModule = () => {
  const { hasFeature } = useContext(FeatureFlagContext);
  const confirmModal = useModal();
  const { sessionId } = useContext(SessionContext);
  const { close } = confirmModal;
  const { Ongoing, Ended, Cancelled } = CampaignStatus;
  const { Gas91, Gas95, Gas97, Diesel } = Products;
  const { history } = useRouter();

  const { user } = useContext(UserContext);
  const { role } = user?.userAccesses?.find((element) => {
    return element.portal === Portal.LOCQ;
  });

  const {
    modifyFilter,
    modifyFilters,
    filterState,
    requestState,
    submitFilter,
    filterCount,
    clearFilter,
  } = useFilter(discountFilterState());

  const {
    request: getDiscountRequest,
    loading: fetchingDiscounts,
    result: getDiscountsResult = { discountCampaigns: [], count: 0 },
  } = useApi({
    api: getDiscounts,

    pageError: true,
  });

  useMount(() => {
    fetchDiscounts();
  });

  const fetchDiscounts = useCallback(
    async (requestState) => {
      const newRequestSate = submitFilter(requestState);
      let statuses = newRequestSate.statuses;

      if (statuses) {
        const index = statuses.indexOf("all");

        if (index !== -1) {
          delete newRequestSate.statuses;
        } else {
          newRequestSate.statuses = statuses.toString();
        }
      } else {
        delete newRequestSate.statuses;
      }

      await getDiscountRequest(newRequestSate);
    },
    [getDiscountRequest, submitFilter]
  );

  const getStatus = useCallback(
    (status) => {
      return (
        <div
          className={classNames(styles.upcoming, {
            [`${styles.ongoing}`]: Ongoing === status,
            [`${styles.ended}`]: Ended === status,
            [`${styles.canceled}`]: Cancelled === status,
          })}
        >
          {status}
        </div>
      );
    },
    [Cancelled, Ended, Ongoing]
  );

  const pesoDiscount = (discounts, product) => {
    return formatAmount(
      discounts.find((f) => f.productCode === product)
        ? discounts.find((f) => f.productCode === product).discount
        : 0
    );
  };

  const cancelStatusRequest = useApi({
    api: updateDiscountCampaign,

    handleOwnError: {
      badrequest: true,
    },
  });

  const submitChangeStatus = useCallback(
    async (params) => {
      handleRequest(async () => {
        const result = await cancelStatusRequest.request({
          ...params,
        });
        if (result) {
          fetchDiscounts(requestState);
        }
      });
    },
    [cancelStatusRequest, fetchDiscounts, requestState]
  );

  const handleChangeStatus = useCallback(
    (params, name) => {
      const isCancel = params.status === CampaignStatus.Cancelled;
      confirmModal.show({
        title: isCancel ? `${locale.cancelCampaign}?` : `${locale.reactivateCampaign}?`,
        content: isCancel ? (
          <locale.Populate
            text={locale.youAreAboutToCancelCampaign}
            items={[<strong>{name}</strong>, name]}
          />
        ) : (
          locale.reactivatingCampaignMessage
        ),
        secondary: {
          text: locale.Cancel,
        },
        primary: {
          text: locale.yesCancelIt,
          onClick: async () => {
            await submitChangeStatus(params);
            close();
          },
        },
      });
    },
    [close, confirmModal, submitChangeStatus]
  );

  const preparedDiscountData = useMemo(() => {
    const { discountCampaigns } = getDiscountsResult;

    if (discountCampaigns.length > 0) {
      const preparedData = discountCampaigns.map((d) => {
        const map = new Map();
        map.set(
          "discountCampaignId",
          <Text className={classNames(styles.textStyle)} small>
            {d.discountCampaignId}
          </Text>
        );
        map.set(
          "campaignName",
          <Text className={classNames(styles.textStyle)} small>
            {d.campaignName}
          </Text>
        );
        map.set(
          "stationCount",
          <PopOverStation
            label={d.stationCount}
            station={{ id: d.discountCampaignId, count: d.stationCount }}
          />
        );
        map.set(
          "startDate",
          <Text className={classNames(styles.textStyle)} small>
            {dateTimeFormat(d.startDate)}
          </Text>
        );
        map.set(
          "endDate",
          <Text className={classNames(styles.textStyle)} small>
            {dateTimeFormat(d.endDate)}
          </Text>
        );
        map.set(
          "dateCreated",
          <Text className={classNames(styles.textStyle)} small>
            {dateTimeFormat(d.createdAt)}
          </Text>
        );
        map.set(
          "products",
          <div className={styles.product}>
            <Product cheddar>
              <Text className={styles.upperText} subtitle>
                {locale.diesel}
              </Text>
            </Product>
            <Product grass>
              <Text className={styles.upperText} subtitle>
                {locale.populate(locale.gasName, ["91"])}
              </Text>
            </Product>
            <Product salsa>
              <Text className={styles.upperText} subtitle>
                {locale.populate(locale.gasName, ["95"])}
              </Text>
            </Product>
            <Product deepBlue>
              <Text className={styles.upperText} subtitle>
                {locale.populate(locale.gasName, ["97"])}
              </Text>
            </Product>
          </div>
        );
        map.set(
          "discounts",
          <div className={classNames(styles.product, styles.right)}>
            <div>
              <Text className={styles.upperText} subtitle>
                {pesoDiscount(d.products, Diesel)}
              </Text>
            </div>
            <div>
              <Text className={styles.upperText} subtitle>
                {pesoDiscount(d.products, Gas91)}
              </Text>
            </div>
            <div>
              <Text className={styles.upperText} subtitle>
                {pesoDiscount(d.products, Gas95)}
              </Text>
            </div>
            <div>
              <Text className={styles.upperText} subtitle>
                {pesoDiscount(d.products, Gas97)}
              </Text>
            </div>
          </div>
        );
        map.set(
          "status",
          <div className={styles.city}>
            {getStatus(d.status)}
            <Text subtitle className={styles.subText}>
              {d.updatedAt !== null && dateTimeFormat(d.updatedAt)}
            </Text>
          </div>
        );
        map.set(
          "action",
          <PopOverMenu
            options={[
              {
                content: locale.viewDetails,
                onClick: () => {
                  history.push(Path.ViewDiscountCampaign, {
                    discountCampaignId: d.discountCampaignId,
                    stationCount: d.stationCount,
                  });
                },
              },
              {
                content: locale.editDetails,
                disabled: role === Role.Accounting,
                onClick: () => {
                  history.push(Path.EditDiscountCampaign, {
                    discountCampaignId: d.discountCampaignId,
                    stationCount: d.stationCount,
                  });
                },
              },
              {
                content: locale.cancel,
                onClick: () => {
                  handleChangeStatus(
                    {
                      discountCampaignId: d.discountCampaignId,
                      status: CampaignStatus.Cancelled,
                    },
                    d.campaignName
                  );
                },
                disabled: d.status === Cancelled || d.status === Ended || role === Role.Accounting,
              },
              {
                content: locale.reactivate,
                onClick: () => {
                  handleChangeStatus(
                    {
                      discountCampaignId: d.discountCampaignId,
                      status: locale.smallCaseActive,
                    },
                    d.campaignName
                  );
                },
                disabled: d.status !== Cancelled || role === Role.Accounting,
              },
            ]}
          >
            <MoreVertIcon className={styles.actionIcon} />
          </PopOverMenu>
        );
        return map;
      });
      return preparedData;
    }
    return [];
  }, [
    Cancelled,
    Diesel,
    Ended,
    Gas91,
    Gas95,
    Gas97,
    getDiscountsResult,
    getStatus,
    handleChangeStatus,
    history,
    role,
  ]);

  const onChangePageCb = useCallback(
    (page) => {
      const { requestState } = modifyFilters({ page });
      const newRequestState = prepareRequestState(requestState);
      fetchDiscounts(newRequestState);
    },
    [modifyFilters, fetchDiscounts]
  );

  const onChangePageSizeCb = useCallback(
    (perPage) => {
      const { requestState } = modifyFilters({ perPage, page: 1 });
      const newRequestState = prepareRequestState(requestState);
      fetchDiscounts(newRequestState);
    },
    [fetchDiscounts, modifyFilters]
  );

  const onSearchCb = useCallback(
    (searchKey) => {
      const { requestState } = modifyFilters({ searchKey, page: 1 });
      const newRequestState = prepareRequestState(requestState);
      fetchDiscounts(newRequestState);
    },
    [modifyFilters, fetchDiscounts]
  );

  const onDateRangeCb = useCallback(
    (value) => {
      const { startDate, endDate } = value;
      modifyFilters({ startDate, endDate, page: 1 });
    },
    [modifyFilters]
  );

  const onStatusChangeCb = useCallback(
    (statuses) => {
      modifyFilters({ statuses, page: 1 });
    },
    [modifyFilters]
  );

  const prepareRequestState = (requestState) => {
    const newRequestState = requestState;

    return newRequestState;
  };

  const PanelButtonProps = {
    discountProps: {
      onClick: () => history.push(Path.Campaigns),
    },
    voucherProps: {
      onClick: () => history.push(Path.VoucherCampaign),
    },
  };

  const exportReport = useExport({
    api: generateReportAdmin,
    reportType: "discount-campaign",
    hasModal: true,
    mappedFilterState: {
      startDate: requestState?.startDate,
      endDate: requestState?.endDate,
      discountCampaignStatuses: requestState?.statuses.join(","),
      searchKey: requestState?.searchKey,
      sessionId,
    },
  });

  return (
    <div>
      {!hasFeature("VOUCHER_V2") && (
        <PanelButton type={CampaignType.Discount} {...PanelButtonProps} />
      )}
      <div>
        {role === Role.Accounting ? (
          <Intro
            title={`${locale.discount} ${locale.campaigns}`}
            subtitle={locale.viewAndCreateDiscountCampaigns}
          />
        ) : (
          <Intro
            title={`${locale.discount} ${locale.campaigns}`}
            subtitle={locale.viewAndCreateDiscountCampaigns}
            actionText={locale.createDiscountCampaign}
            actionOnClick={() => history.push(Path.AddDiscountCampaign)}
          />
        )}
      </div>
      <div className={styles.filters}>
        <DiscountFilter
          filterState={filterState}
          onDateRangeChange={onDateRangeCb}
          onStatusChange={onStatusChangeCb}
          onSearchChange={modifyFilter}
          onSearch={onSearchCb}
          clearFilter={() => {
            const { requestState } = clearFilter();
            fetchDiscounts(requestState);
          }}
          submitFilter={() => {
            const { requestState } = modifyFilters({ page: 1 });
            fetchDiscounts(requestState);
          }}
          filterCount={filterCount}
          {...exportReport}
        />
      </div>
      <div className={styles.table}>
        <DataTable
          loading={fetchingDiscounts}
          page={filterState.page}
          pageSize={filterState.perPage}
          columns={discountColumns}
          data={preparedDiscountData}
          dataCount={getDiscountsResult.count}
          onChangePage={onChangePageCb}
          onChangePageSize={onChangePageSizeCb}
        />
      </div>
      <ConfirmModal {...confirmModal} />
      <SuccessModal {...exportReport?.successModalComponent} />
    </div>
  );
};

export default DiscountListModule;
