import locale from "localization";

export const dealerMonthlyFeeColumns = [
  {
    key: "date",
    text: `${locale.date}`,
    width: "15%",
  },
  { key: "pricelocqStations", text: locale.pricelocqStations, width: "7%" },
  { key: "plcStationsOnly", text: locale.plcStationsOnly, width: "7%" },
  { key: "plbStationsOnly", text: locale.plbStationsOnly, width: "7%" },
  { key: "plcAndPlbStations", text: locale.plcAndPlbStations, width: "7%" },
  { key: "totalMonthlyFee", text: locale.totalMonthlyFee, width: "10%" },
  { key: "dealerShoulderedMonthlyFee", text: locale.totalDealerShoulderedMonthlyFee, width: "12%" },
  { key: "totalWaivedFees", text: locale.totalWaivedFees, width: "10%" },
  { key: "totalNetFees", text: locale.totalNetFees, width: "10%" },
  { key: "billToOilco", text: locale.billToOilco, width: "10%" },
  { key: "action", text: locale.action, width: "250px" },
];
