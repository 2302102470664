import React from "react";
import classNames from "classnames";
import locale from "localization";
import { StationType } from "enums";
import { Text, Checkbox } from "../../commons";
import styles from "./select-station-type.module.scss";

const SelectStationType = ({ childrenClassName, className, onChange, values = [] }) => {
  let checkAll =
    values.indexOf(StationType.Oilco) !== -1 && values.indexOf(StationType.Dealer) !== -1;

  return (
    <div className={classNames(className, styles.container)}>
      <div className={styles.checkAll}>
        <Checkbox onChange={onChange} name="all" value={checkAll} />
        <Text className={styles.selectAllText}>{locale.chargedTo}</Text>
      </div>
      <div className={classNames(styles.checkBoxContainer, childrenClassName)}>
        <div className={styles.checkBox}>
          <Checkbox
            onChange={onChange}
            checked={values.includes(StationType.Oilco)}
            value={values.includes(StationType.Oilco)}
            name={StationType.Oilco}
          />
          <Text className={styles.typeText}>{locale.oilco}</Text>
        </div>
        <div className={styles.checkBox}>
          <Checkbox
            onChange={onChange}
            checked={values.includes(StationType.Dealer)}
            value={values.includes(StationType.Dealer)}
            name={StationType.Dealer}
          />
          <Text className={styles.typeText}>{locale.dealer}</Text>
        </div>
      </div>
    </div>
  );
};

export default SelectStationType;
