import { Autocomplete, Button, DateRange, Field, Filter, SearchField } from "components/commons";
import React from "react";
import {
  prettifyVoucherCampaignCurrency,
  prettifyVoucherCampaignGroup,
  prettifyVoucherStatus,
} from "utils/pretty.utils";
import styles from "./campaign-vouchers-filter.module.scss";
import locale from "localization";
import { VoucherCampaignCurrency } from "enums";
import VoucherStatus from "enums/voucher-status";
import SelectCampaignCreators from "components/field/select-campaign-creators/select-campaign-creators";
import VoucherCampaignGroup from "enums/campaign-group";

const CampaignVouchersFilter = ({
  filterState,
  applyFilter,
  applyClearFilter,
  applyResetFilter,
  modifyFilter,
  modifySearch,
  applySearch,
  applyClearSearch,
  searchKey,
  filterCount,
  exportReport,
  exporting,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.filter}>
        <div className="flex items-center">
          <Filter
            submit={applyFilter}
            clear={applyClearFilter}
            close={applyResetFilter}
            filterCount={filterCount}
          >
            <Field
              className={styles.date}
              labelPosition={"left"}
              label={"Date Claimed Range"}
              childrenClassName={styles.fieldContent}
            >
              <DateRange
                value={{
                  startDate: filterState.startDate,
                  endDate: filterState.endDate,
                }}
                onChange={(_, { value }) => {
                  const { startDate, endDate } = value;
                  modifyFilter({
                    startDate,
                    endDate,
                  });
                }}
              />
            </Field>
            {[
              {
                value: filterState.type,
                name: "type",
                label: locale.voucherType,
                hasAll: true,
                multiple: true,
                options: [
                  VoucherCampaignCurrency.Peso,
                  VoucherCampaignCurrency.Liter,
                  VoucherCampaignCurrency.Points,
                ].map((status) => {
                  return {
                    value: status,
                    label: prettifyVoucherCampaignCurrency(status),
                  };
                }),
              },
              {
                value: filterState.status,
                name: "status",
                label: locale.status,
                hasAll: true,
                multiple: true,
                options: [
                  VoucherStatus.Used,
                  VoucherStatus.AvailableInApp,
                  VoucherStatus.Unclaimed,
                  VoucherStatus.Expired,
                ].map((status) => {
                  return {
                    value: status,
                    label: prettifyVoucherStatus(status),
                  };
                }),
              },
              {
                value: filterState.campaignGroup,
                name: "campaignGroup",
                label: locale.campaignGroup,
                hasAll: true,
                multiple: true,
                options: [
                  VoucherCampaignGroup.Others,
                  VoucherCampaignGroup.SPIAllowance,
                  VoucherCampaignGroup.SPIAllocation,
                ].map((item) => {
                  return {
                    value: item,
                    label: prettifyVoucherCampaignGroup(item),
                  };
                }),
              },
            ].map(({ name, options, value, label, ...props }) => {
              return (
                <Field
                  label={label}
                  labelPosition={"left"}
                  className={styles.fieldSelect}
                  childrenClassName={styles.fieldContent}
                >
                  <Autocomplete
                    name={name}
                    value={value.value}
                    // hasAll
                    options={options}
                    onChange={(name, { value, isSelectedAll }) => {
                      modifyFilter({ [name]: { value, isSelectedAll } });
                    }}
                    {...props}
                  />
                </Field>
              );
            })}
            <Field
              label={locale.campaignCreators}
              labelPosition={"left"}
              className={styles.fieldSelect}
              childrenClassName={styles.fieldContent}
            >
              <SelectCampaignCreators
                {...filterState.campaignCreators}
                onChange={(name, { value, label, isAll }) => {
                  modifyFilter({ campaignCreators: { value, label, isAll } });
                }}
                disableAllOption
                // placeholder={locale.allCampaignCreators}
              />
            </Field>
          </Filter>
          <Field className={styles.search}>
            <SearchField
              restrict={false}
              placeholder={[
                locale.campaignName,
                locale.voucherCode,
                locale.voucherId,
                locale.mobileNumber,
              ].join(", ")}
              value={searchKey}
              onChange={(_, { value }) => modifySearch(value)}
              onEnter={applySearch}
              onClear={applyClearSearch}
            />
          </Field>
        </div>
        <Field className={styles.downloadButton}>
          <Button
            startIcon={<span className="icon-download" />}
            exportButton
            onClick={exportReport}
            disabled={exporting}
            loading={exporting}
          >
            {locale.export}
          </Button>
        </Field>
      </div>
    </div>
  );
};

export default CampaignVouchersFilter;
