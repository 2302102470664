import { Field, SearchField } from "components/commons";
import React from "react";
import styles from "./prepayment-filter.module.scss";
import locale from "localization";

const PrepaymentFilter = ({ filterState = {}, modifyFilter, applyClearFilter, applyFilter }) => {
  const { searchKey } = filterState;
  return (
    <div>
      <Field
        className={styles.search}
        style={{
          width: "300px",
        }}
      >
        <SearchField
          placeholder={locale.customerId}
          value={searchKey}
          searchWithSymbols={false}
          restrict={false}
          onChange={(_, { value }) => {
            modifyFilter({
              searchKey: value,
            });
          }}
          onEnter={applyFilter}
          onClear={applyClearFilter}
        />
      </Field>
    </div>
  );
};

export default PrepaymentFilter;
