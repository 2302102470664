import { Field, Filter, SearchField, Image } from "components/commons";
import { RemoveIcon } from "images";
import { SelectStationType } from "components/field";
import locale from "localization";
import React, { useCallback } from "react";
import styles from "./target-stations-modal.module.scss";

const TargetStationsFilter = ({
  filterState,
  clearFilter,
  onSearchChange,
  onSearch,
  resetFilter,
  submitFilter,
  filterCount,
  inputStationCount,
  onChangeStationType,
  onClearSelectedStation,
}) => {
  const onSearchChangeCb = useCallback(
    (name, { value }) => {
      if (onSearchChange) {
        return onSearchChange("searchKey", { value });
      }
    },
    [onSearchChange]
  );

  const onSearchCb = useCallback(
    (searchKey) => {
      if (onSearch) {
        return onSearch(searchKey);
      }
    },
    [onSearch]
  );
  const onChangeStationTypeCb = useCallback(
    (name, { value }) => {
      if (onChangeStationType) {
        return onChangeStationType(name, { value });
      }
    },
    [onChangeStationType]
  );

  return (
    <div className={styles.filter}>
      <Filter
        submit={submitFilter}
        close={resetFilter}
        clear={clearFilter}
        filterCount={filterCount}
      >
        <SelectStationType
          className={styles.productCode}
          onChange={onChangeStationTypeCb}
          values={filterState.chargedTo}
        />
      </Filter>

      <Field className={styles.search}>
        <SearchField
          placeholder={[locale.stationName, locale.stationCode].join(", ")}
          value={filterState.searchKey}
          onChange={onSearchChangeCb}
          onEnter={onSearchCb}
          restrict={false}
        />
      </Field>
      <Field className={styles.stationCounterSection}>
        {inputStationCount > 0 && (
          <div className={styles.attributeSelectedStation}>
            <span className={styles.stationCounter}>
              {<locale.Populate text={locale.numberSelected} items={[inputStationCount]} />}
            </span>
            <span
              onClick={() => {
                onClearSelectedStation();
              }}
              className={styles.removeCohort}
            >
              <Image src={RemoveIcon} />
            </span>
          </div>
        )}
      </Field>
    </div>
  );
};

export default TargetStationsFilter;
