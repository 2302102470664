import React from "react";
import { Path } from "enums";
import { Route, Switch } from "react-router-dom";
import MopsPriceModule from "modules/pricing/mops/mops-pricing.module";
import AddMopsModule from "modules/pricing/mops/mops-add.module";
const PricingPage = () => {
  return (
    <Switch>
      <Route exact path={Path.MopsPrice} component={MopsPriceModule} />
      <Route exact path={Path.AddMopsPrice} component={AddMopsModule} />
    </Switch>
  );
};

export default PricingPage;
