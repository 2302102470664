import React, { useContext } from "react";
// import { useHistory } from "react-router-dom";

import { generatePricingReportv2, getPricingMovement } from "apis";
import { Intro } from "components/commons";
import DataTableV2 from "components/commons/data-table/data-table-v2";
// import { Path } from "enums";
import useDataTable from "hooks/useDataTable";
import useExport from "hooks/useExport";
import locale from "localization";

import { priceMovementColumns } from "./price-movement-columns";
import styles from "./price-movement.module.scss";
import PriceMovementFilter from "./price-movement-filter";
import { mapDataToList, mapFilterToRequest } from "./price-movement-mapper";
import { pricingChangesFilterState } from "./price-movement-filter.state";

import { SuccessModal } from "components/modals";
import { SessionContext } from "contexts";

const PriceMovementsModule = () => {
  // const history = useHistory();
  const { sessionId } = useContext(SessionContext);

  const { filter, search, table } = useDataTable({
    api: { api: getPricingMovement },
    filter: { initialState: pricingChangesFilterState(), mapper: mapFilterToRequest },
    table: { key: "priceMovements", mapper: mapDataToList, columns: priceMovementColumns },
  });

  const exportReport = useExport({
    api: generatePricingReportv2,
    hasModal: true,
    reportType: "price-movement",
    mappedFilterState: {
      ...filter?.mappedFilterState,
      searchKey: search?.searchKey,
      sessionId,
    },
  });

  return (
    <div>
      <SuccessModal {...exportReport?.successModalComponent} />
      <div>
        <Intro
          title={locale.priceMovements}
          subtitle={locale.viewTrackPriveMovements}
          // actionText={locale.applyPriceChangesToMultipleAccount}
          // actionOnClick={() => {
          //   history.push(Path.BatchPricingChanges);
          // }}
        />
      </div>
      <div className={styles.filters}>
        <PriceMovementFilter {...filter} {...search} {...exportReport} />
      </div>
      <div className={styles.container}>
        <div className={styles.table}>
          <DataTableV2 {...table} />
        </div>
      </div>
    </div>
  );
};

export default PriceMovementsModule;
