import { ApiPath } from "enums";
import { Get, Put } from "utils";

export const getCustomers = async ({ ...query }) => {
  const res = await Get(`${ApiPath.Customers}`, query);
  return res.data;
};

export const updateCustomer = async ({ userId, ...query }) => {
  const res = await Put(`${ApiPath.UpdateCustomer(userId)}`, query);
  return res.data
}

