import React from "react";
import { Path } from "enums";
import { Route, Switch, Redirect } from "react-router-dom";
import PayWithLOCQPayModule from "modules/fleet-card/pay-with-locqpay/pay-with-locqpay.module";
import FleetCardModule from "modules/fleet-card/my-fleet-card/fleet-card.module";
import NonFuelModule from "modules/fuel/non-fuel/non-fuel.module";

const FleetCardPage = () => {
  return (
    <Switch>
      <Route exact path={Path.MyFleetCard} component={FleetCardModule} />
      <Route exact path={Path.PayWithLOCQPay} component={PayWithLOCQPayModule} />
      <Route exact path={Path.NonFuel} component={NonFuelModule} />
      <Redirect to={Path.FleetCard} />
    </Switch>
  );
};

export default FleetCardPage;
