import { ApiPath } from "enums";
import { Get, Post, Put } from "utils";

export const createLOCQPayDiscountCampaign = async ({ ...body }) => {
  const res = await Post(`${ApiPath.OutRightDiscountCampaign}`, body);
  return res.data;
};

export const getLOCQPayDiscounts = async ({ ...query }) => {
  const res = await Get(`${ApiPath.OutRightDiscountCampaign}`, query);
  return res.data;
};

export const getLOCQPayDiscount = async ({ outrightDiscountCampaignId }) => {
  const res = await Get(`${ApiPath.OutRightDiscountCampaign}/${outrightDiscountCampaignId}`);
  return res.data;
};

export const getLOCQPayDiscountCampaignStations = async ({
  outrightDiscountCampaignId,
  ...query
}) => {
  const res = await Get(
    `${ApiPath.OutRightDiscountCampaign}/${outrightDiscountCampaignId}/stations`,
    query
  );
  return res.data;
};

export const updateLOCQPayDiscountCampaign = async ({ outrightDiscountCampaignId, ...body }) => {
  const res = await Put(`${ApiPath.OutRightDiscountCampaign}/${outrightDiscountCampaignId}`, body);
  return res.data;
};
