import React from "react";

import { Autocomplete, Button, Field, Filter, SearchField } from "components/commons";
import locale from "localization";
import styles from "./dealer-monthly-fee-details-list-filter.module.scss";
import { ChargingStatus, PaymentStatus, PricingStationType, StationClass } from "enums";
import {
  prettifyChargingStatus,
  prettifyPaymentStatus,
  prettifyStationClass,
} from "utils/pretty.utils";

const DealerMonthlyFeeDetailsListFilter = ({
  filterState,
  modifyFilter,
  applyFilter,
  applyClearFilter,
  resetFilter,
  filterCount,
  searchKey,
  applySearch,
  applyClearSearch,
  modifySearch,
  exportReport,
  exporting,
}) => {
  const { stationType, stationClass, paymentStatus, chargingStatus } = filterState;
  return (
    <div className={styles.container}>
      <div className={styles.filter}>
        <Filter
          filterCount={filterCount}
          submit={applyFilter}
          close={resetFilter}
          clear={applyClearFilter}
        >
          <Field
            className={styles.filterInput}
            label={locale.stationType}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
          >
            <Autocomplete
              name="stationType"
              value={stationType.value}
              options={[
                PricingStationType.COCO,
                PricingStationType.CODO,
                PricingStationType.CODOX,
                PricingStationType.COXO,
                PricingStationType.DODO,
                PricingStationType.DODOX,
                PricingStationType.WIBCO,
                PricingStationType.WIDOX,
                PricingStationType.WILCO,
              ].map((item) => {
                return {
                  value: item,
                  label: item,
                };
              })}
              onChange={(_, { value, isAll }) => {
                modifyFilter({ stationType: { value, isAll } });
              }}
              multiple
              hasAll
            />
          </Field>
          <Field
            className={styles.filterInput}
            label={locale.stationClass}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
          >
            <Autocomplete
              name="stationClass"
              value={stationClass.value}
              options={[StationClass.EFTI, StationClass.GUI, StationClass.SCI, StationClass.NA].map(
                (item) => {
                  return {
                    value: item,
                    label: prettifyStationClass(item),
                  };
                }
              )}
              onChange={(_, { value, isAll }) => {
                modifyFilter({ stationClass: { value, isAll } });
              }}
              multiple
              hasAll
            />
          </Field>
          <Field
            className={styles.filterInput}
            label={locale.chargingStatus}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
          >
            <Autocomplete
              name="chargingStatus"
              value={chargingStatus.value}
              options={[ChargingStatus.ForCharging, ChargingStatus.Waived].map((item) => {
                return {
                  value: item,
                  label: prettifyChargingStatus(item),
                };
              })}
              onChange={(_, { value, isAll }) => {
                modifyFilter({ chargingStatus: { value, isAll } });
              }}
              multiple
              hasAll
            />
          </Field>
          <Field
            className={styles.filterInput}
            label={locale.paymentStatus}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
          >
            <Autocomplete
              name="paymentStatus"
              value={paymentStatus.value}
              options={[PaymentStatus.Paid, PaymentStatus.Pending, PaymentStatus.Waived].map(
                (item) => {
                  return {
                    value: item,
                    label: prettifyPaymentStatus(item),
                  };
                }
              )}
              onChange={(_, { value, isAll }) => {
                modifyFilter({ paymentStatus: { value, isAll } });
              }}
              multiple
              hasAll
            />
          </Field>
        </Filter>
        <Field className={styles.search}>
          <SearchField
            placeholder={[locale.redemptionStation, locale.redemptionStationBusinessName].join(
              ", "
            )}
            value={searchKey}
            onChange={(_, { value }) => modifySearch(value)}
            onEnter={applySearch}
            onClear={applyClearSearch}
            restrict={false}
          />
        </Field>
        <Field className={styles.downloadButton}>
          <Button
            startIcon={<span className="icon-download" />}
            exportButton
            onClick={exportReport}
            loading={exporting}
          >
            {locale.export}
          </Button>
        </Field>
      </div>
    </div>
  );
};

export default DealerMonthlyFeeDetailsListFilter;
