import React, { useCallback, useMemo } from "react";
import VoucherForm from "./voucher-form";
import useForm from "hooks/useForm.v2";
import { voucherFormInitialState } from "./voucher-campaign.state";
import { useApi, useModal, useMount, useRouter } from "hooks";
import locale from "localization";
import { ActionButton, PopOverList, Text } from "components/commons";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { BilledTo, CampaignStatus, Path } from "enums";
import { mapFormToRequestEdit } from "./voucher.mapper";
import { ConfirmModal } from "components/modals";
import moment from "moment";
import {
  prettifyVoucherCampaignGroup,
  prettifyVoucherClaimMethod,
  prettifyVoucherType,
  prettifyVoucherTypeSetting,
} from "utils/pretty.utils";
import { getVoucherV2, updateVoucherCampaignV2 } from "apis/campaign-program.api";
import { Grid } from "@material-ui/core";
import { formatAmount, formatNumber } from "utils";
import VoucherType from "enums/voucher-type";
import VoucherTypeSetting from "enums/voucher-type-setting";
import { uploadFile } from "apis/storage.api";
import Big from "big.js";

const EditVoucher = () => {
  const history = useHistory();
  const { query = {} } = useRouter();
  const { id: voucherCampaignId } = query;
  const form = useForm(voucherFormInitialState);
  const { initializeForm } = form;
  const confirmModal = useModal();
  const { fields, ...value } = form;
  const formFields = useMemo(() => {
    const obj = {};
    Object.keys(fields).forEach((key) => {
      const fieldKeys = Object.keys(fields[key]);
      obj[key] = {
        ...fields[key],
        visible: fieldKeys.includes("visible") ? fields[key].visible : true,
      };
    });
    return {
      ...value,
      fields: obj,
    };
  }, [fields, value]);
  const { voucherPrefix = {}, campaignName = {}, claimLimit = {} } = fields;

  const voucherRequest = useApi({
    api: getVoucherV2,
    pageError: true,
  });

  const updateRequest = useApi({
    api: updateVoucherCampaignV2,
    handleOwnError: {
      badrequest: true,
    },
  });

  const uploadToStorage = useApi({
    api: uploadFile,
  });

  useMount(async () => {
    const res = await voucherRequest.request({
      voucherCampaignId,
    });
    const {
      campaignName,
      shortDescription,
      longDescription,
      campaignImage,
      voucherType,
      voucherCampaignGroup,
      voucherSubType,
      startDate,
      endDate,
      voucherPrefix,
      claimMethod,
      voucherClaims,
      voucherStations,
      voucherValue,
      discountValueType,
      discountCap,
      minimumSpend,
      discountValue,
      status,
      billedTo,
      remarks,
      multiCodeQuantity,
      totalVoucherCount,
    } = res || {};
    if (status === CampaignStatus.Cancelled) {
      history.push(Path.ViewVoucherCampaignId(voucherCampaignId));
    }
    const isEnded = status === CampaignStatus.Ended || moment(endDate).isBefore(moment());
    const isAutoSeed = voucherSubType === VoucherTypeSetting.AutoSeed;
    const showRecipients = isAutoSeed;
    const isMultiClaimGeneric =
      voucherSubType === VoucherTypeSetting.MultiClaimGeneric ||
      voucherSubType === VoucherTypeSetting.MultiCodeGeneric;
    const isMultiCodeGeneric = voucherSubType === VoucherTypeSetting.MultiCodeGeneric;
    const isThirdPartyVoucher = voucherType === VoucherType.ThirdPartyVoucher;
    const showVoucherValue =
      !isMultiClaimGeneric && !isAutoSeed && voucherType === VoucherType.PesoVoucherCode;
    const isVoucherPesoDiscount =
      voucherType === VoucherType.PesoDiscount ||
      voucherType === VoucherType.LOCQPayPesoDiscountVoucher ||
      voucherType === VoucherType.LOCQPayLubesDiscountVoucher;
    const isOneTimeClaim = voucherSubType === VoucherTypeSetting.OneTimeClaim;

    const voucherPerClaimThirdParty = res?.voucherClaims && res?.voucherClaims[0]?.quantity;

    let billedToValue;
    let billedToOthers;
    let isBilledToOthers;

    if (billedTo) {
      isBilledToOthers =
        Object.keys(BilledTo).findIndex((key) => BilledTo[key] === billedTo) === -1;
      billedToValue = isBilledToOthers ? locale.others : billedTo;
      billedToOthers = isBilledToOthers ? billedTo : "";
    }

    initializeForm(
      {
        campaignName: {
          value: campaignName,
          content: isEnded ? campaignName : "",
        },
        shortDescription: {
          value: shortDescription,
          content: isEnded ? shortDescription : "",
        },
        longDescription: {
          value: longDescription,
          content: isEnded ? longDescription : "",
        },
        campaignImage: {
          fileName: campaignImage,
          content: isEnded ? (
            <div
              style={{
                marginLeft: "10px",
                background: "#f5f6fa",
                minHeight: "35px",
                borderRadius: "40px",
                color: "#753bbd",
                fontWeight: "500",
                display: "flex",
                alignItems: "center",
                padding: "5px",
              }}
            >
              {" "}
              {campaignImage}
            </div>
          ) : (
            ""
          ),
        },
        voucherType: {
          value: voucherType,
          content:
            `${prettifyVoucherType(voucherType)} ${
              voucherSubType ? `(${prettifyVoucherTypeSetting(voucherSubType)})` : ""
            }`.trim() || null,
          validations: {
            isRequired: false,
          },
        },
        voucherPrefix: {
          value: voucherPrefix,
          content: voucherPrefix,
          validations: {
            isRequired: false,
          },
        },
        campaignGroup: {
          value: voucherCampaignGroup,
          content: prettifyVoucherCampaignGroup(voucherCampaignGroup),
        },
        campaignDuration: {
          disableStartDate: true,
          value: {
            startDate: new Date(moment(startDate)),
            endDate: new Date(moment(endDate)),
          },
        },
        claimMethod: {
          value: claimMethod,
          content: prettifyVoucherClaimMethod(claimMethod) || "-",
          validations: {
            isRequired: false,
          },
        },
        voucherValueMultiClaim: {
          value: voucherValue,
          content: formatAmount(voucherValue),
          visible: !isVoucherPesoDiscount && isMultiClaimGeneric,
        },
        voucherValue: {
          validations: {
            isRequired: false,
          },
          visible: showVoucherValue,
          canAdd: false,
          content: (
            <div
              style={{
                padding: "10px 10px",
              }}
            >
              <Grid container spacing={3}>
                <Grid
                  sm="6"
                  style={{
                    marginBottom: "10px",
                  }}
                >
                  <Text subtitle>{locale.amount}</Text>
                </Grid>
                <Grid
                  sm="6"
                  style={{
                    marginBottom: "10px",
                  }}
                >
                  <Text subtitle>{locale.quantity}</Text>
                </Grid>

                {voucherClaims?.map((item) => {
                  return [
                    ["voucherValue", formatAmount],
                    ["quantity", formatNumber],
                  ].map((column, columnKey) => {
                    const [key, formatter] = column;
                    return (
                      <Grid sm="6" key={columnKey}>
                        {formatter(item[key])}
                      </Grid>
                    );
                  });
                })}
              </Grid>
            </div>
          ),
        },
        voucherAmount: {
          validations: {
            isRequired: false,
          },
          visible: false,
          canAdd: false,
        },
        voucherDiscountValue: {
          visible: isVoucherPesoDiscount,
          content: `${
            discountValueType === "fixed"
              ? formatAmount(discountValue)
              : formatNumber(discountValue, 0)
          }${discountValueType === "percentage" ? "%" : ""}`,
        },
        discountCap: {
          content: formatAmount(discountCap),
          visible: isVoucherPesoDiscount,
        },
        minimumSpend: {
          content: formatAmount(minimumSpend),
          visible: isVoucherPesoDiscount,
        },
        requiredReferenceStation: {
          visible: isVoucherPesoDiscount,
          label:
            voucherType.value === VoucherType.LOCQPayPesoDiscountVoucher
              ? locale.eligibleGasUpStation
              : locale.eligibleReferenceStation,
          content:
            voucherStations?.length === 1 ? (
              <Text
                strong
                style={{
                  paddingTop: "10px",
                }}
              >
                {voucherStations?.[0]?.stationName}
              </Text>
            ) : voucherStations?.length ? (
              <div
                style={{
                  paddingTop: "10px",
                }}
              >
                <PopOverList
                  list={[
                    <Text strong sm>
                      Reference Station
                    </Text>,
                    ...voucherStations?.map(({ stationName }) => stationName),
                  ]}
                >
                  <Text strong underline>{`${voucherStations?.length} Stations`}</Text>
                </PopOverList>
              </div>
            ) : (
              <Text
                strong
                style={{
                  paddingTop: "10px",
                }}
              >
                All Stations
              </Text>
            ),
        },
        recipients: {
          validations: {
            isRequired: false,
          },
          visible: showRecipients,
          content: (
            <div
              style={{
                padding: "10px 10px",
              }}
            >
              <Grid container spacing={3}>
                <Grid
                  sm={isThirdPartyVoucher ? "6" : "4"}
                  style={{
                    marginBottom: "10px",
                  }}
                >
                  <Text subtitle>{locale.priceLocqAccount}</Text>
                </Grid>
                <Grid
                  sm="4"
                  style={{
                    marginBottom: "10px",
                  }}
                >
                  <Text subtitle>
                    {isThirdPartyVoucher ? locale.thirdPartyVoucherCode : locale.voucherValue}
                  </Text>
                </Grid>

                {!isThirdPartyVoucher && (
                  <Grid
                    sm="4"
                    style={{
                      marginBottom: "10px",
                    }}
                  >
                    <Text subtitle>{locale.quantity}</Text>
                  </Grid>
                )}
                {voucherClaims?.map((item) => {
                  let obj;

                  if (isThirdPartyVoucher) {
                    obj = ["mobileNumber", "thirdPartyVoucherCode"];
                  } else {
                    obj = ["mobileNumber", "voucherValue", "quantity"];
                  }

                  return obj.map((column, columnKey) => {
                    return (
                      <Grid sm={isThirdPartyVoucher ? "6" : "4"} key={columnKey}>
                        {item[column]}
                      </Grid>
                    );
                  });
                })}
              </Grid>
            </div>
          ),
        },
        // voucherQuantity: {
        //   content: formatNumber(voucherQuantity, 0),
        //   visible: isVoucherPesoDiscount && isOneTimeClaim,
        //   value: voucherQuantity,
        // },
        voucherQuantity: {
          content: formatNumber(totalVoucherCount, 0),
          visible: isVoucherPesoDiscount && isOneTimeClaim,
          value: totalVoucherCount,
        },
        claimLimit: {
          content: formatNumber(
            totalVoucherCount && (multiCodeQuantity || voucherPerClaimThirdParty)
              ? Number(
                  Big(totalVoucherCount || 0).div(multiCodeQuantity || voucherPerClaimThirdParty)
                )
              : 0,
            0
          ),
          visible: isMultiClaimGeneric,
          validations: isMultiClaimGeneric ? { ...claimLimit.validations, isRequired: false } : {},
        },
        billedTo: {
          value: billedToValue,
          content: isEnded ? billedToValue : "",
        },
        billedToOthers: {
          value: billedToOthers,
          content: billedToOthers,
          validations: {
            isRequired: isBilledToOthers,
          },
        },
        remarks: {
          value: remarks,
          content: isEnded ? remarks : "",
        },
        multiCodeQuantity: {
          content: formatNumber(multiCodeQuantity, 0),
          visible: isMultiCodeGeneric,
          validations: isMultiCodeGeneric ? { ...claimLimit.validations, isRequired: false } : {},
        },
        thirdPartyVoucherCodes: {
          visible: isOneTimeClaim && isThirdPartyVoucher,
          content: (
            <div
              style={{
                padding: "10px 10px",
              }}
            >
              <Grid container spacing={3}>
                {voucherClaims?.map((item) => {
                  return (
                    <Grid sm={"12"}>
                      <Text strong>{item.thirdPartyVoucherCode}</Text>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          ),
        },
      },
      true
    );
  });

  const onSubmit = useCallback(
    async (params) => {
      confirmModal.show({
        title: locale.askSsaveChanges,
        content: locale.areYouSureYouWantToSaveCampaign,
        secondary: {
          text: locale.continueEditing,
          onClick: () => {
            confirmModal.close();
          },
        },
        primary: {
          text: locale.saveChanges,
          onClick: async () => {
            let campaignImageUrl = formFields?.campaignImage?.fileName || "";
            if (params.campaignImage?.length) {
              const res = await uploadToStorage.request({
                file: params.campaignImage[0],
                fileName: params.campaignImage[0].name,
              });
              campaignImageUrl = res.data.fileUrl;
            }
            const mappedParams = mapFormToRequestEdit(params);
            try {
              await updateRequest.request({
                ...mappedParams,
                voucherCampaignId,
                campaignImage: campaignImageUrl,
              });
              confirmModal.show({
                content: locale.detailHaveBeenSuccessfullyUpdated,
                title: locale.exclamatedSuccess,
                primary: {
                  text: locale.gotIt,
                  onClick: () => {
                    history.push(Path.ViewVoucherCampaignId(voucherCampaignId));
                  },
                },
                secondary: null,
              });
            } catch (e) {
              const { errorCode } = e?.data || {};
              const errors = {
                VC1000: () => {
                  voucherPrefix.onChange(voucherPrefix.name, {
                    value: voucherPrefix.value,
                    errorType: "VC1000",
                  });
                },
                VC1008: () => {
                  campaignName.onChange(campaignName.name, {
                    value: campaignName.value,
                    errorType: "VC1008",
                  });
                },
              };
              if (errors[errorCode]) {
                confirmModal.close();
                errors[errorCode]();
              } else {
                e.showError();
              }
            }
          },
        },
      });
    },
    [
      campaignName,
      voucherPrefix,
      voucherCampaignId,
      updateRequest,
      confirmModal,
      history,
      formFields?.campaignImage?.fileName,
      uploadToStorage,
    ]
  );

  return (
    <div>
      <ConfirmModal loading={updateRequest.loading || uploadToStorage.loading} {...confirmModal} />

      <VoucherForm
        title={locale.editVoucherCampaign}
        form={formFields}
        onSubmit={onSubmit}
        loading={voucherRequest.loading}
        intro={{
          actionContent: (
            <ActionButton
              right
              items={[
                {
                  text: locale.cancel,
                  onClick: () => {
                    history.push(Path.ViewVoucherCampaignId(voucherCampaignId));
                  },
                },
                {
                  text: locale.save,
                  onClick: () => {
                    form.onSubmit(onSubmit);
                  },
                },
              ]}
            />
          ),
        }}
      />
    </div>
  );
};

export default EditVoucher;
