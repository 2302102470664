const CashInChannel = {
  Card: "card",
  ManualPaymaya: "manual-paymaya",
  ManualBankDeposit: "manual-bank-deposit",
  OnlineBanking: "online-banking",
  LocqRebate: "locq-rebate",
  BNPL: "bnpl",
  NewCustomerVoucher: "new-customer-voucher",
  Voucher: "voucher",
  Referral: "referral",
  EWallet: "ewallet",
  OTC: "otc",
  GCash: "gcash",
  Billease: "billease",
  Cybersource: "cybersource",
  Brankas: "brankas",
  SevenEleven: "7eleven",
  BDO: "bdo-personal",
  Unionbank: "unionbank-personal",
  BPI: "bpi-personal",
  BPIOnlineBank: "bpi",
  Seaoil: "seaoil",
  ExpressPay: "express-pay",
  ECPay: "ecpay",
  HLhuillier: "h-lhuillier",
  RDPawnshop: "rd-pawnshop",
  TrueMoney: "true-money",
  LocqPromo: "locq-promo",
  WithholdingTaxRefund: "withholding-tax-refund",
  IntroductoryPromo: "introductory-promo",
  ShopeePay: "shopeepay",
  DragonPay: "dragonpay",
  Rebate: "rebate",
  PayWithPeso: "pay-with-peso",
  OnlineBankingBrankas: "online-bank-brankas",
  OnlineBankDragonPay: "online-bank-dragonpay",
  OnlineBankBPI: "online-bank-bpi",
  LocqPayDiscount: "locq-pay-discount",
  SeaoilReciprocity: "seaoil-reciprocity",
  SeaoilVip: "seaoil-vip",
  VipLOCQPay: "vip-locqpay",
};

export default CashInChannel;
