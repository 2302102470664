import { ApiPath } from "enums";
import { Get, Post, Put } from "utils";

export const getVehicles = async ({ fleetId, ...query }) => {
  const res = await Get(`${ApiPath.GetVehicles}/${fleetId}`, query);
  return res.data;
};

export const createVehicle = async ({ ...body }) => {
  const res = await Post(`${ApiPath.Vehicle}`, body);
  return res.data;
};

export const updateVehicle = async ({ vehicleId, ...body }) => {
  const res = await Put(`${ApiPath.Vehicle}/${vehicleId}`, body);
  return res.data;
};
