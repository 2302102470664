import React from "react";
import { MenuItem, Select as MaterialSelect } from "@material-ui/core";
import styles from "./dropdown.module.scss";
import classNames from "classnames";
import { useCallback } from "react";

const Dropdown = ({
  name,
  value = "none",
  error,
  placeholder,
  options = [],
  className,
  dropdownClassName,
  onChange,
  disabled,
  type,
  useEventAttribute = false,
  ...props
}) => {
  const onChangeCb = useCallback(
    (e) => {
      if (onChange) {
        const event = useEventAttribute ? e.target : e.target.value;
        return onChange(event, name);
      }
    },
    [onChange, useEventAttribute, name]
  );

  const inputProps = {
    InputProps: {
      placeholder: placeholder,
    },
  };
  
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 224,
      }
    },
    // Show dropdow at bottom of select
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left"
    },
    MenuListProps: {
      tabindex: "1",
      tabIndex: "1"
    }
  };
  return (
    <div
      className={classNames(styles.container, className, {
        [`${styles.simple}`]: type === "simple",
      })}
    >
      <MaterialSelect
        className={classNames(styles.dropdown, dropdownClassName, {
          [`${styles.disabled}`]: disabled,
        })}
        error={error}
        value={value}
        variant="outlined"
        placeholder={placeholder}
        SelectDisplayProps={{
          style: { fontSize: "14px", fontWeight: 500 },
          placeholder: placeholder,
        }}
        renderValue={!value && placeholder ? <div>{placeholder}</div> : null}
        onChange={disabled ? null : onChangeCb}
        disabled={disabled}
        {...props}
        {...inputProps}
        MenuProps={{ ...MenuProps, autoFocus: false }}
      >
        {placeholder && (
          <MenuItem value="none" disabled>
            <span className={styles.placeholder}>{placeholder}</span>
          </MenuItem>
        )}
        {options.map((o) => {
          return (
            <MenuItem key={o.value} value={o.value} disabled={o.disabled}>
              {o.label}
            </MenuItem>
          );
        })}
      </MaterialSelect>
    </div>
  );
};

export default Dropdown;
