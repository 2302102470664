import { useState } from "react";
import { useCallback } from "react";
import useApi from "./useApi";
import useFilterV2 from "./useFilter.v2";
import useMount from "./useMount";
import useTable from "./useTable";

const useDataTable = ({ filter, table, api, searchKey = "" }) => {
  const [search, setSearch] = useState(searchKey);
  const [requestParams, setRequestParams] = useState();

  const [isLastPage, setIsLastPage] = useState(false);

  const {
    filterState,
    filterCount,
    mappedFilterState,
    modifyFilter,
    clearFilter,
    submitFilter,
    resetFilter,
  } = useFilterV2({
    initialState: filter.initialState,
    mapper: filter.mapper,
  });

  const { key, hasColumnPicker, columns, mapper } = table;

  const {
    request,
    loading,
    result = { [key]: [], count: 0 },
  } = useApi({ api: api.api, params: api.params, pageError: true });
  const { pageState, modifyPage, data, isReverse, ...useTableProps } = useTable({
    ...table,
    hasColumnPicker,
    columns,
    mapper,
    data: result?.[key] || [],
    count: result?.count || 0,
  });

  const fetch = useCallback(
    async ({
      newPageState: newPageStateValue,
      newSearchKey,
      newFilterState,
      retry,
      apiOptions,
    }) => {
      const { isReverse: isReverseStateValue, ...newPageState } = { ...newPageStateValue };
      let requestParams = {
        ...pageState,
        ...mappedFilterState,
        searchKey: search,
      };

      if (newPageState) {
        requestParams = { ...requestParams, ...newPageState };
      }
      if (newSearchKey || newSearchKey === "") {
        requestParams = { ...requestParams, searchKey: newSearchKey };
      }
      if (newFilterState) {
        requestParams = { ...requestParams, ...newFilterState };
      }
      setRequestParams(requestParams);
      const toRequestParam = api?.mapper ? api.mapper(requestParams) : requestParams;
      const res = await request(
        {
          ...toRequestParam,
          order: isReverseStateValue ? "asc" : "desc",
        },
        retry,
        apiOptions
      );
      if (res && requestParams.page !== 1) {
        const resultData = res[key] || [];
        const returnToPage1 =
          isReverseStateValue &&
          (resultData.length !== requestParams.perPage || !resultData.length);
        const emptyLastPage = !isReverseStateValue && !resultData.length;
        const newPage = returnToPage1
          ? 1
          : emptyLastPage
          ? requestParams.page - 1
          : requestParams.page;

        if (returnToPage1 || emptyLastPage) {
          const isReverseValue = returnToPage1 ? false : isReverseStateValue;
          modifyPage({
            page: newPage,
            perPage: requestParams.perPage,
            isReverse: returnToPage1 ? isReverseValue : isReverseStateValue,
          });
          if (emptyLastPage) {
            setIsLastPage(true);
          }
          request(
            { ...toRequestParam, page: newPage, order: isReverseValue ? "asc" : "desc" },
            retry,
            apiOptions
          );
        }
      }
    },
    [request, pageState, mappedFilterState, search, key, modifyPage, api]
  );

  useMount(() => {
    if (filter.initialState?.searchKey) {
      setSearch(filter.initialState?.searchKey);
    }
    fetch({ page: 1, newSearchKey: filter.initialState?.searchKey || "" });
  });

  const refetch = useCallback(() => {
    fetch({ newPageState: modifyPage(pageState) });
  }, [modifyPage, pageState, fetch]);

  const applyPage = useCallback(
    ({ page, perPage, isReverse }) => {
      fetch({ newPageState: modifyPage({ page, perPage, isReverse }) });
    },
    [fetch, modifyPage]
  );

  const modifySearch = useCallback((searchKey) => {
    setSearch(searchKey);
  }, []);

  const applySearch = useCallback(() => {
    fetch({ newPageState: modifyPage({ page: 1, isReverse: false }) });
  }, [fetch, modifyPage]);

  const applyClearSearch = useCallback(() => {
    setSearch("");
    fetch({ newPageState: modifyPage({ page: 1, isReverse: false }), newSearchKey: "" });
  }, [fetch, modifyPage]);

  const applyFilter = useCallback(() => {
    fetch({ newPageState: modifyPage({ page: 1, isReverse: false }) });
    submitFilter();
  }, [fetch, modifyPage, submitFilter]);

  const applyClearFilter = useCallback(() => {
    clearFilter();
    fetch({
      newPageState: modifyPage({ page: 1, perPage: 10, isReverse: false }),
      newFilterState: filter.mapper(modifyFilter(filter.initialState)),
    });
  }, [fetch, modifyPage, modifyFilter, filter, clearFilter]);

  return {
    filter: {
      filterState,
      filterCount,
      modifyFilter,
      mappedFilterState,
      applyFilter,
      applyClearFilter,
      resetFilter,
    },
    search: { searchKey: search, modifySearch, applySearch, applyClearSearch },
    table: {
      ...useTableProps,
      loading,
      result,
      pageState,
      dataKey: key,
      data,
      applyPage,
      requestParams,
      fetch,
      refetch,
      isReverse,
      isLastPage,
      setIsLastPage,
      hasColumnPicker,
    },
  };
};

export default useDataTable;
