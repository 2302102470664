import { ApiPath } from "enums";
import { Get, Put, Post, Delete } from "utils";

export const getUser = async () => {
  const res = await Get(`${ApiPath.GetUser}`);
  return res.data;
};

export const getUserV2 = async () => {
  const res = await Get(`${ApiPath.GetUser}/v2`);
  return res.data;
};

export const getUsers = async (query) => {
  const res = await Get(`${ApiPath.GetUsers}`, { ...query });
  return res.data;
};

export const createUser = async ({ role, email, portal, platformType }) => {
  const res = await Post(`${ApiPath.GetUserProfile}/invite`, { email, role, portal, platformType });
  return res.data;
};

export const updateUserStatus = async ({ userAccessId, ...body }) => {
  const res = await Put(`${ApiPath.UpdateUserAccess}/${userAccessId}`, body);
  return res.data;
};

export const removeUser = async ({ userAccessId, ...body }) => {
  const res = await Delete(`${ApiPath.UpdateUserAccess}/${userAccessId}`, body);
  return res.data;
};