import { Text, Product } from "components/commons"
import { Products } from "enums";
import { prettifyProduct } from "utils/pretty.utils"
import styles from "./buy-fuel.module.scss"
import { dateTimeFormat, formatAmount, formatVolume } from "utils";

export const mapDataToList = (buyFuels) => {
  const {
    buyFuelId,
    createdAt,
    fleet,
    station,
    productCode,
    creditPrice,
    literVolume,
    totalCreditAmount,
  } = buyFuels  

  return {
    buyFuelId,
    purchasedDate: dateTimeFormat(createdAt),
    businessNameId: <div>
      <Text>{fleet?.businessName}</Text>
      <Text className={styles.subBusinessId}>{fleet?.shortName}</Text>
    </div>,
    referenceStation: station?.name,
    product: <Product
        grass={productCode === Products.Gas91}
        salsa={productCode === Products.Gas95}
        deepBlue={productCode === Products.Gas97}
        cheddar={productCode === Products.Diesel}
      >
        {prettifyProduct(productCode)}
      </Product>,
    creditPrice: formatAmount(creditPrice),
    volume: formatVolume(literVolume),
    totalCreditAmount: formatAmount(totalCreditAmount),
  };
};

export const mapFilterToRequest = (filterState) => {
  const { productCode, ...fs } = filterState
  const newProductCode = productCode !== "all" ? productCode : ""; 

  return {
    ...fs,
    productCode: newProductCode
  }
};