import React from "react";
import { Path } from "enums";
import { Route, Switch, Redirect } from "react-router-dom";
import ViewKYC from "modules/campaigns-programs/kyc-submission/view-kyc/view-kyc.module";
import KYCSubmission from "modules/campaigns-programs/kyc-submission/kyc-submission.module";

const KYCPage = () => {
  return (
    <Switch>
      <Route exact path={Path.KYCSubmissions} component={KYCSubmission} />
      <Route exact path={Path.ViewKYCSubmissions} component={ViewKYC} />
      <Redirect to={Path.ViewKYCSubmissions} />
    </Switch>
  );
};

export default KYCPage;
