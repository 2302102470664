import { PopOverMenu, Text, PopOverList, Button, Pill } from "components/commons";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { locale } from "localization/en";
import { prettifyFuelCodes, prettifyAccountStatus } from "utils/pretty.utils";
import styles from "./account-list.module.scss";
import moment from "moment";
import { DateTime, FuelCodesStatus, AccountStatus } from "enums";

export const mapDataToList = ({ stations = {} }) => {
  const {
    stationCode,
    name,
    userEmails,
    rsmRtm,
    testFuelCode,
    testFuelCodeStatus,
    redeemedAt,
    onboardedAt,
    stationAccountStatus,
    activatedAt,
  } = stations;

  return {
    stationNameCode: (
      <div>
        <Text>{name}</Text>
        <Text className={styles.subCategory}>{stationCode}</Text>
      </div>
    ),
    userEmails: (
      <PopOverList list={userEmails}>
        <Button link>{locale.view}</Button>
      </PopOverList>
    ),
    rsmRtm: (
      <PopOverList list={rsmRtm}>
        <Button link>{locale.view}</Button>
      </PopOverList>
    ),
    testFuelCode: <Text>{testFuelCode}</Text>,
    testFuelCodeStatus: (
      <>
        <Pill
          sky={testFuelCodeStatus === FuelCodesStatus.Scheduled}
          deepRed={testFuelCodeStatus === FuelCodesStatus.Deactivated}
          grass={testFuelCodeStatus === FuelCodesStatus.Active}
          cement={testFuelCodeStatus === FuelCodesStatus.Expired}
          cyan={testFuelCodeStatus === FuelCodesStatus.Redeemed}
        >
          {prettifyFuelCodes(testFuelCodeStatus)}
        </Pill>
        <Text className={styles.subCategory}>
          {redeemedAt ? moment(redeemedAt).format(DateTime.G) : "- -"}
        </Text>
      </>
    ),
    onboardedAt: <Text>{moment(onboardedAt).format(DateTime.G)}</Text>,
    stationAccountStatus: (
      <>
        <Pill
          grass={stationAccountStatus === AccountStatus.Active}
          cheddar={stationAccountStatus === AccountStatus.Pending}
          deepRed={stationAccountStatus === AccountStatus.Deactivated}
          cement={stationAccountStatus === AccountStatus.Expired}
        >
          {prettifyAccountStatus(stationAccountStatus)}
        </Pill>
        <Text className={styles.subCategory}>
          {stationAccountStatus === AccountStatus.Active
            ? moment(activatedAt).format(DateTime.G)
            : "- -"}
        </Text>
      </>
    ),
    actions: (
      <PopOverMenu
        options={[
          {
            content: locale.viewDetails,
            onClick: () => {},
          },
        ]}
      >
        <MoreVertIcon className={styles.icon} />
      </PopOverMenu>
    ),
  };
};

export const mapFilterToRequest = (filterState) => {
  const { stationAccountStatus, testFuelCodeStatus, ...fs } = filterState;
  return {
    ...fs,
    stationAccountStatus: !stationAccountStatus?.isSelectedAll
      ? stationAccountStatus.value.join(",")
      : null,
    testFuelCodeStatus: !testFuelCodeStatus?.isSelectedAll
      ? testFuelCodeStatus.value.join(",")
      : null,
  };
};
