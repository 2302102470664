import React, { useState, useRef } from "react";
import { useModal } from "hooks";
import locale from "localization";
import styles from "./discount-edit.module.scss";
import { FormMode } from "enums";
import { Path } from "enums";
import { Intro, BackLink } from "components/commons";
import DiscountDetailsModule from "../discount-details/discount-details.module";
import { useHistory } from "react-router-dom";

const DiscountEditModule = (props) => {
  const discountDetailsRef = useRef();
  const successModal = useModal();
  const [updateLoading, setUpdateLoading] = useState(false);
  const [disableUpdate, setDisableUpdate] = useState(false);
  const history = useHistory();
  const discountCampaignId = props.location.state ? props.location.state.discountCampaignId : "";
  const stationCount = props.location.state ? props.location.state.stationCount : "";

  return (
    <div>
      <BackLink text={locale.discountCampaigns} path={Path.Campaigns} />
      <div className={styles.intro}>
        <Intro
          title={locale.editDiscountCampaign}
          actionText={locale.save}
          actionOnClick={() => {
            discountDetailsRef.current.handleUpdate();
          }}
          actionClassName={styles.primarySaveButton}
          actionLoading={updateLoading}
          actionDisabled={disableUpdate}
          actionSecondaryText={locale.cancel}
          actionSecondaryOutlined
          actionSecondaryOnClick={() => {
            history.goBack();
          }}
          actionSecondaryClassName={styles.secondarySaveButton}
        />
        <DiscountDetailsModule
          pageMode={FormMode.Edit}
          {...successModal}
          discountCampaignId={discountCampaignId}
          stationCount={stationCount}
          setUpdateLoading={setUpdateLoading}
          setDisableUpdate={setDisableUpdate}
          ref={discountDetailsRef}
        />
      </div>
    </div>
  );
};

export default DiscountEditModule;
