// back-up feature flag value if firebase failed to initialize on page load
// values should be what to expect in production
export const flags = {
  PAGE_KYC_SUBMISSIONS: true,
  PAGE_PRODUCT_CONVERSION: false,
  IMPORT_KYC: false,
  PAGE_PREPAYMENT: false,
  VOUCHER_V2: true,
  PAGE_VOUCHERS: true,
};
