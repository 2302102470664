import { Status, Products, FuelCodeType } from "enums";

export const initialFilterState = () => ({
  fuelCardTypes: {
    isSelectedAll: true,
    value: [
      FuelCodeType.DriverSpecific,
      FuelCodeType.VehicleSpecific,
      FuelCodeType.DriverVehicleSpecific,
      FuelCodeType.AnyDriverAnyVehicle,
    ],
  },
  fuelCardStatuses: {
    isSelectedAll: true,
    value: [Status.Active, Status.Deactivated, Status.Expired],
  },
  productCodes: [Products.Diesel, Products.Gas91, Products.Gas95, Products.Gas97, Products.Lubes],
});
