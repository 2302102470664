import React from "react";

import { Autocomplete, Field, Filter, Button } from "components/commons";
import locale from "localization";
import styles from "./dealer-monthly-fee-filter.module.scss";
import * as moment from "moment";

const DealerMonthlyFeeFilter = ({
  filterState,
  applyFilter,
  applyClearFilter,
  resetFilter,
  filterCount,
  modifyFilter,
  exportReport,
  exporting,
}) => {
  const months = [
    {
      label: "January",
      value: "01",
    },
    {
      label: "February",
      value: "02",
    },
    {
      label: "March",
      value: "03",
    },
    {
      label: "April",
      value: "04",
    },
    {
      label: "May",
      value: "05",
    },
    {
      label: "June",
      value: "06",
    },
    {
      label: "July",
      value: "07",
    },
    {
      label: "August",
      value: "08",
    },
    {
      label: "September",
      value: "09",
    },
    {
      label: "October",
      value: "10",
    },
    {
      label: "November",
      value: "11",
    },
    {
      label: "December",
      value: "12",
    },
  ];

  const years = () => {
    const yeardiff = moment().year() - 2017;
    const yearOutput = [];
    for (let i = yeardiff; i >= 0; i--) {
      yearOutput.push({
        label: `${2017 + i}`,
        value: `${2017 + i}`,
      });
    }

    return yearOutput;
  };

  return (
    <div className={styles.container}>
      <div className={styles.filter}>
        <Filter
          filterCount={filterCount}
          submit={applyFilter}
          close={resetFilter}
          clear={applyClearFilter}
        >
          <Field
            className={styles.filterInput}
            labelPosition={"left"}
            label={locale.month}
            childrenClassName={styles.fieldContent}
          >
            <Autocomplete
              options={months}
              value={filterState?.month}
              onChange={(_, { value }) => {
                modifyFilter({ month: value });
              }}
            />
          </Field>
          <Field
            className={styles.date}
            labelPosition={"left"}
            label={locale.year}
            childrenClassName={styles.fieldContent}
          >
            <Autocomplete
              options={years()}
              value={filterState?.year}
              onChange={(_, { value }) => {
                modifyFilter({ year: value });
              }}
            />
          </Field>
        </Filter>
        <Field className={styles.downloadButton}>
          <Button
            startIcon={<span className="icon-download" />}
            exportButton
            onClick={exportReport}
            loading={exporting}
          >
            {locale.export}
          </Button>
        </Field>
      </div>
    </div>
  );
};

export default DealerMonthlyFeeFilter;
