import { ApiPath } from "enums";
import { Get } from "utils";

export const getCharging = async ({ ...query }) => {
  const res = await Get(`${ApiPath.Charging}`, query);
  return res.data;
};

export const getChargingById = async ({ id }) => {
  const res = await Get(`${ApiPath.ChargingById(id)}`);
  return res.data;
};

export const getChargingDetails = async ({ id, ...query }) => {
  const res = await Get(`${ApiPath.ChargingDetails(id)}`, query);
  return res.data;
};
