import { Products } from "enums";
export const initialFilterState = ({ searchKey }) => ({
  searchKey,
  startDate: null,
  endDate: null,
  productCodes: [Products.Diesel, Products.Gas91, Products.Gas95, Products.Gas97],
  referenceStation: {
    value: [],
    isAll: true,
  },
});
