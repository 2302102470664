import { ApiPath } from "enums";
import { Get } from "utils";

export const getPrepayments = async ({ ...query }) => {
  const res = await Get(`${ApiPath.Prepayment}`, { ...query, orderBy: "userId" });
  return res.data;
};

export const getPrepaymentsTotal = async () => {
  const res = await Get(`${ApiPath.Prepayment}/total`);
  return res.data;
};
