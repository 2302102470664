import React from "react";
import { Path } from "enums";
import { Route, Switch, Redirect } from "react-router-dom";
import CashInModule from "modules/plc/cashin/cashin.module";

const CashFlowPage = () => {
  return (
    <Switch>
      <Route exact path={Path.ConsumerCashIn} component={CashInModule} />
      <Redirect to={Path.ConsumerCashIn} />
    </Switch>
  );
};

export default CashFlowPage;
