import { AddRounded, RemoveRounded } from "@material-ui/icons";
import classNames from "classnames";
import React from "react";
import styles from "./toggle-button-add-remove.module.scss";

const ToggleButtonAddRemove = ({ active, onClick, className }) => {
  return (
    <div onClick={onClick} className={classNames(styles.container, className)}>
      {active ? (
        <RemoveRounded
          style={{
            width: "15px",
            height: "15px",
            margin: "auto",
            color: "#34384b",
          }}
        />
      ) : (
        <AddRounded
          style={{
            width: "15px",
            height: "15px",
            margin: "auto",
            color: "#34384b",
          }}
        />
      )}
    </div>
  );
};

export default ToggleButtonAddRemove;
