import React, { useState } from "react";
import { useModal, useApi } from "hooks";
import { updateSettlementHistory } from "apis";

import { ConfirmModal } from "components/modals";

import locale from "localization";
import { handleRequest, prettifySettlementStatus } from "utils";

import {
  renderMultipleSettlements,
  renderSingleSettlement,
} from "../components/settlement-collection";

import { Text } from "components/commons";
import { SettlementStatus } from "enums";

const useCancelSettlement = () => {
  const confirmModal = useModal();
  const cancelSettlementErrorModal = useModal();
  const serverErrorModal = useModal();

  const [loading, setLoading] = useState(false);

  const { request: requestUpdateSettlement, loading: settlementLoading } = useApi({
    api: updateSettlementHistory,
    handleOwnError: {
      badrequest: true,
      unauthorized: true,
    },
  });

  const request = ({
    settlementId,
    confirmationRequest,
    settlementCollectionRedirection,
    dealerSettlementStatus,
    discountVoucherStatus,
  }) => {
    if (
      dealerSettlementStatus === SettlementStatus.Processed ||
      discountVoucherStatus === SettlementStatus.Processed
    ) {
      confirmModal.show({
        title: locale.unableToCancelSettlement,
        content: (
          <>
            <Text align={"center"}>{locale.unableToCancelMessage}</Text>
            <br />
            <Text align={"center"}>{`${
              locale.dealerSettlementPayoutStatus
            }: ${prettifySettlementStatus(dealerSettlementStatus)}`}</Text>
            {/* <Text align={"center"}>{`${locale.discountVoucherStatus}: ${prettifySettlementStatus(
              discountVoucherStatus
            )}`}</Text> */}
          </>
        ),
      });
    } else {
      confirmModal.show({
        title: `${locale.cancelSettlement}?`,
        content: (
          <locale.Populate
            text={locale.cancelSettlementMessage}
            items={[<strong>{settlementId}</strong>]}
          />
        ),
        secondary: locale.back,
        primary: {
          text: locale.yesCancelSettlement,
          onClick: () =>
            handleRequest(
              async () => {
                setLoading(settlementLoading);
                await requestUpdateSettlement({
                  settlementId,
                  dealerSettlementStatus: "cancelled",
                });
                confirmModal.close();
                successCancelSettlement({ settlementId, confirmationRequest });
              },
              {
                S0004: ({ settlements }) => {
                  confirmModal.close();
                  cancelSettlementErrorModal.show({
                    title: locale.processCancelSettlementIds,
                    content: (
                      <>
                        <Text align={"center"}>{locale.processCancelSettlementIdsContent}</Text>
                        {settlements?.length > 1
                          ? renderMultipleSettlements(settlements, settlementCollectionRedirection)
                          : renderSingleSettlement(settlements[0], settlementCollectionRedirection)}
                      </>
                    ),
                  });
                },
                S0005: ({ settlements }) => {
                  confirmModal.close();
                  cancelSettlementErrorModal.show({
                    title: locale.processCancelSettlementIds,
                    content: (
                      <>
                        <Text align={"center"}>{locale.processCancelSettlementIdsContent}</Text>
                        {settlements?.length > 1
                          ? renderMultipleSettlements(settlements, settlementCollectionRedirection)
                          : renderSingleSettlement(settlements[0], settlementCollectionRedirection)}
                      </>
                    ),
                  });
                },
              }
            ),
          loading: loading,
        },
      });
    }
  };

  const successCancelSettlement = ({ settlementId, confirmationRequest }) => {
    confirmModal.show({
      closable: false,
      title: `${locale.settelmentCancelSuccess}`,
      content: (
        <locale.Populate
          text={locale.cancelSettlementSuccessMessage}
          items={[<strong>{settlementId}</strong>]}
        />
      ),
      primary: {
        text: locale.gotIt,
        onClick: () => {
          confirmationRequest();
          confirmModal.close();
        },
      },
    });
  };

  const ConfirmCancel = (props) => (
    <ConfirmModal {...confirmModal} loading={settlementLoading} {...props} />
  );

  return {
    request,
    ConfirmCancel,
    cancelSettlementErrorModal,
    serverErrorModal,
  };
};

export default useCancelSettlement;
