import React from "react";
import Image from "../image/image";
import TotalsTable from "../table/totals-table";
import Text from "../text/text";
import Title from "../title/title";
import styles from "./data-table.module.scss";
import locale from "localization";
import { GuyWithMagGlass } from "images";
import Loader from "../loader/loader";
import NextPrevPagination from "../pagination/next-prev-pagination";
import Pagination from "../pagination/pagination";

const DataTotalsTable = ({
  initialActiveColumns,
  table = {},
  columns,
  data = [],
  loading,
  count,
  ...props
}) => {
  const { pageState = {}, applyPage } = table;
  const { page = 1, perPage = 10 } = pageState;

  const isLoading = table.loading || loading;
  if (!data.length) {
    if (isLoading) {
      return <Loader open={true} />;
    }
    return (
      <div className={styles.noSearchResultRender}>
        <div>
          <Image src={GuyWithMagGlass} />
          <Title small>{locale.sorryNoResultFound}</Title>
          <Text subtitle>{locale.weCouldNotFindAMatchForSearchCriteria}</Text>
          <Text subtitle>{locale.pleaseTryADifferentOne}</Text>
        </div>
      </div>
    );
  }
  return (
    <div>
      {isLoading && (
        <div
          style={{
            position: "absolute",
            top: "0px",
          }}
        >
          <Loader open={true} />
        </div>
      )}
      <TotalsTable
        initialActiveColumns={initialActiveColumns}
        {...table}
        columns={columns}
        data={data}
      />
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          justifyContent: "end",
        }}
      >
        {count ? (
          <Pagination
            page={page}
            pageSize={perPage}
            className={styles.pagination}
            dataCount={count}
            onChangePage={(page) => {
              if (applyPage) {
                applyPage({
                  perPage,
                  page,
                });
              }
            }}
            onChangePageSize={(perPage) => {
              if (applyPage) {
                applyPage({
                  perPage,
                  page,
                });
              }
            }}
          />
        ) : (
          <div
            style={{
              width: "100%",
              justifyContent: "flex-end",
            }}
            className="flex"
          >
            <NextPrevPagination
              {...props}
              page={page}
              currentItemCount={data.length - 1}
              perPage={perPage}
              className={styles.pagination}
              onChange={({ page, perPage }) => {
                if (applyPage) {
                  applyPage({
                    perPage,
                    page,
                  });
                }
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DataTotalsTable;
