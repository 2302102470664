import { Autocomplete, Field, SearchField, Button, Filter } from "components/commons";
import React from "react";

import styles from "./feature-flags-filter.module.scss";

import { useCallback } from "react";

const FeatureFlagsFilter = ({
  filterState,
  modifyFilters,
  filterCount,
  fetch,
  clearFilter,
  submittedFilter,
  requestState,
  loading,
  showCreate,
}) => {
  const { app, status } = filterState;

  const handleSubmitFilter = useCallback(
    (param) => {
      fetch({ ...param, page: 1 });
    },
    [fetch]
  );

  return (
    <div className={styles.container}>
      <div className={styles.filter}>
        <Filter
          close={() => {
            modifyFilters(submittedFilter);
          }}
          clear={() => {
            const { requestState } = clearFilter();
            handleSubmitFilter(requestState);
          }}
          submit={() => {
            const { requestState } = modifyFilters({ page: 1 });
            handleSubmitFilter(requestState);
          }}
          filterCount={filterCount}
          // disabled={!convertFrom.length || !convertTo.length}
        >
          {[
            {
              label: "App",
              value: app,
              name: "app",
              options: [
                {
                  label: "PLC App",
                  value: "mobile",
                },
                {
                  label: "Admin Portal",
                  value: "admin",
                },
                {
                  label: "Fleet Portal",
                  value: "fleet",
                },
                {
                  label: "Station Portal",
                  value: "station",
                },
                {
                  label: "Oilco Portal",
                  value: "oilco",
                },
              ],
            },
            {
              label: "Status",
              value: status,
              name: "status",
              options: [
                {
                  label: "Enabled",
                  value: "enabled",
                },
                {
                  label: "Disabled",
                  value: "disabled",
                },
              ],
            },
          ].map(({ label, value, name, options }, key) => {
            return (
              <Field
                key={key}
                labelPosition={"left"}
                childrenClassName={styles.fieldContent}
                className={styles.fieldSelect}
                label={label}
              >
                <Autocomplete
                  capitalize={false}
                  name={name}
                  hasAll
                  value={value.value}
                  label={value.label}
                  options={[{ label: "All", value: "all" }, ...options]}
                  onChange={(name, { value, label }) => {
                    modifyFilters({
                      [name]: {
                        value,
                        label,
                      },
                    });
                  }}
                />
              </Field>
            );
          })}
        </Filter>
        <Field className={styles.search}>
          <SearchField
            placeholder={["Feature Flag Key"].join(", ")}
            value={filterState.searchKey}
            searchWithSymbols={false}
            restrict={false}
            onChange={(name, { value }) => {
              modifyFilters({
                searchKey: value,
              });
            }}
            onEnter={() => {
              handleSubmitFilter(requestState);
            }}
            onClear={() => {
              const { requestState } = modifyFilters({
                searchKey: "",
              });
              handleSubmitFilter({ ...requestState, searchKey: "" });
            }}
          />
        </Field>
      </div>
      <div
        style={{
          width: "200px",
          textAlign: "right",
        }}
      >
        <Button
          primary
          onClick={() => {
            showCreate();
          }}
          loading={loading}
        >
          Create Flag
        </Button>
      </div>
    </div>
  );
};

export default FeatureFlagsFilter;
