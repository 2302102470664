import { Field, SearchField, Button } from "components/commons";
import locale from "localization";
import styles from "./settlement-filter.module.scss";

const SettlementList = ({
  modifySearch,
  applySearch,
  applyClearSearch,
  searchKey,
  exportReport,
  exporting,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.filter}>
        <Field className={styles.search}>
          <SearchField
            placeholder={[locale.redemptionStation, locale.stationBusinessName].join(", ")}
            value={searchKey}
            onChange={(_, { value }) => modifySearch(value)}
            onEnter={applySearch}
            onClear={applyClearSearch}
          />
        </Field>
      </div>
      <Field className={styles.downloadButton}>
        <Button
          startIcon={<span className="icon-download" />}
          exportButton
          onClick={exportReport}
          loading={exporting}
        >
          {locale.export}
        </Button>
      </Field>
    </div>
  );
};

export default SettlementList;
