import { Autocomplete, Button, DateRange, Field, Filter, SearchField } from "components/commons";
import React, { useRef } from "react";
import styles from "./pay-with-peso-filter.module.scss";
import locale from "localization";
import { SelectStation } from "components/field";
import SelectProductCheckbox from "components/field/select-product-checkbox/select-product-checkbox";
import { SettlementStatus } from "enums";
import { prettifySettlementStatus } from "utils";
import { prettifyPayWithPesoStatus } from "utils/pretty.utils";
import PayWithPesoStatus from "enums/pay-with-peso-status";

const PayWithPesoFilter = ({
  filterState,
  applyFilter,
  applyClearFilter,
  applyResetFilter,
  modifyFilter,
  modifySearch,
  applySearch,
  applyClearSearch,
  searchKey,
  filterCount,
  exportReport,
  exporting,
}) => {
  const selectStationRef = useRef();

  const { productCodes, station } = filterState;

  return (
    <div className={styles.container}>
      <div className={styles.filter}>
        <Filter
          submit={applyFilter}
          clear={applyClearFilter}
          close={applyResetFilter}
          filterCount={filterCount}
        >
          <Field
            className={styles.date}
            labelPosition={"left"}
            label={locale.transactionDateRange}
            childrenClassName={styles.fieldContent}
          >
            <DateRange
              value={{
                startDate: filterState.startDate,
                endDate: filterState.endDate,
              }}
              onChange={(_, { value }) => {
                const { startDate, endDate } = value;
                modifyFilter({
                  startDate,
                  endDate,
                });
              }}
            />
          </Field>
          <Field
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
            className={styles.fieldSelect}
            label={locale.gasUpStation}
          >
            <SelectStation
              {...station}
              isMultiple
              disableAllOption
              allLabel={"Station"}
              allSelectedText={"All Station"}
              selectAllLabel={"All Station"}
              ref={selectStationRef}
              onChange={(_, { value, isAll }) => {
                modifyFilter({ station: { value, isAll } });
              }}
            />
          </Field>
          <Field
            label={locale.settlementStatus}
            labelPosition={"left"}
            className={styles.fieldSelect}
            childrenClassName={styles.fieldContent}
          >
            <Autocomplete
              name={"settlementStatuses"}
              value={filterState.settlementStatuses.value}
              hasAll
              multiple
              options={[
                {
                  label: prettifySettlementStatus(SettlementStatus.ForProcessing),
                  value: SettlementStatus.ForProcessing,
                },
                {
                  label: prettifySettlementStatus(SettlementStatus.ForSettlement),
                  value: SettlementStatus.ForSettlement,
                },
                {
                  label: prettifySettlementStatus(SettlementStatus.Processed),
                  value: SettlementStatus.Processed,
                },
                {
                  label: prettifySettlementStatus(SettlementStatus.Cancelled),
                  value: SettlementStatus.Cancelled,
                },
              ]}
              onChange={(name, { value, isSelectedAll }) => {
                modifyFilter({ [name]: { value, isSelectedAll } });
              }}
            />
          </Field>
          <Field
            label={locale.status}
            labelPosition={"left"}
            className={styles.fieldSelect}
            childrenClassName={styles.fieldContent}
          >
            <Autocomplete
              name={"status"}
              value={filterState.status.value}
              hasAll
              multiple
              options={[
                {
                  label: prettifyPayWithPesoStatus(PayWithPesoStatus.Success),
                  value: PayWithPesoStatus.Success,
                },
                {
                  label: prettifyPayWithPesoStatus(PayWithPesoStatus.Voided),
                  value: PayWithPesoStatus.Voided,
                },
              ]}
              onChange={(name, { value, isSelectedAll }) => {
                modifyFilter({ [name]: { value, isSelectedAll } });
              }}
            />
          </Field>
          <SelectProductCheckbox
            name="productCodes"
            value={productCodes}
            onChange={(name, { value }) => {
              modifyFilter({
                [name]: value,
              });
            }}
          />
        </Filter>
        <Field className={styles.search}>
          <SearchField
            restrict={false}
            placeholder={[locale.locqPayId, locale.name, locale.mobileNumber].join(", ")}
            value={searchKey}
            onChange={(_, { value }) => modifySearch(value)}
            onEnter={applySearch}
            onClear={applyClearSearch}
          />
        </Field>
        <Field className={styles.downloadButton}>
          <Button
            startIcon={<span className="icon-download" />}
            exportButton
            onClick={exportReport}
            disabled={exporting}
            loading={exporting}
          >
            {locale.export}
          </Button>
        </Field>
      </div>
    </div>
  );
};

export default PayWithPesoFilter;
