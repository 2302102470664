import { useCallback, useEffect, useRef, useState } from "react";

const useWebSocket = ({
  URL = "",
  options = {
    action: "onConnect",
  },
}) => {
  const ws = useRef(null);
  const [message, setMessage] = useState([]);
  const [sessionId, setSessionId] = useState("");

  const onMessage = useCallback((data) => {
    const messageData = JSON.parse(data);
    if (messageData?.data?.connectionId) {
      setSessionId(messageData?.data?.connectionId);
    } else {
      setMessage((oldState) => [messageData?.data, ...oldState]);
    }
  }, []);

  const onConnect = useCallback(() => {
    ws.current.send(JSON.stringify(options));
  }, [options]);

  const onDisconnect = useCallback(() => {
    ws.current.close();
  }, []);

  const wsConnect = () => {
    if (ws.current?.readyState !== WebSocket.OPEN) {
      ws.current = new WebSocket(URL);
      ws.current.addEventListener("open", onConnect);
      ws.current.addEventListener("close", onDisconnect);
      ws.current.addEventListener("message", (event) => {
        onMessage(event?.data);
      });
    }
  };

  useEffect(() => {
    wsConnect();
    return () => {
      setSessionId("");
      ws.current.close();
    };

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    let socketInterval;
    if (!socketInterval) {
      socketInterval = setInterval(() => {
        ws.current.close();
        wsConnect();
      }, 600000); // 10mins
    }
    return () => {
      clearInterval(socketInterval);
    };

    //eslint-disable-next-line
  }, []);

  return { message, sessionId, onDisconnect, onConnect };
};

export default useWebSocket;
