import { DataTable, Pill } from "components/commons";
import { DateTime } from "enums";
import Currency from "enums/currency";
import VoucherStatus from "enums/voucher-status";
import React, { useCallback } from "react";
import { useMemo } from "react";
import { formatAmount, formatDate, formatPesoWithDecimalRange, formatVolume } from "utils";
import { prettifyCurrency, prettifyVoucherStatus } from "utils/pretty.utils";
import { columns } from "./new-customer-vouchers-columns";

const NewCustomerVouchersList = ({ api, filterState, modifyFilters, fetch }) => {
  const { loading, result } = api || {};
  const { count = 20, newCustomerVoucherClaims = [] } = result || {};
  const { page, perPage } = filterState;

  // export const columns = [
  //   { key: "mobileNumber", text: locale.mobileNumber },
  //   { key: "currency", text: locale.currency },
  //   { key: "value", text: locale.value },
  //   { key: "dateRegistered", text: locale.dateRegistered },
  //   { key: "dateRedeemed", text: locale.dateRedeemed },
  //   { key: "status", text: locale.status },
  // ];

  const data = useMemo(() => {
    return newCustomerVoucherClaims.map((d) => {
      const {
        userDetail = {},
        voucherCurrency,
        voucherValue,
        dateRegistered,
        redeemedAt,
        status: voucherStatus,
      } = d;
      const { mobileNumber } = userDetail;
      let status = voucherStatus;
      // if (moment().diff(dateRegistered, "days") > 30) {
      //   status = VoucherStatus.Expired;
      // }
      const obj = {
        mobileNumber,
        currency: prettifyCurrency(voucherCurrency),
        value:
          voucherCurrency === Currency.Peso
            ? formatPesoWithDecimalRange(voucherValue)
            : voucherCurrency === Currency.Liter
            ? formatVolume(voucherValue)
            : formatAmount(voucherValue),
        dateRegistered: dateRegistered ? formatDate(dateRegistered, DateTime.G) : "--",
        dateRedeemed: redeemedAt ? formatDate(redeemedAt, DateTime.G) : "--",
        status: (
          <Pill
            cement={status === VoucherStatus.Expired}
            sky={status === VoucherStatus.Claimed}
            grass={status === VoucherStatus.Available}
          >
            {prettifyVoucherStatus(status)}
          </Pill>
        ),
      };
      const map = new Map();
      Object.keys(obj).forEach((key) => {
        map.set(key, obj[key]);
      });

      return map;
    });
  }, [newCustomerVoucherClaims]);

  const onChangePage = useCallback(
    (state) => {
      const { requestState } = modifyFilters(state);
      fetch(requestState);
    },
    [fetch, modifyFilters]
  );

  return (
    <DataTable
      loading={loading}
      page={page}
      pageSize={perPage}
      columns={columns}
      data={data}
      dataCount={count}
      onChangePage={(page) => {
        onChangePage({ page });
      }}
      onChangePageSize={(pageSize) => {
        onChangePage({ page: 1, perPage: pageSize });
      }}
    />
  );
};

export default NewCustomerVouchersList;
