import React from "react";
import { Path } from "enums";
import { Route, Switch, Redirect } from "react-router-dom";
import AccountTypesListModule from "modules/customer/account-types/account-types-list/account-types-list.module";
import AddAccountTypeModule from "modules/customer/account-types/account-type-add/account-type-add.module";
import ViewAccountTypeModule from "modules/customer/account-types/account-type-view/account-type-view.module";
import EditAccountTypeModule from "modules/customer/account-types/account-type-edit/account-type-edit.module";

const AccountTypesPage = () => {
  return (
    <Switch>
      <Route exact path={Path.AccountTypes} component={AccountTypesListModule} />
      <Route exact path={Path.AddAccountType} component={AddAccountTypeModule} />
      <Route exact path={Path.ViewAccountType} component={ViewAccountTypeModule} />
      <Route exact path={Path.EditAccountType} component={EditAccountTypeModule} />
      <Redirect to={Path.AccountTypes} />
    </Switch>
  );
};

export default AccountTypesPage;
