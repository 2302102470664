import { ApiPath } from "enums";
import { Get, Put } from "utils";

export const getFuelCards = async ({ ...query }) => {
    const res = await Get(`${ApiPath.FuelCard}`, query);
    return res.data;
};

export const updateFuelCard = async ({ id, ...body }) => {
    const res = await Put(`${ApiPath.FuelCard}/${id}`, body);
    return res.data;
};