import MoreVertIcon from "@material-ui/icons/MoreVert";
import classNames from "classnames";
import { Pill, PopOverMenu, Text } from "components/commons";
import { Path, Products, Status } from "enums";
import locale from "localization";
import { dateTimeFormat, formatAmount, prettifyFuelCodeType } from "utils";
import { prettifyProduct, prettifyStatus } from "utils/pretty.utils";
import styles from "./fleet-card.module.scss";

export const mapDataToList = ({ fuelCards, handleUpdateStatus, history }) => {
  const {
    fleet,
    cardNumber,
    fuelCardType,
    driver,
    vehicle,
    diesel,
    gas91,
    gas95,
    gas97,
    lubes,
    locqpayLimit,
    locqpayPaymentThisMonth,
    transactionLimit,
    transactionThisMonth,
    status,
    updatedAt,
  } = fuelCards;

  const options = [];

  if (status === Status.Deactivated) {
    options.push({
      removable: status === Status.Active,
      content: locale.reactivate,
      onClick: () => {
        handleUpdateStatus(Status.Active, fuelCards);
      },
    });
  }

  if (status === Status.Active) {
    options.push({
      removable: status === Status.Deactivated,
      content: locale.deactivate,
      onClick: () => {
        handleUpdateStatus(Status.Deactivated, fuelCards);
      },
    });
    options.push({
      removable: status === Status.Terminated,
      content: locale.terminate,
      onClick: () => {
        handleUpdateStatus(Status.Terminated, fuelCards);
      },
    });
  }

  options.push({
    content: locale.viewTransaction,
    onClick: () => {
      history.push(Path.PayWithLOCQPay, { cardNumber: cardNumber });
    },
  });

  return {
    businessNameId: (
      <>
        <Text>{fleet?.businessName}</Text>
        <Text className={styles.subBusinessId}>{fleet?.shortName}</Text>
      </>
    ),
    fleetCardNo: (
      <>
        <Text>{cardNumber}</Text>
        <Text className={styles.subBusinessId}>{prettifyFuelCodeType(fuelCardType)}</Text>
      </>
    ),
    assignedDriver: (
      <>
        <Text italic={!driver?.firstName}>{`${driver?.firstName ?? "Any"} ${
          driver?.lastName ?? ""
        }`}</Text>
      </>
    ),
    assignedVehicle: (
      <>
        <Text italic={!vehicle?.plateNumber}>{`${vehicle?.plateNumber ?? "Any"}`}</Text>
      </>
    ),
    product: (
      <>
        {diesel && (
          <div>
            <div className="flex items-center">
              <div className={classNames(styles.circle, styles[Products.Diesel])}></div>
              {prettifyProduct(Products.Diesel)}
            </div>
          </div>
        )}
        {gas91 && (
          <div>
            <div className="flex items-center">
              <div className={classNames(styles.circle, styles[Products.Gas91])}></div>
              {prettifyProduct(Products.Gas91)}
            </div>
          </div>
        )}
        {gas95 && (
          <div>
            <div className="flex items-center">
              <div className={classNames(styles.circle, styles[Products.Gas95])}></div>
              {prettifyProduct(Products.Gas95)}
            </div>
          </div>
        )}
        {gas97 && (
          <div>
            <div className="flex items-center">
              <div className={classNames(styles.circle, styles[Products.Gas97])}></div>
              {prettifyProduct(Products.Gas97)}
            </div>
          </div>
        )}
        {lubes && (
          <div>
            <div className="flex items-center">
              <div className={classNames(styles.circle, styles.lubricants)}></div>
              {prettifyProduct(Products.Lubes)}
            </div>
          </div>
        )}
      </>
    ),
    locqpayLimit: (
      <>
        <Text>{locqpayLimit ? formatAmount(locqpayLimit) : locale.noLimit}</Text>
      </>
    ),
    locqpayPaymentThisMonth: (
      <>
        <Text>{formatAmount(locqpayPaymentThisMonth)}</Text>
      </>
    ),
    transactionLimit: (
      <>
        <Text>{transactionLimit ? transactionLimit : locale.noLimit}</Text>
      </>
    ),
    transactionThisMonth: (
      <>
        <Text>{transactionThisMonth}</Text>
      </>
    ),
    status: (
      <>
        <Pill
          grass={status === Status.Active}
          cheddar={status === Status.Deactivated}
          deepRed={status === Status.Terminated}
        >
          {prettifyStatus(status)}
        </Pill>
        <Text className={styles.subBusinessId}>{dateTimeFormat(updatedAt)}</Text>
      </>
    ),
    actions: (
      <PopOverMenu options={options}>
        <MoreVertIcon className={styles.icon} />
      </PopOverMenu>
    ),
  };
};

export const mapFilterToRequest = (filterState) => {
  const { fuelCardStatuses, productCodes, fuelCardTypes, ...fs } = filterState;
  const newFuelCardType = fuelCardTypes.isSelectedAll ? "" : fuelCardTypes.value.toString();
  const newStatus = fuelCardStatuses.isSelectedAll ? "" : fuelCardStatuses.value.toString();

  return {
    ...fs,
    fuelCardStatuses: newStatus,
    fuelCardTypes: newFuelCardType,
    productCodes: productCodes.length < 5 ? productCodes.join(",") : null,
  };
};
