import React from "react";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { Text } from "components/commons";
import styles from "./back-link-v2.module.scss";
import { Link } from "react-router-dom";
import { useModal } from "hooks";
import locale from "localization";
import { ConfirmModal } from "components/modals";

const BackLinkV2 = ({ text, path, isSubmittable, history }) => {
  const confirmModal = useModal();
  const handleBackClick = (e) => {
    if (!isSubmittable) {
      e.preventDefault();
      confirmModal.show({
        title: locale.areYouSureYouWouldToLeaveThePage,
        content: locale.youHaveUnsavedChangesFromThisPage,
        primary: {
          text: locale.leavePage,
          onClick: () => {
            history.push(path);
            confirmModal.close();
          },
        },
        secondary: {
          text: locale.cancel,
          onClick: async () => {
            confirmModal.close();
          },
        },
      });
    }
  };
  return (
    <>
      <Link to={isSubmittable ? path : ""} onClick={(e) => handleBackClick(e)}>
        <div className={styles.backlink}>
          <span className={styles.backlinkContent}>
            <ChevronLeftIcon className={styles.icon} />
            <Text className={styles.text}>{text}</Text>
          </span>
        </div>
      </Link>
      <ConfirmModal {...confirmModal} />
    </>
  );
};

export default BackLinkV2;
