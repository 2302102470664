import React, { Fragment, useEffect, useState } from "react";
import { Route, Switch, Redirect } from "react-router";
import { Path } from "enums";
import LocqHeader from "components/commons/locq-header/header";
import LocqSidebar from "components/commons/locq-sidebar/sidebar";
import DashboardPage from "./dashboard.page";
import CashFlowPage from "./cashflow.page";
import PricingPage from "./pricing.page";
import DownloadModule from "modules/download/downloads.module";
// import DownloadDrawer from "components/commons/download-drawer/download-drawer";
import { SessionContext } from "contexts";
import useWebSocket from "hooks/useWebSockets";
import env from "environments/env";

const LocqPage = () => {
  const { message, sessionId, onDisconnect, onConnect } = useWebSocket({
    URL: env?.WEB_SOCKET_URL,
  });

  const [newNotification, setNewNotification] = useState(false);

  useEffect(() => {
    if (message.length !== 0) {
      setNewNotification(true);
    }
  }, [message]);

  return (
    <SessionContext.Provider value={{ sessionId, onDisconnect, onConnect }}>
      <Fragment>
        <LocqHeader
          setNewNotification={() => setNewNotification(false)}
          newNotification={newNotification}
        />
        <LocqSidebar />
        <div
          style={{
            marginLeft: "196px",
            marginTop: "60px",
            padding: "27px 36px",
            minWidth: "1170px",
            overflowX: "auto",
            minHeight: "100vh",
            backgroundColor: "#fff",
          }}
        >
          <Switch>
            <Route exact path={Path.LocqDashboard} component={DashboardPage} />
            <Route path={Path.LocqCashFlow} component={CashFlowPage} />
            <Route path={Path.LocqPricing} component={PricingPage} />
            <Route path={Path.LocqDownload} render={() => <DownloadModule pageType={"locq"} />} />
            <Redirect to={Path.LocqDashboard} />
          </Switch>
        </div>
      </Fragment>
    </SessionContext.Provider>
  );
};

export default LocqPage;
