import locale from "localization";
import { formatVolume } from "utils";
import Validation from "utils/validation.utils";

export const initialState = (state = {}) => {
  const {
    account = {
      value: "",
    },
    beforeProduct = {
      value: "",
    },
    afterProduct = {
      value: "",
    },
    referenceStation = {
      value: "",
    },
    referenceDate = {
      value: "",
    },
    volume = {
      value: "",
    },
  } = state;

  return {
    account: {
      name: "account",
      required: true,
      validations: [
        Validation.required({ noMessage: true }),
        (field) => {
          const { noUser } = field;
          if (noUser) {
            return {
              error: true,
              message: "Account not found",
            };
          }
          return {
            error: false,
            message: "",
          };
        },
      ],
      label: `${locale.plcMobileNumber}/ ${locale.plbBusinessId}`,
      placeholder: locale.representativeMobileNumberSample,
      maxLength: 11,
      ...account,
    },
    beforeProduct: {
      name: "beforeProduct",
      required: true,
      validations: [
        Validation.required({ noMessage: true }),
        (field) => {
          const { noData } = field;
          if (noData) {
            return {
              error: true,
              message: "Buy fuel not found",
            };
          }
          return {
            error: false,
            message: "",
          };
        },
      ],
      ...beforeProduct,
    },
    afterProduct: {
      name: "afterProduct",
      required: true,
      validations: [Validation.required({ noMessage: true })],
      ...afterProduct,
    },
    referenceStation: {
      name: "referenceStation",
      required: true,
      validations: [Validation.required({ noMessage: true })],
      ...referenceStation,
    },
    referenceDate: {
      name: "referenceDate",
      required: true,
      validations: [Validation.required({ noMessage: true })],
      placeholder: locale.setDate,
      ...referenceDate,
    },
    volume: {
      name: "volume",
      required: true,
      validations: [
        Validation.required({ noMessage: true }),
        (field) => {
          const { value, maximum = 0 } = field;
          if (Number(value) > maximum && maximum) {
            return {
              error: true,
              message: `Maximum volume input should be ${formatVolume(maximum)}`,
            };
          }
          return {
            error: false,
            message: "",
          };
        },
      ],
      ...volume,
    },
  };
};
