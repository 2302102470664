import { PopOverMenu, Text, PopOverStation, Product } from "components/commons";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import locale from "localization";
import styles from "./discount-list.module.scss";
import classNames from "classnames";
import { dateTimeFormat, formatAmount } from "utils";
import { CampaignStatus, Path, Products, Role } from "enums";

export const mapDataToList = ({
  outrightDiscountCampaigns = {},
  handleChangeStatus,
  history,
  role,
}) => {
  const {
    outrightDiscountCampaignId,
    campaignName,
    stationCount,
    startDate,
    endDate,
    createdAt,
    createdBy,
    discountCap,
    products,
    status,
    updatedAt,
  } = outrightDiscountCampaigns;
  const { Ongoing, Ended, Cancelled } = CampaignStatus;
  const { Gas91, Gas95, Gas97, Diesel } = Products;
  const options = [
    {
      content: locale.viewDetails,
      onClick: () => {
        history.push(Path.ViewLOCQPayDiscountCampaigns, {
          outrightDiscountCampaignId: outrightDiscountCampaignId,
          stationCount: stationCount,
        });
      },
    },
    {
      content: locale.cancel,
      onClick: () => {
        handleChangeStatus(
          {
            outrightDiscountCampaignId: outrightDiscountCampaignId,
            status: CampaignStatus.Cancelled,
          },
          campaignName
        );
      },
      disabled: status === Cancelled || status === Ended || role === Role.Accounting,
    },
  ];

  const getStatus = (status) => {
    return (
      <div
        className={classNames(styles.upcoming, {
          [`${styles.ongoing}`]: Ongoing === status,
          [`${styles.ended}`]: Ended === status,
          [`${styles.canceled}`]: Cancelled === status,
        })}
      >
        {status}
      </div>
    );
  };

  const pesoDiscount = (discounts, product) => {
    return formatAmount(
      discounts.find((f) => f.productCode === product)
        ? discounts.find((f) => f.productCode === product).discount
        : 0
    );
  };

  return {
    discountCampaignId: (
      <Text className={classNames(styles.textStyle)} small>
        {outrightDiscountCampaignId}
      </Text>
    ),
    campaignName: (
      <Text className={classNames(styles.textStyle)} small>
        {campaignName}
      </Text>
    ),
    stationCount: (
      <PopOverStation
        label={stationCount}
        station={{ id: outrightDiscountCampaignId, count: stationCount }}
        isLOCQPay
      />
    ),
    startDate: (
      <Text className={classNames(styles.textStyle)} small>
        {dateTimeFormat(startDate)}
      </Text>
    ),
    endDate: (
      <Text className={classNames(styles.textStyle)} small>
        {endDate !== null && dateTimeFormat(endDate)}
      </Text>
    ),
    dateCreated: (
      <>
        <Text className={classNames(styles.textStyle)} small>
          {dateTimeFormat(createdAt)}
        </Text>
        <Text subtitle className={styles.subText}>
          {createdBy}
        </Text>
      </>
    ),
    volumeCap: (
      <Text className={classNames(styles.textStyle)} small>
        {discountCap}
      </Text>
    ),
    products: (
      <div className={styles.product}>
        <Product cheddar>
          <Text className={styles.upperText} subtitle>
            {locale.diesel}
          </Text>
        </Product>
        <Product grass>
          <Text className={styles.upperText} subtitle>
            {locale.populate(locale.gasName, ["91"])}
          </Text>
        </Product>
        <Product salsa>
          <Text className={styles.upperText} subtitle>
            {locale.populate(locale.gasName, ["95"])}
          </Text>
        </Product>
        <Product deepBlue>
          <Text className={styles.upperText} subtitle>
            {locale.populate(locale.gasName, ["97"])}
          </Text>
        </Product>
      </div>
    ),
    discounts: (
      <div className={classNames(styles.product, styles.right)}>
        <div>
          <Text className={styles.upperText} subtitle>
            {pesoDiscount(products, Diesel)}
          </Text>
        </div>
        <div>
          <Text className={styles.upperText} subtitle>
            {pesoDiscount(products, Gas91)}
          </Text>
        </div>
        <div>
          <Text className={styles.upperText} subtitle>
            {pesoDiscount(products, Gas95)}
          </Text>
        </div>
        <div>
          <Text className={styles.upperText} subtitle>
            {pesoDiscount(products, Gas97)}
          </Text>
        </div>
      </div>
    ),
    status: (
      <div className={styles.city}>
        {getStatus(status)}
        <Text subtitle className={styles.subText}>
          {updatedAt !== null && dateTimeFormat(updatedAt)}
        </Text>
      </div>
    ),
    action: (
      <PopOverMenu options={options}>
        <MoreVertIcon className={styles.icon} />
      </PopOverMenu>
    ),
  };
};

export const mapFilterToRequest = (filterState) => {
  const { ...fs } = filterState;

  return {
    ...fs,
    statuses: fs.statuses.join(","),
  };
};
