import locale from "localization";

export const initialState = {
  lastName: {
    name: "lastName",
    value: "",
    placeholder: "",
    disabled: true,
    label: locale.lastName,
  },
  firstName: {
    name: "firstName",
    value: "",
    placeholder: "",
    disabled: true,
    label: locale.firstName,
  },
  email: {
    name: "email",
    placeholder: "",
    value: "",
    disabled: true,
    maxLength: 75,
    label: locale.emailAddress,
  },
  password: {
    name: "password",
    placeholder: "",
    value: "",
    maxLength: 75,
    message: locale.use8CharWithMix,
    label: locale.password,
  },
  confirmPassword: {
    name: "confirmPassword",
    placeholder: "",
    value: "",
    maxLength: 75,
    label: locale.confirmPassword,
  },
};
