import React from "react";
import { useModal } from "hooks";
import locale from "localization";
import styles from "./discount-add.module.scss";
import { FormMode } from "enums";
import { Path } from "enums";
import { Intro, BackLink } from "components/commons";
import DiscountDetailsModule from "../discount-details/discount-details.module";

const DiscountAddModule = () => {
  const successModal = useModal();

  return (
    <div>
      <BackLink text={locale.locqpayCampaign} path={Path.LOCQPayDiscountCampaigns} />
      <div className={styles.intro}>
        <Intro black title={locale.createLOCQPayDiscountCampaign} />
        <DiscountDetailsModule pageMode={FormMode.Add} {...successModal} />
      </div>
    </div>
  );
};

export default DiscountAddModule;
