import React from "react";
import { Path } from "enums";
import { Route, Switch, Redirect } from "react-router-dom";
import UserAttributesListModule from "modules/customer/user-attributes/user-attributes-list/user-attributes-list.module";
import AddUserAttributeModule from "modules/customer/user-attributes/user-attribute-add/user-attribute-add.module";
import ViewUserAttributeModule from "modules/customer/user-attributes/user-attribute-view/user-attribute-view.module";
import EditUserAttributeModule from "modules/customer/user-attributes/user-attribute-edit/user-attribute-edit.module";

const AttributesPage = () => {
  return (
    <Switch>
      <Route exact path={Path.Attributes} component={UserAttributesListModule} />
      <Route exact path={Path.AddUserAttribute} component={AddUserAttributeModule} />
      <Route exact path={Path.ViewUserAttribute} component={ViewUserAttributeModule} />
      <Route exact path={Path.EditUserAttribute} component={EditUserAttributeModule} />
      <Redirect to={Path.Attributes} />
    </Switch>
  );
};

export default AttributesPage;
