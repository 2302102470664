import { Autocomplete, DateRange, Field, SearchField, Button, Filter } from "components/commons";
import React from "react";
import styles from "./product-conversion-filter.module.scss";
import locale from "localization";
import { productOptions } from "data/select-options";
import { useCallback } from "react";
import { useApi } from "hooks";
import { exportProductConversion } from "apis/product-conversion.api";

const ProductConversionFilter = ({
  filterState,
  modifyFilter,
  filterCount,
  applyFilter,
  applyClearFilter,
  searchKey,
  modifySearch,
  applySearch,
  applyClearSearch,
  requestParams,
  resetFilter,
}) => {
  const { convertFrom, convertTo } = filterState;

  const { loading: exporting, request: exportData } = useApi({
    api: exportProductConversion,
  });

  const handleExport = useCallback(() => {
    const { downloadUrl } = exportData(requestParams);
    if (downloadUrl) window.open(downloadUrl, "_blank");
  }, [exportData, requestParams]);

  return (
    <div className={styles.container}>
      <div className={styles.filter}>
        <Filter
          close={resetFilter}
          clear={applyClearFilter}
          submit={applyFilter}
          filterCount={filterCount}
          disabled={!convertFrom.length || !convertTo.length}
        >
          <Field
            className={styles.date}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
            label={locale.conversionDate}
          >
            <DateRange
              value={{
                startDate: filterState.startDate,
                endDate: filterState.endDate,
              }}
              onChange={(name, { value }) => {
                const { startDate, endDate } = value;
                modifyFilter({
                  startDate,
                  endDate,
                });
              }}
            />
          </Field>
          {[
            {
              label: locale.convertFrom,
              value: convertFrom,
              name: "convertFrom",
              options: productOptions,
            },
            {
              label: locale.convertTo,
              value: convertTo,
              name: "convertTo",
              options: productOptions,
            },
          ].map(({ label, value, name, options }, key) => {
            return (
              <Field
                key={key}
                labelPosition={"left"}
                childrenClassName={styles.fieldContent}
                className={styles.fieldSelect}
                label={label}
              >
                <Autocomplete
                  capitalize={false}
                  name={name}
                  hasAll
                  value={value}
                  options={[{ label: locale.all, value: "" }, ...options]}
                  onChange={(name, { value }) => {
                    modifyFilter({ [name]: value });
                  }}
                />
              </Field>
            );
          })}
        </Filter>
        <Field className={styles.search}>
          <SearchField
            placeholder={[
              locale.accountName,
              locale.productConversionId,
              locale.priceLocqAccount,
              locale.buyFuelId,
            ].join(", ")}
            value={searchKey}
            searchWithSymbols={false}
            restrict={false}
            onChange={(_, { value }) => {
              modifySearch(value);
            }}
            onEnter={applySearch}
            onClear={applyClearSearch}
          />
        </Field>
      </div>
      <Button
        startIcon={<span className="icon-download" />}
        exportButton
        onClick={handleExport}
        loading={exporting}
      >
        {locale.export}
      </Button>
    </div>
  );
};

export default ProductConversionFilter;
