const Add = "/add";
const Edit = "/edit";
const View = "/view";
const Accounts = "/accounts";
const Dashboard = "/dashboard";
const Download = "/download";
const CashIn = "/cashin";
const Fuel = "/fuel";
const Buy = "/buy";
const CashFlow = "/cashflow";
const Prepayment = "/prepayment";
const Pricing = "/pricing";
const Redemption = "/redemption";
const NonFuel = "/non-fuel";
const PayWithLocqPay = "/pay-with-locqpay";
const Fleet = "/fleet";
const PricingChanges = "/pricing-changes";
const Settlement = "/settlement";
const DealerMonthlyFee = "/dealer-monthly-fee";
const RFID = "/rfid";
const OtherServices = "/other-services";
const LubeServOnWheelsBookings = "/lubeserv-on-wheels-bookings";
const LubeServOnSiteBookings = "/lubeserv-on-site-bookings";
const AccessAuthorization = "/access-authorization";

const Business = "/business";
const BusinessCashFlow = `${Business}${CashFlow}`;
const BusinessFuel = `${Business}${Fuel}`;
const BusinessAccounts = `${Business}${Accounts}`;
const BusinessFleetAccounts = `${BusinessAccounts}${Fleet}`;
const BusinessFleetAccountsDetails = `${BusinessAccounts}${Fleet}`;
const BusinessFleet = `${Business}${Fleet}`;

const BusinessPricing = `${Business}${Pricing}`;

const BusinessPaths = {
  PricelocqBusiness: `${Business}`,
  Download: `${Business}${Download}`,
  Accounts: `${BusinessAccounts}`,
  Dashboard: `${Business}${Dashboard}`,
  CashFlow: `${BusinessCashFlow}`,
  CashIn: `${BusinessCashFlow}${CashIn}`,
  ManualCashIn: `${BusinessCashFlow}${CashIn}/manual`,
  Fuel: `${BusinessFuel}`,
  BuyFuel: `${BusinessFuel}${Buy}`,
  FuelCode: `${BusinessFuel}/code`,
  Redemption: `${BusinessFuel}${Redemption}`,
  NonFuel: `${BusinessFleet}${NonFuel}`,
  FleetAccounts: `${BusinessFleetAccounts}/list`,
  ViewFleetDetails: `${BusinessFleetAccountsDetails}/:id/:plbType`,
  ViewFleetDetailsById: (id, plbType) => `${BusinessFleetAccountsDetails}/${id}/${plbType}`,
  ViewFleetAccount: `${BusinessFleetAccountsDetails}/:id/:plbType/info${View}`,
  ViewFleetAccountById: (id, plbType) =>
    `${BusinessFleetAccountsDetails}/${id}/${plbType}/info${View}`,
  ViewFleetPricing: `${BusinessFleetAccountsDetails}/:id/pricing${View}`,
  ViewFleetPricingById: (id) => `${BusinessFleetAccountsDetails}/${id}/pricing${View}`,
  AddFleetAccount: `${BusinessFleetAccountsDetails}${Add}`,
  EditFleetAccount: `${BusinessFleetAccountsDetails}/:id/info${Edit}`,
  EditFleetAccountById: (id) => `${BusinessFleetAccountsDetails}/${id}/info${Edit}`,
  StationAccounts: `${BusinessAccounts}/station`,
  Pricing: `${BusinessPricing}`,
  FleetPrices: `${BusinessPricing}${Fleet}`,
  PriceMovements: `${BusinessPricing}/price-movements`,
  PricingChanges: `${BusinessPricing}${PricingChanges}`,
  BatchPricingChanges: `${BusinessPricing}${PricingChanges}/batch`,
  DoxNegotiatedDiscounts: `${BusinessPricing}/dox-negotiated-discounts`,
  BusinessAccessAuthorization: `${Business}${AccessAuthorization}`,
  BusinessUserAccess: `${Business}${AccessAuthorization}/user-access`,
  FleetCard: `${BusinessFleet}`,
  PayWithLOCQPay: `${BusinessFleet}${PayWithLocqPay}`,
  MyFleetCard: `${BusinessFleet}/fleet-card`,
};

const Consumer = "/consumer";
const ConsumerCashFlow = `${Consumer}${CashFlow}`;
const ConsumerFuel = `${Consumer}${Fuel}`;
const ConsumerSettlement = `${Consumer}${CashFlow}${Settlement}s`;
const ConsumerProcessSettlement = `${Consumer}${CashFlow}${Settlement}s/process`;
const ConsumerSettlementHistory = `${Consumer}${CashFlow}${Settlement}/history`;
const ConsumerConversion = `${ConsumerFuel}/conversion`;
const ConsumerCustomers = `${Consumer}/customers`;
const ConsumerCustomerAttributes = `${ConsumerCustomers}/attributes`;
const ConsumerCustomerTypes = `${ConsumerCustomers}/types`;
const ConsumerCampaigns = `${Consumer}/campaigns-and-programs`;
const ConsumerCampaign = `${ConsumerCampaigns}/campaign`;
const ConsumerDiscountCampaigns = `${ConsumerCampaign}/discount`;
const ConsumerVoucherCampaigns = `${ConsumerCampaign}/voucher`;
const ConsumerLOCQPayDiscountCampaigns = `${ConsumerCampaign}/locqpay-discount`;
const ConsumerVouchers = `${ConsumerCampaigns}/vouchers`;
const ConsumerKYC = `${ConsumerCustomers}/kyc`;
const ConsumerPricing = `${Consumer}${Pricing}`;

const ConsumerPaths = {
  ConsumerDashboard: `${Consumer}${Dashboard}`,
  ConsumerDownload: `${Consumer}${Download}`,
  ConsumerCashFlow: `${ConsumerCashFlow}`,
  ConsumerCashIn: `${ConsumerCashFlow}${CashIn}`,
  Customers: `${ConsumerCustomers}`,
  CustomersList: `${ConsumerCustomers}/list`,
  Attributes: `${ConsumerCustomerAttributes}`,
  AddUserAttribute: `${ConsumerCustomerAttributes}${Add}`,
  ViewUserAttribute: `${ConsumerCustomerAttributes}${View}`,
  EditUserAttribute: `${ConsumerCustomerAttributes}${Edit}`,
  AccountTypes: `${ConsumerCustomerTypes}`,
  AddAccountType: `${ConsumerCustomerTypes}${Add}`,
  ViewAccountType: `${ConsumerCustomerTypes}${View}`,
  EditAccountType: `${ConsumerCustomerTypes}${Edit}`,
  PricelocqConsumer: `${Consumer}`,
  Campaigns: `${ConsumerCampaigns}`,
  Campaign: ConsumerCampaign,
  DiscountCampaign: `${ConsumerDiscountCampaigns}`,
  AddDiscountCampaign: `${ConsumerDiscountCampaigns}${Add}`,
  EditDiscountCampaign: `${ConsumerDiscountCampaigns}${Edit}`,
  ViewDiscountCampaign: `${ConsumerDiscountCampaigns}${View}`,
  VoucherCampaign: `${ConsumerVoucherCampaigns}`,
  AddVoucherCampaign: `${ConsumerVoucherCampaigns}${Add}`,
  EditVoucherCampaign: `${ConsumerVoucherCampaigns}${Edit}`,
  ViewVoucherCampaign: `${ConsumerVoucherCampaigns}${View}`,
  ViewVoucherCampaignDetails: `${ConsumerVoucherCampaigns}/:id`,
  ViewVoucherCampaignId: (id) => `${ConsumerVoucherCampaigns}/${id}`,
  EditVoucherCampaignDetails: `${ConsumerVoucherCampaigns}/:id/edit`,
  EditVoucherCampaignId: (id) => `${ConsumerVoucherCampaigns}/${id}/edit`,
  CampaignVouchers: `${ConsumerCampaigns}/campaign-vouchers`,
  Vouchers: `${ConsumerVouchers}`,
  UniqueVouchers: `${ConsumerVouchers}/unique`,
  GenericVouchers: `${ConsumerVouchers}/generic`,
  NewCustomerVouchers: `${ConsumerCampaigns}/ncv`,
  Programs: `${ConsumerCampaigns}/programs`,
  ConsumerFuelPage: `${ConsumerFuel}`,
  ConsumerBuyFuel: `${ConsumerFuel}${Buy}`,
  ConsumerRedemption: `${ConsumerFuel}${Redemption}`,
  ConsumerManualRedemption: `${ConsumerFuel}${Redemption}/manual`,
  ProductConversion: `${ConsumerConversion}`,
  CreateProductConversion: `${ConsumerConversion}/create`,
  ConsumerPricingPage: `${ConsumerPricing}`,
  ConsumerPriceBuildup: `${ConsumerPricing}/build-up`,
  KYCSubmissions: `${ConsumerKYC}`,
  ViewKYCSubmissions: `${ConsumerKYC}${View}/:id`,
  ViewKYCSubmissionsId: (id) => `${ConsumerKYC}${View}/${id}`,
  ConsumerRFID: `${Consumer}${OtherServices}${RFID}`,
  ConsumerLubeServOnWheelsBookings: `${Consumer}${OtherServices}${LubeServOnWheelsBookings}`,
  ConsumerLubeServOnSiteBookings: `${Consumer}${OtherServices}${LubeServOnSiteBookings}`,
  ConsumerOtherServices: `${Consumer}${OtherServices}`,
  ConsumerSettlement,
  ConsumerProcessSettlement,
  ConsumerSettlementHistory,
  LocqPayForFuel: `${Consumer}${OtherServices}/locqpay-for-fuel`,
  ConsumerLOCQPayNonFuel: `${Consumer}${OtherServices}/non-fuel`,
  ConsumerAccessAuthorization: `${Consumer}${AccessAuthorization}`,
  ConsumerUserAccess: `${Consumer}${AccessAuthorization}/user-access`,
  LOCQPayDiscountCampaigns: `${ConsumerLOCQPayDiscountCampaigns}`,
  AddLOCQPayDiscountCampaigns: `${ConsumerLOCQPayDiscountCampaigns}${Add}`,
  EditLOCQPayDiscountCampaigns: `${ConsumerLOCQPayDiscountCampaigns}${Edit}`,
  ViewLOCQPayDiscountCampaigns: `${ConsumerLOCQPayDiscountCampaigns}${View}`,
};

const Locq = "/locq";
const LocqCashFlow = `${Locq}${CashFlow}`;
const LocqSettlement = `${Locq}${CashFlow}/dealer${Settlement}s`;
const LocqPrepayment = `${Locq}${CashFlow}${Prepayment}`;
const LocqProcessSettlement = `${Locq}${CashFlow}/dealer${Settlement}s/process`;
const LocqSettlementHistory = `${Locq}${CashFlow}/dealer${Settlement}/history`;
const LocqUpdateSettlement = `${Locq}${CashFlow}/dealer${Settlement}/history/update`;
const LocqOilcoSettlementHistory = `${Locq}${CashFlow}/oilco${Settlement}/history`;
const LocqDealerMonthlyFee = `${Locq}${CashFlow}${DealerMonthlyFee}`;
const LocqPricing = `${Locq}${Pricing}`;
const LocqMopsPricing = `${LocqPricing}/mops`;

const LocqPaths = {
  LocqDashboard: `${Locq}${Dashboard}`,
  LocqDownload: `${Locq}${Download}`,
  LocqCashFlow: `${LocqCashFlow}`,
  LocqSettlement: `${LocqSettlement}`,
  LocqPrepayment: LocqPrepayment,
  ProcessSettlement: `${LocqProcessSettlement}`,
  LocqSettlementHistory: `${LocqSettlementHistory}`,
  UpdateSettlement: `${LocqUpdateSettlement}`,
  LocqOilcoSettlementHistory: `${LocqOilcoSettlementHistory}`,
  ViewSettlementHistoryById: (id) => `${LocqSettlementHistory}/${id}${View}`,
  ViewSettlementHistory: `${LocqSettlementHistory}/:id${View}`,
  ViewOilcoSettlementHistoryById: (id) => `${LocqOilcoSettlementHistory}/${id}${View}`,
  ViewOilcoSettlementHistory: `${LocqOilcoSettlementHistory}/:id${View}`,
  LocqDealerMonthlyFee: `${LocqDealerMonthlyFee}`,
  LocqDealerMonthlyFeeDetailsById: (id) => `${LocqDealerMonthlyFee}/${id}${View}`,
  LocqDealerMonthlyFeeDetails: `${LocqDealerMonthlyFee}/:id${View}`,
  Locq: `${Locq}`,
  MopsPrice: `${LocqMopsPricing}`,
  AddMopsPrice: `${LocqMopsPricing}${Add}`,
  ActivateAccount: "/activate-account",
};

const Path = {
  Slash: "/",
  Auth: "/auth",
  FeatureFlag: "/feature-flag",
  ...BusinessPaths,
  ...ConsumerPaths,
  ...LocqPaths,
};

export default Path;
