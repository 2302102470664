import React, { useCallback, useContext, useState } from "react";
import { useApi, useMount } from "hooks";
import { getUserV2 } from "apis";
import { AppContext, FeatureFlagContext, UserContext } from "contexts";
import { Route, Switch } from "react-router-dom";
import { Path } from "enums";
import BusinessPage from "./business/business.page";
import ConsumerPage from "./consumer/consumer.page";
import AppLoader from "components/commons/app-loader/app-loader";
import FeatureFlagAdmin from "modules/feature-flags/feature-flags-admin.module";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import LocqPage from "./locq/locq.page";

const AuthorizedPage = () => {
  const { appState } = useContext(AppContext);
  const { setUserEmail } = useContext(FeatureFlagContext);
  const [user, setUser] = useState();

  const { request: getUserRequest, loading: fetchingUser } = useApi({
    api: getUserV2,
    pageError: true,
  });

  useMount(() => {
    fetchUser();
  });

  const fetchUser = useCallback(async () => {
    const res = await getUserRequest();
    setUser(res);
    setUserEmail(res.email);
  }, [getUserRequest, setUserEmail]);
  return (
    <UserContext.Provider value={{ user, fetchUser, loading: fetchingUser }}>
      {fetchingUser || appState?.loading || !user ? (
        <AppLoader />
      ) : (
        <Switch>
          <Route path={Path.FeatureFlag} component={FeatureFlagAdmin} />
          <Route path={Path.PricelocqBusiness} component={BusinessPage} />
          <Route path={Path.PricelocqConsumer} component={ConsumerPage} />
          <Route path={Path.Locq} component={LocqPage} />
          <Redirect to={Path.PricelocqBusiness} />
        </Switch>
      )}
    </UserContext.Provider>
  );
};

export default AuthorizedPage;
