import { exportCSV, parseCSVFile } from "utils/object.utils";
import { isNumeric } from "utils/text.utils";
import React, { useState } from "react";
import ImportModal from "components/modals/import-modal/import-modal";
import { ActionButton, Modal, Text } from "components/commons";
import locale from "localization";
// import { useCallback } from "react";
import { useModal } from "hooks";

const ImportStationModal = ({
  stationData,
  request = {},
  setStationInputs,
  saveStationsCb,
  stationInputs,
  index,
  modifyForm,
  isHedging,
  setStationsSelected,
  stationsSelected,
  ...importModal
  
}) => {
  const [csvData, setCSVData] = useState([]);
  const resultModal = useModal();
  const { data } = stationData;

  function getUnique(array, key) {
    if (typeof key !== "function") {
      const property = key;
      key = function (item) {
        return item[property];
      };
    }
    return Array.from(
      array
        .reduce(function (map, item) {
          const k = key(item);
          if (!map.has(k)) map.set(k, item);
          return map;
        }, new Map())
        .values()
    );
  }

  function search(value, prop, myArray){
    for (let i=0; i < myArray.length; i++) {
        if (myArray[i][prop] === value) {
            return myArray[i];
        }
    }
}

  const validateFile = async ({ file }, onParse) => {
    let items = await parseCSVFile(file, [
      {
        text: "Station Code",
        key: "stationCode",
        validation: (text, row) => {
          if (text.toString().length === 4 && isNumeric(text)) {
            const isExist = data?.filter((item) => {
              return Number(item.stationCode) === Number(text);
            });

            if (isExist.length >= 1) {
              return {
                error: false,
                message: "",
              };
            } else {
              return {
                error: true,
                message: "Station code not exist",
                errorType: "code",
              };
            }
          } else {
            return {
              error: true,
              message: "Invalid Station Code",
              errorType: "code",
            };
          }
        },
      },
    ]);

    items.items = getUnique(items.items, "stationCode");
    items.passed = getUnique(items.passed, "stationCode");

    let newItemsPassedList = [];
    items.passed.forEach((item)=>{
      const isDisabled = stationsSelected.some((selected) => {
        const station = search(selected.value, 'stationId', data);
        return Number(station.stationCode) === Number(item.stationCode) && selected.id !== index;
      });
      
      if (!isDisabled) {
        newItemsPassedList.push(item);
      } 
    })

    items.passed = newItemsPassedList;
    return items;
  };

  const handleLoadData = (index, isHedging) => {
    let newStations = [];
    let selectedStations = [];
    for (let i = 0; i < csvData.length; i++) {
      for (let j = 0; j < data.length; j++) {
        if (Number(csvData[i].stationCode) === data[j].stationCode) {
          const stationParams = {
            label: data[j].name,
            value: data[j].stationId,
            id: index,
          };

          const stationSelected = stationInputs.some((station) => {
            return station.value === stationParams.value;
          });
          if (!stationSelected) {
            newStations.push(stationParams);
          }
        }
        
      }
    }

    const clone = [...stationInputs];
    selectedStations = [...clone, ...newStations];

    let newItemsPassedList = [];
    selectedStations.forEach((item)=>{
      const isDisabled = stationsSelected.some((selected) => {
        return selected.value === item.value && selected.id !== index;
      });
      
      if (!isDisabled) {
        newItemsPassedList.push(item);
      } 
    })

    selectedStations = newItemsPassedList;
    
    if (isHedging) {
      modifyForm({
        fleetStations: {
          value: selectedStations,
        },
      });
    } else {
      setStationsSelected([...stationsSelected, ...selectedStations]);
      setStationInputs(selectedStations);
      saveStationsCb(index, selectedStations);
    }
  };

  return (
    <div>
      <Modal
        {...resultModal}
        close={() => {
          handleLoadData(index, isHedging);
          setCSVData([]);
          resultModal.close();
        }}
      >
        {resultModal.content}
        <ActionButton
          right={!resultModal.isSuccess}
          center={resultModal.isSuccess}
          items={
            resultModal.isSuccess
              ? [
                  {
                    text: locale.gotIt,
                    onClick: () => {
                      handleLoadData(index, isHedging);
                      setCSVData([]);
                      resultModal.close();
                    },
                    primary: true,
                  },
                ]
              : [
                  {
                    text: locale.proceedAnyways,
                    onClick: () => {
                      handleLoadData(index, isHedging);
                      setCSVData([]);
                      resultModal.close();
                    },
                  },
                  {
                    text: locale.retry,
                    onClick: () => {
                      handleLoadData(index, isHedging);
                      setCSVData([]);
                      resultModal.close();
                      importModal.show({
                        isHedging: true,
                        index,
                      });
                    },
                  },
                ]
          }
        />
      </Modal>
      <ImportModal
        title={locale.importStationCodesByCSV}
        {...importModal}
        loading={request.loading}
        onSubmit={async (file) => {
          const parsedFile = await validateFile(file);
          const errors = [];
          if (parsedFile.hasBlankRows) {
            errors.push({
              title: "Other error:",
              items: ["There are blank rows or columns in your file"],
            });
          }

          if (parsedFile?.invalidFormat) {
            resultModal.show({
              title: locale.importFailed,
              isSuccess: false,
              content: <ErrorImport errors={["Invalid CSV File"]} />,
            });
          } else if (parsedFile?.passed.length) {
            if (parsedFile.errors.length) {
              setCSVData(parsedFile.passed);
              resultModal.show({
                title: locale.importSuccessfulWithInvalidImports,
                isSuccess: false,
                content: (
                  <ErrorImport
                    errors={parsedFile.errors}
                    data={data}
                    correctData={parsedFile.passed}
                  />
                ),
              });
            } else {
              setCSVData(parsedFile.passed);
              resultModal.show({
                title: locale.importSuccess,
                isSuccess: true,
                content: (
                  <div
                    style={{
                      width: "400px",
                      textAlign: "center",
                    }}
                  >
                    <locale.Populate
                      text={locale.importSuccessMessageAddFleetAccountStations}
                      items={[
                        <b>
                          {parsedFile.passed.length <= 1
                            ? `${parsedFile.passed.length} station`
                            : `${parsedFile.passed.length} stations`}
                        </b>,
                      ]}
                    />
                  </div>
                ),
              });
            }

            importModal.close();
          } else {
            importModal.close();

            resultModal.show({
              title: locale.importFailed,
              content: (
                <ErrorImport
                  errors={parsedFile.errors}
                  data={data}
                  correctData={parsedFile.passed}
                />
              ),
            });
          }
        }}
      >
        <Text
          subtitle
          style={{
            margin: "0px 0px 20px 0px",
          }}
        >
          {locale.followTheFollowingColumnOrderAndLeaveRow1}
          <div
            style={{
              margin: "20px 0px",
            }}
          >
            {[locale.stationCode].map((column, index) => {
              return (
                <div key={index}>{locale.populate(locale.columnNTitle, [index + 1, column])}</div>
              );
            })}
          </div>
          <locale.Populate
            text={locale.downloadASampleTemplate}
            items={[
              <span
                colored
                style={{
                  color: "#753bbd",
                  fontWeight: "700",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => {
                  exportCSV({
                    rows: ["Station Code", "4120", "4196", "4383"],
                    filename: "Select Stations",
                  });
                }}
              >
                {locale.sampleTemplate}
              </span>,
            ]}
          />
        </Text>
      </ImportModal>
    </div>
  );
};

const ErrorImport = ({ errors = [], data = [], correctData = [] }) => {
  const getRow = errors.map((item) => item.replace(/[^0-9]/g, ""));
  const checkIfHasData = getRow[0] !== "" ? getRow : [];
  return (
    <div
      style={{
        width: "420px",
      }}
    >
      <div
        style={{
          textAlign: "center",
        }}
      >
        <Text
          style={{
            color: "black",
          }}
        >
          {locale.importSuccessMessage}
        </Text>
      </div>
      {checkIfHasData.length > 0 ? (
        <>
          <div
            className="flex"
            style={{
              marginTop: "20px",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "45%",
              }}
            >
              <Text subtitle>{locale.selectedStations}</Text>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <Text
                style={{
                  color: "black",
                }}
                strong
              >{`${correctData.length} out of ${data.length}`}</Text>
            </div>
          </div>
          <div
            className="flex"
            style={{
              marginTop: "20px",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "45%",
              }}
            >
              <Text subtitle>{locale.invalidEntries}</Text>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <Text
                style={{
                  color: "black",
                }}
                strong
              >
                {errors.length > 10
                  ? errors.length
                  : `${errors.length} (rows no. ${getRow.join(", ")})`}
              </Text>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className="flex"
            style={{
              marginTop: "20px",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <Text subtitle>{locale.otherError}</Text>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <Text
                style={{
                  color: "black",
                }}
                strong
              >
                {" "}
                {errors.slice(0, 5).join(", ")}
              </Text>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ImportStationModal;
