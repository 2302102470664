import locale from "localization";
import styles from "./target-stations-columns.module.scss";

export const TargetStationsColumns = [
  {
    key: "checkbox",
    actionHeader: true,
    width: "5%",
  },
  {
    key: "stationCode",
    text: <span className={styles.heading}>{locale.stationCode}</span>,
    width: "20%",
  },
  {
    key: "stationName",
    text: <span className={styles.heading}>{locale.stationName}</span>,
    width: "35%",
  },

  {
    key: "stationType",
    text: <span className={styles.heading}>{locale.stationType}</span>,
    width: "35%",
  },
];
