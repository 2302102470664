import { InnerLabelTextfield, TextField } from "components/commons";
import React from "react";
import { isLiterValid, isMonthlyVolumeValid, formatNumber, parseNumber } from "utils";

const InputLitre = ({ className, onChange, innerLabel, isMonthlyVolume = false, ...props }) => {
  const componentProps = {
    ...props,
    className,
    onChange: (name, { value }) => {
      if (isMonthlyVolume) {
        if (isMonthlyVolumeValid(value) || !value) {
          onChange(name, { value });
        }
      } else {
        if (isLiterValid(value) || !value) {
          onChange(name, { value });
        }
      }
    },
    onBlur: (name, { value }) => {
      if (isMonthlyVolume) {
        const inputValue =
          Number(value) > 0 ? `${formatNumber(value || 0)}${!innerLabel ? " L" : ""}` : "";

        onChange(name, {
          value: inputValue,
        });
      } else {
        onChange(name, {
          value: `${formatNumber(value || 0)}${!innerLabel ? " L" : ""}`,
        });
      }
    },
    onFocus: () => {
      if (isMonthlyVolume) {
        const inputValue =
          Number(parseNumber(props.value)) > 0
            ? Number(parseNumber(props.value ? props.value.toString().replace(" L", "") : 0))
            : "";

        onChange(props.name, { value: inputValue });
      } else {
        const num = Number(parseNumber(props.value ? props.value.toString().replace(" L", "") : 0));
        onChange(props.name, { value: num || "0" });
      }
    },
  };
  if (innerLabel) {
    return <InnerLabelTextfield {...componentProps} prefix="L" />;
  }
  return <TextField {...componentProps} />;
};

export default InputLitre;
