import locale from "localization";
import styles from "./user-attributes-list-columns.module.scss";

export const UserAttributesColumns = [
  {
    key: "name",
    text: <span className={styles.heading}>{locale.name}</span>,
    width: "30%",
  },
  {
    key: "createdAt",
    text: <span className={styles.heading}>{locale.dateCreated}</span>,
    width: "55%",
  },

  {
    key: "actions",
    text: <span className={styles.heading}>{locale.actions}</span>,
    width: "5%",
  },
];
