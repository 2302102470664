import React from "react";
import { Path } from "enums";
import { Route, Switch, Redirect } from "react-router-dom";
import SettlementHistoryPage from "./settlement-history.page";
import SettlementPage from "../locq/settlement.page";
import Prepayment from "modules/cash-flow/prepayment/prepayment.module";
import OilcoSettlementHistoryPage from "./oilco-settlement-history.page";
import DealerMonthlyFeePage from "./dealer-monthly-fee.page";

const CashFlowPage = () => {
  return (
    <Switch>
      <Route path={Path.LocqSettlement} component={SettlementPage} />
      <Route path={Path.LocqSettlementHistory} component={SettlementHistoryPage} />
      <Route path={Path.LocqOilcoSettlementHistory} component={OilcoSettlementHistoryPage} />
      <Route path={Path.LocqDealerMonthlyFee} component={DealerMonthlyFeePage} />
      <Route path={Path.LocqPrepayment} component={Prepayment} />
      <Redirect to={Path.LocqSettlement} />
    </Switch>
  );
};

export default CashFlowPage;
