import React, { Fragment, useState, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router";
import { Path } from "enums";
import DashboardPage from "./dashboard.page";
import CashFlowPage from "./cashflow.page";
import FuelPage from "./fuel.page";
import FleetCardPage from "./fleet.page";
import PricingPage from "./pricing.page";
import Header from "components/commons/header/header";
import Sidebar from "components/commons/sidebar/sidebar";
import AccountsPage from "./accounts.page";
import DownloadModule from "modules/download/downloads.module";
import AccessAuthorizationPage from "./access-authorization.page";
// import DownloadDrawer from "components/commons/download-drawer/download-drawer";
import useWebSocket from "hooks/useWebSockets";
import env from "environments/env";
import { useMount } from "hooks";
import { SessionContext } from "contexts";


const BusinessPage = () => {
  const { message, sessionId } = useWebSocket({ URL: env?.WEB_SOCKET_URL });
  const [newNotification, setNewNotification] = useState(false);

  useEffect(() => {
    if (message.length !== 0) {
      setNewNotification(true);
    }
  }, [message]);

  useMount(() => {
    localStorage.setItem("platformType", "plb");
  });

  return (
    <SessionContext.Provider value={{ sessionId }}>
      <Fragment>
        <Header
          setNewNotification={() => setNewNotification(false)}
          newNotification={newNotification}
        />
        <Sidebar />
        <div
          style={{
            marginLeft: "196px",
            marginTop: "60px",
            padding: "27px 36px",
            minWidth: "1170px",
            overflowX: "auto",
            minHeight: "100vh",
            backgroundColor: "#fff",
          }}
        >
          <Switch>
            <Route exact path={Path.Dashboard} component={DashboardPage} />
            <Route path={Path.CashFlow} component={CashFlowPage} />
            <Route path={Path.Fuel} component={FuelPage} />
            <Route path={Path.FleetCard} component={FleetCardPage} />
            <Route path={Path.Accounts} component={AccountsPage} />
            <Route path={Path.Pricing} component={PricingPage} />
            <Route path={Path.BusinessAccessAuthorization} component={AccessAuthorizationPage} />
            <Route path={Path.Download} render={() => <DownloadModule pageType={"plb"} />} />
            <Redirect to={Path.Dashboard} />
          </Switch>
        </div>
      </Fragment>
    </SessionContext.Provider>
  );
};

export default BusinessPage;
