import React, { useState, useRef } from "react";
import { useModal } from "hooks";
import locale from "localization";
import styles from "./voucher-edit.module.scss";
import { FormMode } from "enums";
import { Path } from "enums";
import { Intro, BackLink } from "components/commons";
import VoucherDetailsModule from "../voucher-details/voucher-details.module";
import { useHistory } from "react-router-dom";

const VoucherEditModule = (props) => {
  const voucherDetailsRef = useRef();
  const successModal = useModal();
  const [updateLoading, setUpdateLoading] = useState(false);
  const [disableUpdate, setDisableUpdate] = useState(false);
  const history = useHistory();
  const voucherCampaignId = props.location.state ? props.location.state.voucherCampaignId : "";

  return (
    <div>
      <BackLink text={locale.voucherCampaigns} path={Path.VoucherCampaign} />
      <div className={styles.intro}>
        <Intro
          title={locale.editVoucherCampaign}
          actionText={locale.save}
          actionOnClick={() => {
            voucherDetailsRef.current.handleUpdate();
          }}
          actionClassName={styles.primarySaveButton}
          actionLoading={updateLoading}
          actionDisabled={disableUpdate}
          actionSecondaryText={locale.cancel}
          actionSecondaryOutlined
          actionSecondaryOnClick={() => {
            history.goBack();
          }}
          actionSecondaryClassName={styles.secondarySaveButton}
        />
        <VoucherDetailsModule
          pageMode={FormMode.Edit}
          {...successModal}
          voucherCampaignId={voucherCampaignId}
          setUpdateLoading={setUpdateLoading}
          setDisableUpdate={setDisableUpdate}
          ref={voucherDetailsRef}
        />
      </div>
    </div>
  );
};

export default VoucherEditModule;
