import { CloseRounded } from "@material-ui/icons";
import React from "react";

const RemoveItemButton = ({ onClick, marginLeft, ...props }) => {
  return (
    <div
      {...props}
      onClick={() => {
        onClick();
      }}
      style={{
        color: "#4c69a4",
        width: "25px",
        minWidth: "25px",
        height: "25px",
        border: "1px solid #ebeef7",
        borderRadius: "100%",
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        marginLeft: marginLeft ? marginLeft : "20px",
        textAlign: "center",
      }}
      className="pointer"
    >
      <CloseRounded
        style={{
          margin: "auto",
        }}
        fontSize="small"
      />
    </div>
  );
};

export default RemoveItemButton;
