import { useCallback, useState } from "react";

const usePagination = () => {
  const [result, setResult] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const onLoadMore = useCallback(
    ({ data, total, resetPage = false }) => {
      if (resetPage) {
        setResult([...data]);
        setTotal(total);
        setPage(2);
      } else {
        setResult([...result, ...data]);
        setTotal(total);
        setPage(page + 1);
      }
    },
    [result, page]
  );
  const reset = useCallback(() => {
    setTotal(0);
    setPage(1);
    setResult([]);
  }, []);

  return {
    canLoadMore: total > result.length,
    onLoadMore,
    page,
    data: result,
    total,
    reset,
  };
};

export default usePagination;
