import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import locale from "localization";
import styles from "./discount-view.module.scss";
import { FormMode, Path, Role, Portal } from "enums";
import { Intro, BackLink } from "components/commons";
import DiscountDetailsModule from "../discount-details/discount-details.module";
import { UserContext } from "contexts";

const DiscountViewModule = (props) => {
  const [isCancelled, setIsCancelled] = useState(false);
  const history = useHistory();
  const { user } = useContext(UserContext);
  const { role } = user?.userAccesses?.find((element) => {
    return element.portal === Portal.LOCQ;
  });

  const outrightDiscountCampaignId = props.location.state
    ? props.location.state.outrightDiscountCampaignId
    : "";
  const stationCount = props.location.state ? props.location.state.stationCount : "";

  return (
    <div>
      <BackLink text={locale.locqpayCampaign} path={Path.LOCQPayDiscountCampaigns} />
      <div className={styles.intro}>
        {role === Role.Accounting ? (
          <Intro title={locale.viewLOCQPayCampaign} />
        ) : (
          <Intro
            title={locale.viewLOCQPayCampaign}
            actionText={isCancelled ? "" : locale.edit}
            actionOnClick={() => {
              history.push(Path.EditLOCQPayDiscountCampaigns, {
                outrightDiscountCampaignId,
                stationCount,
              });
            }}
            actionOutlined
            actionClassName={styles.primaryEditButton}
            actionStartIcon={<span className="icon-pencil" />}
          />
        )}

        <DiscountDetailsModule
          pageMode={FormMode.View}
          outrightDiscountCampaignId={outrightDiscountCampaignId}
          stationCount={stationCount}
          setIsCancelled={setIsCancelled}
        />
      </div>
    </div>
  );
};

export default DiscountViewModule;
