const UserPLBAccess = {
  Dashboard: "DASHBOARD",
  CashFlow: "CASH_FLOW",
  CashIn: "CASH_IN",
  Fuel: "FUEL",
  BuyFuel: "BUY_FUEL",
  FuelCodes: "FUEL_CODES",
  Redemption: "REDEMPTION",
  Accounts: "ACCOUNTS",
  FleetCard: "FLEET_CARD",
  FleetAccounts: "FLEET_ACCOUNTS",
  StationAccounts: "STATION_ACCOUNTS",
  FleetPrices: "FLEET_PRICES",
  Pricing: "PRICING",
  PricingChanges: "PRICING_CHANGES",
  PriceMovements: "PRICE_MOVEMENTS",
  DoxDiscounts: "DOX_DISCOUNTS",
  AccessAuthorization: "ACCESS_AUTHORIZATION",
  UserAccess: "USER_ACCESS",
};

export default UserPLBAccess;
