import classNames from "classnames";
import { Modal, ActionButton, TextField, Field, Autocomplete, Text } from "components/commons";
import locale from "localization";
import React from "react";
import styles from "./void-transaction-modal.module.scss";

const VoidTransactionModal = ({
  close,
  active,
  onSubmit,
  remarks = "",
  onChangeRemarks,
  loading,
  title,
  description,
  label,
  actionText,
  value,
  setValue,
}) => {
  return (
    <Modal active={active} title={title} close={close} className={styles.modal}>
      <div
        className={classNames(styles.content, {
          [`${styles.noSpace}`]: !description,
        })}
      >
        <Text align="center" style={{ padding: "0px 20px" }}>
          {description}
        </Text>
        <Field
          labelPosition={"left"}
          childrenStyle={{
            width: "400px",
          }}
          style={{
            display: "flex",
            alignItems: "start",
            paddingTop: 10,
          }}
          labelStyle={{
            marginTop: 10,
          }}
          label={locale.reasonRemarks}
        >
          <Autocomplete
            name={"voidTransaction"}
            value={value}
            options={[
              {
                value: "Wrong Product Purchase",
                label: "Wrong Product Purchase",
              },
              {
                value: "others",
                label: "Others",
              },
            ]}
            onChange={(name, { value }) => {
              if (value !== "others") {
                onChangeRemarks(value);
              } else {
                onChangeRemarks("");
              }

              if (value === null) {
                onChangeRemarks("");
              }

              setValue(value);
            }}
          />
          {value === "others" && (
            <div
              style={{
                marginTop: "10px",
              }}
            >
              <TextField
                placeholder="Other reason here"
                multiline
                rowsMax={1}
                onChange={(n, { value }) => {
                  onChangeRemarks(value);
                }}
                value={remarks}
              ></TextField>
            </div>
          )}
        </Field>
      </div>
      <ActionButton
        right
        loading={loading}
        items={[
          {
            text: locale.cancel,
            onClick: () => {
              close();
              setValue("");
            },
          },
          {
            onClick: () => {
              onSubmit(remarks);
              setValue("");
            },
            text: actionText,
            disabled: remarks === "",
          },
        ]}
      />
    </Modal>
  );
};

export default VoidTransactionModal;
