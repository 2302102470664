import { manualRedemption } from "apis";
import { Intro, BackLink, Button, Text } from "components/commons";
import { ConfirmModal } from "components/modals";
import { DateTime, ErrorCode, Path } from "enums";
import { useApi, useForm, useModal, useRouter } from "hooks";
import moment from "moment";
import locale from "localization";
import { useCallback, useMemo } from "react";
import { formatDate, formatNumber, handleRequest } from "utils";
import ManualRedemptionForm from "./manual-redemption-form";

import { initialState as formState } from "./manual-redemption.state";

const ManualRedemptionModule = () => {
  const confirmModal = useModal();
  const successModal = useModal();

  const { history } = useRouter();

  const applyManualRedemption = useApi({
    api: manualRedemption,
    handleOwnError: {
      badrequest: true,
    },
  });

  const form = useMemo(() => {
    return formState();
  }, []);

  const {
    fields,
    modifyField,
    submitForm,
    modifyForm,
    isFormSubmittable,
    applyFieldErrors,
    makeFormInvalid,
    getFormValues,
  } = useForm({
    initialState: form,
  });

  const applyRedemption = useCallback(() => {
    confirmModal?.show({
      title: <Text style={{ color: "#000" }}>{locale?.proceedManualRedemption}</Text>,
      content: locale?.manualRedemptionQuestion,
      loading: applyManualRedemption?.loading,
      secondary: {
        text: locale?.cancel,
        onClick: () => confirmModal?.close(),
      },
      primary: {
        text: locale?.yesProceed,
        onClick: async () => {
          handleRequest(
            async () => {
              const resp = await applyManualRedemption?.request({
                userId: fields?.mobileAccountNumber?.userId,
                literVolume: parseFloat(formatNumber(fields?.liters?.value)),
                productCode: fields?.product?.value,
                redeemedAt: moment(
                  `${formatDate(fields?.redemptionDate?.value, DateTime.K)} ${
                    fields?.redemptionTime?.value
                  }`
                ).toISOString(),
                remarks: fields?.remarks?.value,
                stationCode: fields?.redemptionStation?.value,
              });
              if (resp) {
                confirmModal?.close();
                successModal?.show({
                  title: <Text style={{ color: "#000" }}>{locale?.exclamatedSuccess}</Text>,
                  content: locale.manualRedemptionSuccess,
                  primary: {
                    onClick: () => {
                      history.push(Path.ConsumerRedemption);
                    },
                  },
                });
              }
            },
            null,
            (err) => {
              if (err.data?.errorCode === ErrorCode.RedemptionLimitExceeded) {
                confirmModal.close();
                return applyFieldErrors({
                  liters: err.data?.message,
                });
              }
              return err.showError();
            }
          );
        },
      },
    });
  }, [
    confirmModal,
    applyManualRedemption,
    fields?.mobileAccountNumber?.userId,
    fields?.liters?.value,
    fields?.product?.value,
    fields?.redemptionDate?.value,
    fields?.redemptionTime?.value,
    fields?.remarks?.value,
    fields?.redemptionStation?.value,
    successModal,
    history,
    applyFieldErrors,
  ]);

  const stateForm = {
    fields,
    modifyField,
    submitForm,
    modifyForm,
    applyFieldErrors,
    isFormSubmittable,
    makeFormInvalid,
    getFormValues,
  };

  return (
    <div>
      <ConfirmModal
        {...confirmModal}
        loading={applyManualRedemption?.loading}
        closable={!applyManualRedemption?.loading}
      />

      <ConfirmModal {...successModal} />
      <BackLink text={locale.fuelCodeRedemptions} path={Path.ConsumerRedemption} />
      <div
        style={{
          display: "flex",
        }}
      >
        <Intro
          style={{
            minWidth: "300px",
          }}
          title={locale.manualRedeem}
        />
      </div>
      <ManualRedemptionForm {...stateForm} />
      <Button
        primary
        style={{ marginTop: 25 }}
        onClick={applyRedemption}
        disabled={!isFormSubmittable}
      >
        {locale.continue}
      </Button>
    </div>
  );
};

export default ManualRedemptionModule;
