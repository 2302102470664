import { Field, Filter, SearchField, DateRange, Autocomplete, Button } from "components/commons";

import locale from "localization";
import { CampaignStatus } from "enums";
import React, { useCallback } from "react";
import styles from "./discount-filter.module.scss";

const CustomerFilter = ({
  filterState,
  clearFilter,
  onSearchChange,
  onSearch,
  onStatusChange,
  submitFilter,
  filterCount,
  onDateRangeChange,
  exportReport,
  exporting,
}) => {
  const { Upcoming, Ongoing, Ended, Canceled } = CampaignStatus;
  const onDateRangeChangeCb = useCallback(
    (name, { value, dirty }) => {
      if (onDateRangeChange) return onDateRangeChange(value, dirty);
    },
    [onDateRangeChange]
  );

  const onSearchChangeCb = useCallback(
    (name, { value }) => {
      if (onSearchChange) {
        return onSearchChange("searchKey", { value });
      }
    },
    [onSearchChange]
  );

  const onSearchCb = useCallback(
    (searchKey) => {
      if (onSearch) {
        return onSearch(searchKey);
      }
    },
    [onSearch]
  );

  let checker = (arr, target) => target.every((v) => arr.includes(v));

  const onStatusChangeCb = useCallback(
    (name, { props }) => {
      const allItem = [Upcoming, Ongoing, Ended, Canceled];
      let newStatus = [...filterState.statuses];
      const newValue = props.value;
      let index = newStatus.indexOf(newValue);

      if (index !== -1) {
        if (newValue === "all") {
          newStatus = [];
        } else {
          const allIndex = newStatus.indexOf("all");
          if (allIndex !== -1) {
            newStatus.splice(allIndex, 1);
          }
          index = newStatus.indexOf(newValue);
          newStatus.splice(index, 1);
        }
      } else {
        if (newValue === "all") {
          newStatus = ["all", Upcoming, Ongoing, Ended, Canceled];
        } else {
          newStatus.push(props.value);
        }
      }
      if (checker(newStatus, allItem)) {
        newStatus = ["all", Upcoming, Ongoing, Ended, Canceled];
      }

      return onStatusChange(newStatus);
    },
    [Canceled, Ended, Ongoing, Upcoming, filterState.statuses, onStatusChange]
  );

  return (
    <div className={styles.filter}>
      <Filter submit={submitFilter} clear={clearFilter} filterCount={filterCount}>
        <div className={styles.content}>
          <Field
            className={styles.filterInput}
            label={locale.dateCreated}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
          >
            <DateRange
              value={{
                startDate: filterState.startDate,
                endDate: filterState.endDate,
              }}
              onChange={onDateRangeChangeCb}
            />
          </Field>

          <Field
            className={styles.filterInput}
            label={locale.status}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
          >
            <Autocomplete
              name="statuses"
              value={filterState.statuses}
              options={[
                { label: locale.all, value: "all" },
                { label: locale.upcoming, value: Upcoming },
                { label: locale.ongoing, value: Ongoing },
                { label: locale.ended, value: Ended },
                { label: locale.cancelled, value: Canceled },
              ]}
              onChange={onStatusChangeCb}
              multiple
            />
          </Field>
        </div>
      </Filter>

      <Field className={styles.search}>
        <SearchField
          placeholder={`${locale.discountCampaignId}, ${locale.name}`}
          value={filterState.searchKey}
          onChange={onSearchChangeCb}
          onEnter={onSearchCb}
          restrict={false}
        />
      </Field>
      <Field className={styles.downloadButton}>
        <Button
          startIcon={<span className="icon-download" />}
          exportButton
          onClick={exportReport}
          loading={exporting}
        >
          {locale.export}
        </Button>
      </Field>
    </div>
  );
};

export default CustomerFilter;
