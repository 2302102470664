import MoreVert from "@material-ui/icons/MoreVert";
import classNames from "classnames";
import moment from "moment";
import { Text, Pill, Product, PopOverMenu } from "components/commons";

import { DateTime, FuelCodesStatus, Products, Role } from "enums";
import locale from "localization";
import { formatVolume } from "utils";
import { prettifyFuelCodes, prettifyFuelCodeType, prettifyProduct } from "utils/pretty.utils";
import { pluralize } from "utils/text.utils";

import styles from "./fuel-codes.module.scss";

export const mapDataToList = ({ fuelCodes, overrideFuelCodeModal, role }) => {
  const {
    fleet,
    fuelCode,
    drivers,
    vehicles,
    createdAt,
    productCode,
    literVolume,
    volumeRedeemed,
    maxUsage,
    sendSms,
    autoRenew,
    startDate,
    endDate,
    status,
    fuelCodeId,
    fuelCodeType,
    useCount,
  } = fuelCodes;
  const options = [];
  const disabledOverride = status === FuelCodesStatus.Deactivated;
  options.push({
    content: (
      <div
        className={classNames({
          gray: disabledOverride,
        })}
      >
        {locale.overrideDetails}
      </div>
    ),
    disabled: role === Role.Accounting,
    onClick: () => {
      if (!disabledOverride) {
        overrideFuelCodeModal.show({
          title: locale.populate(locale.overrideFuelCodeDetails, [fuelCode]),
          // onSubmit: addFuelCode,
          actionText: locale.overrideDetails,
          fuelCode: fuelCode,
          fuelCodeId: fuelCodeId,
          fleetId: fleet.fleetId,
          initialState: {
            fuelCodeType: {
              value: fuelCodeType,
              label: prettifyFuelCodeType(fuelCodeType),
              disabled: true,
            },
            vehicleIds: {
              value: vehicles[0]?.plateNumber,
              label: vehicles[0]?.plateNumber,
              disabled: true,
            },
            driverIds: {
              value: drivers[0]?.driverId,
              label: `${drivers[0]?.firstName} ${drivers[0]?.lastName}`,
              disabled: true,
            },
            productCode: {
              value: productCode,
              disabled: Boolean(useCount),
            },
            literVolume: {
              value: literVolume,
              minValue: volumeRedeemed,
              infoMessage: (
                <locale.Populate
                  text={locale.currentRedeemedVolumeIsN}
                  items={[<b>{formatVolume(volumeRedeemed)}</b>]}
                />
              ),
            },
            period: {
              value: {
                startDate: new Date(startDate),
                endDate: new Date(endDate),
              },
              disabled: true,
            },
            duration: {
              value: moment(moment(endDate).endOf("day").add(1, "days")).diff(
                moment(startDate).startOf("day"),
                "days"
              ),
            },
            limitOneTime: {
              value: maxUsage === 1,
              disabled: (maxUsage === useCount && useCount) || useCount >= 2,
              infoMessage: (
                <locale.Populate
                  text={locale.codeHasBeenUsed}
                  items={[
                    <b>
                      {useCount} {pluralize(useCount, locale.time, locale.times)}
                    </b>,
                  ]}
                />
              ),
            },
            autoRenew: {
              value: autoRenew,
              disabled:
                status === FuelCodesStatus.Expired || status === FuelCodesStatus.Deactivated,
            },
            sendAsSms: {
              value: sendSms,
              disabled: true,
            },
            saveTemplate: {
              value: false,
              hidden: true,
            },
          },
        });
      }
    },
  });

  return {
    businessName: (
      <div className={styles.min80}>
        <Text small>{fleet?.businessName}</Text>
        <Text small subtitle>
          {fleet?.shortName}
        </Text>
      </div>
    ),
    fuelCodeType: (
      <>
        <Text small>{fuelCode}</Text>
        <Text small subtitle>
          {prettifyFuelCodeType(fuelCodeType)}
        </Text>
      </>
    ),
    assignedDriver:
      drivers?.length > 0 ? (
        <Text>{`${drivers[0]?.firstName} ${drivers[0]?.lastName}`}</Text>
      ) : (
        <Text italic>{locale.any}</Text>
      ),
    assignedVehicle:
      vehicles?.length > 0 ? (
        <Text>{vehicles[0]?.plateNumber || ""}</Text>
      ) : (
        <Text italic>{locale.any}</Text>
      ),
    createdAt: (
      <div className={styles.date}>
        <Text>{moment(createdAt).format(DateTime.A)}</Text>
        <Text>{moment(createdAt).format(DateTime.B)}</Text>
      </div>
    ),
    product: (
      <Product
        grass={productCode === Products.Gas91}
        salsa={productCode === Products.Gas95}
        deepBlue={productCode === Products.Gas97}
        cheddar={productCode === Products.Diesel}
      >
        {prettifyProduct(productCode)}
      </Product>
    ),
    literVolume: <div className={styles.min100}>{formatVolume(literVolume)}</div>,
    volumeRedeemed: <div className={styles.min100}>{formatVolume(volumeRedeemed)}</div>,
    oneTimeUse: maxUsage === 1 ? locale.yes : locale.no,
    sendSms: sendSms ? locale.yes : locale.no,
    autoRenew: autoRenew ? locale.yes : locale.no,
    startDate: (
      <div className={styles.date}>
        <Text>{moment(startDate).format(DateTime.A)}</Text>
        <Text>{moment(startDate).format(DateTime.B)}</Text>
      </div>
    ),
    endDate: (
      <div className={styles.date}>
        <Text>{moment(endDate).format(DateTime.A)}</Text>
        <Text>{moment(endDate).format(DateTime.B)}</Text>
      </div>
    ),
    status: (
      <Pill
        sky={status === FuelCodesStatus.Scheduled}
        deepRed={status === FuelCodesStatus.Deactivated}
        grass={status === FuelCodesStatus.Active}
        cheddar={status === FuelCodesStatus.Redeemed}
        cement={status === FuelCodesStatus.Expired}
      >
        {prettifyFuelCodes(status)}
      </Pill>
    ),
    actions: (
      <PopOverMenu options={options}>
        <MoreVert className={styles.icon} />
      </PopOverMenu>
    ),
  };
};

export const mapFilterToRequest = (filterState) => {
  const { productCodes, autoRenew, fuelCodeType, oneTimeUse, status, ...fs } = filterState;
  const newAutoRenew = autoRenew === "all" ? "" : autoRenew;
  const newFuelCodeType = fuelCodeType === "all" ? "" : fuelCodeType;
  const newOneTimeUse = oneTimeUse === "all" ? "" : oneTimeUse;
  const newStatus = status === "all" ? "" : status;

  if (productCodes.length && productCodes.length < 4) {
    fs.productCodes = productCodes?.join(",");
  }

  return {
    ...fs,
    autoRenew: newAutoRenew,
    fuelCodeType: newFuelCodeType,
    oneTimeUse: newOneTimeUse,
    status: newStatus,
  };
};
