import locale from "localization";
import styles from "./voucher-column.module.scss";

export const campaignsColumns = [
  {
    key: "voucherCampaignId",
    text: <span className={styles.heading}>{locale.voucherCampaignId}</span>,
    width: "5%",
  },
  {
    key: "namePrefix",
    text: (
      <span className={styles.heading}>
        {locale.name}/{locale.prefix}
      </span>
    ),
    width: "15%",
  },
  {
    key: "valueCurrency",
    text: (
      <span className={styles.heading}>
        {locale.value}/{locale.currency}
      </span>
    ),
    width: "10%",
  },
  {
    key: "voucherType",
    text: <span className={styles.heading}>{locale.type}</span>,
    width: "5%",
  },
  // {
  //   key: "actualRedemption",
  //   text: <span className={styles.heading}>{locale.actualRedemptions}</span>,
  //   width: "10%",
  // },
  {
    key: "quantityMaxRedemptions",
    text: <span className={styles.heading}>{locale.quantityMaxRedemptions}</span>,
    width: "10%",
  },
  {
    key: "startEndDate",
    text: (
      <span className={styles.heading}>
        {locale.startDate}/{locale.endDate}
      </span>
    ),
    width: "15%",
  },
  {
    key: "dateCreated",
    text: <span className={styles.heading}>{locale.dateCreated}</span>,
    width: "10%",
  },

  {
    key: "status",
    text: (
      <span className={styles.heading}>
        {locale.status}/{locale.date}
      </span>
    ),
    width: "13%",
  },
  {
    key: "action",
    text: <span className={styles.heading}>{locale.action}</span>,
    width: "5%",
  },
];
