import React from "react";
import { Path } from "enums";
import { Route, Switch } from "react-router-dom";
import PriceBuildupModule from "modules/pricing/price-buildup/price-buildup.module";
const ConsumerPricingPage = () => {
  return (
    <Switch>
      <Route exact path={Path.ConsumerPriceBuildup} component={PriceBuildupModule} />
    </Switch>
  );
};

export default ConsumerPricingPage;
