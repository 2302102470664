import { PopOverMenu } from "components/commons";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  prettifyPayWithPesoStatus,
  prettifyProduct,
  prettifySettlementStatus,
} from "utils/pretty.utils";
import { formatAmount, formatDate, formatVolume } from "utils";
import { DateTime, Products, Role, SettlementStatus } from "enums";
import LabelAndValue from "components/commons/label-and-value/label-and-value";
import PayWithPesoStatus from "enums/pay-with-peso-status";
import LabelAndCircle from "components/commons/label-and-circle/label-and-circle";
import LabelAndPill from "components/commons/label-and-pill/label-and-pill";
import { locale } from "localization/en";

import styles from "./pay-with-peso.module.scss";

const getProductColor = (product) => {
  return (
    {
      [Products.Diesel]: "#ffaa01",
      [Products.Gas91]: "#019f3e",
      [Products.Gas95]: "#ee3b34",
      [Products.Gas97]: "#2e4491",
    }[product] || null
  );
};

export const mapDataToList = ({ payments, handleVoidPayment, handleActionsHistory, role }) => {
  const {
    paymentId,
    paidAt,
    userDetail = {},
    stationName,
    stationCode,
    productCode,
    amount,
    status = PayWithPesoStatus.Success,
    settlementStatus,
    settlementId,
    literVolume,
    pumpPrice,
    fuelBalance,
    averageCreditPrice,
    voidedAt,
    rebate,
    voidedByEmail,
    totalDiscount,
    discount,
    discountedVolume,
    finalAmount,
    discountType,
  } = payments;
  const { mobileNumber, lastName = "", firstName = "", email = "" } = userDetail;

  const options = [];

  if (status === PayWithPesoStatus.Success) {
    options.push({
      content: locale.voidTransaction,
      disabled: role === Role.Accounting,
      onClick: () =>
        handleVoidPayment({
          paymentId,
          amount,
          rebate,
          settlementStatus,
          settlementId,
          totalDiscount,
          discountType,
        }),
    });
  } else if (status === PayWithPesoStatus.Voided) {
    options.push({
      content: locale.actionsHistory,
      onClick: () => handleActionsHistory({ voidedByEmail, status, voidedAt }),
    });
  }

  return {
    paymentId: <div>{paymentId}</div>,
    createdAt: formatDate(paidAt, DateTime.G),
    name: (
      <LabelAndValue label={mobileNumber}>
        {`${firstName} ${lastName}`.trim() || email}
      </LabelAndValue>
    ),
    stationName,
    stationCode,
    product: (
      <div className="min-70">
        {
          <LabelAndCircle
            label={prettifyProduct(productCode)}
            color={getProductColor(productCode)}
          />
        }
      </div>
    ),
    paymentMethod: "Peso Balance",
    amount: formatAmount(amount),
    literVolume: formatVolume(literVolume),
    pumpPrice: formatAmount(pumpPrice),
    status: (
      <LabelAndPill
        label={voidedAt ? formatDate(voidedAt, DateTime.G) : ""}
        pillProps={{
          sky: status === PayWithPesoStatus.Pending,
          deepRed: status === PayWithPesoStatus.Failed,
          grass: status === PayWithPesoStatus.Success,
          cement: status === PayWithPesoStatus.Voided,
        }}
        pillLabel={prettifyPayWithPesoStatus(status)}
      />
    ),
    settlementStatus: (
      <div>
        <LabelAndPill
          label={settlementId || "- -"}
          pillProps={{
            cyan: settlementStatus === SettlementStatus.ForSettlement,
            sky: settlementStatus === SettlementStatus.ForProcessing,
            grass: settlementStatus === SettlementStatus.Processed,
            deepRed: settlementStatus === SettlementStatus.Cancelled,
          }}
          pillLabel={prettifySettlementStatus(settlementStatus)}
        />
      </div>
    ),
    balanceAverageCreditPrice: (
      <LabelAndValue label={formatAmount(averageCreditPrice)}>
        {formatVolume(fuelBalance)}
      </LabelAndValue>
    ),
    discount: formatAmount(totalDiscount),
    discountPerLiter: `${formatAmount(discount)} / L`,
    discountedVolume: formatVolume(discountedVolume),
    finalAmountPaid: formatAmount(finalAmount),
    action: (
      <PopOverMenu options={options}>
        <MoreVertIcon className={styles.icon} />
      </PopOverMenu>
    ),
  };
};

export const mapFilterToRequest = (filterState) => {
  const { station, settlementStatuses, productCodes, status, ...fs } = filterState;

  if (station.value.length > 0) {
    fs.stationIds = station.value
      .map(({ value }) => {
        return value;
      })
      .join(",");
  } else {
    fs.stationIds = null;
  }

  return {
    ...fs,
    productCodes: productCodes.length < 4 ? productCodes.join(",") : null,
    settlementStatuses: !settlementStatuses?.isSelectedAll
      ? settlementStatuses.value.join(",")
      : null,
    paymentStatus: !status?.isSelectedAll ? status.value.join(",") : null,
  };
};
