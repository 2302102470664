import MoreVertIcon from "@material-ui/icons/MoreVert";
import { PopOverMenu, Text } from "components/commons";
import locale from "localization";
import { DateTime, Path } from "enums";

import styles from "./dealer-monthly-fee.module.scss";
import moment from "moment";
import { formatAmount } from "utils";

export const mapDataToList = ({ charging, history }) => {
  const {
    pricelocqStations,
    plcStations,
    plbStations,
    plcPlbStations,
    totalMonthlyFee,
    totalWaivedFees,
    totalNetFees,
    billToOilco,
    createdAt,
    chargingId,
    dealerShoulderedMonthlyFee,
  } = charging;

  return {
    date: <Text>{moment(createdAt).format(DateTime.M)}</Text>,
    pricelocqStations: <Text>{pricelocqStations}</Text>,
    plcStationsOnly: <Text>{plcStations}</Text>,
    plbStationsOnly: <Text>{plbStations}</Text>,
    plcAndPlbStations: <Text>{plcPlbStations}</Text>,
    totalMonthlyFee: <Text>{formatAmount(totalMonthlyFee)}</Text>,
    totalWaivedFees: <Text>{formatAmount(totalWaivedFees)}</Text>,
    totalNetFees: <Text>{formatAmount(totalNetFees)}</Text>,
    billToOilco: <Text>{formatAmount(billToOilco)}</Text>,
    dealerShoulderedMonthlyFee: <Text>{formatAmount(dealerShoulderedMonthlyFee)}</Text>,

    action: (
      <PopOverMenu
        options={[
          {
            content: locale.viewDetails,
            onClick: () => history.push(Path.LocqDealerMonthlyFeeDetailsById(chargingId)),
          },
        ]}
      >
        <MoreVertIcon className={styles.icon} />
      </PopOverMenu>
    ),
  };
};

export const mapFilterToRequest = (filterState) => {
  const { ...fs } = filterState;
  return {
    ...fs,
  };
};
