import { Products } from "enums";

export const myFuelCodesState = () => ({
  startDate: null,
  endDate: null,
  productCodes: [Products.Diesel, Products.Gas91, Products.Gas95, Products.Gas97],
  page: 1,
  perPage: 10,
  status: "all",
  oneTimeUse: "all",
  fuelCodeType: "all",
  autoRenew: "all",
});
