import React, { useMemo } from "react";
import classNames from "classnames";
import { Skeleton } from "@material-ui/lab";
import {
  Field,
  Title,
  Text,
  Autocomplete,
  ActionButton,
  PopOverList,
  Button,
  TextField,
} from "components/commons";
import { SelectRedemptionStation, SelectStation } from "components/field";
import { FleetAccountType, PricingStationType } from "enums";
import PlatformType from "enums/platform-type";
import locale from "localization";

import styles from "./pricing.module.scss";
import TableForm from "./table-form";

import { ErrorRounded, AutorenewRounded } from "@material-ui/icons";
import { GasType } from "./pricing.module";
import { prettifyFleetAccountType, formatNumber } from "utils";

const { COCO, COXO, CODO, DODO, DODOX, WIBCO, WIDOX, WILCO } = PricingStationType;

const accountTypeOption = Object.values(FleetAccountType).map((data) => ({
  value: data,
  label: prettifyFleetAccountType(data),
}));

// const accountTypeOption = Object.values(FleetAccountType).map((data) => ({
//   value: data,
//   label: prettifyFleetAccountType(data),
// }));

const PricingForm = ({
  isEdit,
  isLoading,
  initialFormLoading,
  initialFormState,
  accumulatedSpecificModal,
  matchPriceModal,
  onApplyUpdates,
  showMatchPumpPriceModal,
  comparePumpPrice,
  computeContractPrice,
  isFormSubmittable,
  priceAssessmentDate,
  onboardingInfo,
  matchPumpPriceFields,
  ...stateForm
}) => {
  const { fields, modifyForm, modifyField, getCurrentInfo, updateCurrentInfo } = stateForm;

  const isButtonShow = useMemo(() => {
    return (
      fields[`referenceStation`].isUpdate ||
      fields[`freightDiesel`].error ||
      fields[`freightGas91`].error ||
      fields[`freightGas95`].error ||
      fields[`freightGas97`].error ||
      fields[`sdumDiesel`].error ||
      fields[`sdumGas91`].error ||
      fields[`sdumGas95`].error ||
      fields[`sdumGas97`].error
    );
  }, [fields]);

  return (
    <div className={styles.container}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Title xsmall style={{ minWidth: 300 }}>
          {locale.currentPricingInfo}
        </Title>
        {isEdit && (
          <div style={{ display: "flex" }}>
            <ActionButton
              right
              style={{ marginTop: 0 }}
              items={[
                ...(isButtonShow
                  ? [
                      {
                        onClick: onApplyUpdates,
                        text: (
                          <>
                            <AutorenewRounded /> &nbsp;
                            {locale.applyUpdates}
                          </>
                        ),
                        primary: true,
                        outline: true,
                        className: styles.buttonLink,
                      },
                    ]
                  : []),
                {
                  onClick: () =>
                    accumulatedSpecificModal?.show({
                      title: locale.applyAccountSpecificMovement,
                    }),
                  text: locale.applyPricingMovement,
                  primary: true,
                  outline: true,
                  disabled:
                    isButtonShow ||
                    fields.accountType.value === FleetAccountType.KeyAccount ||
                    initialFormState.accountType.value === FleetAccountType.KeyAccount,
                },
              ]}
            />
          </div>
        )}
      </div>
      <Field
        className={styles.fieldLabel}
        label={locale.referenceStation}
        labelClassName={styles.fieldLabelInfo}
        labelPosition="left"
        childrenClassName={styles.fieldContent}
      >
        {initialFormLoading ? (
          <Skeleton />
        ) : isEdit ? (
          <SelectStation
            {...fields.referenceStation}
            disableAllOption
            isPlbOnboarded
            platformType={PlatformType.PLB}
            isOnboarding
            onChange={(name, { value, label }) => {
              modifyField(fields?.referenceStation?.name, {
                value,
                label,
                isUpdate: initialFormState?.referenceStation?.value !== value,
              });
              if (value !== fields?.referenceStation?.value) {
                getCurrentInfo.request({
                  stationId: value,
                  effectiveDate: priceAssessmentDate,
                });
                updateCurrentInfo();
              }
            }}
          />
        ) : (
          <Text>{fields?.referenceStation?.label}</Text>
        )}
      </Field>
      <Field
        className={styles.fieldLabel}
        label={locale.referenceStationType}
        labelClassName={styles.fieldLabelInfo}
        labelPosition="left"
        childrenClassName={styles.fieldContent}
      >
        {isLoading ? (
          <Skeleton />
        ) : isEdit ? (
          <Autocomplete
            disabled
            {...fields.referenceStationType}
            options={[
              { label: COCO, value: COCO },
              { label: CODO, value: CODO },
              { label: COXO, value: COXO },
              { label: DODO, value: DODO },
              { label: DODOX, value: DODOX },
              { label: WIBCO, value: WIBCO },
              { label: WIDOX, value: WIDOX },
              { label: WILCO, value: WILCO },
            ]}
            onChange={(_, { value, isAll }) => {
              modifyField(fields?.referenceStationType?.name, { value, label: value });
            }}
          />
        ) : (
          <Text>{fields?.referenceStationType?.value}</Text>
        )}
      </Field>
      <Field
        {...fields.depot}
        className={styles.fieldLabel}
        label={locale.depot}
        labelClassName={styles.fieldLabelInfo}
        labelPosition="left"
        childrenClassName={styles.fieldContent}
      >
        {isLoading ? (
          <Skeleton />
        ) : isEdit ? (
          <TextField value={fields?.depot.label} disabled inputDisabled />
        ) : (
          <Text>{fields?.depot.label}</Text>
        )}
      </Field>
      <Field
        {...fields.accountType}
        className={styles.fieldLabel}
        label={locale.accountType}
        labelClassName={styles.fieldLabelInfo}
        labelPosition="left"
        childrenClassName={styles.fieldContent}
      >
        {isLoading ? (
          <Skeleton />
        ) : isEdit ? (
          <Autocomplete
            {...fields.accountType}
            options={accountTypeOption}
            onChange={(_, { value, isAll }) => {
              modifyField(fields?.accountType?.name, {
                value,
                label: value,
                isUpdate: initialFormState?.accountType?.value !== value,
              });
            }}
          />
        ) : (
          <Text>{prettifyFleetAccountType(fields?.accountType?.value)}</Text>
        )}
      </Field>
      <Field
        {...fields.inventoryLimit}
        className={styles.fieldLabel}
        label={locale.inventoryLimit}
        labelClassName={styles.fieldLabelInfo}
        labelPosition="left"
        childrenClassName={styles.fieldContent}
      >
        {isLoading ? <Skeleton /> : <Text>{formatNumber(fields?.inventoryLimit.value, 2)}</Text>}
      </Field>
      <Field
        className={styles.fieldLabel}
        label={locale.redemptionStations}
        labelClassName={styles.fieldLabelInfo}
        labelPosition="left"
        childrenClassName={styles.fieldContent}
      >
        {initialFormLoading ? (
          <Skeleton />
        ) : isEdit ? (
          <SelectRedemptionStation
            {...fields.redemptionStation}
            {...(fields?.accountType?.value === FleetAccountType.KeyAccount && {
              disabled: true,
              inputDisabled: true,
            })}
            onChange={(name, { value, label, isAll }) => {
              modifyField(fields?.redemptionStation?.name, {
                value,
                label,
                isAll,
                isUpdate: true,
              });
            }}
            params={{
              isPlbOnboarded: true,
              isOnboarding: true,
              platformType: PlatformType.PLB,
            }}
          />
        ) : fields.redemptionStation.isAll ? (
          <Text>All Stations</Text>
        ) : (
          <PopOverList list={fields.redemptionStation?.value.map((station) => station?.label)}>
            <Button link>{locale.view}</Button>
          </PopOverList>
        )}
      </Field>
      <Field
        className={classNames(
          styles.fieldLabel,
          styles.fieldPricingLabel,
          styles.fieldPricingLabelPosition
        )}
        horizontal
      >
        <div className={classNames(styles.horizontalInput)}>
          <Text xsmall className={styles.gasTitle}>
            {locale.refsPumpPriceAtAssessment}
          </Text>
          <Text xsmall className={styles.gasTitle}>
            {locale.agreedDiscounts}
          </Text>
          <Text xsmall className={styles.gasTitle}>
            {locale.accuMovementSinceAssessment}
          </Text>
          <Text xsmall className={styles.gasTitle}>
            {locale.accumulatedStationSpecificMovement}
          </Text>
          <Text xsmall className={styles.gasTitle}>
            {locale.accuSpecificMovement}
            {isEdit && (showMatchPumpPriceModal || comparePumpPrice()) && (
              <ErrorRounded
                style={{
                  fontSize: 18,
                  marginRight: "10px",
                  color: "#ffaa01",
                  cursor: "pointer",
                }}
                onClick={() =>
                  matchPriceModal?.show({
                    title: locale.matchRefSPumpPrice,
                    stateChange: true,
                  })
                }
              />
            )}
          </Text>
          <Text xsmall className={styles.gasTitle}>
            {locale.contractPrice}
          </Text>
          <Text xsmall className={styles.gasTitle}>
            {locale.sdum}
          </Text>
          <Text xsmall className={styles.gasTitle}>
            {locale.freight}
          </Text>
          <Text xsmall className={styles.gasTitle}>
            {locale.estimateMonthlyVolume}
          </Text>
          <Text xsmall className={styles.gasTitle}>
            {locale.existingVolume}
          </Text>
        </div>
      </Field>
      {Object.values(GasType).map((gas, key) => (
        <Field
          className={classNames(
            isEdit || isLoading ? styles.fieldFormLabel : styles.fieldLabel,
            styles.fieldPricingLabel
          )}
          label={locale[gas.toLowerCase()]}
          horizontal
        >
          {onboardingInfo && (
            <TableForm
              gasType={gas}
              fields={fields}
              modifyForm={modifyForm}
              isLoading={isLoading}
              initialFormState={initialFormState}
              matchPumpPriceFields={matchPumpPriceFields}
              computeContractPrice={computeContractPrice}
              isEdit={isEdit}
              currentInfo={getCurrentInfo.result ? getCurrentInfo?.result[key] : null}
              hasSpecificAmount
            />
          )}
        </Field>
      ))}
    </div>
  );
};

export default PricingForm;
