import { ApiPath } from "enums";
import { Get, Post, Put } from "utils";

export const getDrivers = async ({ fleetId, ...query }) => {
  const res = await Get(`${ApiPath.GetDrivers}/${fleetId}`, query);
  return res.data;
};

export const createDriver = async ({ ...body }) => {
  const res = await Post(`${ApiPath.Driver}`, body);
  return res.data;
};

export const updateDriver = async ({ driverId, ...body }) => {
  const res = await Put(`${ApiPath.Driver}/${driverId}`, body);
  return res.data;
};
