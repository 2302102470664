import React from "react";
import moment from "moment";
import { Field, Title, DateRange } from "components/commons";

import { SelectStation } from "components/field";

import locale from "localization";

import styles from "./process-settlement.module.scss";

const ProcessSettlementForm = ({
  fields,
  modifyField,
  modifyForm,
  generateReportLoading,
  setDownloadUrl,
}) => {
  return (
    <div>
      <Title black xsmall>
        {locale.processSettlement}
      </Title>
      <div
        style={{
          width: "600px",
        }}
      >
        <Field
          className={styles.fieldSelect}
          label={locale.stationBusinessName}
          labelPosition="left"
          labelClassName={styles.fieldLabel}
          childrenClassName={styles.fieldContent}
        >
          <SelectStation
            {...fields?.station}
            disabled={generateReportLoading}
            labelKey={"businessName"}
            disableAllOption
            onChange={(_, { value, label, name }) => {
              modifyForm({
                [fields?.redemptionStationId?.name]: {
                  value,
                  label: name,
                },
                [fields?.station?.name]: {
                  value,
                  label,
                },
              });
            }}
          />
        </Field>
        <Field
          className={styles.fieldSelect}
          label={locale.redemptionStation}
          labelPosition="left"
          labelClassName={styles.fieldLabel}
          childrenClassName={styles.fieldContent}
        >
          <SelectStation
            {...fields?.redemptionStationId}
            disabled={generateReportLoading}
            disableAllOption
            onChange={(name, { value, label, businessName }) => {
              modifyForm({
                [fields?.redemptionStationId?.name]: {
                  value,
                  label,
                },
                [fields?.station?.name]: {
                  value,
                  label: businessName,
                },
              });
            }}
          />
        </Field>
        <Field
          className={styles.date}
          label={locale.dateToBeSettled}
          labelPosition="left"
          labelClassName={styles.fieldLabel}
          childrenClassName={styles.fieldContent}
        >
          <DateRange
            {...fields.settlementDate}
            disabled={generateReportLoading}
            minDate={fields?.settlementDate?.startDate ?? new Date("2019-11-05")}
            maxDate={new Date(moment().subtract(1, "day").endOf("day"))}
            value={{
              startDate: fields.settlementDate.value?.startDate,
              endDate: fields.settlementDate.value?.endDate,
            }}
            onChange={(_, { value }) => {
              const { startDate, endDate } = value;
              modifyField(fields?.settlementDate?.name, {
                value: {
                  startDate,
                  endDate,
                },
              });
              setDownloadUrl(null);
            }}
          />
        </Field>
      </div>
    </div>
  );
};

export default ProcessSettlementForm;
