import React from "react";
import { useHistory } from "react-router-dom";
import locale from "localization";
import styles from "./discount-view.module.scss";
import { FormMode } from "enums";
import { Path } from "enums";
import { Intro, BackLink } from "components/commons";
import DiscountDetailsModule from "../discount-details/discount-details.module";

const DiscountViewModule = (props) => {
  const history = useHistory();
  const discountCampaignId = props.location.state ? props.location.state.discountCampaignId : "";
  const stationCount = props.location.state ? props.location.state.stationCount : "";

  return (
    <div>
      <BackLink text={locale.discountCampaigns} path={Path.Campaigns} />
      <div className={styles.intro}>
        <Intro
          title={locale.discountCampaignDetails}
          actionText={locale.edit}
          actionOnClick={() => {
            history.push(Path.EditDiscountCampaign, {
              discountCampaignId,
              stationCount,
            });
          }}
          actionOutlined
          actionClassName={styles.primaryEditButton}
          actionStartIcon={<span className="icon-pencil" />}
        />
        <DiscountDetailsModule
          pageMode={FormMode.View}
          discountCampaignId={discountCampaignId}
          stationCount={stationCount}
        />
      </div>
    </div>
  );
};

export default DiscountViewModule;
