const DateTime = {
  A: "D MMM YYYY",
  B: "h:mma",
  C: "YYYY-MM-DDT00:00:00ZZ",
  D: "YYYY-MM-DDT23:59:59ZZ",
  E: "MMM DD, YYYY",
  F: "MMMM YYYY",
  G: "D MMM YYYY h:mma",
  H: "MMMM DD, YYYY hh:mmA",
  I: "YYYY-MM-DDTHH:mm:ssZZ",
  J: "YYYY-MM-DDT04:00:00ZZ",
  K: "YYYY-MM-DD",
  L: "HH:mm:ssZZ",
  M: "MMMM YYYY",
  N: "MMMM",
  O: "YYYY",
  P: "MMM DD, YYYY hh:mm A",
};

export default DateTime;
