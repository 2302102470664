import classNames from "classnames";
import { Text, FuelIcon } from "components/commons";
import { locale } from "localization/en";
import React from "react";
import { formatAmount, formatVolume } from "utils";
import { prettifyProduct } from "utils/pretty.utils";
import styles from "./fuel-card.module.scss";
import Skeleton from "@material-ui/lab/Skeleton";

const FuelCard = ({
  fuelBalance,
  type,
  active,
  onClick,
  withPrice,
  price,
  creditPrice,
  disabled,
  discount,
  loadingBalance,
}) => {
  return (
    <div
      className={classNames(styles.fuelCard, {
        [`${styles.active}`]: active,
        [`${styles.withPrice}`]: withPrice,
        [`${styles.disabled}`]: disabled,
      })}
      onClick={onClick}
    >
      <div className={classNames(styles.nameBalance, { [`${styles.withPrice}`]: withPrice })}>
        <FuelIcon type={type} disabled={disabled} />
        <div>
          <Text className={classNames(styles.title, { [`${styles.disabled}`]: disabled })}>
            {prettifyProduct(type)}
          </Text>
          {loadingBalance ? (
            <Skeleton variant="text" />
          ) : (
            <Text className={styles.subtitle}>{`${locale.balance}: ${formatVolume(
              fuelBalance
            )}`}</Text>
          )}
        </div>
      </div>
      {withPrice && (
        <div className={styles.price}>
          <Text
            className={classNames(styles.title, styles.creditPrice, {
              [`${styles.disabled}`]: disabled,
            })}
          >{`${creditPrice > 0 ? formatAmount(creditPrice, "PHP") : "PHP - -"} / ${
            locale.liter
          }`}</Text>
          {discount > 0 && (
            <Text className={classNames(styles.subtitle, styles.originalPrice)}>{`${formatAmount(
              price,
              "PHP"
            )} / ${locale.liter}`}</Text>
          )}
        </div>
      )}
    </div>
  );
};

export default FuelCard;
