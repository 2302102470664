import React from "react";
import { Route, Switch, Redirect } from "react-router";
import { Path } from "enums";
import StationAccountListModule from "modules/station-accounts/account-list/account-list.module";

const StationAccountsPage = () => {
  return (
    <Switch>
      <Route exact path={Path.StationAccounts} component={StationAccountListModule} />
      <Redirect to={Path.StationAccounts} />
    </Switch>
  );
};

export default StationAccountsPage;
