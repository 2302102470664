import React, { useState, useCallback, useEffect, useMemo } from "react";
import locale from "localization";
import styles from "./discount-details.module.scss";
import {
  Field,
  TextField,
  Title,
  Button,
  Text,
  PopOverMenu,
  DateRange,
  DropdownMultiple,
  Image,
  PopOverList,
  Dropdown,
  Radio,
} from "components/commons";
import { InputAmount, InputLitre } from "components/field";
import { dateFormat, formatCurrency } from "utils";
import { CampaignStatus } from "enums";
import classNames from "classnames";
import { RemoveIcon } from "images";
import moment from "moment";
import { prettifyShoulderedBy } from "utils/pretty.utils";
import CheckIcon from "@material-ui/icons/Check";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";

const DiscountDetailsFormModule = ({ ...stateForm }) => {
  const {
    viewMode,
    addMode,
    fields,
    isFormSubmittable,
    modifyField,
    onDateRangeCb,
    onSetPaymentMethodRestrictionCb,
    loading,
    handleSubmit,
    preparedAttributes,
    attributeInput,
    removeAttribute,
    selectAccountType,
    selectCohorts,
    selectedAttributeCohort,
    isAllCohort,
    otherTargetAttribute,
    setOtherTargetAttribute,
    getAccountTypesResult,
    removeAccountType,
    stationsModal,
    importStationModal,
    setStationInputs,
    campaignStatus,
    fetchStations,
    onChangeShoulderedByCb,
  } = stateForm;
  const [activeClicked, setActiveClicked] = useState(false);
  const { accountTypes } = getAccountTypesResult;
  const ongoingCampaign = campaignStatus === CampaignStatus.Ongoing;
  const endedCampaign = campaignStatus === CampaignStatus.Ended;
  const cancelledCampaign = campaignStatus === CampaignStatus.Cancelled;
  const disabledMonthlyVolumeCap =
    fields?.paymentMethodRestriction?.value === locale.no ? true : false;

  const openTargetStations = useCallback(
    (perPage) => {
      stationsModal.show();
      fetchStations({ reset: true, perPage });
    },
    [fetchStations, stationsModal]
  );

  const openImportModal = useCallback(
    (perPage) => {
      importStationModal.show();
      fetchStations({ reset: true, perPage });
    },
    [fetchStations, importStationModal]
  );

  // const isValidFormFields = useMemo(() => {
  //   let error = true;
  //   [fields?.accountTypes?.value?.length, fields?.stations?.value?.length].forEach((field) => {
  //     if (field && error) {
  //       error = false;
  //     }
  //   });
  //   if (error) {
  //     Object.keys(fields?.cohorts?.value).forEach((key) => {
  //       const value = fields?.cohorts?.value[key];
  //       if (value.length) {
  //         error = false;
  //       }
  //     });
  //   }
  //   return !error;
  // }, [fields]);
  useEffect(() => {
    if (addMode) {
      if (fields?.paymentMethodRestriction?.value === "") {
        modifyField(fields?.paymentMethodRestriction?.name, {
          value: locale.no,
        });
      }
    }
  }, [addMode, modifyField, fields?.paymentMethodRestriction]);

  const disabledAttribute = useMemo(() => {
    return loading || preparedAttributes.length === 0 || endedCampaign || cancelledCampaign;
  }, [loading, preparedAttributes, endedCampaign, cancelledCampaign]);
  return (
    <div className={styles.container}>
      <Title black xsmall>
        {locale.businessInformation}
      </Title>
      <Field className={styles.fieldLabel} {...fields?.campaignName}>
        {viewMode ? (
          <Text className={styles.fieldText}>{fields?.campaignName?.value}</Text>
        ) : (
          <TextField
            {...fields?.campaignName}
            onChange={modifyField}
            disabled={loading || ongoingCampaign || endedCampaign || cancelledCampaign}
          />
        )}
      </Field>
      <Field className={styles.fieldLabel} {...fields?.campaignDuration}>
        {viewMode ? (
          <Text className={styles.fieldText}>
            {`${dateFormat(fields?.campaignDuration?.value?.startDate)} to ${dateFormat(
              fields?.campaignDuration?.value?.endDate
            )}`}
          </Text>
        ) : (
          <DateRange
            className={styles.dateRange}
            value={{
              startDate: fields?.campaignDuration?.value?.startDate,
              endDate: fields?.campaignDuration?.value?.endDate,
            }}
            minDate={moment().toDate()}
            onChange={onDateRangeCb}
            disableStartDate={endedCampaign || ongoingCampaign || cancelledCampaign}
            disableEndDate={cancelledCampaign}
          />
        )}
      </Field>
      <Title black xsmall>
        {locale.discount}
      </Title>
      <Field className={styles.fieldLabel} {...fields?.diesel}>
        {viewMode ? (
          <Text className={classNames(styles.fieldText, styles.gasAmount)}>
            {formatCurrency(fields?.diesel?.value)}
          </Text>
        ) : (
          <InputAmount
            className={styles.gasAmount}
            {...fields?.diesel}
            onChange={modifyField}
            disabled={loading || endedCampaign || cancelledCampaign}
          />
        )}
      </Field>
      <Field className={styles.fieldLabel} {...fields?.gas91}>
        {viewMode ? (
          <Text className={classNames(styles.fieldText, styles.gasAmount)}>
            {formatCurrency(fields?.gas91?.value)}
          </Text>
        ) : (
          <InputAmount
            className={styles.gasAmount}
            {...fields?.gas91}
            onChange={modifyField}
            disabled={loading || endedCampaign || cancelledCampaign}
          />
        )}
      </Field>
      <Field className={styles.fieldLabel} {...fields?.gas95}>
        {viewMode ? (
          <Text className={classNames(styles.fieldText, styles.gasAmount)}>
            {formatCurrency(fields?.gas95?.value)}
          </Text>
        ) : (
          <InputAmount
            className={styles.gasAmount}
            {...fields?.gas95}
            onChange={modifyField}
            disabled={loading || endedCampaign || cancelledCampaign}
          />
        )}
      </Field>
      <Field className={styles.fieldLabel} {...fields?.gas97}>
        {viewMode ? (
          <Text className={classNames(styles.fieldText, styles.gasAmount)}>
            {formatCurrency(fields?.gas97?.value)}
          </Text>
        ) : (
          <InputAmount
            className={styles.gasAmount}
            {...fields?.gas97}
            onChange={modifyField}
            disabled={loading || endedCampaign || cancelledCampaign}
          />
        )}
      </Field>

      <Field className={styles.fieldLabel} label={locale.shoulderedBy} horizontal>
        {viewMode ? (
          <Text className={classNames(styles.fieldText, styles.redemptionLimitDetailsDisplay)}>
            {`${prettifyShoulderedBy(fields.shoulderedBy.value)}`}
          </Text>
        ) : (
          <Dropdown
            className={styles.shoulderedBy}
            onChange={onChangeShoulderedByCb}
            disabled={loading || endedCampaign || ongoingCampaign}
            {...fields.shoulderedBy}
          />
        )}
      </Field>

      <Title black xsmall>
        {locale.targetUsers}
      </Title>
      {(otherTargetAttribute.accountType || fields?.accountTypes?.value?.length > 0) && (
        <Field className={styles.fieldLabel} {...fields?.accountTypes}>
          {viewMode ? (
            <Text className={classNames(styles.fieldText, styles.accountTypes)}>
              {fields?.accountTypes?.value
                ?.map(({ label }) => {
                  return label;
                })
                .join(", ")}
            </Text>
          ) : (
            <div className={styles.attributesInput}>
              <DropdownMultiple
                {...fields?.accountTypes}
                options={accountTypes.map((accountType) => {
                  return {
                    label: accountType?.name,
                    value: accountType?.accountTypeId,
                  };
                })}
                onChange={selectAccountType}
                classNameDropdown={styles.cohortDropdown}
                total={accountTypes?.length}
                isAll={accountTypes?.length === fields?.accountTypes?.value?.length}
                disabled={loading || endedCampaign || cancelledCampaign}
              />
              {!endedCampaign && (
                <span onClick={removeAccountType} className={styles.removeCohort}>
                  <Image src={RemoveIcon} />
                </span>
              )}
            </div>
          )}
        </Field>
      )}
      {attributeInput?.map((attribute) => (
        <Field
          className={styles.fieldLabel}
          label={attribute?.name}
          horizontal
          key={attribute?.attributeId}
        >
          {viewMode ? (
            selectedAttributeCohort[attribute?.attributeId]?.length ? (
              <PopOverList
                list={selectedAttributeCohort[attribute?.attributeId]?.map(({ name }) => {
                  return name;
                })}
              >
                <span className={styles.linkDetails}>
                  {
                    <locale.Populate
                      text={locale.numberAttributeName}
                      items={[
                        selectedAttributeCohort[attribute?.attributeId]?.length,
                        attribute?.name,
                      ]}
                    />
                  }
                </span>
              </PopOverList>
            ) : (
              <span className={styles.usersWithoutThisAttribute}>
                {locale.usersWithoutThisAttribute}
              </span>
            )
          ) : (
            <div className={styles.attributesInput}>
              <DropdownMultiple
                key={attribute?.attributeId}
                name={attribute?.attributeId?.toString()}
                options={attribute?.cohorts?.map((cohort) => {
                  return {
                    label: cohort.name,
                    value: cohort.cohortId,
                  };
                })}
                onChange={selectCohorts}
                label={attribute?.name}
                classNameDropdown={styles.cohortDropdown}
                value={selectedAttributeCohort[attribute?.attributeId]}
                total={attribute?.cohorts?.length}
                isAll={isAllCohort[attribute?.attributeId]}
                allSelectedText={[locale.all, attribute?.name].join(" ")}
                disabled={loading || endedCampaign || cancelledCampaign}
              />
              {!endedCampaign && (
                <span
                  onClick={() => {
                    removeAttribute(attribute?.name, attribute?.attributeId);
                  }}
                  className={styles.removeCohort}
                >
                  <Image src={RemoveIcon} />
                </span>
              )}
            </div>
          )}
        </Field>
      ))}
      {(otherTargetAttribute.station || fields?.stations?.value?.length > 0) && (
        <Field className={styles.fieldLabel} label={locale.eligibleReferenceStation} horizontal>
          {viewMode ? (
            <PopOverList
              list={fields?.stations?.value?.map(({ label }) => {
                return label;
              })}
              disabled={loading || endedCampaign || cancelledCampaign}
            >
              <span className={styles.linkDetails}>
                {
                  <locale.Populate
                    text={locale.numberStations}
                    items={[fields?.stations?.value?.length]}
                  />
                }
              </span>
            </PopOverList>
          ) : (
            <div className={styles.attributesInput}>
              {fields?.stations?.value?.length === 0 ? (
                <>
                  <Button
                    startIcon={<CheckIcon />}
                    onClick={() => {
                      const perPage = 10;
                      openTargetStations(perPage);
                    }}
                    outline
                  >
                    {locale.selectStations}
                  </Button>
                  <Button
                    startIcon={<GetAppRoundedIcon />}
                    onClick={() => {
                      const perPage = 1000;
                      openImportModal(perPage);
                    }}
                    outline
                  >
                    {locale.importCSV}
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    startIcon={<span className="icon-pencil" />}
                    onClick={() => {
                      const perPage = 10;
                      setStationInputs(fields?.stations?.value);
                      openTargetStations(perPage);
                    }}
                    outline
                    disabled={loading || endedCampaign || cancelledCampaign}
                  >
                    {locale.editStations}
                  </Button>
                  <Button
                    startIcon={<GetAppRoundedIcon />}
                    onClick={() => {
                      const perPage = 1000;
                      setStationInputs(fields?.stations?.value);
                      openImportModal(perPage);
                    }}
                    outline
                    disabled={loading || endedCampaign || cancelledCampaign}
                  >
                    {locale.importCSV}
                  </Button>
                  <span className={styles.numberStationSelected}>
                    {
                      <locale.Populate
                        text={locale.stationsEligibleSelected}
                        items={[fields?.stations?.value?.length]}
                      />
                    }
                  </span>
                </>
              )}

              {!endedCampaign && (
                <span
                  onClick={() => {
                    modifyField(fields?.stations?.name, { value: [] });
                    setOtherTargetAttribute({ ...otherTargetAttribute, station: false });
                  }}
                  className={styles.removeCohort}
                >
                  <Image src={RemoveIcon} />
                </span>
              )}
            </div>
          )}
        </Field>
      )}
      {!viewMode && (
        <PopOverMenu
          options={preparedAttributes}
          position="top"
          disabledClick={disabledAttribute}
          setActiveClicked={setActiveClicked}
        >
          <Button
            className={styles.addCustomAttributeButton}
            outline={!activeClicked}
            primary={activeClicked}
            disabled={
              loading || preparedAttributes.length === 0 || endedCampaign || cancelledCampaign
            }
          >
            {locale.addAttribute}
          </Button>
        </PopOverMenu>
      )}
      <Field
        className={styles.fieldLabel}
        textStyle={{ color: "#34384b", fontWeight: "bold" }}
        {...fields?.paymentMethodRestriction}
        horizontal
      >
        {viewMode ? (
          <Text className={styles.fieldText}>{fields?.paymentMethodRestriction?.value}</Text>
        ) : (
          <Radio
            className={styles.radioContainer}
            radioClassName={styles.radio}
            value={fields.paymentMethodRestriction.value}
            options={[
              { text: locale.yes, value: locale.yes },
              { text: locale.no, value: locale.no },
            ]}
            onChange={onSetPaymentMethodRestrictionCb}
          />
        )}
      </Field>
      {fields?.paymentMethodRestriction?.value === locale.yes && (
        <Field className={styles.fieldLabel} {...fields?.bins}>
          {viewMode ? (
            <Text className={styles.fieldText}>{fields?.bins?.value}</Text>
          ) : (
            <>
              <TextField {...fields?.bins} onChange={modifyField} disabled={loading} />
              <Text subtitle className={styles.fieldText}>
                {locale.binNote}
              </Text>
            </>
          )}
        </Field>
      )}
      <Field
        className={styles.fieldLabel}
        textStyle={{ color: "#34384b", fontWeight: "bold" }}
        {...fields?.monthlyVolumeCap}
      >
        {viewMode ? (
          <Text className={classNames(styles.fieldText, styles.monthlyVolumeCap)}>
            {fields?.monthlyVolumeCap?.value}
          </Text>
        ) : (
          <InputLitre
            {...fields?.monthlyVolumeCap}
            value={fields?.monthlyVolumeCap?.value}
            onChange={modifyField}
            innerLabel={""}
            isMonthlyVolume={true}
            disabled={loading || endedCampaign || cancelledCampaign || disabledMonthlyVolumeCap}
            inputDisabled={
              loading || endedCampaign || cancelledCampaign || disabledMonthlyVolumeCap
            }
          />
        )}
      </Field>
      {addMode && (
        <Button
          primary
          className={styles.continue}
          loading={loading}
          disabled={!isFormSubmittable}
          onClick={handleSubmit}
        >
          {locale.create}
        </Button>
      )}
    </div>
  );
};

export default DiscountDetailsFormModule;
