import { BackLink, Intro } from "components/commons";
import { FormMode, Path } from "enums";
import { useModal } from "hooks";
import locale from "localization";
import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import AccountTypesDetailsModule from "../account-type-details/account-type-details.module";
import styles from "./account-type-edit.module.scss";

const EditAccountTypeModule = (props) => {
  const accountTypeDetailsRef = useRef();
  const history = useHistory();
  const [updateLoading, setUpdateLoading] = useState(false);
  const [disableUpdate, setDisableUpdate] = useState(false);
  const accountTypeId = props.location.state ? props.location.state.accountTypeId : "";
  const successModal = useModal();

  return (
    <div>
      <BackLink text={locale.accountTypes} path={Path.AccountTypes} />
      <div className={styles.intro}>
        <Intro
          actionText={locale.save}
          actionOnClick={() => {
            accountTypeDetailsRef.current.handleUpdate();
          }}
          actionClassName={styles.primarySaveButton}
          actionLoading={updateLoading}
          actionDisabled={disableUpdate}
          actionSecondaryText={locale.cancel}
          actionSecondaryOutlined
          actionSecondaryOnClick={() => {
            history.goBack();
          }}
          actionSecondaryClassName={styles.secondarySaveButton}
          title={locale.editAccountType}
          titleStyle={styles.title}
        />
        <AccountTypesDetailsModule
          pageMode={FormMode.Edit}
          accountTypeId={accountTypeId}
          setUpdateLoading={setUpdateLoading}
          setDisableUpdate={setDisableUpdate}
          ref={accountTypeDetailsRef}
          {...successModal}
        />
      </div>
    </div>
  );
};

export default EditAccountTypeModule;
