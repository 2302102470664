import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Pill, PopOverMenu, Text } from "components/commons";
import locale from "localization";
import { formatAmount, formatVolume, prettifySettlementStatus, formatDate } from "utils";
import { SettlementStatus, Path } from "enums";

import styles from "./settlement.module.scss";

export const mapDataToList = ({ settlements, history }) => {
  const {
    station,
    totalLiter,
    totalDealerSettlement,
    dealerSettlementStatus,
    totalPayWithPeso,
    totalMdrFee,
    totalLocqRumSdumfDifferential,
    totalLocqRum,
    periodEndDate,
    periodStartDate,
  } = settlements;

  return {
    redemptionStation: (
      <>
        <Text>{station?.name}</Text>
        <Text className={styles.subBusinessId}>{station?.businessName}</Text>
      </>
    ),
    totalLiterRedemption: formatVolume(totalLiter),
    totalPayWithPeso: formatAmount(totalPayWithPeso),
    totalMDRFee: formatAmount(totalMdrFee),
    totalRumSDUMFDifferential: formatAmount(totalLocqRumSdumfDifferential),
    totalRumBuy: formatAmount(totalLocqRum),
    totalDealerPayout: formatAmount(totalDealerSettlement),
    status: (
      <>
        <Pill
          cyan={
            dealerSettlementStatus === SettlementStatus.ForProcessing ||
            dealerSettlementStatus === SettlementStatus.ForSettlement
          }
          grass={
            dealerSettlementStatus === SettlementStatus.Processed ||
            dealerSettlementStatus === SettlementStatus.Settled
          }
          cement={dealerSettlementStatus === SettlementStatus.Cancelled}
        >
          {prettifySettlementStatus(dealerSettlementStatus)}
        </Pill>
      </>
    ),
    action: (
      <PopOverMenu
        options={[
          {
            content: locale.processSettlement,
            onClick: () =>
              history.push(Path.ProcessSettlement, {
                station: {
                  value: station?.stationId,
                  label: station?.businessName,
                },
                redemptionStationId: {
                  value: station?.stationId,
                  label: station?.name,
                },
                settlementDate: {
                  value: {
                    startDate: new Date(formatDate(periodStartDate)),
                    endDate: new Date(formatDate(periodEndDate)),
                  },
                },
              }),
          },
        ]}
      >
        <MoreVertIcon className={styles.icon} />
      </PopOverMenu>
    ),
  };
};

export const mapFilterToRequest = (filterState) => {
  const { ...fs } = filterState;
  return {
    ...fs,
  };
};
