import { Autocomplete, DateRange, Field, SearchField, Filter, Button } from "components/commons";
import React from "react";

import styles from "./new-customer-vouchers-filter.module.scss";
import locale from "localization";
import { voucherCurrencyOptions } from "data/select-options";
import { useCallback } from "react";
import VoucherStatus from "enums/voucher-status";
import { prettifyVoucherStatus } from "utils/pretty.utils";

const NewCustomerVoucherFilter = ({
  filterState,
  modifyFilters,
  filterCount,
  fetch,
  clearFilter,
  submittedFilter,
  exporting,
  exportReport,
}) => {
  const { voucherCurrency, statuses } = filterState;

  const handleSubmitFilter = useCallback(
    (param) => {
      fetch({ ...param, page: 1 });
    },
    [fetch]
  );

  return (
    <div className={styles.container}>
      <div className={styles.filter}>
        <Filter
          close={() => {
            modifyFilters(submittedFilter);
          }}
          clear={() => {
            const { requestState } = clearFilter();
            handleSubmitFilter(requestState);
          }}
          submit={() => {
            const { requestState } = modifyFilters({ page: 1 });
            handleSubmitFilter(requestState);
          }}
          filterCount={filterCount}
          disabled={!voucherCurrency.length || !statuses.length}
        >
          <Field
            className={styles.date}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
            label={locale.dateRedeemedRange}
          >
            <DateRange
              value={{
                startDate: filterState.startDate,
                endDate: filterState.endDate,
              }}
              onChange={(name, { value }) => {
                const { startDate, endDate } = value;
                modifyFilters({
                  startDate,
                  endDate,
                });
              }}
            />
          </Field>
          {[
            {
              label: locale.currency,
              value: voucherCurrency,
              name: "voucherCurrency",
              options: voucherCurrencyOptions,
            },
            {
              label: locale.status,
              value: statuses,
              name: "statuses",
              options: [
                {
                  value: VoucherStatus.Available,
                  label: prettifyVoucherStatus(VoucherStatus.Available),
                },
                {
                  value: VoucherStatus.Claimed,
                  label: prettifyVoucherStatus(VoucherStatus.Claimed),
                },
                {
                  value: VoucherStatus.Expired,
                  label: prettifyVoucherStatus(VoucherStatus.Expired),
                },
              ],
            },
          ].map(({ label, value, name, options }, key) => {
            return (
              <Field
                key={key}
                labelPosition={"left"}
                childrenClassName={styles.fieldContent}
                className={styles.fieldSelect}
                label={label}
              >
                <Autocomplete
                  capitalize={false}
                  name={name}
                  hasAll
                  multiple
                  value={value}
                  options={options}
                  onChange={(name, { value }) => {
                    modifyFilters({ [name]: value });
                  }}
                />
              </Field>
            );
          })}
        </Filter>
        <Field className={styles.search}>
          <SearchField
            placeholder={[
              // locale.voucherCode,
              locale.mobileNumber,
            ].join(", ")}
            value={filterState.searchKey}
            onChange={(name, { value }) => {
              modifyFilters({
                searchKey: value,
              });
            }}
            onEnter={() => {
              handleSubmitFilter({ searchKey: filterState.searchKey });
            }}
            onClear={() => {
              modifyFilters({
                searchKey: "",
              });
              handleSubmitFilter({ searchKey: "" });
            }}
          />
        </Field>
        <Field className={styles.downloadButton}>
          <Button
            startIcon={<span className="icon-download" />}
            exportButton
            onClick={exportReport}
            loading={exporting}
          >
            {locale.export}
          </Button>
        </Field>
      </div>
    </div>
  );
};

export default NewCustomerVoucherFilter;
