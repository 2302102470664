import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Path from "./enums/path";
import AuthPage from "./pages/auth.page";
import AuthorizedPage from "./pages/authorized.page";
import AuthRoute from "./routes/auth.route";
import AuthorizedRoute from "./routes/authorized.route";
import { AppContext } from "contexts";
import { ErrorModal } from "components/modals";
import React, { useState, useCallback } from "react";
import { useModal } from "hooks";
import { Image } from "components/commons";
import { CautionSign } from "images";
import CssBaseline from "@material-ui/core/CssBaseline";
import { StylesProvider } from "@material-ui/core/styles";
import ErrorPage from "pages/error.page";
import { CarCrash } from "images";
import UnauthorizedPage from "pages/unauthorized.page";
import firebase from "firebase/compat/app";
import env from "environments/env";
import useBeforeMount from "hooks/useBeforeMount";
import FeatureFlagPage from "pages/featureflag.page";
import AppLoader from "components/commons/app-loader/app-loader";
import ActivateAccountModule from "modules/activate-account/activate-account.module";

function App() {
  const [appState, setAppState] = useState({ loading: false });
  const errorModal = useModal();
  const [pageError, setPageError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(null);
  const [initializing, setInitializing] = useState(true);
  const toggleLoading = useCallback((loading) => {
    setAppState((state) => {
      return { ...state, loading };
    });
  }, []);

  useBeforeMount(async () => {
    await firebase.initializeApp(env.FIREBASE_CONFIG);
    setInitializing(false);
  });
  return (
    <FeatureFlagPage>
      <AppContext.Provider
        value={{
          toggleLoading,
          errorModal,
          appState,
          pageError,
          setPageError,
          errorStatus,
          setErrorStatus,
        }}
      >
        {initializing ? (
          <AppLoader />
        ) : (
          <StylesProvider injectFirst>
            <ErrorModal {...errorModal} />
            <CssBaseline />
            <Router>
              <div className="App">
                {pageError ? (
                  errorStatus === 403 || errorStatus === 401 ? (
                    <UnauthorizedPage />
                  ) : (
                    <ErrorPage />
                  )
                ) : (
                  <Switch>
                    <Route path={Path.ActivateAccount} component={ActivateAccountModule} />
                    <AuthRoute path={Path.Auth} component={AuthPage} />
                    <AuthorizedRoute path={Path.Slash} component={AuthorizedPage} />
                  </Switch>
                )}
              </div>
            </Router>
          </StylesProvider>
        )}
        <div className="render-offline-images">
          <Image src={CautionSign} />
          <Image src={CarCrash} />
        </div>
      </AppContext.Provider>
    </FeatureFlagPage>
  );
}

export default App;
