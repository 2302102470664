import React, { useContext } from "react";
import { Path } from "enums";
import { Route, Switch, Redirect } from "react-router-dom";
import ProductConversion from "modules/fuel/product-conversion/product-conversion.module";
import CreateProductConversion from "modules/fuel/product-conversion/form/create-product-conversion.module";
import BuyFuelModule from "modules/plc/buy-fuel/buy-fuel.module";
// import RedemptionModule from "modules/plc/redemption/redemption.module";
import { FeatureFlagContext } from "contexts";
import RedemptionModule from "modules/plc/redemption/redemption.module";
import ManualRedemptionModule from "modules/plc/redemption/manual-redemption/manual-redemption.module";

const ConsumerFuelPage = () => {
  const { hasFeature } = useContext(FeatureFlagContext);
  return (
    <Switch>
      <Route exact path={Path.ConsumerBuyFuel} component={BuyFuelModule} />
      <Route exact path={Path.ConsumerRedemption} component={RedemptionModule} />
      <Route exact path={Path.ConsumerManualRedemption} component={ManualRedemptionModule} />
      {hasFeature("PAGE_PRODUCT_CONVERSION") && (
        <>
          <Route exact path={Path.CreateProductConversion} component={CreateProductConversion} />
          <Route exact path={Path.ProductConversion} component={ProductConversion} />
        </>
      )}
      <Route exact path={Path.ProductConversion} component={ProductConversion} />
      <Redirect to={Path.ConsumerBuyFuel} />
    </Switch>
  );
};

export default ConsumerFuelPage;
