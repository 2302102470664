import styles from "./sidebar.module.scss";
import { NavLink } from "react-router-dom";
import { Accordion, AccordionDetails, AccordionSummary, List } from "@material-ui/core";
import classNames from "classnames";
import { Path } from "enums";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import locale from "localization";
import { useContext } from "react";
import { FeatureFlagContext, UserContext } from "contexts";
import { GearIcon } from "images";
import { Role, Portal } from "enums";

let Navigation = [
  {
    label: "Dashboard",
    link: Path.ConsumerDashboard,
    icon: "icon-dashboard",
    exact: true,
    permission: [Role.PortalAdmin],
  },
  {
    label: locale.cashFlow,
    icon: "icon-cash-flow",
    link: Path.ConsumerCashIn,
    key: "CASH_FLOW",
    items: [
      {
        label: locale.cashIn,
        child: true,
        link: Path.ConsumerCashIn,
        permission: [Role.PortalAdmin, Role.Accounting, Role.SalesPartnerships],
      },
    ],
    permission: [Role.PortalAdmin, Role.Accounting, Role.SalesPartnerships],
  },
  {
    label: locale.fuel,
    icon: "icon-fuel",
    link: Path.ConsumerFuelPage,
    key: "CONSUMER_FUEL",
    items: [
      {
        label: locale.buyFuelCapitalize,
        child: true,
        link: Path.ConsumerBuyFuel,
        permission: [Role.PortalAdmin, Role.Accounting, Role.SalesPartnerships],
      },
      {
        label: locale.redemptions,
        child: true,
        link: Path.ConsumerRedemption,
        key: "PLC_REDEMPTION",
        permission: [Role.PortalAdmin, Role.Accounting, Role.SalesPartnerships],
      },
      {
        label: locale.productConversions,
        child: true,
        link: Path.ProductConversion,
        key: "PRODUCT_CONVERSION",
        permission: [Role.PortalAdmin, Role.SalesPartnerships],
      },
    ],
    permission: [Role.PortalAdmin, Role.Accounting, Role.SalesPartnerships],
  },
  {
    label: locale.customers,
    icon: "icon-user",
    link: Path.Customers,
    key: "CUSTOMER",
    items: [
      {
        label: locale.customerList,
        child: true,
        link: Path.CustomersList,
        permission: [Role.PortalAdmin, Role.Accounting],
      },
      {
        label: locale.userAttributes,
        child: true,
        link: Path.Attributes,
        permission: [Role.PortalAdmin, Role.Accounting],
      },
      {
        label: locale.accountTypes,
        child: true,
        link: Path.AccountTypes,
        permission: [Role.PortalAdmin, Role.Accounting],
      },
      {
        label: locale.kycSubmission,
        child: true,
        link: Path.KYCSubmissions,
        key: "KYC_SUBMISSIONS",
        permission: [Role.PortalAdmin, Role.Accounting],
      },
    ],
    permission: [Role.PortalAdmin, Role.Accounting],
  },
  {
    label: locale.campaignsAndPrograms,
    icon: "icon-campaigns",
    link: Path.Campaigns,
    key: "CAMPAIGN_AND_PROGRAMS",
    items: [
      {
        label: locale.campaigns,
        child: true,
        link: Path.Campaign,
        key: "CAMPAIGN",
        permission: [Role.PortalAdmin, Role.Accounting],
      },
      {
        label: locale.vouchers,
        child: true,
        link: Path.CampaignVouchers,
        key: "VOUCHER_V2",
        permission: [Role.PortalAdmin, Role.Accounting],
      },
      {
        label: locale.vouchers,
        child: true,
        link: Path.UniqueVouchers,
        key: "VOUCHERS",
        permission: [Role.PortalAdmin],
      },
      {
        label: locale.newCustomerVouchers,
        child: true,
        link: Path.NewCustomerVouchers,
        key: "NEW_CUSTOMER_VOUCHERS",
        permission: [Role.PortalAdmin],
      },
    ],
    permission: [Role.PortalAdmin, Role.Accounting],
  },
  {
    label: locale.pricing,
    icon: "icon-pricing",
    link: Path.ConsumerPricingPage,
    key: "CONSUMER_PRICING_PAGE",
    items: [
      {
        label: locale.priceBuildup,
        child: true,
        link: Path.ConsumerPriceBuildup,
        key: "PRICE_BUILD_UP",
        permission: [Role.PortalAdmin, Role.SalesPartnerships, Role.Accounting],
      },
    ],
    permission: [Role.PortalAdmin, Role.SalesPartnerships, Role.Accounting],
  },
  {
    label: locale.otherServices,
    customIcon: <img src={GearIcon} alt="gear" className={styles.customIcon} />,
    link: Path.ConsumerOtherServices,
    key: "CONSUMER_OTHER_SERVICES",
    items: [
      {
        label: locale.rfidReloading,
        link: Path.ConsumerRFID,
        key: "RFID_LOADING",
        child: true,
        permission: [Role.PortalAdmin, Role.Accounting, Role.SalesPartnerships],
      },
      {
        label: locale.locqPayForFuel,
        link: Path.LocqPayForFuel,
        key: "PAY_WITH_PESO",
        child: true,
        permission: [Role.PortalAdmin, Role.Accounting, Role.SalesPartnerships],
      },
      {
        label: locale.nonFuel,
        link: Path.ConsumerLOCQPayNonFuel,
        key: "LOCQPAY_NON_FUEL",
        child: true,
        permission: [Role.PortalAdmin, Role.Accounting, Role.SalesPartnerships],
      },
      {
        label: locale.lubeServOnWheels,
        link: Path.ConsumerLubeServOnWheelsBookings,
        key: "LUBESERV_ON_WHEELS_BOOKINGS",
        child: true,
        permission: [Role.PortalAdmin, Role.SalesPartnerships, Role.Accounting],
      },
      {
        label: locale.lubeServOnSite,
        link: Path.ConsumerLubeServOnSiteBookings,
        key: "LUBESERV_ON_SITE_BOOKINGS",
        child: true,
        permission: [Role.PortalAdmin, Role.SalesPartnerships, Role.Accounting],
      },
    ],
    permission: [Role.PortalAdmin, Role.Accounting, Role.SalesPartnerships],
  },
  {
    label: locale.accessAuthorization,
    link: Path.ConsumerAccessAuthorization,
    key: "ACCESS_AUTHORIZATION",
    icon: "icon-access",
    items: [
      {
        label: locale.userAccess,
        child: true,
        link: Path.ConsumerUserAccess,
        key: "USER_ACCESS",
        permission: [Role.PortalAdmin],
      },
    ],
    permission: [Role.PortalAdmin],
  },
];

const ChildItem = (props) => {
  const { hasFeature } = useContext(FeatureFlagContext);
  const { styles, item, role } = props;

  return (
    <Accordion elevation={0} className={styles.accordionDetails}>
      <AccordionSummary expandIcon={<ExpandMoreIcon className={styles.expandIcon} />}>
        <NavLink
          to={item.link}
          className={classNames(styles.listItem, styles.disableLink)}
          activeClassName={classNames(item, styles.selectedMenu)}
        >
          <span className={styles.item}>
            <span className={styles.listIconRoot}>
              {item.customIcon}
              {item.icon && <span className={`icon ${item.icon}`} />}
            </span>
            <span className={styles.listText}>{item.label}</span>
          </span>
        </NavLink>
      </AccordionSummary>
      <AccordionDetails>
        <div className={styles.child}>
          {item.items
            ?.filter((child) => {
              return child.key ? hasFeature(`PAGE_${child.key}`) : true;
            })
            .map((child, childKey) => {
              if (!child) return <></>;
              return child.permission?.map((permission) => {
                return permission === role ? (
                  <NavLink
                    key={childKey}
                    to={child.link}
                    className={classNames(styles.listItem)}
                    activeClassName={styles.active}
                  >
                    <span className={styles.listIconRoot}>
                      {child.icon && <span className={`icon ${child.icon}`} />}
                    </span>
                    <span className={styles.listText}>{child.label}</span>
                  </NavLink>
                ) : (
                  <></>
                );
              });
            })}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

const Sidebar = () => {
  const { hasFeature } = useContext(FeatureFlagContext);
  const { user } = useContext(UserContext);
  const { role } = user?.userAccesses.find((element) => {
    return element.portal === Portal.LOCQ;
  });
  return (
    <div className={classNames(styles.consumerSidebarContainer)}>
      <div className={styles.smallColorBox}></div>
      <List className={styles.sidebarList}>
        {Navigation.filter((item) => {
          return item.key ? hasFeature(`PAGE_${item.key}`) : true;
        }).map((item, index) => {
          if (!item) return <></>;

          if (!user.userAccesses)
            return (
              <span key={index}>
                {item.exact ? (
                  <NavLink
                    to={item.link}
                    exact={true}
                    className={classNames(styles.listItem)}
                    activeClassName={styles.active}
                  >
                    <span className={styles.item}>
                      <span className={styles.listIconRoot}>
                        {item.icon && <span className={`icon ${item.icon}`} />}
                      </span>
                      <span className={styles.listText}>{item.label}</span>
                    </span>
                  </NavLink>
                ) : (
                  <ChildItem styles={styles} item={item} role={role} />
                )}
              </span>
            );

          return item.permission?.map((permission) => {
            return permission === role ? (
              <span key={index}>
                {item.exact ? (
                  <NavLink
                    to={item.link}
                    exact={true}
                    className={classNames(styles.listItem)}
                    activeClassName={styles.active}
                  >
                    <span className={styles.item}>
                      <span className={styles.listIconRoot}>
                        {item.icon && <span className={`icon ${item.icon}`} />}
                      </span>
                      <span className={styles.listText}>{item.label}</span>
                    </span>
                  </NavLink>
                ) : (
                  <ChildItem styles={styles} item={item} role={role} />
                )}
              </span>
            ) : (
              <></>
            );
          });
        })}
      </List>
    </div>
  );
};

export default Sidebar;
