import LabelAndValue from "components/commons/label-and-value/label-and-value";
import { getChannelType } from "data/cash-in-channels";
import { CashInChannel, CashInChannelType, CashInStatus, DateTime } from "enums";
import { formatCurrency, formatDate, prettifyCashInStatus } from "utils";
import { prettifyCashInChannel } from "utils/pretty.utils";
import locale from "localization";
import LabelAndPill from "components/commons/label-and-pill/label-and-pill";
import { DisplayCustomerName } from "components/commons/data-display";

const cashinChannelColumn = (cashIn) => {
  const { cashinChannel, paymentProcessor, mode } = cashIn;
  const channel = {
    [CashInChannel.Card]: mode,
    [CashInChannel.OTC]: mode,
    [CashInChannel.NewCustomerVoucher]: cashinChannel,
    [CashInChannel.OnlineBanking]: mode,
  };

  const method = {
    [CashInChannel.NewCustomerVoucher]: locale.voucher,
  };

  let label = "";
  if (
    (paymentProcessor === CashInChannel.LocqPromo && cashinChannel === CashInChannel.Rebate) ||
    (paymentProcessor === CashInChannel.LocqPromo &&
      cashinChannel === CashInChannel.LocqPayDiscount)
  ) {
    label = locale.payWithLocqPay;
  } else {
    label = channel[cashinChannel]
      ? prettifyCashInChannel(channel[cashinChannel]) ||
        channel[cashinChannel].toString().replaceAll("-", " ").trim().toUpperCase()
      : "";
  }

  return (
    <LabelAndValue
      label={
        <div>
          {label ||
            prettifyCashInChannel(paymentProcessor) ||
            paymentProcessor?.toString().toUpperCase()}
        </div>
      }
    >
      <div className="capitalize">
        {method[cashinChannel] || prettifyCashInChannel(cashinChannel)}
      </div>
    </LabelAndValue>
  );
};

export const mapDataToList = (cashIn) => {
  const {
    cashinId,
    referenceNumber,
    amount,
    locqFee,
    customerFee,
    status,
    createdAt,
    fulfilledAt,
    userDetail,
    authAmount,
    requestId,
    errorDetail,
    voidedAt,
    campaignName,
  } = cashIn;

  return {
    cashinId,
    referenceNumber,
    userDetails: <DisplayCustomerName data={userDetail} />,
    amount: <div className="min-100">{formatCurrency(amount)}</div>,
    locqFee: formatCurrency(locqFee),
    customerFee: formatCurrency(customerFee),
    cashInChannel: cashinChannelColumn(cashIn),
    discountCampaign: <div>{campaignName ?? ""}</div>,
    statusCashInDate: (
      <LabelAndPill
        label={formatDate(
          status === CashInStatus.Success
            ? fulfilledAt
            : status === CashInStatus.Voided
            ? voidedAt
            : createdAt,
          DateTime.G
        )}
        pillLabel={prettifyCashInStatus(status)}
        pillProps={{
          sky: status === CashInStatus.Pending,
          deepRed: status === CashInStatus.Failed || status === CashInStatus.Cancelled,
          grass: status === CashInStatus.Success || status === CashInStatus.Completed,
          cement: status === CashInStatus.Expired || status === CashInStatus.Voided,
        }}
      />
    ),
    authAmount: formatCurrency(authAmount || 0),
    errorDetail: errorDetail,
    requestId: requestId,
  };
};

export const mapFilterToRequest = (filterState) => {
  const { cashinMethod, ...param } = filterState;
  if (param.cashinChannel) {
    const channel = getChannelType(param.cashinChannel);
    if (param.cashinChannel === CashInChannel.PayWithPeso) {
      param.paymentProcessor = CashInChannel.LocqPromo;
      param.cashinChannel = cashinMethod;
    } else {
      param[channel] = param.cashinChannel;
      if (channel !== CashInChannelType.Channel) {
        if (param.paymentProcessor === "all") {
          param.cashinChannel = cashinMethod;
        } else {
          param.cashinChannel = cashinMethod;
        }
      }
    }
  } else {
    param.cashinChannel = cashinMethod;
  }
  return {
    ...param,
    cashinChannel: param.cashinChannel !== "all" ? param.cashinChannel : "",
    paymentProcessor: param.paymentProcessor !== "all" ? param.paymentProcessor : "",
    cashinStatus: param.cashinStatus !== "all" ? param.cashinStatus : "",
  };
};
