import React from "react";
import { Path } from "enums";
import { Route, Switch } from "react-router-dom";
import FleetPriceModule from "modules/pricing/fleet/fleet-pricing.module";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import PricingChangesModule from "modules/pricing/pricing-changes/pricing-changes.module";
import BatchPricingChangesModule from "modules/pricing/pricing-changes/batch-pricing-changes/batch-pricing-changes.module";
import PriceMovementsModule from "modules/pricing/price-movement/price-movement.module";
import DoxNegotiatedDiscountsModule from "modules/pricing/dox-negotiated-discounts/dox-negotiated-discounts.module";

const PricingPage = () => {
  return (
    <Switch>
      <Route exact path={Path.FleetPrices} component={FleetPriceModule} />
      <Route exact path={Path.PricingChanges} component={PricingChangesModule} />
      <Route exact path={Path.PriceMovements} component={PriceMovementsModule} />
      <Route exact path={Path.BatchPricingChanges} component={BatchPricingChangesModule} />
      <Route exact path={Path.DoxNegotiatedDiscount} component={DoxNegotiatedDiscountsModule} />
      <Redirect to={Path.FleetPrices} />
    </Switch>
  );
};

export default PricingPage;
