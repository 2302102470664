import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Button, Pill, PopOverList, PopOverMenu, Text } from "components/commons";
import locale from "localization";
import { dateTimeFormat, formatAmount, formatVolume, prettifySettlementStatus } from "utils";
import { SettlementStatus, Path } from "enums";

import styles from "./settlement-history.module.scss";

export const mapDataToList = ({ settlements, history }) => {
  const {
    createdAt,
    settlementId,
    station,
    totalLiter,
    totalDealerSettlement,
    dealerSettlementRemarks,
    dealerSettlementStatus,
    dealerSettlementUpdatedAt,
    totalDiscountVoucher,
    // discountVoucherStatus,
    totalPayWithPeso,
    totalMdrFee,
    totalLocqRumSdumfDifferential,
    totalLocqRum,
    totalSales,
    totalSdumfDifferential,
    totalCreditAmount,
    totalDiscount,
    dealerSettlementApv,
  } = settlements;

  return {
    dateProcessed: (
      <>
        <Text>{dateTimeFormat(createdAt)}</Text>
        <Text className={styles.subBusinessId}>{settlementId}</Text>
      </>
    ),
    redemptionStation: (
      <>
        <Text>{station?.name}</Text>
        <Text className={styles.subBusinessId}>{station?.businessName}</Text>
      </>
    ),
    totalLiterRedemption: formatVolume(totalLiter),
    totalPayWithPeso: formatAmount(totalPayWithPeso),
    totalMDRFee: formatAmount(totalMdrFee),
    totalRumSDUMFDifferential: formatAmount(totalLocqRumSdumfDifferential),
    totalRumBuy: formatAmount(totalLocqRum),
    totalDealerPayout: formatAmount(totalDealerSettlement),
    totalSales: formatAmount(totalSales),
    totalCreditAmount: formatAmount(totalCreditAmount),
    totalLocqpayDiscount: formatAmount(totalDiscount),
    totalSdumfDifferential: formatAmount(totalSdumfDifferential),
    dealerPayoutStatus: (
      <>
        <Pill
          sky={
            dealerSettlementStatus === SettlementStatus.ForProcessing ||
            dealerSettlementStatus === SettlementStatus.ForSettlement
          }
          grass={
            dealerSettlementStatus === SettlementStatus.Processed ||
            dealerSettlementStatus === SettlementStatus.Settled
          }
        >
          {prettifySettlementStatus(dealerSettlementStatus)}
        </Pill>
        <Text className={styles.subBusinessId}>
          {dealerSettlementUpdatedAt ? dateTimeFormat(dealerSettlementUpdatedAt) : "--"}
        </Text>
      </>
    ),
    dealerRemarks:
      dealerSettlementStatus === SettlementStatus.Processed && dealerSettlementRemarks ? (
        <PopOverList list={[dealerSettlementRemarks]}>
          <Button link>{locale.view}</Button>
        </PopOverList>
      ) : (
        "--"
      ),
    dealerSettlementApv: dealerSettlementApv ? <Text>{dealerSettlementApv}</Text> : "--",
    totalDiscountVoucher: formatAmount(totalDiscountVoucher),
    action: (
      <PopOverMenu
        options={[
          {
            content: locale.viewDetails,
            onClick: () => history.push(Path.ViewSettlementHistoryById(settlementId)),
          },
          // {
          //   content: locale.cancelSettlement,
          //   onClick: () =>
          //     handleCancelSettlement({
          //       settlementId,
          //       dealerSettlementStatus,
          //       discountVoucherStatus,
          //     }),
          // },
        ]}
      >
        <MoreVertIcon className={styles.icon} />
      </PopOverMenu>
    ),
  };
};

export const mapFilterToRequest = (filterState) => {
  const { dealerSettlementStatuses, ...fs } = filterState;
  return {
    ...fs,
    // discountVoucherStatuses: discountVoucherStatuses.join(","),
    dealerSettlementStatuses: dealerSettlementStatuses.join(","),
  };
};
