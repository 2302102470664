import React, { useMemo, useState } from "react";

import { BackLink, Button, Field, Intro, Text, TextField } from "components/commons";
import { ConfirmModal } from "components/modals";
import { ErrorCode, Path } from "enums";
import locale from "localization";
import { useApi, useModal, useForm, useRouter } from "hooks";
import { handleRequest } from "utils";

import styles from "./update-settlement.module.scss";

import { initialState as formState } from "./update-settlement.state";

import { getSettlementByReportId, updateSettlementReport } from "apis/settlement.api";

const UpdateSettlement = () => {
  const confirmModal = useModal();
  const successModal = useModal();

  const {
    location: { state },
  } = useRouter();

  const [settlementGroup, setSettlementGroup] = useState();

  const form = useMemo(() => {
    return formState(state);
  }, [state]);

  const { fields, modifyField, modifyForm, applyFieldErrors, clearForm } = useForm({
    initialState: form,
  });

  const getSettlementByReport = useApi({
    api: getSettlementByReportId,
    handleOwnError: {
      badrequest: true,
    },
  });

  const updateSettlementByReport = useApi({
    api: updateSettlementReport,
    handleOwnError: {
      badrequest: true,
    },
  });

  const searchSettlementByReport = async () => {
    modifyForm({
      [fields?.apvNumber?.name]: {
        value: "",
      },
      [fields?.remarks?.name]: {
        value: "",
      },
    });

    if (fields.uniqueReportId.value.length > 0) {
      handleRequest(
        async () => {
          const result = await getSettlementByReport.request({
            reportId: fields.uniqueReportId.value,
          });
          setSettlementGroup(result);

          if (result.status === "processed") {
            modifyForm({
              [fields?.apvNumber?.name]: {
                value: result?.apvNumber,
              },
              [fields?.remarks?.name]: {
                value: result?.remarks,
              },
            });
          }
        },
        null,
        (err) => {
          if (err.data?.errorCode === ErrorCode.SettlementGroupNotFound) {
            applyFieldErrors({
              uniqueReportId: locale.sorryThisUniqueReportIdNotExist,
            });

            setSettlementGroup(null);
          } else {
            return err.showError(false);
          }
        }
      );
    } else {
      applyFieldErrors({
        uniqueReportId: locale.sorryThisUniqueReportIdNotExist,
      });
    }
  };

  const handleUpdateSettlementGroup = async () => {
    confirmModal.show({
      title: `${locale.saveChanges}?`,
      content: (
        <div className={styles.modal}>
          <Text>
            {locale.uniqueReportId}: <b>{fields.uniqueReportId.value}</b>
          </Text>
          <Text>
            {locale.apvNumber}: <b>{fields.apvNumber.value}</b>
          </Text>
          <Text>
            {locale.remarks}: <b>{fields.remarks.value}</b>
          </Text>
        </div>
      ),
      secondary: locale.cancel,
      primary: {
        text: locale.save,
        onClick: () => {
          handleRequest(
            async () => {
              await updateSettlementByReport.request({
                reportId: fields?.uniqueReportId.value,
                dealerSettlementApv: fields?.apvNumber.value,
                dealerSettlementRemarks: fields?.remarks.value,
              });

              successModal.show({
                title: locale.changesSaved,
                content: (
                  <locale.Populate
                    text={locale.youHaveSuccessfullyUpdatedUniqueReportId}
                    items={[<b>{fields?.uniqueReportId.value}</b>]}
                  />
                ),
                primary: {
                  text: locale.okay,
                  onClick: async () => {
                    clearForm();
                    setSettlementGroup(null);
                    successModal.close();
                  },
                },
              });

              confirmModal.close();
            },
            null,
            (err) => {
              return err.showError(false);
            }
          );
        },
      },
    });
  };

  return (
    <>
      <ConfirmModal
        {...confirmModal}
        loading={updateSettlementByReport?.loading}
        closable={updateSettlementByReport?.loading}
        disabled={updateSettlementByReport?.loading}
      />
      <ConfirmModal {...successModal} closable={false} />
      <div className={styles.container}>
        <BackLink text={locale.dealerSettlementHistory} path={Path.LocqSettlementHistory} />
        <div
          style={{
            display: "flex",
          }}
        >
          <Intro
            style={{
              minWidth: "300px",
            }}
            title={locale.updateDealerSettlement}
          />
        </div>
        <div>
          <div
            style={{
              width: "600px",
            }}
          >
            <Field
              {...fields.uniqueReportId}
              className={styles.fieldSelect}
              label={locale.uniqueReportId}
              labelPosition="left"
              labelClassName={styles.fieldLabel}
              childrenClassName={styles.fieldContent}
            >
              <div className={styles.row}>
                <TextField
                  {...fields.uniqueReportId}
                  onChange={(name, { value }) => {
                    setSettlementGroup(null);
                    modifyForm({
                      [name]: { value },
                    });
                  }}
                />
                <Button
                  primary
                  onClick={searchSettlementByReport}
                  loading={getSettlementByReport.loading}
                >
                  {locale.search}
                </Button>
              </div>
            </Field>
            {settlementGroup && (
              <>
                <Field
                  {...fields.apvNumber}
                  className={styles.fieldSelect}
                  label={locale.apvNumber}
                  labelPosition="left"
                  labelClassName={styles.fieldLabel}
                  childrenClassName={styles.fieldContent}
                >
                  <TextField
                    {...fields.apvNumber}
                    onChange={modifyField}
                    disabled={settlementGroup.status === "processed"}
                    inputDisabled={settlementGroup.status === "processed"}
                  />
                </Field>
                <Field
                  {...fields.remarks}
                  className={styles.fieldSelect}
                  label={locale.remarks}
                  labelPosition="left"
                  labelClassName={styles.fieldLabel}
                  childrenClassName={styles.fieldContent}
                >
                  <TextField {...fields.remarks} onChange={modifyField} />
                </Field>
                <div className={styles.right}>
                  <Button
                    primary
                    disabled={fields.apvNumber.value === ""}
                    onClick={handleUpdateSettlementGroup}
                    loading={updateSettlementByReport.loading}
                  >
                    {locale.save}
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateSettlement;
