import locale from "localization";

export const dealerMonthlyFeeDetailsListColumns = [
  {
    key: "redemptionStationBusinessName",
    text: `${locale.redemptionStation}/${locale.businessName}`,
    width: "10%",
  },
  {
    key: "stationTypeStationClass",
    text: `${locale.stationType}/${locale.stationClass}`,
    width: "10%",
  },
  {
    key: "plcPlbStation",
    text: locale.platformOnboarded,
    width: "10%",
  },
  {
    key: "totalMonthlyFee",
    text: locale.totalMonthlyFee,
    width: "8%",
  },
  {
    key: "chargingStatus",
    text: locale.chargingStatus,
    width: "10%",
  },
  {
    key: "dealerShoulderedMonthlyFee",
    text: locale.totalDealerShoulderedMonthlyFee,
    width: "8%",
  },
  {
    key: "totalWaivedMonthlyFee",
    text: locale.totalWaivedMonthlyFee,
    width: "8%",
  },
  {
    key: "totalNetMonthlyFee",
    text: locale.totalNetMonthlyFee,
    width: "8%",
  },
  {
    key: "paymentStatus",
    text: locale.paymentStatus,
    width: "10%",
  },
  {
    key: "dealerSettlementId",
    text: locale.dealerSettlementId,
    width: "5%",
  },
  {
    key: "billToOilco",
    text: locale.billToOilco,
    width: "8%",
  },
];
