import locale from "localization";
import Validation from "utils/validation.utils";

export const initialState = (state = {}) => {
  const {
    name = {
      value: "",
    },
    cohorts = {
      value: [],
    },
  } = state;

  return {
    name: {
      name: "name",
      required: true,
      validations: [Validation.name(), Validation.required({ noMessage: true })],
      maxLength: 30,
      label: locale.userAttributesName,
      horizontal: true,
      ...name,
    },
    cohorts: {
      name: "cohorts",
      required: true,
      ...cohorts,
    },
  };
};
