export const columns = [
  { key: "key", text: "Feature Flag" },
  { key: "createdBy", text: "Created By" },
  { key: "updatedBy", text: "Updated By" },
  { key: "users", text: "Available to Users" },
  {
    key: "status",
    text: "Status",
  },
  {
    key: "actions",
    text: "Actions",
    align: "right",
  },
];
