import React from "react";
import styles from "./input-switch.module.scss";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { FormControlLabel, Switch } from "@material-ui/core";
import classNames from "classnames";

const InputSwitch = ({ onChange, checked, label, className, ...props }) => {
  const CustomSwitch = withStyles((theme) =>
    createStyles({
      root: {
        width: 45,
        height: 22,
        padding: 0,
        marginLeft: theme.spacing(1.5),
        marginRight: theme.spacing(3),
      },
      switchBase: {
        padding: 1,
        "&$checked": {
          transform: "translateX(24px)",
          color: theme.palette.common.white,
          "& + $track": {
            backgroundColor: "red",
            opacity: 1,
            border: "none",
          },
        },
        "&$focusVisible $thumb": {
          color: "#52d869",
          border: "6px solid #fff",
        },
      },
      thumb: {
        width: 20,
        height: 20,
      },
      track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: "#52d869",
        opacity: 1,
        transition: theme.transitions.create(["background-color", "border"]),
      },
      checked: {},
      focusVisible: {},
    })
  )(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classNames(classes.track, className),
          checked: classes.checked,
        }}
        {...props}
      />
    );
  });

  return (
    <FormControlLabel
      {...props}
      className={styles.switchContainer}
      control={<CustomSwitch checked={checked} onChange={onChange} color="primary" />}
      label={label}
    />
  );
};

export default InputSwitch;
