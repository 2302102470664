import { Text } from "components/commons";
import LabelAndValue from "components/commons/label-and-value/label-and-value";
import { prettifyProduct } from "utils/pretty.utils";
import styles from "./fleet-pricing.module.scss";
import classNames from "classnames";
import { formatAmount, formatVolume } from "utils";

const RenderValueField = ({ virtualStationPriceTool = [], keyItem, method }) => {
  return virtualStationPriceTool.length !== 0 ? (
    virtualStationPriceTool.map((item, key) => (
      <div key={`${item[keyItem]}-${key}`}>
        <Text>{method(item[keyItem] ?? 0)}</Text>
      </div>
    ))
  ) : (
    <Text>{method(0)}</Text>
  );
};

export const mapDataToList = (stations) => {
  const { businessName, station, virtualStationPriceTool } = stations;

  return {
    businessName,
    referenceStation: <LabelAndValue label={station.stationType}>{station.name}</LabelAndValue>,
    product:
      virtualStationPriceTool &&
      virtualStationPriceTool.map((item, key) => (
        <div key={`${item.productCode}-${key}`}>
          <div className="flex items-center">
            <div className={classNames(styles.circle, styles[item.productCode])}></div>
            {prettifyProduct(item.productCode)}
          </div>
        </div>
      )),
    supplierWpp: RenderValueField({
      virtualStationPriceTool,
      keyItem: "supplierWpp",
      method: formatAmount,
    }),
    orum: RenderValueField({
      virtualStationPriceTool,
      keyItem: "orum",
      method: formatAmount,
    }),
    sdum: RenderValueField({
      virtualStationPriceTool,
      keyItem: "sdum",
      method: formatAmount,
    }),
    freight: RenderValueField({
      virtualStationPriceTool,
      keyItem: "freight",
      method: formatAmount,
    }),
    sdumf: RenderValueField({
      virtualStationPriceTool,
      keyItem: "sdumf",
      method: formatAmount,
    }),
    virtualRefSPumpPrice: RenderValueField({
      virtualStationPriceTool,
      keyItem: "pumpPrice",
      method: formatAmount,
    }),
    totalDiscount: RenderValueField({
      virtualStationPriceTool,
      keyItem: "discount",
      method: formatAmount,
    }),
    estimateMonthlyVolume: RenderValueField({
      virtualStationPriceTool,
      keyItem: "totalEstimatedVolume",
      method: formatVolume,
    }),
    floorPrice: RenderValueField({
      virtualStationPriceTool,
      keyItem: "floorPrice",
      method: formatAmount,
    }),
    grossFloorMargin: RenderValueField({
      virtualStationPriceTool,
      keyItem: "grossFloorMargin",
      method: formatAmount,
    }),
  };
};

export const mapFilterToRequest = (filterState) => {
  const { stationTypes, referenceStation, ...fs } = filterState;

  if (referenceStation.value.length) {
    fs.referenceStationIds = referenceStation.value.map(({ value }) => value).join(",");
  }

  if (stationTypes.value.length) {
    fs.stationTypes = stationTypes.value.join(",");
  }

  return {
    ...fs,
  };
};
