import React from "react";

import { Button, Image, Text, PopOverMenu } from "components/commons";

import locale from "localization";

import styles from "./process-settlement.module.scss";
import { PdfPlaceHolder } from "images";

import useExport from "hooks/useExport";
import { generatePricingReport } from "apis";

const ProcessSettlementTable = ({
  generateReport,
  generateReportLoading,
  downloadUrl,
  submitSettlement,
  fields: {
    settlementDate: {
      value: { startDate, endDate },
    },
    redemptionStationId: { value },
  },
  settlementId,
  generateSettlementReport,
  genereateSettlementReportLoading,
}) => {
  const exportFile = useExport({
    api: generatePricingReport,
    reportType: "redemptions-settlement",
    mappedFilterState: { settlementId },
  });

  return (
    <>
      <div className={styles.reportContainer}>
        <Button
          primary
          exportButton
          onClick={() => generateReport()}
          loading={generateReportLoading}
          disabled={!(startDate && endDate && value)}
        >
          {locale.generateReport}
        </Button>
        <PopOverMenu
          disabledClick={!downloadUrl}
          options={[
            {
              content: locale.asExcel,
              onClick: settlementId
                ? () => exportFile.exportReport({ fileType: "xlsx" })
                : () => generateSettlementReport({ fileType: "xlsx" }),
            },
            {
              content: locale.asPdf,
              onClick: settlementId
                ? () => exportFile.exportReport({ fileType: "pdf" })
                : () => generateSettlementReport({ fileType: "pdf" }),
            },
          ]}
        >
          <Button
            startIcon={<span className="icon-download" />}
            exportButton
            loading={exportFile.exporting || genereateSettlementReportLoading}
            disabled={!downloadUrl}
          >
            {locale.export}
          </Button>
        </PopOverMenu>
      </div>

      <div className={styles.pdfContainer}>
        {downloadUrl ? (
          <embed
            style={{
              width: "100%",
              height: "100%",
            }}
            src={`${downloadUrl}#toolbar=0&navpanes=0&scrollbar=0&pagemode=none`}
          />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Image src={PdfPlaceHolder} />
            <Text align={"center"} strong>
              {locale.noDataYet}
            </Text>
          </div>
        )}
      </div>
      <Button
        primary
        onClick={() => submitSettlement()}
        disabled={!(downloadUrl && startDate && endDate && value)}
      >
        {locale.processSettlement}
      </Button>
    </>
  );
};

export default ProcessSettlementTable;
