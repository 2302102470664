import { ApiPath } from "enums";
import { Get, Post, Put } from "utils";

export const getSettlementHistory = async ({ ...query }) => {
  const res = await Get(`${ApiPath.SettlementHistory}`, query);
  return res.data;
};

export const getSettlementHistoryById = async ({ id, ...query }) => {
  const res = await Get(`${ApiPath.SettlementHistory}/${id}`, query);
  return res.data;
};

export const getSettlementOilcoHistory = async ({ ...query }) => {
  const res = await Get(`${ApiPath.SettlementOilcoHistory}`, query);
  return res.data;
};

export const getSettlementOilcoHistoryById = async ({ id, ...query }) => {
  const res = await Get(`${ApiPath.SettlementOilcoHistory}/${id}`, query);
  return res.data;
};

export const getSettlementRedemption = async ({ ...query }) => {
  const res = await Get(`${ApiPath.SettlementRedemption}`, query);
  return res.data;
};

export const processSettlement = async (body) => {
  const res = await Post(`${ApiPath.SettlementHistory}`, body);
  return res.data;
};

export const updateSettlementHistory = async ({ settlementId, ...body }) => {
  const res = await Put(`${ApiPath.SettlementHistory}/${settlementId}`, body);
  return res.data;
};

export const updateSettlementOilcoHistory = async ({ settlementId, ...body }) => {
  const res = await Put(`${ApiPath.SettlementOilcoHistory}/${settlementId}`, body);
  return res.data;
};

export const getSettlementByReportId = async ({ reportId }) => {
  const res = await Get(`${ApiPath.SettlementGroup}/report/${reportId}`);
  return res.data;
};

export const updateSettlementReport = async ({ reportId, ...body }) => {
  const res = await Put(`${ApiPath.SettlementGroup}/report/${reportId}`, body);
  return res.data;
};
