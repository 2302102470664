import locale from "localization";
import Validation from "utils/validation.utils";

export const initialState = (state = {}) => {
  const {
    station = {
      value: "",
    },
    redemptionStationId = {
      value: "",
    },
    settlementDate = {
      value: { startDate: null, endDate: null },
    },
  } = state;

  return {
    station: {
      name: "station",
      required: true,
      validations: [Validation.required({ noMessage: true })],
      label: locale.stationBusinessId,
      horizontal: true,
      ...station,
    },
    redemptionStationId: {
      name: "redemptionStationId",
      required: true,
      validations: [Validation.required({ noMessage: true })],
      label: locale.redemptionStationId,
      horizontal: true,
      ...redemptionStationId,
    },
    settlementDate: {
      name: "settlementDate",
      required: true,
      validations: [Validation.required({ noMessage: true })],
      label: locale.settlementDate,
      horizontal: true,
      ...settlementDate,
    },
  };
};
