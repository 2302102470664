import React, { useContext } from "react";
import { Intro } from "components/commons";
import { ConfirmModal, SuccessModal } from "components/modals";

import { getFuelCodes, generateReportAdmin } from "apis";
import { useModal } from "hooks";
import useDataTable from "hooks/useDataTable";
import useExport from "hooks/useExport";
import locale from "localization";
import { Portal } from "enums";

import { myFuelCodesColumns } from "./fuel-codes-columns";
import FuelCodesFilter from "./fuel-codes-filter";
import { myFuelCodesState } from "./fuel-codes-filter.state";
import EditFuelCodeModal from "./fuel-code-form/edit-fuel-code.modal";
import { mapDataToList, mapFilterToRequest } from "./fuel-codes.mapper";
import FuelCodesList from "./fuel-codes.list";
import { SessionContext, UserContext } from "contexts";

const MyFuelCodesModule = () => {
  const confirmModal = useModal();
  const successActivationModal = useModal();
  const overrideFuelCodeModal = useModal();
  const { sessionId } = useContext(SessionContext);
  const { user } = useContext(UserContext);
  const { role } = user?.userAccesses?.find((element) => {
    return element.portal === Portal.LOCQ;
  });

  const { filter, search, table } = useDataTable({
    api: { api: getFuelCodes },
    filter: { initialState: myFuelCodesState(), mapper: mapFilterToRequest },
    table: {
      key: "fuelCodes",
      mapper: (fuelCodes) =>
        mapDataToList({
          fuelCodes,
          overrideFuelCodeModal,
          role
        }),
      columns: myFuelCodesColumns,
    },
  });

  const exports = useExport({
    api: generateReportAdmin,
    hasModal: true,
    reportType: "fuel-codes",
    mappedFilterState: {
      ...filter.mappedFilterState,
      fuelCodeStatus: filter.mappedFilterState.status,
      sessionId,
    },
  });

  return (
    <>
      <ConfirmModal {...confirmModal} />
      <ConfirmModal {...successActivationModal} />
      <SuccessModal {...exports?.successModalComponent} />
      <EditFuelCodeModal
        {...overrideFuelCodeModal}
        callback={() => table.fetch({ newFilterState: filter.mappedFilterState })}
      />
      <Intro title={locale.fuelCodes} subtitle={locale.keepTrackFuelCodesExpiryDates} />
      <FuelCodesFilter {...filter} {...search} {...exports} />
      <FuelCodesList table={table} />
    </>
  );
};

export default MyFuelCodesModule;
