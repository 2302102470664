import locale from "localization";

export const columns = [
  { key: "batchId", align: "center", text: locale.mopsId, width: "7%" },
  {
    key: "createdDate",
    align: "center",
    text: locale.createdDate,
    width: "15%",
  },
  { key: "effectiveDate", align: "center", text: locale.effectiveDate, width: "15%" },
  { key: "product", align: "center", text: locale.product, width: "10%" },
  { key: "price", align: "center", text: locale.price, width: "10%" },
];
