const Status = {
    Active: "active",
    Deactivated: "deactivated",
    Pending: "pending",
    Expired: "expired",
    Inactive: "inactive",
    Terminated: "terminated"
  };
  
  export default Status;
  