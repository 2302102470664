import { Products, DateTime, DiscountCampaignType } from "enums";
import moment from "moment";
import { formatNumber } from "utils";
import locale from "localization";

export const discountCampaignFormPost = (data) => {
  const products = productFormat(data);
  const accountTypes = valueIterate(data?.accountTypes);
  const stations = valueIterate(data?.stations);
  const cohorts = getCohortIds(data?.cohorts);
  let optionalPayload = {};

  if (stations?.length > 0) {
    optionalPayload = { stations };
  }

  return {
    campaignName: data?.campaignName,
    startDate: data?.campaignDuration?.startDate,
    endDate: data?.campaignDuration?.endDate,
    products,
    accountTypes,
    cohorts,
    excludedAttributes: data?.excludedAttributes,
    shoulderedBy: data?.shoulderedBy,
    bins: data?.paymentMethodRestriction === locale.yes ? data?.bins : null,
    monthlyVolumeCap: data?.paymentMethodRestriction === locale.yes ? data?.monthlyVolumeCap : null,
    type:
      data?.paymentMethodRestriction === locale.yes
        ? DiscountCampaignType.PaymentMethod
        : DiscountCampaignType.Regular,
    ...optionalPayload,
  };
};

export const discountCampaignFormGet = (data) => {
  const products = productIterate(data.products);
  const startDate = new Date(moment(data?.startDate).format(DateTime.C));
  const endDate = new Date(moment(data?.endDate).format(DateTime.D));
  const accountTypes = accountTypeIterate(data?.accountTypes);
  const cohorts = cohortsIterate(data?.cohorts);
  const excludedAttributes = attributeIdIterate(data?.excludedAttributes);

  return {
    campaignName: { value: data?.campaignName },
    campaignDuration: {
      value: { startDate: startDate, endDate: endDate },
    },
    ...products,
    accountTypes: { value: accountTypes },
    cohorts: { value: cohorts },
    excludedAttributes: { value: excludedAttributes },
    stations: { value: data?.stations },
    shoulderedBy: { value: data?.shoulderedBy },
    paymentMethodRestriction: { value: data?.bins !== null ? locale.yes : locale.no },
    bins: { value: data?.bins },
    monthlyVolumeCap: { value: data?.monthlyVolumeCap },
  };
};

const productFormat = (data) => {
  const { diesel, gas91, gas95, gas97 } = data;
  let products = [];

  if (diesel) {
    products.push({
      productCode: Products.Diesel,
      discount: diesel,
    });
  }
  if (gas91) {
    products.push({
      productCode: Products.Gas91,
      discount: gas91,
    });
  }
  if (gas95) {
    products.push({
      productCode: Products.Gas95,
      discount: gas95,
    });
  }
  if (gas97) {
    products.push({
      productCode: Products.Gas97,
      discount: gas97,
    });
  }
  return products;
};

const productIterate = (data) => {
  let products = {};

  Object.values(Products)?.forEach((product) => {
    const discount = data.filter(({ productCode }) => productCode === product)[0]?.discount || 0;
    products = Object.assign(products, {
      [product]: {
        value: formatNumber(discount, 2),
        parseNumber: true,
      },
    });
  });
  return products;
};

const accountTypeIterate = (accountTypes) => {
  return accountTypes?.map(({ name, accountTypeId }) => {
    return {
      label: name,
      value: accountTypeId,
    };
  });
};

export const stationsIterate = (stations) => {
  return stations?.map(({ name, stationId }) => {
    return {
      label: name,
      value: stationId,
    };
  });
};

const cohortsIterate = (cohorts) => {
  let attributeInput = {};
  let cohortsInput = [];

  cohorts?.forEach(({ attribute, name, cohortId }) => {
    cohortsInput.push({
      name,
      value: cohortId,
      attributeId: attribute?.attributeId,
    });

    attributeInput[attribute?.attributeId] = [];
    attributeInput[attribute?.attributeId] = cohortsInput.filter(
      (c) => c?.attributeId === attribute?.attributeId
    );
  });
  return attributeInput;
};

const valueIterate = (data) => {
  return data?.map(({ value }) => {
    return value;
  });
};

export const attributeIdIterate = (data) => {
  return data?.map(({ attributeId }) => {
    return attributeId;
  });
};

export const getCohortIds = (cohorts) => {
  const cohortsValues = Object.values(cohorts);
  let cohortArray = [];
  for (let i = 0; i < cohortsValues.length; i++) {
    const values = valueIterate(cohortsValues[i]);
    cohortArray.push(...values);
  }
  return cohortArray;
};
