import React, { useState, useMemo } from "react";
import moment from "moment";

import { BackLink, Intro, Text } from "components/commons";
import { ConfirmModal } from "components/modals";
import { DateTime, Path } from "enums";
import locale from "localization";
import { useApi, useModal, useForm, useRouter } from "hooks";
import { handleRequest, formatDate } from "utils";

import styles from "./process-settlement.module.scss";

import ProcessSettlementForm from "./process-settlement-form.module";
import ProcessSettlementTable from "./process-settlement-table.module";
import { initialState as formState } from "./process-settlement.state";
import { generatePricingReport, processSettlement } from "apis";

import {
  renderMultipleSettlements,
  renderSingleSettlement,
} from "../../settlement-history/components/settlement-collection";

const ProcessSettlement = () => {
  const confirmModal = useModal();
  const successModal = useModal();
  const errorModal = useModal();
  const {
    history,
    location: { state },
  } = useRouter();
  const [downloadUrl, setDownloadUrl] = useState();
  const [generateReportLoading, setGenerateReportLading] = useState(false);

  const form = useMemo(() => {
    return formState(state);
  }, [state]);

  const { fields, modifyField, isFormSubmittable, submitForm, modifyForm } = useForm({
    initialState: form,
  });

  const settlementRedemption = useApi({
    api: generatePricingReport,
    handleOwnError: {
      badrequest: true,
    },
  });

  const generateSettlement = useApi({
    api: generatePricingReport,
    handleOwnError: {
      badrequest: true,
    },
  });

  const postSettlement = useApi({
    api: processSettlement,
    handleOwnError: {
      badrequest: true,
    },
  });

  // api
  const generateReport = () => {
    // done
    handleRequest(
      async () => {
        setGenerateReportLading(true);
        const result = await settlementRedemption.request({
          reportType: "redemptions-settlement",
          startDate: new Date(moment(fields?.settlementDate?.value.startDate).format(DateTime.C)),
          endDate: new Date(moment(fields?.settlementDate?.value.endDate).format(DateTime.D)),
          redemptionStationId: fields?.redemptionStationId.value,
          fileType: "pdf",
        });
        if (result?.downloadUrl) {
          setDownloadUrl(result?.downloadUrl);
          setGenerateReportLading(false);
        }
      },
      {
        S0001: () => {
          setGenerateReportLading(false);
          errorModal.show({
            title: locale.generateReportError,
            content: locale.noRedemptionToSettled,
            primary: {
              text: locale.gotIt,
              onClick: () => errorModal.close(),
            },
          });
        },
      }
    );
  };

  const submitSettlement = async () => {
    // for checking
    const result = await postSettlement.request({
      startDate: new Date(moment(fields?.settlementDate?.value.startDate).format(DateTime.C)),
      endDate: new Date(moment(fields?.settlementDate?.value.endDate).format(DateTime.D)),
      redemptionStationId: fields?.redemptionStationId.value,
      downloadUrl,
    });

    return result?.settlementId;
  };

  // modal for submission
  const handleProcessSettlement = () => {
    confirmModal.show({
      title: locale.processSettlementQuestion,
      content: (
        <locale.Populate
          text={locale.processSettlementMessage}
          items={[
            <strong>{fields?.redemptionStationId?.label}</strong>,
            `${formatDate(fields?.settlementDate?.value.startDate)}`,
            `${formatDate(fields?.settlementDate?.value.endDate)}`,
          ]}
        />
      ),
      secondary: locale.cancel,
      primary: {
        text: locale.processSettlement,
        onClick: () => {
          handleRequest(
            async () => {
              const response = await submitSettlement();
              successSettlement(response);
              confirmModal.close();
            },
            {
              S0001: () => {
                errorModal.show({
                  title: locale.generateReportError,
                  content: locale.noRedemptionToSettled,
                  primary: {
                    text: locale.gotIt,
                    onClick: () => errorModal.close(),
                  },
                });
              },
            },
            ({ data: { settlements } }) => {
              errorModal.show({
                title: locale.processSettlementIds,
                content: (
                  <>
                    <Text align={"center"}>{locale.processSettlementIdsContent}</Text>
                    {settlements?.length > 1
                      ? renderMultipleSettlements(settlements)
                      : renderSingleSettlement(settlements[0])}
                  </>
                ),
              });
            }
          );
        },
      },
    });
  };

  const successSettlement = (settlementId = state.settlementId) => {
    successModal.show({
      title: locale.exclamatedSuccess,
      content: locale.successProcessSettlementMessage,
      secondary: {
        text: locale.goBackSettlements,
        onClick: () => {
          history.push(Path.LocqSettlement);
          successModal.close();
        },
      },
      primary: {
        text: locale.goSettlementHistory,
        onClick: async () => {
          history.push(Path.LocqSettlementHistory);
          successModal.close();
        },
      },
    });
  };

  const generateSettlementReport = async ({ fileType }) => {
    const result = await generateSettlement.request({
      reportType: "redemptions-settlement",
      startDate: new Date(moment(fields?.settlementDate?.value.startDate).format(DateTime.C)),
      endDate: new Date(moment(fields?.settlementDate?.value.endDate).format(DateTime.D)),
      redemptionStationId: fields?.redemptionStationId.value,
      fileType,
    });

    window.open(result?.downloadUrl, "_blank");
  };

  const stateForm = {
    fields,
    isFormSubmittable,
    modifyField,
    submitForm,
    modifyForm,
  };

  return (
    <>
      <ConfirmModal
        {...confirmModal}
        loading={postSettlement?.loading}
        closable={postSettlement?.loading}
        disabled={postSettlement?.loading}
      />
      <ConfirmModal {...successModal} closable={false} />
      <ConfirmModal {...errorModal} />
      <div className={styles.container}>
        <BackLink text={locale.settlements} path={Path.LocqSettlement} />
        <div
          style={{
            display: "flex",
          }}
        >
          <Intro
            style={{
              minWidth: "300px",
            }}
            title={locale.processSettlement}
          />
        </div>
        <ProcessSettlementForm
          generateReportLoading={settlementRedemption?.loading}
          setDownloadUrl={setDownloadUrl}
          {...stateForm}
        />
        <ProcessSettlementTable
          fields={fields}
          generateReport={generateReport}
          generateReportLoading={generateReportLoading}
          downloadUrl={downloadUrl}
          genereateSettlementReportLoading={settlementRedemption?.loading}
          generateSettlementReport={generateSettlementReport}
          submitSettlement={handleProcessSettlement}
          settlementId={state?.settlementId}
        />
      </div>
    </>
  );
};

export default ProcessSettlement;
