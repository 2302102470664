export const userAttributeGetObjects = (data) => {
  const cohorts = cohortsIterate(data);
  return {
    name: { value: data?.name },
    cohorts: { value: cohorts },
  };
};

export const userAttributePutObjects = (originalValues, currentValues) => {
  let updateBody = {
    name: currentValues?.name,
  };
  let addCohorts = toBeAddedCohorts(originalValues?.cohorts, currentValues?.cohorts);
  let deleteCohorts = toBeDeletedCohorts(originalValues?.cohorts, currentValues?.cohorts);
  if (addCohorts.length > 0) {
    updateBody = { ...updateBody, addCohorts };
  }
  if (deleteCohorts.length > 0) {
    updateBody = { ...updateBody, deleteCohorts };
  }
  return updateBody;
};

const toBeAddedCohorts = (originalCohorts, currentCohorts) => {
  let cohorts = [];
  for (let i = 0; i < currentCohorts.length; i++) {
    const foundCohort = originalCohorts.find((og) => og.name === currentCohorts[i]);
    if (!foundCohort) {
      cohorts = [...cohorts, currentCohorts[i]];
    }
  }
  return cohorts;
};

const toBeDeletedCohorts = (originalCohorts, currentCohorts) => {
  let cohorts = [];
  for (let i = 0; i < originalCohorts.length; i++) {
    if (currentCohorts.indexOf(originalCohorts[i]?.name) === -1) {
      cohorts = [...cohorts, originalCohorts[i]?.cohortId];
    }
  }
  return cohorts;
};

const cohortsIterate = (data) => {
  const { cohorts } = data;
  let cohortsArray = [];
  for (let i = 0; i < cohorts.length; i++) {
    cohortsArray = [...cohortsArray, cohorts[i].name];
  }

  return cohortsArray;
};
