import { Modal, Table } from "components/commons";
import LabelAndValue from "components/commons/label-and-value/label-and-value";
import { DateTime } from "enums";
import moment from "moment";
import React from "react";
import { formatDate } from "utils";

const FeatureFlagHistory = ({ title, data = [], ...modal }) => {
  return (
    <Modal {...modal} title={`${title} History`}>
      <div
        style={{
          overflowX: "auto",
          maxHeight: "400px",
        }}
      >
        <Table
          columns={[
            {
              key: "user",
              text: "User",
            },
            {
              key: "date",
              text: "Date",
            },
            {
              key: "status",
              text: "Status",
            },
            {
              key: "remarks",
              text: "Remarks",
            },
          ]}
          data={data.map((d) => {
            const obj = {
              ...d,
              user: d.name ? <LabelAndValue label={d.email}>{d.name}</LabelAndValue> : d.email,
              date: formatDate(moment(d.date, "X"), DateTime.G),
            };
            const map = new Map();
            Object.keys(obj).forEach((key) => {
              map.set(key, obj[key]);
            });

            return map;
          })}
        />
      </div>
    </Modal>
  );
};

export default FeatureFlagHistory;
