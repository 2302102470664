import styles from "./mops-pricing-filter.module.scss";
import { DateRange, Field, Filter, SearchField, Button } from "components/commons";
import React from "react";
import locale from "localization";

const MopsPriceFilter = ({
  filterState,
  modifyFilter,
  filterCount,
  applyFilter,
  applyClearFilter,
  searchKey,
  modifySearch,
  applySearch,
  applyClearSearch,
  exportReport,
  exporting,
  resetFilter,
}) => {
  const { startDateEffective, endDateEffective, startDateCreated, endDateCreated } = filterState;

  return (
    <div className={styles.filter}>
      <Filter
        close={resetFilter}
        clear={applyClearFilter}
        submit={applyFilter}
        filterCount={filterCount}
      >
        <Field
          className={styles.filterInput}
          label={locale.effectiveDate}
          labelPosition={"left"}
          childrenClassName={styles.fieldContent}
        >
          <DateRange
            value={{ startDate: startDateEffective, endDate: endDateEffective }}
            onChange={(_, { value }) => {
              const { startDate, endDate } = value;
              modifyFilter({
                startDateEffective: startDate,
                endDateEffective: endDate,
              });
            }}
          />
        </Field>

        <Field
          className={styles.filterInput}
          label={locale.createdDate}
          labelPosition={"left"}
          childrenClassName={styles.fieldContent}
        >
          <DateRange
            value={{ startDate: startDateCreated, endDate: endDateCreated }}
            onChange={(_, { value }) => {
              const { startDate, endDate } = value;
              modifyFilter({
                startDateCreated: startDate,
                endDateCreated: endDate,
              });
            }}
          />
        </Field>
      </Filter>

      <Field className={styles.search}>
        <SearchField
          placeholder={`${locale.mopsSpaceId}`}
          value={searchKey}
          onChange={(_, { value }) => {
            modifySearch(value);
          }}
          onEnter={applySearch}
          onClear={applyClearSearch}
        />
      </Field>
      <Field className={styles.downloadButton}>
        <Button
          startIcon={<span className="icon-download" />}
          exportButton
          onClick={exportReport}
          loading={exporting}
        >
          {locale.export}
        </Button>
      </Field>
    </div>
  );
};

export default MopsPriceFilter;
