import React from "react";
import { BookingType, Path } from "enums";
import { Route, Switch, Redirect } from "react-router-dom";
import LubeServBookingsModule from "modules/plc/lubeserv-bookings/lubeserv-bookings.module";

const LubeServOnSiteBookingsPage = () => {
  return (
    <Switch>
      <Route
        exact
        path={Path.LubeServOnSiteBookings}
        component={() => <LubeServBookingsModule bookingType={BookingType.LubeservOnSite} />}
      />
      <Redirect to={Path.LubeServOnSiteBookings} />
    </Switch>
  );
};

export default LubeServOnSiteBookingsPage;
