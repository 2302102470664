import { Field, Filter, SearchField, DateRange, Autocomplete, Button } from "components/commons";

import locale from "localization";
import { CampaignStatus, VoucherCampaignCurrency, VoucherCampaignType } from "enums";
import React, { useCallback } from "react";
import styles from "./voucher-filter.module.scss";
import { mapOptions } from "utils/object.utils";
import { prettifyVoucherCampaignGroup } from "utils/pretty.utils";
import VoucherCampaignGroup from "enums/campaign-group";
import SelectCampaignCreators from "components/field/select-campaign-creators/select-campaign-creators";

const VoucherFilter = ({
  filterState,
  clearFilter,
  onSearchChange,
  onSearch,
  resetFilter,
  submitFilter,
  filterCount,
  onDateRangeChange,
  exportReport,
  exporting,
  modifyFilters,
}) => {
  const { Upcoming, Ongoing, Ended, Cancelled } = CampaignStatus;
  const { Generic, Unique } = VoucherCampaignType;
  const { Peso, Liter, Points } = VoucherCampaignCurrency;

  const onDateRangeChangeCb = useCallback(
    (name, { value, dirty }) => {
      if (onDateRangeChange) return onDateRangeChange(value, dirty);
    },
    [onDateRangeChange]
  );

  const onSearchChangeCb = useCallback(
    (name, { value }) => {
      if (onSearchChange) {
        return onSearchChange("searchKey", { value });
      }
    },
    [onSearchChange]
  );

  const onSearchCb = useCallback(
    (searchKey) => {
      if (onSearch) {
        return onSearch(searchKey);
      }
    },
    [onSearch]
  );

  return (
    <div className={styles.filter}>
      <Filter
        submit={submitFilter}
        close={resetFilter}
        clear={clearFilter}
        filterCount={filterCount}
      >
        <div className={styles.content}>
          <Field
            className={styles.filterInput}
            label={locale.dateCreated}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
          >
            <DateRange
              value={{
                startDate: filterState.startDate,
                endDate: filterState.endDate,
              }}
              onChange={onDateRangeChangeCb}
            />
          </Field>

          <Field
            className={styles.filterInput}
            label={locale.type}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
          >
            <Autocomplete
              name="type"
              value={filterState.type}
              options={[
                { label: locale.generic, value: Generic },
                { label: locale.unique, value: Unique },
              ]}
              onChange={(name, { value }) => {
                modifyFilters({ [name]: value });
              }}
              multiple
              hasAll
            />
          </Field>

          <Field
            className={styles.filterInput}
            label={locale.currency}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
          >
            <Autocomplete
              name="currency"
              value={filterState.currency}
              options={[
                { label: locale.peso, value: Peso },
                { label: locale.liter, value: Liter },
                { label: locale.points, value: Points },
              ]}
              hasAll
              onChange={(name, { value }) => {
                modifyFilters({ [name]: value });
              }}
              multiple
            />
          </Field>

          <Field
            className={styles.filterInput}
            label={locale.status}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
          >
            <Autocomplete
              name="status"
              value={filterState.status}
              hasAll
              options={[
                { label: locale.upcoming, value: Upcoming },
                { label: locale.ongoing, value: Ongoing },
                { label: locale.ended, value: Ended },
                { label: locale.cancelled, value: Cancelled },
              ]}
              onChange={(name, { value }) => {
                modifyFilters({ [name]: value });
              }}
              multiple
            />
          </Field>

          <Field
            className={styles.filterInput}
            label={locale.campaignGroups}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
          >
            <Autocomplete
              name="campaignGroups"
              value={filterState.campaignGroups}
              hasAll
              options={mapOptions(
                [
                  VoucherCampaignGroup.SPIAllocation,
                  VoucherCampaignGroup.SPIAllowance,
                  // VoucherCampaignGroup.SPIObTravel,
                  VoucherCampaignGroup.Others,
                ],
                prettifyVoucherCampaignGroup
              )}
              onChange={(name, { value }) => {
                modifyFilters({ [name]: value });
              }}
              multiple
            />
          </Field>

          <Field
            className={styles.filterInput}
            label={locale.campaignCreators}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
          >
            <SelectCampaignCreators
              {...filterState.campaignCreators}
              onChange={(name, { value, label, isAll }) => {
                modifyFilters({ campaignCreators: { value, label, isAll } });
              }}
              disableAllOption
              placeholder={locale.allCampaignCreators}
            />
          </Field>
        </div>
      </Filter>

      <Field className={styles.search}>
        <SearchField
          placeholder={`${locale.voucherCampaignId}, ${locale.name}, ${locale.prefix}`}
          value={filterState.searchKey}
          onChange={onSearchChangeCb}
          onEnter={onSearchCb}
          searchWithSymbols={true}
          restrict={false}
        />
      </Field>
      <Field className={styles.downloadButton}>
        <Button
          startIcon={<span className="icon-download" />}
          exportButton
          onClick={exportReport}
          loading={exporting}
        >
          {locale.export}
        </Button>
      </Field>
    </div>
  );
};

export default VoucherFilter;
