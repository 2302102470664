import { DataTable, Pill, Text } from "components/commons";
import React from "react";
import locale from "localization";
import { useMemo } from "react";
import styles from "./unique-voucher-list.module.scss";
import LabelAndValue from "components/commons/label-and-value/label-and-value";
import {
  formatAmount,
  formatDate,
  formatName,
  formatPesoWithDecimalRange,
  prettifyVoucherCampaignCurrency,
} from "utils";
import { DateTime, VoucherCampaignCurrency } from "enums";
import { prettifyVoucherCampaignGroup, prettifyVoucherStatus } from "utils/pretty.utils";
import VoucherStatus from "enums/voucher-status";
import classNames from "classnames";

const UniqueVoucherList = ({ loading, result, count, filterState, modifyFilters, fetchData }) => {
  const data = useMemo(() => {
    return result.map(({ voucherCode, voucherCampaign = {}, claimedBy, status, claimedAt }) => {
      const {
        campaignName,
        voucherCurrency,
        voucherValue,
        startDate,
        endDate,
        createdAt,
        updatedAt,
        voucherCampaignGroup,
        createdBy,
        createdByEmail,
      } = voucherCampaign;
      const map = new Map();

      const obj = {
        voucherCode,
        campaignName: <div className={styles.columnText}>{campaignName}</div>,
        valueCurrency: (
          <LabelAndValue label={prettifyVoucherCampaignCurrency(voucherCurrency)}>
            {voucherCurrency === VoucherCampaignCurrency.Peso
              ? formatPesoWithDecimalRange(voucherValue)
              : formatAmount(voucherValue)}
          </LabelAndValue>
        ),
        claimedBy: claimedBy ? (
          <div>
            <LabelAndValue label={claimedBy?.mobileNumber}>
              <div>{formatName(claimedBy?.firstName, claimedBy?.lastName)}</div>
            </LabelAndValue>
          </div>
        ) : (
          "-"
        ),
        customerId: claimedBy?.userId || "-",
        startDateEndDate: (
          <LabelAndValue label={formatDate(endDate, DateTime.A)}>
            {formatDate(startDate, DateTime.A)}
          </LabelAndValue>
        ),
        dateClaimedDateUsed: (
          <LabelAndValue label={claimedAt ? formatDate(claimedAt, DateTime.A) : "--"}>
            {claimedAt ? formatDate(claimedAt, DateTime.A) : "--"}
          </LabelAndValue>
        ),
        dateCreated: (
          <LabelAndValue label={formatDate(endDate, DateTime.A)}>
            {formatDate(createdAt, DateTime.A)}
          </LabelAndValue>
        ),
        voucherCampaignGroup: voucherCampaignGroup
          ? prettifyVoucherCampaignGroup(voucherCampaignGroup)
          : "-",
        createdBy: createdByEmail || createdBy || "-",
        status: (
          <>
            <Pill
              className={classNames.pill}
              cement={VoucherStatus.Expired === status}
              cheddar={VoucherStatus.Unclaimed === status}
              grass={VoucherStatus.Used === status}
              sky={VoucherStatus.AvailableInApp === status}
              deepRed={VoucherStatus.Void === status}
            >
              {prettifyVoucherStatus(status)}
            </Pill>
            <Text
              subtitle
              style={{
                marginTop: "10px",
              }}
            >
              {updatedAt && formatDate(updatedAt, DateTime.G)}
            </Text>
          </>
        ),
      };

      Object.keys(obj).forEach((key) => {
        map.set(key, obj[key]);
      });
      return map;
    });
  }, [result]);
  return (
    <div>
      <DataTable
        dataCount={count}
        loading={loading}
        columns={[
          {
            key: "voucherCode",
            text: locale.voucherCode,
            width: "5%",
          },
          {
            key: "voucherCampaignGroup",
            text: locale.campaignGroup,
            width: "5%",
            className: "min-100",
          },
          {
            key: "campaignName",
            text: locale.campaignName,
            width: "10%",
            className: "min-100",
          },
          {
            key: "createdBy",
            text: locale.campaignCreator,
            width: "5%",
            className: "min-100",
          },
          {
            key: "valueCurrency",
            text: locale.valueCurrency,
            width: "10%",
          },
          {
            key: "claimedBy",
            text: locale.claimedBy,
            width: "10%",
            className: "min-100",
          },
          {
            key: "customerId",
            text: locale.customerId,
            width: "10%",
            className: "min-150",
          },
          {
            key: "startDateEndDate",
            text: locale.startDateEndDate,
            width: "10%",
            className: "min-90",
          },
          {
            key: "dateClaimedDateUsed",
            text: locale.dateClaimedDateUsed,
            width: "5%",
            className: "min-90",
          },

          {
            key: "dateCreated",
            text: locale.dateCreated,
            width: "10%",
            className: "min-100",
          },
          {
            key: "status",
            text: locale.status,
            width: "8%",
            className: "min-90",
          },
        ]}
        data={data}
        page={filterState.page}
        pageSize={filterState.perPage}
        onChangePage={(page) => {
          const { requestState } = modifyFilters({
            page,
          });
          fetchData(requestState);
        }}
        onChangePageSize={(perPage) => {
          const { requestState } = modifyFilters({
            perPage,
          });
          fetchData(requestState);
        }}
      />
    </div>
  );
};

export default UniqueVoucherList;
