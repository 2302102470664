import { Field, Filter, SearchField, DateRange, Button } from "components/commons";
import locale from "localization";
import React, { useCallback } from "react";
import styles from "./user-attributes-filter.module.scss";

const UserAttributesFilter = ({
  filterState,
  clearFilter,
  onSearchChange,
  onSearch,
  resetFilter,
  submitFilter,
  filterCount,
  onDateRangeChange,
}) => {
  const onDateRangeChangeCb = useCallback(
    (name, { value, dirty }) => {
      if (onDateRangeChange) return onDateRangeChange(value, dirty);
    },
    [onDateRangeChange]
  );

  const onSearchChangeCb = useCallback(
    (name, { value }) => {
      if (onSearchChange) {
        return onSearchChange("searchKey", { value });
      }
    },
    [onSearchChange]
  );

  const onSearchCb = useCallback(
    (searchKey) => {
      if (onSearch) {
        return onSearch(searchKey);
      }
    },
    [onSearch]
  );

  return (
    <div className={styles.filter}>
      <Filter
        submit={submitFilter}
        close={resetFilter}
        clear={clearFilter}
        filterCount={filterCount}
      >
        <div className={styles.content}>
          <Field
            className={styles.filterInput}
            label={locale.dateCreated}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
          >
            <DateRange
              value={{
                startDate: filterState.startDate,
                endDate: filterState.endDate,
              }}
              onChange={onDateRangeChangeCb}
            />
          </Field>
        </div>
      </Filter>

      <Field className={styles.search}>
        <SearchField
          placeholder={locale.name}
          value={filterState.searchKey}
          onChange={onSearchChangeCb}
          onEnter={onSearchCb}
          restrict={false}
        />
      </Field>
      <Field className={styles.downloadButton}>
        <Button
          startIcon={<span className="icon-download" />}
          exportButton
          onClick={() => {}}
          disabled
        >
          {locale.export}
        </Button>
      </Field>
    </div>
  );
};

export default UserAttributesFilter;
