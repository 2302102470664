import React from "react";
import { useModal } from "hooks";
import locale from "localization";
import styles from "./user-attribute-add.module.scss";
import { FormMode } from "enums";
import { Path } from "enums";
import { Intro, BackLink } from "components/commons";
import UserAttributeDetailsModule from "../user-attribute-details/user-attribute-details.module";

const AddUserAttributeModule = () => {
  const successModal = useModal();

  return (
    <div>
      <BackLink text={locale.userAttributes} path={Path.Attributes} />
      <div className={styles.intro}>
        <Intro title={locale.addUserAttribute} titleStyle={styles.title} />
        <UserAttributeDetailsModule pageMode={FormMode.Add} {...successModal} />
      </div>
    </div>
  );
};

export default AddUserAttributeModule;
