import Currency from "enums/currency";
import VoucherStatus from "enums/voucher-status";

export const filterState = () => ({
  startDate: null,
  endDate: null,
  voucherCurrency: [Currency.Peso],
  statuses: [VoucherStatus.Available, VoucherStatus.Claimed, VoucherStatus.Expired],
  searchKey: "",
  page: 1,
  perPage: 10,
});
