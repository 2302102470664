const UserPLCAccess = {
  Dashboard: "DASHBOARD",
  CashFlow: "CASH_FLOW",
  CashIn: "CASH_IN",
  ConsumerFuel: "CONSUMER_FUEL",
  BuyFuel: "BUY_FUEL",
  Redemption: "PLC_REDEMPTION",
  ProductConversion: "PRODUCT_CONVERSION",
  Customer: "CUSTOMER",
  CustomerList: "CUSTOMER_LIST",
  AttributesList: "ATTRIBUTES_LIST",
  AccountTypes: "ACCOUNT_TYPES",
  KycSubmissions: "KYC_SUBMISSIONS",
  CampaignAndPrograms: "CAMPAIGN_AND_PROGRAMS",
  Campaign: "CAMPAIGN",
  VoucherV2: "VOUCHER_V2",
  Vouchers: "VOUCHERS",
  NewCustomerVouchers: "NEW_CUSTOMER_VOUCHERS",
  ConsumerPricingPage: "CONSUMER_PRICING_PAGE",
  PriceBuildUp: "PRICE_BUILD_UP",
  ConsumerOtherServices: "CONSUMER_OTHER_SERVICES",
  RFIDLoading: "RFID_LOADING",
  PayWithPeso: "PAY_WITH_PESO",
  LOCQPayNonFuel: "LOCQPAY_NON_FUEL",
  LubeServOnWheels: "LUBESERV_ON_WHEELS_BOOKINGS",
  LubeServOnSite: "LUBESERV_ON_SITE_BOOKINGS",
  AccessAuthorization: "ACCESS_AUTHORIZATION",
  UserAccess: "USER_ACCESS",
};

export default UserPLCAccess;
