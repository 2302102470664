import locale from "localization";
import Validation from "utils/validation.utils";

export const initialState = (state = {}) => {
  const {
    campaignName = {
      value: "",
    },
    campaignDuration = {
      value: { startDate: null, endDate: null },
    },
    diesel = {
      value: "",
    },
    gas91 = {
      value: "",
    },
    gas95 = {
      value: "",
    },
    gas97 = {
      value: "",
    },
    accountTypes = {
      value: [],
    },
    stations = {
      value: [],
    },
    cohorts = {
      value: [],
    },
    excludedAttributes = {
      value: [],
    },
    shoulderedBy = {
      value: "",
    },
    paymentMethodRestriction = {
      value: "",
      disabled: false,
    },
    bins = {
      value: "",
    },
    monthlyVolumeCap = {
      value: "",
    },
  } = state;

  return {
    campaignName: {
      name: "campaignName",
      required: true,
      validations: [Validation.required({ noMessage: true }), Validation.maxlength(150)],
      label: locale.campaignName,
      horizontal: true,
      ...campaignName,
    },
    campaignDuration: {
      name: "campaignDuration",
      required: true,
      validations: [Validation.required({ noMessage: true })],
      label: locale.campaignDuration,
      horizontal: true,
      ...campaignDuration,
    },
    diesel: {
      name: "diesel",
      required: true,
      validations: [
        Validation.required({ noMessage: true }),
        Validation.max(30, { isFormatted: true }),
      ],
      label: locale.diesel,
      innerLabel: true,
      parseNumber: true,
      horizontal: true,
      ...diesel,
    },
    gas91: {
      name: "gas91",
      required: true,
      validations: [
        Validation.required({ noMessage: true }),
        Validation.max(30, { isFormatted: true }),
      ],
      label: locale.gas91,
      innerLabel: true,
      parseNumber: true,
      horizontal: true,
      ...gas91,
    },
    gas95: {
      name: "gas95",
      required: true,
      validations: [
        Validation.required({ noMessage: true }),
        Validation.max(30, { isFormatted: true }),
      ],
      label: locale.gas95,
      innerLabel: true,
      parseNumber: true,
      horizontal: true,
      ...gas95,
    },
    gas97: {
      name: "gas97",
      required: true,
      validations: [
        Validation.required({ noMessage: true }),
        Validation.max(30, { isFormatted: true }),
      ],
      label: locale.gas97,
      innerLabel: true,
      parseNumber: true,
      horizontal: true,
      ...gas97,
    },
    accountTypes: {
      name: "accountTypes",
      label: locale.accountType,
      horizontal: true,
      isAll: false,
      allSelectedText: [locale.all, locale.accountTypes].join(" "),
      ...accountTypes,
    },
    stations: {
      name: "stations",
      ...stations,
    },
    cohorts: {
      name: "cohorts",
      ...cohorts,
    },
    excludedAttributes: {
      name: "excludedAttributes",
      ...excludedAttributes,
    },
    shoulderedBy: {
      name: "shoulderedBy",
      horizontal: true,
      required: true,
      validations: [Validation.required({ noMessage: true })],
      options: [
        {
          label: "LOCQ",
          value: "locq",
        },
        {
          label: "OilCo",
          value: "oilco",
        },
        {
          label: "Station-Type Dependent",
          value: "station-type-dependent",
        },
      ],
      ...shoulderedBy,
    },
    paymentMethodRestriction: {
      name: "paymentMethodRestriction",
      required: true,
      label: locale.setPaymentMethodRestriction,
      innerLabel: true,
      horizontal: true,
      ...paymentMethodRestriction,
    },
    bins: {
      name: "bins",
      required: false,
      validations: [Validation.maxlength(150)],
      label: " ",
      horizontal: true,
      ...bins,
    },
    monthlyVolumeCap: {
      name: "monthlyVolumeCap",
      required: false,
      validations: [Validation.required({ noMessage: true }), Validation.maxlength(150)],
      label: locale.monthlyVolumeCap,
      innerLabel: true,
      parseNumber: true,
      horizontal: true,
      disabled: true,
      inputDisabled: true,
      ...monthlyVolumeCap,
    },
  };
};
