import locale from "localization";
import Validation from "utils/validation.utils";

export const initialState = (state = {}) => {
  const {
    mogas = {
      label: locale.mogas,
      value: "",
    },
    gasoil = {
      label: locale.gasoil,
      value: "",
    },
    effectiveAt = {
      label: locale.effectiveDate,
      value: "",
    },
  } = state;
  return {
    mogas: {
      name: "mogas",
      required: true,
      validations: [Validation.required({ noMessage: true })],
      innerLabel: true,
      parseNumber: true,
      ...mogas,
    },
    gasoil: {
      required: true,
      validations: [Validation.required({ noMessage: true })],
      innerLabel: true,
      parseNumber: true,
      name: "gasoil",
      ...gasoil,
    },
    effectiveAt: {
      required: true,
      validations: [Validation.required({ noMessage: true })],
      innerLabel: true,
      name: "effectiveAt",
      ...effectiveAt,
    },
  };
};
