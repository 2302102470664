import React from "react";
import { Text, Field, Button } from "components/commons";
import styles from "./settlement-collection.module.scss";
import locale from "localization";
import { dateTimeFormat } from "utils";

export const renderSingleSettlement = (settlements, settlementCollectionRedirection) => {
  return (
    <>
      <div style={{ marginTop: 20 }}></div>
      <Field
        className={styles.fieldLabel}
        label={locale.dateProcessed}
        labelClassName={styles.fieldModalLabel}
        labelPosition={"left"}
        childrenClassName={styles.fieldContent}
      >
        {dateTimeFormat(settlements?.createdAt)}
      </Field>
      <Field
        className={styles.fieldLabel}
        label={locale.settlementId}
        labelClassName={styles.fieldModalLabel}
        labelPosition={"left"}
        childrenClassName={styles.fieldContent}
      >
        <Button
          link
          onClick={() =>
            refetchSettlement(settlements?.settlementId, settlementCollectionRedirection)
          }
        >
          {settlements?.settlementId}
        </Button>
      </Field>
    </>
  );
};

export const renderMultipleSettlements = (settlements, settlementCollectionRedirection) => {
  return (
    <>
      <div className={styles.multipleSettlementCollection}>
        <Text className={styles.fieldModalHeading}>{locale.dateProcessed}</Text>
        <Text className={styles.fieldModalHeading}>{locale.settlementId}</Text>
      </div>
      {settlements.map((settlement, key) => (
        <Field
          key={key}
          className={styles.fieldLabel}
          label={dateTimeFormat(settlement?.createdAt)}
          labelClassName={styles.fieldModalLabel}
          labelPosition={"left"}
          childrenClassName={styles.fieldContent}
        >
          <Button
            link
            onClick={() =>
              refetchSettlement(settlement?.settlementId, settlementCollectionRedirection)
            }
          >
            {settlement?.settlementId}
          </Button>
        </Field>
      ))}
    </>
  );
};

const refetchSettlement = (settlementId, method) => {
  method && method(settlementId);
};
