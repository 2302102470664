import { DateTime } from "enums";
import { capitalizeAll, formatAmount } from "utils";
import { Text } from "components/commons";
import moment from "moment";

export const mapDataToList = (mops) => {
  const { batchId, createdAt, effectiveAt, productType, price } = mops;

  return {
    batchId: (
      <div>
        <Text small>{batchId}</Text>
      </div>
    ),
    createdDate: (
      <div>
        <Text>
          {moment(createdAt).format(DateTime.A) + " "}
          {moment(createdAt).format(DateTime.B)}
        </Text>
      </div>
    ),
    effectiveDate: (
      <div>
        <Text>
          {moment(effectiveAt).format(DateTime.A) + " "}
          {moment(effectiveAt).format(DateTime.B)}
        </Text>
      </div>
    ),
    product: (
      <div>
        <Text small>{capitalizeAll(productType)}</Text>
      </div>
    ),
    price: (
      <div>
        <Text small>{formatAmount(price)}</Text>
      </div>
    ),
  };
};

export const mapFilterToRequest = (filterState) => {
  return { ...filterState };
};
