import locale from "localization";
import { RedeemLimitType } from "enums";
import { prettifyRedeemLimitType } from "utils";
import Validation from "utils/validation.utils";

export const initialState = (state = {}) => {
  const {
    name = {
      value: "",
    },
    walletSize = {
      value: "",
    },
    fuelTankSize = {
      value: "",
    },
    totalCashinLimitDaily = {
      value: "",
    },
    totalCashinLimitMonthly = {
      value: "",
    },
    totalTransactionCardDaily = {
      value: "",
    },
    totalTransactionCardMonthly = {
      value: "",
    },
    totalBuyFuelLimitDaily = {
      value: "",
    },
    totalBuyFuelLimitMonthly = {
      value: "",
    },
    totalPayWithPesoLimitDaily = {
      value: "",
    },
    totalPayWithPesoLimitMonthly = {
      value: "",
    },
    totalRedeemLimitDaily = {
      value: "",
    },
    totalRedeemLimitType = {
      value: "",
    },
    totalRedemptionLiterLimitDaily = {
      value: "",
    },
  } = state;

  return {
    name: {
      name: "name",
      required: true,
      validations: [Validation.name(), Validation.required({ noMessage: true })],
      maxLength: 25,
      label: locale.accountTypeName,
      horizontal: true,
      ...name,
    },
    walletSize: {
      name: "walletSize",
      required: true,
      validations: [Validation.required({ noMessage: true })],
      label: locale.pesoWalletSize,
      innerLabel: true,
      parseNumber: true,
      horizontal: true,
      ...walletSize,
    },
    fuelTankSize: {
      name: "fuelTankSize",
      required: true,
      validations: [Validation.required({ noMessage: true })],
      label: locale.fuelTankSize,
      innerLabel: true,
      parseNumber: true,
      horizontal: true,
      ...fuelTankSize,
    },
    totalCashinLimitDaily: {
      name: "totalCashinLimitDaily",
      required: true,
      validations: [Validation.required({ noMessage: true })],
      label: locale.totalCashInLimit,
      subLabel: locale.willOverrideViaCardLimit,
      innerLabel: true,
      parseNumber: true,
      horizontal: true,
      ...totalCashinLimitDaily,
    },
    totalCashinLimitMonthly: {
      name: "totalCashinLimitMonthly",
      required: true,
      validations: [Validation.required({ noMessage: true })],
      label: true,
      innerLabel: true,
      parseNumber: true,
      horizontal: true,
      ...totalCashinLimitMonthly,
    },
    totalTransactionCardDaily: {
      name: "totalTransactionCardDaily",
      required: true,
      validations: [Validation.required({ noMessage: true })],
      label: locale.transactionsViaCard,
      innerLabel: true,
      parseNumber: true,
      horizontal: true,
      ...totalTransactionCardDaily,
    },
    totalTransactionCardMonthly: {
      name: "totalTransactionCardMonthly",
      required: true,
      validations: [Validation.required({ noMessage: true })],
      label: true,
      innerLabel: true,
      parseNumber: true,
      horizontal: true,
      ...totalTransactionCardMonthly,
    },
    totalBuyFuelLimitDaily: {
      name: "totalBuyFuelLimitDaily",
      required: true,
      validations: [Validation.required({ noMessage: true })],
      label: locale.totalBuyFuelLimit,
      subLabel: locale.willOverrideViaCardLimit,
      innerLabel: true,
      parseNumber: true,
      horizontal: true,
      ...totalBuyFuelLimitDaily,
    },
    totalBuyFuelLimitMonthly: {
      name: "totalBuyFuelLimitMonthly",
      required: true,
      validations: [Validation.required({ noMessage: true })],
      label: true,
      innerLabel: true,
      parseNumber: true,
      horizontal: true,
      ...totalBuyFuelLimitMonthly,
    },
    totalPayWithPesoLimitDaily: {
      name: "totalPayWithPesoLimitDaily",
      required: true,
      validations: [Validation.required({ noMessage: true })],
      label: locale.totalPayWithPesoLimit,
      innerLabel: true,
      parseNumber: true,
      horizontal: true,
      ...totalPayWithPesoLimitDaily,
    },
    totalPayWithPesoLimitMonthly: {
      name: "totalPayWithPesoLimitMonthly",
      required: true,
      validations: [Validation.required({ noMessage: true })],
      label: true,
      innerLabel: true,
      parseNumber: true,
      horizontal: true,
      ...totalPayWithPesoLimitMonthly,
    },
    totalRedeemLimitDaily: {
      name: "totalRedeemLimitDaily",
      required: true,
      validations: [Validation.required({ noMessage: true }), Validation.numeric()],
      label: locale.redemptionLimit,
      innerLabel: true,
      parseNumber: true,
      horizontal: true,
      ...totalRedeemLimitDaily,
    },
    totalRedeemLimitType: {
      name: "totalRedeemLimitType",
      required: true,
      validations: [Validation.required({ noMessage: true })],
      horizontal: true,
      options: [
        {
          label: prettifyRedeemLimitType(RedeemLimitType.Station),
          value: RedeemLimitType.Station,
        },
        {
          label: prettifyRedeemLimitType(RedeemLimitType.Universal),
          value: RedeemLimitType.Universal,
        },
      ],
      ...totalRedeemLimitType,
    },
    totalRedemptionLiterLimitDaily: {
      name: "totalRedemptionLiterLimitDaily",
      required: true,
      validations: [Validation.required({ noMessage: true })],
      label: locale.literLimit,
      innerLabel: true,
      parseNumber: true,
      horizontal: true,
      ...totalRedemptionLiterLimitDaily,
    },
  };
};
