import { capitalize, formatAmount } from "utils";

const getGrouper = ({ content = "", key = "data", formatter = formatAmount, groupers = [] }) => {
  return {
    content: content,
    key: key,
    dataset: true,
    formatter: formatter,
    groupers: groupers,
  };
};

const getColumns = (data = {}, key = "") => {
  const keys = Object.keys(data);
  if (keys.length) {
    const d = data[keys[0]];
    const groupers = [];
    if (d?.[key]) {
      Object.keys(d?.[key]).forEach((year) => {
        groupers.push({
          key: year,
          text: year,
          formatter: formatAmount,
          data: Object.keys(d[key][year]).map((month) => {
            return {
              key: month,
              text: `${capitalize(month)} ${year}`,
              formatter: formatAmount,
            };
          }),
        });
      });
    }
    return groupers;
  }
  return [];
};

const formatContent = (data = {}) => {
  const d = {};
  let total = 0;
  Object.keys(data).forEach((item) => {
    let t = 0;
    const year = { ...data[item] };
    Object.keys(year).forEach((month) => {
      t = t + year[month] || 0;
    });
    year.total = t;
    total = total + t;
    d[item] = {
      ...year,
    };
  });
  d.total = total || 0;
  return { ...d };
};

export const prepaymentMapDataToList = (prepayment = {}, total = {}) => {
  const result = Object.keys(prepayment).map((customerId) => {
    const data = prepayment[customerId];
    const claimedVouchers = formatContent(data.claimedVouchers, "claimedVouchers");
    const scanToPay = formatContent(data.scanToPay, "ScanToPay");
    const remaining = claimedVouchers?.total - scanToPay?.total;
    return {
      customerId,
      claimedVouchers,
      scanToPay,
      remaining: remaining <= 0 ? 0 : remaining,
    };
  });
  const totalClaimedVouchers = formatContent(total?.claimedVouchers || {}, "totalVoucher");
  const totalScanToPay = formatContent(total?.scanToPay || {}, "totalScanToPay");
  const totalRemaining = totalClaimedVouchers?.total - totalScanToPay?.total;
  return {
    result: result.length
      ? [
          ...result,
          {
            isTotalHightlight: true,
            customerId: "Total",
            claimedVouchers: totalClaimedVouchers,
            scanToPay: totalScanToPay,
            remaining: totalRemaining <= 0 ? 0 : totalRemaining || 0,
          },
        ]
      : [],
    columns: [
      {
        content: "Customer ID",
        key: "customerId",
      },
      getGrouper({
        content: "Claimed Vouchers",
        key: "claimedVouchers",
        groupers: getColumns(prepayment, "claimedVouchers"),
      }),
      getGrouper({
        content: "Scan-to-Pay Amounts",
        key: "scanToPay",
        groupers: getColumns(prepayment, "scanToPay"),
      }),
      {
        content: "Remaining Prepayment Balance",
        key: "remaining",
        formatter: formatAmount,
      },
    ],
  };
};

export const prepaymentMapFilterToRequest = ({ searchKey, ...filterState }) => {
  return {
    ...filterState,
    userId: searchKey,
  };
};
