import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import locale from "localization";
import styles from "./account-view.module.scss";
import { FormMode, Path, Portal, Role } from "enums";
import { Intro } from "components/commons";
import AccountDetailsModule from "../account-details/account-details.module";
import { useRouter } from "hooks";
import { UserContext } from "contexts";

const ViewFleetAccountModule = (props) => {
  const { query } = useRouter();
  const history = useHistory();
  const fleetId = query?.id ?? "";
  const { user } = useContext(UserContext);
  const { role } = user?.userAccesses?.find((element) => {
    return element.portal === Portal.LOCQ;
  });

  return (
    <div>
      <div className={styles.intro}>
        {role === Role.Accounting ? <Intro
          title={locale.fleetAccountDetails}
        /> : <Intro
        title={locale.fleetAccountDetails}
        actionText={locale.edit}
        actionOnClick={() => {
          history.push(Path.EditFleetAccountById(fleetId));
        }}
        actionOutlined
        actionClassName={styles.primaryEditButton}
        actionStartIcon={<span className="icon-pencil" />}
      /> }
        
        <AccountDetailsModule pageMode={FormMode.View} fleetId={fleetId} />
      </div>
    </div>
  );
};

export default ViewFleetAccountModule;
