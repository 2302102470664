import React from "react";
import { DateRange, Field, SearchField, Filter, Button } from "components/commons";
import locale from "localization";
import styles from "./price-movement-filter.module.scss";

const PriceMovementFilter = ({
  filterState,
  applyFilter,
  applyClearFilter,
  modifyFilter,
  modifySearch,
  applySearch,
  applyClearSearch,
  searchKey,
  filterCount,
  resetFilter,
  exportReport,
  exporting,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.filter}>
        <Filter
          clear={applyClearFilter}
          submit={applyFilter}
          filterCount={filterCount}
          close={resetFilter}
        >
          <Field
            labelPosition={"left"}
            className={styles.date}
            childrenClassName={styles.fieldContent}
            label={locale.effectiveDate}
          >
            <DateRange
              value={{
                startDate: filterState.startDate,
                endDate: filterState.endDate,
              }}
              onChange={(name, { value }) => {
                const { startDate, endDate } = value;
                modifyFilter({
                  startDate,
                  endDate,
                });
              }}
            />
          </Field>
        </Filter>
        <Field className={styles.search}>
          <SearchField
            placeholder={locale.priceMovementId}
            value={searchKey}
            onChange={(_, { value }) => modifySearch(value)}
            onEnter={applySearch}
            onClear={applyClearSearch}
          />
        </Field>
        <Field className={styles.downloadButton}>
          <Button
            startIcon={<span className="icon-download" />}
            exportButton
            onClick={exportReport}
            loading={exporting}
          >
            {locale.export}
          </Button>
        </Field>
      </div>
    </div>
  );
};

export default PriceMovementFilter;
