import React from "react";
import { BookingType, Path } from "enums";
import { Route, Switch, Redirect } from "react-router-dom";
import LubeServBookingsModule from "modules/plc/lubeserv-bookings/lubeserv-bookings.module";

const LubeServOnWheelsBookingsPage = () => {
  return (
    <Switch>
      <Route
        exact
        path={Path.LubeServOnSiteBookings}
        component={() => <LubeServBookingsModule bookingType={BookingType.LubeservOnWheels} />}
      />
      <Redirect to={Path.LubeServeOnWheelsBookings} />
    </Switch>
  );
};

export default LubeServOnWheelsBookingsPage;
