import React from "react";

import { Intro } from "components/commons";
import useDataTable from "hooks/useDataTable";
import locale from "localization";

import { mapApiRequest, mapDataToList, mapFilterToRequest } from "./voucher.mapper";
import { voucherCampaignFilterState } from "./voucher-campaign-filter.state";
import { columns } from "./voucher-campaign-columns";

import DataTableV2 from "components/commons/data-table/data-table-v2";

import { ConfirmModal, SuccessModal } from "components/modals";
import useExport from "hooks/useExport";
import { generateReportAdmin } from "apis";
import { SessionContext, UserContext } from "contexts";
import { useApi, useModal, useRouter } from "hooks";
import { Path, Role, Portal } from "enums";
import VoucherCampaignFilter from "./voucher-campaign-filter";
import {
  getVoucherCampaignHistory,
  getVoucherCampaignsV2,
  updateVoucherCampaignStatus,
} from "apis/campaign-program.api";
import { CampaignListModule } from "pages/consumer/campaign.page";
import { useContext } from "react";
import { getVoucherTypeAndSubType } from "enums/voucher-type-options";

const VoucherCampaign = () => {
  const { sessionId } = useContext(SessionContext);
  const { history } = useRouter();
  const confirmModal = useModal();

  const { user } = useContext(UserContext);
  const { role } = user?.userAccesses?.find((element) => {
    return element.portal === Portal.LOCQ;
  });

  const updateVoucherRequest = useApi({
    api: updateVoucherCampaignStatus,
  });

  const getVoucherCampaignHistoryRequest = useApi({
    api: getVoucherCampaignHistory,
  });

  const { filter, search, table } = useDataTable({
    api: { api: getVoucherCampaignsV2, mapper: mapApiRequest },
    filter: { initialState: voucherCampaignFilterState(), mapper: mapFilterToRequest },
    table: {
      key: "voucherCampaigns",
      hasColumnPicker: true,
      initialColumns: [
        "voucherCampaignId",
        "name",
        "voucherPrefix",
        "campaignGroup",
        "voucherType",
        "claimMethod",
        "totalValue",
        "totalClaimed",
        "totalUsed",
        "actions",
      ],
      mapper: (data) =>
        mapDataToList({
          history,
          data,
          updateVoucherRequest,
          confirmModal,
          getVoucherCampaignHistoryRequest,
          role
        }),
      columns,
    },
  });

  const voucher = getVoucherTypeAndSubType(filter?.mappedFilterState.voucherType) || {};

  const exports = useExport({
    api: generateReportAdmin,
    hasModal: true,
    reportType: "voucher-campaign",
    mappedFilterState: {
      ...filter?.mappedFilterState,
      voucherType: voucher.type,
      voucherSubType: voucher.subType,
      searchKey: search.searchKey,
      sessionId,
      voucherCampaignStatuses: filter?.mappedFilterState.status,
      voucherCampaignCreators: filter?.mappedFilterState.campaignCreators,
    },
  });

  return (
    <CampaignListModule>
      <>
        <SuccessModal {...exports?.successModalComponent} />
        <ConfirmModal
          {...confirmModal}
          loading={updateVoucherRequest.loading || getVoucherCampaignHistoryRequest.loading}
          primary={
            confirmModal.primary
              ? {
                  ...confirmModal.primary,
                  onClick: async () => {
                    if (confirmModal.primary?.onClick) {
                      await confirmModal.primary?.onClick();
                      if (confirmModal.refresh) {
                        table.fetch({
                          page: 1,
                        });
                        confirmModal.close();
                      }
                    }
                  },
                }
              : null
          }
        />
        <div>
          {role === Role.Accounting ? <Intro
            title={`${locale.voucher} ${locale.campaigns}`}
            subtitle={locale.viewAndCreateVoucherCampaigns}
          /> : <Intro
          title={`${locale.voucher} ${locale.campaigns}`}
          subtitle={locale.viewAndCreateVoucherCampaigns}
          actionText={locale.createVoucherCampaign}
          actionOnClick={() => {
            history.push(Path.AddVoucherCampaign);
          }}
        />}
          
        </div>
        <VoucherCampaignFilter {...filter} {...search} {...exports} />
        <DataTableV2 {...table} paginationV2={true} />
      </>
    </CampaignListModule>
  );
};

export default VoucherCampaign;
