import locale from "localization";

export const settlementHistoryColumns = [
  {
    key: "oilcoSettlementId",
    text: `${locale.oilcoSettlementId}`,
    width: "10%",
  },
  { key: "totalLiterRedemption", text: locale.totalLiterRedemption, width: "10%" },
  { key: "totalOilcoSettlement", text: locale.totalOilcoSettlementReceivable, width: "10%" },
  {
    key: "totalOilcoSettlementReceivablePayableRemarks",
    text: `${locale.totalOilcoSettlementReceivableRemarks}`,
    width: "10%",
  },
  {
    key: "totalMOPSMovement",
    text: `${locale.totalMOPSMovement}/${locale.totalNationwideMovement}`,
    width: "10%",
  },
  {
    key: "totalMopsRemarks",
    text: `${locale.totalMopsRemarks}`,
    width: "10%",
  },
  { key: "totalPriceDifferential", text: locale.totalPriceMovementDifferential, width: "10%" },
  { key: "totalRedSMovement", text: locale.totalRedSMovement, width: "10%" },
  { key: "totalOilcoShoulderedDiscount", text: locale.totalOilcoShoulderedDiscount, width: "10%" },
  { key: "totalOilcoShoulderedStation", text: locale.totalOilcoShoulderedStation, width: "10%" },
  {
    key: "totalAdditionalOilcoShoulderedAdjustment",
    text: locale.totalAdditionalOilcoShoulderedAdjustment,
    width: "10%",
  },
  {
    key: "oilcoSettlementStatus",
    text: `${locale.totalOilcoSettlementStatus}/${locale.date}`,
    width: "15%",
  },
  { key: "action", text: locale.action, width: "250px" },
];
