import { getCustomers, getUserAttributes, assignUserCohort, generateReportAdmin } from "apis";
import { getAccountTypes, updateUserAccountType } from "apis/acount-type.api";
import { updateUserMobileNumber } from "apis/user.api";
import { DialogDropdown, Image, Intro } from "components/commons";
import { ConfirmModal as ConfirmSaveAttributes, SuccessModal } from "components/modals";
import { SessionContext } from "contexts";
import { CustomerStatus, Path } from "enums";
// import AccountType from "enums/account-type";
import { useApi, useFilter, useModal, useMount, useRouter } from "hooks";
import useExport from "hooks/useExport";
import { ActionIcon } from "images";
import locale from "localization";
import React, { useCallback, useContext, useState } from "react";
import { formatVolume } from "utils";
import ConfirmStatusChange from "./confirm-status-change-modal";
import ConfirmEditMobileModal from "./confirm-edit-mobile-modal";
import { customerColumns } from "./customer-list-columns";
import CustomerFilter from "./customer-list-filter";
import { customerFilterState } from "./customer-list-filter.state";
import styles from "./customer-list.module.scss";
import EditAttributeModal from "./edit-attribute-modal";
import EditMobileNumberModal from "./edit-mobile-number-modal";
import ImportFileModal from "./import-file-modal";
import { capitalize } from "utils/text.utils";
import useDataTable from "hooks/useDataTable";
import { mapDataToList, mapFilterToRequest } from "./customer-list.mapper";
import DataTableV2 from "components/commons/data-table/data-table-v2";

const CustomerListModule = () => {
  const { sessionId } = useContext(SessionContext);
  const history = useRouter();
  const confirmModal = useModal();
  const importFileModal = useModal();
  const editAttributeModal = useModal();
  const editMobileNumberModal = useModal();
  const successUpdateMobileModal = useModal();
  const confirmSaveAttributesModal = useModal();
  const confirmEditMobileModal = useModal();
  const [customerProfile, setCustomerProfile] = useState({});
  const [, setNewRequestFilterState] = useState(null);
  const { submitFilter } = useFilter(customerFilterState());
  const [attributeInput, setAttributeInput] = useState([]);
  const [cohortInputs, setCohortInputs] = useState([]);

  //const { user } = useContext(UserContext);
  // const { role } = user?.userAccesses?.find((element) => {
  //   return element.portal === Portal.LOCQ;
  // });

  const { request: getCustomerRequest } = useApi({
    api: getCustomers,
    modalError: true,
  });

  const getUserAttributesRequest = useApi({
    api: getUserAttributes,
    modalError: true,
    params: { page: 1, perPage: 1000 }, // todo: temporal fix perPage value
  });

  const getAccountTypeRequest = useApi({
    api: getAccountTypes,
    pageError: true,
    params: { page: 1, perPage: 1000 }, // todo: temporal fix perPage value
  });

  const requestChangeAccountType = useApi({
    api: updateUserAccountType,
    modalError: true,
  });

  const requestUpdateMobileNumber = useApi({
    api: updateUserMobileNumber,
    modalError: true,
  });

  const requestAssignUserCohort = useApi({
    api: assignUserCohort,
    modalError: true,
  });

  useMount(() => {
    // fetchCustomers();
    getAccountTypeRequest.request();
    getUserAttributesRequest.request();
  });

  const fetchCustomers = useCallback(
    async (requestStateValue) => {
      const { status, ...rs } = { ...submitFilter(requestStateValue) };
      let referenceStationId = rs?.redemptionStation && rs?.redemptionStation.value;

      if (referenceStationId === "all" || !referenceStationId) {
        rs.referenceStationId = null;
      } else {
        rs.referenceStationId = Number(referenceStationId) || null;
      }

      setNewRequestFilterState({ ...rs, status: status.join(",") });
      await getCustomerRequest({ ...rs, status: status.join(",") });
    },
    [getCustomerRequest, submitFilter]
  );

  const populateAttributes = useCallback(
    ({ userCohorts, attributes }) => {
      let accumulateAttribute = [...attributeInput];
      let cloneCohort = [...cohortInputs];

      userCohorts?.forEach((userCohort) => {
        const entryAttribute = attributes?.filter(
          (attribute) => attribute.attributeId === userCohort?.cohort?.attribute?.attributeId
        )[0];
        const selected = Object.keys(attributeInput).some((k) => {
          return attributeInput[k].name === entryAttribute?.name;
        });

        if (attributes?.length > 0 && !selected) {
          if (entryAttribute) {
            accumulateAttribute.push({
              name: entryAttribute?.name,
              cohorts: entryAttribute?.cohorts,
            });
            cloneCohort.push(userCohort?.cohort?.cohortId);
          }
        } else {
          accumulateAttribute = [];
          cloneCohort = [];
        }
      });
      setAttributeInput(accumulateAttribute);
      setCohortInputs(cloneCohort);
    },
    [attributeInput, cohortInputs, setAttributeInput, setCohortInputs]
  );

  const clearAttributeForm = useCallback(() => {
    setAttributeInput([]);
    setCohortInputs([]);
  }, []);

  const onChangeCohortCb = useCallback(
    (value, name) => {
      let cloneCohort = [...cohortInputs];
      // const { name, value } = event;
      cloneCohort[Number(name)] = value;
      setCohortInputs(cloneCohort);
    },
    [cohortInputs, setCohortInputs]
  );

  const editUserAttribute = useCallback(
    async (data) => {
      if (data) {
        const { mobileNumber, accountType, userCohorts, userId } = data;
        const getUserAttributesResult = await getUserAttributesRequest?.result;
        const { attributes, count, loading } = getUserAttributesResult;

        const accountTypes = getAccountTypeRequest?.result?.accountTypes || [];
        editAttributeModal.show({
          fixDisplay: {
            mobileNumber: {
              value: mobileNumber,
              label: locale.mobileNumber,
              horizontal: true,
            },
          },
          initialState: {
            accountType: {
              value: accountType?.accountTypeId,
              label: locale.accountType,
              options: accountTypes.map((at) => {
                return {
                  label: at.name,
                  value: at.accountTypeId,
                  disabled: accountType?.type !== at.type,
                };
              }),
              horizontal: true,
            },
          },
          userId,
          attributes,
          count,
          loading,
        });
        populateAttributes({ userCohorts, attributes });
      }
    },
    [
      editAttributeModal,
      getAccountTypeRequest?.result?.accountTypes,
      getUserAttributesRequest?.result,
      populateAttributes,
    ]
  );

  const editMobileNumber = useCallback(
    async (data) => {
      if (data) {
        const { mobileNumber, userId, firstName, lastName } = data;

        editMobileNumberModal.show({
          fixDisplay: {
            fromMobileNumber: {
              value: mobileNumber,
              label: locale.currentMobileNumber,
              horizontal: true,
            },
            fullName: {
              value: `${capitalize(firstName)} ${capitalize(lastName)}`,
            },
          },
          initialState: {
            toMobileNumber: {
              value: "",
              label: locale.newMobileNumber,
              placeholder: "XXXXXXXXXX",
              horizontal: true,
            },
          },
          userId,
        });
      }
    },
    [editMobileNumberModal]
  );

  const actionComponent = useCallback(
    ({ name, data }) => {
      return (
        <>
          <DialogDropdown
            className={styles.perPage}
            dropdownClassName={styles.dropdown}
            icon={<Image className={styles.actionIcon} src={ActionIcon} />}
            closeOnClick={true}
            options={[
              {
                label: locale.viewCashins,
                action: () => history.push(Path.ConsumerCashIn, { searchKey: data.mobileNumber }),
                closeOnClick: true,
              },
              {
                label: locale.viewBuyFuel,
                action: () => history.push(Path.ConsumerBuyFuel, { searchKey: data.mobileNumber }),
              },
              {
                label: locale.viewRedemptions,
                action: () =>
                  history.push(Path.ConsumerRedemption, { searchKey: data.mobileNumber }),
              },
              { label: locale.viewReferrals, action: () => {}, disabled: true },
              {
                label: locale.viewPurchasedVouchers,
                action: () => {},
                disabled: true,
              },
              {
                label: locale.editAttributes,
                action: () => {
                  editUserAttribute(data);
                },
              },
              {
                label: data.status === CustomerStatus.Blocked ? locale.reactivate : locale.block,
                action: () => {
                  setCustomerProfile({
                    name,
                    userId: data.userId,
                    status: data.status,
                  });
                  confirmModal.show();
                },
                disabled: data.status === CustomerStatus.Deleted || !data.status,
              },
              {
                label: locale.updateMobileNumber,
                action: () => {
                  editMobileNumber(data);
                },
              },
            ]}
          />
        </>
      );
    },
    [confirmModal, editUserAttribute, editMobileNumber, history]
  );

  const literBalance = (fuelBalances, product) => {
    return formatVolume(
      fuelBalances.find((f) => f.productCode === product)
        ? fuelBalances.find((f) => f.productCode === product).fuelBalance
        : 0,
      "L"
    );
  };
  const { filter, search, table } = useDataTable({
    api: {
      api: getCustomers,
    },
    filter: { initialState: customerFilterState(), mapper: mapFilterToRequest },
    table: {
      key: "customers",
      mapper: (customers) =>
        mapDataToList({ customers, actionComponent, literBalance, customerProfile }),
      columns: customerColumns,
    },
  });

  const submitUpdateMobileNumber = useCallback(
    async (params) => {
      return await requestUpdateMobileNumber.request({
        ...params,
      });
    },
    [requestUpdateMobileNumber]
  );

  const submitChangeAccountType = useCallback(
    async (params) => {
      return await requestChangeAccountType.request({
        ...params,
      });
    },
    [requestChangeAccountType]
  );

  const submitAssignCohort = useCallback(
    async (params) => {
      return await requestAssignUserCohort.request({
        ...params,
      });
    },
    [requestAssignUserCohort]
  );

  const submitAttribute = useCallback(
    async (params) => {
      const { accountType, cohort } = params;
      const changeAccountTypeResult = await submitChangeAccountType(accountType);
      const assignCohortResult = await submitAssignCohort(cohort);

      if (changeAccountTypeResult?.data && assignCohortResult?.message) {
        setCustomerProfile((prevState) => ({
          ...prevState,
          updatedProfile: changeAccountTypeResult?.data,
        }));
        table.fetch({});
        clearAttributeForm();
      }
      editAttributeModal.close();
      confirmSaveAttributesModal.close();
    },
    [
      clearAttributeForm,
      confirmSaveAttributesModal,
      editAttributeModal,
      submitAssignCohort,
      submitChangeAccountType,
      table,
    ]
  );

  const handleSubmitAttributeCb = useCallback(
    (params) => {
      confirmSaveAttributesModal.show({
        title: locale.saveChangesQuestion,
        content: locale.wouldYouLikeToSaveTheseAttributeChanges,
        secondary: {
          text: locale.Cancel,
          loading: false,
        },
        primary: {
          text: locale.confirm,
          onClick: () => {
            submitAttribute(params);
            confirmSaveAttributesModal.close();
          },
        },
      });
    },
    [confirmSaveAttributesModal, submitAttribute]
  );

  const submitMobileNumber = useCallback(
    async (params) => {
      const { fromMobileNumber, toMobileNumber } = params;

      const updateMobileNumberResult = await submitUpdateMobileNumber({
        fromMobileNumber,
        toMobileNumber,
      });

      if (updateMobileNumberResult.message) {
        table.fetch({});
        successUpdateMobileModal.show({
          title: locale.exclamatedSuccess,
          content: params.isExist
            ? locale.successfullyMergedTwoAccounts
            : locale.successfullyUpdatedMobile,
          primary: {
            text: locale.gotIt,
            onClick: () => {
              successUpdateMobileModal.close();
            },
          },
        });
      }

      editMobileNumberModal.close();
      confirmEditMobileModal.close();
    },
    [
      confirmEditMobileModal,
      editMobileNumberModal,
      successUpdateMobileModal,
      submitUpdateMobileNumber,
      table,
    ]
  );

  const handleUpdateMobileNumberCb = useCallback(
    (params) => {
      const contentValues = params.isExist
        ? [
            <strong>{params.fromMobileNumber}</strong>,
            <strong>{params.toMobileNumber}</strong>,
            <strong>{params.fullName}</strong>,
            <strong>{params.mergeToAccount}</strong>,
          ]
        : [
            <strong>{params.fromMobileNumber}</strong>,
            <strong>{params.toMobileNumber}</strong>,
            <strong>{params.fullName}</strong>,
          ];

      confirmEditMobileModal.show({
        title: params.isExist
          ? locale.questionDoYouWantToMergeTwoAccounts
          : locale.questionUpdateMobileNumber,
        content: (
          <locale.Populate
            text={
              params.isExist
                ? locale.youAreNowMergingTwoAccounts
                : locale.youAreNowUpdatingTheMobileNumber
            }
            items={contentValues}
          />
        ),
        params: params,
      });
    },
    [confirmEditMobileModal]
  );

  const openImportCb = useCallback(() => {
    importFileModal.show();
  }, [importFileModal]);

  const editAttributeProps = {
    attributeInput,
    setAttributeInput,
    cohortInputs,
    setCohortInputs,
    clearAttributeForm,
    onChangeCohortCb,
  };

  const exports = useExport({
    api: generateReportAdmin,
    reportType: "customer-list",
    mappedFilterState: {
      ...filter?.mappedFilterState,
      searchKey: search.searchKey,
      userStatus: filter?.mappedFilterState?.status,
      sessionId,
    },
    hasModal: true, // temp fix
  });

  return (
    <div className={styles.content}>
      <SuccessModal {...exports?.successModalComponent} />
      <div>
        <Intro
          titleStyle={styles.titleStyle}
          textStyle={styles.textStyle}
          title={locale.customerList}
          subtitle={locale.viewDetailsOfPriceLocqAppUsers}
        />
      </div>
      <div className={styles.filters}>
        <CustomerFilter {...filter} {...search} {...exports} openImport={openImportCb} />
      </div>
      <div className={styles.table}>
        <DataTableV2 {...table} />
      </div>
      <ImportFileModal {...importFileModal} fetchCustomersRequest={fetchCustomers} />
      <EditAttributeModal
        submit={handleSubmitAttributeCb}
        submitting={requestChangeAccountType.loading || requestAssignUserCohort.loading}
        {...editAttributeModal}
        {...editAttributeProps}
      />
      <EditMobileNumberModal
        submit={handleUpdateMobileNumberCb}
        submitting={requestUpdateMobileNumber.loading}
        {...editMobileNumberModal}
        {...editAttributeProps}
      />
      <ConfirmSaveAttributes {...successUpdateMobileModal} />
      <ConfirmSaveAttributes {...confirmSaveAttributesModal} />

      <ConfirmEditMobileModal
        {...confirmEditMobileModal}
        submitting={requestUpdateMobileNumber.loading}
        submit={submitMobileNumber}
      />
      <ConfirmStatusChange
        setCustomerProfile={setCustomerProfile}
        {...customerProfile}
        {...confirmModal}
      />
    </div>
  );
};

export default CustomerListModule;
