import React from "react";

import styles from "./discount-filter.module.scss";
import { Autocomplete, SearchField, Field, Filter, Button, DateRange } from "components/commons";
import locale from "localization";
import { CampaignStatus } from "enums";

const DiscountFilter = ({
  filterState,
  applyFilter,
  applyClearFilter,
  applyResetFilter,
  modifyFilter,
  modifySearch,
  applySearch,
  applyClearSearch,
  searchKey,
  filterCount,
  exportReport,
  exporting,
}) => {
  const { Upcoming, Ongoing, Ended, Canceled } = CampaignStatus;
  return (
    <div className={styles?.container}>
      <div className={styles?.filter}>
        <Filter
          submit={applyFilter}
          clear={applyClearFilter}
          close={applyResetFilter}
          filterCount={filterCount}
        >
          <Field
            className={styles.date}
            labelPosition={"left"}
            label={locale.dateCreated}
            childrenClassName={styles.fieldContent}
          >
            <DateRange
              value={{
                startDate: filterState.startDate,
                endDate: filterState.endDate,
              }}
              onChange={(_, { value }) => {
                const { startDate, endDate } = value;
                modifyFilter({
                  startDate,
                  endDate,
                });
              }}
            />
          </Field>
          <Field
            className={styles.filterInput}
            label={locale.status}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
          >
            <Autocomplete
              name="statuses"
              value={filterState.statuses}
              options={[
                { label: locale.upcoming, value: Upcoming },
                { label: locale.ongoing, value: Ongoing },
                { label: locale.ended, value: Ended },
                { label: locale.cancelled, value: Canceled },
              ]}
              hasAll
              multiple
              onChange={(name, { value }) => modifyFilter({ [name]: value })}
            />
          </Field>
        </Filter>
        <Field className={styles.search}>
          <SearchField
            restrict={false}
            searchWithSymbols
            placeholder={`${locale.locqpayDiscountSearch}`}
            value={searchKey}
            onChange={(_, { value }) => {
              modifySearch(value);
            }}
            onEnter={applySearch}
            onClear={applyClearSearch}
          />
        </Field>
        <Field className={styles.downloadButton}>
          <Button
            startIcon={<span className="icon-download" />}
            exportButton
            onClick={exportReport}
            loading={exporting}
          >
            {locale.export}
          </Button>
        </Field>
      </div>
    </div>
  );
};

export default DiscountFilter;
