import { Modal, Title, Image, Text, Button } from "components/commons";
import locale from "localization";
import styles from "./confirm-mops-modal.module.scss";
import { CarWithLady } from "images";
import { useRouter } from "hooks";
import { Path } from "enums";
import { formatAmount } from "utils";

const SuccessMopsModal = ({ values, image = CarWithLady, deactivated, ...modalState }) => {
  const { active } = modalState;
  const { history } = useRouter();

  return (
    <Modal
      active={active}
      close={() => {
        history.push(Path.MopsPrice);
      }}
      className={styles.modal}
    >
      <div className={styles.container}>
        <Image src={image} className={styles.image} />
        <Title small className={styles.title}>
          {locale.success}
        </Title>
        <Text className={styles.content}>{locale.yourChangesSaved}</Text>
      </div>

      <div className={styles.mainContainer}>
        <div className={styles.subContainer}>
          <div className={styles.category}>
            <Text align={"left"} label>
              {locale.mogas}
            </Text>
          </div>
          <div>
            <Text strong>{formatAmount(values.mogas.value) || "N/A"}</Text>
          </div>
        </div>

        <div className={styles.subContainer}>
          <div className={styles.category}>
            <Text align={"left"} label>
              {locale.gasoil}
            </Text>
          </div>
          <div>
            <Text strong>{formatAmount(values.gasoil.value) || "N/A"}</Text>
          </div>
        </div>

        <div className={styles.subContainer}>
          <div className={styles.category}>
            <Text align={"left"} label>
              {locale.effectiveDate}
            </Text>
          </div>
          <div>
            <Text strong>{values.effectiveAt.value || "N/A"}</Text>
          </div>
        </div>

        <div className={styles.buttonContainer}>
          <Button
            onClick={() => {
              history.push(Path.MopsPrice);
            }}
            primary
          >
            {locale.goToMops}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SuccessMopsModal;
