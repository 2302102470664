import { ApiPath } from "enums";
import { Get, Post } from "utils";

export const getProductConversion = async (body) => {
  const res = await Get(`${ApiPath.ProductConversion}`, body);
  return res.data;
};

export const exportProductConversion = async (body) => {
  const res = await Post(`${ApiPath.ProductConversion}`, body);
  return res.data;
};

export const getProductConversionPrice = async (body) => {
  const res = await Get(`${ApiPath.ProductConversion}/price`, body);
  return res.data;
};

export const createProductConversion = async (body) => {
  const res = await Post(`${ApiPath.ProductConversion}`, body);
  return res.data;
};
