import { VoucherCampaignCurrency } from "enums";
import VoucherCampaignGroup from "enums/campaign-group";
import VoucherStatus from "enums/voucher-status";

export const uniqueVoucherFilterState = () => {
  return {
    page: 1,
    perPage: 10,
    endDate: null,
    startDate: null,
    searchKey: "",
    statuses: {
      isSelectedAll: true,
      value: [
        VoucherStatus.Unclaimed,
        VoucherStatus.Used,
        VoucherStatus.Expired,
        VoucherStatus.Void,
      ],
    },
    currencies: {
      isSelectedAll: true,
      value: [
        VoucherCampaignCurrency.Peso,
        VoucherCampaignCurrency.Liter,
        VoucherCampaignCurrency.Points,
      ],
    },
    campaignGroups: {
      isSelectedAll: true,
      value: [
        VoucherCampaignGroup.SPIAllocation,
        VoucherCampaignGroup.SPIAllowance,
        VoucherCampaignGroup.Others,
      ],
    },
    campaignCreators: {
      value: [],
      isAll: true,
    },
  };
};
