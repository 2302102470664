import React from "react";

import { getSettlementHistory } from "apis";
import { Intro } from "components/commons";
import { Path, SettlementStatus } from "enums";
import useDataTable from "hooks/useDataTable";
import locale from "localization";

import { settlementFilterState } from "./settlement-filter.state";
import { mapFilterToRequest, mapDataToList } from "./settlement.mapper";
import { settlementColumns } from "./settlement-columns";
import SettlementFilter from "./settlement-filter";
import SettlementList from "./settlement.list";
import styles from "./settlement.module.scss";
import { useRouter } from "hooks";
// import useExport from "hooks/useExport";

const SettlementModule = () => {
  const history = useRouter();

  const { filter, search, table } = useDataTable({
    api: {
      api: getSettlementHistory,
      params: { dealerSettlementStatuses: SettlementStatus.ForSettlement },
    },
    filter: { initialState: settlementFilterState(), mapper: mapFilterToRequest },
    table: {
      key: "settlementGenerated",
      mapper: (settlements) =>
        mapDataToList({
          settlements,
          history,
        }),
      columns: settlementColumns,
    },
  });

  // const exportReport = useExport({
  //   api: generatePricingReport,
  //   reportType: "settlements",
  //   mappedFilterState: {
  //     ...filter.mappedFilterState,
  //     dealerSettlementStatuses: SettlementStatus.ForSettlement,
  //     searchKey: search.searchKey,
  //   },
  // });

  return (
    <>
      <Intro
        title={locale.dealerSettlements}
        actionText={locale.processSettlement}
        actionOnClick={() => history.push(Path.ProcessSettlement)}
        subtitle={locale.viewTrackSettlement}
      />
      <SettlementFilter {...filter} {...search} />
      <div className={styles.table}>
        <SettlementList table={table} />
      </div>
    </>
  );
};

export default SettlementModule;
