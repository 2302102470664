import React from "react";
import { useHistory } from "react-router-dom";

import { generatePricingReport } from "apis";
import { Intro } from "components/commons";
import useDataTable from "hooks/useDataTable";
import locale from "localization";

import { settlementHistoryFilterState } from "./oilco-settlement-history-filter.state";
import { mapFilterToRequest, mapDataToList } from "./oilco-settlement-history.mapper";
import { settlementHistoryColumns } from "./oilco-settlement-history-columns";
import SettlementHistoryFilter from "./oilco-settlement-history-filter";
import SettlementHistoryList from "./oilco-settlement-history.list";
import styles from "./oilco-settlement-history.module.scss";
import useCancelSettlement from "./hooks/useCancelSettlement";
import useExport from "hooks/useExport";
import { ConfirmModal } from "components/modals";
import { Path } from "enums";
import { getSettlementOilcoHistory } from "apis/settlement.api";

const OilcoSettlementHistoryModule = () => {
  const history = useHistory();
  const cancelSettlement = useCancelSettlement();

  const handleCancelSettlement = ({
    settlementId,
    dealerSettlementStatus,
    discountVoucherStatus,
  }) => {
    cancelSettlement.request({
      settlementId,
      confirmationRequest: () => table.fetch({ newFilterState: filter.mappedFilterState }),
      settlementCollectionRedirection: (settlementId) =>
        history.push(Path.ViewSettlementHistoryById(settlementId)),
      dealerSettlementStatus,
      discountVoucherStatus,
    });
  };

  const { filter, search, table } = useDataTable({
    api: { api: getSettlementOilcoHistory },
    filter: { initialState: settlementHistoryFilterState(), mapper: mapFilterToRequest },
    table: {
      key: "settlements",
      mapper: (settlements) =>
        mapDataToList({
          settlements,
          handleCancelSettlement,
          history,
        }),
      columns: settlementHistoryColumns,
    },
  });

  const exportReport = useExport({
    api: generatePricingReport,
    reportType: "settlements",
    mappedFilterState: {
      ...filter.mappedFilterState,
      searchKey: search.searchKey,
      settlementType: "oilco",
    },
  });

  return (
    <>
      <cancelSettlement.ConfirmCancel />
      <ConfirmModal {...cancelSettlement.cancelSettlementErrorModal} />
      <Intro title={locale.oilcoSettlementHistory} subtitle={locale.viewTrackSettlement} />
      <SettlementHistoryFilter {...filter} {...search} {...exportReport} />
      <div className={styles.table}>
        <SettlementHistoryList table={table} />
      </div>
    </>
  );
};

export default OilcoSettlementHistoryModule;
