import { Path } from "enums";
import DealerMonthlyFeeDetailsModule from "modules/cash-flow/dealer-monthly-fee/dealer-monthly-fee-details/dealer-monthly-fee-details.module";
import DealerMonthlyFeeModule from "modules/cash-flow/dealer-monthly-fee/dealer-monthly-fee.module";
import React from "react";
import { Redirect, Route } from "react-router-dom/cjs/react-router-dom.min";

const DealerMonthlyFeePage = () => {
  return (
    <div>
      <Route exact path={Path.LocqDealerMonthlyFee} component={DealerMonthlyFeeModule} />
      <Route
        exact
        path={Path.LocqDealerMonthlyFeeDetails}
        component={DealerMonthlyFeeDetailsModule}
      />
      <Redirect to={Path.LocqDealerMonthlyFee} />
    </div>
  );
};

export default DealerMonthlyFeePage;
