import locale from "localization";

export const accountListColumns = [
  {
    key: "stationNameCode",
    text: locale.stationNameStationCode,
    width: "15%",
  },
  { key: "userEmails", text: locale.accountUserEmails, width: "10%" },
  { key: "rsmRtm", text: locale.rsmRtmEmails, width: "10%" },
  { key: "testFuelCode", text: locale.testFuelCode, width: "12%" },
  {
    key: "testFuelCodeStatus",
    text: `${locale.testFuelCodeStatus}/${locale.dateRedeemed}`,
    width: "15%",
  },
  { key: "onboardedAt", text: locale.dateOnboarded, width: "12%" },
  {
    key: "stationAccountStatus",
    text: `${locale.accountStatus}/${locale.dateOfLastUpdate}`,
    width: "12%",
  },
  { key: "actions", text: locale.actions, width: "3%" },
];
