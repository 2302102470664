import React from "react";
import Dropzone from "react-dropzone";
import { Image } from "components/commons";
import { ImportIcon } from "images";
import locale from "localization";
import styles from "./import-file.module.scss";
import { useCallback } from "react";
import { Text } from "..";
import DescriptionIcon from "@material-ui/icons/Description";

const ImportFile = ({ onChange, value }) => {
  const onSelectFile = useCallback(
    (files) => {
      if (files.length > 0) {
        onChange(files);
      }
    },
    [onChange]
  );

  return (
    <div
      style={{
        background: "#f7f8fa",
        padding: "35px 0px",
        border: "dashed 2px #a1aeb7",
        borderRadius: "4px",
      }}
    >
      <Dropzone onDrop={onSelectFile} multiple={false}>
        {({ getRootProps, getInputProps }) => (
          <div className={styles.dropZone} {...getRootProps()}>
            <input {...getInputProps()} />
            <Image src={ImportIcon} />
            <div
              style={{
                color: "black",
              }}
            >
              {locale.dragAndDropFilesToUploadOr}
            </div>
            {value && value[0]?.name && (
              <div
                style={{
                  color: "black",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px 10px",
                  marginTop: "20px",
                }}
              >
                <DescriptionIcon /> {value && value[0].name}
              </div>
            )}
            <div>
              <Text
                strong
                style={{
                  textDecoration: "underline",
                  color: "#753bbd",
                  cursor: "pointer",
                }}
              >
                {value && value[0]?.name ? locale.replaceFile : locale.addFile}
              </Text>
            </div>
          </div>
        )}
      </Dropzone>
    </div>
  );
};

export default ImportFile;
