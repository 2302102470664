import { DateTime } from "enums";
import React, { useCallback, useRef } from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import { formatDate, parseDate } from "react-day-picker/moment";
import styles from "./date-time.module.scss";
import { Calendar } from "images";
import Image from "../image/image";
import classNames from "classnames";
import moment from "moment";
import { useMount } from "hooks";

const DateTimePicker = ({ value, name, onChange, className }) => {
  const dateRef = useRef();

  const changeCb = useCallback(
    (value) => {
      return onChange(name, { value: value });
    },
    [name, onChange]
  );

  useMount(() => {
    dateRef.current.input.readOnly = true;
  });

  return (
    <div className={classNames(styles.inputFromTo, className)}>
      <div className={styles.startDate}>
        <DayPickerInput
          placeholder="Effective Date"
          format={DateTime.H}
          ref={dateRef}
          value={value}
          formatDate={formatDate}
          parseDate={parseDate}
          dayPickerProps={{
            disabledDays: { before: new Date(moment().format(DateTime.I)) },
            numberOfMonths: 2,
          }}
          onDayChange={changeCb}
        />
      </div>

      <Image className={styles.icon} src={Calendar} />
    </div>
  );
};

export default DateTimePicker;
