import { Intro, DataTable, Text, PopOverMenu } from "components/commons";
import { DateTime, Path, Role, Portal } from "enums";
import { useApi, useFilter, useMount, useModal } from "hooks";
import React, { useCallback, useMemo, useContext } from "react";
import { UserAttributesColumns } from "./user-attributes-list-columns";
import styles from "./user-attributes-list.module.scss";
import locale from "localization";
import { UserAttributesFilterState } from "./user-attributes-list-filter.state";
import { handleRequest } from "utils";
import { getUserAttributes, deleteUserAttribute } from "apis";
import { useHistory } from "react-router-dom";
import { ConfirmModal } from "components/modals";
import moment from "moment";
import UserAttributesFilter from "./user-attributes-filter";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { UserContext } from "contexts";

const UserAttributesListModule = () => {
  const history = useHistory();
  const confirmModal = useModal();
  const { close } = confirmModal;
  const {
    filterState,
    requestState,
    submitFilter,
    modifyFilters,
    filterCount,
    modifyFilter,
    clearFilter,
  } = useFilter(UserAttributesFilterState());

  const { user } = useContext(UserContext);
  const { role } = user?.userAccesses?.find((element) => {
    return element.portal === Portal.LOCQ;
  });

  const {
    request: getUserAttributesRequest,
    loading: fetchingUserAttributes,
    result: getUserAttributesResult = { attributes: [], count: 0 },
  } = useApi({
    api: getUserAttributes,
    pageError: false,
  });

  useMount(() => {
    fetchUserAttributes();
  });

  const fetchUserAttributes = useCallback(
    (rs = { ...requestState }) => {
      getUserAttributesRequest(submitFilter(rs));
    },
    [getUserAttributesRequest, requestState, submitFilter]
  );

  const onChangePageCb = useCallback(
    (page) => {
      const { requestState } = modifyFilters({ page });
      fetchUserAttributes(requestState);
    },
    [modifyFilters, fetchUserAttributes]
  );

  const onChangePageSizeCb = useCallback(
    (perPage) => {
      const { requestState } = modifyFilters({ perPage, page: 1 });
      fetchUserAttributes(requestState);
    },
    [fetchUserAttributes, modifyFilters]
  );

  const deleteUserAttributeRequest = useApi({
    api: deleteUserAttribute,
    handleOwnError: {
      badrequest: true,
    },
  });

  const submitDeleteUserAttribute = useCallback(
    (params) => {
      handleRequest(async () => {
        const result = await deleteUserAttributeRequest?.request({
          ...params,
        });
        if (result) {
          fetchUserAttributes();
        }
      });
    },
    [deleteUserAttributeRequest, fetchUserAttributes]
  );

  const handleDeleteUserAttribute = useCallback(
    (params, name) => {
      confirmModal.show({
        title: locale.deleteAttributeQuestion,
        content: (
          <locale.Populate text={locale.youAreAboutDeleteAttribute} items={[<b>{name}</b>]} />
        ),
        secondary: {
          text: locale.Cancel,
        },
        primary: {
          text: locale.yesDeleteIt,
          onClick: async () => {
            submitDeleteUserAttribute(params);
            close();
          },
        },
      });
    },
    [close, confirmModal, submitDeleteUserAttribute]
  );

  const preparedUserAttributesData = useMemo(() => {
    const { attributes } = getUserAttributesResult;

    if (attributes && attributes.length > 0) {
      const preparedData = attributes.map((a) => {
        const map = new Map();

        map.set("name", <Text className={styles.textStyle}>{a.name}</Text>);
        map.set(
          "createdAt",
          <Text className={styles.textStyle}>{moment(a.createdAt).format(DateTime.G)}</Text>
        );
        map.set(
          "actions",
          <PopOverMenu
            options={[
              {
                content: locale.viewDetails,
                onClick: () => {
                  history.push(Path.ViewUserAttribute, {
                    attributeId: a.attributeId,
                  });
                },
              },
              {
                removable: true,
                content: locale.editAttribute,
                disabled: role === Role.Accounting,
                onClick: () => {
                  history.push(Path.EditUserAttribute, {
                    attributeId: a.attributeId,
                  });
                },
              },
              {
                removable: true,
                content: locale.delete,
                disabled: a.isDefault || role === Role.Accounting,
                onClick: () => {
                  handleDeleteUserAttribute({ attributeId: a.attributeId }, a.name);
                },
              },
            ]}
          >
            <MoreVertIcon className={styles.icon} />
          </PopOverMenu>
        );

        return map;
      });
      return preparedData;
    }
    return [];
  }, [getUserAttributesResult, handleDeleteUserAttribute, history, role]);

  const onSearchCb = useCallback(
    (searchKey) => {
      const { requestState } = modifyFilters({ searchKey, page: 1 });
      getUserAttributesRequest(requestState);
    },
    [getUserAttributesRequest, modifyFilters]
  );

  const onDateRangeCb = useCallback(
    (value) => {
      const { startDate, endDate } = value;
      modifyFilters({ startDate, endDate, page: 1 });
    },
    [modifyFilters]
  );

  return (
    <div>
      <ConfirmModal
        {...confirmModal}
        titleStyle={styles.modalTitle}
        contentStyle={styles.modalContent}
      />
      <div>
        <Intro
          titleStyle={styles.titleStyle}
          textStyle={styles.textStyle}
          actionText={locale.addAttribute}
          actionOnClick={() => {
            history.push(Path.AddUserAttribute);
          }}
          title={locale.userAttributes}
          subtitle={locale.viewDetailsCustomerAttributes}
        />
      </div>
      <div className={styles.filters}>
        <UserAttributesFilter
          filterState={filterState}
          onDateRangeChange={onDateRangeCb}
          onSearchChange={modifyFilter}
          onSearch={onSearchCb}
          resetFilter={() => {
            modifyFilters({
              endDate: null,
              startDate: null,
            });
          }}
          clearFilter={() => {
            const { requestState } = clearFilter();
            fetchUserAttributes(requestState);
          }}
          submitFilter={() => {
            const { requestState } = modifyFilters({ page: 1 });
            fetchUserAttributes(requestState);
          }}
          filterCount={filterCount}
        />
      </div>
      <div className={styles.table}>
        <DataTable
          loading={fetchingUserAttributes || deleteUserAttributeRequest?.loading}
          page={filterState.page}
          pageSize={filterState.perPage}
          columns={UserAttributesColumns}
          data={preparedUserAttributesData}
          dataCount={getUserAttributesResult.count}
          onChangePage={onChangePageCb}
          onChangePageSize={onChangePageSizeCb}
        />
      </div>
    </div>
  );
};

export default UserAttributesListModule;
