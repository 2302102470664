import { VoucherCampaignCurrency } from "enums";
import VoucherCampaignGroup from "enums/campaign-group";
import VoucherStatus from "enums/voucher-status";

export const voucherCampaignFilterState = ({ location = {} }) => {
  const { state = {} } = location;
  const { search = "" } = state || {};
  return {
    startDate: null,
    endDate: null,
    searchKey: search || "",
    voucherType: "all",
    page: 1,
    perPage: 10,
    status: {
      isSelectedAll: true,
      value: [
        VoucherStatus.Used,
        VoucherStatus.AvailableInApp,
        VoucherStatus.Unclaimed,
        VoucherStatus.Expired,
      ],
    },
    type: {
      isSelectedAll: true,
      value: [
        VoucherCampaignCurrency.Peso,
        VoucherCampaignCurrency.Liter,
        VoucherCampaignCurrency.Points,
      ],
    },
    campaignCreators: {
      value: [],
      isAll: true,
    },
    campaignGroup: {
      isSelectedAll: true,
      value: [
        VoucherCampaignGroup.Others,
        VoucherCampaignGroup.SPIAllowance,
        VoucherCampaignGroup.SPIAllocation,
      ],
    },
  };
};
