import locale from "localization";
import { accumulatedModalState } from "./accumulated-modal.state";
import { tableFormState } from "./table-form.state";

export const initialState = (state = {}) => {
  const {
    redemptionStation = {
      value: [],
      isAll: false,
    },
    referenceStation = {
      value: [],
    },
    referenceStationType = {
      value: [],
    },
    accountType = {
      value: [],
    },
    depot = {
      value: [],
    },
    inventoryLimit = {
      value: 0
    }
  } = state;

  return {
    redemptionStation: {
      name: "redemptionStation",
      validations: [
        ({ value, isAll }) => {
          if (isAll) {
            return {};
          }
          if (!isAll && !value.length) {
            return {
              error: true,
              message: "",
            };
          }
          return {};
        },
      ],
      placeholder: locale.searchOrSelect,
      inputLabel: locale.redemptionStations,
      horizontal: true,
      disableAllOption: true,
      isAll: false,
      ...redemptionStation,
    },
    referenceStation: {
      name: "referenceStation",
      ...referenceStation,
    },
    referenceStationType: {
      name: "referenceStationType",
      ...referenceStationType,
    },
    accountType: {
      name: "accountType",
      ...accountType,
    },
    depot: {
      name: "depot",
      ...depot,
    },
    inventoryLimit: {
      name: "inventoryLimit",
      ...inventoryLimit
    },
    ...tableFormState(state),
    ...accumulatedModalState(state),
  };
};
