import { DateRange, Field, SearchField, Filter, Button } from "components/commons";
import React from "react";
import styles from "./buy-fuel-filter.module.scss";
import locale from "localization";
import { SelectStation } from "components/field";
import SelectProductCheckbox from "components/field/select-product-checkbox/select-product-checkbox";

const BuyFuelFilter = ({
  filterState,
  modifyFilter,
  filterCount,
  applyFilter,
  applyClearFilter,
  searchKey,
  modifySearch,
  applySearch,
  applyClearSearch,
  exportReport,
  exporting,
  resetFilter,
}) => {
  const { productCodes, referenceStation } = filterState;
  return (
    <div className={styles.container}>
      <div className={styles.filter}>
        <Filter
          close={resetFilter}
          clear={applyClearFilter}
          submit={applyFilter}
          filterCount={filterCount}
          disabled={
            (!referenceStation.value.length && !referenceStation.isAll) || !productCodes.length
          }
        >
          <Field
            className={styles.date}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
            label={locale.purchasedDate}
          >
            <DateRange
              value={{
                startDate: filterState.startDate,
                endDate: filterState.endDate,
              }}
              onChange={(_, { value }) => {
                const { startDate, endDate } = value;
                modifyFilter({ startDate, endDate });
              }}
            />
          </Field>

          <Field
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
            className={styles.fieldSelect}
            label={locale.referenceStation}
          >
            <SelectStation
              {...referenceStation}
              isMultiple
              disableAllOption
              allLabel={"Station"}
              allSelectedText={"All Station"}
              selectAllLabel={"All Station"}
              onChange={(_, { value, isAll }) => {
                modifyFilter({ referenceStation: { value, isAll } });
              }}
            />
          </Field>
          <SelectProductCheckbox
            name="productCodes"
            value={productCodes}
            onChange={(name, { value }) => {
              modifyFilter({
                [name]: value,
              });
            }}
          />
        </Filter>
        <Field className={styles.search}>
          <SearchField
            restrict={false}
            searchWithSymbols
            placeholder={[
              locale.buyFuelId,
              locale.name,
              locale.mobileNumber,
              locale.discountCampaigns,
            ].join(", ")}
            value={searchKey}
            onChange={(_, { value }) => {
              modifySearch(value);
            }}
            onEnter={applySearch}
            onClear={applyClearSearch}
          />
        </Field>
      </div>

      <Button
        startIcon={<span className="icon-download" />}
        exportButton
        onClick={exportReport}
        loading={exporting}
      >
        {locale.export}
      </Button>
    </div>
  );
};

export default BuyFuelFilter;
