import * as queryString from "query-string";
import React, { useState, useCallback } from "react";
import { useForm, useApi } from "hooks";
import { initialState } from "./login.state";
import styles from "./login.module.scss";
import { ErrorCode, Path, UserGroup } from "enums";
import {
  Checkbox,
  Field,
  TextField,
  PasswordField,
  Button,
  Highlight,
  Text,
  Image,
  Title,
  Footer,
} from "components/commons";
import classNames from "classnames";
import { Logo, GuyCoffee } from "images";
import locale from "localization";
import { handleRequest, redirectTo } from "utils";
import { isEmailValid } from "utils/text.utils";
import { login, getUser } from "apis";

const LoginModule = (props) => {
  const parse = queryString.parse(
    props.location ? props.location.search : null
  );
  const [errorCode] = useState(parse.code || null);
  const [incorrectCreds, setIncorrectCreds] = useState();

  const { request: loginRequest, loading: loggingIn } = useApi({
    api: login,
    handleOwnError: {
      badrequest: true,
    },
  });

  const {
    fields,
    modifyField,
    submitForm,
    makeFormInvalid,
    getFormValues,
    applyFieldErrors,
  } = useForm({
    initialState,
  });

  const OnBlurValidateEmail = () => {
    if (fields.email.value && !isEmailValid(fields.email.value)) {
      applyFieldErrors({
        email: locale.pleaseEnterAValidEmailAddress,
      });
    }
  };

  const { request: getUserRequest, loading: fetchingUser } = useApi({
    api: getUser,
    pageError: true,
  });

  const fetchUser = useCallback(async () => {
    return await getUserRequest();
  }, [getUserRequest]);

  const handleLogin = async () => {
    setIncorrectCreds(false);
    const { email, password, remember } = getFormValues();
    if (!email || !password) {
      applyFieldErrors({
        email: !email ? "" : null,
        password: !password ? "" : null,
      });
      return;
    }

    handleRequest(
      async () => {
        if (!isEmailValid(email)) {
          applyFieldErrors({
            email: locale.pleaseEnterAValidEmailAddress,
          });
          return;
        }
        const res = await loginRequest({ email, password }, () =>
          handleLogin()
        );
        localStorage.setItem("accessToken", res?.AccessToken);
        localStorage.setItem("refreshToken", res.RefreshToken);

        if (res) {
          const userInfo = await fetchUser();
          // user must be admin
          if (
            userInfo &&
            userInfo.groups.find(
              (userGroup) => userGroup === UserGroup.LocqAdmin
            )
          ) {
            if (remember) {
              localStorage.setItem("email", email);
            } else {
              localStorage.removeItem("email");
            }
            redirectTo(Path.CashIn);
          } else {
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            makeFormInvalid();
            setIncorrectCreds(true);
            return applyFieldErrors({
              email: "",
              password: "",
            });
          }
        } else {
          makeFormInvalid();
          setIncorrectCreds(true);
          return applyFieldErrors({
            email: "",
            password: "",
          });
        }
      },
      null,
      (err) => {
        if (err.data?.errorCode === ErrorCode.IncorrectCredentials) {
          makeFormInvalid();
          setIncorrectCreds(true);
          return applyFieldErrors({
            email: "",
            password: "",
          });
        }
        return err.showError();
      }
    );
  };

  return (
    <div className={styles.container}>
      <div className={classNames(styles.header)}>
        <Image src={Logo} className={styles.logoImage} />
      </div>

      <div className={styles.content}>
        <div className={styles.contentContainer}>
          <div className={styles.image}>
            <Image src={GuyCoffee} className={styles.contentImage} />
          </div>

          <div className={styles.login}>
            <Title className={styles.title}>{locale.locqPortal}</Title>
            {!incorrectCreds &&
              errorCode &&
              (errorCode === ErrorCode.InvalidInviteUserToken ||
              errorCode === ErrorCode.ExpiredInviteUserToken) && (
                <Highlight warning>
                  <Text>{locale.expiredActivationLinkToken}</Text>
                </Highlight>
              )}
            {incorrectCreds && (
              <Highlight error>
                <Text>{locale.theEnteredEmailPassIncorrect}</Text>
              </Highlight>
            )}
            <Field {...fields.email}>
              <TextField
                {...fields.email}
                onChange={modifyField}
                onBlur={OnBlurValidateEmail}
              />
            </Field>
            <Field {...fields.password}>
              <PasswordField
                {...fields.password}
                onChange={modifyField}
                onEnter={() => {
                  submitForm(handleLogin);
                }}
              />
            </Field>
            <div className={styles.remember}>
              <Checkbox {...fields.remember} onChange={modifyField} />
              {fields.remember.label}
            </div>
            <Button
              primary
              className={styles.loginButton}
              loading={loggingIn || fetchingUser}
              onClick={() => {
                submitForm(handleLogin);
              }}
            >
              {locale.logIn}
            </Button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LoginModule;
