import { Intro } from "components/commons";
import React from "react";
import locale from "localization";
import { getProductConversion } from "apis/product-conversion.api";
import { initialFilterState } from "./product-conversion-filter.state";
import { useHistory } from "react-router-dom";
import { Path } from "enums";
import { columns } from "./product-conversion-columns";
import { mapDataToList, mapFilterToRequest } from "./product-conversion.mapper";
import useDataTable from "hooks/useDataTable";
import ProductConversionFilter from "./product-conversion-filter";
import ProductConversionList from "./product-conversion.list";

const ProductConversion = () => {
  const history = useHistory();

  const { filter, search, table } = useDataTable({
    api: { api: getProductConversion },
    filter: { initialState: initialFilterState(), mapper: mapFilterToRequest },
    table: { key: "productConversions", mapper: mapDataToList, hasColumnPicker: true, columns },
  });

  return (
    <div>
      <div>
        <Intro
          title={locale.productConversions}
          subtitle={locale.viewAndTrackProductConversions}
          actionText={locale.addNewProductConversion}
          actionOnClick={() => {
            history.push(Path.CreateProductConversion);
          }}
        />
      </div>
      <ProductConversionFilter {...filter} {...search} />
      <ProductConversionList table={table} />
    </div>
  );
};

export default ProductConversion;
