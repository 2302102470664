import { Intro } from "components/commons";
import React, { useContext, useState } from "react";
import locale from "localization";
import { initialFilterState } from "./buy-fuel-filter.state";
import BuyFuelFilter from "./buy-fuel-filter";
import BuyFuelList from "./buy-fuel-list";
import { getBuyFuel } from "apis/buy-fuel.api";
import useDataTable from "hooks/useDataTable";
import { mapDataToList, mapFilterToRequest } from "./buy-fuel.mapper";
import { columns } from "./buy-fuel-columns";
import { useApi, useModal, useRouter } from "hooks";
import useExport from "hooks/useExport";
import { generateReportAdmin, voidTransactionApi, editFuel } from "apis";
import { ConfirmModal, SuccessModal } from "components/modals";
import { SessionContext } from "contexts";
import styles from "./buy-fuel.module.scss";

import EditRemarksModal from "./components/edit-remarks-modal";
import VoidTransactionModal from "./components/void-transaction-modal";

const BuyFuelModule = () => {
  const { location } = useRouter();

  const { sessionId } = useContext(SessionContext);
  const voidTransaction = useModal();
  const editTransaction = useModal();
  const successMessage = useModal();
  const [selectedOption, setSelectedOption] = useState("");
  const [remarks, setRemarks] = useState("");

  const voidTrans = useApi({
    api: voidTransactionApi,
  });

  const editRemarks = useApi({
    api: editFuel,
  });

  const voidTransactionModal = ({ buyFuelId, amount, liters }) => {
    voidTransaction.show({
      title: `${locale.voidTransaction}?`,
      description: (
        <>
          <locale.Populate
            text={locale.voidTransactionText}
            items={[<strong>{liters}</strong>, <strong>{amount}</strong>]}
          />
        </>
      ),
      actionText: locale.voidTransaction,
      loading: voidTrans.loading,
      close: () => {
        voidTransaction?.close();
        setRemarks("");
        setSelectedOption("");
      },
      submit: async (remarks) => {
        const resp = await voidTrans.request({ buyFuelId, remarks });
        if (resp) {
          voidTransaction.close();
          setRemarks("");
          setSelectedOption("");
          successMessage.show({
            title: locale.transactionVoided,
            content: (
              <locale.Populate
                text={locale.voidTransactionSuccess}
                items={[<strong>{amount}</strong>]}
              />
            ),
            primary: {
              onClick: () => {
                table?.refetch();
                successMessage.close();
              },
            },
          });
        }
      },
    });
  };

  const editRemarksModal = ({ buyFuelId, remarks }) => {
    setRemarks(remarks);
    editTransaction.show({
      title: locale.editRemarks,
      actionText: locale.save,
      loading: editRemarks.loading,
      submit: async (remarks) => {
        const resp = await editRemarks.request({ id: buyFuelId, remarks });
        if (resp) {
          editTransaction.close();
          table?.refetch();
        }
      },
    });
  };

  const { filter, search, table } = useDataTable({
    api: { api: getBuyFuel, params: { platformType: "plc" } },
    filter: {
      initialState: initialFilterState({ searchKey: location?.state?.searchKey }),
      mapper: mapFilterToRequest,
    },
    table: {
      key: "buyFuels",
      mapper: (buyFuel) =>
        mapDataToList({
          buyFuel,
          voidTransactionModal,
          editRemarksModal,
        }),
      columns,
    },
    searchKey: location?.state?.searchKey,
  });

  const exports = useExport({
    api: generateReportAdmin,
    hasModal: true,
    reportType: "buy-fuel",
    modifyFilter: filter.modifyFilter,
    mappedFilterState: {
      ...filter.mappedFilterState,
      platformType: "plc",
      searchKey: search?.searchKey,
      sessionId,
    },
  });

  return (
    <div>
      <SuccessModal {...exports?.successModalComponent} />
      <ConfirmModal {...successMessage} />
      <VoidTransactionModal
        {...voidTransaction}
        remarks={remarks}
        onChangeRemarks={(remarks) => setRemarks(remarks)}
        value={selectedOption}
        setValue={setSelectedOption}
        loading={voidTrans.loading}
        onSubmit={voidTransaction.submit}
        close={voidTransaction?.close}
        //pass api here
      />
      <EditRemarksModal
        {...editTransaction}
        remarks={remarks}
        loading={editTransaction.loading}
        onSubmit={editTransaction.submit}
        onChangeRemarks={(remarks) => setRemarks(remarks)}
      />
      <div>
        <Intro title={locale.buyFuelCapitalize} subtitle={locale.viewTrackPurchases} />
      </div>
      <BuyFuelFilter {...filter} {...search} {...exports} />
      <div className={styles.table}>
        <BuyFuelList table={table} />
      </div>
    </div>
  );
};

export default BuyFuelModule;
