import {
  Button,
  Field,
  Text,
  TextField,
  Title,
  SearchField,
  Image,
  Checkbox,
} from "components/commons";
import locale from "localization";
import React from "react";
import styles from "./user-attribute-details.module.scss";
import { TrashcanIcon } from "images";

const UserAttributeDetailsFormModule = ({ ...stateForm }) => {
  const {
    viewMode,
    addMode,
    fields,
    isFormSubmittable,
    loading,
    submitForm,
    handleSubmit,
    modifyField,
    duplicateCohort,
    cohortsList,
    handleAddCohorts,
    removeSelected,
    cohortsSearchKey,
    onSearchChangeCb,
    checkAllCohort,
    isAllCohortSelected,
  } = stateForm;

  return (
    <div className={styles.container}>
      <Field className={styles.fieldLabel} {...fields.name}>
        {viewMode ? (
          <Text className={styles.fieldText}>{fields.name.value}</Text>
        ) : (
          <TextField disabled={loading} {...fields.name} onChange={modifyField} />
        )}
      </Field>
      <Title className={styles.title} xsmall>
        {locale.cohorts}
      </Title>
      <div className={styles.onSearchAddContainer}>
        <SearchField
          value={cohortsSearchKey}
          onChange={onSearchChangeCb}
          onEnter={() => {}}
          placeholder={locale.name}
          restrict={false}
          maxLength={30}
          disabled={loading}
          className={{ [styles.viewModeSearch]: viewMode }}
        />
        {!viewMode && (
          <>
            <Button className={styles.searchButton} onClick={handleAddCohorts} exportButton>
              {locale.add}
            </Button>
            <Button className={styles.deleteButton} link onClick={removeSelected}>
              <Image src={TrashcanIcon} />
            </Button>
          </>
        )}
      </div>
      {duplicateCohort && (
        <div className={styles.duplicateCohortMsg}>
          <Text className={styles.errorMsg}>{locale.cohortAlreadyExists}</Text>
        </div>
      )}

      <div className={styles.allCohort}>
        <div className={styles.checkAllName}>
          {!viewMode && (
            <Checkbox
              name="all"
              checked={isAllCohortSelected}
              value={isAllCohortSelected}
              onChange={checkAllCohort}
              disabled={loading}
            />
          )}
          <Text className={styles.textCheckbox}>{locale.name}</Text>
        </div>
      </div>
      <div className={styles.cohortsListContainer}>
        <div className={styles.cohortsListItem}>{cohortsList}</div>
      </div>

      {addMode && (
        <Button
          primary
          className={styles.create}
          loading={loading}
          disabled={!isFormSubmittable}
          onClick={() => {
            submitForm(handleSubmit);
          }}
        >
          {locale.create}
        </Button>
      )}
    </div>
  );
};
export default UserAttributeDetailsFormModule;
