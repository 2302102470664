const IntroductoryPromoOffer = {
  Basic: "basic",
  GlobalLinker: "global-linker",
  Mias: "mias",
  NoIntroductoryPromo: "no-introductory-promo",
  IntroductoryPlbHedging: "introductory-plb-hedging",
  IntroductoryPlbLocqpay: "introductory-plb-locqpay",
};

export default IntroductoryPromoOffer;
