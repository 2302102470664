import React, { Fragment, useState, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router";
import { Path } from "enums";
import CustomerPage from "./customer.page";
import ConsumerFuelPage from "./consumer-fuel-page";
import ConsumerPricingPage from "./consumer-pricing.page";
import ConsumerHeader from "components/commons/consumer-header/header";
import ConsumerSidebar from "components/commons/consumer-sidebar/sidebar";
import DashboardPage from "./dashboard.page";
import CashFlowPage from "./cashflow.page";
import CampaingsPage from "./campaigns.page";
import RFIDPage from "./rfid.page";
import AccessAuthorizationPage from "./access-authorization.page";
import DownloadModule from "modules/download/downloads.module";
// import DownloadDrawer from "components/commons/download-drawer/download-drawer";
import useWebSocket from "hooks/useWebSockets";
import env from "environments/env";
import { useMount } from "hooks";
import PayWithPesoPage from "./pay-with-peso.page";
import NonFuelPage from "./non-fuel.page";
import { SessionContext } from "contexts";
import LubeServOnSiteBookingsPage from "./lubeserv-on-site-bookings.page";
import LubeServOnWheelsBookingsPage from "./lubeserv-on-wheels-bookings.page";

const ConsumerPage = () => {
  // const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { message, sessionId } = useWebSocket({ URL: env?.WEB_SOCKET_URL });
  const [newNotification, setNewNotification] = useState(false);

  useEffect(() => {
    if (message.length !== 0) {
      setNewNotification(true);
    }
  }, [message]);

  useMount(() => {
    localStorage.setItem("platformType", "plc");
  });

  return (
    <SessionContext.Provider value={{ sessionId }}>
      <Fragment>
        <ConsumerHeader
          setNewNotification={() => setNewNotification(false)}
          newNotification={newNotification}
        />
        <ConsumerSidebar />
        {/* <DownloadDrawer
        isOpen={isDrawerOpen}
        drawerData={message}
        setIsOpen={setIsDrawerOpen}
        pageType={"plc"}
      /> */}
        <div
          style={{
            marginLeft: "196px",
            marginTop: "60px",
            padding: "27px 36px",
            minWidth: "1170px",
            overflowX: "auto",
            minHeight: "100vh",
            backgroundColor: "#fff",
          }}
        >
          <Switch>
            <Route exact path={Path.ConsumerDashboard} component={DashboardPage} />
            <Route path={Path.ConsumerCashFlow} component={CashFlowPage} />
            <Route path={Path.ConsumerFuelPage} component={ConsumerFuelPage} />
            <Route path={Path.Customers} component={CustomerPage} />
            <Route path={Path.Campaigns} component={CampaingsPage} />
            <Route path={Path.ConsumerPricingPage} component={ConsumerPricingPage} />
            <Route path={Path.ConsumerRFID} component={RFIDPage} />
            <Route
              path={Path.ConsumerLubeServOnWheelsBookings}
              component={LubeServOnWheelsBookingsPage}
            />
            <Route
              path={Path.ConsumerLubeServOnSiteBookings}
              component={LubeServOnSiteBookingsPage}
            />
            <Route path={Path.ConsumerAccessAuthorization} component={AccessAuthorizationPage} />
            <Route
              path={Path.ConsumerDownload}
              render={() => <DownloadModule pageType={"plc"} />}
            />
            <Route path={Path.ConsumerLOCQPayNonFuel} component={NonFuelPage} />
            <Route path={Path.LocqPayForFuel} component={PayWithPesoPage} />
            <Redirect to={Path.ConsumerDashboard} />
          </Switch>
        </div>
      </Fragment>
    </SessionContext.Provider>
  );
};

export default ConsumerPage;
