import { Autocomplete, DateRange, Field, Filter, SearchField, Button } from "components/commons";
import { CashInChannel, CashInStatus, Role, Portal } from "enums";
import React, { useContext } from "react";
import { prettifyCashInChannel, prettifyCashInStatus } from "utils/pretty.utils";
import styles from "./cashin-filter.module.scss";
import locale from "localization";
import classNames from "classnames";
import { UserContext } from "contexts";

const CashInFilter = ({
  filterState,
  applyFilter,
  applyClearFilter,
  applyResetFilter,
  modifyFilter,
  modifySearch,
  applySearch,
  applyClearSearch,
  searchKey,
  filterCount,
  exportReport,
  exporting,
  openImport,
}) => {
  const { user } = useContext(UserContext);
  const { role } = user?.userAccesses?.find((element) => {
    return element.portal === Portal.LOCQ;
  });

  return (
    <div className={styles.container}>
      <div className={styles.filter}>
        <Filter
          submit={applyFilter}
          clear={applyClearFilter}
          close={applyResetFilter}
          filterCount={filterCount}
        >
          <Field
            className={styles.date}
            labelPosition={"left"}
            label={locale.cashInDate}
            childrenClassName={styles.fieldContent}
          >
            <DateRange
              value={{
                startDate: filterState.startDate,
                endDate: filterState.endDate,
              }}
              onChange={(_, { value }) => {
                const { startDate, endDate } = value;
                modifyFilter({
                  startDate,
                  endDate,
                });
              }}
            />
          </Field>
          <Field
            label={locale.status}
            labelPosition={"left"}
            className={styles.fieldSelect}
            childrenClassName={styles.fieldContent}
          >
            <Autocomplete
              name="cashinStatus"
              value={filterState.cashinStatus}
              options={[
                { label: locale.all, value: "all" },
                {
                  label: prettifyCashInStatus(CashInStatus.Success),
                  value: CashInStatus.Success,
                },
                {
                  label: prettifyCashInStatus(CashInStatus.Pending),
                  value: CashInStatus.Pending,
                },
                {
                  label: prettifyCashInStatus(CashInStatus.Failed),
                  value: CashInStatus.Failed,
                },
                {
                  label: prettifyCashInStatus(CashInStatus.Expired),
                  value: CashInStatus.Expired,
                },
              ]}
              onChange={(name, { value }) => {
                modifyFilter({ [name]: value });
              }}
            />
          </Field>
          <Field
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
            className={styles.fieldSelect}
            label={locale.cashInChannel}
          >
            <Autocomplete
              name="cashinChannel"
              value={filterState.cashinChannel}
              options={[
                { label: locale.all, value: "all" },
                {
                  label: prettifyCashInChannel(CashInChannel.Card),
                  value: CashInChannel.Card,
                },
                {
                  label: prettifyCashInChannel(CashInChannel.LocqRebate),
                  value: CashInChannel.LocqRebate,
                },
                {
                  label: prettifyCashInChannel(CashInChannel.OnlineBankingBrankas),
                  value: CashInChannel.OnlineBankingBrankas,
                },
                // {
                //   label: prettifyCashInChannel(CashInChannel.OnlineBankBPI),
                //   value: CashInChannel.OnlineBankBPI,
                // },
                {
                  label: prettifyCashInChannel(CashInChannel.OnlineBankBPI),
                  value: CashInChannel.OnlineBankBPI,
                },
                {
                  label: prettifyCashInChannel(CashInChannel.OnlineBankDragonPay),
                  value: CashInChannel.OnlineBankDragonPay,
                },
                {
                  label: prettifyCashInChannel(CashInChannel.ManualPaymaya),
                  value: CashInChannel.ManualPaymaya,
                },
                {
                  label: prettifyCashInChannel(CashInChannel.ManualBankDeposit),
                  value: CashInChannel.ManualBankDeposit,
                },
                {
                  label: prettifyCashInChannel(CashInChannel.WithholdingTaxRefund),
                  value: CashInChannel.WithholdingTaxRefund,
                },
                {
                  label: prettifyCashInChannel(CashInChannel.IntroductoryPromo),
                  value: CashInChannel.IntroductoryPromo,
                },
                {
                  label: prettifyCashInChannel(CashInChannel.OTC),
                  value: CashInChannel.OTC,
                },
                {
                  label: prettifyCashInChannel(CashInChannel.LocqPayDiscount),
                  value: CashInChannel.LocqPayDiscount,
                },
                {
                  label: prettifyCashInChannel(CashInChannel.VipLOCQPay),
                  value: CashInChannel.VipLOCQPay,
                },
              ]}
              onChange={(name, { value }) => {
                modifyFilter({ [name]: value });
              }}
            />
          </Field>
        </Filter>
        <Field className={styles.search}>
          <SearchField
            restrict={false}
            placeholder={locale.cashInIdAndBusinessName}
            value={searchKey}
            onChange={(_, { value }) => modifySearch(value)}
            onEnter={applySearch}
            onClear={applyClearSearch}
          />
        </Field>
        <Field className={styles.downloadButton}>
          {role === Role.Accounting ? (
            <div></div>
          ) : (
            <Button
              startIcon={<span className={classNames("icon-download", styles.import)} />}
              onClick={openImport}
              outline
            >
              {locale.import}
            </Button>
          )}

          <Button
            startIcon={<span className="icon-download" />}
            exportButton
            onClick={exportReport}
            loading={exporting}
          >
            {locale.export}
          </Button>
        </Field>
      </div>
    </div>
  );
};

export default CashInFilter;
