import React, { useCallback, useMemo } from "react";
import { Modal, DataTable, Text } from "components/commons";
import locale from "localization";
import styles from "./selected-fleet-card-modal.module.scss";
import { SelectedFleetCardColumns } from "./selected-fleet-card-columns";
import { prettifyFleetCardType, prettifyProduct } from "utils/pretty.utils";
import { formatAmount } from "utils";
import classNames from "classnames";
import { Products } from "enums";

const SelectedFleetCardModal = ({ ...props }) => {
  const { close, active, inputStationCount, fleetCardSelected } = props;

  const preparedTargetStationsData = useMemo(() => {
    const data = fleetCardSelected;
    if (data && data.length > 0) {
      const preparedData = data.map((i) => {
        const map = new Map();

        map.set("cardNumber", <Text className={styles.textStyle}>{i?.cardNumber}</Text>);
        map.set(
          "locqpayLimit",
          <Text className={styles.textStyle}>
            {i.locqpayLimit ? formatAmount(i.locqpayLimit) : locale.noLimit}
          </Text>
        );
        map.set(
          "transactionLimit",
          <Text className={styles.textStyle}>
            {i.transactionLimit ? i.transactionLimit : locale.noLimit}
          </Text>
        );
        map.set("driver", <Text className={styles.textStyle}>{i?.driver}</Text>);
        map.set("driverLicenseId", <Text className={styles.textStyle}>{i?.driverLicenseId}</Text>);
        map.set(
          "products",
          <div>
            {i.diesel && (
              <div className="flex items-center">
                <div className={classNames(styles.circle, styles.diesel)}></div>
                {prettifyProduct(Products.Diesel)}
              </div>
            )}
            {i.gas91 && (
              <div className="flex items-center">
                <div className={classNames(styles.circle, styles.gas91)}></div>
                {prettifyProduct(Products.Gas91)}
              </div>
            )}
            {i.gas95 && (
              <div className="flex items-center">
                <div className={classNames(styles.circle, styles.gas95)}></div>
                {prettifyProduct(Products.Gas95)}
              </div>
            )}
            {i.gas97 && (
              <div className="flex items-center">
                <div className={classNames(styles.circle, styles.gas97)}></div>
                {prettifyProduct(Products.Gas97)}
              </div>
            )}
            {i.lubes && (
              <div className="flex items-center">
                <div className={classNames(styles.circle, styles.lubricants)}></div>
                {prettifyProduct(Products.Lubes)}
              </div>
            )}
          </div>
        );
        map.set("mobileNumber", <Text className={styles.textStyle}>{i?.mobileNumber}</Text>);
        map.set("model", <Text className={styles.textStyle}>{i?.model}</Text>);
        map.set("plateNumber", <Text className={styles.textStyle}>{i?.plateNumber}</Text>);
        map.set("vehicleRemarks", <Text className={styles.textStyle}>{i?.vehicleRemarks}</Text>);
        map.set(
          "cardType",
          <Text className={styles.textStyle}>{prettifyFleetCardType(i?.cardType)}</Text>
        );

        return map;
      });
      return preparedData;
    }
    return [];
  }, [fleetCardSelected]);

  const closeCb = useCallback(async () => {
    close();
  }, [close]);

  return (
    <Modal
      active={active}
      title={locale.fleetCards}
      close={closeCb}
      className={styles.modal}
      titleStyle={styles.titleStyle}
    >
      <div className={styles.content}>
        <div className={styles.table}>
          <DataTable
            columns={SelectedFleetCardColumns}
            data={preparedTargetStationsData}
            dataCount={inputStationCount}
            hidePagination
          />
        </div>
      </div>
    </Modal>
  );
};

export default SelectedFleetCardModal;
