import locale from "localization";
import Validation from "utils/validation.utils";

export const initialState = (state = {}) => {
  const {
    mobileAccountNumber = {
      value: "",
    },
    accountName = {
      value: "",
    },
    redemptionStation = {
      value: [],
    },
    product = {
      value: [],
    },
    liters = {
      value: "",
    },
    redemptionDate = {
      value: "",
    },
    redemptionTime = {
      value: "",
    },
    remarks = {
      value: "",
    },
  } = state;

  return {
    redemptionStation: {
      name: "redemptionStation",
      required: true,
      placeholder: locale.searchOrSelect,
      inputLabel: locale.redemptionStation,
      horizontal: true,
      disableAllOption: true,
      isAll: false,
      isMultiple: false,
      ...redemptionStation,
    },
    mobileAccountNumber: {
      name: "mobileAccountNumber",
      required: true,
      maxLength: 10,
      innerLabel: true,
      parseNumber: true,
      validations: [
        Validation.mobileNumber({
          hasInputLabel: true,
        }),
      ],
      userId: null,
      ...mobileAccountNumber,
    },
    accountName: {
      name: "accountName",
      required: true,
      ...accountName,
      validations: [Validation.required({ noMessage: true })],
    },
    product: {
      name: "product",
      required: true,
      ...product,
      validations: [Validation.required({ noMessage: true })],
    },
    liters: {
      name: "liters",
      required: true,
      innerLabel: true,
      parseNumber: true,
      ...liters,
      validations: [Validation.required({ noMessage: true })],
    },
    redemptionDate: {
      placeholder: "Select Date",
      name: "redemptionDate",
      required: true,
      ...redemptionDate,
      validations: [Validation.required({ noMessage: true })],
    },
    redemptionTime: {
      placeholder: "Select Time",
      name: "redemptionTime",
      required: true,
      ...redemptionTime,
      validations: [Validation.required({ noMessage: true })],
    },
    remarks: {
      name: "remarks",
      required: true,
      ...remarks,
      validations: [Validation.required({ noMessage: true })],
    },
  };
};
