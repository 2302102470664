import locale from "localization";

export const myFuelCodesColumns = [
  { key: "businessName", text: locale.businessNameId, width: "7%" },
  {
    key: "fuelCodeType",
    text: `${locale.fuelCode}/${locale.fuelCodeType}`,
    width: "7%",
  },
  { key: "assignedDriver", text: locale.assignedDriver, width: "5%" },
  { key: "assignedVehicle", text: locale.assignedVehicle, width: "5%" },
  { key: "product", text: locale.product, width: "15%" },
  { key: "literVolume", text: locale.volumeLimit, width: "15%" },
  { key: "volumeRedeemed", text: locale.volumeRedeemed, width: "15%" },
  { key: "oneTimeUse", text: `${locale.oneTimeUse}?`, width: "20%" },
  { key: "sendSms", text: `${locale.sentAsSms}?`, width: "20%" },
  { key: "autoRenew", text: `${locale.autoRenew}?`, width: "20%" },
  { key: "startDate", text: locale.codeStartDate, width: "10%" },
  { key: "endDate", text: locale.codeEndDate, width: "10%" },
  { key: "createdAt", text: locale.dateGenerated, width: "7%" },
  { key: "status", text: locale.status, width: "2%" },
  { key: "actions", text: locale.actions, width: "2%" },
];
